import { GlobalService } from 'src/app/core/_services/global/global.service';
import { environment } from 'src/environments/environment.prod';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ServiceType } from '../company-admin/pages/car-wash-folder/Enum/ServiceType';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
@Component({
  selector: 'app-global-website',
  templateUrl: './global-website.component.html',
  styleUrls: ['./global-website.component.scss']
})
export class GlobalWebsiteComponent implements OnInit {
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Adjust the scroll position (e.g., 50) based on when you want the background to change
    if (offset > 50) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
  scrolled: boolean = false;
  websiteObj:any ={}
  service:any ={}
  websiteName = ''
  isToggle =  false
  services = []
  settings = {
    counter: false,
    plugins: [lgZoom],
};

 serviceType = ServiceType
 products: any= []
 productsPackaging: any= []
 facebookLink = ''
 instagramLink = ''
 yelpLink = ''
 whatsappLink = ''
 twitterLink = ''
 companyInfo:any ={}
 bufferProduct:any ={}
 valueDate = new Date()
 sendContactObj:any = {}
 google_jump_location = environment.google_jump_location
 baseDomain = environment.baseDomain
 urlMap:SafeResourceUrl = ''
 isToggleOpen = false
 favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
  constructor(
   private _carwashService:CarWashService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private _globalService: GlobalService,
    private titleService:Title,
    private _toaster: ToasterCustomService,
    public sanitizer: DomSanitizer

  ) { }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
};

  ngOnInit() {
    // let el: HTMLElement = document.getElementById('elementID');
    //   el.style.fontSize = "62%";
    this.favIcon.href = './assets/car-service.ico';
    document.documentElement.style.fontSize = "62.5%";
    this.websiteName = this.route.snapshot.paramMap.get('id');
    this.getWebsiteInfo()
  }
  jumpToSection(elementId: string){
    this.viewportScroller.scrollToAnchor(elementId);
    if(this.isToggleOpen){
      let navbar = document.querySelector('.header .navbar')
      let menu = document.querySelector('.header .fa-bars')
      navbar.classList.toggle('active');
      menu.classList.toggle('active');
      this.isToggleOpen = false
    }
  }
  toggleMenu(){
    let navbar = document.querySelector('.header .navbar')
    let menu = document.querySelector('.header .fa-bars')
    navbar.classList.toggle('active');
    menu.classList.toggle('active');
    this.isToggleOpen = true
  }
  toggleBar() {
    this.isToggle  = !this.isToggle
    // let navBar = document.getElementById('info-btn')
    // let contact_info = document.getElementById('contact_Id')
    // let menu = document.getElementById('menu') as HTMLImageElement
    // console.log(contact_info.style.display)
    // if(contact_info.style.display == 'none'){
    //   contact_info.style.display = 'block'
    // } else {
    //   contact_info.style.display = 'none'
    // }
  }
  getWebsiteInfo(){
    this._carwashService.getWebsiteByName(this.websiteName).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.websiteObj = response.data.website
       this.facebookLink = this.websiteObj.socialMedia.items.find(w => w.id == 1)
       this.whatsappLink = this.websiteObj.socialMedia.items.find(w => w.id == 2)
       this.twitterLink = this.websiteObj.socialMedia.items.find(w => w.id == 6)
       this.yelpLink = this.websiteObj.socialMedia.items.find(w => w.id == 4)
       this.instagramLink = this.websiteObj.socialMedia.items.find(w => w.id == 5)
       this.products = response.data.products.filter(p =>p.isPackage == false)
       console.log('productsssssssss',this.products)
       this.productsPackaging = response.data.products.filter(p =>p.isPackage == true)
       this.companyInfo = response.data.company
       this.services = response.data.services.filter(f => f.isAchieve == false)
       this.titleService.setTitle(this.companyInfo.companyName);
       this.urlMap= this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyAbDN1oHe1xUA_Xg4wmLvIT8Ef1w9ZQnh8&q=${this.websiteObj.contactAddress.phoneEmailObj.address}`);

      }
    })
  }
  getPrice(price){
    return price / 100
  }
  storeService(service){
    this.service = service
  }
  showDetails(product:any){
    this.bufferProduct = product
  }
  sendContact(){
    this._carwashService.sendEmailContact(this.websiteObj.id,this.sendContactObj).subscribe((response:ResponseModel)=>{
      if(response.success){
      this._toaster.success('','Successfully Send Mail')
      this.sendContactObj ={}
      }
    })

  }
   showPosition() {
    this._globalService.openLink(this.google_jump_location + this.websiteObj.contactAddress.phoneEmailObj.address)
  }
  getTime(time){
    // if (event !== "") {
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      let suffix = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      hours = hours < 10 ? "0" + hours : hours;
      return  hours + ":" + minutes + " " + suffix;
      // isStart ? this.days[index].start  = formatTime  : this.days[index].end  = formatTime
    // }
  }
  getMapLink(){
    this.urlMap = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAbDN1oHe1xUA_Xg4wmLvIT8Ef1w9ZQnh8&q=${this.websiteObj.contactAddress.phoneEmailObj.address}`
  }
  getAddress(address){
    if(address){
     return address.substring(0, 20) + '..';
    }
    else{
       return ''
    }
  }
  getFirstWord(): string {
    // Split the company name into words using space as a delimiter
    const words = this.companyInfo.companyName.split(' ');
    // Return the first word
    return words.length > 0 ? words[0] : '';
  }
  subscribeCustomer(){
    this._globalService.openLink(`${this.baseDomain}/invite-customer/${this.companyInfo.companyNumber}-${this.companyInfo.signUpDateTimeStamp}`)
  }
}
