import { ToasterCustomService } from './../../../../core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from './../../../../models/response/ResponseModel';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonService } from './../../../../core/_services/person/person.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/models/role/RoleModel';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { PersonPendingService } from 'src/app/core/_services/PendingPersons/personPending.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { CategoryCompany } from '../permissions/Enum-Category/CategoryCompany';
declare var $;
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  users: any[] = []
  usersPending: any[] = []
  user: any
  jobs: any = []
  userItem: string
  roleUsers: any
  role: string
  searchText = ''
  searchBendingText = ''
  countryCode: boolean = true
  p: number = 1;
  p2: number = 1;
  p3: number = 1;
  idUser :string
  roleUser:string
  userObj: any = {}
  tools: any = {}
  othersTools: any = {}
  bufferId:string=''
  categoryCompany = CategoryCompany
  @ViewChild('basicModal') basicModal: ModalDirective;
  constructor(
    private _personService: PersonService,
    private _companiesService: CompaniesService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private socket: Socket,
    private _personPendingService: PersonPendingService,
    private _toasterCustomService: ToasterCustomService,
    private _toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.countryCode = this._companiesService.getCountry()
    this.roleUsers = [
      { key: 'Manager', value: 'manager' },
      { key: 'Team Leader', value: 'teamleader' },
      { key: 'Crew', value: 'crew' },
      { key: '3Rd Party', value: '3rdparty' },
      { key: 'Disable', value: 'disable' }
    ]
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        this.othersTools = this.tools.find(t => t.toolType == ToolType.Setting_Tools)
        if (this.role == 'admin' || this.role == 'manager' || this.role == 'teamleader' || this.role == 'crew') {
          this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
            if(response.success){
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
                this.userObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.Users_list,ToolType.Setting_Tools,permissionList)
               if(this.role == 'admin' ||this.userObj.itemStatus == "Active" && this.userObj.roleStatus == "active" || this.userObj.itemStatus == "Active" && this.userObj.roleStatus == "readOnly" ){
                this.getAllUsers()
                this.getUsersPending()
              }
            }
          })
        }
      }
    }, error => {
      console.log(error)
    })
    // realtime users list
    this.socket.on(RealTimeKeys.User_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._personService.getPersonById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.users.unshift(response.data)
          }
        }, error => {
          console.log(error)
        })
      }
    });
    // user pending realtime
    this.socket.on(RealTimeKeys.Invite_User, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._personPendingService.getPendingUser(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.usersPending.unshift(response.data)
          }
        }, error => {
        })
      }
    });
  }
  getAllUsers() {
    this._personService.getUsersByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
      this.users = response.data.filter(u => u.role != 'admin')
      this.users = this.filterUsersByRole(this.user.role, this.users)
    })
  }
  getUsersPending() {
    this._personPendingService.getAllPendingUsers(this.user.companyId).subscribe((response: ResponseModel) => {
      this.usersPending =  response.data
      this.usersPending = this.filterUsersByRole(this.user.role, this.usersPending)
    })
  }
  routeCustomerDetails(id) {
    if (this.role == 'admin' || this.role == 'manager') {
      this.router.navigate(['/admin/user-profile', id])
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty' || this.role =='customer' ) {
      this.router.navigate(['/user/user-profile', id])
    }
  }
  filterUsersByRole(role: string, usersArray: any) {
    let usersReturened: any
    if (role === 'admin') {
      usersReturened = usersArray
    }
    if (role === 'manager') {
      usersReturened = usersArray.filter(u => u.role === 'teamleader' || u.role === 'crew' || u.role === '3rdparty' || u.role === 'customer')
      this.roleUsers = [
        { key: 'Team Leader', value: 'teamleader' },
        { key: 'Crew', value: 'crew' },
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === 'teamleader') {
      usersReturened = usersArray.filter(u => u.role === 'crew' || u.role === '3rdparty' || u.role === 'customer')
      this.roleUsers = [
        { key: 'Crew', value: 'crew' },
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === 'crew') {
      usersReturened = usersArray.filter(u => u.role === '3rdparty' || u.role === 'customer')
      this.roleUsers = [
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === '3rdparty') {
      usersReturened = usersArray.filter(u => u.role === '3rdparty')
      this.roleUsers = [
        { key: '3rdparty', value: '3Rd Party' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    return usersReturened;
  }
  filterUsersPendingByRole(role: string, usersArray: any) {
    let usersReturened: any
    if (role === 'admin') {
      usersReturened = usersArray
    }
    if (role === 'manager') {
      usersReturened = usersArray.filter(u => u.role === 'manager' ||  u.role === 'teamleader' || u.role === 'crew' || u.role === '3rdparty' )
      this.roleUsers = [
        { key: 'Team Leader', value: 'teamleader' },
        { key: 'Crew', value: 'crew' },
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === 'teamleader') {
      usersReturened = usersArray.filter(u =>u.role === 'teamleader' ||  u.role === 'crew' || u.role === '3rdparty' )
      this.roleUsers = [
        { key: 'Crew', value: 'crew' },
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === 'crew') {
      usersReturened = usersArray.filter(u => u.role === 'crew' ||  u.role === '3rdparty')
      this.roleUsers = [
        { key: '3Rd Party', value: '3rdparty' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    else if (role === '3rdparty') {
      usersReturened = usersArray.filter(u => u.role === '3rdparty')
      this.roleUsers = [
        { key: '3rdparty', value: '3Rd Party' },
        { key: 'Disable', value: 'disable' }
      ]
    }
    return usersReturened;
  }
  inviteUser() {
    if(this.role == 'admin' || this.role == 'manager'){
      this.router.navigate(['/admin/invite'])
    }
    else{
      this.router.navigate(['/user/invite'])
    }
  }
  getUsers() {
    this._personService.getUsers().subscribe(data => {
    })
  }
  disableUserConfirm(event: any, id: string){
    this.idUser = id
    this.roleUser = event
  }
  disableUser(event: any, id: string) {
    if (event === 'disable') {
      this._personService.disableUser(id).subscribe((response: ResponseModel) => {
        if(response.success){
        this._toasterCustomService.success('You can change the role anytime later.','User Successfully Disable')
        }
        else{
          this._toasterCustomService.error('Please try again','User Error Disable')
        }
      }, error => {
        this._toasterCustomService.error('Please try again','User Error Disable')
      })
    }
    else {
      this._personService.updatePersonRole(id, event).subscribe((response: ResponseModel) => {
        if(response.success){
          this._toasterCustomService.success('You can change the role anytime later.','User Role Successfully Changed')
        }
        else{
          this._toasterCustomService.error('Please try again','User Role Error Changed')
        }
      }, error => {
        this._toasterCustomService.error('Please try again','User Role Error Changed')
      })
    }
  }
  reInvitePerson(id: string) {
    this.spinner.show()
    this._personService.ReInvitePerson(id).subscribe(data => {
      this.spinner.hide()
      this.toastr.success('You can cancel invitation anytime later', 'User Successfully Re-Invited',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        });
    }, error => {
      this.spinner.hide()
      this.toastr.error('Please try again', 'Error Invitation',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        });
    }
    )
  }
  restPagination(event){
    let test = 0
    this.p=1
  }
  restPaginationPending(event){
    this.p2  =1
  }
  ReInvitePersonPending(id: string) {
    this.spinner.show()
    this._personService.ReInvitePersonPending(id).subscribe((response:ResponseModel) => {
      if(response.success){
        this._toasterCustomService.success('You can cancel invitation anytime later','User Successfully Re-Invited')
      }
      else{
        this._toasterCustomService.error('Please try again','User Error Re-Invited')
      }
    }, error => {
      this._toasterCustomService.error('Please try again','User Error Re-Invited')
    }
    )
  }
  getAllJobsByUser(userId: string) {
    this._personService.getAllJobsByUser(this.user.companyId, userId).subscribe((response: ResponseModel) => {
      this.jobs = response.data
      this.basicModal.show()
    }, error => {
      console.log(error)
    })
  }
  storId(item: any) {
    this.userItem = item
  }
  storeReInvitePersonPending(id:string){
    this.bufferId = id
  }
  cancelPerson(id: string) {
    let index = this.users.indexOf(id)
    this.spinner.show()
    this._personService.cancelPerson(id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.users.splice(index, 1)
      }
      this.spinner.hide()
    }, error => {
      console.log(error)
      this.spinner.hide()
    })
  }
  cancelPersonPending(item: any) {
    let index = this.usersPending.indexOf(this.userItem)
    this._personService.cancelUserPending(item.id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.usersPending.splice(index, 1)
        this._toasterCustomService.success('Link Sent is No More Valid You can invite the user anytime later.','Pending Invite Successfully Canceled')
      }else{
        this._toasterCustomService.error('Please try again','Pending Invite Error Canceled')
      }
    }, error => {
      console.log(error)
      this._toasterCustomService.error('Please try again','Pending Invite Error Canceled')
    })
  }
  inviteManager() {
    localStorage.setItem('isAdmin', 'true')
    this.router.navigate(['/admin/invite'])
  }
  redirection(jobItem: any) {
    if(this.userObj.roleStatus == "readOnly"){
    }
    else if(this.userObj.roleStatus == "active" || this.role == 'admin'){
      let job: any = this.jobs.find(j => j.id == jobItem.id)
      let jobModel = {
        jobNumber: job.jobNumber,
        primaryCustomerName: job.primaryCustomerName,
        jobId: job.id,
        jobType: job.jobType
      }
      localStorage.setItem('jobModel', JSON.stringify(jobModel))
      // this.router.navigate(['/admin/job-details/', jobItem.id])
      if(this.role == 'admin' || this.role == 'manager'){
        this.router.navigate(['/admin/job-details/', jobItem.id])
        }
        else{
        this.router.navigate(['/user/job-details/', jobItem.id])
        }
      this.basicModal.hide()
    }
  }
}
