import { ToolJobsEditItem } from './../tools-model/ToolJobsEditItem';
import { JobStatusEnum } from './JobStatusEnum';
// let user:any = JSON.parse(localStorage.getItem('user'))
export class EmptyJobDetails {
  constructor(
    public notes?: [],
    public dateAndTime?: any[],
    public signature?: [],
    public statusJobs?: any[],
    public jobSummary?: any,
    public militaryBill?: any,
    public progressStatus?: any[]
  ) {
    this.notes = [];
    this.dateAndTime = [];
    this.progressStatus = [];
    this.signature = []
    this.statusJobs = [{
      id: 1,
      name: "Draft",
      isActive: "true",
      type: JobStatusEnum.Draft
    },
    {
      id: 2,
      name: "Deal",
      isActive: "false",
      type: JobStatusEnum.Deal
    },
    {
      id: 3,
      name: "In Progress",
      isActive: "false",
      type: JobStatusEnum.In_Progress
    },
    {
      id: 4,
      name: "Done",
      isActive: "false",
      type:  JobStatusEnum.Done
    },
    {
      id: 5,
      name: "Canceled",
      isActive: "false",
      type:  JobStatusEnum.Canceled
    }
    ]
    this.jobSummary = {
      itemsActualQuantity: 0,
      itemsActualWeight: 0,
      itemsActualVolume: 0,
      itemsActualValue: 0,
      itemsEstimateQuantity: 0,
      itemsEstimateWeight: 0,
      itemsEstimateVolume: 0,
      itemsEstimateValue: 0,
      itemsEstimateBoxed: 0,
      itemsActualBoxed: 0,
      itemsActualPads: 0,
      itemsEstimatePads: 0,
      itemsActualExtraServiceFee: 0,
      itemsEstimateExtraServiceFee: 0,
    }
    this.militaryBill = {
    militaryBillIn: {
          serviceInformation: {},
        documentScreen: [],
        weight: []
      },
      militaryBillOut: {
        serviceInformation: {
      },
    documentScreen: [],
    weight: []
      }
    }
    }
}
