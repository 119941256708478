import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { PaymentType } from '../Enum/PaymentType';

@Component({
  selector: 'app-add-vehicle-customer',
  templateUrl: './add-vehicle-customer.component.html',
  styleUrls: ['./add-vehicle-customer.component.scss']
})
export class AddVehicleCustomerComponent implements OnInit {
  @ViewChild('warningKeyStripModal') warningKeyStripModal: ModalDirective;
  numCharPattern = ValidationModel.complexPattern
  carsList: any = []
  addVehicleFormGroup: FormGroup;
  filteredOptions: Observable<any[]>;
  user: any = {}
  vehicle:any={}
  isExists = false
  isLinear = false;
  timer:any =  {};
  paymentType = PaymentType.card
  paymentTypeEnum = PaymentType
  monthsCard = ['forever','1','2','3','4','5','6','7','8','9','10','11','12']
  historyCarWash = HistoryCarWash
  isHaveSecretKey= false
  countrySelected:any = {}
  countrySelectedArr = []
  constructor(
    private _formBuilder: FormBuilder,
    private _toaster: ToasterCustomService,
    private _carWashService: CarWashService,
    private router: Router,
    public datePipe: DatePipe,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.getData()
    this.user = JSON.parse(localStorage.getItem('user'))
    localStorage.setItem('countMonth', 'forever')
    this.storePaymentType(this.paymentType)
    this.createAddVehicleForm()
    this.filteredOptions = this.addVehicleFormGroup.get('vehicleType').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.countrySelectedArr.unshift(localStorage.getItem('country').toLowerCase())
    console.log(this.f.plateNumber)
  }
  createAddVehicleForm() {
    this.addVehicleFormGroup = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['', [Validators.required,Validators.pattern(this.numCharPattern)]],
      vehicleModel: [''],
      phone: ['']
    });
  }
  get f() {
    return this.addVehicleFormGroup.controls;
  }
  chooseCar(car:any){
    this.addVehicleFormGroup.patchValue({
      vehicleType :car.Make,
      vehicleModel :car.Model,
    })
  }
  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.carsList.filter(option => option.Make.toLowerCase().indexOf(filterValue) === 0);
  }
  getData() {
    return this.http.get('/assets/cars_list.json').subscribe((response: any) => {
      this.carsList = response
    });
  }
  isPlateNumberExist(){
    clearTimeout(this.timer)
    this.timer  = setTimeout(()=>{
      if (this.f.plateNumber.status == "VALID") {
        this._carWashService.isPlateNumberExist(this.user.companyId, this.f.plateNumber.value.toUpperCase()).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExists = false
            // this.isAnotherVehicle = false
          }
          else {
            this.isExists = true
            // this.isAnotherVehicle = true
          }
        })
      }
      else {
        this.isExists = false
        return null
      }
    },1000)
  }

  storePaymentType(paymentType){
    localStorage.setItem('paymentType',paymentType)
  }
  addVehicle(){
    let item = this.addVehicleFormGroup.value
    console.log(item)
    let todayDate = new Date()
    this.datePipe.transform(todayDate,'shortTime')
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model = {
      vehicleType : item.vehicleType,
      plateNumber : item.plateNumber.toUpperCase().replace(/\s/g,''),
      vehicleModel : item.vehicleModel,
      qrCodeNumber : 0,
      condition : "",
      phone : item.phone,
      date : (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      creationTime :this.datePipe.transform(todayDate,'H:mm'),
      createdDate :new Date(),
      companyId : this.user.companyId,
      addedBy : this.user.displayName,
      branchName:currentCompany.companyName,
      histories : [{
        id : 1,
        userId : this.user.id,
        branchId : this.user.companyId,
        addedBy : this.user.displayName,
        branchName:currentCompany.companyName,
        dateHistory : new Date(),
        timeHistory : this.datePipe.transform(todayDate,'H:mm'),
        key: this.historyCarWash.Add_Vehicle,
      }]
    }
    console.log(model)
    this._carWashService.addVehicleGlobal(model,this.user.id).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.vehicle = response.data
        localStorage.setItem('vehicleId',this.vehicle.id)
        this._toaster.success('It is optional to do the next step','Car Profile Successfully Added')
      }
    })
  }
  chooseCountMonth(event){
    localStorage.setItem('countMonth',event)
    }
    createMemberShipCash(vehicleId){
      this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
        if(response.success){
          this.isHaveSecretKey = false
            this.router.navigate(['/user/products',vehicleId])
        }
        else{
          this.isHaveSecretKey = true
          this.warningKeyStripModal.show()
        }
      })
    }
    yourComponentMethodToTreatyCountryChangedEvent(event) {
      this.countrySelected = event
    }
}
