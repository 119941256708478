import { PersonService } from './../_services/person/person.service';
import { JobsService } from './../_services/jobs/jobs.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class GetPersonByIdResolver implements Resolve<any[]>
{
  constructor(private _personService: PersonService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let id: string = ''
    let user = JSON.parse(localStorage.getItem('user'))
    if (route.params['id'] != null && route.params['id'] === user.companyId) {
      let user = JSON.parse(localStorage.getItem('user'))
      id = user.id
    }
    else if (route.params['id'] != null) {
      id = route.params['id']
    }
    else {
      id = user.id
    }
    if(id){
      return this._personService.getPersonById(id).pipe(
        catchError(error => {
          // console.log(error)
          return of(null);
        })
      );
    }
  }
}
