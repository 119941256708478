<div class="card">
    <div class="tittle-section">
        <p>Vehicle Profile</p>
        <div class=" btn-add-new" [routerLink]="['/user/new-vehicle']" *ngIf="(vehicle | json) == '{}'">
            <span class="badge badge-success">Add New</span>
        </div>
        <div class="pr-1" *ngIf="queueData.isInQueue && queueData.queueNumber >= 0">
            <span>Your turn:</span> <strong>{{queueData.queueNumber}}</strong>
        </div>
    </div>
    <!-- <div class="content" *ngIf="(vehicle | json) != '{}'"> -->
    <div class="content" *ngIf="vehicles.length > 0">

        <div class="header-member">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="row ">
                            <div class="col-4">
                                <span class="tittle ">
                                <span>Plate / #VIN </span>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="tittle">
                                <span>Make</span>
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="tittle">
                                <span >M. Status</span>
                                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <mat-accordion *ngFor="let vehicle of vehicles">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-4">
                            <span class="tittle plate-make">
                               <span>{{vehicle.plateNumber}}</span>
                            </span>
                        </div>
                        <div class="col-4">
                            <span class="tittle plate-make">
                              <span>{{vehicle.vehicleType}}</span>
                            </span>
                        </div>
                        <div class="col-4">
                            <span class="tittle">
                              <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                            <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd">
                                  <i class="uil uil-money-bill-slash uil-medium required"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="header-vehicle">
                    <div class="added-by-date">
                        <div class="icons-vehicle">
                            <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                              <i  *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                              <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                              <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                           </small>
                            <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                        </div>
                        <div *ngIf="!vehicle.isSubscriptionEnd">
                            <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date:'M/d/yyyy'}}</span>
                            <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span> </span>
                            <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                        </div>
                    </div>
                    <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && carWashObj.roleStatus != 'notActive'  && user.role != 'admin'">
            <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
        </div> -->
                    <!-- <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage != 0 && carWashObj.roleStatus != 'notActive' && user.role != 'admin'">
            <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
        </div> -->
                </div>
                <div class="info-vehicle">
                    <div class="item">
                        <label>Plate / #VIN( Last 5 Digits)</label>
                        <h6>{{vehicle.plateNumber}}</h6>
                    </div>
                    <div class="item">
                        <label>Make</label>
                        <h6>{{vehicle.vehicleType}}</h6>
                    </div>
                    <div class="item">
                        <label>Model</label>
                        <h6>{{vehicle.vehicleModel}}</h6>
                    </div>
                    <div class="item" *ngIf="vehicle?.phone">
                        <label>Phone</label>
                        <h6>{{vehicle.phone}}</h6>
                    </div>
                    <div class="item" *ngIf="vehicle.condition">
                        <label>Vehicle Condition</label>
                        <h6>{{vehicle.condition}}</h6>
                    </div>
                </div>
                <div class="footer-vehicle">
                    <div>
                        <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Actions </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="storeVehicle(vehicle)"> <i class="uil uil-edit"></i>Edit Vehicle Information</button>
                            <button mat-menu-item (click)="selectAction(actionsCar.View_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd && vehicle.paymentType == paymentTypeEnum.card"><i class="uil uil-credit-card"></i> Edit/View card subscription <i class="uil uil-external-link-alt uil-size"></i> </button>
                            <button mat-menu-item (click)="selectAction(actionsCar.Create_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip"><i class="uil uil-plus"></i>Create card subscription</button>
                            <button mat-menu-item [routerLink]="['/user/my-profile']"> <i class="uil uil-user-square"></i>My Profile</button>
                        </mat-menu>
                    </div>
                    <div class="history-service">
                        <div class=" badge-light">
                            <button class="btn btn-sm " (click)="storeHistories(vehicle);historiesModal.show()"><i class="uil uil-history"></i> Log History</button>
                        </div>
                        <!-- <div class=" badge-light">
                        <button class="btn btn-sm " (click)="getVehicleStaticsYear(vehicle.id)"><i class="uil uil-history"></i>  Service History</button>
                    </div> -->
                    </div>
                    <!-- <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.card">
                    <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Card Subscription  <i class="uil uil-external-link-alt "></i></button>
                </div> -->
                    <!--  <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.cash">
                    <button class="btn btn-sm btn-light-custom" (click)="changeDenied(true);deniedModal.show()"><i class="far fa-plus-square "></i> Card Subscription</button>
                    <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Cash Subscription  <i class="uil uil-external-link-alt  "></i></button>
                </div> -->
                </div>
                <!-- </div> -->
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="ant-empty-image" *ngIf="vehicles?.length <= 0">
        <!-- <img src="./assets/img/icons/empty.svg" alt="empty-img"> -->
        <i class="uil uil-car-slash uil-large btn-custom-danger"></i>
        <span class="pb-2">No Car Added Yet! </span>
    </div>
</div>
<div class="modal  history " mdbModal #historiesModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title "> Log History</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="historiesModal.hide()" aria-label="Close ">
           <span>&times;</span>
       </button>
            </div>
            <div class="modal-body ">
                <div *ngIf="histories.length > 0 ">
                    <div *ngFor="let item of histories | paginate: {id: 'history_Id' , itemsPerPage: 3, currentPage: p4 }">
                        <div class="card ">
                            <div>
                                <small>{{item.dateHistory | date: 'MMM d,  y'}}, {{item.timeHistory }}</small>
                                <!-- <small> By: {{item.addedBy}} in {{item.branchName}} branch </small> -->
                                <div class="row-history ">
                                    <p><span [ngClass]="[item.key == historyCarWash.Add_Vehicle || item.key == historyCarWash.Update_Vehicle || item.key == historyCarWash.Update_Vehicle_Subscription || item.key == historyCarWash.Check_In_Action || item.key == historyCarWash.Assign_Qr_Code ? 'section-to' : '',  item.key == historyCarWash.Create_Membership  ? 'blue-item' : '', item.key == historyCarWash.Check_Out_Action ||   item.key == historyCarWash.Cancel_Vehicle_Subscription ||  item.key == historyCarWash.Force_Update_Plan ? 'section-from' : '']">
                                    <span *ngIf="item.key == historyCarWash.Add_Vehicle">Added</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle">Updated</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle_Subscription">Update Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Assign_Qr_Code">Assign Qr Code</span>
                                        <span *ngIf="item.key == historyCarWash.Cancel_Vehicle_Subscription">Cancel Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Create_Membership">Create <span *ngIf="item.paymentType == paymentTypeEnum.cash">Cash</span> <span *ngIf="item.paymentType == paymentTypeEnum.card">Card</span> Membership</span>
                                        <span *ngIf="item.key ==  historyCarWash.Force_Update_Plan">Force Update Plan</span>
                                        </span> in <strong>{{item.branchName}}</strong> branch, by <strong>{{item.addedBy}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 " *ngIf="histories.length> 0 ">
                    <pagination-controls id="history_Id" (pageChange)="p4 = $event "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="histories.length <= 0 ">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal  history " mdbModal #updateVehicleModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Update Vehicle</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="updateVehicleModal.hide()" aria-label="Close ">
                  <span>&times;</span>
              </button>
            </div>
            <div class="modal-body ">
                <form [formGroup]="updateVehicleFormGroup">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Vehicle Make</mat-label>
                        <input type="text" required matInput formControlName="vehicleType">
                    </mat-form-field>
                    <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="f.vehicleType.errors.required">Vehicle Make is required</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Plate / #VIN( Last 5 Digits)</mat-label>
                            <input matInput required type="text" class="text-uppercase" formControlName="plateNumber" (keyup)="isPlateNumberExist()">
                        </mat-form-field>
                        <!-- <label for="plate-number">Plate / #VIN( Last 5 Digits)<span class="required">* </span> :</label> -->
                        <!-- <input type="text" id="plate-number" class="form-control text-uppercase" (keyup)="isPlateNumberExist()" placeholder="Plate Number / #VIN (Last 4 digits)" formControlName="plateNumber"> -->
                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                            <div *ngIf="f.plateNumber.errors.pattern">plate number only characters & numbers</div>
                        </div>
                        <div *ngIf="isExists" class="alert alert-danger" role="alert">Plate Number is Exists.</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Car Model</mat-label>
                            <input matInput type="text" formControlName="vehicleModel">
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <ngx-mat-intl-tel-input formControlName="phone" [preferredCountries]="countrySelectedArr" [enablePlaceholder]="true" [enableSearch]="true" name="phone" (countryChanged)="yourComponentMethodToTreatyCountryChangedEvent($event)">
                            </ngx-mat-intl-tel-input>
                        </mat-form-field>
                        <mat-error *ngIf="f.phone?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-light" (click)="updateVehicleModal.hide()">Cancel</button>
                <button class="btn btn-sm btn-success" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid  || isExists || f.phone?.errors?.validatePhoneNumber" (click)="updateVehicle();updateVehicleModal.hide()">Update</button>
            </div>
        </div>
    </div>
</div>
