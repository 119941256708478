<div class="card" *ngIf="userPermission.value != statusActive.InActive ">
    <div class="tittle-section">
        <p> <i class="uil uil-qrcode-scan uil-extra-small pr-1"></i> Inventory QR Codes </p>
    </div>
    <div class="templates">
        <!-- <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 1</span>
                    <span class="tittle">Company name, Phone Number</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p class="mb-0">{{companyInfo.companyPhone}}</p>
                        </div>
                        <strong class="qr-code-number">N</strong>
                    </div>
                </div>
                <div class="third-side" *ngIf="userPermission.value == statusActive.Active ">
                    <div class="select-stickers">
                        <mat-form-field appearance="outline" class="mb-2">
                            <mat-label>Stickers per page</mat-label>
                            <input matInput name="labelStickers" [(ngModel)]="labelStickers" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>N. Of Pages</mat-label>
                            <mat-select name="page" [(value)]="page" [(ngModel)]="page" (selectionChange)="choosePage($event.value)">
                                <mat-option *ngFor="let page of numPages" value="{{page}}">{{page}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Number</mat-label>
                            <input matInput type="number" name="startTemplate" (input)="checkValidity()" required [(ngModel)]="startTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>End Number</mat-label>
                            <input matInput type="number" name="endTemplate" disabled [(ngModel)]="endTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>Total Stickers</mat-label>
                            <input matInput type="number" name="total" disabled [(ngModel)]="total">
                        </mat-form-field>
                        <div class="print-btn">
                            <button class="btn btn-sm btn-primary" [disabled]="errorModel.isError" (click)="addQrCodes(enumTemplates.Template_One)">Print</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="errorModel.isError" class="alert alert-danger">
                    <span>{{errorModel.msg}}</span>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                    </ul>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(thirtyStickersLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Laser & Inkjet Printer Labels by Batrical (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
        <!-- <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 2</span>
                    <span class="tittle">Company name, Num, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p>{{companyInfo.companyPhone}}</p>
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                        <strong class="qr-code-number">N</strong>
                    </div>
                </div>
                <div class="third-side" *ngIf="userPermission.value == statusActive.Active ">
                    <div class="select-stickers">
                        <mat-form-field appearance="outline" class="mb-2">
                            <mat-label>Stickers per page</mat-label>
                            <input matInput name="labelStickers" [(ngModel)]="labelStickers" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>N. Of Pages</mat-label>
                            <mat-select name="page" [(value)]="page" [(ngModel)]="page" (selectionChange)="choosePage($event.value)">
                                <mat-option *ngFor="let page of numPages" value="{{page}}">{{page}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Number</mat-label>
                            <input matInput type="number" name="startTemplate" (input)="checkValidity()" required [(ngModel)]="startTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>End Number</mat-label>
                            <input matInput type="number" name="endTemplate" disabled [(ngModel)]="endTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>Total Stickers</mat-label>
                            <input matInput type="number" name="total" disabled [(ngModel)]="total">
                        </mat-form-field>
                        <div class="print-btn">
                            <button class="btn btn-sm btn-primary" [disabled]="errorModel.isError" (click)="addQrCodes(enumTemplates.Template_Tow)">Print</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="errorModel.isError" class="alert alert-danger">
                    <span>{{errorModel.msg}}</span>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>

                    </ul>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(thirtyStickersLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Laser & Inkjet Printer Labels by Batrical (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
        <!-- <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 3</span>
                    <span class="tittle">Company name, Address, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                            <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                        <strong class="qr-code-number">N</strong>
                    </div>
                </div>
                <div class="third-side" *ngIf="userPermission.value == statusActive.Active ">
                    <div class="select-stickers">
                        <mat-form-field appearance="outline" class="mb-2">
                            <mat-label>Stickers per page</mat-label>
                            <input matInput name="labelStickers" [(ngModel)]="labelStickers" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>N. Of Pages</mat-label>
                            <mat-select name="page" [(value)]="page" [(ngModel)]="page" (selectionChange)="choosePage($event.value)">
                                <mat-option *ngFor="let page of numPages" value="{{page}}">{{page}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Number</mat-label>
                            <input matInput type="number" name="startTemplate" (input)="checkValidity()" required [(ngModel)]="startTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>End Number</mat-label>
                            <input matInput type="number" name="endTemplate" disabled [(ngModel)]="endTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>Total Stickers</mat-label>
                            <input matInput type="number" name="total" disabled [(ngModel)]="total">
                        </mat-form-field>
                        <div class="print-btn">
                            <button class="btn btn-sm btn-primary" [disabled]="errorModel.isError" (click)="addQrCodes(enumTemplates.Template_Third)">Print</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="errorModel.isError" class="alert alert-danger">
                    <span>{{errorModel.msg}}</span>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>

                    </ul>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(thirtyStickersLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Laser & Inkjet Printer Labels by Batrical (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Print</span>
                    <span class="tittle">Job Number, C. name, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>
                                <strong>#{{jobModel.jobNumber}}</strong>
                            </p>
                            <p>{{jobModel.primaryCustomerName}}</p>
                            <!-- <p>{{companyInfo.companyAddress.addressOne}} <span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p> -->
                            <!-- <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p> -->
                            <!-- <p>{{companyInfo.companyPhone}} </p> -->
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                        <strong class="qr-code-number">N</strong>
                    </div>
                </div>
                <div class="third-side" *ngIf="userPermission.value == statusActive.Active ">
                    <div class="select-stickers">
                        <mat-form-field appearance="outline" class="mb-2">
                            <mat-label>Stickers per page</mat-label>
                            <input matInput name="labelStickers" [(ngModel)]="labelStickers" readonly>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>N. Of Pages</mat-label>
                            <mat-select name="page" [(value)]="page" [(ngModel)]="page" (selectionChange)="choosePage($event.value)">
                                <mat-option *ngFor="let page of numPages" value="{{page}}">{{page}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Start Number</mat-label>
                            <input matInput type="number" name="startTemplate" (input)="checkValidity()" required [(ngModel)]="startTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>End Number</mat-label>
                            <input matInput type="number" name="endTemplate" disabled [(ngModel)]="endTemplate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2">
                            <mat-label>Total Stickers</mat-label>
                            <input matInput type="number" name="total" disabled [(ngModel)]="total">
                        </mat-form-field>
                        <div class="print-btn">
                            <button class="btn btn-sm btn-primary" [disabled]="errorModel.isError" (click)="addQrCodes(enumTemplates.Template_Four)">Print</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="errorModel.isError" class="alert alert-danger">
                    <span>{{errorModel.msg}}</span>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                    </ul>
                </div>
                <div class="notes">
                    <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(thirtyStickersLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Laser & Inkjet Printer Labels by Batrical (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<!--  print QR -->
<div mdbModal #qrPrintModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Template</h5>
                    <button type="button" class="close" (click)="qrPrintModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <div class="row" id="qr_codes_Id_30" *ngIf="quantity == '30'">
                        <div class="col-4 " *ngFor="let item of qrCodes;let i=index ">
                            <div class="full-item">
                                <div class="info ">
                                    <div class="qr-img">
                                        <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                                        <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                    </div>
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                    </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div> -->
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                                        <p>
                                            <strong>#{{jobModel.jobNumber}}</strong>
                                        </p>
                                        <p *ngIf="jobModel.primaryCustomerName != 'Customer Name'">{{jobModel.primaryCustomerName}}</p>
                                        <!-- <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p> -->
                                        <!-- <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p> -->
                                        <!-- <p>{{companyInfo.companyPhone}} </p> -->
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <strong class="qr-code-number">{{item.qrCodeNumber}}</strong>
                                </div>
                            </div>
                            <!-- <div [ngClass]="[i == 30  ? 'full-item' : '']">
                              <div class="info "></div>
                          </div> -->
                        </div>
                    </div>
                    <div class="row" id="qr_codes_Id_10" *ngIf="quantity == '10'">
                        <div class="col-6 " *ngFor="let item of qrCodes ">
                            <div class="full-item">
                                <div class="qr-img">
                                    <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                                    <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                </div>
                                <div class="info ">
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <strong class="qr-code-number">{{item.qrCodeNumber}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer ">
                <button type="button " class="btn btn-sm btn-primary " (click)="qrPrintModal.hide();printFixed() ">Print</button>
            </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div id="test_print">
test print
</div> -->
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='true'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>