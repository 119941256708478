import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgxPrintElementService } from 'ngx-print-element';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment.prod';
import { Browser } from '@capacitor/browser';

// declare var $;
@Component({
  selector: 'app-all-inventories-print-map',
  templateUrl: './all-inventories-print-map.component.html',
  styleUrls: ['./all-inventories-print-map.component.scss']
})
export class AllInventoriesPrintMapComponent implements OnInit {
  @Input() objFromParent3: any = {};
  date: any = new Date()
  jobNumber
  mywindow: any
  signatures: any[] = []
  lastName = 'omar'
  @ViewChild('divContainer') divContainer: any;
  @HostListener("window:afterprint", ["$event"])
  baseUrl = environment.baseDomain
  constructor(
    public print: NgxPrintElementService,
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit() {
    // this.jobNumber = this.objFromParent3.inventories[0].jobNumber
  }
  printAll(divName) {
    let printContents: string = document.getElementById(divName).id;
    // console.log(printContents)
    // printJS(printContents,'html')
    let innerContents = document.getElementById(divName).innerHTML;
    // var popupWindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    // popupWindow.document.open();
    // popupWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="./assets/css/documentation.print.css"  type=\"text/css\" media=\"print\" /></head><body onload="window.print()">' + innerContents + '</html>');
    // popupWindow.document.close();
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  printElment() {
    let printContents = document.getElementById('all-inventories').id;
    this.print.print(printContents, { printMode: 'template', pageTitle: 'Hello World' })
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
  }
  printFixed(id) {
    // if (this.deviceService.isDesktop()) {
      let printContents = document.getElementById(id).innerHTML;
      let popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>print</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
          <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
          <style>
          .print {
            text-align: center;
        }
        body {
          // margin : 2rem auto;
          // text-align : center;
          page-break-after: always;
          // page-break-after: auto;
          // page-break-after: always;
          // page-break-after: avoid;
          // page-break-after: left;
          // page-break-after: right;
          // page-break-after: recto;
          // page-break-after: verso;
        }
        .print .btn {
            text-transform: capitalize;
        }

        .job-summary .table {
            text-align: center;
        }

        .job-summary .table thead tr th {
            background: #c1c1cc;
            border: 1px solid #e9e9eb;
        }

        .job-summary .table tbody tr td {
            border: 1px solid #e9e9eb;
        }

        .row .col-12 {
            margin: 7px auto;
        }

        .row .col-12 .first-row-header {
            display: flex;
            justify-content: space-between;
            background: #c1c1cc;
            padding: 5px;
            border: 1px solid #a5a5a5;
        }

        .row .col-12 .tow-row-header,
        .row .col-12 .three-row-header {
            display: flex;
            justify-content: left;
            background: #c1c1cc;
            padding: 5px;
            border: 1px solid #a5a5a5;
        }

        .row .col-12 .tow-row,
        .row .col-12 .three-row {
            display: flex;
            justify-content: center;
            padding: 5px;
            border: 1px solid #a5a5a5;
            background-color: transparent;
        }

        .row .item-person {
            display: flex;
            padding: 10px;
            background: #e9e9eb;
            align-items: baseline;
            margin: 5px auto;
        }

        .row .item-person label,
        .row .item-person h6 {
            margin: 0;
        }

        .row .item-person h6 {
            padding-left: 5px;
        }

        .signature .table tbody td {
            border: 1px solid #c1c1c1 !important;
        }

        .signature .table tbody .img-sign {
            text-align: center;
        }

        .signature .table tbody .img-sign img {
            width: 100px;
        }

        .signature .table tbody .status-date {
            display: flex;
            justify-content: space-between;
        }

        .signature .table tbody .last {
            height: 11px;
        }

        .tittle h6 {
            padding: 0.5rem;
            text-align: center;
            background: #c1c1cc;
            margin: 10px auto;
        }
        .inventories .col-12{
          page-break-after: always;
        }
        @media print {
       .header .btn{
        display:none
       }
       .btn-sm{
        display:none;
       }
        }
          </style>
        </head>
    <body onload="window.print()">
    <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
    ${printContents}</body>
      </html>`
      );
      popupWin.document.close();
    // } else {
    //   let url:any =`${this.baseUrl}items-in-map-tag-print?isAccess=true&id=${id}&data=${JSON.stringify(this.objFromParent3)}`
    //   Browser.open({ url: url });
    // }
  }
  getBody() {
    let tableDiv:any = document.getElementById('firstTable')
    // let textBody =`<h1>Dear ${this.lastName} , ${this.lastName}</h1>
    // <table>
    //    <tr><td>This is a table</td></tr>
    // </table>`
    // let htmlBody = this.divContainer.nativeElement.innerHtml;
  //  let  tableDiv :HTMLTableElement
    // var tbl = document.createElement('table');
    // tbl.style.border = "1px solid black";
    // for(var i = 0; i < 5; i++)
    // {
    //     var tr = tbl.insertRow();
    //     for(var j = 0; j < 9; j++)
    //     {
    //         var td = tr.insertCell();
    //     }
    // }
    // tableDiv.append("<br>");
    // tableDiv.append(tbl);

  // return "mailto:" + encodeURIComponent('omar@maplly.com') + "?subject=" + encodeURIComponent('omar') + "&body=" + encodeURIComponent(tableDiv.toString);

    return tableDiv
  }
}
