import { Step } from './../../../models/step/Step';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  baseUrl = environment.baseUrl
  idItem: any

  constructor(private http: HttpClient) {
    this.idItem = ToolJobsEditItem.steps
  }
  getSteps() {
    return this.http.get(this.baseUrl + 'steps' + '/' + this.idItem)
  }
  getAllStepsByJob(jobId: any) {
    return this.http.get(this.baseUrl + 'steps/' + jobId + '/' + this.idItem)
  }
  getStepById(id: any, jobId: string) {
    return this.http.get(this.baseUrl + 'step/' + jobId + '/' + id + '/' + this.idItem)
  }
  addStep(step: any, jobId: string, isCustomerEmail: number) {
    return this.http.post(this.baseUrl + 'step' + '/' + jobId + '/' + this.idItem + '?isCustomerEmail=' + isCustomerEmail, step)
  }
  updateStep(newStep: any, id: string, jobId: string, isCustomerEmail: number) {
    return this.http.put(this.baseUrl + 'step/' + jobId + '/' + id + '/' + this.idItem + '?isCustomerEmail=' + isCustomerEmail, newStep)
  }
  deleteStep(id: string, jobId: string) {
    return this.http.delete(this.baseUrl + 'step/' + jobId + '/' + id + '/' + this.idItem)
  }
}
