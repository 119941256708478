import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getMapByCompany(companyId: string) {
    return this.http.get(this.baseUrl + 'getMap/' + companyId);
  }
  getMapBackUpDays(id: string) {
    return this.http.get(this.baseUrl + 'mapBackUpDays/' + id);
  }
  getMapBackUpByDay(id: string, day: any) {
    return this.http.get(this.baseUrl + 'mapBackUpDay/' + id +'/'+ day);
  }
  getMapBackUpTime(companyId: string) {
    return this.http.get(this.baseUrl + 'mapBackUpTime/' + companyId );
  }
  updateMap(model: any) {
    return this.http.put(this.baseUrl + 'updateMap', model);
  }
  updateMapColor(id: string, model: any) {
    return this.http.put(this.baseUrl + 'updateMapColor/' + id, model);
  }
  updateColumnNames(id: string, model: any) {
    return this.http.put(this.baseUrl + 'updateMapColumn/' + id, model);
  }
  updateRowNames(id: string, model: any) {
    return this.http.put(this.baseUrl + 'updateMapRow/' + id, model);
  }
  updateMapData(id: string, model) {
    return this.http.put(this.baseUrl + 'updateMapData/' + id, model);
  }
  updateMapTimeBackUp(id: string, backUpTime: any) {
    return this.http.put(this.baseUrl + 'updateMapTime/' + id, {backUpTime:backUpTime});
  }
  // map new fun
  getInventoryItemInMap(id: any) {
    return this.http.get(this.baseUrl + 'inventoryMap/' + id);
  }
  getContainerInMap(id: any) {
    return this.http.get(this.baseUrl + 'containerMap/' + id );
  }
  getStatisticInventoriesMap(companyId:string){
    return this.http.get(this.baseUrl + 'inventoriesMap/' + companyId );
  }
  getStatisticContainersMap(companyId:string){
    return this.http.get(this.baseUrl + 'containersMap/' + companyId );
  }
  mapJobsInfo(companyId: any) {
    return this.http.get(this.baseUrl + 'mapJobsInfo/' + companyId );
  }
  // update update Container
  updateContainer(newContainer: any, id: string,) {
    return this.http.put(this.baseUrl + 'containerMap/' + id , newContainer);
  }
}
