<div class="container-fluid">
    <h1>Accordev</h1>
    <div class="first">
        <p>Your Invitation was canceled!.</p>
    </div>
    <div class="second">
        <strong>Your Invitation </strong>
        <!-- <p>Company Name</p> -->
        <!-- <p>
            {{companyInfo.companyAddress?.addressOne}},<small *ngIf="companyInfo.companyAddress?.addressTow"> {{companyInfo.companyAddress?.addressTow}}, </small> {{companyInfo.companyAddress?.Country.name}}, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}},
            {{companyInfo.companyAddress?.zip}}
        </p> -->
        <strong> was canceled!.</strong>
    </div>
    <div class="third">
        <p>You are no longer able to signup for an Accordev account.if you think your invitation was canceled by mistake ,please contact the company.</p>
        <strong>Kind Regards, Accordev Team</strong>
    </div>
    <!-- <div class="request-form">
      <button class="btn btn-primary">Request Form</button>
  </div> -->
</div>
