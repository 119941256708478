import { ValidationModel } from './../../../../ValidationModel/ValidationModel';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Person } from 'src/app/models/person/Person';
import { EmptyJobDetails } from 'src/app/models/job-details/emptyJobDetails';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { UpdateInfo } from '../edit-company/Enum/UpdateInfo';
import { CategoryCompany } from '../permissions/Enum-Category/CategoryCompany';
declare var google: any;
interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
}
@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit  {
  @ViewChild('addressText') addressText: any;
  companyModal: any
  Currencies: any[] = []
  customerFormGroup: FormGroup
  defaultValue: Country = {} as Country;
  usersToCompany: Person[] = []
  customersToCompany: Person[] = []
  trucksToCompany: any[] = []
  customerModel: any = {}
  isUpdated: boolean
  user: any = {}
  emailPattern = ValidationModel.emailPattern;
  numberPattern = ValidationModel.numberPattern;
  isExists = false
  isRequired = false
  isNotEmail = false
  timer:any
  role: string = ''
  categoryCompany = CategoryCompany
  place_id = ''
  formatted_address = ''
  lng = 0 
  lat = 0
  constructor(
    private router: Router,
    private _personService: PersonService,
    private route: ActivatedRoute,
    private _toasterCustomService: ToasterCustomService
  ) { }
  ngOnInit() {
    this.isUpdated = false
    this.defaultValue = {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['customer'].success) {
        this.customerModel = response['customer'].data
        this.isUpdated = true
        this.defaultValue = this.customerModel.country
      }
      else {
        this.customerModel = {}
        this.isUpdated = false
      }
    }, error => {
    })
  }
  onCountrySelected(country: any) {
    this.customerModel.country = country
  }
  updateCustomer() {
    this.customerModel.name = this.customerModel.lastName + ', ' + this.customerModel.firstName
    this._personService.updatePerson(this.customerModel, this.customerModel.id,UpdateInfo.All).subscribe((response: ResponseModel) => {
      if (this.user.role == "admin" || this.user.role == "manager") {
        this.router.navigate(['admin/customers-list'])
      }
      else if (this.user.role == "teamleader" || this.user.role == "crew") {
        this.router.navigate(['user/customers-list'])
      }
      this._toasterCustomService.success('','Information Successfully Saved')
    }, error => {
      console.log(error)
    })
  }
  isEmailExist() {
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      if (this.customerModel.email == '') {
        this.isRequired = true
        this.isNotEmail = false
      }
      else {
        this.isRequired = false
        let re = new RegExp(this.emailPattern)
        if (re.test(this.customerModel.email)) {
          this.isNotEmail = false
        }
        else {
          this.isNotEmail = true
        }
      }
      if (this.customerModel.email) {
        let model = {
          email: this.getEmailWithoutSpace(this.customerModel.email),
          companyId: this.user.companyId
        }
        this._personService.isEmailInPersonAndInPendingExists(model).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExists = false
          }
          else {
            this.isExists = true
          }
        })
      }
      else {
        this.isExists = false
        return null
      }
    },2000)
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       let model = this.getAddressFromComponents(place.address_components)
       this.customerModel.addressOne = model.addressOne
       this.customerModel.addressTow = model.addressTow
       this.customerModel.city = model.city
       this.customerModel.state = model.state
       this.customerModel.zip = model.zip
       this.customerModel.lat = place.geometry.location.lat()
       this.customerModel.lng = place.geometry.location.lng()
       this.customerModel.formatted_address = place.formatted_address
       this.customerModel.place_id = place.place_id
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      // country: countryName,
      zip: zip
    };
  }
}

