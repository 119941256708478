<div class="supplies-section" id="supply">
    <div class="tittle-section">
        <p><i class="fab fa-stripe-s"></i> Supplies </p>
        <button class="btn bnt-success" (click)="addSupplyModal.show() ">
          <i class="far fa-plus-square"></i> Add New
       </button>
    </div>
    <div>
        <div class="ant-empty-image" *ngIf="supplies.length <= 0 ">
            <img src="./assets/img/empty.svg" alt="empty-img">
            <span>No Data</span>
        </div>
        <mat-accordion class="example-headers-align " multi *ngFor="let item of supplies; let i= index">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    {{item.materialName}}
                    <div class="title-info">
                        <span>Quantity: {{item.quantity}} || Total: 00000</span>
                    </div>
                </mat-expansion-panel-header>
                <div class="card">
                    <div>
                        <div class="title-card ">
                            <!-- <button class="btn btn-success" (click)="isEditable(item);editContainerModal.show()"><i class="fas fa-edit"></i> Edit</button> -->
                            <button class="btn btn-danger" (click)="isEditable(item);deleteSupplyModal.show()"><i class="fas fa-trash-alt pr-1"></i>Delete</button>
                            <button class="btn btn-primary" (click)="print()"><i class="fas fa-save pr-1"></i> Print Tag</button>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <div class="item">
                                    <h6> Id</h6>
                                    <label>{{item.id}}</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item">
                                    <h6> Name</h6>
                                    <label>{{item.materialName}}</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item">
                                    <h6>Handled By </h6>
                                    <label>{{item.handledBy}}</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item">
                                    <h6>Category </h6>
                                    <label>{{item.categoryName}}</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item">
                                    <h6> Quantity </h6>
                                    <label>{{item.quantity}}</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item">
                                    <h6> Price </h6>
                                    <label>{{item.price}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<!-- Add Supply -->
<div mdbModal #addSupplyModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New Supply</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addSupplyModal.hide()">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body">
                    <form #SupplyForm="ngForm" id="SupplyForm">
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text " required placeholder="Material Name " name="materialName" [(ngModel)]="supplyModel.materialName " class="form-control " />
                            </div>
                            <div class="col-sm-6">
                                <input type="text " required placeholder="HandledBy(Names)" name="handledBy" [(ngModel)]="supplyModel.handledBy " class="form-control " />
                            </div>
                            <div class="col-sm-6">
                                <input type="text " required placeholder="Category Name " name="categoryName" [(ngModel)]="supplyModel.categoryName " class="form-control " />
                            </div>
                            <div class="col-sm-6">
                                <input type="text " required placeholder="Quantity " name="quantity" [(ngModel)]="supplyModel.quantity " class="form-control " />
                            </div>
                            <div class="col-sm-6">
                                <input type="text " required placeholder="Price " name="price" [(ngModel)]="supplyModel.price " class="form-control " />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="addSupplyModal.hide()">Close</button>
                    <button type="submit " class="btn btn-success " form="SupplyForm " [disabled]="!SupplyForm.dirty " (click)="addSupply(SupplyForm);addSupplyModal.hide() ">Add </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Supply -->
<!--  Delete Container Modal  -->
<div mdbModal #deleteSupplyModal="mdbModal" class="modal fade">
    <div class="modal-dialog">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteSupplyModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <p>Do you want to remove a Supply ?</p>
                    <p class="text-secondary"><small>If you don't remove, your changes will be lost.</small></p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-primary " data-dismiss="modal " (click)="deleteSupplyModal.hide() ">Close</button>
                    <button type="button " class="btn btn-danger " (click)="deleteSupply();deleteSupplyModal.hide() ">Delete
         </button>
                </div>
            </div>
        </div>
    </div>
</div>