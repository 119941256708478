<!-- <ngx-spinner bdColor="rgba(0,0,0,0.61)" size="medium" color="#4481eb" type="pacman" [fullScreen]="true">
</ngx-spinner> -->

<div class="card">
    <div class="tittle-profile">
        <h6>Member Profile</h6>
    </div>
    <!-- <div class="jobs-invite">
        <div class="item" [routerLink]="['/admin']">
            <p> Jobs</p>
        </div>
        <div class="item" [routerLink]="['/admin/invite']">
            <p> Invite User</p>
        </div>
    </div> -->
    <!-- <div class="card-title">
        <h6>User Details</h6>
        <div class="status">
            <label for="stauts">Status:</label>
            <select class="browser-default custom-select">
              <option selected>Disable</option>
              <option value="1">Admin</option>
              <option value="1">Manager</option>
              <option value="1">Team Leader</option>
              <option value="1">3Rd Party</option>
              <option value="1">Customer</option>
           </select>
        </div>
    </div> -->
    <!-- <div class="container mt-4 mb-5">
        <iframe width="100%" height="300" frameborder="0" scrolling="no" src="https://maps.google.co.uk/maps?f=q&source=s_q&hl=en&geocode=&q=15+Springfield+Way,+Hythe,+CT21+5SH&aq=t&sll=52.8382,-2.327815&sspn=8.047465,13.666992&ie=UTF8&hq=&hnear=15+Springfield+Way,+Hythe+CT21+5SH,+United+Kingdom&t=m&z=14&ll=51.077429,1.121722&output=embed"></iframe>
    </div> -->
    <form [formGroup]="userFormGroup">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="firstName"> First Name <span class="required">*</span> :</label>
                    <input type="text" id="firstName" formControlName="firstName" class="form-control" [ngClass]="{'is-invalid' : f.firstName.errors && f.firstName.touched}" />
                    <div *ngIf="f.firstName.touched && f.firstName.invalid" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="lastName"> Last Name <span class="required">*</span> :</label>
                    <input type="text" id="lastName" formControlName="lastName" class="form-control" [ngClass]="{'is-invalid' : f.lastName.errors && f.lastName.touched}" />
                    <div *ngIf="f.lastName.touched && f.lastName.invalid" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="email"> Email <span class="required">*</span> :</label>
                    <input type="email" id="email" formControlName="email" class="form-control" readonly />
                    <!-- <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="userType"> Member Type <span class="required">*</span> :</label>
                    <input type="text" id="userType" formControlName="customPersonType" class="form-control" [ngClass]="{'is-invalid' : f.customPersonType.errors && f.customPersonType.touched}" />
                    <div *ngIf="f.customPersonType.touched && f.customPersonType.invalid" class="invalid-feedback">
                        <div *ngIf="f.customPersonType.errors.required">Member Type is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="phone"> Phone <span class="required">*</span> :</label>
                    <input type="text" id="phone" formControlName="phone" class="form-control" [ngClass]="{'is-invalid' : f.phone.errors && f.phone.touched}" />
                    <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Phone is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="adddraddressOneessOne"> Address 1 <span class="required">*</span> :</label>
                    <input type="text" id="adddressOne" formControlName="addressOne" #addressText class="form-control" [ngClass]="{'is-invalid' : f.addressOne.errors && f.addressOne.touched}" />
                    <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="invalid-feedback">
                        <div *ngIf="f.addressOne.errors.required">Address One is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="addressTow"> Address 2 :</label>
                    <input type="text" id="addressTow" formControlName="addressTow" class="form-control" />
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="city"> City <span class="required">*</span> :</label>
                    <input type="text" id="city" formControlName="city" class="form-control" [ngClass]="{'is-invalid' : f.city.errors && f.city.touched}" />
                    <div *ngIf="f.city.touched && f.city.invalid" class="invalid-feedback">
                        <div *ngIf="f.city.errors.required">City is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="state"> State <span class="required">*</span> :</label>
                    <input type="text" id="state" formControlName="state" class="form-control" [ngClass]="{'is-invalid' : f.state.errors && f.state.touched}" />
                    <div *ngIf="f.state.touched && f.state.invalid" class="invalid-feedback">
                        <div *ngIf="f.state.errors.required">State is required</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6  col-sm-12">
                <div class="form-group">
                    <label for="zip"> Zip Code <span class="required">*</span> :</label>
                    <input type="text" id="zip" formControlName="zip" class="form-control" [ngClass]="{'is-invalid' : f.zip.errors && f.zip.touched}" />
                    <div *ngIf="f.zip.touched && f.zip.invalid" class="invalid-feedback">
                        <div *ngIf="f.zip.errors.required">Zip Code is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="country"> Country <span class="required">*</span> :</label>
                    <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                    </mat-select-country>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-sm-12">
                <div class="form-group">
                    <label for="currency"> Currency :</label>
                    <select class="form-control" formControlName="currency" [ngClass]="{'is-invalid' : f.currency.errors && f.currency.touched}">
                      <option *ngFor="let currency of Currencies"> {{currency}} </option>
                   </select>
                    <div *ngIf="f.currency.touched && f.currency.invalid" class="invalid-feedback">
                        <div *ngIf="f.currency.errors.required">Currency is required</div>
                    </div>
                </div>
            </div> -->
            <div class="button-group">
                <button class="btn" color="success" [disabled]="userFormGroup.invalid" (click)="basicModal.show()">
                     <i class="far fa-save"></i><span>Save</span> </button>
            </div>
        </div>
    </form>
</div>
<!-- Modal -->
<div mdbModal #basicModal="mdbModal" class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <p>Save changes?</p>
                    <!-- <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="basicModal.hide()">Close</button>
                    <button type="button" class="btn btn-sm btn-success" (click)="updateUser();basicModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>