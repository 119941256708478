import { ToasterCustomService } from './../../../../../core/_services/toaster-service/ToasterCustomService.service';
import { CarWashService } from './../../../../../core/_services/car-wash-service/car-wash.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';
import { environment } from 'src/environments/environment.prod';
import { EnumTemplates } from '../../car-wash-folder/Enum-Template/Enum-Templates';
import { PaymentType } from '../../car-wash-folder/Enum/PaymentType';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import * as moment from 'moment';
import { CompanyPlan } from 'src/app/models/company/company';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ActionsCar } from '../../car-wash-folder/Enum/ActionsCar';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { HistoryCarWash } from '../../car-wash-folder/Enum/HistoryCarWash';
import { HistoryCarWashNames } from '../../car-wash-folder/Enum/HistoryCarWashNames';
import { QueueHistory } from '../../car-wash-folder/Enum/QueueHistory';
import { SMSNotification } from 'src/app/models/tools-model/SmsNotification';
import { AssignType } from '../../car-wash-folder/Enum/AssignType';
import { Capacitor } from '@capacitor/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';
import { WebcamInitError } from 'ngx-webcam';

@Component({
  selector: 'app-car-wash',
  templateUrl: './car-wash.component.html',
  styleUrls: ['./car-wash.component.scss']
})
export class CarWashComponent implements OnInit {
  @ViewChild('editVehicleModal') editVehicleModal: ModalDirective;
  @ViewChild('qrPrintModal') qrPrintModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
  @ViewChild('serviceStatisticModal') serviceStatisticModal: ModalDirective;
  @ViewChild('assignModal') assignModal: ModalDirective;
  @ViewChild('reNewMembershipModal') reNewMembershipModal: ModalDirective;
  @ViewChild('deniedModal') deniedModal: ModalDirective;
  @ViewChild('deleteVehicleModal') deleteVehicleModal: ModalDirective;
  @ViewChild('noPhoneModal') noPhoneModal: ModalDirective;
  @ViewChild('notSendMessageModal') notSendMessageModal: ModalDirective;
  @ViewChild('sendMessageModal') sendMessageModal: ModalDirective;
  @ViewChild('cameraScanModal') cameraScanModal: ModalDirective;
  baseUrl = environment.baseDomain
  baseWordpressUrl = environment.baseWordpressUrl
  isLinear = false;
  jobsTools:any={}
  othersTools:any={}
  items:any[]=[]
  user:any={}
  userPermission:any={}
  statusActive: any = StatusActive
  vehicles:any[]=[]
  p:number= 1
  p4:number = 1
  p6 :number= 1
  searchText =''
  step;
  isCheckIn = false
  vehicleId:string =''
  customerId:string =''
  vehicle:any={}
  vehicleForm: FormGroup
  linkQrCode:any
  companyInfo: any = {}
  jobId:string
  templateType = EnumTemplates.Template_One
  enumTemplates: any = EnumTemplates
  qrCodes:any=[]
  paymentTypeEnum = PaymentType
  countMemberShip = 0
  isScan = false
  itemsPerPage  = 3
  buffVehicleId = ''
  yearStatistic1: any
  statisticYearVehicles: any = {}
  companyPlan = CompanyPlan
  assignVehicles:any =[]
  buffVehicle:any = {}
  carWashServices:any=[]
  additionalServices:any=[]
  usersToAssigned:any = []
  servicesSelected :any = []
  servicesOthersSelected :any = []
  totalDuNormal: number = 0
  totalDueOthers: number = 0
  receivedAmount = 0
  isDisabled: boolean = false;
  selectedUsers :any =[]
  servicesObj:any ={}
  bufferVehicle: any = {};
  assignedVehicles:any =[]
  actionsCar = ActionsCar
  isDenied = true
  customerObj: any = {}
  selectedPackageIndex1
  histories:any=[]
  historyCarWash = HistoryCarWash
  assignType = AssignType
  queueHistory = QueueHistory
  smsType :any
  historyCarWashNames = HistoryCarWashNames
  smsNotification = SMSNotification
  jobModel:any = {}
  historiesDetailing :any = []
  selectedIndex = 2
  jobObj:any={}
  isMobile = false
  public showWebcam = false;
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: any = null;
  public errors: WebcamInitError[] = [];
  constructor(
    private  route:ActivatedRoute,
    private  _toolsService:ToolsService,
    private  _jobsService:JobsService,
    private  _carWashService:CarWashService,
    private  router:Router,
    public datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private socket: Socket,
    private _toaster: ToasterCustomService,
    private _personService: PersonService,
    private _globalService:GlobalService,
    private deviceService: DeviceDetectorService,

  ) { }
  ngOnInit() {
    (Capacitor.isNativePlatform() ||  !this.deviceService.isDesktop())? this.isMobile = true : this.isMobile = false
    this.yearStatistic1 = this.datePipe.transform(new Date(), 'y')
    this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
    localStorage.setItem('isAssignedQR','false')
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.user = JSON.parse(localStorage.getItem('user'))
    this.createAddVehicleForm()
    this.linkQrCode =  this.baseWordpressUrl
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        this.othersTools = response['tools'].data.find(t => t.toolType == ToolType.Setting_Tools)
        if (this.user.role == 'admin' || this.user.role == 'manager' || this.user.role == 'teamleader' || this.user.role == 'crew') {
          this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
              this.customerObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.Customers_list, ToolType.Setting_Tools, permissionList)
            }
          })
        }
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        this.jobObj =  response['jobs'].data
        this.vehicle =  this.jobObj.jobVehicleObj ??  this.vehicle
        this.assignedVehicles =  response['jobs'].data.assignedVehicles ?? this.assignedVehicles
        if (this.user.role == "admin") {
          this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.Assigned_Vehicle)
          if(this.userPermission.itemStatus == 'Active') {
            this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
            console.log( this.userPermission)
            // console.log('userPermission from admin',this.userPermission)
          }
          this.getVehicles()
        }
        if (this.user.role != "admin" && this.user.role != "manager") {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.Assigned_Vehicle, this.user.id)
          // console.log('this is not admin and manager',this.userPermission)
          if (this.userPermission?.value != this.statusActive.InActive) {
            this.getVehicles()
          }
          }
        if (this.user.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.Assigned_Vehicle, this.user.id)
          if (this.userPermission?.value != this.statusActive.InActive) {
            this.getVehicles()
          }
        }
      }
     }
    }, error => {
      console.log(error)
    })
    this. getCompanyAddress()
  //  Vehicle_Check_In Check_Out and Subscription Realtime
    this.socket.on(RealTimeKeys.Vehicle_Assigned, (data: any) => {
    if (this.user.companyId == data.companyId && this.jobId ==  data.id) {
      this._jobsService.getJobById(data.id).subscribe((response: ResponseModel) => {
        if (response.success) {
        this.assignedVehicles = response.data.assignedVehicles ?? this.assignedVehicles
        let index =  this.vehicles.findIndex(c => c.id == data.vehicleId)
        if(index != -1 && response.data.jobVehicleObj){
        this.vehicles[index] = response.data.jobVehicleObj
        this.getServices(response.data.jobVehicleObj,false)
        }
        this.assignVehicles = this.vehicles.filter((x: any) => this.assignedVehicles.includes(x.id))
          // this._carWashService.getVehicleById(data.vehicleId).subscribe((response: ResponseModel) => {
          // })
        }
      })
    }
    })
    // this.socket.on(RealTimeKeys.Vehicle_Check_Out, (data: any) => {
    //   if (this.user.companyId == data.companyId ) {
    //     this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
    //       if (response.success) {
    //         let index =  this.vehicles.findIndex(c => c.id == data.id)
    //         if(index != -1){
    //         this.vehicles[index] = response.data
    //         }}
    //     })}
    // })
    this.socket.on(RealTimeKeys.Vehicle_Change_Subscription, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId ==  data.id ) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index =  this.vehicles.findIndex(c => c.id == data.id)
            if(index != -1){
            this.vehicles[index] = response.data
            } }
        })}
    })
    this.socket.on(RealTimeKeys.Vehicle_Delete, (data: any) => {
      if (this.user.companyId == data.companyId) {
        let index =  this.vehicles.findIndex(c => c.id == data.id)
        if (index > -1) {
          this.vehicles.splice(index, 1);
        }
      }
    });
    this.socket.on(RealTimeKeys.Service_Assigned, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId ==  data.id) {
        this._jobsService.getJobById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.jobObj = response.data
            this.vehicle =  this.jobObj.jobVehicleObj ??  this.vehicle
            this.assignedVehicles =  this.jobObj.assignedVehicles ?? this.assignedVehicles
            this.assignVehicles = this.vehicles.filter((x: any) => this.assignedVehicles.includes(x.id))
          }
        })
      }
    })
  }
  getCompanyAddress() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyInfo = {
      companyName: company.companyName,
      companyPhone: this.user.companyPhone,
      companyWebsite: this.user.companyWebsite,
      companyAddress: company.companyAddress
    }
  }
  checkItemPlan() {
    if (this._jobsService.getToolPlan(this.items, ToolJobsEditItem.Assigned_Vehicle) > this.user.companyPlan) {
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
getVehicles(){
  this._carWashService.getVehiclesByJob(this.user.companyId,this.jobId).subscribe((response:ResponseModel)=>{
    if(response.success){
      // console.log(response.data)
       this.vehicles = response.data.vehicles
       this.assignVehicles = this.vehicles.filter((x: any) => this.assignedVehicles.includes(x.id))
       console.log(this.assignVehicles[0])
      // console.log(assignedBuffer)
      // assignedBuffer ?  this.assignedVehicle = this.assignedVehicles[0] : this.assignedVehicle = null
      this.countMemberShip = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == true ).length
      this.checkItemPlan()
    }
  })
}
isCheckInVehicle(id:string){
  let vehicle = this.vehicles.find(v => v.id == id)
  if(vehicle.CheckInObject.length <= 0){
    return false
  }
  else{
    let isCheckIn = vehicle.CheckInObject.find(c => c.isCheckIn == true)
    if(isCheckIn != undefined || isCheckIn != null){
      return true
    }
    else{
      return false
    }
  }
}
createPortalCustomerStripeCustom(vehicle: any) {
  let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
  let history = {
   // id : this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
   userId : this.user.id,
   branchId : this.user.companyId,
   addedBy : this.user.displayName,
   branchName:currentCompany.companyName,
   dateHistory :new Date(),
   timeHistory : this.datePipe.transform(new Date(),'H:mm'),
 }
  this._carWashService.createPortalCustomerStripeCustom(vehicle.id,vehicle.paymentType,history).subscribe((response: ResponseModel) => {
    if (response.success) {
      // window.open(response.data)
      window.location.href = response.data
    }
  })
}
checkIn(vehicleId:string){
  let todayDate = new Date()
  let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
  let model ={
    date :  (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
    time :this.datePipe.transform(todayDate,'H:mm'),
    companyId : this.user.companyId,
    addedBy : this.user.displayName,
    userId : this.user.id,
    companyName:currentCompany.companyName,
  }
this._carWashService.updateCheckInVehicleInJob(vehicleId,this.jobId,model).subscribe((response:ResponseModel)=>{
  if(response.success){
    let index = this.vehicles.findIndex(v => v.id == vehicleId)
    this.vehicles[index] = response.data
  }
})
}
checkOut(vehicleId:string){
  let todayDate = new Date()
  let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
  let model ={
    date :  (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
    time :this.datePipe.transform(todayDate,'H:mm'),
    companyId : this.user.companyId,
    addedBy : this.user.displayName,
    userId : this.user.id,
    companyName:currentCompany.companyName,
  }
  this._carWashService.updateCheckOutVehicleInJob(vehicleId,this.jobId,model).subscribe((response:ResponseModel)=>{
    if(response.success){
      let index = this.vehicles.findIndex(v => v.id == vehicleId)
      this.vehicles[index] = response.data
    }
  })
}
redirection() {
  localStorage.setItem('isAssignedQR','true')
  localStorage.setItem('isDetailing','true')
  localStorage.removeItem('renewMembership')
  if (this.user.role == 'admin' || this.user.role == 'manager') {
    this.router.navigate(['/admin/add-vehicle'])
  }
  else {
    this.router.navigate(['/user/add-vehicle'])
  }
}
renewMembership(vehicle: any) {
  localStorage.setItem('renewMembership', 'true')
  localStorage.setItem('paymentType',vehicle.paymentType)
  if (this.user.role == 'manager' || this.user.role == 'admin') {
    this.router.navigate(['/admin/products-strip', vehicle.id])
  }
  else {
    this.router.navigate(['/user/products-strip', vehicle.id])
  }
}
handlingMembersPagination(event){
  this.step =''
  this.p = event
}
createMemberShip(vehicle:any){
  localStorage.setItem('type',String(vehicle.paymentType))
  localStorage.setItem('selectedIndex',String(0))
  if(this.user.role == 'admin' || this.user.role == 'manager'){
    this.router.navigate(['/admin/complete-membership/', vehicle.id])
  }
  else{
    this.router.navigate(['/user/complete-membership/', vehicle.id])
  }
}
setStep(index: number) {
  this.step = index;
}
restPagination(event) {
  this.p = 1
}
// searchQR() {
//   if(Capacitor.isNativePlatform()) {
//     this.isScan = true
//     this.printScanMobile.show()
//   } else {
//     localStorage.setItem('search', 'true_job')
//     this.router.navigate(['/qr-scanner'])
//   }
// }
// Edit
createAddVehicleForm() {
  this.vehicleForm = this._formBuilder.group({
    vehicleType: ['', Validators.required],
    plateNumber: ['', [Validators.required]],
    vehicleModel: [''],
    color: [''],
    condition: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
  });
}
get f() {
  return this.vehicleForm.controls;
}
storeVehicle(vehicle: any) {
  this.vehicleId = vehicle.id
  this.customerId = vehicle.customerId
  this.vehicle = vehicle
  // this.router.navigate(['/user/complete-membership/', vehicle.id])
  localStorage.setItem('type',String(this.vehicle.paymentType))
  if(this.user.role == 'admin' || this.user.role == 'manager'){
    this.router.navigate(['/admin/complete-membership/', vehicle.id])
  }
  else{
    this.router.navigate(['/user/complete-membership/', vehicle.id])
  }
  // if (vehicle.qrCodeId != "") {
  //   if (vehicle.customer != null) {
  //     this.vehicleForm.setValue({
  //       vehicleType: vehicle.vehicleType,
  //       plateNumber: vehicle.plateNumber,
  //       vehicleModel: vehicle.vehicleModel,
  //       color: vehicle.color,
  //       condition: vehicle.condition,
  //       firstName: vehicle.customer.firstName,
  //       lastName: vehicle.customer.lastName
  //     })
  //   }
  //   else {
  //     this.vehicleForm.setValue({
  //       vehicleType: vehicle.vehicleType,
  //       plateNumber: vehicle.plateNumber,
  //       vehicleModel: vehicle.vehicleModel,
  //       color: vehicle.color,
  //       condition: vehicle.condition,
  //       firstName: '',
  //       lastName: ''
  //     })
  //   }
  // }
  // else {
  //   this.router.navigate(['/user/complete-membership/', vehicle.id])
  // }
}
getVehicleStaticsYear(vehicleId: string) {
  this.vehicleId = vehicleId
  this._carWashService.getVehiclesStaticsYearInJob(vehicleId,this.jobId ,this.yearStatistic1).subscribe((response: ResponseModel) => {
    if (response.success) {
      // console.log(response)
      this.statisticYearVehicles = response.data
      this.serviceStatisticModal.show()
    }
  })
}
getProductName(item: any, product: any) {
  return item['count' + product]
}
getTotalName(i: number) {
  return this.statisticYearVehicles.countColumn['AllCount' + this.statisticYearVehicles.products[i]]
}
previousYearStatistic() {
  this.yearStatistic1 = moment(this.yearStatistic1).subtract(1, 'year').format('YYYY').toString();
  this.getVehicleStaticsYearGlobal(this.vehicleId)
}
nextYearStatistic() {
  this.yearStatistic1 = moment(this.yearStatistic1).add(1, 'year').format('YYYY').toString();
  this.getVehicleStaticsYearGlobal(this.vehicleId)
}
getVehicleStaticsYearGlobal(vehicleId: string) {
  this._carWashService.getVehicleStaticsYear(vehicleId, this.yearStatistic1).subscribe((response: ResponseModel) => {
    if (response.success) {
      // console.log(response)
      this.statisticYearVehicles = response.data
    }
  })
}
updateCustomerInformation() {
  let item = this.vehicleForm.value
  let model = {
    firstName: item.firstName,
    lastName: item.lastName,
  }
  this._carWashService.updateCustomerVehicle(this.customerId, model).subscribe((response: ResponseModel) => {
    if (response.success) {
      let index = this.vehicles.findIndex(v => v.id == this.vehicleId)
      this.vehicles[index].customer.firstName = response.data.firstName
      this.vehicles[index].customer.lastName = response.data.lastName
      this.editVehicleModal.hide()
    }
  })
}
updateVehicleInformation() {
  let item = this.vehicleForm.value
  let model = {
    vehicleType: item.vehicleType,
    plateNumber: item.plateNumber,
    vehicleModel: item.vehicleModel,
    // color: item.color,
    condition: item.condition,
  }
  console.log(model)
  this._carWashService.updateVehicleInJob(this.vehicleId,this.jobId, model).subscribe((response: ResponseModel) => {
    // console.log(response)
    if (response.success) {
      this.setValueVehicle(response.data)
      this.editVehicleModal.hide()
    }
  })
}
setValueVehicle(newVehicle: any) {
  let index = this.vehicles.findIndex(v => v.id == this.vehicleId)
  this.vehicles[index].vehicleType = newVehicle.vehicleType
  this.vehicles[index].plateNumber = newVehicle.plateNumber
  this.vehicles[index].vehicleModel = newVehicle.vehicleModel
  // this.vehicles[index].color = newVehicle.color
  this.vehicles[index].condition = newVehicle.condition
}
// re-print-QRcode
// rePrintRQCode(qrCodeId:string)
// {
//   if(Capacitor.isNativePlatform()) {
//     this.isScan = false
//     this.printScanMobile.show()
//   } else {
//     this.linkQrCode =  this.baseUrl + 'med-route-qr/' + qrCodeId
//     this._qrService.getQrCodeById(qrCodeId).subscribe((response:ResponseModel)=>{
//       if(response.success){
//         this.templateType = response.data.template
//         this.qrCodes.push({
//           id:response.data.id,
//           isAssigned : response.data.isAssigned
//         })
//         this.printFixed()
//       }
//     })
//   }
// }
timeConverter(UNIX_timestamp) {
  return new Date(UNIX_timestamp * 1000)
}

 printFixed() {
  let  printContents = document.getElementById('qr_codes_Id').innerHTML;
   let popupWin = window.open('', 'top=0,left=0,height=100%,width=auto');
   popupWin.document.open();
  //  if (this.deviceService.isDesktop()) {
     popupWin.document.write(`
     <html>
       <head>
         <title>print</title>
         <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
         <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
         <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
         <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
         <style>
         body {
          text-align : center;
          display: inline-flex;
          justify-content: space-between !important;
          flex-wrap: wrap;
          margin-top:0 !important;
          align-items: center;
        }
        .row{
          width:384px !important
          height:192px !important
          padding: 0
        }
        .col-6{
          display: flex;
          align-items: center;
        }
        .full-item {
          display: flex;
          justify-content: left !important;
          margin: 2.6rem  auto ;
          width:384px ;!important
          height:192px; !important
        }
        .last-item{
          margin-top:1rem !important
        }
        .full-item .info{
          direction: rtl !important;
        }
        .info-details  p {
          font-size: 15px;
          margin: 0;
          font-weight:700
          -webkit-transform: scaleX(-1);
          }
        .qr-img  {
          display: block;
          text-align : left
        }
        .qr-img  img{
          width:165px !important
          height:165px !important
          -webkit-transform: scaleX(-1);
        }
        .qr-img  span {
          padding-left: 5px;
          font-size: 7px;
          }
          .info {
            display: flex;
            justify-content: left !important;
            align-items: center;
            text-align:left !important;
        }
        .back{
          text-align:center;
        }
      img{
        -webkit-transform: scaleX(-1) !important;
      }
        @media print {
          .pagebreak  {
              page-break-before: always;
          }
          .btn-sm{
            display:none;
          }
          }
         </style>
       </head>
       <body onload="window.print()">
       <div class="back">
       <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
       </div>
       ${printContents}</body>
     </html>`
     );
     popupWin.document.close();

 }
 storeVehicleId(vehicleId:string){
  this.buffVehicleId = vehicleId
}
deleteVehicle(vehicleId:string){
  this._carWashService.deleteVehicleInJob(this.buffVehicle.id,this.jobId).subscribe((response:ResponseModel)=>{
   if(response.success){
     this._toaster.success('','Successfully delete vehicle')
   }
   else{
     this._toaster.error('','Error delete vehicle')
   }
  })
 }
 renewForce(vehicleId:string){
  this._carWashService.renewUpdateForceInJob(this.buffVehicle.id,this.jobId).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.renewMembership(response.data)
    }
    else{
    }
   })
}
assignVehicle(vehicle: any) {
  let model = {
   vehicleId: vehicle.id,
   isAssigned: true,
   history : this.getHistoryObj(AssignType.Assign)
  }

  this._carWashService.jobVehicleAssigned(this.jobId,model).subscribe((response: ResponseModel) => {
    console.log(response)
  }, error => {
    console.log(error)
  })
}
getHistoryObj(event:any){
  let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
  let model = {
    id : this.buffVehicle?.historiesDetailing ? this.buffVehicle?.historiesDetailing.length + 1 : 2,
    userId : this.user.id,
    branchId : this.user.companyId,
    addedBy : this.user.displayName,
    branchName:currentCompany.companyName,
    dateHistory : new Date(),
    timeHistory : this.datePipe.transform(new Date(),'H:mm'),
    key: event,
  }
  return model
}
UnAssignVehicle(vehicle: any) {
  let model = {
   vehicleId: vehicle.id,
   isAssigned: false,
   history : this.getHistoryObj(AssignType.UnAssign)
  }
  this._carWashService.jobVehicleAssigned(this.jobId,model).subscribe((response: ResponseModel) => {
    console.log(response)
  }, error => {
    console.log(error)
  })
}
getServices(vehicle:any,isOpen:boolean){
  this.buffVehicle = vehicle
  this._carWashService.getServices(this.user.companyId).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.carWashServices = response.data.normalServices
      this.additionalServices = response.data.otherServices
       this._personService.getUsersByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
        if(response.success){
          response.data = response.data.filter(r =>r.role != 'admin')
          this.usersToAssigned = response.data.map(s => ({name: s.name,id:s.id}))
          console.log(this.usersToAssigned)
          if(this.jobObj.jobVehicleServiceObj){
            let  servicesObj = JSON.parse(JSON.stringify(this.jobObj.jobVehicleServiceObj.servicesObj))
            this.servicesSelected = servicesObj.servicesNormalSelected
            this.servicesOthersSelected = servicesObj.servicesOthersSelected
            this.selectedUsers = servicesObj.usersSelected
            this.receivedAmount = servicesObj.receivedAmount
            this.totalDuNormal = servicesObj.totalDu
            this.totalDueOthers = servicesObj.totalDueOthers
          }
          isOpen  ? this.assignModal.show() : ''
        }
      })
    }
  })
}
resetServices(){
  this.servicesSelected = []
  this.carWashServices = []
  this.additionalServices = []
  this.usersToAssigned = []
  this.servicesOthersSelected =[]
  this.selectedUsers =[]
  this.receivedAmount = 0
  this.totalDuNormal = 0
  this.totalDueOthers = 0
  this.isDisabled = true
}
equalsUsers(objOne, objTwo) {
  if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
    return objOne.id === objTwo.id;
  }
}
isChecked(service:any){
  let isChecked = false
  if(this.servicesSelected.length> 0){
    let item = this.servicesSelected.find(s => s.id == service.id )
    item ? isChecked = true : isChecked = false
  }
  return isChecked
}
isCheckedOther(service){
  let isChecked = false
  if(this.servicesOthersSelected.length> 0){
    let item = this.servicesOthersSelected.find(s => s.id == service.id)
    item ? isChecked = true : isChecked = false
  }
  return isChecked
}
handleChange(event,service){
  console.log(service)
  if(event.target.checked){
    this.servicesSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      time: service.time,
      date: service.date,
      price: 0,
      size: '' ,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    // this.totalDuNormal  += 0
  }
  else{
    let index = this.servicesSelected.findIndex(s =>s.serviceName == service.serviceName)
    // this.totalDuNormal  -= this.servicesSelected[index].price
    this.servicesSelected.splice(index,1)
  }
  console.log(this.servicesSelected)
  this.getSummary()
  this.isCheckedSize(service)
}
getSummary(){
  let sum: number = 0;
  if(this.servicesSelected.length > 0) {
    this.servicesSelected.forEach(a => sum += a.price);
    this.totalDuNormal = sum > 0  ? sum : 0
  }
  else{
    this.totalDuNormal = 0
  }
  // let results = this.servicesSelected.concat(this.servicesOthersSelected);
  // console.log(results)
}
isCheckedSize(service:any){
  let size = ''
  if(this.servicesSelected.length> 0){
    let itemBuff = this.servicesSelected.find(s => s.id == service.id)
    if(itemBuff){
      size =  itemBuff.size
    }
  }
  return size
}
isCheckedSizePrice(service:any){
  let itemBuff
  if(this.servicesSelected.length> 0){
     itemBuff = this.servicesSelected.find(s => s.id == service.id)
  }
  return itemBuff
}
  getCurrency(){
  return this._globalService.getCurrency()
}
getDateAssigned(vehicle:any){
  let buffVehicle :any = {}
  if(vehicle?.servicesDetailing && vehicle.servicesDetailing.length > 0) {
     buffVehicle =  vehicle.servicesDetailing.find(v =>v.jobId == this.jobId)
     buffVehicle = buffVehicle ? buffVehicle.servicesObj : {}
  }
  return buffVehicle.date
}
selectedNormalService(event,item:any,service,i){
  let index = this.servicesSelected.findIndex(s =>s.id == service.id)
  if(index != -1 ){
  this.servicesSelected[index].price = Number(item['price'+i])
  this.servicesSelected[index].size =  item['size'+i]
  // this.totalDuNormal  += this.servicesSelected[index].price
  }
  else{
    // let event ={target:{checked:true}
    // }
    this.servicesSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      time: service.time,
      date: service.date,
      price: Number(item['price'+i]),
      size: item['size'+i] ,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    this.isChecked(service)
    // this.handleChange(event,service,i)
  }
  console.log(this.servicesSelected)
  this.getSummary()
}
selectedOtherService(event,service:any){
  console.log(service)
  if(event.target.checked){
    this.servicesOthersSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      price: service.price,
      time: service.time,
      date: service.date,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    this.totalDueOthers  += service.price
    this.totalDueOthers > 0 ? this.totalDueOthers  : 0
  }
  else{
    let index = this.servicesOthersSelected.findIndex(s =>s.id == service.id)
    this.servicesOthersSelected.splice(index,1)
    this.totalDueOthers  -= service.price
    this.totalDueOthers > 0 ? this.totalDueOthers  : 0
  }
  console.log(this.servicesOthersSelected)
}
changeReceivedAmount(event){
  this.receivedAmount =  Number(event)
}
getCashBack(){
  (this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)) < 0 ? this.isDisabled = true : this.isDisabled = false
  return this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)
}
assignedService(){
  let model = {
    servicesNormalSelected: this.servicesSelected,
    servicesOthersSelected: this.servicesOthersSelected,
    usersSelected:this.selectedUsers,
    totalDu: this.totalDuNormal ,
    receivedAmount: this.receivedAmount,
    cashBack:this.getCashBack(),
    totalDueOthers:this.totalDueOthers,
    date: new Date()
   }
  this._carWashService.assignedJobService(this.buffVehicle.id,this.jobId,model).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.resetServices()
    }
  })
}
checkServiceName(service){
  if(this.servicesSelected.length> 0){
  let itemBuff = this.servicesSelected.find(s => s.id == service.id)
    if(itemBuff){
      if(itemBuff.serviceName != service.serviceName){
        return itemBuff.serviceName
      }
    }
  }
}
isCheckAssign(vehicle){
  // console.log(this.assignedVehicles.some(el => el.id == vehicle.id))
  return this.assignedVehicles.some(el => el == vehicle.id)
}
selectAction(event: any, vehicle: any) {
  localStorage.setItem('isDetailing','true')
  if (event == this.actionsCar.Edit_Vehicle_Profile) {
    this.storeVehicleEdit(vehicle, vehicle.paymentType,0)
  }
  if (event == this.actionsCar.Qr_Code) {
    this.storeVehicleEdit(vehicle, vehicle.paymentType,1)
  }
  else if (event == this.actionsCar.Delete_Vehicle_Profile) {
    this.storeBuffVehicle(vehicle)
    this.deleteVehicleModal.show()
  }
  else if (event == this.actionsCar.Force_Plan_Change) {
    this.storeBuffVehicle(vehicle);
    this.reNewMembershipModal.show()
  }
  else if (event == this.actionsCar.Create_Card_Subscription) {
    this.storeVehicleEdit(vehicle, this.paymentTypeEnum.card,2)
  }
  else if (event == this.actionsCar.View_Card_Subscription) {
     this.createPortalCustomerStripeCustom(vehicle)
  }
  else if (event == this.actionsCar.Create_Cash_Subscription) {
    this.storeVehicleEdit(vehicle, this.paymentTypeEnum.cash,2)
  }
  else if (event == this.actionsCar.View_Cash_Subscription) {
     this.createPortalCustomerStripeCustom(vehicle)
  }
  else if (event == this.actionsCar.Disabled_Payment_Type) {
    this.changeDenied(false)
    this.deniedModal.show()
  }
  else if (event == this.actionsCar.Customer_Route) {
    if (vehicle.customer.uid) {
      (this.user.role == 'admin' || this.user.role == 'manager') ? this.router.navigate(['/admin/customer-profile/', vehicle.customer.id]) : this.router.navigate(['/user/customer-profile/', vehicle.customer.id]);
    }
    if (!vehicle.customer.uid) {
      localStorage.setItem('bufferCustomer', JSON.stringify(vehicle.customer));
      (this.user.role == 'admin' || this.user.role == 'manager') ? this.router.navigate(['/admin/add-customer-manually']) : this.router.navigate(['/user/add-customer-manually']);
    }
  }
}
storeVehicleEdit(vehicle: any, type: any,index:any) {
  localStorage.setItem('isDetailing','true')
  let url = ''
  if (type == undefined || type == null) {
    type = this.paymentTypeEnum.card
  }
  localStorage.setItem('type',String(type))
  localStorage.setItem('selectedIndex',String(index))
  if (this.user.role == 'admin' || this.user.role == 'manager') {
    this.router.navigate(['/admin/complete-membership/', vehicle.id])
  }
  else {
    this.router.navigate(['/user/complete-membership/', vehicle.id])
  }
}
storeBuffVehicle(vehicle: any) {
  this.buffVehicle = vehicle
}
changeDenied(isDenied) {
  this.isDenied = isDenied
}
selectItemPackage1(i) {
  this.selectedPackageIndex1 = i;
}
storeHistories(vehicle: any) {
  this.p4 = 1
  if (!vehicle.queueHistories) {
    this.historiesDetailing = []
  }
  else {
    this.historiesDetailing = vehicle.queueHistories
  }
}
isExistsInVehicles(vehicle:any){
  return this.assignVehicles.some(a =>  a.id == vehicle.id)
}
checkAction(event: any, vehicle: any){
  this.smsType = event
  if(vehicle.phone == undefined ||  vehicle.phone == '' || vehicle.phone == null){
    this.noPhoneModal.show()
  }
  else{
    this.buffVehicle = vehicle
    this.smsType = event
    if(event == this.smsNotification.Car_Wash_Ready_to_Served){
      let car_Wash_Ready_to_Served = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Ready_to_Served.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Ready){
      let car_ready = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_ready.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Service_Canceled){
      let car_Wash_Service_Canceled = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Service_Canceled.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Job_Started){
      let car_Wash_Job_Started = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Job_Started.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
  }
}
sendSMSMessage(){
  let model = {
    smsType: this.smsType,
    date: (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1)) + '-' + new Date().getDate()),
  }
  this._carWashService.sendSmsVehicleDetailing(this.buffVehicle.id,this.user.companyId,model,this.jobModel.jobId).subscribe((response:ResponseModel)=>{
    if(response.success){
      this._toaster.success('',response.message)
    }
  })
}
storeAssignedHistories(vehicle: any) {
  console.log(vehicle)
  this.p6 = 1
  if (!vehicle.histories) {
    this.historiesDetailing = []
  }
  else {
    this.historiesDetailing = vehicle.histories.filter(h => h.key != this.historyCarWash.Check_In_Action && h.key != this.historyCarWash.Check_Out_Action && h.key != this.queueHistory.Out_Of_Queue )
  }
}
getService(vehicle:any){
  let servicesObj :any = {}
  if(vehicle.servicesDetailing && vehicle.servicesDetailing.length > 0){
    servicesObj =   vehicle.servicesDetailing.find(v => v.jobId == this.jobId)
    servicesObj = servicesObj ? servicesObj.servicesObj : {}
  }
  return servicesObj
 }
 openCamera() {
  this.showWebcam = true
  this.trigger.next();
}
closeCamera() {
  this.showWebcam = false
}
  // functions for open camera
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public handleImage(webcamImage: any): void {
    this.webcamImage = webcamImage;
    this.trigger = new Subject<void>();
  this._carWashService.getVehicleInformationFromAPI(this.webcamImage._imageAsDataUrl).subscribe((response:ResponseModel)=>{
  //  console.log(response)
    if(response.success){
     if(response.data.results.length > 0){
      this.searchText = response.data.results[0].plate.toString().toLowerCase()
      this.step = 0
      this.p= 1
      this.cameraScanModal.hide()
     }
     else{
      this.cameraScanModal.hide()
      this._toaster.warning('Try again','plate number NOT detect ')
     }
    }
  })
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = !this.showWebcam
  }
}

