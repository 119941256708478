export enum TicketStatus {
  Proposed,
  Active,
  Resolved,
  Resolved_Waiting_Updating,
  Later,
  Closed,
  Discard,
}

export enum TicketPriority {
  Critical,
  High,
  Medium,
  Low,
}

export enum TicketSeverity {
  Critical,
  High,
  Medium,
  Low,
}
export enum TicketHistory {
  Status,
  Priority,
  Severity,
}
