<div class="card">
    <div class="tittle-section">
        <span (click)="redirection()"><i class="fas fa-arrow-left pr-2" ></i></span>
        <p>Add a Vehicle</p>
    </div>
    <div class="section-stepper">
        <form [formGroup]="addVehicleFormGroup">
            <mat-vertical-stepper [linear]="isLinear" #stepper>
                <mat-step state="vehicle">
                    <ng-template matStepLabel>
                        Vehicle Information
                        <i class="required italic-required">(required)</i>
                    </ng-template>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Vehicle Make</mat-label>
                        <input type="text" required matInput formControlName="vehicleType" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let car of filteredOptions | async" [value]="car.Make" (click)="chooseCar(car)">
                                {{car.Make}} / {{car.Model}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="f.vehicleType.errors.required">Vehicle Make is required</div>
                    </div>
                    <div class="form-group open-camera">
                        <mat-form-field appearance="outline">
                            <mat-label>Plate / #VIN( Last 4 Digits)</mat-label>
                            <input matInput required type="text" class="text-uppercase" formControlName="plateNumber" (keyup)="isPlateNumberExist()">
                            <button mat-icon-button matSuffix *ngIf="isMobile" (click)="cameraScanModal.show();openCamera()"><i class="uil uil-camera"></i></button>
                        </mat-form-field>
                        <!-- <label for="plate -number">Plate / #VIN( Last 5 Digits)<span class="required">* </span> :</label> -->
                        <!-- <input type="text" id="plate-number" class="form-control text-uppercase" (keyup)="isPlateNumberExist()" placeholder="Plate Number / #VIN (Last 4 digits)" formControlName="plateNumber"> -->
                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                            <div *ngIf="f.plateNumber.errors.pattern">plate number only characters & numbers</div>
                        </div>
                        <div *ngIf="isExists" class="alert alert-danger" role="alert">Plate Number is Exists.</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Car Model</mat-label>
                            <input matInput type="text" formControlName="vehicleModel">
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="isDetailing">
                        <mat-form-field appearance="outline">
                            <mat-label>Customer Email</mat-label>
                            <input matInput type="text" required formControlName="customerEmail" (input)="saveCustomerEmail($event.target.value)">
                        </mat-form-field>
                        <!-- <div *ngIf="isErrorEmailCustomer" class="alert alert-danger">
                            Email customer not valid.
                        </div> -->
                        <div *ngIf="f.customerEmail.touched && f.customerEmail.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.customerEmail.errors.required">Plate Number is required</div>
                            <div *ngIf="f.customerEmail.errors.pattern">Email customer not valid</div>
                        </div>
                        <div *ngIf="isExistsCustomer" class="alert alert-danger">
                            Customer E-mail exists please enter another Email.
                        </div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <ngx-mat-intl-tel-input formControlName="phone" [preferredCountries]="countrySelectedArr" [enablePlaceholder]="true" [enableSearch]="true" name="phone" (countryChanged)="yourComponentMethodToTreatyCountryChangedEvent($event)">
                            </ngx-mat-intl-tel-input>
                        </mat-form-field>
                        <mat-error *ngIf="f.phone?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input matInput type="number" formControlName="phone">
                        </mat-form-field>
                    </div> -->
                    <!-- <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" formControlName="phone">
                    </ngx-intl-tel-input> -->
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Car Condition</mat-label>
                            <textarea rows="1" cols="26" matInput type="text" formControlName="condition"> </textarea>
                        </mat-form-field>
                    </div>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" (click)="addVehicle()" [disabled]="addVehicleFormGroup.invalid || vehicle.id || isExists  || f.phone?.errors?.validatePhoneNumber || isExistsCustomer">Create a Car Profile</button>
                </mat-step>
                <mat-step state="edit">
                    <ng-template matStepLabel>QR Code <i class="italic-optional">(Optional, It will provided at the car wash site)</i></ng-template>
                    <div class="tittle-section-qr">
                        <p>QR Code</p>
                        <div class="btn-add-new" *ngIf="user.companyPlan == companyPlan.Premium">
                            <span class="badge badge-success" (click)="assignQR()" *ngIf="vehicle.id">
                          <a *ngIf="!vehicle.qrCodeNumber">Assign</a>
                          <a *ngIf="vehicle.qrCodeNumber">Re-Assign</a>
                         </span>
                        </div>
                        <!-- <span class="btn btn-sm btn-primary">Upgrade</span> -->
                    </div>
                    <div>
                        <button matStepperPrevious type="button" class="btn btn-sm btn-primary">Back</button>
                        <button matStepperNext type="button" class="btn btn-sm btn-success">Next</button>
                    </div>
                </mat-step>
                <mat-step state="membership">
                    <ng-template matStepLabel>Membership <i class="italic-optional">(Optional)</i></ng-template>
                    <span class="no-vehicle" *ngIf="!vehicle.id">First, you MUST add vehicle information</span>
                    <div class="check-section" *ngIf="vehicle.id">
                        <!-- Card checked radio -->
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Card" (change)="handleChange($event)" id="flexRadioDefault2" checked/>
                            <label class="form-check-label" for="flexRadioDefault2">Card </label>
                        </div>
                        <!-- Cash checked radio -->
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Cash" (change)="handleChange($event)" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">Cash </label>
                        </div>
                    </div>
                    <!--  card payment -->
                    <div class="form-group" *ngIf="vehicle.id && paymentType == paymentTypeEnum.card">
                        <div class="cash-month">
                            <span class="pr-2">Expires aftar </span>
                            <select class="browser-default custom-select form-control" (change)="chooseCountMonth($event.target.value)">
                              <option *ngFor="let month of monthsCard" value="{{month}}"> {{month}} </option>
                             </select>
                            <span class="pl-2"> Months</span>
                        </div>
                        <button class="btn btn-sm btn-primary mt-3" *ngIf=" paymentType == paymentTypeEnum.card" [disabled]="!vehicle.id" (click)="createMemberShipCash(vehicle.id)">Create Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                    </div>
                    <!-- cash payment section  -->
                    <div class="cash-payment" *ngIf="vehicle.id && paymentType == paymentTypeEnum.cash">
                        <!-- <h6>Membership period</h6> -->
                        <mat-tab-group [disableRipple]="false">
                            <mat-tab label="Membership">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12">
                                        <ul class="list-group" *ngFor="let product of products; let i=index">
                                            <li (click)="storeBuffProductPrice(product);selectItem(i)" [ngClass]="{'selected-item': selectedID == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                                <span>
                                          <input type="radio" id="product.id"  name="product.id" value="product.name" >
                                          {{product.name}}
                                        </span>
                                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{getPrice(product.price)}}</span>
                                            </li>
                                            <!-- <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                          A second list item
                                          <span class="badge bg-primary rounded-pill">$14</span>
                                      </li>
                                      <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                          A third list item
                                          <span class="badge bg-primary rounded-pill">$14</span>
                                      </li>
                                      <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                          A third list item
                                          <span class="badge bg-primary rounded-pill">$14</span>
                                      </li>
                                      <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                          A third list item
                                          <span class="badge bg-primary rounded-pill">$14</span>
                                      </li> -->
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 left-statistic">
                                        <!-- <div class="card"> -->
                                        <div class="form-group date ">
                                            <mat-form-field class="example-full-width mt-0" appearance="outline">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)">
                                                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                            <div class="form-group date">
                                                <mat-form-field class="example-full-width  mt-0" appearance="outline">
                                                    <mat-label>End Date</mat-label>
                                                    <input matInput [matDatepicker]="picker1" (dateChange)="addEvent1('change', $event)">
                                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <span class="alert alert-danger error-date" *ngIf="isDateCheck">The end date must be after</span>
                                        <!-- <span>Total Days 00</span> -->
                                        <div class="date-statistic">
                                            <strong>Total days:
                                      <div class="input-group ">
                                        <input type="text" class="form-control " name="diffInDays" [value]="diffInDays" readonly >
                                      </div>
                                    </strong>
                                            <strong>Total due:
                                        <div class="input-group ">
                                          <input type="text" class="form-control " [value]="getTotalDuePrice()" readonly >
                                          <div class="input-group-append">
                                            <span class="input-group-text">({{getCurrency()}})</span>
                                          </div>
                                        </div>
                                      </strong>
                                            <strong><span class="success">Received:</span>
                                        <div class="input-group ">
                                        <input type="number" class="form-control "  min="0" (input)="accountReceived($event.target.value)">
                                        <div class="input-group-append">
                                          <span class="input-group-text">({{getCurrency()}})</span>
                                        </div>
                                      </div>
                                      </strong>
                                            <strong><span class="required">Cash Back:</span>
                                         <div class="input-group ">
                                        <input type="text" class="form-control"  min="0" [value]="cashBackAmount" readonly >
                                        <div class="input-group-append">
                                          <span class="input-group-text">({{getCurrency()}})</span>
                                        </div>
                                      </div>
                                    </strong>
                                        </div>
                                        <span class="alert alert-danger error-date text-left" *ngIf="isBack">The received amount must be equal to or greater than the due amount.                                        </span>
                                    </div>
                                </div>
                                <div class="btn-cash" *ngIf="vehicle.id && paymentType == paymentTypeEnum.cash">
                                    <button class="btn btn-primary" [disabled]="isBack  || isDateCheck || isHaveSecretKey" type="button" (click)="summaryCashModal.show()">Create Cash Membership </button>
                                </div>
                            </mat-tab>
                            <mat-tab label="Packages">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12">
                                        <ul class="list-group" *ngFor="let product of packageProducts;let i= index">
                                            <li (click)="storeBuffProductPackagingPrice(product);selectItemPackage(i)" [ngClass]="{'selected-item': selectedPackageIndex == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                                <span>
                                                   <input type="radio" id="product.id"  name="product.id" value="product.name" >
                                                    {{product.name}}
                                                 </span>
                                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{getPrice(product.price)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 left-statistic">
                                        <!-- <div class="card"> -->
                                        <!-- <div class="form-group date ">
                                        <mat-form-field class="example-full-width mt-0" appearance="outline">
                                            <mat-label>Start Date</mat-label>
                                            <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)">
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <div class="form-group date">
                                            <mat-form-field class="example-full-width  mt-0" appearance="outline">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="picker1" (dateChange)="addEvent1('change', $event)">
                                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                        <!-- <span class="alert alert-danger" *ngIf="isDateCheck">start date bigest end date</span> -->
                                        <!-- <span>Total Days 00</span> -->
                                        <div class="date-statistic">
                                            <!-- <strong>Total days:
                                  <div class="input-group ">
                                    <input type="text" class="form-control " name="diffInDays" [value]="diffInDays" readonly >
                                  </div>
                                </strong> -->
                                            <strong>Total due:
                                      <div class="input-group ">
                                        <input type="text" class="form-control " [value]="pricePackageProduct" readonly >
                                        <div class="input-group-append">
                                          <span class="input-group-text">({{getCurrency()}})</span>
                                        </div>
                                      </div>
                                  </strong>
                                            <strong><span class="success">Received: </span>
                                      <div class="input-group ">
                                      <input type="number" class="form-control "  min="0" (input)="accountReceivedPackaging($event.target.value)">
                                      <div class="input-group-append">
                                        <span class="input-group-text">({{getCurrency()}})</span>
                                      </div>
                                    </div>
                                  </strong>
                                            <strong><span class="required">Cash Back:</span>
                                      <div class="input-group ">
                                      <input type="text" class="form-control" [value]="cashBackPackageProduct" readonly >
                                      <div class="input-group-append">
                                        <span class="input-group-text">({{getCurrency()}})</span>
                                      </div>
                                    </div>
                                  </strong>
                                        </div>
                                        <span class="alert alert-danger text-left" *ngIf="isBack1">The received amount must be equal to or greater than the due amount.                                        </span>
                                    </div>
                                </div>
                                <div class="btn-cash" *ngIf="vehicle.id && paymentType == paymentTypeEnum.cash">
                                    <button class="btn btn-primary" [disabled]="isBack1 || isHaveSecretKey" type="button" (click)="summaryCashPackageModal.show()">Create Cash Membership </button>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <!-- <div class="list-group">
                            <a class="list-group-item list-group-item-action active" aria-current="true">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">List group item heading</h5>
                                    <small>3 days ago</small>
                                </div>
                            </a>
                            <a class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">List group item heading</h5>
                                    <small class="text-muted">3 days ago</small>
                                </div>
                            </a>
                            <a class="list-group-item list-group-item-action">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">List group item heading</h5>
                                    <small class="text-muted">3 days ago</small>
                                </div>
                            </a>
                        </div> -->
                    </div>
                </mat-step>
                <ng-template matStepperIcon="scan">
                    <mat-icon>camera_alt</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="vehicle">
                    <mat-icon> directions_car</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="person">
                    <mat-icon>person</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="membership">
                    <mat-icon>card_membership</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <!-- <ng-template matStepperIcon="done">
                    <mat-icon>forum</mat-icon>
                </ng-template> -->
            </mat-vertical-stepper>
        </form>
    </div>
</div>
<!--  Warning key  -->
<div mdbModal #warningKeyModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warning</h5>
                    <button type="button" class="close" (click)="warningKeyModal.hide()" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <p>The payment gate is NOT integrated in the company account.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Cash key  -->
<div mdbModal #warningCashModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningCashModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p>Please add an email for the cash membership first. <br> Menu > Company account> Stripe integration.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningCashModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Email Customer Cash modal -->
<div id="myModal" mdbModal #customerCashModal="mdbModal" class="modal cashModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Add a stripe cash account (One time only)</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="customerCashModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body strip_key-body">
                <ol>
                    <li>Create a regular new email (must be different than the one stripe account created with).</li>
                    <li>Add the email below.
                        <div class="form-group mt-2">
                            <input name="customerEmailCash" id="customerEmailCash" class="form-control" placeholder="Email" [(ngModel)]="customerEmailCash">
                        </div>
                    </li>
                    <li>Click [Create cash Membership].</li>
                </ol>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger" (click)="customerCashModal.hide()">Cancel</button>
                <button class="btn btn-sm btn-success" (click)="createMemberShip(bufferVehicleId);customerCashModal.hide()">Create Cash Membership</button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='false'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Please import the secret stripe key first.
Menu > Company account> Stripe integration. -->
<div mdbModal #warningKeyStripModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningKeyStripModal.hide()" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-left">
                    <p>Ensure that the payment gate is integrated into the company account: <br> Accordev Home Page> Menu > Company account> Stripe integration.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyStripModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="scanWebModal" mdbModal #scanWebModal="mdbModal" class="modal tag-modal scan-web-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Scan</h5>
                    <button type="button" class="close" (click)="scanWebModal.hide();handle(action, 'stop')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body px-0 text-center">
                    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event)" (error)="onError($event)"></ngx-scanner-qrcode>
                    <span *ngIf="action.isLoading">⌛ Loading...</span> <br>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  open camera -->
<div mdbModal #cameraScanModal="mdbModal" class="modal camera-scan " [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Capture Plate</h5>
                    <button type="button" class="close" (click)="cameraScanModal.hide();closeCamera()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center px-0">
                    <div class="text-center">
                        <webcam [height]="500" [width]="360" [captureImageData]="true" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam" [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
                    </div>
                </div>
                <div class="modal-footer ">
                    <div class="upload-buttons">
                        <div class="btn-group">
                            <button class="btn btn-success" *ngIf="showWebcam " (click)="triggerSnapshot()"> <i class="uil uil-capture pr-2"></i><span>Capture</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- summaryCashModal-->
<div mdbModal #summaryCashModal="mdbModal" class="modal summary-cash-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Summary</h5>
                    <button type="button" class="close" (click)="summaryCashModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body summary-modal">
                    <div class="text-left ">
                        <span>Your selected paln is: </span> <strong>{{buffProduct.name}} / {{buffProduct.price / 100}} ({{getCurrency()}})</strong>
                    </div>
                    <div class="date-statistic-modal">
                        <strong>Total days: {{diffInDays}}</strong>
                        <strong>Total due: {{getTotalDuePrice()}} ({{getCurrency()}})</strong>
                        <strong class="success">Received: {{receivedAmount}} ({{getCurrency()}})</strong>
                        <strong class="required">Cash Back: {{cashBackAmount}} ({{getCurrency()}}) </strong>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button class="btn btn-success" (click)="createNewCashSubscription()">Create Cash Subscription</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- summaryCashModal-->
<div mdbModal #summaryCashPackageModal="mdbModal" class="modal summary-cash-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Summary</h5>
                    <button type="button" class="close" (click)="summaryCashPackageModal.hide()" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body summary-modal">
                    <div class="text-left ">
                        <span>Your selected paln is: </span> <strong>{{buffProductPackaging.name}} / {{buffProductPackaging.price / 100}} ({{getCurrency()}})</strong>
                    </div>
                    <div class="date-statistic-modal">
                        <strong>Total due: {{pricePackageProduct}} ({{getCurrency()}})</strong>
                        <strong class="success">Received: {{receivedAmountPackageProduct}}({{getCurrency()}}) ({{getCurrency()}})</strong>
                        <strong class="required">Cash Back: {{cashBackPackageProduct}} ({{getCurrency()}}) </strong>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button class="btn btn-success" (click)="createNewCashPackageSubscription()">Create Cash Subscription</button>
                </div>
            </div>
        </div>
    </div>
</div>
