import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { JobsService } from './../_services/jobs/jobs.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class GetCompanyByIdResolver implements Resolve<any[]>
{
  constructor(private _companiesService: CompaniesService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    return this._companiesService.getCompanyById(user.companyId).pipe(
      catchError(error => {
        console.log(error)
        return of(null);
      })
    );
  }
}
