import { ToasterCustomService } from './../../../../../core/_services/toaster-service/ToasterCustomService.service';
import { PaymentType } from './../Enum/PaymentType';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { Capacitor } from '@capacitor/core';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ModalDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-products-strip',
  templateUrl: './products-strip.component.html',
  styleUrls: ['./products-strip.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ProductsStripComponent implements OnInit, OnDestroy {
  @ViewChild('assignCustomerToVehicleModal') assignCustomerToVehicleModal: ModalDirective;
  user: any = {}
  products: any[] = []
  vehicleId: string = ''
  paymentType
  customerId
  countMonth
  product: any = {}
  currentCompany: any = {}
  emailAdd = ''
  // paymentType = PaymentType.card
  paymentTypeEnum = PaymentType
  emailPattern = ValidationModel.emailPattern
  isNotEmail = true
  isExists = false
  // emailCard =''
  reNew = false
  emailCard = ''
  historyCarWash = HistoryCarWash
  customerObj: any = {}
  constructor(
    private _companiesService: CompaniesService,
    private _carWashService: CarWashService,
    private route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    public _toaster: ToasterCustomService,
    private _globalService: GlobalService
  ) { }

  ngOnInit() {
    this.paymentType = localStorage.getItem('paymentType')
    this.customerId = localStorage.getItem('customerId')
    this.countMonth = localStorage.getItem('countMonth')
    this.user = JSON.parse(localStorage.getItem('user'))
    this.vehicleId = this.route.snapshot.paramMap.get('id');
    this.getProductsCompanyById()
    this.currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    if (Number(this.paymentType) == this.paymentTypeEnum.card) {
      let reNew = localStorage.getItem('renewMembership')
      if (reNew != null && reNew != undefined) {
        this.reNew = true
        this._carWashService.getCustomerEmailCard(this.vehicleId).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.emailCard = response.data
          }
        })
      }
      this.isNotEmail = true
    }
    else {
      this.isNotEmail = false
    }
  }
  getProductsCompanyById() {
    this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
      this.products = response.data
    })
  }
  redirection(product: any) {
    let todayDate = new Date()
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: Number(localStorage.getItem('historyLength')),
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      planName: product.name,
      dateHistory: todayDate,
      timeHistory: this.datePipe.transform(todayDate, 'H:mm'),
      key: this.historyCarWash.Create_Membership,
      paymentType: Number(this.paymentType)
      // eventName: 'Create membership'
    }
    let model: any = {
      date: (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      time: this.datePipe.transform(todayDate, 'H:mm'),
      paymentType: Number(this.paymentType)
    }
    model.history = history
    if (this.customerId != null || this.customerId != undefined) {
      model.customerId = this.customerId
    }
    if (this.countMonth != null || this.countMonth != undefined) {
      if (this.countMonth == 'forever') {
        model.countMonth = this.countMonth
      }
      else {
        model.countMonth = Number(this.countMonth)
      }
    }
    this._carWashService.addSubscriptionVehicle(this.vehicleId, product.default_price, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          this.router.navigate(['/admin/vehicles-list'])
        }
        else {
          this.router.navigate(['/user/vehicles-list'])
        }
        if (this.reNew) {
          localStorage.removeItem('renewMembership')
        }
        // window.open(response.data, "_blank")
        console.log('response from add subscription', response.data)
        this._globalService.openLink(response.data)
        // window.open(response.data.url);
      }
    })
  }
  getPrice(price) {
    return price / 100
  }
  storeProduct(product) {
    this.product = product
  }
  handleChange(event, index: number) {
    if (event.target.checked) {
      this.products[index].isCheck = true
    }
    else {
      this.products[index].isCheck = false
    }
  }
  createMemberShip(product, i) {
    // if(!Capacitor.isNativePlatform() ){
    if (this.paymentType == this.paymentTypeEnum.card && !this.reNew) {
      let email: any = document.getElementById(`email${i + 1}`)
      let model: any = {
        firstName: '',
        lastName: '',
        email: this.getEmailWithoutSpace(email.value),
        companyId: this.user.companyId,
        isInvited: false,
        role: "customer",
        name: '',
        type: "customer",
        status: "Unassigned",
        disable: false,
        isCanceled: false,
        isCustomerCash: false,
        isCustomerCarWash: true,
        date: new Date(),
        dateFormate: this.datePipe.transform(new Date(), 'yyyy-d-M'),
        timeFormate: this.datePipe.transform(new Date(), 'h:mm a'),
        country: {
          name: "United States",
          alpha2Code: "US",
          alpha3Code: "US",
          numericCode: "840"
        }
      }
      this._carWashService.addCustomerVehicle(this.vehicleId, model).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.redirection(product)
        }
        else {
          this.customerObj = response.data
          this.storeProduct(product)
          this.assignCustomerToVehicleModal.show()
          // this._toaster.error('', 'Customer already exists')
        }
      })
    }
    else {
      this.redirection(product)
    }
    // }
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  checkEmail(event) {
    let re = new RegExp(this.emailPattern)
    if (re.test(this.emailAdd)) {
      this.isNotEmail = false
    }
    else {
      this.isNotEmail = true
    }
  }
  assignExistsCustomerToVehicle() {
    this._carWashService.assignExistingCustomerVehicle(this.vehicleId, this.customerObj).subscribe((response) => {
      this.redirection(this.product)
    }, error => {
      console.log(error)
    })
  }
  getTermsAndCondition(model: string) {
    // console.log(model.replace(/<br\s*\/?>/mg, "&#013;"))
    return model.replace(/<br\s*\/?>/mg, "&#013;")
    // return  model.replace("&#013", "<br>")
    // model.replace("&#013", "<br>")
  }
  ngOnDestroy() {
    localStorage.removeItem('paymentType')
    localStorage.removeItem('customerId')
    localStorage.removeItem('countMonth')
    localStorage.removeItem('renewMembership')
    localStorage.removeItem('historyLength')
    // localStorage.removeItem('emailCard')
  }

}
