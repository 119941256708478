<!-- form Search -- -->
<form class="serach-form">
    <div class="form-group row" *ngIf="newJobPermission.itemStatus === 'Active' && newJobPermission.roleStatus === 'active'">
        <div class="col-md-6 " [routerLink]="['/user/vehicles-list']">
            <div class="btn-add " *ngIf="carMangerPermission.itemStatus === 'Active' && carMangerPermission.roleStatus === 'active'">
                <a>
                    <i class="uil uil-car-wash"></i> Check-In
                </a>
            </div>
        </div>
        <div class="col-md-6" [routerLink]="['/user/add-vehicle']">
            <div class="btn-add " *ngIf="carMangerPermission.itemStatus === 'Active' && carMangerPermission.roleStatus === 'active'">
                <a> <i class="uil uil-car"></i> Add Vehicle</a>
            </div>
        </div>
        <div class="col-md-6">
            <div class="btn-add " (click)="newJobWithoutCloneModal.show()">
                <a><i class="far fa-plus-square pr-1"></i>Draft Job</a>
            </div>
        </div>
        <!-- map button -->
        <div class="col-md-6">
            <div class="btn-add " (click)="getJobsClone();newJobModal.show()">
                <a> <i class="far fa-copy"></i>Templates</a>
            </div>
            <!-- <div class="btn-add my-jobs" *ngIf="whorehousePermission.roleStatus === 'readOnly' " (click)="isChooseMap()">
                <a>
                    <i class="fas fa-map pr-1"></i>WH Map</a>
            </div> -->
            <!-- <div *ngIf="whorehousePermission.roleStatus === 'notActive' ">
            </div> -->
        </div>
    </div>
</form>
<div class="calender-statistic">
    <div class="calender-statistic-header">
        <h4 class="mb-0">Search</h4>
    </div>
    <!-- Card Jobs -->
    <mdb-card class="jobs">
        <mdb-card-body>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h6>My Jobs</h6>
                        <div class="tittle-accordion">
                            <span>({{jobs.length}}) Jobs</span>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="col-12 px-0 mt-2 text-center">
                        <div class="form-group">
                            <input type="text" id="job-text" class="form-control border-serach" aria-describedby="job-text" (keydown)="serachJob($event)" [(ngModel)]="jobText" placeholder="Search job Number" autofocus />
                        </div>
                    </div>
                    <table class="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">#Job</th>
                                <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let job of jobs | JobUserFilter: jobText | paginate: { id: 'first',  itemsPerPage:3, currentPage: p};" (click)="storeId(job.id,0)">
                            <tr class="change-pointer">
                                <th class="job-item text-center" scope="row">
                                    <h6> {{job.jobNumber}} <span *ngIf="job.vehicle"> | {{job.vehicle?.plateNumber}}</span></h6>
                                    <hr>
                                    <h6 *ngIf="job.vehicle">
                                        <span> {{job.vehicle?.vehicleType}} <span *ngIf="job.vehicle?.vehicleModel"> | {{job.vehicle?.vehicleModel}}</span></span>
                                    </h6>
                                    <h6 *ngIf="!job.vehicle"> {{job.jobType}}</h6>
                                </th>
                                <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                    <h6 *ngIf="job.primaryCustomerName">{{job.primaryCustomerName}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerName">CustomerName</h6>
                                    <hr>
                                    <h6 *ngIf="job.primaryCustomerType"> {{job.primaryCustomerType}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerType"> Customer Type</h6>
                                </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                <!-- US Date formate -->
                                <td *ngIf="countryCode === true">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'shortDate'}}
                                   {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                              </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | date:'EE' }}, {{job.jobDetailsDateTime.endDateWindowTo | date:'shortDate' }}
                                 {{job.jobDetailsDateTime.endTimeWindowTo}}
                               </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                                <!-- Other Country Date formate -->
                                <td *ngIf="countryCode === false">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'d/M/yy'}}
                                 {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                            </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | date:'EE' }},  {{job.jobDetailsDateTime.endDateWindowTo | date:'d/M/yy' }}
                               {{job.jobDetailsDateTime.endTimeWindowTo}}
                             </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                            </tr>
                            <tr *ngIf="job.jobDetailsProgress">
                                <td colspan="1"><strong>{{job.jobDetailsProgress.subject}} :</strong> </td>
                                <td colspan="3" class="progress-status">{{job.jobDetailsProgress.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="ant-empty-image" *ngIf="jobs.length <= 0 ">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div>
                    <div class="text-center mt-3" *ngIf="jobs.length > 0 ">
                        <pagination-controls id="first" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- </mdb-card-text> -->
        </mdb-card-body>
    </mdb-card>
    <!-- Search card -->
    <mdb-card class="jobs" *ngIf="searchSpacePermission.itemStatus === 'Active' && searchSpacePermission.roleStatus === 'active' || searchSpacePermission.itemStatus === 'Active' && searchSpacePermission.roleStatus === 'readOnly'">
        <mdb-card-body>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h6>Search</h6>
                        <div class="tittle-accordion">
                            <span>({{jobsSearch.length}}) Jobs</span>
                        </div>
                    </mat-expansion-panel-header>
                    <form class="serach-form mt-2">
                        <div class="form-group row">
                            <div class="col-lg-10 serach-class">
                                <input *ngIf="searchSpacePermission.roleStatus === 'active'" mdbInput type="text" name='textSerach' [(ngModel)]="textSerach" class="form-control border-serach serach-input" id="inputEmail3" placeholder="Search Job Number or Customer Name ">
                                <input *ngIf="searchSpacePermission.roleStatus === 'readOnly'" readonly class="form-control border-serach serach-input" placeholder="Serach Name , Job or Item">
                                <div *ngIf="searchSpacePermission.roleStatus === 'notActive'">
                                </div>
                            </div>
                            <div class="col-lg-2" (click)="getAllJobsBySearch();select('Serach')" [ngClass]="{activeJobs: isActive('Serach')}" *ngIf="searchSpacePermission.roleStatus === 'active'">
                                <div class="btn-add my-jobs">
                                    <a><i class="fas fa-search pr-1"></i> Serach</a>
                                </div>
                            </div>
                            <div class="col-lg-2" [ngClass]="{activeJobs: isActive('Serach')}" *ngIf="searchSpacePermission.roleStatus === 'readOnly'">
                                <div class="btn-add my-jobs">
                                    <a><i class="fas fa-search pr-1"></i> Serach</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    <table class="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">#Job</th>
                                <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let job of jobsSearch | paginate: { id: 'jobs-Search',  itemsPerPage:3, currentPage: p1, totalItems: jobsSearch.length };" (click)="storeId(job.id,1)">
                            <tr class="change-pointer">
                                <th class="job-item text-center" scope="row">
                                    <h6> {{job.jobNumber}} <span *ngIf="job.vehicle"> | {{job.vehicle?.plateNumber}}</span></h6>
                                    <hr>
                                    <h6 *ngIf="job.vehicle">
                                        <span> {{job.vehicle?.vehicleType}} <span *ngIf="job.vehicle?.vehicleModel"> | {{job.vehicle?.vehicleModel}}</span></span>
                                    </h6>
                                    <h6 *ngIf="!job.vehicle"> {{job.jobType}}</h6>
                                </th>
                                <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                    <h6 *ngIf="job.primaryCustomerName">{{job.primaryCustomerName}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerName">CustomerName</h6>
                                    <hr>
                                    <h6 *ngIf="job.primaryCustomerType"> {{job.primaryCustomerType}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerType"> Customer Type</h6>
                                </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                <!-- US Date formate -->
                                <td *ngIf="countryCode === true">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'shortDate'}}
                                 {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                            </span> <br>
                                    <span class="end-window">{{job.jobDetailsDateTime.endDateWindowTo | date:'EE'}},  {{job.jobDetailsDateTime.endDateWindowTo | date:'shortDate' }}
                               {{job.jobDetailsDateTime.endTimeWindowTo}}
                             </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                                <!-- Other Country Date formate -->
                                <td *ngIf="countryCode === false">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'d/M/yy'}}
                               {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                          </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | date:'EE' }},  {{job.jobDetailsDateTime.endDateWindowTo | date:'d/M/yy' }}
                             {{job.jobDetailsDateTime.endTimeWindowTo}}
                           </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                            </tr>
                            <tr *ngIf="job.jobDetailsProgress">
                                <td colspan="1"><strong>{{job.jobDetailsProgress.subject}} :</strong> </td>
                                <td colspan="3" class="progress-status">{{job.jobDetailsProgress.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center mt-3" *ngIf="jobsSearch.length > 0 ">
                        <pagination-controls id="jobs-Search" (pageChange)="p1 = $event"></pagination-controls>
                    </div>
                    <div class="ant-empty-image" *ngIf="jobsSearch.length <= 0 ">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mdb-card-body>
    </mdb-card>
    <!-- sttasitc card -->
    <mdb-card class="statistic-card " *ngIf="jobStatisticsPermission.itemStatus === 'Active' && jobStatisticsPermission.roleStatus == 'active' || jobStatisticsPermission.itemStatus === 'Active' && jobStatisticsPermission.roleStatus == 'readOnly'">
        <mdb-card-body>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h6>Jobs status</h6>
                        <div class="tittle-accordion">
                            <span>({{jobsInYear.countYearFilter}}) Jobs to Go</span>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="week-picker mt-2 mb-2">
                        <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize" (click)="previousYear()">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                        <div class="week-text mx-2">
                            <p><b> {{yearStatistic}} </b></p>
                        </div>
                        <!-- right arrow read and write  -->
                        <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize " (click)="nextYear()">
                          <mat-icon>chevron_right</mat-icon>
                    </button>
                    </div>
                    <!-- <div class="btn btn-sm btn-success" (click)="previousYear()">Previous</div>
                  <span>{{yearStatistic}}</span>
                  <div class="btn btn-sm btn-success" (click)="nextYear()">Next</div> -->
                    <table class="table table-bordered mt-2">
                        <thead>
                            <tr>
                                <th class="text-left" scope="col">Month</th>
                                <th scope="col">01</th>
                                <th scope="col">02</th>
                                <th scope="col">03</th>
                                <th scope="col">04</th>
                                <th scope="col">05</th>
                                <th scope="col">06</th>
                                <th scope="col">07</th>
                                <th scope="col">08</th>
                                <th scope="col">09</th>
                                <th scope="col">10</th>
                                <th scope="col">11</th>
                                <th scope="col">12</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Draft</th>
                                <td scope="col" *ngFor="let item of jobsInYear.count">{{item.count1}}</td>
                                <td class="text-blue" (click)="getCountJobsByTypeInYear(jobStatusEnum.Draft);select('draft')" [ngClass]="{activeJobs: isActive('draft')}"><a>{{jobsInYear.countDraft}}</a></td>
                            </tr>
                            <tr>
                                <th scope="row">Deal</th>
                                <td scope="col" *ngFor="let item of jobsInYear.count">{{item.count2}}</td>
                                <td class="text-blue" (click)="getCountJobsByTypeInYear(jobStatusEnum.Deal);select('deal')" [ngClass]="{activeJobs: isActive('deal')}"><a>{{jobsInYear.countDeal}}</a></td>
                            </tr>
                            <tr>
                                <th scope="row">In Progress</th>
                                <td scope="col" *ngFor="let item of jobsInYear.count">{{item.count3}}</td>
                                <td class="text-blue" (click)="getCountJobsByTypeInYear(jobStatusEnum.In_Progress);select('proccess')" [ngClass]="{activeJobs: isActive('proccess')}"><a>{{jobsInYear.countProcess}}</a></td>
                            </tr>
                            <tr>
                                <th scope="row">Done</th>
                                <td scope="col" *ngFor="let item of jobsInYear.count">{{item.count4}}</td>
                                <td class="text-blue" (click)="getCountJobsByTypeInYear(jobStatusEnum.Done);select('done')" [ngClass]="{activeJobs: isActive('done')}"><a>{{jobsInYear.countDone}}</a></td>
                            </tr>
                            <tr>
                                <th scope="row">Canceled</th>
                                <td scope="col" class="canceled" *ngFor="let item of jobsInYear.count">{{item.count5}}</td>
                                <td class="canceled text-blue" (click)="getCountJobsByTypeInYear(jobStatusEnum.Canceled);select('cancel')" [ngClass]="{activeJobs: isActive('cancel')}"><a>{{jobsInYear.countCancel}}</a></td>
                            </tr>
                            <tr>
                                <th scope="row">Total</th>
                                <td scope="col" *ngFor="let item of jobsInYear.count">{{item.countMonth}}</td>
                                <td class="text-blue" (click)="getUserJobsByYear();select('total')" [ngClass]="{activeJobs: isActive('total')}"><a>{{jobsInYear.countYear}}</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="jobs mt-3">
                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th scope="col">#Job</th>
                                    <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let job of jobsStatistic | paginate: { id: 'jobs-Statistic',  itemsPerPage:3, currentPage: p4, totalItems: jobsStatistic.length };" (click)="storeId(job.id,2)">
                                <tr class="change-pointer">
                                    <th class="job-item text-center" scope="row">
                                        <h6> {{job.jobNumber}} <span *ngIf="job.vehicle"> | {{job.vehicle?.plateNumber}}</span></h6>
                                        <hr>
                                        <h6 *ngIf="job.vehicle">
                                            <span> {{job.vehicle?.vehicleType}} <span *ngIf="job.vehicle?.vehicleModel"> | {{job.vehicle?.vehicleModel}}</span></span>
                                        </h6>
                                        <h6 *ngIf="!job.vehicle"> {{job.jobType}}</h6>
                                    </th>
                                    <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                        <h6 *ngIf="job.primaryCustomerName">{{job.primaryCustomerName}}</h6>
                                        <h6 *ngIf="!job.primaryCustomerName">CustomerName</h6>
                                        <hr>
                                        <h6 *ngIf="job.primaryCustomerType"> {{job.primaryCustomerType}}</h6>
                                        <h6 *ngIf="!job.primaryCustomerType"> Customer Type</h6>
                                    </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                    <!-- US Date formate -->
                                    <td *ngIf="countryCode === true">
                                        <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'shortDate'}}
                                           {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                                       </span> <br>
                                        <span class="end-window">{{job.jobDetailsDateTime.endDateWindowTo | date:'EE'}},{{job.jobDetailsDateTime.endDateWindowTo | date:'shortDate' }}
                             {{job.jobDetailsDateTime.endTimeWindowTo}}
                           </span><br> {{job.jobDetailsDateTime.day}}
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                    </td>
                                    <!-- Other Country Date formate -->
                                    <td *ngIf="countryCode === false">
                                        <span class="start-window"> {{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'d/M/yy'}}
                             {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                        </span> <br>
                                        <span class="end-window"> {{job.jobDetailsDateTime.jobDetailsDateTime | date:'EE'}}, {{job.jobDetailsDateTime.endDateWindowTo | date:'d/M/yy' }}
                           {{job.jobDetailsDateTime.endTimeWindowTo}}
                         </span><br> {{job.jobDetailsDateTime.day}}
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                    </td>
                                </tr>
                                <tr *ngIf="job.jobDetailsProgress">
                                    <td colspan="1"><strong>{{job.jobDetailsProgress.subject}} :</strong> </td>
                                    <td colspan="3" class="progress-status">{{job.jobDetailsProgress.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center mt-3" *ngIf="jobsStatistic.length > 0 ">
                            <pagination-controls id="jobs-Statistic" (pageChange)="p4 = $event"></pagination-controls>
                        </div>
                        <div class="ant-empty-image" *ngIf="jobsStatistic.length <= 0 ">
                            <img src="./assets/img/empty.svg" alt="empty-img">
                            <span>No Data</span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mdb-card-body>
    </mdb-card>
    <!-- Card Filtering -->
    <mdb-card class="jobs pb-2" *ngIf="calendarPermission.itemStatus === 'Active' && calendarPermission.roleStatus === 'active' || calendarPermission.itemStatus === 'Active' && calendarPermission.roleStatus === 'readOnly'">
        <!--Card content-->
        <mdb-card-body>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <h6>Calendar</h6>
                        <div class="tittle-accordion">
                            <span>
                                {{dateObj | date : 'dd'}}, {{date.format('MMMM ')}} {{date.format('YYYY ')}}
                             </span>
                        </div>
                    </mat-expansion-panel-header>
                    <mdb-card-body>
                        <mdb-card-title>
                            <div class="row">
                                <div class="col-lg-6 col-sm-12 week-picker">
                                    <!-- left arrow read and write -->
                                    <button mat-icon-button matTooltip="Previous Week" *ngIf="calendarPermission.roleStatus === 'active'" matTooltipPosition="'above'" class="iconsSize" (click)="previousweek();getCountJobsByDate()">
                                       <mat-icon>chevron_left</mat-icon>
                                    </button>
                                    <!-- left arrow read only -->
                                    <button mat-icon-button matTooltip="Previous Week" *ngIf="calendarPermission.roleStatus === 'readOnly'   " matTooltipPosition="'above'" class="iconsSize">
                                         <mat-icon>chevron_left</mat-icon>
                                     </button>
                                    <div class="week-text">
                                        <p><b> Week {{weekno}} {{date.format('MMMM ')}} {{date.format('YYYY ')}} </b></p>
                                    </div>
                                    <!-- right arrow read and write  -->
                                    <button mat-icon-button matTooltip="Previous Week" *ngIf="calendarPermission.roleStatus === 'active'" matTooltipPosition="'above'" class="iconsSize " (click)="nextweek();getCountJobsByDate()">
                                          <mat-icon>chevron_right</mat-icon>
                                   </button>
                                    <!-- right arrow read only  -->
                                    <button mat-icon-button matTooltip="Previous Week" *ngIf="calendarPermission.roleStatus === 'readOnly' " matTooltipPosition="'above'" class="iconsSize ">
                                       <mat-icon>chevron_right</mat-icon>
                                   </button>
                                </div>
                                <div class="col-lg-6 col-sm-12 form-group text-center">
                                    <input type="text" placeholder="Choose Date" class="form-control" (keydown)="resetPaginationCalender($event)" bsDatepicker name="dateItemPicker" [(ngModel)]="dateItemPicker" (ngModelChange)="getJobsByDate($event)" [bsConfig]="bsConfig">
                                </div>
                            </div>
                            <div class="container" *ngIf="calendarPermission.itemStatus === 'Active'">
                                <table class="table">
                                    <tbody *ngIf="calendarPermission.roleStatus === 'active'">
                                        <tr>
                                            <th class="day-name" *ngFor="let day of days">{{day.name}}</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let day of days">{{day.value}}</th>
                                        </tr>
                                        <tr>
                                            <td class="change-pointer" *ngFor="let item of countJobsByDate" (click)="getJobsByDate(item.date);select(item)" [ngClass]="{active: isActive(item) || item.date == getTodayDate(dateObj)}">
                                                <span class="count-jobs-calender">{{item.countJob}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="calendarPermission.roleStatus === 'readOnly' ">
                                        <tr>
                                            <th *ngFor="let day of days">{{day.name}}</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let day of days">{{day.value}}</th>
                                        </tr>
                                        <tr>
                                            <td class="change-pointer" *ngFor="let item of countJobsByDate">
                                                <span class="count-jobs-calender"> {{item.countJob}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <div *ngIf="calendarPermission.roleStatus === 'notActive'"></div>
                                </table>
                            </div>
                        </mdb-card-title>
                    </mdb-card-body>
                    <table class="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">#Job</th>
                                <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let job of jobsCalender | paginate: { id: 'jobs-Calender',  itemsPerPage:3, currentPage: p2, totalItems: jobsCalender.length };" (click)="storeId(job.id,3)">
                            <tr class="change-pointer">
                                <th class="job-item text-center" scope="row">
                                    <h6> {{job.jobNumber}} <span *ngIf="job.vehicle"> | {{job.vehicle?.plateNumber}}</span></h6>
                                    <hr>
                                    <h6 *ngIf="job.vehicle">
                                        <span> {{job.vehicle?.vehicleType}} <span *ngIf="job.vehicle?.vehicleModel"> | {{job.vehicle?.vehicleModel}}</span></span>
                                    </h6>
                                    <h6 *ngIf="!job.vehicle"> {{job.jobType}}</h6>
                                </th>
                                <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                    <h6 *ngIf="job.primaryCustomerName">{{job.primaryCustomerName}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerName">CustomerName</h6>
                                    <hr>
                                    <h6 *ngIf="job.primaryCustomerType"> {{job.primaryCustomerType}}</h6>
                                    <h6 *ngIf="!job.primaryCustomerType"> Customer Type</h6>
                                </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                <!-- US Date formate -->
                                <td *ngIf="countryCode === true">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'shortDate'}}
                                 {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                            </span> <br>
                                    <span class="end-window">{{job.jobDetailsDateTime.endDateWindowTo | date:'EE'}}, {{job.jobDetailsDateTime.endDateWindowTo | date:'shortDate' }}
                               {{job.jobDetailsDateTime.endTimeWindowTo}}
                             </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                                <!-- Other Country Date formate -->
                                <td *ngIf="countryCode === false">
                                    <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'d/M/yy'}}
                               {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                          </span> <br>
                                    <span class="end-window">{{job.jobDetailsDateTime.endDateWindowTo | date:'EE'}}, {{job.jobDetailsDateTime.endDateWindowTo | date:'d/M/yy' }}
                             {{job.jobDetailsDateTime.endTimeWindowTo}}
                           </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                </td>
                            </tr>
                            <tr *ngIf="job.jobDetailsProgress">
                                <td colspan="1"><strong>{{job.jobDetailsProgress.subject}} :</strong> </td>
                                <td colspan="3" class="progress-status">{{job.jobDetailsProgress.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center mt-3" *ngIf="jobsCalender.length > 0 ">
                        <pagination-controls id="jobs-Calender" (pageChange)="p2 = $event"></pagination-controls>
                    </div>
                    <div class="ant-empty-image" *ngIf="jobsCalender.length <= 0 ">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mdb-card-body>
    </mdb-card>
</div>
<!-- Modal to display job details -->
<!-- <div mdbModal id="basicModal" #basicModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Job Summary</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body">
                    <div class="item-short">
                        <h6>Job Number:</h6> <span>{{jobModel.jobNumber}}</span>
                    </div>
                    <div class="item-short">
                        <h6>Job Status : </h6> <span>{{jobModel.jobStatus}}</span>
                    </div>
                    <br>
                    <div class="item">
                        <h6>Date & Time Window : </h6>
                        <span *ngFor="let date of jobModel.datesAndTimes"> {{date.summaryDate}}.

                      </span>
                    </div>
                    <br>
                    <div class="item" *ngFor="let step of jobModel.steps">
                        <h6>Step {{step.priority}} : </h6>
                        <span>{{step.addressOne}}. {{step.addressTow}}, {{step.city}}, {{step.zip}} {{step.state}}.</span>
                    </div>
                    <br>
                    <br>
                    <div class="item-short">
                        <h6>Customer : </h6>
                        <span>{{jobModel.primaryCustomerName}}</span>
                    </div>
                    <div class="item-short">
                        <h6>Email : </h6>
                        <span>{{email}}</span>
                    </div>
                    <div>
                        <div class="job-lunch" (click)="redirection()">
                            <button class="btn btn-primary">Launch Job Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Modal to display job details -->
<div mdbModal id="basicModal" #basicModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Job Summary</h5>
                    <button type="button" (click)="basicModal.hide()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="col-lg-12 modal-job">
                    <div class="customer-info">
                        <h5>Customer Information</h5>
                        <div>
                            <div class="item">
                                <h6>Customer </h6>
                                <a *ngIf="jobModel.primaryCustomerName != undefined ">{{jobModel.primaryCustomerName}}</a>
                                <a *ngIf="jobModel.primaryCustomerName == undefined ">CustomerName</a>
                            </div>
                            <div class="item">
                                <h6>Phone </h6>
                                <a href="tel:person.phone">{{person.phone}}</a>
                            </div>
                            <div class="item">
                                <h6>Email </h6>
                                <a href="mailto:person.email">{{person.email}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="job-info">
                        <h5>Job Information</h5>
                        <div class="item">
                            <h6>Job Number </h6>
                            <a>{{jobModel.jobNumber}}</a>
                        </div>
                        <div class="item">
                            <h6>Customer Name </h6>
                            <a>{{jobModel.primaryCustomerName}}</a>
                        </div>
                        <div class="item">
                            <h6>Status </h6>
                            <a>{{jobModel.jobStatus}}</a>
                        </div>
                        <div class="item" *ngFor="let dateTime of jobModel.datesAndTimes">
                            <h6> Date & Time Window </h6>
                            <a>{{dateTime.summaryDate}}</a>
                        </div>
                    </div>
                    <div class="text-center mt-3 mb-3" [routerLink]="['/user/job-details/',publicId]">
                        <button class="btn mx-auto btn-sm btn-primary" (click)="basicModal.hide()">Launch Job Details</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal confirmation -->
<div mdbModal #newJobModal="mdbModal" class="modal modal-clone">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Use a template</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="newJobModal.hide()">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body text-center px-0">
                    <!-- <div class="col-12 mb-2 mr-0 ml-0  " (click)="newJob();newJobModal.hide()">
                        <div class="btn btn-success ">
                            New Draft Job
                        </div>
                    </div> -->
                    <mdb-card class="jobs">
                        <table class="table ">
                            <!-- <thead>
                                <tr>
                                    <th class="heading-clone" scope="col">
                                        <strong *ngIf="jobsClone.length > 0">Use a template</strong>
                                        <strong *ngIf="jobsClone.length <= 0">Template</strong>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead> -->
                            <tbody *ngFor="let job of jobsClone | paginate: { id: 'clone_id',  itemsPerPage:3, currentPage: p5}">
                                <tr class="change-pointer">
                                    <th scope="row">
                                        <h6> {{job.jobNumber}}</h6>
                                        <hr>
                                        <h6> {{job.jobType}}</h6>
                                    </th>
                                    <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                        <h6 *ngIf="job.primaryCustomerName">{{job.primaryCustomerName}}</h6>
                                        <h6 *ngIf="!job.primaryCustomerName">CustomerName</h6>
                                        <hr>
                                        <h6 *ngIf="job.primaryCustomerType"> {{job.primaryCustomerType}}</h6>
                                        <h6 *ngIf="!job.primaryCustomerType"> Customer Type</h6>
                                    </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                    <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                    <!-- US Date formate -->
                                    <td *ngIf="countryCode === true">
                                        <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'shortDate'}}
                               {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                          </span> <br>
                                        <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | date:'EE' }}, {{job.jobDetailsDateTime.endDateWindowTo | date:'shortDate' }}
                             {{job.jobDetailsDateTime.endTimeWindowTo}}
                           </span><br> {{job.jobDetailsDateTime.day}}
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                    </td>
                                    <!-- Other Country Date formate -->
                                    <td *ngIf="countryCode === false">
                                        <span class="start-window">{{job.jobDetailsDateTime?.startDateWindowFrom | date:'EE'}}, {{job.jobDetailsDateTime?.startDateWindowFrom | date:'d/M/yy'}}
                             {{job.jobDetailsDateTime?.startTimeWindowFrom }}
                        </span> <br>
                                        <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | date:'EE' }},  {{job.jobDetailsDateTime.endDateWindowTo | date:'d/M/yy' }}
                           {{job.jobDetailsDateTime.endTimeWindowTo}}
                         </span><br> {{job.jobDetailsDateTime.day}}
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == true "> Day, One Date</span>
                                        <span *ngIf="job.jobDetailsDateTime?.isNewSequence == false "> Days ,Many Dates</span>
                                    </td>
                                </tr>
                                <tr *ngIf="job.jobDetailsProgress">
                                    <td colspan="1"><strong>{{job.jobDetailsProgress.subject}} :</strong> </td>
                                    <td colspan="3" class="progress-status">{{job.jobDetailsProgress.description}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <button class="btn btn-sm btn-danger" (click)="storeBufferJobId(job.id);clonePinModal.show();newJobModal.hide()">unpin</button>
                                        <button class="btn btn-sm btn-success" (click)="storeBufferJobId(job.id);colneJobModal.show();newJobModal.hide()">Clone</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="ant-empty-image" *ngIf="jobsClone.length <= 0 ">
                            <img src="./assets/img/empty.svg" alt="empty-img">
                            <span>No pinned template yet</span>
                        </div>

                    </mdb-card>
                    <div class="text-center mt-3" *ngIf="jobsClone.length > 0 ">
                        <pagination-controls id="clone_id" (pageChange)="p5 = $event"></pagination-controls>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <div class="btn btn-sm btn-light" (click)="newJobModal.hide()">Cancel</div>
                    <div class="btn btn-sm btn-success" (click)="newJob();newJobModal.hide()">Yes</div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div mdbModal id="clonePinModal" #clonePinModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pin to Quick Clone </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="clonePinModal.hide()">
                 <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <span>When unpinned, the user will NOT be able to clone this job from the [Create a new draft job] button.</span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-success" (click)="unpinCloneJob(bufferJobId);clonePinModal.hide()"> Confirm </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- colne job -->
<div mdbModal #colneJobModal="mdbModal" class="modal fade">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Clone Job </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="colneJobModal.hide()">
                     <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body ">
                    <strong class="text-left">
                      <span>When cloned: </span> <br>
                      <span>1- The following tools will have the same information: <br>
                    <span> - Customer information</span><br>
                      <span> - Assigned customer(s)</span><br>
                      <span> - Job Status</span><br>
                      <span> - Assigned Trucks</span><br>
                      <span> - Location(s)</span><br>
                      </span>
                      <span>2 - All other tools will have the default information.</span>
                  </strong>
                </div>
                <div class="modal-footer text-center">
                    <button type="button " class="btn btn-sm btn-danger " (click)="colneJobModal.hide()">Cancel</button>
                    <button type="button " class="btn btn-sm btn-success " (click)="coloneJob(bufferJobId);colneJobModal.hide()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal confirmation -->
<div mdbModal #newJobWithoutCloneModal="mdbModal" class="modal modal-clone">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content ">
                <div class="modal-header">
                    <h5 class="modal-title">New Draft Job</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="newJobWithoutCloneModal.hide()">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center ">
                    <p class="mt-3">Create a New Draft Job!</p>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-sm btn-light" (click)="newJobWithoutCloneModal.hide()">Cancel</div>
                    <div class="btn btn-sm btn-success" (click)="newJob();newJobWithoutCloneModal.hide()">Yes</div>
                </div>
            </div>
        </div>
    </div>
</div>
