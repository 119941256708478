import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';
import { AssignedType } from './../../../../../models/Enum';
import { JobsService } from './../../../../../core/_services/jobs/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { PersonService } from './../../../../../core/_services/person/person.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { buffer } from 'rxjs/operators';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { ModalDirective } from 'angular-bootstrap-md';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-assigned-users',
  templateUrl: './assigned-users.component.html',
  styleUrls: ['./assigned-users.component.scss']
})
export class AssignedUsersComponent implements OnInit {
  assignedUsers: any[] = []
  users: any[] = []
  thirdPartyUsers: any[] = []
  isAssigned = false
  jobId: any = {}
  role: any
  allUsers: any = []
  searchText = '';
  p1: number = 1;
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  user: any = {}
  adminId: string = ''
  usersAssignedNotAdminAndManagers: any[] = []
  bufferItem: any = {}
  jobsTools: any = {}
  settingsTools: any = {}
  assignedUsersObj: any = {}
  usersObj: any = {}
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  totalUserCompany: number = 0
  totalUser3rd: number = 0
  @ViewChild('sendEmailassignedUserModal') sendEmailassignedUserModal: ModalDirective;
  @ViewChild('assignedUserModal') assignedUserModal: ModalDirective;
  creationUserId:string=''
  constructor(
    private _jobsService: JobsService,
    private route: ActivatedRoute,
    private router: Router,
    private _toolsService: ToolsService,
    private socket: Socket,
    private _personService: PersonService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        if (response['jobs'].success) {
          this.assignedUsers = response['jobs'].data.assignedUsers
          this.totalUser3rd = response['jobs'].data.totalUser3rd
          this.totalUserCompany = response['jobs'].data.totalUserCompany
          this.jobId = response['jobs'].data.id
          this.items = response['jobs'].data.showItems
          this.creationUserId = response['jobs'].data.creationUserId
          if (this.role == 'admin') {
            this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.assignedUsers)
            if(this.userPermission.itemStatus == 'Active') {
              this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
            }
            this.getUsers()
          }
          if (this.role != 'admin' && this.role != "manager") {
            this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.assignedUsers, this.user.id)
            if (this.userPermission.value != this.statusActive.InActive) {
              this.getUsers()
            }
          }
          if (this.role == "manager") {
            this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assignedUsers, this.user.id)
            if (this.userPermission.value != this.statusActive.InActive) {
              this.getUsers()
            }
          }
        }
      }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Assigned_User, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.id) {
        this._jobsService.getJobById(this.jobId).subscribe((response: ResponseModel) => {
          this.assignedUsers = response.data.assignedUsers
          this.totalUser3rd = response.data.totalUser3rd
          this.totalUserCompany = response.data.totalUserCompany
        })
      }
    });
  }
    checkItemPlan(){
    if(this._jobsService.getToolPlan(this.items,ToolJobsEditItem.assignedUsers) > this.user.companyPlan){
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getUsers() {
    this._personService.getUsersViewByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        let companyUsers = []
        let thirdPartyUsers = []
        companyUsers = response.data
        thirdPartyUsers = response.data
        companyUsers = companyUsers.filter(u => u.role != "admin")
        companyUsers = companyUsers.filter(u => u.role != "3rdparty")
        this.users = companyUsers.filter(u => u.role != "manager")
        if(this.user.role == "teamleader"){
          this.users = companyUsers.filter(u => u.role != "manager" && u.role != "teamleader")
        }
        if(this.user.role == "crew"){
          this.users = companyUsers.filter(u =>u.role != "manager" && u.role != "teamleader" && u.role != "crew")
        }
        if(this.user.role == "3rdparty"){
          this.users = companyUsers.filter(u =>u.role != "manager" && u.role != "teamleader" && u.role != "3rdparty" && u.role != "crew")
        }
        this.adminId = response.data.find(u => u.role == 'admin').id
        this.thirdPartyUsers = thirdPartyUsers.filter(a => a.role != 'admin')
        this.thirdPartyUsers = thirdPartyUsers.filter(a => a.role === '3rdparty')
        if(this.user.role == "3rdparty"){
          this.thirdPartyUsers = thirdPartyUsers.filter(a => a.role != "admin" && a.role != "manager" && a.role != "teamleader" && a.role != "3rdparty" && a.role != "crew")
        }
        this.removeAdminFromAssignedUsers()
        this.checkItemPlan()
      }
    })
  }
  removeAdminFromAssignedUsers() {
    this.usersAssignedNotAdminAndManagers = this.assignedUsers
    for (let index = 0; index < this.usersAssignedNotAdminAndManagers.length; index++) {
      if (this.usersAssignedNotAdminAndManagers[index] === this.adminId) {
        this.usersAssignedNotAdminAndManagers.splice(index, 1);
      }
    }
  }
  storeItem(item, isAssigned: boolean) {
    // console.log('isAssigned', isAssigned)
    this.bufferItem = item
    // let notify: any = {}
    // if (isAssigned) {
    //   notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.assignedUsers, EmailNotification.Email_Notification__Users_Assigned_Job)
    // } else {
    //   notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.assignedUsers, EmailNotification.Email_Notification__Users_Unassigned_Job)
    // }
    // if (notify.roleStatus && notify.roleStatus == 'active' && notify.notifyStatus && notify.notifyStatus == 'Active') {
    //   this.sendEmailassignedUserModal.show()
    //   this.isCustomerEmail = Is_Customer_Email.Send_Email
    //   console.log('', this.isCustomerEmail)
    // }
    // else {
    //   this.assignedUserModal.show()
    //   this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
    // }
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  assignedFun(item: any) {
    let isExists = false;
    for (let index = 0; index < this.assignedUsers.length; index++) {
      if (this.assignedUsers[index] === item.id) {
        // this.assignedUsers.splice(index, 1);
        isExists = true
      }
    }
    if (!isExists) {
      // this.assignedUsers.push(item.id)
      isExists = false;
    }
    let model = {
      assignedType: AssignedType.USER,
      isAssigned:  !isExists,
      id : item.id
    }
    let itemId = ToolJobsEditItem.assignedUsers
    this._jobsService.updateJobAssigned(this.jobId, model, itemId, Is_Customer_Email.Not_Send_Email).subscribe((response: ResponseModel) => {
      this.assignedUsers = response.data.assignedUsers
      this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
      this.removeAdminFromAssignedUsers()
    }, error => {
      console.log(error)
    })
  }
  checkActive(item) {
    for (let index = 0; index < this.assignedUsers.length; index++) {
      if (this.assignedUsers[index] === item.id) {
        return true
      }
    }
    return false
  }
  invite() {
    // this.router.navigate(['/admin/invite'])
    if (this.role == 'manager' || this.role == 'admin') {
      localStorage.setItem('isThirdParty', 'true')
      this.router.navigate(['/admin/invite'])
    }
    else {
      localStorage.setItem('isThirdParty', 'true')
      this.router.navigate(['/user/invite'])
    }
  }
  resetPaginationUser(event){
    this.p1 = 1
  }
}
