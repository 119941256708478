import { ResponseModel } from './../../../models/response/ResponseModel';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success-strip',
  templateUrl: './success-strip.component.html',
  styleUrls: ['./success-strip.component.scss']
})
export class SuccessStripComponent implements OnInit {
user:any={}
  constructor(
    // private route: ActivatedRoute,
    // private _carWashService: CarWashService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    // this.route.queryParams.subscribe(params => {
    //   if (params.vehicleId != undefined || params.vehicleId != null) {
    //       this._carWashService.updateVehicleMemberShip(params.vehicleId).subscribe((response: ResponseModel) => {
    //         if (response.success) {

    //         }
    //       })
    //   }
    // });
  }
  updateVehicleMemberShip(){
    // window.close()
    if(this.user.role == 'admin' || this.user.role == 'manager'){
      this.router.navigate(['/admin/vehicles-list'])
    }
    else if(this.user.role == 'customer' ){
      this.router.navigate(['/user/view-vehicle/',this.user.id])
    }
    else{
    this.router.navigate(['/user/vehicles-list'])
    }
  }
}
