<div class="section-tool-job" *ngIf="userPermission.value != statusActive.InActive  ">
    <div class="tittle-section">
        <p>
            <!-- <img src="./assets/img/icons/crm_icons/note.svg" class="pr-1" alt="note-img"> -->
            <!-- <i class="fas fa-sticky-note pr-1"></i> -->
            <i class="uil uil-notebooks  uil-medium pr-1"></i>
            <span>Note(s)</span>
        </p>
        <div class="btn-add-new" (click)="basicModal.show() " *ngIf="userPermission.value == statusActive.Active ">
            <span class="badge badge-success">
        <i class="far fa-plus-square"></i>
        Add New
      </span>
        </div>
        <!-- read only  -->
        <!-- <div class="btn-add-new" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
            <span class="badge badge-success">
         <i class="far fa-plus-square"></i>
        Add New
      </span>
        </div> -->
    </div>
    <div class="inner-section">
        <div class="ant-empty-image" *ngIf="notes.length <= 0 ">
            <img src="./assets/img/empty.svg" alt="empty-img">
            <span>No Data</span>
        </div>
        <mat-accordion class="example-headers-align " multi *ngFor="let item of notes | paginate: {id:'note', itemsPerPage: 3, currentPage: p  };let i=index">
            <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                <mat-expansion-panel-header>
                    {{item.addBy}} <span class="subject pl-1"> ({{item.subject}})</span>
                    <div class="title-info">
                        <span>{{item.date | date: 'MMM d, y, h:mm a'}} </span>
                    </div>
                </mat-expansion-panel-header>
                <ngx-flip [flip]="flipDiv">
                    <div front [style.display]="!flipDiv ? 'block' : 'none'">
                        <div class="card">
                            <div class="title-card" *ngIf="userPermission.value == statusActive.Active && item.userId == user.id ">
                                <button class="btn btn-success" (click)="storId(item);onClick()"><i class="fas fa-edit"></i> Edit</button>
                                <!-- <button class="btn btn-danger" (click)="storId(item);deleteModal.show()"> <i class="fas fa-trash-alt"></i> Delete  </button> -->
                            </div>
                            <!-- read only -->
                            <!-- <div class="title-card" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
                                <button class="btn btn-success"><i class="fas fa-edit"></i> Edit</button>
                                <button class="btn btn-danger">
                              <i class="fas fa-trash-alt"></i> Delete
                         </button>
                            </div> -->
                            <div class="row">
                                <!-- <div class="col-sm-12">
                                    <div class="item">
                                        <h6>Add By</h6>
                                        <label>{{item.addBy}}</label>
                                    </div>
                                </div> -->
                                <div class="col-sm-12">
                                    <div class="item">
                                        <h6>Subject</h6>
                                        <label>{{item.subject}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="item">
                                        <h6>Description </h6>
                                        <label>{{item.description}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div back [style.display]="flipDiv ? 'block' : 'none'">
                        <div class="card back">
                            <div class="title-card" *ngIf="userPermission.value == statusActive.Active  ">
                                <button class="btn btn-primary" (click)="onClick()"><i class="fas fa-backward"></i> Back</button>
                                <button class="btn btn-success" form="editForm" [disabled]="editForm.invalid" (click)="editModal.show()"><i class="far fa-save pr-1"></i>Save</button>
                            </div>
                            <!-- read only -->
                            <div class="title-card" *ngIf="userPermission.value == statusActive.Read_Only ">
                                <button class="btn btn-primary"><i class="fas fa-backward"></i> Back</button>
                                <button class="btn btn-success"><i class="far fa-save pr-1"></i>Save</button>
                            </div>
                            <form #editForm="ngForm" id="editForm">
                                <!-- This is older -->
                                <div class="row mt-4">
                                    <!-- <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Add By:</label>
                                            <input type="text" name="userName" required class="form-control" [(ngModel)]="userName" />
                                        </div>
                                    </div> -->
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Subject <span class="required">*</span>:</label>
                                            <input type="text" name="subject" required class="form-control" minlength="1" maxlength="12" #esubject="ngModel" [(ngModel)]="editNoteModel.subject" />
                                            <div *ngIf="esubject.errors">
                                                <div *ngIf="esubject.errors.minlength" class="alert alert-danger">
                                                    Limited to 12 characters.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Description <span class="required">*</span>:</label>
                                            <textarea name="description " required [(ngModel)]="editNoteModel.description " class="form-control " cols="30 " rows="8 "></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ngx-flip>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="text-center mt-4" *ngIf="notes.length > 0 ">
            <pagination-controls id="note" (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>
<!-- Add Note Modal  -->
<div mdbModal #basicModal="mdbModal" class="modal " id="exampleModal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">New Note</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form #addForm="ngForm" id="addForm">
                        <!-- <div class=" form-group">
                            <label for="name ">Add By :</label>
                            <input type="text " placeholder="Name " name="name " [(ngModel)]="noteModel.name " class="form-control " />
                        </div> -->
                        <div class=" form-group">
                            <label for="subject">Subject <span class="required">*</span> :</label>
                            <input type="text " placeholder="Subject " required name="subject" [(ngModel)]="noteModel.subject " minlength="1" maxlength="12" #usubject="ngModel" class="form-control " />
                            <div *ngIf="usubject.errors">
                                <div *ngIf="usubject.errors.minlength" class="alert alert-danger">
                                    Limited to 12 characters.
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label for="description ">Note Description <span class="required">*</span> :</label>
                            <textarea name="description" required [(ngModel)]="noteModel.description " class="form-control " placeholder="Note Description " cols="30 " rows="8 "></textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="basicModal.hide() ">Close</button>
                    <button type="button " class="btn btn-success " form="addForm " [disabled]="addForm.invalid " (click)="addNoteNotSendModal.show();storeFrom(addForm);basicModal.hide()">Add </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Delete Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Note</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <p> Delete a note ?</p>
                    <!-- <p class="text-secondary"><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-primary " data-dismiss="modal " (click)="deleteModal.hide() ">Close</button>
                    <button type="button " class="btn btn-danger " (click)="deleteNote();deleteModal.hide() ">Delete </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Edit Note Modal -->
<div mdbModal #editModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="editModal.hide()" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body text-center">
                    <p>Change Note Information?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="editModal.hide()">Close</button>
                    <button type="button" class="btn btn-success" (click)="checkEditSendEmail();editModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Not Send Date and time -->
<div mdbModal id="noteNotSendModal" #noteNotSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="noteNotSendModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Change Note Information? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="noteNotSendModal.hide()"> Cancel
          </button>
                    <button type="button " class="btn btn-success " (click)="updateNote();noteNotSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send Date and time -->
<div mdbModal id="noteSendModal" #noteSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();noteSendModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();updateNote();noteSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="updateNote();noteSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send date and time -->
<div mdbModal id="addNoteSendModal" #addNoteSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();addNoteSendModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();addNote(noteBufferForm);addNoteSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="addNote(noteBufferForm);addNoteSendModal.hide() ">
            Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Not Send date and time -->
<div mdbModal id="addNoteNotSendModal" #addNoteNotSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addNoteNotSendModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Add a New Note? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="resetAddForm();addNoteNotSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="checkAddSendEmail(noteBufferForm);addNoteNotSendModal.hide() ">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>