import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers-messaging',
  templateUrl: './customers-messaging.component.html',
  styleUrls: ['./customers-messaging.component.scss']
})
export class CustomersMessagingComponent implements OnInit {
  explanations: any[] = []
  pattern: any = "<<Job Number>>"
  Status: any[] = []
  jobStatus: any = "Draft"
  dateTimeChange:any="InActive"
  stopChange:any="Active"
  userAssigned:any="InActive"
  truckAssigned:any="InActive"
  noteAdded:any="Active"
  constructor() { }
  ngOnInit() {
    this.explanations = [{
      name: "Job Number",
      value: "<<Job Number>>"
    },
    {
      name: "Job Date and Time",
      value: "<<Job Date and Time>>"
    },
    {
      name: "Job Status",
      value: "<<Job Status>>"
    },
    {
      name: "Customer first and last name",
      value: "<<Customer first and last name>>"
    },
    {
      name: "Customer porta Link to signup",
      value: "<<Customer porta Link to signup>>"
    },
    {
      name: "Company Logo",
      value: "<<Company Logo>>"
    },
    {
      name: "Company name",
      value: "<<Company name>>"
    },
    {
      name: "Company 1 line address",
      value: "<<Company 1 line address>>"
    },
    {
      name: "Company many lines address",
      value: "<<Company many lines address>>"
    },
    {
      name: "Company phone number",
      value: "<<Company phone number>>"
    },
    {
      name: "Company web site Link",
      value: "<<Company web site Link>>"
    }
    ]
    this.Status = [
      { name: "Active" },
      { name: "InActive" }
    ]
  }
  onOptionsSelected(value: string) {
    this.pattern = value
  }
  onOptionsDate(value){
    this.dateTimeChange=value
  }
  onOptionsStop(value){
    this.stopChange=value
  }
  onOptionsUserAssigned(value){
    this.userAssigned=value
  }
  onOptionsTruckAssigned(value){
    this.truckAssigned=value
  }
  onOptionsNoteAdded(value){
    this.noteAdded=value
  }
  statusJobSelected(value: string) {
    this.jobStatus = value
  }
}
