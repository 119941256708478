<div class="Location section-tool-job" *ngIf="userPermission.value != statusActive.InActive ">
    <div class="tittle-section">
        <p>
            <!-- <i class="fas fa-stop pr-1"></i> -->
            <i class="uil uil-map-pin-alt  uil-medium pr-1"></i>
            <!-- <img src="./assets/img/icons/crm_icons/add_step.svg" class="pr-1" alt="note-img"> -->
            <span>Location(s)</span>
        </p>
        <div class="btn-add-new" (click)="resetCountry();addStopModal.show()" *ngIf="userPermission.value == statusActive.Active  ">
            <span class="badge badge-success">
            <i class="far fa-plus-square"></i>
             Add New
           </span>
        </div>
        <!-- <div class="btn-add-new" (click)="getLocation()">
            <span class="badge badge-success">
          <i class="far fa-plus-square"></i>
           get Location
         </span>
        </div> -->
        <!-- read only -->
        <!-- <div class="btn-add-new" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
            <span class="badge badge-success">
          <i class="far fa-plus-square"></i>
           Add New
         </span>
        </div> -->
    </div>
    <div class="inner-section">
        <div class="map-container">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>Locations on Map</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div #mapContainer id="map"></div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <mat-accordion class="example-headers-align " multi *ngFor="let item of steps | NoteFilter: stepText | paginate: {id:'stepId', itemsPerPage: 3, currentPage: p  }">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Location {{item.priority}}
                        <div class="title-info">
                            <span>{{item.city}}, {{item.state}}, {{item.country.name}}</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card">
                    <!-- <div class="card-title">
                        <label> Location Priority {{item.priority}}</label>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> Location Priority </h6>
                                <label>{{item.priority}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> Address 1 </h6>
                                <label>{{item.addressOne}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> Address 2 </h6>
                                <label>{{item.addressTow}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> City </h6>
                                <label>{{item.city}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> State </h6>
                                <label>{{item.state}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> Zip Code </h6>
                                <label>{{item.zip}}</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="item">
                                <h6> Country </h6>
                                <label>{{item.country.name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="button-group" *ngIf="userPermission.value == statusActive.Active ">
                        <button class="btn btn-primary" (click)="isEditFlag();storeId(item);editModal.show()">Edit Location</button>
                        <button class="btn btn-danger" *ngIf="item.totalItems <= 0" (click)="deleteModal.show();storeId(item)">Delete Location</button>
                    </div>
                    <!-- read only -->
                    <!-- <div class="button-group" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
                    <button class="btn btn-primary">Edit Step</button>
                    <button class="btn btn-danger">Delete Step</button>
                </div> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="text-center mt-4" *ngIf="steps.length > 0 ">
            <pagination-controls id="stepId" (pageChange)="p = $event "></pagination-controls>
        </div>
        <div class="ant-empty-image" *ngIf="steps.length <= 0 ">
            <img src="./assets/img/empty.svg" alt="empty-img">
            <span>No Data</span>
        </div>
    </div>
</div>
<!-- <input class="input" type="text" #addressText [(ngModel)]="autocompleteInput" style="padding: 12px 20px; border: 1px solid #ccc; width: 400px"> -->
<!-- <input matInput class="form-control" matGoogleMapsAutocomplete (onAutocompleteSelected)="onAutocompleteSelected($event)" (onLocationSelected)="onLocationSelected($event)"> -->
<!-- <input class="input" type="text" [(ngModel)]="autocompleteInput" #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 400px"> -->
<!-- Add Stop Modal -->
<div mdbModal #addStopModal="mdbModal" class="modal InventoryModal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New Location</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addStopModal.hide()">
                       <span aria-hidden="true">&times;</span>
                     </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="StopForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-primary">
                                    You Can Change the Location Priority any Time Later.
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <select #mySelect class="browser-default custom-select form-control custom" (change)='selectAddressExists(mySelect.value,false)'>
                                    <option value="" selected disabled>Quick Fill</option>
                                    <option *ngFor="let address of stepsArr" [value]="address.id">{{address.name}}</option>
                               </select>
                                <div *ngIf="f.priority.touched && f.priority.invalid" class="invalid-feedback">
                                    <div *ngIf="f.priority.errors.required">Priority is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <select class="browser-default custom-select form-control" formControlName="priority" [ngClass]="{'is-invalid':f.priority.errors && f.priority.touched}">
                                        <option value="" disabled selected>Choose Priority *</option>
                                        <option *ngFor="let item of letters" [value]="item">{{item}}</option>
                                   </select>
                                <div *ngIf="f.priority.touched && f.priority.invalid" class="invalid-feedback">
                                    <div *ngIf="f.priority.errors.required">Priority is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" #addressText formControlName="addressOne" placeholder="Address 1 *" [ngClass]="{'is-invalid':f.addressOne.errors && f.addressOne.touched}" />
                                <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="invalid-feedback">
                                    <div *ngIf="f.addressOne.errors.required">Address One is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" placeholder="Address 2" formControlName="addressTow" />
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" formControlName="city" placeholder="City *" [ngClass]="{'is-invalid':f.city.errors && f.city.touched}" />
                                <div *ngIf="f.city.touched && f.city.invalid" class="invalid-feedback">
                                    <div *ngIf="f.city.errors.required">City is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" formControlName="state" placeholder="State *" [ngClass]="{'is-invalid':f.state.errors && f.state.touched}" />
                                <div *ngIf="f.state.touched && f.state.invalid" class="invalid-feedback">
                                    <div *ngIf="f.state.errors.required">State is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" formControlName="zip" placeholder="Zip Code *" [ngClass]="{'is-invalid':f.zip.errors && f.zip.touched}" />
                                <div *ngIf="f.zip.touched && f.zip.invalid" class="invalid-feedback">
                                    <div *ngIf="f.zip.errors.required">Zip Code is required</div>
                                    <div *ngIf="f.zip.errors.pattern">Zip Code is numbers only</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <mat-select-country class="control" label="{{defaultValue?.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                                </mat-select-country>
                            </div>
                            <div class="col-12 text-center mt-1">
                                <!-- <button type="button" mdbBtn class="btn btn-sm" color="primary">Back</button> -->
                                <button type="button" mdbBtn class="btn btn-sm" color="success" [disabled]="StopForm.invalid" (click)="checkAddSendEmail();addStopModal.hide()">Add </button>
                            </div>
                        </div>
                        <!-- </mat-step> -->
                        <!-- </mat-horizontal-stepper> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Stop Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Location</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body text-center">
                    <p>Delete Location?</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-light " data-dismiss="modal " (click)="deleteModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-success " (click)="deleteStop();deleteModal.hide() ">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Edit Stop Modal -->
<div mdbModal #editModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit Location {{editStepModel.priority}}</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editModal.hide()">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body">
                    <form #stepForm="ngForm" id="stepForm">
                        <div class="row">
                            <div class="col-lg-6">
                                <select #mySelect1 class="browser-default custom-select form-control custom" (change)='selectAddressExists(mySelect1.value,true)'>
                                          <option value="" selected disabled>Quick Fill</option>
                                         <option *ngFor="let address of stepsArr" [value]="address.id">{{address.name}}</option>
                                </select>
                                <!-- <div *ngIf="f.priority.touched && f.priority.invalid" class="invalid-feedback">
                                    <div *ngIf="f.priority.errors.required">Priority is required</div>
                                </div> -->
                            </div>
                            <div class="col-sm-6">
                                <!-- <input type="text " placeholder="Enter Step Priority" required name="priority" [(ngModel)]="editStepModel.priority" #priority="ngModel" class="form-control " /> -->
                                <select class="browser-default custom-select form-control" name="priority" [(ngModel)]="editStepModel.priority" #priority="ngModel" [ngClass]="{'is-invalid':f.priority.errors && f.priority.touched}">
                                  <option *ngFor="let item of letters" [value]="item">{{item}}</option>
                             </select>
                                <div *ngIf="priority.errors && priority.touched" class="alert alert-danger">
                                    <div *ngIf="priority.errors.required">
                                        Priority is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <input type="text " placeholder="Address 1 *" name="addressOne" required [(ngModel)]="editStepModel.addressOne" #addressText1 class="form-control " />
                                <!-- <div *ngIf="addressText.errors && addressText.touched" class="alert alert-danger">
                                    <div *ngIf="addressText.errors.required">
                                        Address 1 is required.
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-sm-6">
                                <input type="text " placeholder="Address 2 " name="addressTow" [(ngModel)]="editStepModel.addressTow" class="form-control " />
                            </div>
                            <div class="col-sm-6">
                                <input type="text " placeholder="City *" required name="city" [(ngModel)]="editStepModel.city" #city="ngModel" class="form-control " />
                                <div *ngIf="city.errors && city.touched" class="alert alert-danger">
                                    <div *ngIf="city.errors.required">
                                        City is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" placeholder="State *" required name="state" [(ngModel)]="editStepModel.state" #state="ngModel" class="form-control " />
                                <div *ngIf="state.errors && state.touched" class="alert alert-danger">
                                    <div *ngIf="state.errors.required">
                                        State is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <input type="text " placeholder="Zip Code *" required name="zip" [(ngModel)]="editStepModel.zip" [pattern]="numberPattern" #zip="ngModel" class="form-control " />
                                <div *ngIf="zip.errors && zip.touched" class="alert alert-danger">
                                    <div *ngIf="zip.errors.required">
                                        Zip Code is required.
                                    </div>
                                    <div *ngIf="zip.errors.pattern">
                                        Zip Code is numbers only
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                                </mat-select-country>
                            </div>

                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-success " from="stepForm" [disabled]="!stepForm.valid" (click)="checkEditSendEmail(stepForm);editModal.hide() ">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm No tSend Date and time -->
<div mdbModal id="stepNotSendModal" #stepNotSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="stepNotSendModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Change Location Information? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="stepNotSendModal.hide()"> Cancel </button>
                    <button type="button " class="btn btn-success " (click)="editStep(stepBufferForm);stepNotSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send Date and time -->
<div mdbModal id="stepSendModal" #stepSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();stepSendModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();editStep(stepBufferForm);stepSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="editStep(stepBufferForm);stepSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send date and time -->
<div mdbModal id="addStepSendModal" #addStepSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();addStepSendModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();addStep();addStepSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="addStep();addStepSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Not Send date and time -->
<div mdbModal id="addStepNotSendModal" #addStepNotSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addStepNotSendModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Add a New Location? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="addStepNotSendModal.hide() "> Cancel </button>
                    <button type="button " class="btn btn-success " (click)="addStep();addStepNotSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- can't delete Location -->
<div mdbModal id="notDeleteModal" #notDeleteModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warining</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="notDeleteModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Can't Delete Location!</p>
                    <!-- <p  ><small></small></p> -->
                </div>
            </div>
        </div>
    </div>
</div>
