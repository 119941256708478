<router-outlet>
</router-outlet>
<a id="button" (click)="scrollTop()"><i class="fa fa-chevron-up"></i></a>
<!-- <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate">

</mat-progress-bar> -->
<!--
<div class="loader" *ngIf="_loaderService.isLoading | async">
    <mat-spinner style="top:50%;left:50%"></mat-spinner>
</div> -->
<app-Loader></app-Loader>

<!-- RealTime  -->
<!-- <div *ngFor="let message of messageList">

</div> -->

<!-- <input [(ngModel)]="newMessage" (keyup)="$event.keyCode == 13 && sendMessage()" />
<button (click)="sendMessage()">Send Message</button> -->