import { Component, OnDestroy, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {
//
// availableDevices: MediaDeviceInfo[];
// currentDevice: MediaDeviceInfo = null;

// formatsEnabled: BarcodeFormat[] = [
//   BarcodeFormat.CODE_128,
//   BarcodeFormat.DATA_MATRIX,
//   BarcodeFormat.EAN_13,
//   BarcodeFormat.QR_CODE,
// ];

// hasDevices: boolean;
// hasPermission: boolean;
// qrResultString: string;
// torchEnabled = false;
// torchAvailable$ = new BehaviorSubject<boolean>(false);
// tryHarder = false;
  constructor(
    // private readonly _dialog: MatDialog
  ) { }

  ngOnInit() {
  }
//

// clearResult(): void {
//   this.qrResultString = null;
// }

// onCamerasFound(devices: MediaDeviceInfo[]): void {
//   this.availableDevices = devices;
//   this.hasDevices = Boolean(devices && devices.length);
// }

// onCodeResult(resultString: string) {
//   this.qrResultString = resultString;
//   window.location.href = this.qrResultString
//   // window.open(this.qrResultString,"_self")
// }

// onDeviceSelectChange(selected: string) {
//   const device = this.availableDevices.find(x => x.deviceId === selected);
//   this.currentDevice = device || null;
// }

// openFormatsDialog() {
//   // const data = {
//   //   formatsEnabled: this.formatsEnabled,
//   // };

//   // this._dialog
//   //   .open(FormatsDialogComponent, { data })
//   //   .afterClosed()
//   //   .subscribe(x => { if (x) { this.formatsEnabled = x; } });
//   // console.log('openFormatsDialog')
// }

// // onHasPermission(has: boolean) {
// //   this.hasPermission = has;
// // }

// // openInfoDialog() {
// //   // const data = {
// //   //   hasDevices: this.hasDevices,
// //   //   hasPermission: this.hasPermission,
// //   // };
// //   // this._dialog.open(AppInfoDialogComponent, { data });
// //   // console.log('openInfoDialog')
// // }

// onTorchCompatible(isCompatible: boolean): void {
//   this.torchAvailable$.next(isCompatible || false);
// }

// toggleTorch(): void {
//   this.torchEnabled = !this.torchEnabled;
// }

// toggleTryHarder(): void {
//   this.tryHarder = !this.tryHarder;
// }
// ngOnDestroy() {
//   localStorage.setItem('isAssignedQR','false')
// }
}
