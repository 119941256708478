import { PersonService } from './../_services/person/person.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';
import { ToolsService } from '../_services/tools/tools.service';
import { GlobalService } from '../_services/global/global.service';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';

@Injectable()

export class GetUserByCompanyIdResolver implements Resolve<any[]>
{
  settingTools: any = {}
  usersObj: any = {}
  user: any = {}
  constructor(
    private _personService: PersonService,
    private _toolsService: ToolsService,
    private _globalService: GlobalService,
  ) { }
  resolve(): Observable<any[]> {
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this._globalService.items != undefined && this._globalService.items.success) {
      this.settingTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
      this.usersObj = this._toolsService.getPermission(this.user.role, this.settingTools, ToolSettingEditItem.Users_list)
      if (this.user.role =='admin' || this.usersObj.itemStatus == "Active" && this.usersObj.roleStatus == "active" || this.usersObj.itemStatus == "Active" && this.usersObj.roleStatus == "readOnly") {
        return this._personService.getUsersByCompany(this.user.companyId).pipe(
          catchError(error => {
            // console.log(error)
            return of(null);
          })
        );
      }
    }
    else if(this._globalService.items == undefined){
      if (this._globalService.items != undefined && this._globalService.items.success) {
        this.settingTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
        this.usersObj = this._toolsService.getPermission(this.user.role, this.settingTools, ToolSettingEditItem.Users_list)
        if (this.user.role =='admin' || this.usersObj.itemStatus == "Active" && this.usersObj.roleStatus == "active" || this.usersObj.itemStatus == "Active" && this.usersObj.roleStatus == "readOnly") {
          return this._personService.getUsersByCompany(this.user.companyId).pipe(
            catchError(error => {
              // console.log(error)
              return of(null);
            })
          );
        }
      }
    }
  }
}
