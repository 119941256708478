import { NgForm } from '@angular/forms';
import { CarWashService } from './../../../../../core/_services/car-wash-service/car-wash.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { DatePipe } from '@angular/common';
import { ServiceType } from '../Enum/ServiceType';
import { ModalDirective } from 'angular-bootstrap-md';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { GlobalService } from 'src/app/core/_services/global/global.service';
const MAX_SIZES_LENGTH = 1
@Component({
  selector: 'app-car-wash-services',
  templateUrl: './car-wash-services.component.html',
  styleUrls: ['./car-wash-services.component.scss']
})
export class CarWashServicesComponent implements OnInit {
  @ViewChild('removeModal') removeModal: ModalDirective;
  @ViewChild('editServiceModal') editServiceModal: ModalDirective;
  @ViewChild('inQueueModal') inQueueModal: ModalDirective;
  @ViewChild('editServiceAdditionalModal') editServiceAdditionalModal: ModalDirective;
  user: any = {}
  tools: any = {}
  othersTools: any = {}
  carWashServicesObj: any = {}
  carWashServicesObjEdit: any = {}
  carWashServices: any = []
  carWashServicesSizes: any = []
  carWashServicesPerks: any = []
  additionalServices: any = []
  additionalServiceObj: any = {}
  additionalServiceObjEdit: any = {}
  serviceInfoObj: any = {}
  public selectedIndex: number = 0;
  public selectedIndex1: number = 0;
  p: number = 1
  p1: number = 1
  itemsPerPage = 6
  searchText = ''
  searchText1 = ''
  isServiceNameExists = false
  isServiceNameExists2 = false
  indexNormal = 0
  indexOther = 0
  bufferIndex = 0
  sizesEdit: any = []
  perksEdit: any = []
  bufferService = {}
  isRemoveSize = true
  constructor(
    private route: ActivatedRoute,
    private _personService: PersonService,
    private _toolsService: ToolsService,
    public datePipe: DatePipe,
    public _carWashService: CarWashService,
    public socket: Socket,
    private _globalService:GlobalService,
  ) { }

  ngOnInit() {
    this.InitialSizesServices()
    this.InitialPerkServices()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getServices()
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        this.othersTools = this.tools.find(t => t.toolType == ToolType.Setting_Tools)
        if (this.user.role == 'admin' || this.user.role == 'manager' || this.user.role == 'teamleader' || this.user.role == 'crew') {
          this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
              this.carWashServicesObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
              // console.log(this.carWashObj)
              // if (this.user.role == 'admin' || this.carWashServicesObj.itemStatus == "Active" && this.carWashObj.roleStatus == "active" || this.carWashObj.itemStatus == "Active" && this.carWashObj.roleStatus == "readOnly") {
              //   this.getVehicles()
              // }
            }
          })
        }
      }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Service_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getServiceById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            if(data.type == ServiceType.Other){
              this.additionalServices.push(response.data)
            }
            else if(data.type == ServiceType.Normal){
              this.carWashServices.push(response.data)
            }
        }
        })
      }
    })
    this.socket.on(RealTimeKeys.Service_Update, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getServiceById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            if(data.type == ServiceType.Other){
              let index = this.additionalServices.findIndex(a => a.id == data.id)
              this.additionalServices[index] = response.data
            }
            else if(data.type == ServiceType.Normal){
              let index = this.carWashServices.findIndex(a => a.id == data.id)
              this.carWashServices[index] = response.data
            }
        }
        })
      }
    })
    this.socket.on(RealTimeKeys.Service_Achieve, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getServiceById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
              let index = this.carWashServices.findIndex(a => a.id == data.id)
              this.carWashServices[index] = response.data
          }
        })
      }
    })
  }
  getServices() {
    this._carWashService.getServices(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success ) {
        this.carWashServices = response.data.normalServices
        this.additionalServices = response.data.otherServices
      }
    })
  }
  restPagination(event) {
    this.p = 1
  }
  restOtherPagination(event) {
    this.p1 = 1
  }
  handlingNormalPagination(event) {
    this.p = event
  }
  handlingOtherPagination(event) {
    this.p1 = event
  }
  checkServiceName(event) {
    setTimeout(() => {
      this._carWashService.isCarWashServiceExist(this.user.companyId,event.trim().toLocaleLowerCase()).subscribe((response:ResponseModel)=>{
        if (response.success) {
          this.isServiceNameExists = false
        }
        else {
          this.isServiceNameExists = true
        }
      })
    }, 1000);
  }
  checkOtherServiceName(event) {
    setTimeout(() => {
      this._carWashService.isCarWashServiceExist(this.user.companyId,event.trim().toLocaleLowerCase()).subscribe((response:ResponseModel)=>{
        if (response.success) {
          this.isServiceNameExists2 = false
        }
        else {
          this.isServiceNameExists2 = true
        }
      })
    }, 1000);
    console.log( this.isServiceNameExists2)
  }
  InitialSizesServices() {
    this.carWashServicesSizes = []
    for (let index = 0; index < MAX_SIZES_LENGTH; index++) {
      this.carWashServicesSizes.push({
        [`size${index}`]: '',
        [`price${index}`]: '',
        id: index + 1
      });
    }
    console.log(this.carWashServicesSizes)
  }
  InitialPerkServices() {
    this.carWashServicesPerks = []
    for (let index = 0; index < MAX_SIZES_LENGTH; index++) {
      this.carWashServicesPerks.push({
        [`perk${index}`]: '',
        id: index + 1
      });
    }
    console.log(this.carWashServicesSizes)
  }
  addSizeAndPrice() {
    console.log(this.carWashServicesSizes.length)
    this.carWashServicesSizes.push({
      [`size${this.carWashServicesSizes.length}`]: '',
      [`price${this.carWashServicesSizes.length}`]: '',
      id: this.carWashServicesSizes.length + 1
    });
    console.log(this.carWashServicesSizes)
  }
  addSizeAndPriceEdit() {
    this.carWashServicesObjEdit.sizes.push({
      [`size${this.carWashServicesObjEdit.sizes.length}`]: '',
      [`price${this.carWashServicesObjEdit.sizes.length}`]: '',
      id: this.carWashServicesObjEdit.sizes.length + 1,
      isEdit: true
    });
    console.log(this.carWashServicesObjEdit.sizes)
  }
  addPerk() {
    this.carWashServicesPerks.push({
      [`perk${this.carWashServicesPerks.length}`]: '',
      id: this.carWashServicesPerks.length + 1
    });
    console.log(this.carWashServicesPerks)
  }
  addPerkEdit() {
    this.carWashServicesObjEdit.perks.push({
      [`perk${this.carWashServicesObjEdit.perks.length}`]: '',
      id: this.carWashServicesObjEdit.perks.length + 1
    });
    console.log(this.carWashServicesObjEdit.perks)
  }
  removePerk(index: number) {
    this.carWashServicesPerks.splice(index, 1)
  }
  removePerkEdit(index: number) {
    this.carWashServicesObjEdit.perks.splice(index, 1)
    this.carWashServicesObjEdit.perks = this.carWashServicesObjEdit.perks
  }
  checkRemove(isRemoveSize, index) {
    this.isRemoveSize = isRemoveSize
    this.bufferIndex = index
    this.removeModal.show()
  }
  removeSize(index: number) {
    this.carWashServicesSizes.splice(index, 1)
    console.log( this.carWashServicesSizes)
  }
  removeSizeEdit(index: number) {
    this.carWashServicesObjEdit.sizes.splice(index, 1)
    this.carWashServicesObjEdit.sizes = this.carWashServicesObjEdit.sizes
  }
  addAdditionalService(form: NgForm) {
    let todayDate = new Date()
    this.additionalServiceObj.date = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      this.additionalServiceObj.time = this.datePipe.transform(todayDate, 'H:mm')
    this.additionalServiceObj.isActive = true
    this.additionalServiceObj.dynamicObj = {}
    this.additionalServiceObj.isShowJob = true
    this.additionalServiceObj.isAchieve = false
    this.additionalServiceObj.createdDate = new Date()
    this.additionalServiceObj.companyId = this.user.companyId
    this.additionalServiceObj.type = ServiceType.Other
    // this.additionalServices.push(this.additionalServiceObj)
    console.log(this.additionalServices)
    this._carWashService.addNormalServices(this.additionalServiceObj).subscribe((response: ResponseModel) => {
      if (response.success) {
        // this.additionalServices.push( response.data)
        this.serviceInfoObj = {}
        this.selectedIndex1 = 0
        form.reset()
      }
    })
    // this.additionalServiceObj = {}
  }
  addInfoService() {
    console.log(this.serviceInfoObj)
  }
  addInfoServiceSizes(form: NgForm) {
    let todayDate = new Date()
    this.serviceInfoObj.date = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      this.serviceInfoObj.time = this.datePipe.transform(todayDate, 'H:mm')
    this.serviceInfoObj.sizes = this.carWashServicesSizes
    this.serviceInfoObj.perks = this.carWashServicesPerks
    this.serviceInfoObj.isActive = true
    this.serviceInfoObj.dynamicObj = {}
    this.serviceInfoObj.isShowJob = true
    this.serviceInfoObj.isAchieve = false
    this.serviceInfoObj.createdDate = new Date()
    this.serviceInfoObj.companyId = this.user.companyId
    this.serviceInfoObj.type = ServiceType.Normal
    // this.carWashServices.push(this.serviceInfoObj)
    // console.log(this.serviceInfoObj)
    // console.log(this.carWashServicesSizes)
    this._carWashService.addNormalServices(this.serviceInfoObj).subscribe((response: ResponseModel) => {
      if (response.success) {
        // this.carWashServicesSizes = response.data.normalServices
        // this.carWashServices.push(response.data)
        this.serviceInfoObj = {}
        this.selectedIndex = 0
        this.InitialSizesServices()
        this.InitialPerkServices()
        form.reset()
        this.restoreService()
      }
    })
  }
  updateInfoServiceSizes() {
    let todayDate = new Date()
    let sizes = []
    for (let index = 0; index < this.carWashServicesObjEdit.sizes.length; index++) {
      sizes.push({
        [`size${index}`]: this.carWashServicesObjEdit.sizes[index]['size' + index],
        [`price${index}`]: this.carWashServicesObjEdit.sizes[index]['price' + index],
        id: this.carWashServicesObjEdit.sizes[index]['id']
      })
    }
    this.carWashServicesObjEdit.sizes = sizes
    // this.carWashServices[this.indexNormal] = this.carWashServicesObjEdit
    this.carWashServicesObjEdit.companyId = this.user.companyId
    this.carWashServicesObjEdit.type = ServiceType.Normal
    this.carWashServicesObjEdit.date = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
    this.carWashServicesObjEdit.time = this.datePipe.transform(todayDate, 'H:mm')
    this._carWashService.updateNormalServices(this.carWashServicesObjEdit.id,this.carWashServicesObjEdit).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.carWashServices[this.indexNormal] = response.data
        this.restoreService()
      }
    })
  }
  nextTab() {
    this.selectedIndex = 1
  }
  nextTabEdit() {
    this.selectedIndex1 = 1
  }
  onTabChanged(event: any) {
    this.selectedIndex = event.index
  }
  restoreService() {
    this.isServiceNameExists = false
  }
  resetOther() {
    this.isServiceNameExists2 = false
  }
  storeEdit(service: any, i) {
    this._carWashService.isVehiclesInQueue(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.data){
        this.inQueueModal.show()
      }
      else{
        this.bufferService = Object.create(service)
        this.indexNormal = i
        this.carWashServicesObjEdit = JSON.parse(JSON.stringify(service))
        console.log(this.carWashServicesObjEdit)
        this.sizesEdit = Object.assign([], this.carWashServicesObjEdit.sizes)
        this.editServiceModal.show()
      }
    })
  }
  storeAdditionalEdit(service: any, index: number) {
    this._carWashService.isVehiclesInQueue(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.data){
        this.inQueueModal.show()
      }
      else{
        this.additionalServiceObjEdit = Object.assign({}, service)
        this.indexOther = index
        this.editServiceAdditionalModal.show()
      }
    })
  }
  onTabChangedEdit(event: any) {
    this.selectedIndex1 = event.index
  }
  updateAdditionalService() {
    // this.additionalServices[this.indexOther].serviceName = this.additionalServiceObjEdit.serviceName
    // this.additionalServices[this.indexOther].price = this.additionalServiceObjEdit.price
    let todayDate = new Date()
    this.additionalServiceObjEdit.companyId = this.user.companyId
    this.additionalServiceObjEdit.type = ServiceType.Other
    this.additionalServiceObjEdit.date = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
    this.additionalServiceObjEdit.time = this.datePipe.transform(todayDate, 'H:mm')
    this._carWashService.updateNormalServices(this.additionalServiceObjEdit.id,this.additionalServiceObjEdit).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.additionalServices[this.indexOther] = response.data
      }
    })
  }
  carWashServiceStatus(id){
    let index = this.carWashServices.findIndex(c => c.id == id )
    this._carWashService.carWashServiceStatus(id).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.carWashServices[index] = response.data
      }
    })
  }
  getCurrency(){
    return this._globalService.getCurrency()
  }
}
