<!-- Customers Messaging -->
<div class="card">
    <h5>Messages Settings & Editor</h5>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>Tags Creator</span>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-lg-10 text-center ">
                    <select #mySelect class="browser-default custom-select form-control" (change)='onOptionsSelected(mySelect.value)'>
                      <option *ngFor="let item of explanations" [value]="item.value">{{item.name}}</option>
                   </select>
                </div>
                <div class="col-lg-10 mt-2 mb-2">
                    <p class="pattern"> {{pattern}} </p>
                </div>
                <div class="col-lg-2  mt-2 mb-2">
                    <p class="copy" [cdkCopyToClipboard]="pattern">Copy</p>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<!-- Job Actions (Customer & User) -->
<div class="card">
    <h5>Job Actions (Customer & User)</h5>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>Date & Time Changes</span>
                <div class="status-job">
                    <span>{{dateTimeChange}}</span>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <select #myDate class="browser-default custom-select form-control" (change)='onOptionsDate(myDate.value)'>
                      <option selected disabled >Choose Status</option>
                      <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-group ">
                        <textarea class="form-control">Dear6 &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                       </textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>Stops Changes</span>
                <div class="status-job">
                    <span>{{stopChange}}</span>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <select #myStop class="browser-default custom-select form-control" (change)='onOptionsStop(myStop.value)'>
                      <option selected disabled >Choose Status</option>
                      <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
               </select>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-group ">
                        <textarea class="form-control">Dear7 &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                       </textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<!-- Job Actions ( User Only) -->
<div class="card">
    <h5>Job Actions ( User Only)</h5>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>User Assigned</span>
                <div class="status-job">
                    <span>{{userAssigned}}</span>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <select #myUserAssigned class="browser-default custom-select form-control" (change)='onOptionsUserAssigned(myUserAssigned.value)'>
                      <option selected disabled >Choose Status</option>
                      <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
               </select>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-group ">
                        <textarea class="form-control">Dear6 &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                     </textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>Truck Assigned</span>
                <div class="status-job">
                    <span>{{truckAssigned}}</span>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <select #myTruckAssigned class="browser-default custom-select form-control" (change)='onOptionsTruckAssigned(myTruckAssigned.value)'>
                      <option selected disabled >Choose Status</option>
                      <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
             </select>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-group ">
                        <textarea class="form-control">Dear7 &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                     </textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="example-headers-align " multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <span>Note Added</span>
                <div class="status-job">
                    <span>{{noteAdded}}</span>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12">
                    <select #myNoteAdded class="browser-default custom-select form-control" (change)='onOptionsNoteAdded(myNoteAdded.value)'>
                      <option selected disabled >Choose Status</option>
                      <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-12 mt-3">
                    <div class="form-group ">
                        <textarea class="form-control">Dear7 &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                   </textarea>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>