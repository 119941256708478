export enum SectionType {
  Header ,
  Services,
  Pricing,
  Some_Works,
  Social_Media,
  About,
  Address_Map,
  Contact_Address
}
