import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnumTemplates } from 'src/app/modules/company-admin/pages/car-wash-folder/Enum-Template/Enum-Templates';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-qr-mobile-print',
  templateUrl: './qr-mobile-print.component.html',
  styleUrls: ['./qr-mobile-print.component.scss']
})
export class QrMobilePrintComponent implements OnInit {
  // @Input() objFromParent: any = {};
  qrCodes:any
  user:any
  companyInfo: any = {}
  baseUrl = environment.baseDomain
  bufferTemplate :any
  enumTemplates: any = EnumTemplates
  constructor(
    private route: ActivatedRoute,
  ) { }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.qrCodes =  JSON.parse(params['data'])
     this.bufferTemplate =  params['templateType']
     this.companyInfo = JSON.parse(params['Info'])
     });
    setTimeout(() => {
    let  printContents = document.getElementById('qr_codes_Id').innerHTML;
    let popupWin = window.open('','top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>print</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
          <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
          <style>
          body {
            // margin:1rem auto !important
            text-align : center;
            display: inline-flex;
            justify-content: space-between !important;
            flex-wrap: wrap;
            margin-top:0 !important;
            align-items: center;
          }
          // .modal-body{
          //   margin : 0rem auto;
          //   padding: 0 1rem;
          // }
          .row{
            // display: flex;
            // display: inline-flex;
            // max-width: 100%;
            // width: 100%;
            // flex-wrap: wrap;
            // margin-right: -15px;
            // margin-left: -15px;
            // page-break-after: always ;
            width:384px !important
            height:192px !important
            // margin:1rem auto !important
            padding: 0
          }
          .col-6{
            // flex: 0 0 50% !important;
            // border: 1px solid #e7e4e4;
            // border: 1px solid #000;
            // max-width: 50% !important;
            // width: 50% !important;
            // margin:0px;
            display: flex;
            align-items: center;
            // margin-bottom:10px;
            // width:384px ;!important
            // height:192px ;!important
            // margin:1rem ; !important
            // padding: 10px

          }
          .full-item {
            // border: 1px solid #e7e4e4;
            display: flex;
            justify-content: left !important;
            margin: 2.6rem  auto ;
            width:384px ;!important
            height:192px; !important
            // margin:0 auto !important
          }
          .last-item{
            margin-top:1rem !important
          }
          .full-item .info{
            direction: rtl !important;
            unicode-bidi: bidi-override !important;
          }
          .info-details  p {
            font-size: 15px;
            margin: 0;
            // line-height: 1.1;
            font-weight:700
            -webkit-transform: scaleX(-1);
            // direction: rtl !important;
            // unicode-bidi: bidi-override !important;
            }
          .qr-img  {
            // height:192px !important
            display: block;
            // margin: 10px  ;
            text-align : left
          }
          .qr-img  img{
            width:165px !important
            height:165px !important
            -webkit-transform: scaleX(-1);
          }
          .qr-img  span {
            padding-left: 5px;
            font-size: 7px;
            }
            .info {
              display: flex;
              justify-content: left !important;
              align-items: center;
              text-align:left !important;
          }

        img{
          -webkit-transform: scaleX(-1) !important;
        }
          @media print {
            .pagebreak  {
                page-break-before: always;
            }
            /* page-break-after works, as well */
        }
          </style>
        </head>
        <body onload="window.print()">${printContents}</body>
      </html>`
      );
      popupWin.document.close();
  }, 1000);
  }
  getCompanyAddress() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyInfo = {
      companyName: company.companyName,
      companyPhone: this.user.companyPhone,
      companyWebsite: this.user.companyWebsite,
      companyAddress: company.companyAddress
    }
  }
  // getLinkQR(item: any) {
  //   return this.baseUrl + 'med-route-qr/' + item.id
  // }
}
