import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { QrService } from 'src/app/core/_services/qr-service/qr.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxPrintElementService } from 'ngx-print-element';
import { Capacitor } from '@capacitor/core';
import { QrCodeMovingService } from 'src/app/core/_services/qr-code-moving/qr-code-moving.service';
import { EnumTemplates } from '../../car-wash-folder/Enum-Template/Enum-Templates';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { QrCodeType } from 'src/app/models/inventory-item/qrCodeType';

@Component({
  selector: 'app-qr-code-inventory',
  templateUrl: './qr-code-inventory.component.html',
  styleUrls: ['./qr-code-inventory.component.scss']
})
export class QrCodeInventoryComponent implements OnInit {
  @ViewChild('qrPrintModal') qrPrintModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
   qrCodes:any = []
  numberPattern = ValidationModel.numberPattern;
  user: any = {}
  quantity = "30"
  baseUrl = environment.baseDomain
  baseWordpressUrl = environment.baseDomain
  companyInfo: any = {}
  enumTemplates: any = EnumTemplates
  bufferTemplate = EnumTemplates.Template_One
  tools: any[] = []
  othersTools: any = {}
  qrCodeObj: any = {}
  jobsTools: any = {}
  isMobile = false
  startTemplate = 1
  endTemplate = 30
  total = 30
  statusActive: any = StatusActive
  items: any[] = []
  errorModel = {
    isError : false,
    msg : ''
  }
   numPages = new Array(50).fill(null).map((_, i) => i + 1);
   page = "1"
   labelStickers = '30 Stickers, Size: (1"x2-5/8")'
   tenStickersLink   = 'https://www.amazon.com/Avery-Glossy-Crystal-Address-Printers/dp/B078HHB584/ref=sr_1_2?crid=2BWKV9QHVHW6L&keywords=avery%2B2%2Bx%2B4%2Blabels%2Bglossy%2Bclear&qid=1672077188&sprefix=avery%2Bglossy%2Bclear%2Blabels%2B2%2B4%2Caps%2C163&sr=8-2&th=1'
   thirtyStickersLink  = 'https://www.amazon.com/Address-Labels-Sticker-Printer-mailing/dp/B09P16YV2K?th=1'
   userPermission :any = {}
   jobModel :any = {}
   qrCodeType  = QrCodeType
   constructor(
    private _qrService: QrCodeMovingService,
    private route: ActivatedRoute,
    private router: Router,
    private _personService: PersonService,
    private _toolsService: ToolsService,
    private deviceService: DeviceDetectorService,
    public print: NgxPrintElementService,
    public _globalService: GlobalService,
    private _jobsService: JobsService,
  ) {
  }
  ngOnInit() {
    this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getCompanyAddress()
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        if (response['jobs'].success) {
          this.items = response['jobs'].data.showItems
          if (this.user.role == "admin") {
            this.userPermission = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolJobsEditItem.Qr_Code_Inv)
            if (this.userPermission.itemStatus == 'Active') {
              this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
            }
          }
          if (this.user.role != "admin" && this.user.role != "manager") {
            this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.Qr_Code_Inv, this.user.id)
          }
          if (this.user.role == "manager") {
            this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.Qr_Code_Inv, this.user.id)
          }
        }
      }
    }, error => {
      console.log(error)
    })
    if (Capacitor.isNativePlatform()) {
      this.isMobile = true
    }
    else {
      this.isMobile = false
    }
  }
  getCompanyAddress() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyInfo = {
      companyName: company.companyName,
      companyPhone: this.user.companyPhone,
      companyWebsite: this.user.companyWebsite,
      companyAddress: company.companyAddress,
      companyLogo: company.companyLogo,
    }
    this.baseWordpressUrl =  !this.user.companyWebsite ? this.baseWordpressUrl :  this.user.companyWebsite
  }
  changeStickers(event){
    console.log(event)
    this.total = Number(event) * Number(this.page)
    this.endTemplate =  Number(this.startTemplate)  +  (this.total - 1)
    this.checkValidity()
  }
  choosePage(event){
   console.log(event)
   this.total = Number(event) * Number(this.quantity)
   this.endTemplate =   Number(this.startTemplate) +  (this.total - 1)
   this.checkValidity()
  }
  addQrCodes(bufferTemplate: any) {
    if (Capacitor.isNativePlatform()) {
      this.printScanMobile.show()
    } else {
      this.bufferTemplate = bufferTemplate
      this.qrCodes = []
      for (let index = Number(this.startTemplate); index <= Number(this.endTemplate); index++) {
        this.qrCodes.push({
          qrCodeNumber : index
        })
      }
      console.log(this.qrCodes)
      setTimeout(() => {
        this.printFixed()
        }, 1000);
      }
    }
  checkValidity() {
    // Number(this.startTemplate) >= 0 ? this.startTemplate = 1  : this.startTemplate
    // Number(this.endTemplate) >= 0 ? this.endTemplate = 1  : this.endTemplate
    this.endTemplate =  Number(this.startTemplate) +  (this.total - 1)
    if (Number(this.startTemplate) > Number(this.endTemplate)) {
      this.errorModel.isError = true
      this.errorModel.msg = 'start bigger from end '
    }
    else if (Number(this.startTemplate) < Number(this.endTemplate) && (Number(this.endTemplate) - Number(this.startTemplate)) > 100) {
      this.errorModel.isError = true
      this.errorModel.msg = 'rang between start and end must 100 '
    }
    else if (Number(this.endTemplate) == 0 &&  Number(this.startTemplate) == 0) {
      this.errorModel.isError = true
      this.errorModel.msg = 'enter start and  end   '
    }
    else if (Number(this.startTemplate) == 0 ) {
      this.errorModel.isError = true
      this.errorModel.msg = 'enter start  '
    }
    else {
      this.errorModel.isError = false
      this.errorModel.msg = ' '
      this.endTemplate = Number(this.startTemplate) +  (this.total - 1)
   }
  }
  getLinkQR(item: any) {
    return  item ? `${this.jobModel.jobNumber},${item.qrCodeNumber},${this.qrCodeType.Qr_Not_Generic}` : " "
  }
  // printFixed() {
  //   // let popupWin: any = {}
  //   let printContents = document.getElementById('qr_codes_Id').innerHTML;
  //   let  popupWin = window.open('', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   // if (this.deviceService.isDesktop() || this.deviceService.isMobile()) {
  //     popupWin.document.write(`
  //      <html>
  //        <head>
  //          <title>print</title>
  //          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
  //          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
  //          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
  //          <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
  //          <style>
  //          body {
  //           // margin:1rem auto !important
  //           text-align : center;
  //           display: inline-flex;
  //           justify-content: space-between !important;
  //           flex-wrap: wrap;
  //           margin-top:0 !important;
  //           align-items: center;
  //         }
  //         // .modal-body{
  //         //   margin : 0rem auto;
  //         //   padding: 0 1rem;
  //         // }
  //         .row{
  //           // display: flex;
  //           // display: inline-flex;
  //           // max-width: 100%;
  //           // width: 100%;
  //           // flex-wrap: wrap;
  //           // margin-right: -15px;
  //           // margin-left: -15px;
  //           // page-break-after: always ;
  //           width:384px !important
  //           height:192px !important
  //           // margin:1rem auto !important
  //           padding: 0
  //         }
  //         .col-6{
  //           // flex: 0 0 50% !important;
  //           // border: 1px solid #e7e4e4;
  //           // border: 1px solid #000;
  //           // max-width: 50% !important;
  //           // width: 50% !important;
  //           // margin:0px;
  //           display: flex;
  //           align-items: center;
  //           // margin-bottom:10px;
  //           // width:384px ;!important
  //           // height:192px ;!important
  //           // margin:1rem ; !important
  //           // padding: 10px

  //         }
  //         .full-item {
  //           // border: 1px solid #e7e4e4;
  //           display: flex;
  //           justify-content: left !important;
  //           margin: 2.6rem  auto ;
  //           width:384px ;!important
  //           height:192px; !important
  //           // margin:0 auto !important
  //         }
  //         .last-item{
  //           margin-top:1rem !important
  //         }
  //         .full-item .info{
  //           direction: rtl !important;
  //           // unicode-bidi: bidi-override !important;
  //         }
  //         .info-details  p {
  //           font-size: 15px;
  //           margin: 0;
  //           // line-height: 1.1;
  //           font-weight:700
  //           // direction: rtl !important;
  //           // unicode-bidi: bidi-override !important;
  //           -webkit-transform: scaleX(-1);
  //           }
  //         .qr-img  {
  //           // height:192px !important
  //           display: block;
  //           // margin: 10px  ;
  //           text-align : left
  //         }
  //         .qr-img  img{
  //           width:165px !important
  //           height:165px !important
  //           -webkit-transform: scaleX(-1);
  //         }
  //         .qr-img  span {
  //           padding-left: 5px;
  //           font-size: 7px;
  //           }
  //           .info {
  //             display: flex;
  //             justify-content: left !important;
  //             align-items: center;
  //             text-align:left !important;
  //         }

  //       img{
  //         -webkit-transform: scaleX(-1) !important;
  //       }
  //         @media print {
  //           .pagebreak  {
  //               page-break-before: always;
  //           }
  //           /* page-break-after works, as well */
  //       }
  //          </style>
  //        </head>
  //        <body onload="window.print();window.close()">${printContents}</body>
  //      </html>`
  //     );
  //     popupWin.document.close();
  //   // }
  //   // else {
  //   //   let companyInfo = {
  //   //     companyName:  this.companyInfo .companyName,
  //   //     companyPhone: this.user.companyPhone,
  //   //     companyWebsite: this.user.companyWebsite,
  //   //     addressOne:  this.companyInfo .companyAddress.addressOne,
  //   //     addressTow:  this.companyInfo .companyAddress.addressTow,
  //   //     city:  this.companyInfo .companyAddress.city,
  //   //     state:  this.companyInfo .companyAddress.state,
  //   //     zip:  this.companyInfo .companyAddress.zip,
  //   //     country:  this.companyInfo.companyAddress.country.name,
  //   //   }
  //   //   let url =`${this.baseUrl}qr-mobile-print?isAccess=true&templateType=${this.bufferTemplate}&data=${JSON.stringify(this.qrCodes)}&Info=${JSON.stringify(companyInfo)} `
  //   //   Browser.open({ url: url });
  //   // }
  // }
  // printFixed() {
  //   //  if (this.deviceService.isDesktop()) {
  //     let  printContents = document.getElementById('qr_codes_Id').innerHTML;
  //     let popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
  //     popupWin.document.open();
  //      popupWin.document.write(`
  //      <html>
  //      <head>
  //        <title>print</title>
  //        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
  //        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
  //        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
  //        <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
  //        <style>
  //        body {
  //         text-align : center;
  //         display: inline-flex;
  //         justify-content: space-between !important;
  //         flex-wrap: wrap;
  //         margin-top:0 !important;
  //         align-items: center;
  //       }
  //       .row{
  //         width:384px !important
  //         height:192px !important
  //         padding: 0
  //       }
  //       .col-6{
  //         display: flex;
  //         align-items: center;
  //       }
  //       .full-item {
  //         display: flex;
  //         justify-content: left !important;
  //         margin: 2.6rem  auto ;
  //         width:384px ;!important
  //         height:192px; !important
  //       }
  //       .last-item{
  //         margin-top:1rem !important
  //       }
  //       .full-item .info{
  //         direction: rtl !important;
  //       }
  //       .info-details  p {
  //         font-size: 15px;
  //         margin: 0;
  //         font-weight:700
  //         -webkit-transform: scaleX(-1);
  //         }
  //       .qr-img  {
  //         // height:192px !important
  //         display: block;
  //         // margin: 10px  ;
  //         text-align : left
  //       }
  //       .qr-img  img{
  //         width:165px !important
  //         height:165px !important
  //         -webkit-transform: scaleX(-1);
  //       }
  //       .qr-img  span {
  //         padding-left: 5px;
  //         font-size: 7px;
  //         }
  //         .info {
  //           display: flex;
  //           justify-content: left !important;
  //           align-items: center;
  //           text-align:left !important;
  //       }
  //       .back{
  //         text-align:center;
  //       }
  //     img{
  //       -webkit-transform: scaleX(-1) !important;
  //     }
  //       @media print {
  //         .pagebreak  {
  //             page-break-before: always;
  //         }
  //         .btn-sm{
  //           display:none;
  //         }
  //       }
  //        </style>
  //      </head>
  //      <body onload="window.print()">
  //      <div class="back">
  //      <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
  //      </div>
  //      ${printContents}</body>
  //    </html>`
  //      );
  //      popupWin.document.close();
  //   //  } else {
  //   //     let companyInfo = {
  //   //     companyName:  this.companyInfo .companyName,
  //   //     companyPhone: this.user.companyPhone,
  //   //     companyWebsite: this.user.companyWebsite,
  //   //     addressOne:  this.companyInfo .companyAddress.addressOne,
  //   //     addressTow:  this.companyInfo .companyAddress.addressTow,
  //   //     city:  this.companyInfo .companyAddress.city,
  //   //     state:  this.companyInfo .companyAddress.state,
  //   //     zip:  this.companyInfo .companyAddress.zip,
  //   //     country:  this.companyInfo.companyAddress.country.name,
  //   //   }
  //   //   let url =`${this.baseUrl}qr-mobile-print?isAccess=true&templateType=${this.bufferTemplate}&data=${JSON.stringify(this.qrCodes)}&Info=${JSON.stringify(companyInfo)} `
  //   //   Browser.open({ url: url });
  //   // }
  //  }
  printFixed() {
    //  if (this.deviceService.isDesktop()) {
    // let printContents = document.getElementById('qr_codes_Id').innerHTML;
    let printContents:any =''
    if(Number(this.quantity) == 10){
     printContents = document.getElementById('qr_codes_Id_10').innerHTML;
    }
    else{
     printContents = document.getElementById('qr_codes_Id_30').innerHTML;
    }
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
       <html>
         <head>
           <title>print</title>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
           <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
           <style>
           body {
            text-align : center;
            display: inline-flex;
            justify-content: space-between !important;
            flex-wrap: wrap;
            margin-top:1.2rem !important;
            align-items: center;
            page-break-before: always !important;
          }
          .row{
            width:384px !important
            height:192px !important
            padding: 0
          }
          .col-4{
            display: flex;
            align-items: center;
          }
          .qr-code-number{
            font-size: 12px;
            position: absolute;
            width: 60px;
            height: 60px;
            border: 1px solid #000;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 80px;
            line-height: 1;
            font-size : 1.5rem
          }
           .full-item {
            display: flex;
            justify-content: center  !important;
            margin: 0.6rem auto  ;
            // margin: 0.3rem auto  0.7rem  ;
            width:384px ;
            // height:192px !important;
          }
          .col-4 {
            padding-left:20px !important;
          }
          .col-4:nth-child(3n+1){
            padding: 0 !important;
          }
          .col-4:nth-child(3n+1) .full-item{
            // justify-content: space-evenly !important;
            justify-content: center !important;
            padding-right: 0  !important;
            padding-left: 0  !important;
          }
          .col-4:nth-child(n+13) .full-item {
            margin: 0.4rem auto !important;
          }
          .col-4 .qr-code-number{
            right: 0;
            top: 25px;
          }
          .last-item{
            margin-top:1rem !important
          }
          .full-item .info{
            padding-right:16px;
            // direction: rtl !important;
            text-align: center !important;
          }
          .col-4:nth-child(3n+1) .full-item .info{
            padding:0px;
          }
          .col-4:nth-child(30n+31),
          .col-4:nth-child(30n+32),
          .col-4:nth-child(30n+33){
            margin-top :11.625rem
          }
          .col-6{
            display: flex;
            align-items: center;
            padding:0;
          }
          .col-6 .full-item {
            margin: 2.6rem  auto ;
          }
          .col-6:nth-child(n+5) .full-item {
            margin: 2.3rem  auto ;
          }
          .col-6:nth-child(10n+11),
          .col-6:nth-child(10n+12){
            margin-top: 10rem  ;
          }
          .col-6 .full-item  .qr-img  img{
            width:165px !important
            height:165px !important
            // -webkit-transform: scaleX(-1);
          }
          .col-6  .qr-code-number{
            right: 20px;
            top: 70px;
          }
          .info-details  p {
            text-align: left;
            font-size: 11px;
            margin: 0;
            font-weight:700;
            // transform: scaleX(-1);
            // direction: ltr !important;
            // unicode-bidi: bidi-override !important;
            // direction: ltr !important;
            // unicode-bidi: bidi-override !important;
            line-height: 1.5;
            }
          .qr-img  {
            // height:192px !important
            display: block;
            // margin: 10px  ;
            text-align : left
          }
          .col-4  .qr-img  img{
            width:120px !important;
            height:120px !important;
            // -webkit-transform: scaleX(-1);
          }
          .col-6 .qr-img img{
            width:200px !important;
            height:200px !important;
            // -webkit-transform: scaleX(-1);
          }
          .qr-img  span {
            padding-left: 5px;
            font-size: 7px;
            }
            .info {
              display: flex;
              justify-content: center !important;
              align-items: center;
              text-align:left !important;
          }
          .info p strong{
            font-size:2rem
          }
        img{
          // -webkit-transform: scaleX(-1) !important;
        }
        @page {
          margin-left: 0;
      }
      .page-break  {
       margin-top : 175px
      }
      @media print {
        // .page-break  {
        // break-before: auto;
        // }
        /* page-break-after works, as well */
      }
      .page-break  {
        break-after: always !important;
        page-break-after: always !important;
        clear: both;
      }
           </style>
         </head>
         <body onload="window.print()">${printContents}</body>
       </html>`
    );
    popupWin.document.close();
  }
  //    printFixed() {
  //     let printContents = document.getElementById('qr_codes_Id').innerHTML;
  //     let popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
  //     popupWin.document.open();
  //     popupWin.document.write(`
  //     <html>
  //       <head>
  //         <title>print</title>
  //         <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
  //         <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
  //         <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
  //         <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
  //         <style>
  //         .print {
  //           text-align: center;
  //       }
  //       body {
  //         page-break-after: always;
  //       }
  //       .print .btn {
  //           text-transform: capitalize;
  //       }

  //       .job-summary .table {
  //           text-align: center;
  //       }

  //       .job-summary .table thead tr th {
  //           background: #c1c1cc;
  //           border: 1px solid #e9e9eb;
  //       }

  //       .job-summary .table tbody tr td {
  //           border: 1px solid #e9e9eb;
  //       }

  //       .row .col-12 {
  //           margin: 7px auto;
  //       }

  //       .row .col-12 .first-row-header {
  //           display: flex;
  //           justify-content: space-between;
  //           background: #c1c1cc;
  //           padding: 5px;
  //           border: 1px solid #a5a5a5;
  //       }

  //       .row .col-12 .tow-row-header,
  //       .row .col-12 .three-row-header {
  //           display: flex;
  //           justify-content: left;
  //           background: #c1c1cc;
  //           padding: 5px;
  //           border: 1px solid #a5a5a5;
  //       }

  //       .row .col-12 .tow-row,
  //       .row .col-12 .three-row {
  //           display: flex;
  //           justify-content: left;
  //           padding: 5px;
  //           border: 1px solid #a5a5a5;
  //           background-color: transparent;
  //       }

  //       .row .item-person {
  //           display: flex;
  //           padding: 10px;
  //           background: #e9e9eb;
  //           align-items: baseline;
  //           margin: 5px auto;
  //       }

  //       .row .item-person label,
  //       .row .item-person h6 {
  //           margin: 0;
  //       }

  //       .row .item-person h6 {
  //           padding-left: 5px;
  //       }

  //       .signature .table tbody td {
  //           border: 1px solid #c1c1c1 !important;
  //       }

  //       .signature .table tbody .img-sign {
  //           text-align: center;
  //       }

  //       .signature .table tbody .img-sign img {
  //           width: 100px;
  //       }

  //       .signature .table tbody .status-date {
  //           display: flex;
  //           justify-content: space-between;
  //       }

  //       .signature .table tbody .last {
  //           height: 11px;
  //       }

  //       .tittle h6 {
  //           padding: 0.5rem;
  //           text-align: center;
  //           background: #c1c1cc;
  //           margin: 10px auto;
  //       }
  //       .back{
  //         text-align:center;
  //         margin:auto;
  //       }
  //       @media print {
  //         .page-break  { display:block; page-break-aftar:always; }

  //         .btn-sm{
  //           display:none;
  //         }
  //       }
  //         </style>
  //       </head>
  //       <body onload="window.print()">
  //       <div class="back">
  //       <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
  //       </div>
  //   ${printContents}</body>
  //     </html>`
  //     );
  //     popupWin.document.close();
  // }
openLink(url){
 this._globalService.openLink(url)
}
}
