<!-- <button class="btn btn-sm btn-primary" (click)="addTool()">Add Tool</button> -->
<!-- <div [innerHTML]="test"></div> -->
<section id="permission">
    <div class="parent">
        <div class="tittle-section1">
            <h2>
                Add-Ins Store
            </h2>
            <div class="filter">
                <!-- <select class="browser-default custom-select form-control" [(ngModel)]="categoryCompanyFilterValue" (change)="filterToolsCategory($event.target.value)">
              <option value="{{categoryCompany.Inventory}}"> Moving Inventory </option>
              <option value="{{categoryCompany.Car_Wash}}"> Car Wash </option>
           </select> -->
                <span>
                  <small *ngIf="user.categoryCompany == categoryCompany.Inventory"> Moving Inventory</small>
                 <small *ngIf="user.categoryCompany == categoryCompany.Car_Wash"> Car Wash</small>
                </span>
                <select class="browser-default custom-select form-control" [(ngModel)]="companyPlanFilterValue" (change)="filterToolsPlan($event.target.value)">
                    <option  selected value="4">All</option>
                    <option value="0"> Basic </option>
                    <option value="1"> Pro </option>
                    <option value="2"> Premuim </option>
               </select>
            </div>
        </div>
        <div class="body">
            <div class="ant-empty-image" *ngIf="tools.length <= 0 ">
                <img src="./assets/img/empty.svg" alt="empty-img">
                <span>No Data</span>
            </div>
            <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedIndex">
                <mat-tab *ngFor="let item of tools; let z= index">
                    <ng-template mat-tab-label>
                        <i class="fas fa-home pr-1" *ngIf="item.toolType == 3"></i>
                        <i class="fas fa-toolbox pr-1" *ngIf="item.toolType == 2"></i>
                        <i class="uil uil-apps pr-1" *ngIf="item.toolType == 1"></i> {{item.toolName}}
                    </ng-template>
                    <div class="card">
                        <div>
                            <div class="form-group">
                                <input type="text" class="form-control border-serach" name="searchText2" [(ngModel)]="searchText2" placeholder="Search Others Add-Ins  " *ngIf="item.toolType == 1">
                                <input type="text" class="form-control border-serach" name="searchText2" [(ngModel)]="searchText2" placeholder="Search Job Dashboard Add-Ins  " *ngIf="item.toolType == 2">
                                <input type="text" class="form-control border-serach" name="searchText2" [(ngModel)]="searchText2" placeholder="Search Home Page Add-Ins " *ngIf="item.toolType == 3">
                            </div>
                        </div>
                        <div class="pagination pagination-top" *ngIf="tools.length > 0">
                            <pagination-controls previousLabel="" nextLabel="" [id]="item.toolName" (pageChange)="p[z] = $event">
                            </pagination-controls>
                        </div>
                        <div class="all-items" *ngFor="let component of item.items |  permissionFilter: searchText2  | paginate: {  id: item.toolName, itemsPerPage: 3, currentPage: p[z]}; let i= index" [ngClass]="[component.toolStatus === 'Active' ? 'active-add-in' : ' not-active-add-in']">
                            <!-- Component Tittle  -->
                            <div *ngIf="item.toolType == 1 || item.toolType == 3 || item.toolType == 2 && component.itemId != 17 " class="tittle-section" [ngClass]="[component.planName === 0 ? 'class1' : '',  component.planName === 1 ? 'class2' : '', component.planName === 2 ? 'class3' : '']">
                                <p>
                                    {{component.itemName}}
                                    <small class="plan-name free" *ngIf="component.planName === 0"> (Basic)</small>
                                    <small class="plan-name pro" *ngIf="component.planName === 1"> (Pro)</small>
                                    <small class="plan-name premium" *ngIf="component.planName === 2"> (Premium)</small>
                                </p>
                                <!-- <select #toolSelect class="browser-default custom-select" value={{component.toolStatus}} (change)="saveTool(toolSelect.value,z,component.itemId);toolModal.show()">
                            <option selected>Activate </option>
                            <option>Disactivate</option>
                       </select> -->
                                <div class="toggle" *ngIf="item.toolName === 'Job Dashboard' && component.itemId != 17" [ngClass]="[component.planName === 0 ? 'class-toggle1' : '',  component.planName === 1 ? 'class-toggle2' : '', component.planName === 2 ? 'class-toggle3' : '']">
                                    <button class="badge badge-light" *ngIf="component.planName > companyPlan" [routerLink]="['/admin/edit-company']">Upgrade</button>
                                    <!-- <mat-slide-toggle *ngIf="component.toolStatus === 'Active' && component.itemId != 16" [disabled]="component.planName > companyPlan" (change)="saveTool('Active',z,component.itemId,i);toolModal.show()" class="mat-checked pl-2"><span>Added to ToolBox</span></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="component.toolStatus === 'Active' && component.itemId == 16" [disabled]="true" class="mat-checked pl-2"><span>Added to ToolBox</span></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName > companyPlan" (change)="saveTool('InActive',z,component.itemId,i);toolModal.show()" class="pl-2"><span>Add to ToolBox</span></mat-slide-toggle>-->
                                    <button *ngIf="component.isLoading">
                              <i class="fas fa-circle-notch fa-spin pr-1" ></i>
                          </button>
                                    <button *ngIf="component.toolStatus === 'Active' && component.itemId != 16" [disabled]="component.planName > companyPlan" (click)="saveTool('Active',z,component.itemId)">
                              <i class="uil uil-times-circle uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Hide from ToolBox</small>
                            </button>
                                    <button *ngIf="component.toolStatus === 'Active' && component.itemId == 16" [disabled]="true">
                              <i class="uil uil-times-circle uil-medium pr-1" ></i> <small>Added to ToolBox</small>
                            </button>
                                    <button *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName > companyPlan" (click)="saveTool('InActive',z,component.itemId)">
                              <i class="uil uil-arrow-circle-down uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Added to ToolBox</small>
                            </button>
                                </div>
                                <div class="toggle " *ngIf="item.toolName === 'Home Page'" [ngClass]="[component.planName === 0 ? 'class-toggle1' : '',  component.planName === 1 ? 'class-toggle2' : '', component.planName === 2 ? 'class-toggle3' : '']">
                                    <button class="badge badge-light" *ngIf="component.planName > companyPlan" [routerLink]="['/admin/edit-company']">Upgrade</button>
                                    <!-- <mat-slide-toggle *ngIf="component.toolStatus === 'Active'" [disabled]="component.planName > companyPlan" (change)="saveTool('Active',z,component.itemId);toolModal.show()" class="mat-checked pl-2"><span>Added to Home Page</span></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName  > companyPlan" (change)="saveTool('InActive',z,component.itemId);toolModal.show()" class="pl-2"><span>Add to Home Page</span></mat-slide-toggle> -->
                                    <button *ngIf="component.isLoading">
                            <i class="fas fa-circle-notch fa-spin pr-1" ></i>
                            </button>
                                    <button *ngIf="component.toolStatus === 'Active'" [disabled]="component.planName > companyPlan" (click)="saveTool('Active',z,component.itemId)">
                              <i class="uil uil-times-circle uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Hide from Home Page</small>
                              </button>
                                    <button *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName  > companyPlan" (click)="saveTool('InActive',z,component.itemId)">
                              <i class="uil uil-arrow-circle-down uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Add to Home Page</small>
                              </button>
                                </div>
                                <div class="toggle" *ngIf="item.toolName === 'Others'" [ngClass]="[component.planName === 0 ? 'class-toggle1' : '',  component.planName === 1 ? 'class-toggle2' : '', component.planName === 2 ? 'class-toggle3' : '']">
                                    <button class="badge badge-light" *ngIf="component.planName > companyPlan" [routerLink]="['/admin/edit-company']">Upgrade</button>
                                    <!-- <mat-slide-toggle *ngIf="component.toolStatus === 'Active'" [disabled]="component.planName > companyPlan " (change)="saveTool('Active',z,component.itemId,i);toolModal.show()" class="mat-checked pl-2"><span>Added to Others</span></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName > companyPlan " (change)="saveTool('InActive',z,component.itemId,i);toolModal.show()" class="pl-2"><span>Add to Others</span></mat-slide-toggle> -->
                                    <button *ngIf="component.isLoading">
                                  <i class="fas fa-circle-notch fa-spin pr-1" ></i>
                              </button>
                                    <button *ngIf="component.toolStatus === 'Active'" [disabled]="component.planName > companyPlan " (click)="saveTool('Active',z,component.itemId)">
                                  <i class="uil uil-times-circle uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Hide from  Others</small>
                                </button>
                                    <button *ngIf="component.toolStatus === 'InActive'" [disabled]="component.planName > companyPlan " (click)="saveTool('InActive',z,component.itemId)">
                              <i class="uil uil-arrow-circle-down uil-medium pr-1" *ngIf="!component.isLoading"></i> <small>Added to Others</small>
                            </button>
                                </div>
                            </div>
                            <!-- Preview Add-in -->
                            <div class="accordion-preview">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            {{getPreviewObject(item.toolType,component.itemId)?.tittle}}
                                        </mat-expansion-panel-header>
                                        <div class="description">
                                            <!-- <h6> {{getPreviewObject(item.toolType,component.itemId)?.description}}</h6> -->
                                            <div [innerHTML]="getPreviewObject(item.toolType,component.itemId)?.description"></div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <!-- Permission component  -->
                            <mat-accordion class="example-headers-align" *ngIf="item.toolType == 1 || item.toolType == 3 || item.toolType == 2 && component.itemId != 17 ">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <span>Default Permission Settings</span>
                                        <button class="badge badge-light override" *ngIf="item.toolType == 1 || item.toolType == 3" [disabled]="component.planName > companyPlan" (click)="getAllPersonInCompanies(component,item.toolType,component.itemId)">Override</button>
                                    </mat-expansion-panel-header>
                                    <div class="accordion-body">
                                        <table class="table mt-2">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Change Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let role of getPermissionSettings(component.permissionSettings); let j= index">
                                                    <th>{{role.view}}{{checkItemInCustomer(item.toolType,component.itemId,role.key)}}</th>
                                                    <th scope="row">
                                                        <select #mySelect class="form-control" *ngIf="item.toolType != 3 " [(ngModel)]="role.value" [disabled]="component.planName > companyPlan" (change)="changeStatus(mySelect.value,z,component.itemId,j)">
                                                  <!-- <option selected disabled *ngIf="role.value === 0"> No Access </option>
                                                  <option selected disabled *ngIf="role.value === 1"> Can Edit </option>
                                                  <option selected disabled *ngIf="role.value === 2"> View Only </option> -->
                                                  <option value="0">No Access</option>
                                                  <option value="1" *ngIf="isCanEdit">Can Edit</option>
                                                  <option value="2"  >View Only</option>
                                                </select>
                                                        <select #mySelect1 class="form-control" *ngIf="item.toolType == 3 " [(ngModel)]="role.value" [disabled]="component.planName > companyPlan" (change)="changeStatus(mySelect1.value,z,component.itemId,j)">
                                                  <!-- <option selected disabled *ngIf="role.value === 0"> No Access </option>
                                                  <option selected disabled *ngIf="role.value === 1"> Can Access </option> -->
                                                  <!-- <option selected disabled *ngIf="role.value === 2"> View Only </option> -->
                                                  <option value="0">No Access</option>
                                                  <option value="1">Can Access</option>
                                                  <!-- <option value="2">View Only</option> -->
                                                </select>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="col-12 text-center">
                                            <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="save(indexPrimary,indexSecondary)">Save</button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <!-- Notifacations -->
                            <div class="notifications jobs-status">
                                <!-- ------------------------------------------------------------ -->
                                <div class="emailnotifications-settings" *ngIf="component.emailNotification">
                                    <mat-accordion class="example-headers-align " multi *ngFor="let emailnotify of component.emailNotification; let m= index ">
                                        <mat-expansion-panel [disabled]="!emailnotify.template">
                                            <mat-expansion-panel-header>
                                                <div class="header-notification">
                                                    <b> {{getTittleNotification(item.toolType,component.itemId,emailnotify.type)?.name}}</b> <br>
                                                    <span>{{getTittleNotification(item.toolType,component.itemId,emailnotify.type)?.description}}</span>
                                                </div>
                                                <!-- <div class="status-job" *ngIf="emailnotify.template">
                                                    <span>{{emailnotify.status}}</span>
                                                </div> -->
                                                <div class="status-select" *ngIf="!emailnotify.template">
                                                    <select #myStatusEmail class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan || item.toolType == toolType.Setting_Tools && component.itemId == toolSettingEditItem.Customers_list && emailnotify.type == emailNotificationEnum.Email_Notification_Invite_Reinvite"
                                                        [value]="emailnotify.status" [(ngModel)]="emailnotify.status" (change)='changeStatusEmail(myStatusEmail.value,z,component.itemId,emailnotify.type)'>
                                                       <option *ngFor="let item of Status"  [selected]="item.name == emailnotify.status" [value]="item.name">{{item.name}}</option>
                                                     </select>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="row">
                                                <div class="col-6" *ngIf="item.toolType === 2 && emailnotify.type != 25 && emailnotify.type != 34 && emailnotify.type != 33  || item.toolType === 3 || item.toolType == 1  && emailnotify.type != 1 ">
                                                    <select #myStatusEmail class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" value="emailnotify.status" (change)='changeStatusEmail(myStatusEmail.value,z,component.itemId,emailnotify.type)'>
                                                        <!-- <option selected disabled>Choose Status</option> -->
                                                        <option *ngFor="let item of Status" [selected]="item.name == emailnotify.status"[value]="item.name">{{item.name}} </option>
                                                       </select>
                                                </div>
                                                <div class="col-6 email-reply" *ngIf="item.toolType === 2 && emailnotify.type == 33 && component.planName <= companyPlan" (click)="emailReplyModal.show();getCompanyContactEmail()">
                                                    <div class="btn btn-sm btn-primary">Insert an email to be shown as a contact email in the template</div>
                                                </div>
                                                <div class="col-6 email-reply" *ngIf="item.toolType === 2 && emailnotify.type == 33 && component.planName > companyPlan">
                                                    <div class="btn btn-sm btn-primary">Insert an email to be shown as a contact email in the template</div>
                                                </div>
                                                <div class="col-6" *ngIf="item.toolName === 'Job Dashboard' && emailnotify.template " (click)="openTagModal(component.planName,companyPlan)">
                                                    <div class="tag-creator">
                                                        <p>Tag Creator</p>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="item.toolName === 'Others' && emailnotify.template" (click)="openTagSettingToolModal(component.planName,companyPlan)">
                                                    <div class="tag-creator">
                                                        <p>Tag Creator</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3" *ngIf="emailnotify.template">
                                                    <div class="form-group ">
                                                        <textarea id="{{emailnotify.type}}" [disabled]="component.planName > companyPlan" class="form-control" [innerHTML]="emailnotify.template">
                                              </textarea>
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="save(z,component.itemId,emailnotify.type,emailnotify.type)">Save</button>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                                <!-- ------------------------------------------------------------ -->
                                <!-- Draft EmailNotification -->
                                <mat-accordion class="example-headers-align " multi *ngIf="component.emailNotificationDraft">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <b> {{getTittleNotification(item.toolType,component.itemId,emailnotify.type)?.name}}</b> <br>
                                            <div class="status-job">
                                                <span>{{getTittleNotification(item.toolType,component.itemId,emailnotify.type)?.description}}</span>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <select #myDraft class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" (change)='onOptionsDraft(myDraft.value,z,i)'>
                                            <!-- <option selected disabled>Choose Status</option> -->
                                            <option *ngFor="let item of Status"  [value]="item.name">{{item.name}}</option>
                                       </select>
                                            </div>
                                            <div class="col-6" (click)="openTagModal(component.planName,companyPlan)">
                                                <div class="tag-creator">
                                                    <p>Tag Creator</p>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group ">
                                                    <!-- <textarea id="myTextarea" class="form-control">Dear &lt;&lt;Customer Name&gt;&gt;, &#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                                            </textarea> -->
                                                    <textarea id="myTextareaDraft" [disabled]="component.planName > companyPlan" class="form-control">{{component.emailNotificationDraft.template}}</textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="saveJobStatus('draft',z,i)">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <!-- Deal EmailNotification -->
                                <mat-accordion class="example-headers-align " multi *ngIf="component.emailNotificationDeal">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <span> {{component.emailNotificationDeal.name}}</span>
                                            <div class="status-job">
                                                <span>{{component.emailNotificationDeal.status}}</span>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <select #myDeal class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" (change)='onOptionsDeal(myDeal.value,z,i)'>
                                            <!-- <option selected disabled>Choose Status</option> -->
                                            <option *ngFor="let item of Status" [selected]="item.name == component.emailNotification.status" [value]="item.name">{{item.name}}</option>
                                        </select>
                                            </div>
                                            <div class="col-6" (click)="openTagModal(component.planName,companyPlan)">
                                                <div class="tag-creator">
                                                    <p>Tag Creator</p>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group ">
                                                    <!-- <textarea class="form-control">Dear &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                                            </textarea> -->
                                                    <textarea id="myTextareaDeal" class="form-control">{{component.emailNotificationDeal.template}}</textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="saveJobStatus('deal',z,i)">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <!-- InProcess EmailNotification -->
                                <mat-accordion class="example-headers-align " multi *ngIf="component.emailNotificationInProcess">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <span> {{component.emailNotificationInProcess.name}}</span>
                                            <div class="status-job">
                                                <span>{{component.emailNotificationInProcess.status}}</span>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <select #myInProcess class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" (change)='onOptionsInProcess(myInProcess.value,z,i)'>
                                            <!-- <option selected disabled>Choose Status</option> -->
                                            <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                                      </select>
                                            </div>
                                            <div class="col-6" (click)="openTagModal(component.planName,companyPlan)">
                                                <div class="tag-creator">
                                                    <p>Tag Creator</p>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group ">
                                                    <!-- <textarea class="form-control">Dear &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                                            </textarea> -->
                                                    <textarea id="myTextareaInProcess" class="form-control">{{component.emailNotificationInProcess.template}}</textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="saveJobStatus('inProcess',z,i)">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <!-- Done EmailNotification -->
                                <mat-accordion class="example-headers-align " multi *ngIf="component.emailNotificationInDone">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <span> {{component.emailNotificationInDone.name}}</span>
                                            <div class="status-job">
                                                <span>{{component.emailNotificationInDone.status}}</span>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <select #myDone class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" (change)='onOptionsDone(myDone.value,z,i)'>
                                          <!-- <option selected disabled>Choose Status</option> -->
                                          <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                                        </select>
                                            </div>
                                            <div class="col-6" (click)="openTagModal(component.planName,companyPlan)">
                                                <div class="tag-creator">
                                                    <p>Tag Creator</p>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group ">
                                                    <!-- <textarea class="form-control">Dear &lt;&lt;Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                                        </textarea> -->
                                                    <textarea id="myTextareaDone" class="form-control">{{component.emailNotificationInDone.template}}</textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="saveJobStatus('inDone',z,i)">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                                <!-- Canceled EmailNotification -->
                                <mat-accordion class="example-headers-align " multi *ngIf="component.emailNotificationCanceled">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <span> {{component.emailNotificationCanceled.name}}</span>
                                            <div class="status-job">
                                                <span>{{component.emailNotificationCanceled.status}}</span>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <select #myCanceled class="browser-default custom-select form-control" (change)='onOptionsCancelled(myCanceled.value,z,i)'>
                                            <!-- <option selected disabled>Choose Status</option> -->
                                            <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                                       </select>
                                            </div>
                                            <div class="col-6" (click)="openTagModal(component.planName,companyPlan)">
                                                <div class="tag-creator">
                                                    <p>Tag Creator</p>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="form-group ">
                                                    <!-- <textarea class="form-control">Dear &lt;&lt; Customer Name &gt;&gt;,&#013; &#013;Text &lt;&lt; Customer Name &gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt; Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt; Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt; Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt; Company Phone&gt;&gt;&#013;&#013;&lt;&lt; Company Logo&gt;&gt;
                                        </textarea> -->
                                                    <textarea id="myTextareaCanceled" class="form-control">{{component.emailNotificationCanceled.template}}</textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center">
                                                <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="saveJobStatus('canceled',z,i)">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <!-- Sms Messages -->
                            <div class="notifications jobs-status">
                                <!-- ------------------------------------------------------------ -->
                                <div class="emailnotifications-settings" *ngIf="component.SmsNotification">
                                    <mat-accordion class="example-headers-align " multi *ngFor="let smsNotify of component.SmsNotification; let m= index ">
                                        <mat-expansion-panel [disabled]="!smsNotify.template">
                                            <mat-expansion-panel-header>
                                                <div class="header-notification">
                                                    <b> {{getTittleSmsNotification(item.toolType,component.itemId,smsNotify.type)?.name}}</b> <br>
                                                    <span>{{getTittleSmsNotification(item.toolType,component.itemId,smsNotify.type)?.description}}</span>
                                                </div>
                                                <!-- <div class="status-job" *ngIf="smsNotify.status">
                                                    <span>{{smsNotify.status}}</span>
                                                </div> -->
                                                <div class="status-select" *ngIf="!smsNotify.template">
                                                    <select #myStatusEmail class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" [value]="smsNotify.status" [(ngModel)]="smsNotify.status" (change)='changeSmsStatusEmail(myStatusEmail.value,z,component.itemId,smsNotify.type)'>
                                                       <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                                                     </select>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <!-- <div class="row">
                                                <div class="col-6" *ngIf="item.toolType === 2 && emailnotify.type != 25 && emailnotify.type != 33  || item.toolType === 3 || item.toolType == 1  && emailnotify.type != 1 ">
                                                    <select #myStatusEmail class="browser-default custom-select form-control" [disabled]="component.planName > companyPlan" value="emailnotify.status" (change)='changeStatusEmail(myStatusEmail.value,z,component.itemId,emailnotify.type)'>
                                                        <option selected disabled>Choose Status</option>
                                                        <option *ngFor="let item of Status" [value]="item.name">{{item.name}}</option>
                                                       </select>
                                                </div>
                                                <div class="col-6 email-reply" *ngIf="item.toolType === 2 && emailnotify.type == 33 && component.planName <= companyPlan" (click)="emailReplyModal.show();getCompanyContactEmail()">
                                                    <div class="btn btn-sm btn-primary">Insert an email to be shown as a contact email in the template</div>
                                                </div>
                                                <div class="col-6 email-reply" *ngIf="item.toolType === 2 && emailnotify.type == 33 && component.planName > companyPlan">
                                                    <div class="btn btn-sm btn-primary">Insert an email to be shown as a contact email in the template</div>
                                                </div>
                                                <div class="col-6" *ngIf="item.toolName === 'Job Dashboard' && emailnotify.template " (click)="openTagModal(component.planName,companyPlan)">
                                                    <div class="tag-creator">
                                                        <p>Tag Creator</p>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="item.toolName === 'Others' && emailnotify.template" (click)="openTagSettingToolModal(component.planName,companyPlan)">
                                                    <div class="tag-creator">
                                                        <p>Tag Creator</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3" *ngIf="emailnotify.template">
                                                    <div class="form-group ">
                                                        <textarea id="{{emailnotify.type}}" [disabled]="component.planName > companyPlan" class="form-control" [innerHTML]="emailnotify.template">
                                              </textarea>
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <button class="btn btn-sm btn-success" [disabled]="component.planName > companyPlan" (click)="save(z,component.itemId,emailnotify.type,emailnotify.type)">Save</button>
                                                </div>
                                            </div> -->
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                                <!-- ------------------------------------------------------------ -->
                            </div>
                            <!-- terms& condition -->
                            <div class="accordion-preview" *ngIf="item.toolType == 1 && component.itemId == 8">
                                <mat-accordion>
                                    <mat-expansion-panel (opened)="getCompanyTermAndCondition()">
                                        <mat-expansion-panel-header>
                                            <span>Terms & Conditions</span>
                                            <!-- <div class="status-select">
                                                <select #termsSelect class="browser-default custom-select form-control" (change)='changeTermsCondition(termsSelect.value)'>
                                             <option *ngFor="let term of tagsTerms" [value]="term.value">{{term.name}}</option>
                                           </select>
                                            </div> -->
                                            <button class="badge badge-light override" (click)="tagTermsModal.show()">Tag Creator</button>
                                        </mat-expansion-panel-header>
                                        <div>
                                            <textarea id="terms_Id" class="form-control terms-condition" [innerHTML]="termsCondition">     </textarea>
                                            <div class="text-center">
                                                <button class="btn btn-sm btn-success" (click)="updateCompanyTermAndCondition()">Save</button>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="ant-empty-image" *ngIf="component.length <= 0 ">
                                <img src="./assets/img/empty.svg" alt="empty-img">
                                <span>No Data</span>
                            </div>
                        </div>
                        <div class="pagination" *ngIf="tools.length > 0">
                            <pagination-controls [id]="item.toolName" (pageChange)="p[z] = $event">
                            </pagination-controls>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</section>
<!-- tag Modal -->
<div mdbModal #tagModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tag Creater</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="tagModal.hide()">
                       <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <div *ngIf="isCopied" class="alert alert-success">
                            <i class="fas fa-check"></i> Copied to Clipboard
                        </div>
                    </div>
                    <div class="col-lg-12 text-center ">
                        <select #mySelect class="browser-default custom-select form-control" (change)='onOptionsSelected(mySelect.value)'>
                           <option *ngFor="let item of explanations" [value]="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 pattern">
                        <p class="pattern"> {{pattern}} </p>
                    </div>
                    <div class="col-lg-12 copy-section" (click)="copyNotify()">
                        <p class="copy" [cdkCopyToClipboard]="pattern">Copy</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- tag settings Modal -->
<div mdbModal #tagSettingsModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tag Creater Settings</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="tagSettingsModal.hide()">
                       <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <div *ngIf="isCopied" class="alert alert-success">
                            <i class="fas fa-check"></i> Copied to Clipboard
                        </div>
                    </div>
                    <div class="col-lg-12 text-center ">
                        <select #mySetSelect class="browser-default custom-select form-control" (change)='onOptionsSelectedSettings(mySetSelect.value)'>
                         <option *ngFor="let item of explanationsSettings" [value]="item.value">{{item.name}}</option>
                      </select>
                    </div>
                    <div class="col-lg-12 pattern">
                        <p class="pattern"> {{patternSettings}} </p>
                    </div>
                    <div class="col-lg-12 copy-section" (click)="copyNotify()">
                        <p class="copy" [cdkCopyToClipboard]="patternSettings">Copy</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal confirmation -->
<div mdbModal #toolModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{isActivate}}</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="toolModal.hide();reset(indexPrimary,indexSecondary)">
                      <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body">
                    <p class="text-center" *ngIf="isActivate == 'Active'">
                        Activate Add-In?
                    </p>
                    <p class="text-center" *ngIf="isActivate == 'InActive'">
                        Deactivate Add-In?
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-sm btn-light" (click)="toolModal.hide();reset(indexPrimary,indexSecondary)">Cancel</div>
                    <div class="btn btn-sm btn-success" (click)="toolModal.hide();save(indexPrimary,indexSecondary,null,'')">Ok</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal changeBackupTime -->
<div mdbModal #changeBackupTime="mdbModal" class="modal  fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="changeBackupTime.hide()">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <p class="text-center">
                        Are you sure from change the backup time of map?
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-sm btn-success" (click)="updateMapTimeBackUp();changeBackupTime.hide()">Ok</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- overide or customized -->
<div mdbModal #overridePermissionModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Only For This Add-In </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="overridePermissionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body" [ngClass]="[!isExistsCustomer ? 'full-width' : 'med-width']">
                    <p class="mb-2" *ngIf="allUsers.length > 0">Override Default Permission Settings</p>
                    <mat-tab-group>
                        <mat-tab label="Members">
                            <div class="form-group mb-2" *ngIf="allUsers.length > 0">
                                <input type="text" id="search-text" class="form-control" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search Member Name" />
                            </div>
                            <table class="table" *ngIf="allUsers.length > 0">
                                <thead>
                                    <tr>
                                        <th scope="col">Assigned Member</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Defualt</th>
                                        <th scope="col">Custom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of allUsers | appFilter: searchText | paginate: { id: 'person_Id',itemsPerPage: 3, currentPage: p1 }">
                                        <th>{{user.lastName}}, {{user.firstName}}</th>
                                        <td>{{user.role}}</td>
                                        <td>{{getDefaultPermission(user.role)}}</td>
                                        <td>
                                            <select #mySelect3 class="form-control" value="{{getValuePermission(user.id,user.role)}}" *ngIf="bufferToolType == 3" (change)="changeUserPermission(mySelect3.value,user.id)">
                                        <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                                        <option value="0">No Access</option>
                                      <!-- <option value="1" *ngIf="isOverrideEdit" >Can Edit</option> -->
                                      <option value="1">Can Access</option>
                                   </select>
                                            <select #mySelect3 class="form-control" value="{{getValuePermission(user.id,user.role)}}" *ngIf="bufferToolType == 1 && bufferItem.itemId != 6" (change)="changeUserPermission(mySelect3.value,user.id)">
                                        <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                                        <option value="0">No Access</option>
                                        <option value="1" >Can Edit</option>
                                        <option value="2">View Only</option>
                                     </select>
                                            <select #mySelect4 class="form-control" value="{{getValuePermission(user.id,user.role)}}" *ngIf="bufferToolType == 1 && bufferItem.itemId == 6" (change)="changeUserPermission(mySelect4.value,user.id)">
                                        <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                                        <option value="0">No Access</option>
                                        <option value="2">View Only</option>
                                     </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-footer text-center mt-3" *ngIf="allUsers.length > 0">
                                <pagination-controls id="person_Id" (pageChange)="p1=$event "></pagination-controls>
                            </div>
                            <div class="ant-empty-image" *ngIf="allUsers.length <= 0">
                                <img src="./assets/img/empty.svg" alt="empty-img">
                                <span class="mb-2">No Add Customers Yet!</span>
                            </div>
                        </mat-tab>
                        <mat-tab label="Customers" *ngIf="isExistsCustomer">
                            <div class="form-group mb-2" *ngIf="allCustomer.length > 0">
                                <input type="text" id="search-text1" class="form-control" aria-describedby="search-text1" (keydown)="restPagination1($event)" [(ngModel)]="searchText1" placeholder="Search Customer Name" />
                            </div>
                            <table class="table" *ngIf="allCustomer.length > 0">
                                <thead>
                                    <tr>
                                        <th scope="col">Assigned Customer</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Defualt</th>
                                        <th scope="col">Custom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let customer of allCustomer | appFilter: searchText1 | paginate: { id: 'customer_Id',itemsPerPage: 3, currentPage: p2 }">
                                        <th>{{customer.lastName}}, {{customer.firstName}}</th>
                                        <td>{{customer.role}}</td>
                                        <td>{{getDefaultPermission(customer.role)}}</td>
                                        <td>
                                            <select #mySelect3 class="form-control" value="{{getValuePermission(customer.id,customer.role)}}" *ngIf="bufferToolType == 3" (change)="changeUserPermission(mySelect3.value,customer.id)">
                                    <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                                    <option value="0">No Access</option>
                                  <!-- <option value="1" *ngIf="isOverrideEdit" >Can Edit</option> -->
                                  <option value="1">Can Access</option>
                               </select>
                                            <select #mySelect3 class="form-control" value="{{getValuePermission(customer.id,customer.role)}}" *ngIf="bufferToolType == 1 && bufferItem.itemId != 6" (change)="changeUserPermission(mySelect3.value,customer.id)">
                                     <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                                     <option value="0">No Access</option>
                                     <option value="1" >Can Edit</option>
                                   <option value="2">View Only</option>
                             </select>
                                            <select #mySelect4 class="form-control" value="{{getValuePermission(customer.id,customer.role)}}" *ngIf="bufferToolType == 1 && bufferItem.itemId == 6" (change)="changeUserPermission(mySelect4.value,customer.id)">
                              <option *ngIf="isHasPermission"  selected disabled>Choose permission</option>
                              <option value="0">No Access</option>
                            <option value="2">View Only</option>
                      </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-footer text-center mt-3" *ngIf="allCustomer.length > 0">
                                <pagination-controls id="customer_Id" (pageChange)="p2=$event "></pagination-controls>
                            </div>
                            <div class="ant-empty-image" *ngIf="allCustomer.length <= 0">
                                <img src="./assets/img/empty.svg" alt="empty-img">
                                <span class="mb-2">No Add Customers Yet!</span>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <!-- <div class="modal-footer text-center" *ngIf="allUsers.length > 0">
                    <button type="button " class="btn btn-sm btn-success ">Done</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- tags terms and condition Modal -->
<div mdbModal #tagTermsModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tag Creater Settings</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="tagTermsModal.hide()">
                     <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <div *ngIf="isCopied" class="alert alert-success">
                            <i class="fas fa-check"></i> Copied to Clipboard
                        </div>
                    </div>
                    <div class="col-lg-12 text-center ">
                        <select #myTermsSelect class="browser-default custom-select form-control" (change)='onOptionsSelectedSettings1(myTermsSelect.value)'>
                       <option *ngFor="let item of tagsTerms" [value]="item.value">{{item.name}}</option>
                    </select>
                    </div>
                    <div class="col-lg-12 pattern">
                        <p class="pattern"> {{patternSettings1}} </p>
                    </div>
                    <div class="col-lg-12 copy-section" (click)="copyNotify()">
                        <p class="copy" [cdkCopyToClipboard]="patternSettings1">Copy</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- tags terms and condition Modal -->
<div mdbModal #emailReplyModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Contact Email </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="emailReplyModal.hide()">
                   <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form #fromReply="ngForm" id="fromReply">
                        <div class="form-group">
                            <label for="emailReply">{{emailReplyLabel}} </label>
                            <input type="email" name="emailReply" id="emailReply" placeholder="Email to Reply" [(ngModel)]="emailReply" required [pattern]="emailPattern" class="form-control" #emailReplyModel="ngModel">
                            <div *ngIf="emailReplyModel.errors && emailReplyModel.touched" class="alert alert-danger">
                                <div *ngIf="emailReplyModel.errors.required">
                                    Email user required.
                                </div>
                                <div *ngIf="emailReplyModel.errors.pattern">
                                    Email user not valid.
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <button class="btn btn-sm btn-success" form="fromReply" [disabled]="!fromReply.valid" (click)="updateCompanyContactEmail();emailReplyModal.hide()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
