<div class="AssignedUsers section-tool-job" *ngIf="userPermission.value != statusActive.InActive ">
    <div class="tittle-section">
        <p>
            <!-- <i class="fas fa-users pr-1"></i> -->
            <!-- <i class="fas fa-users-cog pr-1"></i> -->
            <i class="uil uil-user-circle uil-medium pr-1"></i>
            <!-- <img src="./assets/img/icons/crm_icons/assigned_users.svg" class="pr-1" alt="assigned_users"> -->
            <span> Assigned Member(s) </span>
        </p>
        <button class="btn btn-success" (click)="invite()" *ngIf="userPermission.value == statusActive.Active && role !='customer'  ">
           <i class="fas fa-paper-plane mr-1"></i>Invite 3rd Party Member
        </button>
        <!-- read only  -->
        <!-- <button class="btn btn-success" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
          <i class="fas fa-paper-plane mr-1"></i>Invite 3rd Party User
       </button> -->
    </div>
    <div class="card inner-section">
        <!-- Users Assigned -->
        <mat-accordion class="example-headers-align " multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Company Members
                        <div class="title-info">
                            <span *ngIf="users?.length > 0 "> <span class="pr-1">({{totalUserCompany}})</span> </span>
                            <span *ngIf="users?.length <= 0 "> <span class="pr-1">(0)</span> </span> Member(s)
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <table class="table mt-3 text-center" *ngIf="users?.length > 0 ">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of users">
                            <td>{{item.lastName}}, {{item.firstName}}
                                <span class="badge badge-danger" *ngIf="item.disable == true && item.id != creationUserId">disable</span>
                                <span class="badge badge-primary" *ngIf="item.disable == false && item.id == creationUserId">creator</span>
                            </td>
                            <td>{{item.role}}</td>
                            <td *ngIf="userPermission.value == statusActive.Active  ">
                                <span *ngIf="checkActive(item) === true">Assigned</span>
                                <span *ngIf="checkActive(item) === false">UnAssigned</span>
                                <mat-slide-toggle *ngIf="checkActive(item) === true && item.disable == false" class="mat-checked pl-2" (click)="assignedUserModal.show();storeItem(item,false)"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == false" class="pl-2" (click)="assignedUserModal.show();storeItem(item,true)"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === true && item.disable == true" class="mat-checked pl-2" [disabled]="item.disable"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == true" class="pl-2" [disabled]="item.disable"></mat-slide-toggle>
                            </td>
                            <!-- read only  -->
                            <td *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
                                <span *ngIf="checkActive(item) === true">Assigned</span>
                                <span *ngIf="checkActive(item) === false">UnAssigned</span>
                                <mat-slide-toggle *ngIf="checkActive(item) === true" class="mat-checked pl-2" [disabled]="true"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false" class="pl-2" [disabled]="true"></mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="ant-empty-image" *ngIf="users?.length <= 0 ">
                    <img src="./assets/img/empty.svg" alt="empty-img">
                    <span>No user add yet</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- </mat-accordion> -->
        <mat-accordion class="example-headers-align " multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        3rd Party Members
                        <div class="title-info">
                            <span *ngIf="thirdPartyUsers.length > 0"> <span class="pr-1">({{totalUser3rd}}) </span></span>
                            <span *ngIf="thirdPartyUsers.length <= 0"> <span class="pr-1">(0)</span> </span>Member(s)
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-group" *ngIf="thirdPartyUsers.length > 0">
                    <input type="email" class="form-control border-serach" id="search-text" aria-describedby="search-text" (keydown)="resetPaginationUser($event)" [(ngModel)]="searchText" placeholder="Search Name">
                </div>
                <table class="table" *ngIf="thirdPartyUsers.length > 0">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of thirdPartyUsers | appFilter: searchText | paginate: {id:'thirdUsers', itemsPerPage: 3, currentPage: p1 };">
                            <td>{{item.lastName}}, {{item.firstName}}
                                <span class="badge badge-danger" *ngIf="item.disable == true && item.id != creationUserId">disable</span>
                                <span class="badge badge-primary" *ngIf="item.disable == false && item.id == creationUserId">creator</span>
                            </td>
                            <td>{{item.role}}</td>
                            <td *ngIf="userPermission.value == statusActive.Active ">
                                <span *ngIf="checkActive(item) === true">Assigned</span>
                                <span *ngIf="checkActive(item) === false">UnAssigned</span>
                                <mat-slide-toggle *ngIf="checkActive(item) === true && item.disable == false" class="mat-checked pl-2" (click)="assignedUserModal.show();storeItem(item,false)"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == false" class="pl-2" (click)="assignedUserModal.show();storeItem(item,true)"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === true && item.disable == true" class="mat-checked pl-2" [disabled]="item.disable"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == true" class="pl-2" [disabled]="item.disable"></mat-slide-toggle>
                            </td>
                            <!-- read only  -->
                            <td *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
                                <span *ngIf="checkActive(item) === true">Assigned</span>
                                <span *ngIf="checkActive(item) === false">UnAssigned</span>
                                <mat-slide-toggle *ngIf="checkActive(item) === true" class="mat-checked pl-2" [disabled]="true"></mat-slide-toggle>
                                <mat-slide-toggle *ngIf="checkActive(item) === false" class="pl-2" [disabled]="true"></mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-footer text-center " *ngIf="thirdPartyUsers.length > 0">
                    <pagination-controls id="thirdUsers" (pageChange)="p1 = $event "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="thirdPartyUsers?.length <= 0 ">
                    <img src="./assets/img/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<!-- Confirm assigned -->
<div mdbModal #assignedUserModal="mdbModal" class="modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();assignedUserModal.hide()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary" *ngIf="checkActive(bufferItem) === false">Assign Member? </p>
                    <p class="text-secondary" *ngIf="checkActive(bufferItem) === true">Unassign Member? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="assignedUserModal.hide() "> Cancel </button>
                    <button type="button " class="btn btn-sm btn-success " (click)="assignedFun(bufferItem);assignedUserModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm assigned Send -->
<div mdbModal id="sendEmailassignedUserModal" #sendEmailassignedUserModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();sendEmailassignedUserModal.hide()">
                      <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Send email to customer? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();assignedFun(bufferItem);sendEmailassignedUserModal.hide() "> No </button>
                    <button type="button " class="btn btn-sm btn-success " (click)="assignedFun(bufferItem);sendEmailassignedUserModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>