import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { identifierModuleUrl } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  baseUrl = environment.baseUrl
  dashboardTools: any
  tools: any
  toolObj: any
  constructor(private http: HttpClient) {
  }
  // getTools(){
  //   let companyId = localStorage.getItem('companyId')
  //   this.http.get(this.baseUrl + 'tools/' + companyId).subscribe((response:ResponseModel)=>{
  //     this.tools = response.data
  //     console.log(this.tools)
  //   })
  // }
  getAllToolsByCompany(companyId: any) {
    return this.http.get(this.baseUrl + 'tools/' + companyId)
  }
  getToolsByType(companyId: any, type: any) {
    return this.http.get(this.baseUrl + 'tools/' + companyId + '/' + type)
  }
  getToolItem(id,toolItem){
    return this.http.get(this.baseUrl + 'tool/' + id + '/' + toolItem)
  }
  addTool(newTool: any) {
    return this.http.post(this.baseUrl + 'tool', newTool)
  }
  updateTool(toolId: any, itemId: any, model: any) {
    return this.http.put(this.baseUrl + 'tools/' + toolId + "/" + itemId + "/", model)
  }
  getPermission(role: string, tools: any, itemId: any) {
    let roleStatus
    let itemStatus
    itemStatus = tools.items.find(i => i.itemId == itemId).toolStatus
    let roleObj: any = tools.items.find(t => t.itemId == itemId).permissionSettings.find(p => p.key == role)
    if (roleObj == undefined || roleObj == null) {
      roleStatus = 'notActive'
    }
    // console.log('this is roleObj ', roleObj)
    if (role == 'admin') {
      itemStatus = itemStatus
      roleStatus = 'active'
    }
    else if (roleObj != undefined && roleObj.value == 0) {
      roleStatus = 'notActive'
    }
    else if (roleObj != undefined && roleObj.value == 1) {
      roleStatus = 'active'
    }
    else if (roleObj != undefined && roleObj.value == 2) {
      roleStatus = 'readOnly'
    }

    let model = {
      itemStatus: itemStatus,
      roleStatus: roleStatus,
    }
    return model
  }
  // get Override permission
  getOverridePermission(role: string, tools: any, itemId: any, toolType: number, personPermissionList: any) {
    let roleStatus
    let itemStatus
    itemStatus = tools.items.find(i => i.itemId == itemId).toolStatus
    // if (personPermissionList != null || personPermissionList != undefined) {
      let person = personPermissionList.find(p => p.itemId == itemId && p.toolType == toolType)
      if(person != null || person != undefined){
         if ( person.overridePermission == 0) {
          roleStatus = 'notActive'
        }
        else if  (person.overridePermission == 1) {
          roleStatus = 'active'
        }
        else if ( person.overridePermission == 2) {
          roleStatus = 'readOnly'
        }
      }
    // }
    else{
      let roleObj: any = tools.items.find(t => t.itemId == itemId).permissionSettings.find(p => p.key == role)
      if (roleObj == undefined || roleObj == null) {
        roleStatus = 'notActive'
      }
      // console.log('this is roleObj ', roleObj)
      if (role == 'admin') {
        itemStatus = itemStatus
        roleStatus = 'active'
      }
      else if (roleObj != undefined && roleObj.value == 0) {
        roleStatus = 'notActive'
      }
      else if (roleObj != undefined && roleObj.value == 1) {
        roleStatus = 'active'
      }
      else if (roleObj != undefined && roleObj.value == 2) {
        roleStatus = 'readOnly'
      }
    }
    let model = {
      itemStatus: itemStatus,
      roleStatus: roleStatus,
    }
    return model
  }
  getEmailNotification(role: string, tools: any, itemId: any, notifyType: number) {
    let roleStatus
    // let itemStatus
    let notifyStatus
    let notifyObj
    let roleObj: any
    let model: any = {}
    // itemStatus = tools.items.find(i => i.itemId === itemId).toolStatus
    // if (role == 'admin') {
    //   console.log(tools.items.find(t => t.itemId === itemId).emailNotification )
    //   if (tools.items.find(t => t.itemId === itemId).emailNotification != undefined) {
    //     notifyObj = tools.items.find(t => t.itemId === itemId).emailNotification.find(e => e.type == notifyType)
    //     if (notifyObj != undefined) {
    //       notifyStatus = notifyObj.status
    //     }
    //   }
    //   model = {
    //     roleStatus: 'active',
    //     notifyStatus: notifyStatus
    //   }
    //   console.log('model admin', model)
    //   return model
    // }
    // else {
    notifyObj = tools.items.find(t => t.itemId == itemId).emailNotification.find(e => e.type == notifyType)
    // roleObj = tools.items.find(t => t.itemId === itemId).permissionSettings.find(p => p.key == role)
    // console.log('roleObj',roleObj)
    if (notifyObj != undefined) {
      // if (roleObj != undefined && roleObj.value == 0) {
      //   roleStatus = 'notActive'
      //   notifyStatus = 'NotActive'
      // }
      // else if (roleObj != undefined && roleObj.value == 1) {
      //   roleStatus = 'active'
      //   if (tools.items.find(t => t.itemId === itemId).emailNotification != undefined) {
      //     notifyObj = tools.items.find(t => t.itemId === itemId).emailNotification.find(e => e.type == notifyType)
      //     if (notifyObj != undefined) {
      //       notifyStatus = notifyObj.status
      //     }
      //   }
      // }
      // else if (roleObj != undefined && roleObj.value == 2) {
      //   notifyStatus = 'NotActive'
      //   roleStatus = 'readOnly'
      // }
      model = {
        // roleStatus: roleStatus,
        notifyStatus: notifyObj.status
      }
      console.log('model not admin', model)
      return model
    }
    // }
    // return null
  }
}
