<div class="submit-ticket">
    <div class="ticket-header">
        <h5>Support Tickets</h5>
    </div>
    <mat-tab-group [disableRipple]="false">
        <mat-tab label="In Progress ({{ticketsProgress.length}})">
            <div class="tittle-ticket ">
                <strong>Tickets In Progress </strong>
                <div class="btn-add-new">
                    <button class="badge badge-success" *ngIf="supportTickets.roleStatus == 'active'" (click)="addTicketModal.show()"> <i class="far fa-plus-square"></i> Add New</button>
                </div>
            </div>
            <div class="form-group" *ngIf="ticketsProgress.length > 0 ">
                <input type="text" id="ticket1-text" class="form-control border-serach" aria-describedby="ticket1-text" (keydown)="serachTicketsProgress($event)" [(ngModel)]="ticketProcessText" placeholder="Enter Ticket ID or Location to search" autofocus />
            </div>
            <mat-accordion *ngFor="let ticket of ticketsProgress | TicketFilter: ticketProcessText | paginate: { id: 'ticketsProgress',  itemsPerPage:3, currentPage: p1}; let i =index">
                <mat-expansion-panel class="progress-ticket" [hideToggle]="false" *ngIf="ticket.status < 5" (mouseover)="onFocus(ticket)">
                    <mat-expansion-panel-header>
                        <strong>Ticket ID :{{ticket.ticketNumber}}</strong>
                        <span class="mx-auto">
                          <strong class="badge badge-primary" *ngIf="ticket.status == 0">Proposed</strong>
                          <strong class="badge badge-success" *ngIf="ticket.status == 1">Active</strong>
                          <strong class="badge badge-success" *ngIf="ticket.status == 2">Resolved</strong>
                          <strong class="badge badge-success" *ngIf="ticket.status == 3">Resolved (Waiting an Update)</strong>
                          <strong class="badge badge-dark" *ngIf="ticket.status == 4">Later</strong> |
                        <strong>{{ticket.createdDate | date: 'short'}}</strong>
                      </span>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <div class="header-item">
                                    <details>
                                        <summary>Created by: {{ticket.addedBy}}</summary>
                                        <p class="mt-1"> <strong>Email : <a href="mailto: {{ticket.emailAdded}}">{{ticket.emailAdded}}</a> </strong> </p>
                                        <p *ngIf="ticket.phone"> <strong> phone : {{ticket.phone}}</strong> </p>
                                    </details>
                                    <strong> {{ticket.ticketDateUpdate | date: 'short'}}</strong>
                                    <!-- <strong>{{ticket.ticketDateUpdate | date: 'shortTime'}}</strong> -->
                                    <!-- <strong>By: {{ticket.addedBy}}</strong> -->
                                </div>
                            </div>
                            <div class="col-12 edit-history px-0">
                                <div class="btn-add-new" *ngIf="supportTickets.roleStatus == 'active'" (click)="editTicket(ticket);editTicketModal.show()">
                                    <button class="badge badge-success"> <i class="far fa-edit pr-1"></i>  Edit Ticket</button>
                                </div>
                                <div class="btn-add-new" *ngIf="supportTickets.roleStatus == 'active'" (click)="storeBufferStatus(ticket,3);closeTicketModal.show()">
                                    <button class="badge badge-danger"> Close</button>
                                </div>
                                <div class="btn-add-new" *ngIf="supportTickets.roleStatus == 'active'" (click)="storeBufferStatus(ticket,4);closeTicketModal.show()">
                                    <button class="badge badge-danger"> Disregard</button>
                                </div>
                                <div class="btn-add-new" (click)="getProgressHistory(ticket.id)">
                                    <button class="badge badge-light"> Status History</button>
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Issue Location</label>
                                <div class="content">
                                    {{ticket.issueLocation}}
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Description</label>
                                <div class="content">
                                    {{ticket.description}}
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Steps Caused Issue</label>
                                <div class="content" [innerHTML]="ticket.stepsIssue">
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Actual Result</label>
                                <div class="content" [innerHTML]="ticket.actualResult">
                                    <!-- {{ticket.actualResult}} -->
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Expected Result</label>
                                <div class="content" [innerHTML]="ticket.expectedResult">
                                    <!-- {{ticket.expectedResult}} -->
                                </div>
                            </div>
                            <div class="col-12 item" *ngIf="ticket.note">
                                <label>Note</label>
                                <div class="content" [innerHTML]="ticket.note">
                                </div>
                            </div>
                            <div class="col-4 " class="img-ticket" *ngFor="let item of ticket?.ticketImage" (click)="viewImage(item);openImgtModal.show()">
                                <label>Photo(s) Attached</label>
                                <div *ngIf="item && !item.isDeleted">
                                    <img src="{{item.src}}" alt="image-ticket">
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="text-center mt-3" *ngIf="ticketsProgress.length > 0 ">
                <pagination-controls id="ticketsProgress" (pageChange)="p1 = $event"></pagination-controls>
            </div>
            <div class="ant-empty-image" *ngIf="ticketsProgress.length <= 0 ">
                <img src="./assets/img/empty.svg" alt="empty-img">
                <span>No Data</span>
            </div>
        </mat-tab>
        <mat-tab label="Closed ({{ticketsClose.length}}) ">
            <div class="tittle-ticket">
                <strong>Tickets Closed </strong>
                <!-- <button class="badge badge-success" (click)="addTicketModal.show()"> <i class="far fa-plus-square" ></i> Add New</button> -->
            </div>
            <div class="form-group" *ngIf="ticketsClose.length > 0 ">
                <input type="text" id="ticket-text" class="form-control border-serach" aria-describedby="ticket-text" (keydown)="serachTicketsClose($event)" [(ngModel)]="ticketCloseText" placeholder="Enter Ticket ID or Location to search" autofocus />
            </div>
            <mat-accordion *ngFor="let ticket of ticketsClose | TicketFilter: ticketCloseText | paginate: { id: 'ticketsClose',  itemsPerPage:3, currentPage: p2 };let i = index">
                <mat-expansion-panel class="close-ticket" [hideToggle]="false" *ngIf="ticket.status == 5 || ticket.status == 6 ">
                    <mat-expansion-panel-header>
                        <strong>Ticket ID :{{ticket.ticketNumber}}</strong>
                        <span class="mx-auto">
                      <strong class="badge badge-danger" *ngIf="ticket.status == 5">Closed</strong>
                      <strong class="badge badge-danger" *ngIf="ticket.status == 6">Discard</strong> |
                      <strong>{{ticket.createdDate | date: 'short'}}</strong>
                    </span>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <div class="header-item">
                                    <details>
                                        <summary>Created by: {{ticket.addedBy}}</summary>
                                        <p class="mt-1"> <strong>Email : <a href="mailto: {{ticket.emailAdded}}">{{ticket.emailAdded}}</a> </strong> </p>
                                        <p *ngIf="ticket.phone"> <strong> phone : {{ticket.phone}}</strong> </p>
                                    </details>
                                    <strong>{{ticket.ticketDateUpdate | date: 'short'}}</strong>
                                    <!-- <strong>{{ticket.ticketDateUpdate | date: 'shortTime'}}</strong> -->
                                    <!-- <strong>By: {{ticket.addedBy}}</strong> -->
                                </div>
                            </div>
                            <div class="col-12 px-0">
                                <div class="btn-add-new" (click)="getClosedHistory(ticket.id)">
                                    <button class="badge badge-light"> Status History</button>
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Issue Location</label>
                                <div class="content">
                                    {{ticket.issueLocation}}
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Description</label>
                                <div class="content">
                                    {{ticket.description}}
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Steps Caused Issue</label>
                                <div class="content" [innerHTML]="ticket.stepsIssue">
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Actual Result</label>
                                <div class="content" [innerHTML]="ticket.actualResult">
                                    <!-- {{ticket.actualResult}} -->
                                </div>
                            </div>
                            <div class="col-12 item">
                                <label>Expected Result</label>
                                <div class="content" [innerHTML]="ticket.expectedResult">
                                    <!-- {{ticket.expectedResult}} -->
                                </div>
                            </div>
                            <div class="col-12 item" *ngIf="ticket.note">
                                <label>Note</label>
                                <div class="content" [innerHTML]="ticket.note">
                                </div>
                            </div>
                            <div class="col-4" class="img-ticket" *ngFor="let item of ticket?.ticketImage" (click)="viewImage(item);openImgtModal.show()">
                                <div *ngIf="item && !item.isDeleted">
                                    <img src="{{item.src}}" alt="image-ticket">
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="text-center mt-3" *ngIf="ticketsClose.length > 0 ">
                <pagination-controls id="ticketsClose" (pageChange)="p2 = $event"></pagination-controls>
            </div>
            <div class="ant-empty-image" *ngIf="ticketsClose.length <= 0 ">
                <img src="./assets/img/empty.svg" alt="empty-img">
                <span>No Data</span>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<!-- Add Ticket -->
<div mdbModal id="addTicketModal" #addTicketModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">New Ticket</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addTicketModal.hide()"> <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="addTicketFormGroup">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="issueLocation">Issue Location <span>*</span> :</label>
                                    <input type="text" id="issueLocation" formControlName="issueLocation" placeholder="Page Name/Tool Name/ Function" class="form-control">
                                    <div *ngIf="f.issueLocation.touched && f.issueLocation.invalid" class="alert alert-danger">
                                        <div *ngIf="f.issueLocation.errors.required">Issue Location is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="description">Description <span>*</span> : </label>
                                    <textarea type="text" id="description" formControlName="description" placeholder="Brief of the Issue." class="form-control" rows="3"></textarea>
                                    <div *ngIf="f.description.touched && f.description.invalid" class="alert alert-danger">
                                        <div *ngIf="f.description.errors.required">Description is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="stepsIssue">Steps Caused Issue<span>*</span> : </label>
                                    <textarea type="text" id="stepsIssue" formControlName="stepsIssue" placeholder="Actions made that caused the Issue: &#13;1-Setp 1 &#13;2-Setp 2 &#13;3-Setp 3" class="form-control" rows="3"></textarea>
                                    <div *ngIf="f.stepsIssue.touched && f.stepsIssue.invalid" class="alert alert-danger">
                                        <div *ngIf="f.stepsIssue.errors.required">Steps Issue is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="actualResultAdd">Actual Result<span>*</span> : </label>
                                    <textarea type="text" id="actualResultAdd" formControlName="actualResult" placeholder="Software Response Which is inconsistent with the Software Specification" class="form-control" rows="3"></textarea>
                                    <div *ngIf="f.actualResult.touched && f.actualResult.invalid" class="alert alert-danger">
                                        <div *ngIf="f.actualResult.errors.required">Actual Result is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="expectedResultAdd">Expected Result<span>*</span> : </label>
                                    <textarea type="text" id="expectedResultAdd" formControlName="expectedResult" placeholder="Software Response Which is consistent with the Software Specification" class="form-control" rows="3"></textarea>
                                    <div *ngIf="f.expectedResult.touched && f.expectedResult.invalid" class="alert alert-danger">
                                        <div *ngIf="f.expectedResult.errors.required">Expected Result is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="noteAdd">Note: </label>
                                    <textarea type="text" id="noteAdd" formControlName="note" placeholder="Please Lets Know If you have any Note: &#13;1-Note  1 &#13;2-Note 2 " class="form-control" rows="3"></textarea>
                                    <!-- <div *ngIf="f.note.touched && f.note.invalid" class="alert alert-danger">
                                        <div *ngIf="f.note.errors.required">Note is required.</div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <input id="file" type="file" class="form-control" (change)="onFileChange($event)">
                                </div>
                            </div> -->
                            <!-- <div class="col-6 upload">
                                <div class="form-group mx-0">
                                    <input id="actual-btn" type="file" class="form-control" accept="image/*" multiple hidden (change)="onFileChange($event)">
                                    <label class="btn-upload" for="actual-btn">
                                    <i class="fas fa-upload fa-1x"></i>
                                    Choose File
                                  </label>
                                </div>
                            </div> -->
                            <div class="col-12 upload">
                                <div class="form-group mx-0">
                                    <input id="actual-btn" type="file" class="form-control" accept="image/*" multiple hidden (change)="selectFiles($event)">
                                    <label class="btn-upload" for="actual-btn">
                                  <i class="fas fa-upload fa-2x"></i>
                                  Upload Screenshoot
                                </label>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-3" *ngFor="let item of addTicketModel?.ticketImage">
                                <div class="img-add-ticket" *ngIf="item && !item.isDeleted">
                                    <div class="remove-btn" (click)="removeImage(item.id)">
                                        <i class="fas fa-times"></i>
                                    </div>
                                    <img *ngIf="item" src="{{item.src}}" alt="ticket-img">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer text-center">
                    <button type="submit " class="btn btn-sm btn-success " [disabled]="addTicketFormGroup.invalid" (click)="AddTicket(addTicketModal);addTicketModal.hide()">Add Ticket </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Update Ticket -->
<div mdbModal #editTicketModal="mdbModal" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Ticket</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editTicketModal.hide()"> <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <form #editForm="ngForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="issueLocation">Issue Location <span>*</span> :</label>
                                    <textarea type="text" id="issueLocation" name="issueLocation" required placeholder="Page Name/Tool Name/ Function" (blur)="checkValidate()" [(ngModel)]="editTicketModel.issueLocation" #issueLocation="ngModel" class="form-control" rows="3"></textarea>
                                    <div *ngIf="issueLocation.errors && issueLocation.touched" class="alert alert-danger">
                                        <div *ngIf="issueLocation.errors.required">
                                            Issue Location required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="description">Description <span>*</span> : </label>
                                    <textarea type="text" id="description" name="description" required placeholder="Brief of the Issue." [(ngModel)]="editTicketModel.description" #description="ngModel" class="form-control" rows="3"></textarea>
                                    <div *ngIf="description.errors && description.touched" class="alert alert-danger">
                                        <div *ngIf="description.errors.required">
                                            Description required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="stepsIssuEdit">Steps Caused Issue <span>*</span> : </label>
                                    <textarea type="text" id="stepsIssueEdit" name="stepsIssue" [innerHTML]="editStep" (blur)="checkValidate()" required placeholder="Actions made that caused the Issue: &#13;1-Setp 1 &#13;2-Setp 2 &#13;3-Setp 3" class="form-control" rows="3"></textarea>
                                    <!-- <div *ngIf="stepsIssueEdit.errors && stepsIssueEdit.touched" class="alert alert-danger">
                                        <div *ngIf="stepsIssueEdit.errors.required">
                                            Steps Caused Issue required.
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="actualResult">Actual Result <span>*</span> : </label>
                                    <textarea type="text" id="actualResultEdit" name="actualResult" placeholder="Software Response Which is consistent with the Software Specification" (blur)="checkValidate()" [innerHTML]="actualResult" required class="form-control" rows="3"></textarea>
                                    <!-- <div *ngIf="isValidate" class="alert alert-danger">
                                        Actual Result required.
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="expectedResult">Expected Result <span>*</span> : </label>
                                    <textarea type="text" id="expectedResultEdit" name="expectedResult" (blur)="checkValidate()" required placeholder="Software Response Which is consistent with the Software Specification" [innerHTML]="expectedResult" class="form-control" rows="3"></textarea>
                                    <!-- <div *ngIf="expectedResult.errors && expectedResult.touched" class="alert alert-danger">
                                        <div *ngIf="expectedResult.errors.required">
                                            Expected Result required.
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="noteEdit">Note: </label>
                                    <textarea type="text" id="noteEdit" name="note" placeholder="Please Lets Know If you have any Note: &#13;1-Note  1 &#13;2-Note 2 " [innerHTML]="editNote" class="form-control" rows="3"></textarea>
                                    <!-- <div *ngIf="f.note.touched && f.note.invalid" class="alert alert-danger">
                                    <div *ngIf="f.note.errors.required">Note is required.</div>
                                </div> -->
                                </div>
                            </div>
                            <div class="col-12 upload">
                                <div class="form-group mx-0">
                                    <input id="actual-btn-more" type="file" class="form-control" accept="image/*" hidden (change)="selectMoreFiles($event)">
                                    <label class="btn-upload" for="actual-btn-more">
                                <i class="fas fa-upload fa-2x"></i>
                                Upload Screenshoot
                              </label>
                                </div>
                            </div>
                            <div class="col-lg-4" *ngFor="let item of editTicketModel.ticketImage">
                                <div class="img-add-ticket" *ngIf="item && !item.isDeleted">
                                    <div class="remove-btn">
                                        <i class="fas fa-times" (click)="removeImageTicket(item)"></i>
                                    </div>
                                    <img *ngIf="item" src="{{item.src}}" alt="ticket-img">
                                </div>
                            </div>
                            <!--
                            <div class="col-lg-4 mt-3" *ngFor="let item of addTicketModel?.ticketImage">
                              <div class="img-add-ticket">
                                  <div class="remove-btn" (click)="removeImage(item.id)">
                                      <i class="fas fa-times"></i>
                                  </div>
                              </div>
                          </div> -->


                        </div>
                    </form>
                </div>
                <div class="modal-footer text-center">
                    <button type="submit " class="btn btn-sm btn-success " [disabled]="!editForm.valid || isValidate" (click)="updateTicket();editTicketModal.hide()">Update Ticket  </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Open Image Ticket -->
<div mdbModal #openImgtModal="mdbModal" class="modal img-modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <!-- <div class="modal-header">
                    <h5 class="modal-title" id="openImgtModal">Update Ticket</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="openImgtModal.hide()"> <span aria-hidden="true">&times;</span></button>
                </div> -->
                <button type="button" class="close" data-dismiss="modal" (click)="openImgtModal.hide()"> <span aria-hidden="true">&times;</span></button>
                <div class="modal-body">
                    <img src="{{imgViewUrl}}" alt="ticket-img" class="modal-content">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Hitories Modal -->
<div class="modal  history " mdbModal #historiesModal="mdbModal">
    <div class="modal-dialog modal-lg ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Status History</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="historiesModal.hide()" aria-label="Close ">
               <span>&times;</span>
           </button>
            </div>
            <div class="modal-body ">
                <!-- <div *ngFor="let item of histories | HistoryFilter: historyText | paginate: {id: 'history', itemsPerPage: 3, currentPage: p2 } "> -->
                <!-- <mat-tab-group> -->
                <!-- <mat-tab label="Status" aria-disabled="true"> -->
                <div *ngFor="let item of histories">
                    <div class="card " *ngIf="item.type == 0">
                        <div>
                            <small>{{item.date | date: 'short'}}</small>
                            <!-- <small> By: {{item.addBy}} </small> -->
                            <div class="row-history ">
                                <p> <span class="blue-item">Ticket Status </span> Changed From
                                    <span class="section-from" *ngIf="item.ticketFrom == 0">[Proposed]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 1">[Active]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 2">[Resolved]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 3">[Resolved (Waiting an Update)]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 4">[Later]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 5">[Close]</span>
                                    <span class="section-from" *ngIf="item.ticketFrom == 6">[Discard]</span> To
                                    <span class="section-to" *ngIf="item.ticketTo == 0">[Proposed]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 1">[Active]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 2">[Resolved]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 3">[Resolved (Waiting an Update)]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 4">[Later]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 5">[Closed]</span>
                                    <span class="section-to" *ngIf="item.ticketTo == 6">[Discard]</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ant-empty-image" *ngIf="histories.length <= 0 ">
                    <img src="./assets/img/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
                <!-- </mat-tab> -->
                <!-- </mat-tab-group> -->
                <!-- <div class="text-center mt-3 " *ngIf="histories.length> 0 ">
              <pagination-controls id="history" (pageChange)="p2 = $event "></pagination-controls>
          </div> -->
            </div>
        </div>
    </div>
</div>
<!-- close Modal -->
<!-- <div mdbModal #closeTicketModal="mdbModal" class="modal fade">
  <div class="modal-dialog " role="document">
      <div class="container">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title">Confirmiation </h5>
                  <button type="button" class="close" data-dismiss="modal" (click)="closeTicketModal.hide()">
                 <span aria-hidden="true">&times;</span>
          </button>
              </div>
              <div class="modal-body text-center">
                  <p class="text-secondary">Add Column to the Right?</p>
              </div>
              <div class="modal-footer text-center">
                  <button type="button " class="btn btn-sm btn-success " (click)="addColumn();closeTicketModal.hide()">Yes</button>
              </div>
          </div>
      </div>
  </div>
</div> -->
<!-- Discard and close Modal -->
<div mdbModal #closeTicketModal="mdbModal" class="modal fade">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmiation </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="closeTicketModal.hide()">
                   <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary" *ngIf="typeTicket == 4">Disregard ticket ?</p>
                    <p class="text-secondary" *ngIf="typeTicket == 3">Close ticket ?</p>
                </div>
                <div class="modal-footer text-center">
                    <button type="button " class="btn btn-sm btn-success " (click)="updateStatusTicket(bufferTicket,typeTicket);closeTicketModal.hide()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
