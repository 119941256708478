<section id="warehouse-map">
    <div class="tittle-section">
        <p><i class="fas fa-sticky-note pr-1"></i>Warehouse Map</p>
        <button class="btn btn-success"><i class="fas fa-tasks pr-1"></i>
        <a href="https://devwarehouse2021.000webhostapp.com">Manage</a>
        </button>
    </div>
    <div class="items">
        <div class="item-status">
            <label>Total Containers : 0000</label>
        </div>
    </div>
</section>
