<div class="card">
    <div class="tittle-section">
        <p>Add a Vehicle</p>
    </div>
    <div class="section-stepper">
        <form [formGroup]="addVehicleFormGroup">
            <mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]="getActiveStep()">
                <mat-step state="edit">
                    <ng-template matStepLabel>Vehicle information <i class="required italic-required">(required)</i></ng-template>
                    <div class="form-group">
                        <label for="vehicle-type">Make<span class="required">* </span> :</label>
                        <input type="text" id="vehicle-type" class="form-control" readonly placeholder="Vehicle Make" formControlName="vehicleType">
                        <!-- <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.vehicleType.errors.required">Vehicle Type is required</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label for="plate-number">Plate / #VIN( Last 5 Digits)<span class="required">* </span> :</label>
                        <input type="text" id="plate-number" class="form-control text-uppercase" readonly placeholder="Plate Number / #VIN (Last 5 digits)" formControlName="plateNumber">
                        <!-- <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                            <div *ngIf="f.plateNumber.errors.pattern">Plate Number not valid must only number.</div>
                        </div> -->
                    </div>
                    <div class="form-group">
                        <label for="vin">Model :</label>
                        <input type="text" id="vin" class="form-control" readonly placeholder="Car Model" formControlName="vehicleModel">
                    </div>
                    <!-- <div class="form-group">
                        <label for="color">Color :</label>
                        <input type="text" id="color" class="form-control" readonly placeholder="Car Color" formControlName="color">
                    </div> -->
                    <div class="form-group">
                        <label for="condition">Condition :</label>
                        <textarea type="text" id="condition" class="form-control" readonly placeholder="Car Condition" formControlName="condition" rows="3"></textarea>
                    </div>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid ">Next</button>
                </mat-step>
                <!-- <mat-step state="edit">
                    <ng-template matStepLabel>Customer Information <i class="italic-optional">(optinal)</i></ng-template>
                    <div class="form-group">
                        <label for="firstName">First Name<span class="required">* </span> :</label>
                        <input type="text" id="firstName" *ngIf="vehicle.customer" class="form-control" readonly placeholder="Customer Fisrt Name" formControlName="firstName">
                        <input type="text" id="firstName" *ngIf="!vehicle.customer" class="form-control" placeholder="Customer Fisrt Name" formControlName="firstName">
                        <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lastName">Last Name<span class="required">* </span> :</label>
                        <input type="text" id="lastName" *ngIf="vehicle.customer" class="form-control" readonly placeholder="Customer Last name" formControlName="lastName">
                        <input type="text" id="lastName" *ngIf="!vehicle.customer" class="form-control" placeholder="Customer Last name" formControlName="lastName">
                        <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">E-mail<span class="required">* </span> :</label>
                        <input type="text" id="email" *ngIf="vehicle.customer" class="form-control" readonly placeholder="Customer E-mail" formControlName="email">
                        <input type="text" id="email" *ngIf="!vehicle.customer" class="form-control" placeholder="Customer E-mail" formControlName="email">
                        <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email not valid</div>
                        </div>
                    </div>
                    <button matStepperPrevious type="button" class="btn btn-sm btn-primary">Back</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" *ngIf="!vehicle.customer" [disabled]="f.firstName.invalid || f.lastName.invalid || f.email.invalid   " (click)="addCustomer()">Add customer Information</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" *ngIf="vehicle.customer">Next</button>
                </mat-step> -->
                <mat-step state="edit">
                    <ng-template matStepLabel>Membership <i class="italic-optional">(Optional)</i></ng-template>
                    <div class="form-group" *ngIf="vehicle.id && !vehicle.isMemberShip">
                        <div class="check-section" *ngIf="vehicle.id && !vehicle.isMemberShip && !vehicle.isSubscriptionEnd ">
                            <!-- Card checked radio -->
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Card" (change)="handleChange($event)" id="flexRadioDefault2" checked/>
                                <label class="form-check-label" for="flexRadioDefault2">Card </label>
                            </div>
                            <!-- Cash checked radio -->
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Cash" (change)="handleChange($event)" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Cash </label>
                            </div>
                        </div>
                        <span class="no-vehicle" *ngIf="!vehicle.id">First, you MUST add vehicle information. </span>
                        <!-- cash Payment -->
                        <div class="cash-month" *ngIf="vehicle.id && !vehicle.isMemberShip || vehicle.isSubscriptionEnd && vehicle.stripeObj">
                            <span class="pr-2">Expires aftar </span>
                            <select class="browser-default custom-select form-control" *ngIf="paymentType == paymentTypeEnum.card" (change)="chooseCountMonth($event.target.value)">
                              <option *ngFor="let month of monthsCard" value="{{month}}"> {{month}} </option>
                             </select>
                            <select class="browser-default custom-select form-control" *ngIf="paymentType == paymentTypeEnum.cash" (change)="chooseCountMonth($event.target.value)">
                              <option *ngFor="let month of monthsCash" value="{{month}}"> {{month}} </option>
                             </select>
                            <span class="pl-2"> Months</span>
                        </div>
                        <!-- <button class="btn btn-sm btn-success mt-3" *ngIf="paymentType == paymentTypeEnum.card_exists && vehicle.id" (click)="storeVehicleId(vehicle.id);customerCashModal.show()">Create Cash Account (One time only)</button> -->
                        <!-- <label for="email" *ngIf="paymentType == paymentTypeEnum.card && vehicle.id">E-mail <span class="required">* </span>  :</label> -->
                        <!-- <input type="text" id="email" class="form-control" readonly *ngIf="paymentType == paymentTypeEnum.card && vehicle.id && vehicle.customer" placeholder="Customer E-mail" formControlName="email"> -->
                        <!-- <input type="text" id="email" class="form-control" *ngIf="paymentType == paymentTypeEnum.card && vehicle.id && !vehicle.customer" placeholder="Customer E-mail" formControlName="email"> -->
                        <!-- <div *ngIf="f.email.touched && f.email.invalid && paymentType == paymentTypeEnum.card && vehicle.id" class="alert alert-danger" role="alert">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email not valid</div>
                        </div> -->
                    </div>
                    <div>
                        <small class="required" *ngIf="isHaveSecretKey">Make sure that the payment gate is integrated in the company account.<br></small>
                        <!-- <button class="btn btn-sm btn-primary" *ngIf="paymentType == paymentTypeEnum.card && !vehicle.isMemberShip" [disabled]="!vehicle.id || f.email.invalid " (click)="createMemberShip(vehicle.id)">Create Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button> -->
                        <button class="btn btn-sm btn-primary" *ngIf="paymentType == paymentTypeEnum.cash && !vehicle.isMemberShip || paymentType == paymentTypeEnum.card && !vehicle.isMemberShip" [disabled]="!vehicle.id " (click)="createMemberShipCash(vehicle.id)">Create Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                        <!-- <button class="btn btn-sm btn-primary" *ngIf="vehicle.isMemberShip &&  !vehicle.isSubscriptionEnd" (click)="createPortalCustomerStripeCustom()">View Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button> -->
                        <button class="btn btn-sm btn-primary" *ngIf="paymentType == paymentTypeEnum.cash &&  vehicle.isMemberShip &&  vehicle.isSubscriptionEnd && vehicle.stripeObj || paymentType == paymentTypeEnum.card && vehicle.isMemberShip &&  vehicle.isSubscriptionEnd && vehicle.stripeObj"
                            [disabled]="!vehicle.id " (click)="createMemberShipCash(vehicle.id);renewMembership()">Renew Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                    </div>
                </mat-step>
                <!-- <mat-step state="edit">
                    <ng-template matStepLabel>QR Code <i class="italic-optional">(Optional, It will provided at the car wash site)</i></ng-template>
                    <div class="tittle-section-qr">
                        <p>QR Code</p>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control qr-input" readonly placeholder="QR Code Id" formControlName="qrCodeId">
                    </div>
                    <div>
                        <button matStepperPrevious type="button" class="btn btn-sm btn-primary">Back</button>
                    </div>
                </mat-step> -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
            </mat-vertical-stepper>
        </form>
    </div>
</div>
<!--  Warning key  -->
<div mdbModal #warningKeyModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warning</h5>
                    <button type="button" class="close" (click)="warningKeyModal.hide()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                    <p>The payment gate is NOT integrated in the company account.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Cash key  -->
<div mdbModal #warningCashModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningCashModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p>Please add an email for the cash membership first. <br> Menu > Company account> Stripe integration. </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningCashModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Email Customer Cash modal -->
<div id="myModal" mdbModal #customerCashModal="mdbModal" class="modal cashModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Add a stripe cash account (One time only)</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="customerCashModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body strip_key-body">
                <ol>
                    <li>Create a regular new email (must be different than the one stripe account created with).</li>
                    <li>Add the email below.
                        <div class="form-group mt-2">
                            <input name="customerEmailCash" id="customerEmailCash" class="form-control" placeholder="Email" [(ngModel)]="customerEmailCash">
                        </div>
                    </li>
                    <li>Click [Create cash Membership].</li>
                </ol>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger" (click)="customerCashModal.hide()">Cancel</button>
                <button class="btn btn-sm btn-success" (click)="createMemberShip(bufferVehicleId);customerCashModal.hide()">Create Cash Membership</button>
            </div>
        </div>
    </div>
</div>