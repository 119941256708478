<div class="card">
    <div class="tittle-section">
        <p> Car Wash Manager</p>
        <div class=" btn-add-new" *ngIf="carWashObj.roleStatus == 'active'|| user.role == 'admin' ">
            <span class="badge badge-success mr-2" (click)="checkStripKey()">Invite</span>
            <span class="badge badge-success" (click)="redirection()">Add New</span>
        </div>
    </div>
    <!-- <div class="link-invite">
        <div *ngIf="isCopied" class="alert alert-success">
            <i class="fas fa-check"></i> Copied to Clipboard
        </div>
        <div class="input-group" [cdkCopyToClipboard]="inviteLink" (click)="copyLink()">
            <div class="input-group-prepend">
                <span class="input-group-text"> Link Invite </span>
            </div>
            <input type="url" class="form-control" readonly value="{{inviteLink}}">
            <div class="input-group-append copy-icon">
                <span class="input-group-text"> <i class="uil uil-copy"></i>  </span>
            </div>
        </div>
    </div> -->
    <mat-tab-group>
        <mat-tab label="Check">
            <div class="templates">
                <div class="row search-qr-scan">
                    <div class="col-lg-12">
                        <div class="form-group" *ngIf="vehicles.length > 0">
                            <input type="text" id="search-text" class="form-control" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search Plate / Make / #VIN (Last 4 digits)" />
                        </div>
                    </div>
                    <div class="col-12 " (click)="cameraScanModal.show();openCamera()" *ngIf="vehicles.length > 0 && isMobile">
                        <div class="btn btn-sm btn-primary">
                            <p> <i class="uil uil-camera"></i> Capture Plate </p>
                        </div>
                    </div>
                    <div class="col-12 " (click)="searchQR()" *ngIf="vehicles.length > 0 && isMobile">
                        <div class="btn btn-sm btn-primary">
                            <p> <i class="uil uil-qrcode-scan "></i> Scan QR Code</p>
                        </div>
                    </div>
                </div>
                <div class="header-member" *ngIf="vehicles.length > 0">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <div class="row ">
                                    <div class="col-4">
                                        <span class="tittle ">
                                          <span>Plate / #VIN <strong >({{vehicles.length}})</strong></span>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                      <span>Make</span>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                        <span >M. Status <strong>({{countMemberShip}})</strong></span>
                                        </span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <mat-accordion *ngFor="let vehicle of vehicles | vehicleFilter: searchText | paginate: {id: 'vehicle_id' , itemsPerPage: itemsPerPage, currentPage: p }; let i=index ">
                    <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                        <mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-4">
                                    <span class="tittle plate-make">
                                  <span>{{vehicle.plateNumber}}</span>
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="tittle plate-make text-capitalize">
                                  <span>{{vehicle.vehicleType}}</span>
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="tittle">
                                  <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                                    <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd">
                                      <i class="uil uil-money-bill-slash uil-medium required"></i>
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="body">
                            <div class="header-vehicle">
                                <div class="added-by-date">
                                    <!-- <details>
                                        <summary><strong>Branch Name:</strong> {{vehicle?.branchName}}</summary>
                                        <p class="mt-1"> <strong>Added By : </strong> {{vehicle.addedBy}} </p>
                                    </details> -->
                                    <!-- <div class="badge badge-light" (click)="storeHistories(vehicle);historiesModal.show()">
                                        <span>Edit History</span>
                                    </div> -->
                                    <!-- <span>{{vehicle.date | date : 'MMM d,  y'}}, {{vehicle.creationTime}}</span> -->
                                    <div class="icons-vehicle">
                                        <!-- <small [ngClass]="[vehicle.id != '' ? 'icon-active' : 'icon-not-active']"><i class="fas fa-car"></i></small> -->
                                        <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                        <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                        </small>
                                        <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                    </div>
                                    <div *ngIf="!vehicle.isSubscriptionEnd">
                                        <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt)
                                        | date:'M/d/yyyy'}} </span>
                                        <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                        </span>
                                        <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                                          <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                        <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                                         <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                                          <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, yy'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate
                                        | date: 'MMM d, yy'}}
                                        </span>
                                        <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                                          <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                        </span>
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && carWashObj.roleStatus != 'notActive'  && user.role != 'admin'">
                                    <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                </div> -->
                                <!-- <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage != 0 && carWashObj.roleStatus != 'notActive' && user.role != 'admin'">
                                    <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                </div> -->
                            </div>
                            <!-- <div class="button-group">
                                <button class="btn btn-sm btn-info-custom" (click)="getVehicleStaticsYear(vehicle.id)">
                                Service Statistics
                              </button>
                                <div *ngIf="!vehicle.isSubscriptionEnd">
                                    <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                    <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Subscription</span> </span>
                                    <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                </div>
                            </div> -->

                            <div class="section-checks">
                                <div class="button-group" *ngIf="isCheckInVehicle(vehicle.id) == false && carWashObj.roleStatus == 'active'  || isCheckInVehicle(vehicle.id) == false && user.role == 'admin' ">
                                    <div class="label-check" *ngIf="vehicle.lastCheckDate">
                                        <span *ngIf="isUnCheckInVehicle(vehicle.id) == true"><small class="required"> <i class="uil uil-clock-three uil-extra-small"></i> Uncheck in,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                        <span *ngIf="isUnCheckInVehicle(vehicle.id) == false "><small class="required"> <i class="uil uil-clock-three uil-extra-small"></i> Checked out,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" (click)="checkIn(vehicle) ">Check In   </button>
                                        <button class="btn btn-sm btn-danger-custom" [disabled]="true">Checked Out</button>
                                    </div>
                                    <!-- <div> -->
                                    <!-- <div class="item-check"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_In_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- <div class="item-check item-check-out disabled-option"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_Out_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- </div> -->
                                    <!-- <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                    <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Forever</span> </span>
                                    <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span> -->
                                </div>
                                <div class="button-group" *ngIf="isCheckInVehicle(vehicle.id) == true  && carWashObj.roleStatus == 'active'  || isCheckInVehicle(vehicle.id) == true && user.role == 'admin' ">
                                    <div class="label-check" *ngIf="vehicle.lastCheckDate">
                                        <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Checked in,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" [disabled]="true">Checked In </button>
                                        <button class="btn btn-sm btn-danger-custom" (click)="checkOut(vehicle)">Check Out </button>
                                    </div>
                                    <!-- <div> -->
                                    <!-- <div class="item-check disabled-option"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_In_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- <div class="item-check item-check-out"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_Out_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- </div> -->
                                    <!-- <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                    <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Forever</span> </span>
                                    <span *ngIf="vehicle.cancelAt  && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span> -->
                                </div>
                            </div>
                            <div class="info-vehicle">
                                <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                    <p>Model</p>
                                    <span>{{vehicle.vehicleModel}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.phone">
                                    <p>Phone</p>
                                    <span>{{vehicle.phone}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.condition">
                                    <p>Condition</p>
                                    <span>{{vehicle.condition}}</span>
                                </div>
                            </div>
                            <div class="footer-vehicle" *ngIf="carWashObj.roleStatus == 'active'|| user.role == 'admin' ">
                                <div>
                                    <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Actions </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="selectAction(actionsCar.Edit_Vehicle_Profile,vehicle)"> <i class="uil uil-edit"></i>Edit Vehicle Information</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Qr_Code,vehicle)" *ngIf="user.companyPlan == companyPlan.Premium">  <i class="uil uil-qrcode-scan "></i>
                                          <ng-container  *ngIf="!vehicle.qrCodeNumber">Assign</ng-container>
                                          <ng-container  *ngIf="vehicle.qrCodeNumber">Re-Assign</ng-container> a QR Code
                                        </button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Create_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip"><i class="uil  uil-credit-card"></i>Create card subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.View_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.card"><i class="uil uil-credit-card"></i> Edit/View card subscription <i class="uil uil-external-link-alt uil-size"></i> </button>
                                        <!-- <button mat-menu-item (click)="selectAction(actionsCar.View_Cash_Subscription,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.cash"><i class="uil uil-dollar-sign-alt"></i> Edit/View cash subscription <i class="uil uil-external-link-alt uil-size"></i> </button> -->
                                        <button mat-menu-item (click)="selectAction(actionsCar.Create_Cash_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip"><i class="uil uil-dollar-sign"></i>Create cash subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Force_Plan_Change,vehicle)" *ngIf="vehicle.countPackageBase && vehicle.countPackage"><i class="uil uil-sync-slash uil-small"></i> Force plan change</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Delete_Vehicle_Profile,vehicle)" *ngIf="!vehicle.isMemberShip && (!vehicle.stripeObj || !vehicle.stripeObj.subscriptionId)"> <i class="uil uil-trash-alt"></i>Delete vehicle profile</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Disabled_Payment_Type,vehicle)" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd && vehicle.paymentType == paymentTypeEnum.card"><i class="uil uil-dollar-sign"></i>Create cash subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Disabled_Payment_Type,vehicle)" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd && vehicle.paymentType == paymentTypeEnum.cash"><i class="uil  uil-credit-card"></i>Create card subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Customer_Route,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.card && (customerObj.roleStatus == 'active'|| role == 'admin') "><i class="uil uil-user"></i>Vehicle Owner</button>
                                    </mat-menu>
                                </div>
                                <div class="history-service">
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="storeHistories(vehicle);historiesModal.show()"><i class="uil uil-history"></i> Log History</button>
                                    </div>
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="getVehicleStaticsYear(vehicle.id)"><i class="uil uil-history"></i>  Service History</button>
                                    </div>
                                </div>
                                <!-- <button class="btn btn-sm btn-success-custom" (click)="storeVehicle(vehicle,vehicle.paymentType)">Edit</button> -->
                                <!-- <div class="button-group"> -->
                                <!-- <button class="btn btn-sm btn-custom" *ngIf="(!vehicle.countPackageBase && !vehicle.countPackage ||  vehicle.countPackageBase && vehicle.countPackage > 0) && vehicle.isMemberShip && carWashObj.roleStatus == 'active'   || (!vehicle.countPackageBase && !vehicle.countPackage ||  vehicle.countPackageBase && vehicle.countPackage > 0) && vehicle.isMemberShip && user.role == 'admin' "
                                    (click)="createPortalCustomerStripeCustom(vehicle)">
                                    View Subscription
                                 </button> -->
                                <!-- <button class="btn btn-sm btn-success" *ngIf="!vehicle.isMemberShip && carWashObj.roleStatus == 'active' || !vehicle.isMemberShip && user.role == 'admin'" (click)="createMemberShip(vehicle.id)">
                                  Create Subscription
                               </button> -->
                                <!-- <button class="btn btn-sm btn-primary" *ngIf="!vehicle.cancelAt && vehicle.isMemberShip && vehicle.countPackageBase && vehicle.countPackage == 0 && carWashObj.roleStatus == 'active' || !vehicle.cancelAt  && vehicle.isMemberShip && vehicle.countPackageBase && vehicle.countPackage == 0 && user.role == 'admin' " (click)="renewMembership(vehicle)"> Renew Subscription</button> -->
                                <!-- <button class="btn btn-sm btn-primary" (click)="getVehicleStaticsYear(vehicle.id)">
                                   Service Statistics
                                 </button> -->
                                <!-- <button class="btn btn-sm btn-danger-custom" *ngIf="!vehicle.isMemberShip && (!vehicle.stripeObj || !vehicle.stripeObj.subscriptionId)" (click)="storeBuffVehicle(vehicle);deleteVehicleModal.show()">Delete Vehicle</button> -->
                                <!-- <button class="btn btn-sm btn-danger-custom" *ngIf="vehicle.countPackageBase && vehicle.countPackage" (click)="storeBuffVehicle(vehicle);reNewMembershipModal.show()">Force Plan change</button> -->
                                <!-- </div> -->
                                <!-- <div class="create-membership" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip">
                                    <div class="buttons-membership">
                                        <button class="btn btn-sm btn-light-custom" (click)="storeVehicle(vehicle,paymentTypeEnum.card)"><i class="far fa-plus-square "></i> Card Subscription</button>
                                        <button class="btn btn-sm btn-light-custom" (click)="storeVehicle(vehicle,paymentTypeEnum.cash)"><i class="far fa-plus-square "></i> Cash Subscription</button>
                                    </div>
                                </div> -->
                                <!-- <div class="create-membership" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd">
                                    <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.card">
                                        <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Card Subscription  <i class="uil uil-external-link-alt "></i></button>
                                        <button class="btn btn-sm btn-sm btn-light-custom" (click)="changeDenied(false);deniedModal.show()"><i class="far fa-plus-square "></i> Cash Subscription</button>
                                    </div>
                                    <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.cash">
                                        <button class="btn btn-sm btn-light-custom" (click)="changeDenied(true);deniedModal.show()"><i class="far fa-plus-square "></i> Card Subscription</button>
                                        <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Cash Subscription  <i class="uil uil-external-link-alt  "></i></button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="table-footer mt-3" *ngIf="vehicles.length > 0">
                    <pagination-controls previousLabel="" nextLabel="" id="vehicle_id" (pageChange)="handlingAllMemberPagination($event) "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="vehicles.length <= 0">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Queue({{vehiclesQueue.length}})" *ngIf="user.companyPlan >= companyPlan.Premium">
            <div class="templates">
                <div class="row search-qr-scan">
                    <div class="col-lg-12">
                        <div class="form-group" *ngIf="vehiclesQueue.length > 0">
                            <input type="text" id="search-text4" class="form-control" aria-describedby="search-text4" (keydown)="restPaginationQueue($event)" [(ngModel)]="searchText4" placeholder="Search Plate / Make / #VIN (Last 4 digits)" />
                        </div>
                    </div>
                    <!-- <div class="col-12 " (click)="cameraScanModal.show();openCamera()" *ngIf="vehicles.length > 0 && isMobile">
                        <div class="btn btn-sm btn-primary">
                            <p> <i class="uil uil-camera"></i> Capture Plate </p>
                        </div>
                    </div>
                    <div class="col-12 " (click)="searchQR()" *ngIf="vehicles.length > 0 && isMobile">
                        <div class="btn btn-sm btn-primary">
                            <p> <i class="uil uil-qrcode-scan "></i> Scan QR Code</p>
                        </div>
                    </div> -->
                </div>
                <div class="header-member" *ngIf="vehiclesQueue.length > 0">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <div class="row ">
                                    <div class="col-4">
                                        <span class="tittle ">
                                    <span>Plate / Make</span>
                                        </span>
                                    </div>
                                    <!-- <div class="col-4">
                                        <span class="tittle">
                                    <span>Make</span>
                                        </span>
                                    </div> -->
                                    <div class="col-4">
                                        <span class="tittle">
                                          <span >Model  </span>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                          <span>Turn</span>
                                        </span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <mat-accordion *ngFor="let vehicle of vehiclesQueue | vehicleFilter: searchText4 | paginate: {id: 'queue_id' , itemsPerPage: itemsPerPage, currentPage: p5 }; let i=index; let first = first ">
                    <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                        <mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-4">
                                    <span class="tittle plate-make ">
                                        <span class="queue-plate-make">{{vehicle.plateNumber}} / {{vehicle.vehicleType}}</span>
                                    </span>
                                </div>
                                <!-- <div class="col-4">
                                    <span class="tittle plate-make text-capitalize">
                                <span>{{vehicle.vehicleType}}</span>
                                    </span>
                                </div> -->
                                <div class="col-4">
                                    <span class="tittle">
                                        {{vehicle.vehicleModel}}
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="tittle plate-make turn">
                                      <!-- <span *ngIf="first">OK</span> -->
                                    <span *ngIf="(((p5-1)*itemsPerPage)+i) != 0">{{((p5-1)*itemsPerPage)+i+1}}</span>
                                    <span *ngIf="(((p5-1)*itemsPerPage)+i) == 0">OK</span>
                                    </span>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="body">
                            <div class="header-vehicle">
                                <div class="added-by-date">
                                    <div class="icons-vehicle">
                                        <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                      <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                      </small>
                                        <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                    </div>
                                    <div *ngIf="!vehicle.isSubscriptionEnd">
                                        <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt)
                                        | date:'M/d/yyyy'}} </span>
                                        <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                        </span>
                                        <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                        <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                                       <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                                        <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, y'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate
                                        | date: 'MMM d, y'}}
                                        </span>
                                        <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                        </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="section-checks">
                                <div class="button-group" *ngIf="carWashObj.roleStatus == 'active'  || isCheckInVehicle(vehicle.id) == false && user.role == 'admin' ">
                                    <!-- <div class="label-check" >
                                        <span><small class="required"> <i class="uil uil-clock-three uil-extra-small"></i> Checked out,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                    </div> -->
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" (click)="unCheckinModal.show();storeBuffVehicle(vehicle)">Uncheck in </button>
                                        <button class="btn btn-sm btn-danger-custom" (click)="checkOut(vehicle)">Check out</button>
                                    </div>
                                </div>
                                <!-- <div class="button-group" *ngIf="isCheckInVehicle(vehicle.id) == true  && carWashObj.roleStatus == 'active'  || isCheckInVehicle(vehicle.id) == true && user.role == 'admin' ">
                                    <div class="label-check" *ngIf="vehicle.lastCheckDate">
                                        <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Checked in,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" [disabled]="true">Checked In </button>
                                        <button class="btn btn-sm btn-danger-custom" (click)="checkOut(vehicle)">Check Out </button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="info-vehicle">
                                <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                    <p>Model</p>
                                    <span>{{vehicle.vehicleModel}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.phone">
                                    <p>Phone</p>
                                    <span>{{vehicle.phone}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.condition">
                                    <p>Condition</p>
                                    <span>{{vehicle.condition}}</span>
                                </div>
                                <div class="services-block" *ngIf="vehicle.CheckInObject[0]?.servicesObj">
                                    <h6 class="tittle-service">Services</h6>
                                    <div class="assigned-users" *ngIf="vehicle.CheckInObject[0].servicesObj?.usersSelected.length > 0">
                                        <p *ngFor="let userObj of vehicle.CheckInObject[0].servicesObj?.usersSelected">
                                            <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                        </p>
                                    </div>
                                    <div class="others-normal-services">
                                        <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesNormalSelected.length > 0">
                                            <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesNormalSelected">
                                                <strong>{{service.serviceName}}</strong>
                                                <span>({{getCurrency()}}) {{service.price}}</span>
                                            </p>
                                        </div>
                                        <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesOthersSelected.length > 0">
                                            <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesOthersSelected">
                                                <strong>{{service.serviceName}}</strong>
                                                <span>({{getCurrency()}}) {{service.price}}</span>
                                            </p>
                                        </div>
                                        <hr>
                                        <div class="summary-services">
                                            <div>
                                                <p>
                                                    <strong>Total Due</strong>
                                                    <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.totalDu + vehicle.CheckInObject[0].servicesObj.totalDueOthers}}</span>
                                                </p>
                                                <p>
                                                    <strong>Recived </strong>
                                                    <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.receivedAmount}}</span>
                                                </p>
                                                <p class="required">
                                                    <strong>Cash Back</strong>
                                                    <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.cashBack}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer-vehicle fo-vehicle" *ngIf="carWashObj.roleStatus == 'active'|| user.role == 'admin' ">
                                <div>
                                    <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Notify Customer </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready_to_Served,vehicle)"> Ready to serve vehicle</button>
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready,vehicle)">Vehicle is ready</button>
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Out_Of_Queue,vehicle)">Vehicle is removed from Queue  </button>
                                    </mat-menu>
                                </div>
                                <div class="history-service">
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="storeQueueHistories(vehicle);historiesQueueModal.show()"><i class="uil uil-history"></i> Log History</button>
                                    </div>
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="unCheckinModal.show();storeBuffVehicle(vehicle)">Unqueue</button>
                                    </div>
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="getServices(vehicle)">Add/Edit Service</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="table-footer mt-3" *ngIf="vehiclesQueue.length > 0">
                    <pagination-controls previousLabel="" nextLabel="" id="queue_id" (pageChange)="handlingQueuePagination($event) "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="vehiclesQueue.length <= 0">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Today">
            <!-- Members Vehicles checked in today -->
            <div class="members-vehicles">
                <mat-accordion>
                    <mat-expansion-panel (opened)="getOthersAndMembersVehicles()">
                        <mat-expansion-panel-header>Members </mat-expansion-panel-header>
                        <div class="templates">
                            <div class="row">
                                <div class="col-lg-9 col-sm-12">
                                    <div class="form-group " *ngIf="membersVehicles.length > 0">
                                        <input type="text" id="search-text1" class="form-control" (keydown)="restPagination1($event)" [(ngModel)]="searchText1" placeholder="Search Plate Number / #VIN (Last 4 digits)" />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-12" *ngIf="membersVehicles.length > 0">
                                    <div class="print-today btn-sm btn-primary" (click)="printFixedToday(true)">
                                        <button>Print</button>
                                    </div>
                                </div>
                            </div>
                            <div class="header-member" *ngIf="membersVehicles.length > 0">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <div class="row ">
                                                <div class="col-4">
                                                    <span class="tittle ">
                                                <span>Plate / #VIN </span>
                                                    </span>
                                                </div>
                                                <div class="col-4">
                                                    <span class="tittle">
                                                <span>Make</span>
                                                    </span>
                                                </div>
                                                <div class="col-4">
                                                    <span class="tittle">
                                                <span >M. Status</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </mat-expansion-panel-header>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <mat-accordion *ngFor="let vehicle of membersVehicles  | vehicleFilter: searchText1 | paginate: {id: 'membersVehicle_id' , itemsPerPage: itemsPerPage, currentPage: p1 }; let i=index ">
                                <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                                    <mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-4">
                                                <span class="tittle plate-make">
                                              <span>{{vehicle.plateNumber}}</span>
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <span class="tittle plate-make text-capitalize">
                                              <span>{{vehicle.vehicleType}}</span>
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <span class="tittle">
                                                  <span *ngIf="vehicle.planNameInCheck"> {{vehicle.planNameInCheck}}</span>
                                                <span *ngIf="!vehicle.planNameInCheck"><i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                                </span>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="body">
                                        <div class="header-vehicle">
                                            <div class="added-by-date header-check">
                                                <p><i class="uil uil-user-location"></i>{{vehicle?.companyName}}, <strong class="pl-1 pr-1"> By: </strong> {{vehicle?.addedByInCheck}}</p>
                                                <!-- <details>
                                                    <summary><strong>Branch Name:</strong> {{vehicle?.companyName}}</summary>
                                                    <p class="mt-1"> <strong>Added By : </strong> {{vehicle?.addedByInCheck}} </p>
                                                    <p class="mt-1"> <strong>Check in  : </strong> {{vehicle.dateCheckIn | date : 'MMM d, y'}}, {{vehicle.timeCheckIn}} </p>
                                                </details> -->
                                                <div class="date-icon">
                                                    <span *ngIf="vehicle.dateCheckIn && vehicle.timeCheckIn ">{{vehicle.dateCheckIn | date : 'MMM d,  y'}}, {{vehicle.timeCheckIn}}</span>
                                                    <div class="icons-vehicle">
                                                        <!-- <small [ngClass]="[vehicle.id != '' ? 'icon-active' : 'icon-not-active']"><i class="fas fa-car"></i></small> -->
                                                        <!-- <small [ngClass]="[vehicle.customer != null ? 'icon-active' : 'icon-not-active']"><i class="fas fa-user-check"></i></small> -->
                                                        <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                                        <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                                        </small>
                                                        <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage  ">
                                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                            </div>
                                            <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage != 0 ">
                                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                            </div> -->
                                        </div>
                                        <div class="info-vehicle">
                                            <!-- <div class="item">
                                                <label>Plate / #VIN( Last 5 Digits)</label>
                                                <h6>{{vehicle.plateNumber}}</h6>
                                            </div>
                                            <div class="item">
                                                <label>Make</label>
                                                <h6>{{vehicle.vehicleType}}</h6>
                                            </div> -->
                                            <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                                <p>Model</p>
                                                <span>{{vehicle.vehicleModel}}</span>
                                            </div>
                                            <div class="item-vehicle" *ngIf="vehicle?.phone">
                                                <p>Phone</p>
                                                <span>{{vehicle.phone}}</span>
                                            </div>
                                            <div class="item-vehicle" *ngIf="vehicle?.condition">
                                                <p>Condition</p>
                                                <span>{{vehicle.condition}}</span>
                                            </div>
                                            <div class="services-block" *ngIf="vehicle.CheckInObject[0]?.servicesObj">
                                                <h6 class="tittle-service">Services</h6>
                                                <div class="assigned-users" *ngIf="vehicle.CheckInObject[0].servicesObj?.usersSelected.length > 0">
                                                    <p *ngFor="let userObj of vehicle.CheckInObject[0].servicesObj?.usersSelected">
                                                        <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                                    </p>
                                                </div>
                                                <div class="others-normal-services">
                                                    <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesNormalSelected.length > 0">
                                                        <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesNormalSelected">
                                                            <strong>{{service.serviceName}}</strong>
                                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                                        </p>
                                                    </div>
                                                    <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesOthersSelected.length > 0">
                                                        <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesOthersSelected">
                                                            <strong>{{service.serviceName}}</strong>
                                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                                        </p>
                                                    </div>
                                                    <hr>
                                                    <div class="summary-services">
                                                        <div>
                                                            <p>
                                                                <strong>Total Due</strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.totalDu + vehicle.CheckInObject[0].servicesObj.totalDueOthers}}</span>
                                                            </p>
                                                            <p>
                                                                <strong>Recived </strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.receivedAmount}}</span>
                                                            </p>
                                                            <p class="required">
                                                                <strong>Cash Back</strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.cashBack}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="footer-vehicle today-service" *ngIf="carWashObj.roleStatus == 'active' && vehicle.CheckInObject[0].servicesObj || user.role == 'admin' && vehicle.CheckInObject[0].servicesObj ">
                                            <button class="btn btn-sm " (click)="getServices(vehicle)">Add/Edit Service</button>
                                            <button class="btn btn-sm ml-2" (click)="storeValuesToPrint(vehicle.CheckInObject[0].servicesObj,vehicle)">Print Receipt</button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <div class="table-footer " *ngIf="membersVehicles.length > 0">
                                <pagination-controls previousLabel="" nextLabel="" id="membersVehicle_id" (pageChange)="handlingMembersPagination($event) "></pagination-controls>
                            </div>
                            <div class="ant-empty-image" *ngIf="membersVehicles.length <= 0">
                                <img src="./assets/img/icons/empty.svg" alt="empty-img">
                                <span>No Data</span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="other-vehicles">
                <!-- Other Vehicles checked in today -->
                <mat-accordion>
                    <mat-expansion-panel (opened)="getOthersAndMembersVehicles()">
                        <mat-expansion-panel-header>Others </mat-expansion-panel-header>
                        <div class="templates">
                            <div class="row">
                                <div class="col-lg-9 col-sm-12">
                                    <div class="form-group " *ngIf="otherVehicles.length > 0">
                                        <input type="text" id="search-text" class="form-control" aria-describedby="search-text2" (keydown)="restPagination2($event)" [(ngModel)]="searchText2" placeholder="Search Plate Number / #VIN (Last 4 digits)" />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-12" *ngIf="otherVehicles.length > 0">
                                    <div class="print-today btn-sm btn-primary" (click)="printFixedToday(false)">
                                        <button>Print</button>
                                    </div>
                                </div>
                            </div>
                            <div class="header-member" *ngIf="otherVehicles.length > 0">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <div class="row ">
                                                <div class="col-4">
                                                    <span class="tittle ">
                                                <span>Plate / #VIN </span>
                                                    </span>
                                                </div>
                                                <div class="col-4">
                                                    <span class="tittle">
                                                <span>Make</span>
                                                    </span>
                                                </div>
                                                <div class="col-4">
                                                    <span class="tittle">
                                                <span >M. Status</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </mat-expansion-panel-header>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <mat-accordion *ngFor="let vehicle of otherVehicles | vehicleFilter: searchText2 | paginate: {id: 'otherVehicles_id' , itemsPerPage: itemsPerPage, currentPage: p2 }; let i=index ">
                                <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                                    <mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-4">
                                                <span class="tittle plate-make">
                                            <span>{{vehicle.plateNumber}}</span>
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <span class="tittle plate-make text-capitalize">
                                            <span>{{vehicle.vehicleType}}</span>
                                                </span>
                                            </div>
                                            <div class="col-4">
                                                <span class="tittle">
                                                <span *ngIf="vehicle.planNameInCheck"> {{vehicle.planNameInCheck}}</span>
                                                <span *ngIf="!vehicle.planNameInCheck"><i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                                </span>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="body">
                                        <div class="header-vehicle">
                                            <div class="added-by-date header-check">
                                                <p><i class="uil uil-user-location"></i> {{vehicle?.companyName}}, <strong class="pl-1 pr-1"> By: </strong> {{vehicle?.addedByInCheck}}</p>
                                                <!-- <details>
                                                    <summary><strong>Branch Name:</strong> {{vehicle?.companyName}}</summary>
                                                    <p class="mt-1"> <strong>Added By : </strong> {{vehicle?.addedByInCheck}} </p>
                                                    <p class="mt-1"> <strong>Check in  : </strong> {{vehicle.dateCheckIn | date : 'MMM d, y'}}, {{vehicle.timeCheckIn}} </p>
                                                </details> -->
                                                <div class="date-icon">
                                                    <span *ngIf="vehicle.dateCheckIn && vehicle.timeCheckIn ">{{vehicle.dateCheckIn | date : 'MMM d,  y'}}, {{vehicle.timeCheckIn}}</span>
                                                    <div class="icons-vehicle">
                                                        <!-- <small [ngClass]="[vehicle.id != '' ? 'icon-active' : 'icon-not-active']"><i class="fas fa-car"></i></small> -->
                                                        <!-- <small [ngClass]="[vehicle.customer != null ? 'icon-active' : 'icon-not-active']"><i class="fas fa-user-check"></i></small> -->
                                                        <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                                        <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                                        </small>
                                                        <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage">
                                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                            </div>
                                            <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage != 0 ">
                                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                            </div> -->
                                        </div>
                                        <div class="info-vehicle">
                                            <!-- <div class="item">
                                                <label>Plate / #VIN( Last 5 Digits)</label>
                                                <h6>{{vehicle.plateNumber}}</h6>
                                            </div>
                                            <div class="item">
                                                <label>Make</label>
                                                <h6>{{vehicle.vehicleType}}</h6>
                                            </div> -->
                                            <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                                <p>Model</p>
                                                <span>{{vehicle.vehicleModel}}</span>
                                            </div>
                                            <div class="item-vehicle" *ngIf="vehicle?.phone">
                                                <p>Phone</p>
                                                <span>{{vehicle.phone}}</span>
                                            </div>
                                            <div class="item-vehicle" *ngIf="vehicle?.condition">
                                                <p>Condition</p>
                                                <span>{{vehicle.condition}}</span>
                                            </div>
                                            <div class="services-block" *ngIf="vehicle.CheckInObject[0]?.servicesObj">
                                                <h6 class="tittle-service">Services</h6>
                                                <div class="assigned-users" *ngIf="vehicle.CheckInObject[0].servicesObj?.usersSelected.length > 0">
                                                    <p *ngFor="let userObj of vehicle.CheckInObject[0].servicesObj?.usersSelected">
                                                        <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                                    </p>
                                                </div>
                                                <div class="others-normal-services">
                                                    <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesNormalSelected.length > 0">
                                                        <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesNormalSelected">
                                                            <strong>{{service.serviceName}}</strong>
                                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                                        </p>
                                                    </div>
                                                    <div *ngIf="vehicle.CheckInObject[0].servicesObj.servicesOthersSelected.length > 0">
                                                        <p *ngFor="let service of vehicle.CheckInObject[0].servicesObj.servicesOthersSelected">
                                                            <strong>{{service.serviceName}}</strong>
                                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                                        </p>
                                                    </div>
                                                    <hr>
                                                    <div class="summary-services">
                                                        <div>
                                                            <p>
                                                                <strong>Total Due</strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.totalDu + vehicle.CheckInObject[0].servicesObj.totalDueOthers}}</span>
                                                            </p>
                                                            <p>
                                                                <strong>Recived </strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.receivedAmount}}</span>
                                                            </p>
                                                            <p class="required">
                                                                <strong>Cash Back</strong>
                                                                <span>({{getCurrency()}}) {{vehicle.CheckInObject[0].servicesObj.cashBack}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="footer-vehicle today-service" *ngIf="carWashObj.roleStatus == 'active' && vehicle.CheckInObject[0].servicesObj || user.role == 'admin' && vehicle.CheckInObject[0].servicesObj ">
                                            <button class="btn btn-sm " (click)="getServices(vehicle)">Add/Edit Service</button>
                                            <button class="btn btn-sm ml-2" (click)="storeValuesToPrint(vehicle.CheckInObject[0].servicesObj,vehicle)">Print Receipt</button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <div class="table-footer " *ngIf="otherVehicles.length > 0">
                                <pagination-controls previousLabel="" nextLabel="" id="otherVehicles_id" (pageChange)="handlingOthersPagination($event) "></pagination-controls>
                            </div>
                            <div class="ant-empty-image" *ngIf="otherVehicles.length <= 0">
                                <img src="./assets/img/icons/empty.svg" alt="empty-img">
                                <span>No Data</span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-tab>
        <mat-tab label="Statistics">
            <div class="templates">
                <!-- Monthly / Yearly -->
                <mat-accordion>
                    <mat-expansion-panel (opened)="getVehiclesStaticsYear()">
                        <mat-expansion-panel-header>
                            <span class="daily-monthly"> Monthly </span>
                        </mat-expansion-panel-header>
                        <div class="body">
                            <div class="paragraph">
                                <p> Monthly check-In Statistics</p>
                            </div>
                            <div class="table-statistic">
                                <!-- Card Filtering -->
                                <mdb-card class="jobs ">
                                    <!--Card content-->
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12 week-picker">
                                            <!-- left arrow read and write -->
                                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize" (click)="previousYear()">
                                                <mat-icon>chevron_left</mat-icon>
                                            </button>
                                            <div class="week-text">
                                                <p><b>{{yearStatistic}} </b></p>
                                            </div>
                                            <!-- right arrow read and write  -->
                                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize " (click)="nextYear()">
                                                <mat-icon>chevron_right</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </mdb-card>
                                <div class="col-lg-12 table-statistic-year">
                                    <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">Month</th>
                                                <th scope="col" *ngFor="let product of  vehiclesStaticsYear?.products">{{product}}</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of vehiclesStaticsYear?.count;let i=index ">
                                                <th scope="row">
                                                    <span>{{i+1}}</span>
                                                    <!-- <span *ngIf="last">Total</span> -->
                                                </th>
                                                <td scope="col" *ngFor="let product of  vehiclesStaticsYear?.products">{{getProductName1(item,product)}}</td>
                                                <!-- <td scope="col">{{item.countDaily}}</td>
                                              <td scope="col">{{item.countMonthly}}</td> -->
                                                <td scope="col">{{item.countMonth}}</td>
                                                <!-- <td class="text-blue" (click)="getCountJobsByTypeInYear(0);select('draft')" [ngClass]="{activeJobs: isActive('draft')}"><a>{{jobsInYear.countDraft}}</a></td> -->
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td scope="col" *ngFor="let product of  vehiclesStaticsYear?.products ; let i =index">
                                                    <span>{{getTotalName1(i)}}</span>
                                                </td>
                                                <td scope="col">{{vehiclesStaticsYear.countAll}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- Hourly / Daily -->
                <mat-accordion>
                    <mat-expansion-panel (opened)="getCountVehicleByDate()">
                        <mat-expansion-panel-header>
                            <span class="daily-monthly"> Daily </span>
                        </mat-expansion-panel-header>
                        <div class="body">
                            <div class="paragraph">
                                <p>Daily check-In Statistics</p>
                            </div>
                            <div class="table-statistic">
                                <!-- Card Filtering -->
                                <mdb-card class="jobs pb-2">
                                    <!--Card content-->
                                    <div class="row">
                                        <div class="col-lg-6 col-sm-12 week-picker">
                                            <!-- left arrow read and write -->
                                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize" (click)="previousweek()">
                                          <mat-icon>chevron_left</mat-icon>
                                        </button>
                                            <div class="week-text">
                                                <p><b> Week {{weekno}} {{date.format('MMMM ')}} {{date.format('YYYY ')}} </b></p>
                                            </div>
                                            <!-- right arrow read and write  -->
                                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize " (click)="nextweek()">
                                              <mat-icon>chevron_right</mat-icon>
                                      </button>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 form-group text-center">
                                            <input type="text" placeholder="Choose Date" class="form-control" bsDatepicker name="dateItemPicker" [(ngModel)]="dateItemPicker" (ngModelChange)="getVehiclesByDate($event)" [bsConfig]="bsConfig">
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-12 ">
                                            <timepicker type="text" placeholder="Choose Time" class="form-control" name="timeItemPicker" [(ngModel)]="timeItemPicker" (ngModelChange)="getVehiclesByDateTime($event)"></timepicker>
                                        </div> -->
                                    </div>
                                    <div class="container">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <th class="day-name" *ngFor="let day of days">{{day.name}}</th>
                                                </tr>
                                                <tr>
                                                    <th *ngFor="let day of days">{{day.value}}</th>
                                                </tr>
                                                <!-- <tr>
                                                    <td class="change-pointer" *ngFor="let day of days;let i = index">
                                                        <span class="count-jobs-calender"> {{i}}</span>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <td class="change-pointer" *ngFor="let item of vehiclesByDates" (click)="getVehiclesByDate(item.date);select(item)" [ngClass]="{active: isActive(item) || item.date == getTodayDate(dateObj)}">
                                                        <span class="count-jobs-calender"> {{item.countJob}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </mdb-card>
                                <div class="templates">
                                    <!-- <div class="row search-qr-scan">
                                        <div class="col-lg-8">
                                            <div class="form-group" *ngIf="vehicles.length > 0">
                                                <input type="text" id="search-text" class="form-control" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search Plate Number / #VIN (Last 4 digits)" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 " (click)="searchQR()" *ngIf="vehicles.length > 0">
                                            <div class="btn btn-sm btn-primary">
                                                <p>Scan QR Code</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="header-member" *ngIf="vehiclesByDate.length > 0">
                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <div class="row ">
                                                        <div class="col-4">
                                                            <span class="tittle ">
                                                              <span>Plate / #VIN </span>
                                                            </span>
                                                        </div>
                                                        <div class="col-4">
                                                            <span class="tittle">
                                                      <span>Make</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-4">
                                                            <span class="tittle">
                                                      <span >M. Status</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </mat-expansion-panel-header>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                    <mat-accordion *ngFor="let vehicle of vehiclesByDate  | paginate: {id: 'vehiclesByDate_Id' , itemsPerPage: itemsPerPage, currentPage: p3 }; let i=index ">
                                        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                                            <mat-expansion-panel-header>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <span class="tittle plate-make">
                                                      <span>{{vehicle.plateNumber}}</span>
                                                        </span>
                                                    </div>
                                                    <div class="col-4">
                                                        <span class="tittle plate-make text-capitalize">
                                                      <span>{{vehicle.vehicleType}}</span>
                                                        </span>
                                                    </div>
                                                    <div class="col-4">
                                                        <span class="tittle">
                                                        <span *ngIf="vehicle.planNameInCheck"> {{vehicle.planNameInCheck}}</span>
                                                        <span *ngIf="!vehicle.planNameInCheck"><i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="body">
                                                <div class="header-vehicle">
                                                    <div class="added-by-date header-check">
                                                        <p><i class="uil uil-user-location"></i> {{vehicle?.companyName}}, <strong class="pl-1 pr-1"> By: </strong> {{vehicle?.addedByInCheck}}</p>
                                                        <!-- <details>
                                                            <summary><strong>Branch Name:</strong> {{vehicle?.companyName}}</summary>
                                                            <p class="mt-1"> <strong>Added By : </strong> {{vehicle?.addedByInCheck}} </p>
                                                            <p class="mt-1"> <strong>Check in  : </strong> {{vehicle.dateCheckIn | date : 'MMM d, y'}}, {{vehicle.timeCheckIn}} </p>
                                                        </details> -->
                                                        <div class="date-icon">
                                                            <span *ngIf="vehicle.dateCheckIn && vehicle.timeCheckIn ">{{vehicle.dateCheckIn | date : 'MMM d,  y'}}, {{vehicle.timeCheckIn}}</span>
                                                            <div class="icons-vehicle">
                                                                <!-- <small [ngClass]="[vehicle.id != '' ? 'icon-active' : 'icon-not-active']"><i class="fas fa-car"></i></small> -->
                                                                <!-- <small [ngClass]="[vehicle.customer != null ? 'icon-active' : 'icon-not-active']"><i class="fas fa-user-check"></i></small> -->
                                                                <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                                                <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                                                <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                                                <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                                                </small>
                                                                <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage ">
                                                        <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                                    </div>
                                                    <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage ">
                                                        <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                                    </div> -->
                                                </div>
                                                <div class="info-vehicle">
                                                    <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                                        <p>Model</p>
                                                        <span>{{vehicle.vehicleModel}}</span>
                                                    </div>
                                                    <div class="item-vehicle" *ngIf="vehicle?.phone">
                                                        <p>Phone</p>
                                                        <span>{{vehicle.phone}}</span>
                                                    </div>
                                                    <div class="item-vehicle" *ngIf="vehicle?.condition">
                                                        <p>Condition</p>
                                                        <span>{{vehicle.condition}}</span>
                                                    </div>
                                                    <div class="services-block" *ngIf="vehicle?.servicesObj">
                                                        <h6 class="tittle-service">Services</h6>
                                                        <div class="assigned-users" *ngIf="vehicle?.servicesObj?.usersSelected.length > 0">
                                                            <p *ngFor="let userObj of vehicle?.servicesObj?.usersSelected">
                                                                <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                                            </p>
                                                        </div>
                                                        <div class="others-normal-services">
                                                            <div *ngIf="vehicle?.servicesObj.servicesNormalSelected.length > 0">
                                                                <p *ngFor="let service of vehicle?.servicesObj.servicesNormalSelected">
                                                                    <strong>{{service.serviceName}}</strong>
                                                                    <span>({{getCurrency()}}) {{service.price}}</span>
                                                                </p>
                                                            </div>
                                                            <div *ngIf="vehicle?.servicesObj.servicesOthersSelected.length > 0">
                                                                <p *ngFor="let service of vehicle?.servicesObj.servicesOthersSelected">
                                                                    <strong>{{service.serviceName}}</strong>
                                                                    <span>({{getCurrency()}}) {{service.price}}</span>
                                                                </p>
                                                            </div>
                                                            <hr>
                                                            <div class="summary-services">
                                                                <div>
                                                                    <p>
                                                                        <strong>Total Due</strong>
                                                                        <span>({{getCurrency()}}) {{vehicle?.servicesObj.totalDu + vehicle?.servicesObj.totalDueOthers}}</span>
                                                                    </p>
                                                                    <p>
                                                                        <strong>Recived </strong>
                                                                        <span>({{getCurrency()}}) {{vehicle?.servicesObj.receivedAmount}}</span>
                                                                    </p>
                                                                    <p class="required">
                                                                        <strong>Cash Back</strong>
                                                                        <span>({{getCurrency()}})  {{vehicle?.servicesObj.cashBack}}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="item">
                                                      <label>Membership</label>
                                                      <h6>Membership</h6>
                                                  </div> -->
                                                </div>
                                                <div class="footer-vehicle today-service" *ngIf="carWashObj.roleStatus == 'active' && vehicle?.servicesObj || user.role == 'admin' && vehicle?.servicesObj ">
                                                    <button class="btn btn-sm" (click)="storeValuesToPrint(vehicle?.servicesObj,vehicle)">Print Receipt</button>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                    <div class="table-footer " *ngIf="vehiclesByDate.length > 0">
                                        <pagination-controls previousLabel="" nextLabel="" id="vehiclesByDate_Id" (pageChange)="handlingStatisticPagination($event) "></pagination-controls>
                                    </div>
                                    <div class="ant-empty-image" *ngIf="vehiclesByDate.length <= 0">
                                        <img src="./assets/img/icons/empty.svg" alt="empty-img">
                                        <span>No Data</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<!-- Edit Vehicle Modal -->
<div mdbModal #editVehicleModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Vehicle</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editVehicleModal.hide()" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body ">
                    <!-- start strpper -->
                    <form [formGroup]="vehicleForm">
                        <mat-horizontal-stepper [linear]="isLinear" #stepper>
                            <mat-step [stepControl]="vehicleForm">
                                <ng-template matStepLabel>Vehicle Details</ng-template>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="vehicleType" placeholder="make" />
                                        <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.vehicleType.errors.required">Vehicle Type is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="plateNumber" placeholder="plate number" />
                                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="vehicleModel" placeholder="vehicle model" />
                                    </div>
                                    <div class="col-md-6">
                                        <!-- <input type="text" class="form-control" formControlName="color" placeholder="color" /> -->
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="condition" placeholder="condition " />
                                    </div>
                                </div>
                                <button type="button" mdbBtn class="btn btn-sm btn-success" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid " (click)="updateVehicleInformation();editVehicleModal.show()">Update</button>
                                <button type="button" mdbBtn class="btn btn-sm btn-primary" matStepperNext *ngIf="vehicle.customer">Next</button>
                            </mat-step>
                            <mat-step [stepControl]="vehicleForm" *ngIf="vehicle.customer">
                                <ng-template matStepLabel>Customer Details</ng-template>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="firstName" placeholder="first name" />
                                        <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="lastName" placeholder="last name " />
                                        <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" mdbBtn class="btn btn-sm btn-primary" matStepperPrevious>Back</button>
                                <button type="submit" mdbBtn class="btn btn-sm btn-success" [disabled]="f.firstName.invalid || f.lastName.invalid " (click)="updateCustomerInformation();editVehicleModal.show()">Update </button>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </form>
                    <!-- end stepper -->
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="editVehicleModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-success" (click)="editVehicleModal.hide() ">Save </button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- Cancel Vehicle Modal -->
<div mdbModal #cancelVehicleModal="mdbModal" class="modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="cancelVehicleModal.hide()" aria-label="Close">
                      <span>&times;</span>
                   </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mb-0">Cancel Vehicle?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelVehicleModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-success" (click)="cancelVehicleModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- search qr   Modal -->
<div mdbModal #searchVehicleModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="backRoute();searchVehicleModal.hide()" aria-label="Close">
                      <span>&times;</span>
                   </button>
                </div>
                <div class="modal-body ">
                    <!-- <app-view-vehicle></app-view-vehicle> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="backRoute();searchVehicleModal.hide()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- service statistic Modal -->
<div mdbModal #serviceStatisticModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Service History</h5>
                    <button type="button" class="close" (click)="serviceStatisticModal.hide()" aria-label="Close">
                    <span>&times;</span>
                 </button>
                </div>
                <div class="modal-body text-center">
                    <div class="row">
                        <div class="col-lg-12 week-picker">
                            <!-- left arrow read and write -->
                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize" (click)="previousYearStatistic()">
                            <mat-icon>chevron_left</mat-icon>
                        </button>
                            <div class="week-text">
                                <p><b>{{yearStatistic1}} </b></p>
                            </div>
                            <!-- right arrow read and write  -->
                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize " (click)="nextYearStatistic()">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                        </div>
                        <div class="col-lg-12">
                            <table class="table table-bordered mt-2">
                                <thead>
                                    <tr>
                                        <th scope="col">Month</th>
                                        <th scope="col" *ngFor="let product of  statisticYearVehicles?.products">{{product}}</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of statisticYearVehicles?.count;let i=index ">
                                        <th scope="row">
                                            <span>{{i+1}}</span>
                                            <!-- <span *ngIf="last">Total</span> -->
                                        </th>
                                        <td scope="col" *ngFor="let product of  statisticYearVehicles?.products">{{getProductName(item,product)}}</td>
                                        <!-- <td scope="col">{{item.countDaily}}</td>
                                        <td scope="col">{{item.countMonthly}}</td> -->
                                        <td scope="col">{{item.countMonth}}</td>
                                        <!-- <td class="text-blue" (click)="getCountJobsByTypeInYear(0);select('draft')" [ngClass]="{activeJobs: isActive('draft')}"><a>{{jobsInYear.countDraft}}</a></td> -->
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td scope="col" *ngFor="let product of  statisticYearVehicles?.products ; let i =index">
                                            <span>{{getTotalName(i)}}</span>
                                        </td>
                                        <td scope="col">{{statisticYearVehicles.countAll}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='isPrint'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #deleteVehicleModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Delete Vehicle</h6>
                    <button type="button" class="close" (click)="deleteVehicleModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body text-center px-3">
                    <strong> Delete Vehicle?</strong>
                </div>
                <div class="modal-footer px-1">
                    <button class="btn btn-sm btn-light" (click)="deleteVehicleModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="deleteVehicle(buffVehicle);deleteVehicleModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #reNewMembershipModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Force update plan</h5>
                    <button type="button" class="close" (click)="reNewMembershipModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center">
                    <span> if you need to make a refund, You must log in to the <strong>business stripe account > Customers > Tap on the customer email> refund the amount.</strong></span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-light" (click)="reNewMembershipModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="renewForce(buffVehicle);reNewMembershipModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #deniedModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">This Action Cannot Be Completed</h6>
                    <button type="button" class="close" (click)="deniedModal.hide()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body ">
                    <!-- <span *ngIf="isDenied">Card Denied</span> -->
                    <span>  This Action Cannot Be Completed Because there is a current subscription. </span><br>
                    <span>    To proceed, You can cancel the existing subscription.</span>
                    <!-- <span *ngIf="!isDenied">Card Denied</span> -->
                    <div class="text-center">
                        <button class="btn btn-sm btn-success-custom" (click)="deniedModal.hide()">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal  history " mdbModal #historiesModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title "> Log History</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="historiesModal.hide()" aria-label="Close ">
             <span>&times;</span>
         </button>
            </div>
            <div class="modal-body ">
                <div *ngIf="histories.length > 0 ">
                    <div *ngFor="let item of histories | paginate: {id: 'history_Id' , itemsPerPage: 3, currentPage: p4 }">
                        <div class="card ">
                            <div>
                                <small>{{item.dateHistory | date: 'MMM d,  y'}}, {{item.timeHistory }}</small>
                                <small *ngIf="item.startDate">( {{item.startDate | date: 'MMM d,  yy'}}</small>
                                <small *ngIf="item.endDate">-{{item.endDate |  date: 'MMM d,  yy'}} )</small>
                                <small *ngIf="item?.planName"> ({{item?.planName}}) </small>
                                <div class="row-history ">
                                    <p><span [ngClass]="[item.key == historyCarWash.Add_Vehicle || item.key == historyCarWash.Update_Vehicle || item.key == historyCarWash.Update_Vehicle_Subscription || item.key == historyCarWash.Check_In_Action  || item.key == historyCarWash.Assign_Qr_Code ? 'section-to' : '',  item.key == historyCarWash.Create_Membership  ? 'blue-item' : '', item.key == historyCarWash.Check_Out_Action   ||   item.key == historyCarWash.Cancel_Vehicle_Subscription || item.key == queueHistory.Out_Of_Queue ||  item.key == historyCarWash.Force_Update_Plan ? 'section-from' : '']">
                                      <span *ngIf="item.key == historyCarWash.Add_Vehicle">Added</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle">Updated</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle_Subscription">Update Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Assign_Qr_Code">Assign Qr Code</span>
                                        <span class="" *ngIf="item.key == queueHistory.Out_Of_Queue">Removed from Queue</span>
                                        <span *ngIf="item.key == historyCarWash.Cancel_Vehicle_Subscription">Cancel Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Create_Membership">Create
                                          <span *ngIf="item.paymentType == paymentTypeEnum.cash">Cash</span>
                                        <span *ngIf="item.paymentType == paymentTypeEnum.card">Card</span> Membership
                                        </span>
                                        <span *ngIf="item.key ==  historyCarWash.Force_Update_Plan">Force Update Plan</span>
                                        </span> in <strong>{{item.branchName}}</strong> branch, by <strong>{{item.addedBy}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 " *ngIf="histories.length> 0 ">
                    <pagination-controls id="history_Id" (pageChange)="p4 = $event "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="histories.length <= 0 ">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #inviteModal="mdbModal" class="modal invite-modal">
    <div class="modal-dialog modal-md modal-newsletter" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Invite a Customer</h6>
                    <button type="button" class="close" (click)="inviteModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body ">
                    <mat-tab-group>
                        <mat-tab label="Enter email">
                            <div class="text-center">
                                <form (ngSubmit)="InviteUser()">
                                    <div class="modal-body text-center">
                                        <p>Add Customer email in order to send the membership sign up link</p>
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="emailCustomer" [(ngModel)]="emailCustomer" placeholder="Add customer email" required [pattern]="emailPattern" #emailCu="ngModel">
                                            <div *ngIf="emailCu.errors && emailCu.touched" class="alert alert-danger">
                                                <div *ngIf="emailCu.errors.required">
                                                    Email required.
                                                </div>
                                                <div *ngIf="emailCu.errors.pattern">
                                                    Email not valid.
                                                </div>
                                            </div>
                                            <input type="submit" [disabled]="emailCu.errors" class="btn btn-primary btn-block" value="Send">
                                        </div>
                                        <!-- <div class="footer-link"><a href="/">No Thanks</a></div> -->
                                    </div>
                                </form>
                            </div>
                        </mat-tab>
                        <mat-tab label="Share link">
                            <div class="share-link">
                                <div *ngIf="isCopied" class="alert alert-success">
                                    <i class="fas fa-check"></i> Copied to Clipboard
                                </div>
                                <p>Share the membership sign-up link</p>
                                <div class="input-group">
                                    <input type="url" class="form-control" readonly value="{{inviteLink}}">
                                    <div class="input-group-append copy-icon" [cdkCopyToClipboard]="inviteLink" (click)="copyLink()">
                                        <span class="input-group-text"> <i class="uil uil-copy"></i>  </span>
                                    </div>
                                    <div class="input-group-append" (click)="shareLink()">
                                        <span class="input-group-text"> <i class="uil uil-share-alt"></i> </span>
                                    </div>
                                </div>
                                <!-- <strong [cdkCopyToClipboard]="inviteLink" (click)="copyLink()">
                                    <i class="uil uil-link"></i>
                                </strong> -->
                            </div>
                        </mat-tab>
                        <mat-tab label="Scan QR Code">
                            <div id="qr_code_print">
                                <div class="item">
                                    <div class="first-side">
                                        <img src="{{companyInfo.companyLogo}}" alt="logo-img"> <br>
                                    </div>
                                    <div class="second-side">
                                        <div class="info">
                                            <p>{{companyInfo.companyName}}</p>
                                            <p *ngIf="companyInfo.companyPhone">{{companyInfo.companyPhone}} </p>
                                            <p class="mb-0" *ngIf="companyInfo.companyWebsite">{{companyInfo.companyWebsite}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="address-section">
                                    <p class="address-small">{{companyInfo.companyAddress.addressOne}} <span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span>, {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}}
                                        {{companyInfo.companyAddress.zip}}
                                    </p>
                                </div>
                                <div class="qr-code">
                                    <h5>Scan QR Code</h5>
                                    <qrcode [qrdata]="inviteLink" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                </div>
                                <div class="steps">
                                    <div class="steps-content">
                                        <p><span class="num">1</span> Scan the QR Code</p>
                                        <p><span class="num">2</span>Sign up as a customer</p>
                                        <p><span class="num">3</span>Buy a membership or a package</p>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-print">
                                <button class="btn btn-sm btn-primary" (click)="printFixed()">Print</button>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #qrPrintModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Template</h5>
                    <button type="button" class="close" (click)="qrPrintModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <div class="row" id="qr_codes_Id_30" *ngIf="quantity == 30">
                        <div class="col-4 " *ngFor="let item of qrCodes ">
                            <div class="full-item">
                                <div class="info ">
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyPhone}}</p>
                                </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyPhone}}</p>
                                    <p>{{companyInfo.companyWebsite}}</p>
                                </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                    <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                    <p>{{companyInfo.companyWebsite}}</p>
                                </div> -->
                                    <div class="info-details">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                </div>
                                <div class="qr-img">
                                    <qrcode [qrdata]="inviteLink" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                    <!-- <img src="{{inviteLink}}" alt="qrcode-img"> -->
                                    <!-- <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="qr_codes_Id_10" *ngIf="quantity == 10">
                        <div class="col-6 " *ngFor="let item of qrCodes ">
                            <div class="full-item">
                                <div class="info ">
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyPhone}}</p>
                                </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyPhone}}</p>
                                    <p>{{companyInfo.companyWebsite}}</p>
                                </div> -->
                                    <!-- <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                    <p>{{companyInfo.companyName}}</p>
                                    <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                    <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                    <p>{{companyInfo.companyWebsite}}</p>
                                </div> -->
                                    <div class="info-details">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                </div>
                                <div class="qr-img">
                                    <qrcode [qrdata]="inviteLink" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                    <!-- <img src="{{inviteLink}}" alt="logo-img"> -->
                                    <!-- <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer ">
                <button type="button " class="btn btn-sm btn-primary " (click)="qrPrintModal.hide();printFixed() ">Print</button>
            </div> -->
            </div>
        </div>
    </div>
</div>
<div id="scanWebModal" mdbModal #scanWebModal="mdbModal" class="modal tag-modal scan-web-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Scan</h5>
                    <button type="button" class="close" (click)="scanWebModal.hide();handle(action, 'stop')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body px-0 text-center">
                    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event)" (error)="onError($event)"></ngx-scanner-qrcode>
                    <span *ngIf="action.isLoading">⌛ Loading...</span> <br>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Strip Key -->
<div mdbModal #stripKeyModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="stripKeyModal.hide()" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Please add strip key first. <br> Menu > Company account> Stripe integration > Step2.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="stripKeyModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  open camera -->
<div mdbModal #cameraScanModal="mdbModal" class="modal camera-scan " [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Capture Plate</h5>
                    <button type="button" class="close" (click)="cameraScanModal.hide();closeCamera()" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center px-0">
                    <div class="text-center">
                        <webcam [height]="500" [width]="500" [captureImageData]="true" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam" [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
                    </div>
                </div>
                <div class="modal-footer ">
                    <div class="upload-buttons">
                        <div class="btn-group">
                            <button class="btn btn-success" *ngIf="showWebcam " (click)="triggerSnapshot()"> <i class="uil uil-capture pr-2"></i><span>Capture</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Send Message -->
<div mdbModal #sendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                        <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                        <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                        <span *ngIf="smsType == smsNotification.Car_Wash_Out_Of_Queue">Vehicle is removed from Queue</span>
                    </strong>
                    <button type="button" class="close" (click)="sendMessageModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Send Message</p>
                </div>
                <div class="modal-footer">
                    <button type="button " class="btn btn-sm btn-light" (click)="sendMessageModal.hide() ">cancel</button>
                    <button type="button " class="btn btn-sm btn-success" (click)="sendMessageModal.hide();sendSMSMessage() ">send</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--   denied Send Message -->
<div mdbModal #notSendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                        <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                        <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                        <span *ngIf="smsType == smsNotification.Car_Wash_Out_Of_Queue">Vehicle is removed from Queue</span>
                    </strong>
                    <button type="button" class="close" (click)="notSendMessageModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Sending this notification is inactive in the Add-Ins Store > Car Wash Manager</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="notSendMessageModal.hide() ">cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- unscheckin and un queue -->
<div mdbModal #unCheckinModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Remove the vehicle from the queue</h6>
                    <button type="button" class="close" (click)="unCheckinModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body text-center">
                    <span> Uncheck-in vehicle and remove the vehicle from the queue? </span><br>
                    <div class="pt-3">
                        <button class="btn btn-sm btn-danger-custom" (click)="unCheckinModal.hide()">Cancel</button>
                        <button class="btn btn-sm btn-success-custom" (click)="unCheckinModal.hide();updateUnCheckInVehicle(buffVehicle)">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal history" mdbModal #historiesQueueModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title "> Log History</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="historiesQueueModal.hide()" aria-label="Close ">
           <span>&times;</span>
       </button>
            </div>
            <div class="modal-body ">
                <div *ngIf="queueHistories.length > 0 ">
                    <div *ngFor="let item of queueHistories | paginate: {id: 'history_Queue_Id' , itemsPerPage: 3, currentPage: p6 }">
                        <div class="card ">
                            <div>
                                <small>{{item.dateHistory | date: 'MMM d,  y'}}, {{item.timeHistory }} (Customer Notified)</small>
                                <small *ngIf="item?.planName"> ({{item?.planName}}) </small>
                                <!-- <small class="pl-2" *ngIf="item.startDate">( <b class="required"></b> {{item.startDate | date: 'yy-M-d'}}</small> -->
                                <!-- <small class="pl-2" *ngIf="item.endDate"> <b class="success"> - </b> {{item.endDate | date: 'yy-M-d'}} ) </small> -->
                                <!-- <small> By: {{item.addedBy}} in {{item.branchName}} branch </small> -->
                                <div class="row-history ">
                                    <p><span [ngClass]="[item.key == queueHistory.CheckIn_Queue || item.key == queueHistory.Ready_To_Serve  || item.key == queueHistory.Vehicle_Is_Ready  ? 'section-to' : '',  item.key == queueHistory.Out_Of_Queue  ? 'section-from' : '']">
                                    <span *ngIf="item.key == queueHistory.CheckIn_Queue">Vehicle in Queue</span>
                                        <span *ngIf="item.key == queueHistory.Out_Of_Queue">Removed from Queue</span>
                                        <span *ngIf="item.key == queueHistory.Ready_To_Serve">Ready to serve</span>
                                        <span *ngIf="item.key == queueHistory.Vehicle_Is_Ready">Vehicle is ready</span>
                                        </span> in <strong>{{item.branchName}}</strong> branch, by <strong>{{item.addedBy}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 " *ngIf="queueHistories.length> 0 ">
                    <pagination-controls id="history_Queue_Id" (pageChange)="p6 = $event "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="queueHistories.length <= 0 ">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" mdbModal #printTodayModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title ">Print</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="printTodayModal.hide()" aria-label="Close ">
                <span>&times;</span>
             </button>
            </div>
            <div class="modal-body ">
                <div id="today_members_id">
                    <h6>{{companyInfo.companyName}}</h6>
                    <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}, </span> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                    <div class="tittle-table">
                        <p>Checked in Today</p>
                        <p>{{dateObj | date: 'MMM d, y'}}</p>
                    </div>
                    <div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Plate</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Served by</th>
                                    <th scope="col">Total Time(HH:MM)</th>
                                    <th scope="col">({{getCurrency()}})</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let member of membersVehicles">
                                    <th scope="row">{{member.plateNumber}}</th>
                                    <td>
                                        <span *ngIf="member.CheckInObject[0].servicesObj">
                                        <span *ngIf="member.CheckInObject[0].servicesObj.servicesNormalSelected.length > 0">
                                          <span class="service-item" *ngFor="let service of member.CheckInObject[0].servicesObj.servicesNormalSelected">({{service.serviceName}}), </span>
                                        </span>
                                        <span *ngIf="member.CheckInObject[0].servicesObj.servicesOthersSelected.length > 0">
                                          <span class="service-item" *ngFor="let service of member.CheckInObject[0].servicesObj.servicesOthersSelected">({{service.serviceName}}), </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="member.CheckInObject[0].servicesObj">
                                        <span *ngIf="member.CheckInObject[0].servicesObj?.usersSelected.length > 0">
                                          <span class="service-item" *ngFor="let userObj of member.CheckInObject[0].servicesObj?.usersSelected">({{userObj.name}}), </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>{{member.checkInTimeCalc}}</td>
                                    <td>
                                        <span *ngIf="member.CheckInObject[0].servicesObj">
                                          {{member.CheckInObject[0].servicesObj.totalDu + member.CheckInObject[0].servicesObj.totalDueOthers}}
                                      </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="today_others_id">
                    <h6>{{companyInfo.companyName}}</h6>
                    <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}, </span> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                    <div class="tittle-table">
                        <p>Checked in Today</p>
                        <p>{{dateObj | date: 'MMM d, y'}}</p>
                    </div>
                    <div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Plate</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Served by</th>
                                    <th scope="col">Total Time(HH:MM)</th>
                                    <th scope="col">({{getCurrency()}})</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let member of otherVehicles">
                                    <th scope="row">{{member.plateNumber}}</th>
                                    <td>
                                        <span *ngIf="member.CheckInObject[0].servicesObj">
                                    <span *ngIf="member.CheckInObject[0].servicesObj.servicesNormalSelected.length > 0">
                                      <span class="service-item" *ngFor="let service of member.CheckInObject[0].servicesObj.servicesNormalSelected">({{service.serviceName}}), </span>
                                        </span>
                                        <span *ngIf="member.CheckInObject[0].servicesObj.servicesOthersSelected.length > 0">
                                      <span class="service-item" *ngFor="let service of member.CheckInObject[0].servicesObj.servicesOthersSelected">({{service.serviceName}}), </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="member.servicesObj">
                                    <span *ngIf="member.CheckInObject[0].servicesObj?.usersSelected.length > 0">
                                      <span class="service-item" *ngFor="let userObj of member.CheckInObject[0].servicesObj?.usersSelected">({{userObj.name}}), </span>
                                        </span>
                                        </span>
                                    </td>
                                    <td>{{member.checkInTimeCalc}}</td>
                                    <td>
                                        <span *ngIf="member.CheckInObject[0].servicesObj">
                                    {{member.CheckInObject[0].servicesObj.totalDu + member.CheckInObject[0].servicesObj.totalDueOthers}}
                                  </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #noPhoneModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                      <span >Can't send message</span>
                  </strong>
                    <button type="button" class="close" (click)="noPhoneModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Add the phone number first</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="noPhoneModal.hide() ">close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Assign  -->
<div mdbModal #assignModal="mdbModal" class="modal assign-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                    <span >Add/Edit Service </span>
                </strong>
                    <button type="button" class="close" (click)="assignModal.hide();resetServices()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body ">
                    <h6>Assign Crew</h6>
                    <form>
                        <div class="form-group muli-select">
                            <label>Crew Name:</label>
                            <mat-form-field appearance="outline">
                                <mat-select placeholder="user name" name="userObj" [compareWith]="equalsUsers" [(ngModel)]="selectedUsers" multiple>
                                    <mat-option *ngFor="let userObj of usersToAssigned" [value]="userObj">{{userObj.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="selectedUsers.length > 0">
                            <ul class="list-group" *ngFor="let user of selectedUsers; let i=index">
                                <li class="list-group-item user-item  list-group-item-action">
                                    <span><i class="uil uil-check-circle success"></i>  {{user.name}}</span>
                                </li>
                            </ul>
                        </div>
                    </form>
                    <h6>Select Services</h6>
                    <div class="servies-normal" *ngFor="let service of carWashServices;let j=index">
                        <mat-accordion *ngIf="(isChecked(service) && service.isAchieve) || !service.isAchieve">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <div class="header-service">
                                        <span>
                                      <input type="checkbox" id="" name="" (change)="handleChange($event,service,null)" [checked]="isChecked(service)"> {{service.serviceName}}
                                      <span *ngIf="checkServiceName(service)">({{checkServiceName(service)}})</span>
                                        </span>
                                        <small *ngIf="isCheckedSizePrice(service)">( {{isCheckedSizePrice(service).size}} | ({{getCurrency()}}) {{isCheckedSizePrice(service).price}} )</small>
                                    </div>
                                </mat-expansion-panel-header>
                                <ul class="list-group">
                                    <li *ngFor="let item of service.sizes; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                        <span>
                                         <input type="radio" id="{{service.serviceName}}" [value]="item['size' + i ]"  [checked]="isCheckedSize(service) == item['size' + i ]"  name="{{service.serviceName}}" (change)="selectedNormalService($event,item,service,i)"  >
                                         {{item['size' + i ]}}
                                       </span>
                                        <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{item['price' + i ]}}</span>
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <h6>Select Other Services</h6>
                    <div class="servies-others">
                        <ul class="list-group" *ngFor="let service of additionalServices; let i=index">
                            <li [ngClass]="{'selected-item': selectedPackageIndex1 == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                <span>
                                 <input type="checkbox" id="service.serviceName"  name="service.serviceName" (change)="selectedOtherService($event,service);selectItemPackage1(i)" value="service.serviceName" [checked]="isCheckedOther(service)">
                                 {{service.serviceName}}
                               </span>
                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{service.price}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="summary">
                        <p><strong>Total due</strong> ({{getCurrency()}}) {{totalDuNormal + totalDueOthers > 0 ? totalDuNormal + totalDueOthers : 0}}</p>
                        <p> <strong>Received</strong>
                            <input type="number" class="form-control w-25" value="{{receivedAmount}}" min="0" (input)="changeReceivedAmount($event.target.value)">
                        </p>
                        <p> <strong>Cash Back</strong> ({{getCurrency()}}) {{getCashBack()}}</p>
                        <span *ngIf="isDisabled" class="alert alert-danger">The received amount must be equal to or greater than the due amount.</span>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-sm btn-danger" (click)="assignModal.hide();resetServices() ">close</button>
                    <button type="button" class="btn btn-sm btn-success" [disabled]="isDisabled" (click)="assignedService();assignModal.hide() ">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Print Receipt  -->
<div mdbModal #printReceiptModal="mdbModal" class="modal assign-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                  <span >Print Receipt </span>
              </strong>
                    <button type="button" class="close" (click)="printReceiptModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body ">
                    <div id="receipt_id">
                        <div class="tittle-invoice">
                            <h1>Invoice</h1>
                            <span><strong>Date of Issue :</strong> {{dateObj | date: 'MMM d, y, h:mm a'}}</span>
                        </div>
                        <div class="item">
                            <div class="first-side">
                                <img src="{{currentCompany.companyLogo}}" alt="logo-img"> <br>
                            </div>
                            <div class="second-side">
                                <div class="info">
                                    <p>{{currentCompany.companyName}}</p>
                                    <p class="address-small">{{currentCompany.companyAddress.addressOne}} <span *ngIf="currentCompany.companyAddress.addressTow != ''">, {{currentCompany.companyAddress.addressTow}}</span>, {{currentCompany.companyAddress.city}}, {{currentCompany.companyAddress.state}}
                                        {{currentCompany.companyAddress.zip}}
                                    </p>
                                    <p *ngIf="user.companyPhone">{{user.companyPhone}} </p>
                                    <p class="mb-0" *ngIf="user.companyWebsite">{{user.companyWebsite}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="services-block">
                            <div class="tittle-service vehicle-info">
                                <h5>Vehicle Information</h5>
                                <!-- <strong>{{servicesObj?.date | date: 'MMM d, y'}}</strong> -->
                            </div>
                            <div class="others-normal-services">
                                <div>
                                    <p>
                                        <strong>Plate Number</strong>
                                        <span>{{bufferVehicle.plateNumber}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <strong>Make</strong>
                                        <span>{{bufferVehicle.vehicleType}}</span>
                                    </p>
                                </div>
                                <div *ngIf="vehicle.vehicleModel">
                                    <p>
                                        <strong>Model</strong>
                                        <span>{{bufferVehicle.vehicleModel}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="services-block">
                            <div class="tittle-service">
                                <h5>Services</h5>
                                <strong>{{servicesObj?.date | date: 'MMM d, y'}}</strong>
                            </div>
                            <div class="others-normal-services">
                                <div *ngIf="servicesObj.servicesNormalSelected?.length > 0">
                                    <p *ngFor="let service of servicesObj.servicesNormalSelected">
                                        <strong>{{service.serviceName}}</strong>
                                        <span>({{getCurrency()}}) {{service.price}}</span>
                                    </p>
                                </div>
                                <div *ngIf="servicesObj.servicesOthersSelected?.length > 0">
                                    <p *ngFor="let service of servicesObj.servicesOthersSelected">
                                        <strong>{{service.serviceName}}</strong>
                                        <span>({{getCurrency()}}) {{service.price}}</span>
                                    </p>
                                </div>
                                <hr>
                                <div class="summary-services">
                                    <div>
                                        <p>
                                            <strong>Total Due</strong>
                                            <span>({{getCurrency()}}) {{servicesObj.totalDu + servicesObj.totalDueOthers}}</span>
                                        </p>
                                        <p>
                                            <strong>Recived </strong>
                                            <span>({{getCurrency()}}) {{servicesObj.receivedAmount}}</span>
                                        </p>
                                        <p class="required">
                                            <strong>Cash Back</strong>
                                            <span>({{getCurrency()}}) {{servicesObj.cashBack}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>