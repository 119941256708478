<div class="text-center">
    <button class="btn btn-sm btn-primary" (click)="goBack()">Back</button>
</div>
<div id="{{item}}" *ngFor="let item of objFromParent3?.jobNUmbers">
    <div class="header">
        <!-- <div class="tittle"> -->
        <h6>Job Number: #{{item}}</h6>
        <!-- </div> -->
    </div>
    <div class="row mb-2 inventories">
        <!-- <div class="col-12">
          <div class="tittle">
              <h6>Item(s) List</h6>
          </div>
      </div> -->
        <div class="col-12" *ngFor="let inventory of objFromParent3?.inventories[item]">
            <div class="first-row-header ">
                <span>N: {{inventory.itemNumber}}</span>
                <span>{{inventory.name}}</span>
                <span>Qty: {{inventory.quantity}}</span>
            </div>
            <div class="tow-row">
                <h6 class="pr-1">Condition: </h6>
                <span *ngIf="inventory.quality">{{inventory.quality}}</span>
                <span *ngIf="!inventory.quality"><br></span>
            </div>
        </div>
    </div>
</div>
