import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-stripe',
  templateUrl: './error-stripe.component.html',
  styleUrls: ['./error-stripe.component.scss']
})
export class ErrorStripeComponent implements OnInit {
  user:any={}
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))

  }
  goBack(){
      if(this.user.role == 'admin' || this.user.role == 'manager'){
        this.router.navigate(['/admin/vehicles-list'])
      }
      else{
      this.router.navigate(['/user/vehicles-list'])
      }
  }
}
