import { PersonService } from 'src/app/core/_services/person/person.service';
import { Socket } from 'ngx-socket-io';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { TicketService } from './../../../../core/_services/ticket/ticket.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TicketPriority, TicketSeverity, TicketStatus } from './TicketEnum/AddTicketEnum';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ModalDirective } from 'angular-bootstrap-md';
import { HistoryTicketType } from 'src/app/layout/pages/super-admin/pages/ticket/HistoryTicket';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
// import { GalleryItem, ImageItem } from 'ng-gallery';
declare var $: any
@Component({
  selector: 'app-submit-ticket',
  templateUrl: './submit-ticket.component.html',
  styleUrls: ['./submit-ticket.component.scss']
})
export class SubmitTicketComponent implements OnInit {
  @ViewChild('addTicketModal') addTicketModalInstance: ModalDirective
  @ViewChild('historiesModal') historiesModal: ModalDirective
  addTicketModel: any = {}
  user: any = {}
  addTicketFormGroup: FormGroup;
  tickets: any[] = []
  editTicketModel: any = {}
  imgViewUrl: string
  ticketCloseText: string = ''
  ticketProcessText: string = ''
  ticketsClose: any[] = []
  ticketsProgress: any[] = []
  p1: number = 1
  p2: number = 1
  imageSrc
  imageSrcEdit
  // multiple  images
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageEdit: any = {}
  editStep: string
  editNote: string
  actualResult: string
  expectedResult: string
  isValidate = false
  ticketImageDeleted: any[] = []
  histories: any[] = []
  medModel: any = []
  person: any = {}
  oldTicket: any = {}
  bufferTicket: any = {}
  typeTicket: number
  settingsTools: any = {}
  supportTickets: any = {}
  constructor(
    private _formBuilder: FormBuilder,
    private _ticketService: TicketService,
    private _personService: PersonService,
    private socket: Socket,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    private _toolsService: ToolsService,
    private router: Router,
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.createAddTicketForm()
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.settingsTools = response['tools'].data.find(d => d.toolType === ToolType.Setting_Tools)
        if (this.user.role == 'admin' || this.user.role == 'manager' || this.user.role == 'teamleader' || this.user.role == 'crew' || this.user.role == '3rdparty') {
          this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
            if(response.success){
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
              this.supportTickets = this._toolsService.getOverridePermission(this.user.role, this.settingsTools, ToolSettingEditItem.Support_Tickets,ToolType.Setting_Tools,permissionList)
              if (this.user.role == 'admin' || this.supportTickets.itemStatus == 'Active' && this.supportTickets.roleStatus == 'active' || this.supportTickets.itemStatus == 'Active' && this.supportTickets.roleStatus == 'readOnly') {
                   this.getTickets()
              }
               }
          })
        }
      }
    })
    this.socket.on(RealTimeKeys.Ticket_Update, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._ticketService.getTicket((data.id)).subscribe((response: ResponseModel) => {
          if (response.data.status < 5) {
            let Index = this.ticketsProgress.findIndex(t => t.id == data.id)
            this.ticketsProgress[Index] = response.data
            // this.toasterService.success('','Successfully Update Ticket')
          } else {
            let Index = this.ticketsClose.findIndex(t => t.id == data.id)
            this.ticketsClose[Index] = response.data
            // this.toasterService.success('','Successfully Update Ticket')
          }
        }, error => {
          console.log(error)
        })
      }
    });
    this.socket.on(RealTimeKeys.Ticket_Update_Status, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._ticketService.getTicket((data.id)).subscribe((response: ResponseModel) => {
          if (response.data.status < 5 && data.isClosed == false) {
            let index = this.ticketsProgress.findIndex(t => t.id == data.id)
            if (index != -1) {
              this.ticketsProgress[index] = response.data
              // this.toasterService.success('','Successfully Update Status Ticket')
            }
            else {
              let j = this.ticketsClose.findIndex(t => t.id == data.id)
              this.ticketsClose.splice(j, 1);
              this.ticketsProgress.unshift(response.data)
              // this.toasterService.success('','Successfully Update Status Ticket')
            }
          } else if (response.data.status == 5 && data.isClosed == true || response.data.status == 6 && data.isClosed == true) {
            let index = this.ticketsProgress.findIndex(t => t.id == data.id)
            if (index != -1) {
              this.ticketsProgress.splice(index, 1);
              this.ticketsClose.unshift(response.data)
              // this.toasterService.success('','Successfully Update Status Ticket')
            }
          }
        }, error => {
          console.log(error)
        })
      }
    });
    this.socket.on(RealTimeKeys.Ticket_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._ticketService.getTicket(data.id).subscribe((response: ResponseModel) => {
          this.ticketsProgress.unshift(response.data)
          // this.toasterService.success('','Successfully Add New Ticket')
        }, error => {
          console.log(error)
        })
      }
    });
    // this.socket.on(RealTimeKeys.Tool_Change, (data: any) => {
    //   console.log('this is tools realtime', data)
    //   if (this.user.companyId == data.companyId) {
    //     this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
    //         this.settingsTools = response.data.find(d => d.toolType === ToolType.Setting_Tools)
    //         this.supportTickets = this._toolsService.getPermission(this.user.role, this.settingsTools, ToolSettingEditItem.Support_Tickets)
    //         console.log('supportTickets', this.supportTickets)
    //         if (this.supportTickets.itemStatus == 'InActive' || this.supportTickets.itemStatus == 'Active' && this.supportTickets.roleStatus == 'notActive') {
    //           if (this.user.role == 'admin' || this.user.role == 'manager') {
    //             this.router.navigate(['/admin'])
    //           }
    //           else {
    //             this.router.navigate(['/user'])
    //           }
    //         }
    //     }, error => {
    //       console.log(error)
    //     })
    //   }
    // });
    this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
      this.person = response.data
      // console.log('person', response.data)
    })
  }
  getTickets() {
    this._ticketService.getTicketsByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
      this.tickets = response.data
      this.ticketsProgress = response.data.filter(ti => ti.status < 5)
      this.ticketsClose = response.data.filter(ti => ti.status == 5 || ti.status == 6)
      // console.log(this.tickets)
    })
  }
  // ******** Initial createAddTicketForm **********
  createAddTicketForm() {
    this.addTicketFormGroup = this._formBuilder.group({
      issueLocation: ['', Validators.required],
      description: ['', Validators.required],
      stepsIssue: ['', Validators.required],
      actualResult: ['', Validators.required],
      expectedResult: ['', Validators.required],
      note: [''],
      ticketImage: ['']
    })
  }
  get f() {
    return this.addTicketFormGroup.controls;
  }
  serachTicketsProgress(event) {
    this.p1 = 1
  }
  serachTicketsClose(event) {
    this.p2 = 1
  }
  AddTicket(form: NgForm) {
    console.log(this.addTicketFormGroup.value)
    this.addTicketModel = Object.assign({}, this.addTicketFormGroup.value)
    this.addTicketModel.status = TicketStatus.Proposed
    this.addTicketModel.priority = TicketPriority.Low
    this.addTicketModel.severity = TicketSeverity.Low
    this.addTicketModel.createdDate = new Date()
    this.addTicketModel.companyNumber = this.user.companyNumber
    this.addTicketModel.companyId = this.user.companyId
    this.addTicketModel.addedBy = this.person.lastName + ',' + this.person.firstName
    this.addTicketModel.emailAdded = this.person.email
    this.addTicketModel.phone = this.person.phone
    this.addTicketModel.history = []
    if (this.addTicketModel.ticketImage == '' || this.addTicketModel.ticketImage == null || this.addTicketModel.ticketImage == undefined) {
      this.addTicketModel.ticketImage = []
    }
    // this.addTicketModel.TimeUpdateStatus = new Date()
    // this.addTicketModel.TimeUpdatePriority = new Date()
    // this.addTicketModel.TimeUpdateSeverity = new Date()
    this.addTicketModel.ticketDateUpdate = new Date()
    // console.log(this.addTicketModel)
    this.addTicketModel.stepsIssue = this.getNewFiledWithBreak('stepsIssue')
    this.addTicketModel.note = this.getNewFiledWithBreak('noteAdd')
    this.addTicketModel.actualResult = this.getNewFiledWithBreak('actualResultAdd')
    this.addTicketModel.expectedResult = this.getNewFiledWithBreak('expectedResultAdd')
    this._ticketService.AddTicket(this.addTicketModel).subscribe((response: ResponseModel) => {
      // console.log(response)
      if (response.success) {
        this.toasterService.success('', 'Support Ticket Successfully Created')
        this.addTicketFormGroup.reset()
        this.addTicketModel.ticketImage = []
      }
      else {
        this.toasterService.error('', 'Please Complete all Required fields')
      }
    }, error => {
      this.toasterService.error('', 'Please Complete all Required fields')
    })
  }
  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.addTicketFormGroup.patchValue({
          ticketImage: reader.result
        });
      };
    }
  }
  editTicket(ticket: any) {
    this.editTicketModel = Object.assign({}, ticket)
    if (this.editTicketModel.note) {
      (document.getElementById('noteEdit') as HTMLTextAreaElement).innerHTML = this.getNewFiledWithEntity(this.editTicketModel.note);
      (document.getElementById('noteEdit') as HTMLTextAreaElement).value = (document.getElementById('noteEdit') as HTMLTextAreaElement).innerHTML
    }
    if (this.editTicketModel.note == '') {
      (document.getElementById('noteEdit') as HTMLTextAreaElement).innerHTML = this.getNewFiledWithEntity(this.editTicketModel.note);
      (document.getElementById('noteEdit') as HTMLTextAreaElement).value = ''
    }
    (document.getElementById('actualResultEdit') as HTMLTextAreaElement).innerHTML = this.getNewFiledWithEntity(this.editTicketModel.actualResult);
    (document.getElementById('actualResultEdit') as HTMLTextAreaElement).value = (document.getElementById('actualResultEdit') as HTMLTextAreaElement).innerHTML;
    (document.getElementById('expectedResultEdit') as HTMLTextAreaElement).innerHTML = this.getNewFiledWithEntity(this.editTicketModel.expectedResult);
    (document.getElementById('expectedResultEdit') as HTMLTextAreaElement).value = (document.getElementById('expectedResultEdit') as HTMLTextAreaElement).innerHTML;
    (document.getElementById('stepsIssueEdit') as HTMLTextAreaElement).innerHTML = this.getNewFiledWithEntity(this.editTicketModel.stepsIssue);
    (document.getElementById('stepsIssueEdit') as HTMLTextAreaElement).value = (document.getElementById('stepsIssueEdit') as HTMLTextAreaElement).innerHTML;

  }
  onFocus(ticket: any) {
    this.oldTicket = ticket
  }
  updateTicket() {
    this.editTicketModel.stepsIssue = this.getNewFiledWithEntityBreak('stepsIssueEdit')
    this.editTicketModel.note = this.getNewFiledWithEntityBreak('noteEdit')
    this.editTicketModel.actualResult = this.getNewFiledWithEntityBreak('actualResultEdit')
    this.editTicketModel.expectedResult = this.getNewFiledWithEntityBreak('expectedResultEdit')
    this.editTicketModel.ticketDateUpdate = new Date()
    this.editTicketModel.ticketImageDeleted = this.ticketImageDeleted
    // console.log(this.editTicketModel.stepsIssue)
    this._ticketService.updateTicket(this.editTicketModel.id, this.editTicketModel).subscribe((response: ResponseModel) => {
      // console.log('ticket aftar update', response.data)
      if (response.success) {
        this.toasterService.success('', 'Successfully Update Ticket')
      }
      else {
        this.toasterService.error('Error Update Ticket', 'Please try again')
      }
    }, error => {
      this.toasterService.error('Error Update Ticket', 'Please try again')
    })
  }
  storeBufferStatus(ticket: any, type: number) {
    // console.log('ticket', ticket)
    this.bufferTicket = ticket
    this.typeTicket = type
  }
  updateStatusTicket(ticket: any, status: number) {
    // console.log('this is ticket', ticket)
    let historyModel: any = {
      addBy: this.person.lastName + ',' + this.person.firstName,
      date: new Date(),
    }
    historyModel.type = HistoryTicketType.Status
    historyModel.ticketFrom = this.oldTicket.status
    if (status == 3) {
      historyModel.ticketTo = TicketStatus.Closed
    }
    else {
      historyModel.ticketTo = TicketStatus.Discard
    }
    ticket.history.unshift(historyModel)
    let ticketModel: any = {
      status: historyModel.ticketTo,
      priority: ticket.priority,
      severity: ticket.severity,
      history: ticket.history
    }
    // console.log(ticketModel)
    this._ticketService.updateTicketStatus(ticket.id, ticketModel).subscribe((response: ResponseModel) => {
      // console.log('ticket aftar update', response.data)
      if (response.success) {
        if (response.data.status == 5) {
          this.toasterService.success('', 'Support Ticket Successfully Closed')
        }
        if (response.data.status == 6) {
          this.toasterService.success('', 'Support Ticket Successfully Disregarded')
        }
      }
      else {
        this.toasterService.error('Error Update Ticket', 'Please try again')
      }
    }, error => {
      this.toasterService.error('Error Update Ticket', 'Please try again')
    })
  }
  getNewFiledWithBreak(id) {
    let TextArea = (document.getElementById(id) as HTMLInputElement)
    let model: string = TextArea.value
    model = model.replace(/(\r\n|\n|\r)/gm, "<br>");
    return model
  }
  getNewFiledWithEntity(item: string) {
    return item.replace(/<br\s*\/?>/mg, "&#013;")
  }
  getNewFiledWithEntityBreak(id) {
    let model: string = (document.getElementById(id) as HTMLTextAreaElement).value
    model.replace("&#013", "<br>")
    return model.replace(/\n/g, "<br>")
  }
  viewImage(item: any) {
    this.imgViewUrl = item.src
  }
  storeImageEdit(item: any) {
    this.imageEdit = item
  }
  onFileChangeEdit(event) {
    let index = this.editTicketModel.ticketImage.findIndex(e => e.id == this.imageEdit.id)
    // console.log('this is image validation', index)
    const reader = new FileReader();
    if (event.target.files && event.target.files.length && event.target.files[0].size <= 2000000) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrcEdit = reader.result as string;
        this.editTicketModel.ticketImage[index].src = reader.result
        this.editTicketModel.ticketImage[index].isUpdated = true
        // console.log(this.editTicketModel)
      };
    }
    else if (event.target.files[0].size > 2000000) {
      this.toasterService.error('Error', 'Please upload less than 2MB image');
    }
    else {
      this.editTicketModel.ticketImage = this.editTicketModel.ticketImage
    }
  }
  // upload  image
  selectFiles(event: any) {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.previews = [];
    let medModel: any = []
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // console.log(e.target.result);
          this.previews.push(e.target.result);
          medModel.push({
            id: i + 1,
            src: e.target.result,
            isUpdated: false
          })
          // this.images = [
          //   new ImageItem({ src: e.target.result , thumb: e.target.result })
          // ];
          this.addTicketFormGroup.patchValue({
            ticketImage: medModel
          });
          this.addTicketModel.ticketImage = medModel
          // console.log(this.addTicketModel.ticketImage)
          // console.log('this is preview', this.previews)
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
  selectMoreFiles(event: any) {
    // console.log(this.editTicketModel.ticketImage)
    if (this.editTicketModel.ticketImage == '') {
      this.editTicketModel.ticketImage = []
    }
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.imageSrc = reader.result as string;
        this.editTicketModel.ticketImage.unshift(
          {
            id: this.editTicketModel.ticketImage.length + (new Date().getMilliseconds()),
            src: reader.result,
            isUpdated: true
          }
        )
        // console.log(this.editTicketModel.ticketImage)
      };
    }
  }
  removeImage(id) {
    let index = this.addTicketModel.ticketImage.findIndex(a => a.id == id)
    this.addTicketModel.ticketImage.splice(index, 1)
  }
  checkValidate() {
    let stepsEdit = (document.getElementById('stepsIssueEdit') as HTMLTextAreaElement).value
    let expectedResultEdit = (document.getElementById('expectedResultEdit') as HTMLTextAreaElement).value
    let actualResultEdit = (document.getElementById('actualResultEdit') as HTMLTextAreaElement).value
    if (stepsEdit == '' || expectedResultEdit == '' || actualResultEdit == '') {
      this.isValidate = true
      // console.log('is validate', this.isValidate)
    }
    else {
      this.isValidate = false
      // console.log('is validate', this.isValidate)
    }
  }
  removeImageTicket(item: any) {
    // console.log(item)
    let index = this.editTicketModel.ticketImage.findIndex(a => a.id == item.id)
    this.ticketImageDeleted.push(this.editTicketModel.ticketImage[index])
    this.editTicketModel.ticketImage.splice(index, 1)
    this.editTicketModel.ticketImageDeleted = this.ticketImageDeleted
    // console.log(this.editTicketModel.ticketImage)
    // console.log(this.ticketImageDeleted)
  }
  // getHistory()
  getProgressHistory(id: string) {
    let index = this.ticketsProgress.findIndex(ti => ti.id === id)
    this.histories = this.ticketsProgress[index].history
    this.historiesModal.show()
  }
  getClosedHistory(id: string) {
    let index = this.ticketsClose.findIndex(ti => ti.id === id)
    this.histories = this.ticketsClose[index].history
    this.historiesModal.show()
  }
}
