import { Role } from './../../../../../models/role/RoleModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/_services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { CompanyStatus } from '../../../super-admin/pages/companies/super-enum/company-enum';
import { ModalDirective } from 'angular-bootstrap-md';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
import { CategoryCompany } from 'src/app/modules/company-admin/pages/permissions/Enum-Category/CategoryCompany';
import { environment } from 'src/environments/environment.prod';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
  companies: any[] = []
  companyName = "Comapny Name"
  ownerName = "Owner Name"
  userId: any
  user: any = {}
  role:any
  settingsTools: any[]
  customersList: any = {}
  usersList: any = {}
  // trucksList: any = {}
  myProfile: any = {}
  logoUrl = ''
  currentCompany: any = {}
  supportTickets: any = {}
  knowledgeBase: any = {}
  carWashVehicle: any = {}
  carwashWebsiteObj:any = {}
  // whorehousePermission: any = {}
  qrCode: any = {}
  // qrCodeMoving: any = {}
  qrCodeStickers:any = {}
  timer:any
  bufferData:any
  companyStatus:number
  companyStatusEnum = CompanyStatus
  planCompanyType: any = PlanCompanyType
  categoryCompany = CategoryCompany
  isOnline = environment.isOnline
  logo_online = environment.logo_online
  logo_test = environment.logo_test
  carWashServices: any = {}
  @ViewChild('warningModal') warningModal: ModalDirective;
  @ViewChild('userDisableModal') userDisableModal: ModalDirective;
  // @ViewChild('branchMobileModal') branchMobileModal: ModalDirective;
  constructor(
    private _authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _toolsService: ToolsService,
    private socket:Socket,
    public _companiesService: CompaniesService,
    public _personService: PersonService,
    public _globalService: GlobalService,
  ) { }

  ngOnInit() {
    // this.getCompanies()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        let roleName = this.user.role
        this.settingsTools = response['tools'].data.find(d => d.toolType === ToolType.Setting_Tools)
        this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
          if(response.success){
            let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
            this.customersList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Customers_list,ToolType.Setting_Tools,permissionList)
            this.usersList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Users_list,ToolType.Setting_Tools,permissionList)
            // this.trucksList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Trucks_list,ToolType.Setting_Tools,permissionList)
            this.supportTickets = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Support_Tickets,ToolType.Setting_Tools,permissionList)
            this.myProfile = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.My_Profile,ToolType.Setting_Tools,permissionList)
            this.knowledgeBase = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.knowledge_Base,ToolType.Setting_Tools,permissionList)
            this.carWashVehicle = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
            this.qrCode = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Qr_Code, ToolType.Setting_Tools, permissionList)
            // this.qrCodeMoving= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Qr_Code, ToolType.Setting_Tools, permissionList)
            this.qrCodeStickers= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Qr_Code_Stickers, ToolType.Setting_Tools, permissionList)
            this.carWashServices= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Services, ToolType.Setting_Tools, permissionList)
            this.carwashWebsiteObj= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Was_Website, ToolType.Setting_Tools, permissionList)
            // this.whorehousePermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Warehouse_Map, ToolType.Setting_Tools, permissionList)
        //  console.log('knowledgeBase',this.knowledgeBase)
        //  console.log('customersList',this.customersList)

        }
        })
      }
    })
    this.companies = this.user.companyData
    this.userId = localStorage.getItem('userId')
    this.ownerName =  this.user.displayName
    if (!localStorage.getItem('isUser')) {
      localStorage.setItem('isUser', 'false')
    }
    if (!localStorage.getItem('isCustomer')) {
      localStorage.setItem('isCustomer', 'false')
    }
    // this.ownerName = localStorage.getItem('displayName')
    // let comapnyId = localStorage.getItem("companyId")
    this._companiesService.getCompanyFullSummaryWithRole(this.user.companyId,this.user.id).subscribe((response:ResponseModel)=>{
      if(response.success){
        // console.log('getCompanyFullSummaryWithRole',response.data)
        if(response.data.companyStatus == CompanyStatus.NotPayPeriod){
          this._globalService.getComapnyInfo(response.data,CompanyStatus.NotPayPeriod)
        }
           if(response.data.companyStatus == CompanyStatus.Canceled){
            this._globalService.getComapnyInfo(response.data,CompanyStatus.Canceled)
        }
        if(response.data.companyStatus == CompanyStatus.Deactivated){
          this._globalService.getComapnyInfo(response.data,CompanyStatus.Deactivated)
        }
        if(response.data.companyStatus == CompanyStatus.Active){
        let index = this.user.companyData.findIndex(c => c.companyId == this.user.companyId)
        this.user.companyData[index].companyLogo = response.data.companyLogo
        this.user.companyData[index].companyName = response.data.companyName
        this.user.companyData[index].companyAddress = response.data.companyAddress
        this.user.companyPlan = response.data.companyPlan
        this.user.companyPlanType = response.data.companyPlanType
        this.user.companyStatus = response.data.companyStatus
        this.user.displayName = response.data.displayName
        this.user.role = response.data.role
        localStorage.setItem('currency',response.data.currency)
        this.user.companyData[index].role = response.data.role
        localStorage.setItem('user', JSON.stringify(this.user))
        this.user = JSON.parse(localStorage.getItem('user'))
        this.role = this.user.role
         this.getCompanies()
        }
      }
    })
    this.companyName = this.companies.find(item => item.companyId === this.user.companyId).companyName
    this.socket.on(RealTimeKeys.Company_Update, (data: any) => {
      if(this.user.companyId == data.id){
        this._companiesService.getCompanyById(data.id).subscribe((response: ResponseModel) => {
          if(response.success){
            this.logoUrl = response.data.companyLogo
            this.companyName = response.data.companyName
            // this.ownerName = response.data.ownerName
            this.user.companyName = response.data.companyName
            // this.user.displayName = response.data.ownerName
            this.user.companyLogo = response.data.companyLogo
            let index = this.user.companyData.findIndex(c => c.companyId == response.data.id)
            this.user.companyData[index].companyLogo = response.data.companyLogo
            this.user.companyData[index].companyName = response.data.companyName
            this.user.companyData[index].companyAddress = response.data.companyAddress
            this.user.companyPlanType = response.data.companyPlanType
            this.user.companyStatus = response.data.companyStatus
            localStorage.setItem('user', JSON.stringify(this.user))
            this.user = JSON.parse(localStorage.getItem('user'))
             this.companies = this.user.companyData.filter(c => c.isHideInSwitch == false)
             this.getCompanies()
          }
        })
      }
    });
    this.socket.on(RealTimeKeys.Company_Update_Logo, (data: any) => {
      this._companiesService.getCompanyById(data.id).subscribe((response: ResponseModel) => {
        this.logoUrl = response.data.companyLogo
        this.user.companyLogo = response.data.companyLogo
        let index = this.user.companyData.findIndex(c => c.companyId == response.data.id)
        this.user.companyData[index].companyLogo = response.data.companyLogo
        localStorage.setItem('user', JSON.stringify(this.user))
        this.user = JSON.parse(localStorage.getItem('user'))
        this.companies = this.user.companyData.filter(c => c.isHideInSwitch == false)
      })
    });
    this.socket.on(RealTimeKeys.Tool_Change, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
          let roleName = this.user.role
          this.settingsTools = response.data.find(d => d.toolType === ToolType.Setting_Tools)
          this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
            if(response.success){
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
              this.customersList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Customers_list,ToolType.Setting_Tools,permissionList)
              this.usersList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Users_list,ToolType.Setting_Tools,permissionList)
              // this.trucksList = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Trucks_list,ToolType.Setting_Tools,permissionList)
              this.supportTickets = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Support_Tickets,ToolType.Setting_Tools,permissionList)
              this.myProfile = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.My_Profile,ToolType.Setting_Tools,permissionList)
              this.knowledgeBase = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.knowledge_Base,ToolType.Setting_Tools,permissionList)
              this.carWashVehicle = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
              this.qrCode = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Qr_Code, ToolType.Setting_Tools, permissionList)
              // this.qrCodeMoving= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Qr_Code, ToolType.Setting_Tools, permissionList)
              this.qrCodeStickers= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Qr_Code_Stickers, ToolType.Setting_Tools, permissionList)
              this.carWashServices= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Services, ToolType.Setting_Tools, permissionList)
              this.carwashWebsiteObj= this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Was_Website, ToolType.Setting_Tools, permissionList)
              // this.whorehousePermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Warehouse_Map, ToolType.Setting_Tools, permissionList)
            }
          })
        },error=>{
          // console.log(error)
        })
      }
    });
    this.socket.on(RealTimeKeys.Show_Hide_Update, (data: any) => {
      let isExists = this.user.companyData.find(c => c.companyId === data.companyId)
      if (isExists != undefined || isExists != null) {
        this._personService.getAllUserCompanies(this.user.uid).subscribe((response: ResponseModel) => {
          this.companies = response.data.filter(c => c.isHideInSwitch == false)
          this.user.companyData = response.data
          localStorage.setItem('user', JSON.stringify(this.user))
        })
      }
    });
    this.socket.on(RealTimeKeys.Person_Update, (data: any) => {
      if(data.companyId == this.user.companyId && this.user.id == data.id)  {
         this._personService.getPersonById(data.id).subscribe((response:ResponseModel)=>{
           if(response.data){
              let displayName = response.data.lastName + ', ' + response.data.firstName
              this.user.displayName = displayName
              this.ownerName = displayName
              localStorage.setItem('user',JSON.stringify(this.user))
           }
         })
      }
     });
  }
  getCompanyId(){
    return this.user.companyId
  }
  getCompanies() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.companies = this.user.companyData.filter(c=>c.isHideInSwitch == false)
    // let comapnyId = localStorage.getItem("companyId")
    localStorage.setItem('companyName', this.companies.find(item => item.companyId === this.user.companyId).companyName)
    this.currentCompany = this.companies.find(item => item.companyId === this.user.companyId)
    this.logoUrl = this.currentCompany.companyLogo
    this.companyName = this.currentCompany.companyName
    // this.ownerName = this.currentCompany.ownerName
  }
  chooseCompany(company: any) {
    let bufferCompany = this.user.companyData.find(c => c.companyId == company.companyId)
    if(company.companyStatus == CompanyStatus.Canceled && !bufferCompany.userDisable){
      this.companyStatus = CompanyStatus.Canceled
      this.warningModal.show()
    }
    if(company.companyStatus == CompanyStatus.Deactivated && !bufferCompany.userDisable){
      this.companyStatus = CompanyStatus.Deactivated
      this.warningModal.show()
    }
    if(bufferCompany.userDisable ){
      this.userDisableModal.show()
    }
    else if(company.companyStatus == CompanyStatus.Active && !bufferCompany.userDisable
      || company.companyStatus == CompanyStatus.NotPay && !bufferCompany.userDisable
      ||company.companyStatus == CompanyStatus.NotPayPeriod && !bufferCompany.userDisable
      ){
      this.companyName = company.companyName
      let model = {
        companyId: company.companyId,
        email: this.user.email,
        uid: this.user.uid
      }
      this._authService.loginToAnotherCompany(model).subscribe((response: ResponseModel) => {
        let id = response.data.companyId
        if(response.success){
           if (response.data.role === "admin" || response.data.role === "manager") {
            localStorage.setItem("userId", response.data.id)
            this.router.navigate(['/admin/', id])
            localStorage.setItem("companyId", response.data.companyId)
              clearTimeout(this.timer)
              this.timer =  setTimeout(() => {
                 window.location.reload()
               }, 2000);
          }
          else if (response.data.role === "customer" || response.data.role === "crew"
            || response.data.role === "teamleader" || response.data.role === "3rdparty") {
               let user = JSON.parse(localStorage.getItem('user'))
              this.role = user.role
            // this.currentCompany = this.companies.find(item => item.companyId === response.data.companyId)
            localStorage.setItem("userId", response.data.id)
            localStorage.setItem("companyId", response.data.companyId)
            this.router.navigate(['/user/', response.data.id])
            clearTimeout(this.timer)
            this.timer =  setTimeout(() => {
               window.location.reload()
             }, 2000);
          }
        }
        else if(!response.success){
          if ( response.data.companyStatus == CompanyStatus.NotPay) {
            this.router.navigate(['/not-paid'])
          }
          if ( response.data.companyStatus == CompanyStatus.NotPayPeriod) {
            this.router.navigate(['/not-period'])
          }
          else if (response.data.companyStatus == CompanyStatus.Canceled) {
            this.router.navigate(['/disabled-company'])
          }
          else if (response.data.companyStatus == CompanyStatus.Deactivated) {
            this.router.navigate(['/deactivated-company'])
          }
        }
      })
    }
  }
  logout() {
    this._globalService.logout()
  }
  // createNewBranch(){
  //   !Capacitor.isNativePlatform() ?  this.branchMobileModal.show()  :
  //   this.router.navigate(['/new-company-plans'])
  // }
}
