import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class GetCompanyByIdSuperResolver implements Resolve<any[]>
{
  constructor(private _companiesService: CompaniesService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this._companiesService.getCompanyById(route.params['id']).pipe(
      catchError(error => {
        console.log(error)
        return of(null);
      })
    );
  }
}
