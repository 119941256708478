import { EventEmitter, Injectable, Output, ViewChild } from '@angular/core';
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class AddressMapsService {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
constructor() {
  // this.getPlaceAutocomplete(this.addressText);
}
 getPlaceAutocomplete(addressText:any) {
  let place:any
  const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement,
    {
      // componentRestrictions: { country: 'US' },
      types: ['establishment']  // 'establishment' / 'address' / 'geocode'
    }
    );
  google.maps.event.addListener(autocomplete, 'place_changed', () => {
     place = autocomplete.getPlace();
     return  this.getAddressFromComponents(place)
  });
}
getAddressFromComponents(geo: any) {
  const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
  const streetName = geo.find( g => g.types.find( t => t === 'route' )) !=undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
  const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
  const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
  const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
  const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) !=undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
  return {
    addressOne: `${streetNumber} ${streetName}`,
    city: cityName,
    state: stateName,
    country: countryName,
    zip: zip
  };
}
}
