import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { JobsService } from './../../../../../core/_services/jobs/jobs.service';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { JobDetailsService } from './../../../../../core/_services/job-details/job-details.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import * as moment from 'moment';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { DatePipe } from '@angular/common';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { ModalDirective } from 'angular-bootstrap-md';
interface TimeDate {
  id: string,
  jobDescription: string,
  startDateWindowFrom: Date
  startDateWindowTo: Date
  startTimeWindowFrom: Date
  startTimeWindowTo: Date
  endDateWindowFrom: Date
  endDateWindowTo: Date
  endTimeWindowFrom: Date
  endTimeWindowTo: Date
}
@Component({
  selector: 'app-draft-job',
  templateUrl: './draft-job.component.html',
  styleUrls: ['./draft-job.component.scss']
})
export class DraftJobComponent implements OnInit {
  isLinear = false;
  // times: TimeDate[] = []
  time: TimeDate = {} as TimeDate
  editItem: any = {}
  timeDateFrom: FormGroup
  editTimeDateFrom: FormGroup
  uniqId = ''
  bsConfig: Partial<BsDatepickerConfig>;
  jobDetails: JobDetails
  jobDetailsId: string
  datesAndTimes: any = []
  jobId: any
  p: number = 1
  error: any = {
    isError: false,
    errorMessage: ''
  };
  indexDelete
  items: any[] = []
  jobStatusLayer: any = {}
  user: any = {}
  statusActive: any = StatusActive
  userPermission: any = {}
  role: string = ''
  jobsTools: any = {}
  dateAndTimeObj: any = {}
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  timer: any
  timer1: any
  timer2: any
  // for update
  @ViewChild('dateAndTimeSendModal') dateAndTimeSendModal: ModalDirective;
  @ViewChild('dateAndTimeNotSendModal') dateAndTimeNotSendModal: ModalDirective;
  // for add
  @ViewChild('addDateAndTimeSendModal') addDateAndTimeSendModal: ModalDirective;
  @ViewChild('addDateAndTimeNotSendModal') addDateAndTimeNotSendModal: ModalDirective;
  @ViewChild('notDeleteModal') notDeleteModal: ModalDirective;
  constructor(
    private fb: FormBuilder,
    private _jobDetailsService: JobDetailsService,
    private route: ActivatedRoute,
    public datePipe: DatePipe,
    public _jobsService: JobsService,
    public _toolsService: ToolsService,
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.createTimeDateFrom()
    this.createEditTimeDateFrom()
    this.bsConfig = {
      containerClass: 'theme-blue',
      returnFocusToInput: true
    };
    // get Dates and Times
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        if (this.role == 'admin') {
          this.getDatesAndTimes()
        }
        if (this.role != 'admin' && this.role != 'manager') {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.Date_and_Time, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getDatesAndTimes()
          }
        }
        if (this.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.Date_and_Time, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getDatesAndTimes()
          }
        }
      }
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
      }
    }, error => {
      console.log(error)
    })
  }
  getDatesAndTimes() {
    this._jobDetailsService.getJobDetailsByIdItem(this.jobId, ToolJobsEditItem.Date_and_Time).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.datesAndTimes = response.data.dateAndTime
        this.jobDetailsId = response.data.id
      }
    })
  }
  createTimeDateFrom() {
    this.timeDateFrom = this.fb.group({
      jobDescription: [''],
      startDateWindowFrom: ['', Validators.required],
      startDateWindowTo: ['',],
      startTimeWindowFrom: ['', Validators.required],
      startTimeWindowTo: ['',],
      endDateWindowFrom: ['',],
      endDateWindowTo: ['', Validators.required],
      endTimeWindowFrom: ['',],
      endTimeWindowTo: ['', Validators.required],
    })
  }
  createEditTimeDateFrom() {
    this.editTimeDateFrom = this.fb.group({
      jobDescription: [''],
      startDateWindowFrom: ['', Validators.required],
      startDateWindowTo: ['',],
      startTimeWindowFrom: ['', Validators.required],
      startTimeWindowTo: ['',],
      endDateWindowFrom: ['',],
      endDateWindowTo: ['', Validators.required],
      endTimeWindowFrom: ['',],
      endTimeWindowTo: ['', Validators.required],
    })
  }
  // getLayerPermission() {
  //   let jobStatus = this.items.find(i => i.itemId == ToolJobsEditItem.Date_and_Time)
  //   this.userPermission = jobStatus.users.find(p => p.id == this.user.id).permission
  //   console.log('Permission DateAndTime', jobStatus)
  //   console.log('statusActive',this.statusActive.Active)
  // }
  get f() {
    return this.timeDateFrom.controls
  }
  get ff() {
    return this.editTimeDateFrom.controls
  }
  storeId(item) {
    this.uniqId = item.id
    this.editItem = Object.assign({}, item)
    this.editItem.startDateWindowFrom = new Date(this.editItem.startDateWindowFrom)
    this.editItem.startDateWindowTo = new Date(this.editItem.startDateWindowTo)
    this.editItem.endDateWindowFrom = new Date(this.editItem.endDateWindowFrom)
    this.editItem.endDateWindowTo = new Date(this.editItem.endDateWindowTo)
    this.editTimeDateFrom.patchValue({ ... this.editItem });
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  checkEditSendEmail() {
    let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.Date_and_Time, EmailNotification.Email_Notification_Date_Add_Or_Changed)
    if ( notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.dateAndTimeSendModal.show()
      this.isCustomerEmail = Is_Customer_Email.Send_Email
    }
    else {
      this.updateTimeDate()
    }
  }
  checkAddSendEmail() {
    let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.Date_and_Time, EmailNotification.Email_Notification_Date_Add_Or_Changed)
    if (notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.addDateAndTimeSendModal.show()
      this.isCustomerEmail = Is_Customer_Email.Send_Email
    }
    else {
      this.addTimeDate()
    }
  }
  resetAddForm() {
    this.timeDateFrom.reset()
  }
  addTimeDate() {
    let item = this.timeDateFrom.value
    item.id = (this.datesAndTimes.length) + 1
    let idItem = ToolJobsEditItem.Date_and_Time
    item.startDateWindowFrom = this.getFormateDateAndTime(item.startDateWindowFrom)
    item.startDateWindowTo = this.getFormateDateAndTime(item.startDateWindowTo)
    item.endDateWindowFrom = this.getFormateDateAndTime(item.endDateWindowFrom)
    item.endDateWindowTo = this.getFormateDateAndTime(item.endDateWindowTo)
    item.summaryDate = this.getFormateDateAndTime(item.startDateWindowFrom) + ' : ' + item.startTimeWindowFrom + ' --> ' + this.getFormateDateAndTime(item.endDateWindowTo) + ' : ' + item.endTimeWindowTo
    this.datesAndTimes.push(item)
    // console.log( this.datesAndTimes)
    this._jobDetailsService.updateJobDetails(this.datesAndTimes, this.jobId, idItem, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      this.timeDateFrom.reset()
    }, error => {
      console.log(error)
    })
  }
  dateFrom(event) {
    clearTimeout(this.timer2);
    this.timer2 = setTimeout(() => {
      this.timeDateFrom.get('startDateWindowTo').setValue(event)
    }, 500);
  }
  dateEndTo(event) {
    clearTimeout(this.timer1);
    this.timer1 = setTimeout(() => {
      this.timeDateFrom.get('endDateWindowFrom').setValue(event)
    }, 500);
  }
  setStartTimeFrom(event) {
    this.timeDateFrom.get('startTimeWindowTo').setValue(event)
  }
  setEndTimeTo(event) {
    this.timeDateFrom.get('endTimeWindowFrom').setValue(event)
  }
  // Edit Date And Time
  setStartTimeEditFrom(event) {
    this.editTimeDateFrom.get('startTimeWindowTo').setValue(event)
  }
  editDateFrom(event) {
    clearTimeout(this.timer2);
    this.timer2 = setTimeout(() => {
      this.editTimeDateFrom.get('startDateWindowTo').setValue(event)
    }, 500);
  }
  editDateEndTo(event) {
    clearTimeout(this.timer1);
    this.timer1 = setTimeout(() => {
      this.editTimeDateFrom.get('endDateWindowFrom').setValue(event)
    }, 500);
  }
  setEndTimeEditTo(event) {
    this.editTimeDateFrom.get('endTimeWindowFrom').setValue(event)
  }
  // setStartTimeFrom(event) {
  //   this.editItem.startTimeWindowFrom = event
  // }
  setStartTimeTo(event) {
    this.editItem.startTimeWindowTo = event
  }
  setEndTimeFrom(event) {
    this.editItem.endTimeWindowFrom = event
  }
  // setEndTimeTo(event) {
  //   this.editItem.endTimeWindowTo = event
  // }
  updateTimeDate() {
    let index = this.datesAndTimes.findIndex(d => d.id == this.uniqId);
    let idItem = ToolJobsEditItem.Date_and_Time
    this.editItem = Object.assign({}, this.editTimeDateFrom.value)
    this.editItem.startDateWindowFrom = this.getFormateDateAndTime(this.editItem.startDateWindowFrom)
    this.editItem.startDateWindowTo = this.getFormateDateAndTime(this.editItem.startDateWindowTo)
    this.editItem.endDateWindowFrom = this.getFormateDateAndTime(this.editItem.endDateWindowFrom)
    this.editItem.endDateWindowTo = this.getFormateDateAndTime(this.editItem.endDateWindowTo)
    this.editItem.id = this.uniqId
    this.datesAndTimes[index] = this.editItem
    this._jobDetailsService.updateJobDetails(this.datesAndTimes, this.jobId, idItem, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      this.datesAndTimes = response.data.dateAndTime
      this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
      this.editTimeDateFrom.reset()
    }, error => {
      console.log(error)
    })
  }
  storeIndex(i) {
    this.indexDelete = i
  }
  deleteTime() {
    if (this.datesAndTimes.length == 1) {
      this.notDeleteModal.show()
    }
    else {
      let idItem = ToolJobsEditItem.Date_and_Time
      this.datesAndTimes.splice(this.datesAndTimes[this.indexDelete], 1);
      this._jobDetailsService.updateJobDetails(this.datesAndTimes, this.jobId, idItem, 0).subscribe((response: ResponseModel) => {
        this.datesAndTimes = response.data.dateAndTime
      }, error => {
        console.log(error)
      })
    }
  }
  compareTwoDates() {
    if (this.timeDateFrom.controls['startDateWindowFrom'].value != '' && this.timeDateFrom.controls['endDateWindowTo'].value != '') {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let startDate = new Date(this.timeDateFrom.controls['startDateWindowFrom'].value)
        let dateOne = this.datePipe.transform(startDate, 'MM-dd-yyyy')
        let endDate = new Date(this.timeDateFrom.controls['endDateWindowTo'].value)
        let dateTow = this.datePipe.transform(endDate, 'MM-dd-yyyy')
        if (moment(dateOne).isAfter(moment(dateTow))) {
          this.error = {
            isError: true,
            errorMessage: 'End Date can not before start date '
          };
        }
        else {
          this.error = {
            isError: false,
          }
        }

      }, 1500);
    }
  }
  compareTwoEditDates() {
    if(this.editTimeDateFrom.controls['startDateWindowFrom'].value !='' && this.editTimeDateFrom.controls['endDateWindowTo'].value!=''){
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let startDate = new Date(this.editTimeDateFrom.controls['startDateWindowFrom'].value)
        let dateOne = this.datePipe.transform(startDate, 'MM-dd-yyyy')
        let endDate = new Date(this.editTimeDateFrom.controls['endDateWindowTo'].value)
        let dateTow = this.datePipe.transform(endDate, 'MM-dd-yyyy')
        if (moment(dateOne).isAfter(moment(dateTow))) {
          this.error = {
            isError: true,
            errorMessage: 'End Date can not before start date '
          };
        }
        else {
          this.error = {
            isError: false,
          }
        }

      }, 1500);
    }
  }
  resetError() {
    this.error = {
      isError: false,
      errorMessage: ''
    };
  }
  getFormateDateAndTime(date: Date) {
    let medDate = new Date(date)
    return medDate.getFullYear() + '-' + ((medDate.getMonth() + 1)) + '-' + medDate.getDate()
  }
}
