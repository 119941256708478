import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  getHistoryById(id: string) {
    return this.http.get(this.baseUrl + "history/" + id)
  }
  getHistoryByJob(jobId: string) {
    return this.http.get(this.baseUrl + "historyByJob/" + jobId)
  }
  getHistoryByHub(companyId: string, jobId: string) {
    return this.http.get(this.baseUrl + "historyHub/" + companyId + '/' + jobId)
  }
  getHistoryByMap(companyId: string) {
    return this.http.get(this.baseUrl + "historyMap/" + companyId)
  }
  addHistory(model: any) {
    return this.http.post(this.baseUrl + "history", model)
  }
}
