<!-- <!DOCTYPE html>
<html lang="en"> -->

<!-- <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>K&R Car Wash</title> -->
<!-- <link rel="shortcut icon" type="image/jpg" href="images/img/icon-48x48.png" /> -->
<!-- font awesome cdn link  -->
<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"> -->

<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/css/lightgallery.min.css"> -->

<!-- <link rel="stylesheet" href="https://unpkg.com/swiper@7/swiper-bundle.min.css" /> -->

<!-- custom css file link  -->
<!-- <link rel="stylesheet" href="./assets/car-wash/_website_global.css"> -->

<!-- </head> -->

<!-- <body> -->
<div class="carwash-website">

    <!-- home section starts  -->

    <section class="home" id="home" *ngIf="websiteObj.header?.isShow" style="background: url(./assets/car-wash/car_wash_images/car_wah_banner.jpg) no-repeat;">
        <!-- header section starts  -->
        <header class="header" [ngClass]="{'scrolled': scrolled}">
            <a (click)="jumpToSection('home')" class="logo">
                <!-- <span class="redText">{{getFirstWord()}}</span> -->
                <span [ngClass]="{'blackText': scrolled}">{{companyInfo.companyName}}</span>
                <!-- <span [ngClass]="{'blackText': scrolled}">{{companyInfo.companyName}}</span> -->
            </a>
            <nav class="navbar">
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('home')" *ngIf="websiteObj.header?.isShow">Home</a>
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('pricing')" *ngIf="websiteObj.pricing?.isShow">Express Services</a>
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('services')" *ngIf="websiteObj.services?.isShow">Our Services</a>
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('projects')" *ngIf="websiteObj.someWorks?.isShow && websiteObj.someWorks.images.length  >0">Some Works</a>
                <!-- <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('addressMap')" *ngIf="websiteObj.addressMap?.isShow">Hours & Map</a> -->
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('contact')" *ngIf="websiteObj.contactAddress?.isShow">Contact</a>
                <a [ngClass]="{'blackText': scrolled}" (click)="jumpToSection('about')" *ngIf="websiteObj.about?.isShow">About</a>
                <!-- <a href="#blogs">blogs</a> -->
            </nav>
            <div class="icons">
                <div id="info-btn" (click)="toggleBar()">
                    <img src="./assets/car-wash/car_wash_images/contact-mail2.png" alt="contact-mail">
                </div>
                <div id="menu-btn" class="fas fa-bars" (click)="toggleMenu()"></div>
                <!-- <div id="search-btn" class="fas fa-search"></div>
    <div id="login-btn" class="fas fa-user"></div> -->
            </div>

            <form action="" class="search-form">
                <input type="search" name="" placeholder="search here..." id="search-box">
                <label for="search-box" class="fas fa-search"></label>
            </form>

            <form action="" class="login-form">
                <h3>login form</h3>
                <input type="email" placeholder="enter your email" class="box">
                <input type="password" placeholder="enter your password" class="box">
                <div class="flex">
                    <input type="checkbox" name="" id="remember-me">
                    <label for="remember-me">remember me</label>
                    <a href="#">forgot password?</a>
                </div>
                <input type="submit" value="login now" class="btn">
                <p>don't have an account <a href="#">create one!</a></p>
            </form>

        </header>

        <div id="contact_Id" class="contact-info" *ngIf="isToggle">

            <div id="close-contact-info" class="fas fa-times" (click)="toggleBar()"></div>

            <div class="info">
                <i class="fas fa-phone"></i>
                <h3>phone number</h3>
                <!-- <p>+4915167770989</p> -->
                <p><a href="tel:{{websiteObj.contactAddress.phoneEmailObj.phone}}">{{websiteObj.contactAddress.phoneEmailObj.phone}}</a></p>
                <!-- <p>+111-222-3333</p> -->
            </div>

            <div class="info">
                <i class="fas fa-envelope"></i>
                <h3>email address</h3>
                <p> <a href="mailto:{{websiteObj.contactAddress.contactObj.email}}">{{websiteObj.contactAddress.phoneEmailObj.email}}</a></p>
                <!-- <p>anasbhai@gmail.com</p> -->
            </div>
            <div class="info" (click)="showPosition()">
                <i class="fas fa-map-marker-alt"></i>
                <h3>location address</h3>
                <p>{{websiteObj.contactAddress.phoneEmailObj.address}}</p>
            </div>
            <!-- <div class="share">
    <a href="#" class="fab fa-facebook-f"></a>
    <a href="#" class="fab fa-twitter"></a>
    <a href="#" class="fab fa-instagram"></a>
    <a href="#" class="fab fa-linkedin"></a>
</div> -->

        </div>

        <!-- header section ends -->

        <div class="home-slider">
            <div class="swiper-wrapper">
                <!-- <section style="background: url(./assets/car-wash/car_wash_images/img-carwash-4.jpg) no-repeat;">
                    <div class="content">
                        <h3 style="font-size: 3rem;">FULL SERVICE AND EXPRESS CAR WASH </h3>
                        <p>K&R Car Wash is a family owned and operated business. Rasim and Samer Hallum have over 20 years experience with car care perfection.</p>
                        <a href="#about" class="btn">More </a>
                    </div>
                </section> -->
                <section class="swiper-slide slide">
                    <div class="content">
                        <h3>{{websiteObj.header?.tittleHeader}}</h3>
                        <p> {{websiteObj.header?.textHeader}}</p>
                        <!-- <a (click)="jumpToSection('about')" class="btn">More </a> -->
                    </div>
                </section>

                <!-- <section class="swiper-slide slide" style="background: url(images/img/img-11.jpg) no-repeat;">
                    <div class="content">
                        <h3 style="font-size: 3rem;">Die beste Reinigungsfirma des Landes</h3>
                        <p>Unser professioneller Hausreinigungsservice verleiht Ihrer Wohnung ein sauberes, professionelles Aussehen, das Ihre Gäste beeindrucken wird.</p>
                        <a href="#about" class="btn">Weiter </a>
                    </div>
                </section> -->

            </div>

            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>

        </div>

    </section>

    <!-- home section ends -->
    <!--
    <section class="about" id="about" *ngIf="websiteObj.about?.isShow">

        <h1 class="heading">About Our Company </h1>

        <div class="row">

            <div class="video">
                <img src="./assets/car-wash/car_wash_images/img-carwash-3.jpg" style="width: 100% !important;">
            </div>

            <div class="content">
                <h3>{{websiteObj.about?.tittleAbout}}</h3>
                <p>{{websiteObj.about?.textAbout}}</p>
                <a href="#services" class="btn">Continue Reading</a>
            </div>

        </div>

    </section> -->

    <!-- about section ends -->
    <!-- pricing section starts  -->

    <section class="pricing" id="pricing" *ngIf="websiteObj.pricing?.isShow && products.length > 0">
        <!-- Normal Pricing -->
        <h1 class="heading"> express services </h1>
        <div class="box-container single-wash-container">
            <div class="box" *ngFor="let product of products">
                <h2>{{product.name}}</h2>
                <ul>
                    <li *ngIf="product?.priceSingleWash?.priceAfter > 0">
                        <div class="price-item">
                            <h3> Single - One wash <sub class="discount ml-1 mr-2" *ngIf="product?.priceSingleWash?.isShow"><s> ${{product?.priceSingleWash?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{product?.priceSingleWash?.priceAfter}}</div>
                        </div>
                    </li>
                    <li>
                        <div class="price-item">
                            <h3> Monthly - Unlimited <sub class="discount ml-1 mr-2" *ngIf="product?.priceBeforeDiscount?.priceBefore > 0 && product?.priceBeforeDiscount?.isShow"><s> ${{product?.priceBeforeDiscount?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{getPrice(product.price)}}</div>
                        </div>
                    </li>
                    <li *ngIf="product?.productsMaxCar?.priceAfter > 0">
                        <div class="price-item">
                            <h3> Add Car - Max {{product?.productsMaxCar?.countCar}} Cars <sub class="discount ml-1 mr-2" *ngIf="product?.productsMaxCar?.isShow"><s> ${{product?.productsMaxCar?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{product?.productsMaxCar?.priceAfter}}</div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="main-icon">
                    <i class="fas fa-id-card"></i>
                </div> -->
                <div class="btns-express">
                    <a class="btn" (click)="showDetails(product);productModal.show()">Wash Features</a>
                    <a class="btn" (click)="subscribeCustomer()">Join The Unlimited Club Now</a>
                </div>
            </div>
        </div>
        <h1 class="heading mt-5" *ngIf="productsPackaging?.length > 0"> Packages pricing </h1>
        <div class="box-container single-wash-container">
            <div class="box" *ngFor="let product of productsPackaging">
                <!-- <i class="fas fa-cart-plus"></i> -->
                <h2>{{product.name}}</h2>
                <ul>
                    <li *ngIf="product?.priceSingleWash?.priceAfter > 0">
                        <div class="price-item">
                            <h3> Single - One wash <sub class="discount ml-1 mr-2" *ngIf="product?.priceSingleWash?.isShow"><s> ${{product?.priceSingleWash?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{product?.priceSingleWash?.priceAfter}}</div>
                        </div>
                    </li>
                    <li>
                        <div class="price-item">
                            <h3> Monthly - Unlimited <sub class="discount ml-1 mr-2" *ngIf="product?.priceBeforeDiscount?.priceBefore > 0 && product?.priceBeforeDiscount?.isShow"><s> ${{product?.priceBeforeDiscount?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{getPrice(product.price)}}</div>
                        </div>
                    </li>
                    <li *ngIf="product?.productsMaxCar?.priceAfter > 0">
                        <div class="price-item">
                            <h3> Add Car - Max {{product?.productsMaxCar?.countCar}} Cars <sub class="discount ml-1 mr-2" *ngIf="product?.productsMaxCar?.isShow"><s> ${{product?.productsMaxCar?.priceBefore}}</s></sub></h3>
                            <div class="price pl-2"> <span><sup>$</sup></span>{{product?.productsMaxCar?.priceAfter}}</div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="main-icon">
                    <i class="fas fa-id-card"></i>
                </div> -->
                <div class="btns-express">
                    <a class="btn" (click)="showDetails(product);productModal.show()">Wash Features</a>
                    <a class="btn" (click)="subscribeCustomer()">Membership Details</a>
                </div>
                <!-- <div class="price"><span>$</span>{{getPrice(product.price)}}</div>
                <a class="btn" (click)="showDetails(product);productModal.show()">Membership Details</a> -->
            </div>
        </div>
    </section>

    <!-- pricing section ends -->
    <!-- services section starts  -->

    <section class="services" id="services" *ngIf="websiteObj.services?.isShow &&  services.length > 0">
        <h1 class="heading"> Our Services </h1>
        <div class="box-container">
            <div class="box" *ngFor="let service of services">
                <!-- <img src="images/service-1.png" alt=""> -->
                <i class="uil uil-car-wash" *ngIf="service.type == serviceType.Normal"></i>
                <i class="uil uil-car" *ngIf="service.type == serviceType.Other"></i>
                <!-- <i class="uil uil-create-dashboard"></i> -->
                <h3>{{service.serviceName}} </h3>
                <span class="service-normal" *ngIf="service.type == serviceType.Normal" (click)="storeService(service);serviceModal.show()">Service Details</span>
                <span class="other-price" *ngIf="service.type == serviceType.Other">${{service.price}}</span>
            </div>
            <!-- <script async src="https://js.stripe.com/v3/buy-button.js">
            </script>
            <stripe-buy-button buy-button-id="buy_btn_1OcE6IEBEHnFomvdtbCIxj1L" publishable-key="pk_test_51LLHPuEBEHnFomvd59RfNp69wrOUzeFdGuGWmAhDAujJnPmLumsnrZkYY8tOOvah9Mo4hbSRJctabNTwSzorAVHW0028zsXUKz">
            </stripe-buy-button> -->
        </div>
    </section>

    <!-- services section ends -->

    <!-- projects section starts  -->

    <section class="projects" id="projects" *ngIf="websiteObj.someWorks?.isShow && websiteObj.someWorks.images.length  >0">
        <h1 class="heading"> Some Works </h1>
        <!-- <div class="box-container">
            <div *ngFor="let item of  websiteObj.someWorks.images">
                <a href="{{item.src}}" class="box">
                    <div class="image">
                        <img src="{{item.src}}" alt="">
                    </div>
                </a>
            </div>
        </div> -->
        <lightgallery class="box-container" [settings]="settings" [onInit]="onInit">
            <a *ngFor="let item of  websiteObj.someWorks.images" href="{{item.src}}" class="box" data-lg-size="480-480-480, 800-800-800, 1400-1400">
                <div class="image">
                    <img alt="img1" src="{{item.src}}" class="img-item-gallery" />
                </div>
            </a>
            <!-- <a href="./assets/car-wash/car_wash_images/img-carwash-8.jpg" class="box" data-lg-size="480-480-480, 800-800-800, 1400-1400">
                <div class="image">
                    <img alt="img2" src="./assets/car-wash/car_wash_images/img-carwash-8.jpg" class="img-item-gallery" />
                </div>
            </a>
            <a href="./assets/car-wash/car_wash_images/img-carwash-8.jpg" class="box" data-lg-size="480-480-480, 800-800-800, 1400-1400">
                <div class="image">
                    <img alt="img2" src="./assets/car-wash/car_wash_images/img-carwash-8.jpg" class="img-item-gallery" />
                </div>
            </a> -->
        </lightgallery>

        <!-- <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
            <a *ngFor="let item of  websiteObj.someWorks.images" href="{{item.src}}">
                <img alt="img1" src="{{item.src}}" />
            </a>
        </lightgallery> -->

    </section>
    <!-- projects section ends -->

    <!-- reviews section starts  -->


    <!-- <section class="reviews" id="reviews">

        <h1 class="heading"> Customer Reviews </h1>

        <div class="swiper reviews-slider">

            <div class="swiper-wrapper ">
                <div class="swiper-slide slide">
                    <p>Great car wash, staff is very attentive. The whole place is very clean. The owner jumped right in to help when four other cars pulled into the car wash after mine. Reasonably priced with tip. </p>
                    <div class="user">
                        <div class="info">
                            <h3>Anthony L.</h3>
                            <div class="stars">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide slide">
                    <p>Great car wash, staff is very attentive. The whole place is very clean. The owner jumped right in to help when four other cars pulled into the car wash after mine. Reasonably priced with tip.</p>
                    <div class="user">
                        <div class="info">
                            <h3>Rita Asina</h3>
                            <div class="stars">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide slide">
                    <p>Great car wash, staff is very attentive. The whole place is very clean. The owner jumped right in to help when four other cars pulled into the car wash after mine. Reasonably priced with tip.</p>
                    <div class="user">
                        <div class="info">
                            <h3>Jim Johnson</h3>
                            <div class="stars">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star-o"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- reviews section ends -->



    <!-- contact section starts  -->

    <!-- <section class="contact" id="addressMap" *ngIf="websiteObj.addressMap?.isShow">
        <h1 class="heading"> Hours & Map </h1>
        <div class="row">
            <iframe class="map" [src]="urlMap" allowfullscreen="" loading="lazy">
            </iframe>
            <div class="col-lg-6 hours-section">
                <div class="card">
                    <table class="table">
                        <thead class="bg-primary">
                            <tr>
                                <th scope="col">Day</th>
                                <th scope="col">Open</th>
                                <th scope="col">Close</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let day of this.websiteObj.addressMap?.days" [ngClass]="[!day.isOpen ? 'bg-danger' : '']">
                                <th scope="row">{{day.name}}</th>
                                <td>{{ day.isOpen ? getTime(day.start) : 'Closed' }}</td>
                                <td>{{day.isOpen ? getTime(day.end) : 'Closed'}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </section> -->

    <!-- contact section ends -->

    <!-- Contact us -->

    <!-- <section class="contact contact2" id="contact" *ngIf="websiteObj.contactAddress?.isShow">

        <h1 class="heading"> Contact Us </h1>

        <div class="row">
            <div class="col-lg-6">
                <div class="card address">
                    <div class="info">
                        <i class="fas fa-phone mt-2"></i>
                        <h3>phone number</h3>
                        <p><a href="tel:{{websiteObj.contactAddress.phoneEmailObj.phone}}">{{websiteObj.contactAddress.phoneEmailObj.phone}}</a></p>
                    </div>
                    <div class="info">
                        <i class="fas fa-envelope"></i>
                        <h3>email address</h3>
                        <p> <a href="mailto:{{websiteObj.contactAddress.phoneEmailObj.email}}"> {{websiteObj.contactAddress.phoneEmailObj.email}}</a></p>
                    </div>
                    <div class="info" (click)="showPosition()">
                        <i class="fas fa-map-marker-alt"></i>
                        <h3>location address</h3>
                        <p>{{websiteObj.contactAddress.phoneEmailObj.address}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <form #contactForm="ngForm">
                    <h3>Contact</h3>
                    <input type="text" placeholder="Name" required class="box" name="name" [(ngModel)]="sendContactObj.name">
                    <input type="email" placeholder="E-Mail" required class="box" name="email" [(ngModel)]="sendContactObj.email">
                    <input type="number" placeholder="Phone" min="0" class="box" name="phone" [(ngModel)]="sendContactObj.phone">
                    <textarea name="" placeholder="Message /Comment" required class="box" id="" cols="30" rows="10" name="message" [(ngModel)]="sendContactObj.message"></textarea>
                    <input type="button" value="send Message" [disabled]="!contactForm.valid" (click)="sendContact()" class="btn">
                </form>
            </div>
        </div>

    </section> -->
    <section class="contact-map" id="contact" *ngIf="websiteObj.contactAddress?.isShow">
        <div class="container-fluid px-0  contact">
            <div class="container px-0 pt-5">
                <!-- <div class="section-title text-center position-relative pb-3 mb-5 mx-auto">
              <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
              <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
            </div> -->
                <h1 class="heading"> Contact Us </h1>
                <div class="row contact-media g-5 mb-5">
                    <div class="col-lg-4" *ngIf="websiteObj.contactAddress.phoneEmailObj.phone !== ''">
                        <div class="contact-item d-flex align-items-center ">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded">
                                <i class="fa fa-phone text-white"></i>
                            </div>
                            <div class="contact-item-content px-4">
                                <h4 class="mb-2">Phone Number</h4>
                                <h5 class="text-primary mb-0">
                                    <a className="bar-item me-3 text-primary" href="tel:{{websiteObj.contactAddress.phoneEmailObj.phone}}" rel="noreferrer">
                                        <i className="fa fa-phone-alt me-2"></i>
                                        <span class="bar-item-label">{{websiteObj.contactAddress.phoneEmailObj.phone}}</span>
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" *ngIf="websiteObj.contactAddress.phoneEmailObj.email !== ''">
                        <div class="contact-item d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded">
                                <i class="fa fa-envelope-open text-white"></i>
                            </div>
                            <div class="contact-item-content px-4">
                                <h4 class="mb-2">Email Address</h4>
                                <h5 class="text-primary mb-0">
                                    <a className="bar-item text-primary" href="mailto:{{websiteObj.contactAddress.phoneEmailObj.email}}" target="_blank" rel="noreferrer">
                                        <i className="fa fa-envelope-open me-2"></i>
                                        <span class="bar-item-label">{{websiteObj.contactAddress.phoneEmailObj.email}}</span>
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4" *ngIf="websiteObj.contactAddress.phoneEmailObj.address !== ''">
                        <div class="contact-item d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                            <div class="bg-primary d-flex align-items-center justify-content-center rounded">
                                <a>
                                    <i class="fa fa-map-marker-alt text-white"></i>
                                </a>
                            </div>
                            <div class="contact-item-content px-4">
                                <h4 class="mb-2">Location Address</h4>
                                <h5 class="text-primary mb-0">
                                    <a className="bar-item me-3 text-primary" (click)="showPosition()">
                                        <i className="fa fa-map-marker-alt me-2"></i>
                                        <span class="bar-item-label">{{getAddress(websiteObj.contactAddress.phoneEmailObj.address)}}</span>
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div [ngClass]="websiteObj.addressMap.isMapShow  ? 'col-lg-6' : ' col-lg-12'" data-wow-delay="0.3s">
                        <form #contactForm="ngForm">
                            <div class="row g-3 contact-form">
                                <div class="col-md-6">
                                    <input type="text" class="input-item form-control border-0 bg-light px-4" [(ngModel)]="sendContactObj.name" placeholder="Your Name" />
                                </div>
                                <div class="col-md-6">
                                    <input type="email" class="input-item form-control border-0 bg-light px-4" [(ngModel)]="sendContactObj.email" placeholder="Your Email" />
                                </div>
                                <div class="col-12">
                                    <input type="text" class="input-item form-control border-0 bg-light px-4" [(ngModel)]="sendContactObj.phone" placeholder="Phone" />
                                </div>
                                <div class="col-12">
                                    <textarea class="form-control textarea-item border-0 bg-light px-4 py-3" rows="5" [(ngModel)]="sendContactObj.message" placeholder="Message"></textarea>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" [disabled]="!contactForm.valid" (click)="sendContact()" type="button">
                                     Send Message
                                 </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-6 col-sm-12 wow slideInUp" data-wow-delay="0.6s" *ngIf="websiteObj.addressMap.isMapShow">
                        <div className="map">
                            <!-- <iframe class="map" [src]="urlMap" allowfullscreen="" loading="lazy">
                          </iframe> -->
                            <iframe [src]="urlMap" width="100%" style="border-radius: 10px;border: 1px solid #e3dada;" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- about section starts  -->
    <section class="about" id="about" *ngIf="websiteObj.about?.isShow">
        <h1 class="heading">About Our Company </h1>
        <div class="row">
            <div class="video">
                <img src="./assets/car-wash/car_wash_images/img-carwash-3.jpg" style="width: 100% !important;">
            </div>
            <div class="content">
                <h3>{{websiteObj.about?.tittleAbout}}</h3>
                <p>{{websiteObj.about?.textAbout}}</p>
                <!-- <a (click)="jumpToSection('services')" class="btn mt-0">Continue Reading</a> -->
            </div>
        </div>
    </section>
    <!-- blogs section starts  -->

    <!-- <section class="blogs" id="blogs">

    <h1 class="heading"> our blogs </h1>

    <div class="swiper blogs-slider">

        <div class="swiper-wrapper">

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="./assets/car-wash/car_wash_images/img-carwash-1.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="./assets/car-wash/car_wash_images/img-carwash-2.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="./assets/car-wash/car_wash_images/img-carwash-3.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="./assets/car-wash/car_wash_images/img-carwash-4.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="./assets/car-wash/car_wash_images/img-carwash-5.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

            <div class="swiper-slide slide">
                <div class="image">
                    <img src="images/img-carwash-6.jpg" alt="">
                </div>
                <div class="content">
                    <h3>blog title goes here</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, nobis!</p>
                    <a href="#" class="btn">read more</a>
                </div>
            </div>

        </div>

    </div>

</section> -->

    <!-- blogs section ends -->

    <!-- client logo section starts -->
    <!--
  <section class="logo-container">
      <div class="swiper logo-slider">
          <div class="swiper-wrapper">
              <div class="swiper-slide slide"><img src="images/client-logo-1.png" alt=""></div>
              <div class="swiper-slide slide"><img src="images/client-logo-2.png" alt=""></div>
              <div class="swiper-slide slide"><img src="images/client-logo-3.png" alt=""></div>
              <div class="swiper-slide slide"><img src="images/client-logo-4.png" alt=""></div>
              <div class="swiper-slide slide"><img src="images/client-logo-5.png" alt=""></div>
              <div class="swiper-slide slide"><img src="images/client-logo-6.png" alt=""></div>
          </div>
      </div>
  </section> -->

    <!-- client logo section ends -->

    <!-- footer section starts  -->
    <!-- <section class="footer">
        <div class="social" *ngIf="this.websiteObj.socialMedia?.isShow">
            <a *ngIf="whatsappLink.link != '' && whatsappLink.isShow" href="{{whatsappLink.link}}" class="whatsapp" target="_blank"><i class="fab fa-whatsapp"></i></a>
            <a *ngIf="facebookLink.link != '' && facebookLink.isShow" href="{{facebookLink.link}}" class="facebook" target="_blank"><i class="fab fa-facebook"></i></a>
            <a *ngIf="instagramLink.link != '' && instagramLink.isShow" href="{{instagramLink.link}}" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a>
            <a *ngIf="yelpLink.link != '' && yelpLink.isShow" href="{{yelpLink.link}}" class="yelp" target="_blank"><i class="fab fa-yelp"></i></a>
            <a *ngIf="twitterLink.link != '' && twitterLink.isShow" href="{{twitterLink.link}}" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a>
        </div>
        <div class="links">
            <a (click)="jumpToSection('home')" class="btn" *ngIf="websiteObj.header?.isShow">Home</a>
            <a (click)="jumpToSection('services')" class="btn" *ngIf="websiteObj.services?.isShow">Services</a>
            <a (click)="jumpToSection('projects')" class="btn" *ngIf="websiteObj.someWorks?.isShow && websiteObj.someWorks.images.length  >0">Some Works</a>
            <a (click)="jumpToSection('pricing')" class="btn" *ngIf="websiteObj.pricing?.isShow">Express Services</a>
            <a (click)="jumpToSection('addressMap')" class="btn" *ngIf="websiteObj.addressMap?.isShow">Hours & Map</a>
            <a (click)="jumpToSection('contact')" class="btn" *ngIf="websiteObj.contactAddress?.isShow">Contact</a>
            <a (click)="jumpToSection('about')" class="btn" *ngIf="websiteObj.about?.isShow">About</a>
        </div>
        <div class="credit"> All Rights Reserved! © {{valueDate | date: 'yyyy'}}
            <span class="redText">{{getFirstWord()}}</span>
            <span [ngClass]="{'blackText': scrolled}">{{companyInfo.companyName.substr(getFirstWord().length)}}</span>
        </div>
    </section> -->

    <section class="footer">
        <div class="footer-blocks">
            <div class="footer-block" *ngIf="websiteObj.addressMap?.isShow">
                <p>Opening Times</p>
                <ul class="opening-times">
                    <li *ngFor="let day of this.websiteObj.addressMap?.days">
                        <div class="open-day">
                            <span class="day-of-week">{{day.name}}
                            </span>
                            <span class="time-of-day">
                                <span *ngIf="day.isOpen">{{ getTime(day.start)  }} - {{ getTime(day.end) }}</span>
                            <span class="required " *ngIf="!day.isOpen"> Closed!</span>
                            </span>

                        </div>
                    </li>
                </ul>
            </div>
            <div class="footer-block">
                <p>Contact Info</p>
                <ul>
                    <li *ngIf="websiteObj?.contactAddress?.phoneEmailObj?.email">
                        <div class="contact-item">
                            <label>Phone Number </label>
                            <a href="tel:{{websiteObj.contactAddress.phoneEmailObj.phone}}">
                                <span class="icon-contact">
                                  <i class="fas fa-phone"></i>
                                </span>
                                <span>{{websiteObj.contactAddress.phoneEmailObj.phone}}</span>
                            </a>
                        </div>
                    </li>
                    <li *ngIf="websiteObj?.contactAddress?.phoneEmailObj?.phone">
                        <div class="contact-item">
                            <label>E-Mail Us </label>
                            <a href="mailto:{{websiteObj.contactAddress.phoneEmailObj.email}}">
                                <span class="icon-contact">
                                  <i class="far fa-envelope-open"></i>
                              </span>
                                <span>{{websiteObj.contactAddress.phoneEmailObj.email}}</span>
                            </a>
                        </div>
                    </li>
                    <li *ngIf="websiteObj?.contactAddress?.phoneEmailObj?.address">
                        <div class="contact-item">
                            <label>Our Address </label>
                            <a (click)="showPosition()">
                                <span class="icon-contact">
                                  <i class="fas fa-map-marker-alt"></i>
                              </span>
                                <span>{{websiteObj.contactAddress.phoneEmailObj.address}}</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="footer-block" *ngIf="this.websiteObj.socialMedia?.isShow">
                <p>Follow us on</p>
                <ul>
                    <li *ngIf="whatsappLink.link != '' && whatsappLink.isShow">
                        <a href="{{whatsappLink.link}}" class="whatsapp" target="_blank">
                            <span class="icon-social"><i class="fab fa-whatsapp"></i></span>
                            <span>whatsapp</span>
                        </a>
                    </li>
                    <li *ngIf="facebookLink.link != '' && facebookLink.isShow">
                        <a href="{{facebookLink.link}}" class="facebook" target="_blank">
                            <span class="icon-social">  <i class="fab fa-facebook-f"></i></span>
                            <span>facebook</span></a>
                    </li>
                    <li *ngIf="instagramLink.link != '' && instagramLink.isShow">
                        <a href="{{instagramLink.link}}" class="instagram" target="_blank">
                            <span class="icon-social"><i class="fab fa-instagram"></i></span>
                            <span>instagram</span></a>
                    </li>
                    <li *ngIf="yelpLink.link != '' && yelpLink.isShow">
                        <a href="{{yelpLink.link}}" class="yelp" target="_blank">
                            <span class="icon-social"><i class="fab fa-yelp"></i></span><span>yelp</span></a>
                    </li>
                    <li *ngIf="twitterLink.link != '' && twitterLink.isShow">
                        <a href="{{twitterLink.link}}" class="twitter" target="_blank">
                            <span class="icon-social">
                              <img src="./assets/car-wash/car_wash_images/twitter.png" alt="twitter-img">
                            </span>
                            <span>twitter</span></a>
                    </li>
                </ul>
            </div>
            <div class="footer-block">
                <p>Quick Links</p>
                <ul>
                    <li>
                        <a (click)="jumpToSection('home')" *ngIf="websiteObj.header?.isShow"> <span><i class="fas fa-long-arrow-alt-right"></i>Home</span></a>
                    </li>
                    <li>
                        <a (click)="jumpToSection('pricing')" *ngIf="websiteObj.pricing?.isShow">
                            <span><i class="fas fa-long-arrow-alt-right"></i> Express Services</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="jumpToSection('services')" *ngIf="websiteObj.services?.isShow">
                            <span><i class="fas fa-long-arrow-alt-right"></i> Our Services</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="jumpToSection('projects')" *ngIf="websiteObj.someWorks?.isShow && websiteObj.someWorks.images.length  >0">
                            <span><i class="fas fa-long-arrow-alt-right"></i> Some Works</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="jumpToSection('contact')" *ngIf="websiteObj.addressMap?.isShow">
                            <span><i class="fas fa-long-arrow-alt-right"></i> Contact</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="jumpToSection('about')" *ngIf="websiteObj.about?.isShow">
                            <span><i class="fas fa-long-arrow-alt-right"></i> About</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div class="footer-block">
                <div class="map">
                    <iframe class="map" [src]="urlMap" width="100%" height="100%" borderRaduis="20px" allowfullscreen="" loading="lazy">
                </iframe>
                </div>
            </div> -->
        </div>
        <hr>
        <div class="copy-rights">
            <span>Copyright {{valueDate | date: 'yyyy'}} © <span >{{companyInfo.companyName}}</span> </span>
            <!-- <span>  All rights reserved. Privacy Policy</span> -->
        </div>
    </section>
</div>
<!-- footer section ends -->

<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/lightgallery-js/1.4.0/js/lightgallery.min.js"></script> -->

<!-- <script src="https://unpkg.com/swiper@7/swiper-bundle.min.js"></script> -->

<!-- <script src="./assets/car-wash/js/script1.js"></script> -->


<!-- <script>
      lightGallery(document.querySelector('.projects .box-container'));
  </script> -->
<!-- </body> -->

<!-- </html> -->
<div mdbModal #serviceModal="mdbModal" class="modal website-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6>{{service.serviceName}} Details</h6>
                    <button type="button" class="close" (click)="serviceModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-12">
                            <div class="description-package" *ngIf="service.serviceDesc">
                                <!-- <p>Description</p> -->
                                <span>{{service.serviceDesc}}</span>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <!-- <h6>Sizes</h6> -->
                            <ul class="list-group">
                                <li *ngFor="let item of service.sizes; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                    {{item['size' + i ]}}
                                    <span class="badge bg-primary rounded-pill">${{item['price' + i ]}}</span>
                                </li>
                            </ul>
                            <ul class="list-group mt-1" *ngIf="service.perks?.length > 0">
                                <li *ngFor="let item of service.perks; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                    <span *ngIf="item['perk' + i ] != ''"><i class="uil uil-check-circle pr-2"></i> {{item['perk' + i ]}}</span>
                                    <span *ngIf="item['perk' + i ] == ''">No Data</span>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="col-lg-6" *ngIf="service.perks?.length > 0">
                            <ul class="list-group">
                                <li *ngFor="let item of service.perks; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                    <span *ngIf="item['perk' + i ] != ''"><i class="uil uil-check-circle pr-2"></i> {{item['perk' + i ]}}</span>
                                    <span *ngIf="item['perk' + i ] == ''">No Data</span>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #productModal="mdbModal" class="modal website-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6>{{bufferProduct.name}} Details</h6>
                    <button type="button" class="close" (click)="productModal.hide()" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body ">
                    <div class="row">
                        <div class="col-12">
                            <div class="description-package" *ngIf="bufferProduct.description">
                                <!-- <p>Description</p> -->
                                <span>{{bufferProduct.description}}</span>
                            </div>

                        </div>
                        <div class="col-lg-12" *ngIf="bufferProduct.customDescription?.length > 0">
                            <ul class="list-group">
                                <span *ngFor="let item of bufferProduct.customDescription; let i=index">
                                <li *ngIf="item.description != ''" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                  <span ><i class="uil uil-check-circle pr-2"></i> {{item.description}}</span>
                                <!-- <span *ngIf="item.description == ''">No Description</span> -->
                                </li>
                                </span>
                            </ul>
                        </div>
                        <!-- <div class="mx-auto">
                            <div class="btn btn-sm btn-primary" (click)="subscribeCustomer()">Subscribe</div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>