import { AuthService } from '../_services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class Auth2Guard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService,
    private route: ActivatedRoute,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.route.queryParams.subscribe(params =>{
      let  isMobilePrint = params['isAccess']
      if(isMobilePrint != undefined && isMobilePrint == true){
        return true
      }
      else{
        const user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
          if (user.role != null || user.role != undefined) {
            if (user.role == "superAdmin") {
              this.router.navigate(['/super-company']);
              return true
            }
            else if (user.role == "admin" || user.role == "manager") {
              this.router.navigate(['/admin']);
              return true;
            }
            else if (user.role != "admin" && user.role != "manager") {
              this.router.navigate(['/user']);
              return true;
            }
          }
          else {
            this.router.navigate(['/Login']);
          }
        }
        else if (user == null || user == undefined) {
          this.router.navigate(['/Login']);
          return true
        }
      }
    })
    return true
  }
}
