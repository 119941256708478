import { SuppliesService } from './../_services/supplies/supplies.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class SuppliesResolver implements Resolve<any[]>
{
  constructor(private _suppliesService: SuppliesService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this._suppliesService.getAllSuppliesByJob(route.params['id']).pipe(
      catchError(error => {
        console.log(error)
        return of(null);
      })
    );
  }
}
