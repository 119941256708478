import { environment } from 'src/environments/environment.prod';
import { LoaderService } from './core/_services/loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { CompaniesService } from './core/_services/companies/companies.service';
import { ChatService } from './core/_services/chat-Service/chat.service';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from './models/realtime/RealTimeKeys';
import { CompanyStatus, UpdateCompany } from './models/company/company';
import { GlobalService } from './core/_services/global/global.service';
import { App } from '@capacitor/app';
import { Title } from '@angular/platform-browser';

declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Accordev';
  todos: any = [];
  loading: boolean = false;
  newMessage: string;
  messageList: any;
  user: any = {};
  isInvite: any = false;
  content_visibility = 'hidden'
  isOnline = environment.isOnline
  favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
  constructor(
    private _companiesService: CompaniesService,
    private router: Router,
    public _loaderService: LoaderService,
    private route: ActivatedRoute,
    private socket: Socket,
    private _globalService: GlobalService,
    private titleService:Title
  ) {}
  ngOnInit() {
    this.title =  this.isOnline ? environment.Online_Tittle : environment.Test_Tittle
    // document.getElementById('appFavicon').setAttribute('href', '/car-service.ico');
    this.favIcon.href = './favicon.ico';
    this.titleService.setTitle( this.title);
    localStorage.setItem('country', 'US');
    localStorage.setItem('isMap', 'false');
    // this._companiesService.getIpInfo()
    this.user = JSON.parse(localStorage.getItem('user'));
    // console.log('user',this.user)
    this.socket.on(RealTimeKeys.Company_Update_Force, (data: any) => {
      console.log('data', data);
      if (this.user.companyId == data.id) {
        console.log('aftar compare id', this.user.companyId);
        if (data.type == UpdateCompany.plan) {
          console.log('aftar compare plan');
          this.user.companyPlan = data.value;
          this.user.companyPlanType = data.value2;
          localStorage.setItem('user', JSON.stringify(this.user));
          window.location.reload();
        } else if (data.type == UpdateCompany.status) {
          console.log('aftar compare status');
          if (data.value == CompanyStatus.Deactivated) {
            this.router.navigate(['/deactivated-company']);
          }
          if (data.value == CompanyStatus.Canceled) {
            this.router.navigate(['/disabled-company']);
          }
          if (data.value == CompanyStatus.NotPay) {
            this.router.navigate(['/not-paid']);
          }
          if (data.value == CompanyStatus.NotPayPeriod) {
            this.router.navigate(['/not-period']);
          }
        }
      }
    });
    this.socket.on(RealTimeKeys.Company_Update_Category, (data: any) => {
      if (this.user.companyId == data.id) {
        this.user.categoryCompany = data.categoryCompany;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
      }
    });
    //  if(this.user && this.user.role == "admin" || this.user && this.user.role == "manager"  ){
    //     this.router.navigate(['/a'])
    //  }
    //  this.route.queryParams.subscribe(params => {
    //   this.isInvite = params.isInvite;
    //   console.log('this is invite',params)
    // });
    //  this.user = JSON.parse(localStorage.getItem('user'));
    //   console.log('this is user',this.user)
    //   if (this.user == null && this.isInvite == undefined) {
    //     this.router.navigate(['/login'])
    //   }
    this._companiesService.getIpInfo()
  }
  scrollTop() {
    document.documentElement.scrollTop = 0;
  }
  async setupListener() {
    App.addListener('appStateChange', ({ isActive }) => {
      if (!isActive) {
        // App went to background
        // Save anything you fear might be lost
      } else {
        // App went to foreground
        // restart things like sound playing
      }
    });
    App.addListener('backButton', (data) => {
      console.log('back button click:', JSON.stringify(data));
      if (data.canGoBack) {
        window.history.back();
      } else {
        // Maybe show alert before closing app?
        App.exitApp();
      }
    });
  }
}
