<div class="container-fluid">
    <h1>Accordev</h1>
    <div class="tittle">
        <p>Finish signing up</p>
    </div>
    <div class="second">
        <strong>
          Please finish signing up, in order to be able to access your Accordev account:
          </strong>
        <div class="first">
            <p>{{companyInfo.companyName}}</p>
            <p>
                {{companyInfo.companyAddress?.addressOne}},<span *ngIf="companyInfo.companyAddress?.addressTow"> {{companyInfo.companyAddress?.addressTow}}, </span> {{companyInfo.companyAddress?.country.name}}, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}}
                {{companyInfo.companyAddress?.zip}}
            </p>
        </div>
        <!-- <strong> waiting Pay .</strong> -->
    </div>
    <!-- <div class="third">
        <p> please Pay a invoice:</p>
        <strong>Kind Regards, Accordev Team</strong>
    </div> -->
    <div class="request-form" *ngIf="companyInfo.companyPlan != planCompanyType.BASIC">
        <a class="btn btn-primary" href="{{companyInfo.url}}" target="_blank">Add Payment Method</a>
    </div>
    <div class="request-form" *ngIf="companyInfo.companyPlan == planCompanyType.BASIC">
        <a class="btn btn-primary" href="{{companyInfo.url}}" target="_blank">Add a Credit Card</a><br>
        <small>Accordev only uses this information to verify your identity. Your card won’t be charged, and you’re not sharing balance or other financial information.</small>
    </div>
</div>
