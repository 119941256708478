<div class="contianer sign-up-mode">
    <div class="forms-container">
        <div class="signin-signup">
            <!-- sign in form -->
            <form [formGroup]="signInFormGroup" (submit)="SignIn()" class="sign-in-form">
                <h2 class="title text-uppercase">Login1</h2>
                <div class="input-filed">
                    <i class="fas fa-user"></i>
                    <!-- <input type="text" *ngIf="emailUser" readonly name="emailUser" value="{{emailUser}}"> -->
                    <input type="text" placeholder="Email" formControlName="email">
                    <div *ngIf="ff.email.touched && ff.email.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="ff.email.errors.required">User email is required</div>
                        <div *ngIf="ff.email.errors.pattern">User email not valid</div>
                    </div>
                </div>
                <div class="input-filed">
                    <i class="fas fa-eye" *ngIf="showSignIn" (click)="passwordSignIn()"></i>
                    <i class="fas fa-eye-slash" *ngIf="!showSignIn" (click)="passwordSignIn()"></i>
                    <input [type]="showSignIn ? 'text' : 'password'" placeholder="Password" formControlName="password">
                    <div *ngIf="ff.password.touched && ff.password.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="ff.password.errors.required">Password is required </div>
                    </div>
                </div>
                <div class="forget-password">
                    <a>Forgot my password</a>
                </div>
                <button type="submit" [disabled]="signInFormGroup.invalid" class="btn mt-2 btn-solid">Login </button>
                <footer>
                    <div>
                        <p>By continuing, you agree to Accordev's <strong class="text-blue"> Terms of Service </strong> and acknowledge you've read our <strong class="text-blue">  Privacy Policy</strong></p>
                    </div>
                    <a href="https://accordev.com/contact" target="_blank" type="button" class="btn btn-sm btn-primary">Help</a>
                </footer>
            </form>
            <!-- Sign Up Form -->
            <form [formGroup]="registerFormGroup" (submit)="SignUp()" class="sign-up-form">
                <h2 class="title mt-3">Sign up</h2>
                <mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]="3">
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>User Information</ng-template>
                        <div class="input-filed">
                            <i class="fas fa-user"></i>
                            <input type="text" placeholder="First Name" readonly formControlName="firstName">
                            <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.firstName.errors.required">firstName is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-user"></i>
                            <input type="text" placeholder="Last Name" readonly formControlName="lastName">
                            <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.lastName.errors.required">LastName is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-envelope"></i>
                            <input type="email" placeholder="User Email (For Logging In Later)" readonly formControlName="email" [ngClass]="{'is-invalid' : f.email.errors && f.email.touched}">
                            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.email.errors.required">email is required</div>
                                <div *ngIf="f.email.errors.invalid">{{f.email.errors.mes}}</div>
                                <div *ngIf="f.email.errors.pattern">Email not valid</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-phone-square"></i>
                            <input type="text" placeholder="User Phone" readonly formControlName="phone">
                            <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.phone.errors.required">phone is required</div>
                                <div *ngIf="f.phone.errors.pattern">Phone not valid must only number.</div>
                            </div>
                        </div>
                        <!-- <div class="input-filed">
                            <i class="fas fa-lock"></i>
                            <input [type]="showSignUp ? 'text' : 'password'" placeholder="Password" formControlName="passwrod">
                            <div *ngIf="f.passwrod.touched && f.passwrod.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.passwrod.errors.required">passwrod is required</div>
                                <div *ngIf="f.passwrod.errors.pattern"> must be at least 6 characters and should contain atleast one number and one special character and capital letter</div>
                            </div>
                        </div> -->
                        <!-- <div class="input-filed">
                            <i class="fas fa-lock"></i>
                            <input [type]="showSignUp ? 'text' : 'password'" id="myInput" placeholder="Confirm Password" formControlName="confirmPassword">
                            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.confirmPassword.errors.required">confirm password required</div>
                                <div *ngIf="f.confirmPassword.errors.pattern">must be at least 6 characters and should contain atleast one number and one special character and capital letter</div>
                            </div>
                            <div class="alert  alert-danger" *ngIf="f.confirmPassword.touched &&registerFormGroup.hasError('mismatch')">
                                Password is Mismatch</div>
                        </div> -->
                        <!-- <div class="check-password">
                            <mat-checkbox class="example-margin" (click)="passwordSignUp()"><span>Show Password</span></mat-checkbox>
                        </div> -->
                        <button matStepperNext type="button" mdbBtn color="success">Next</button>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>Company Information</ng-template>
                        <div class="input-filed">
                            <i class="fas fa-building"></i>
                            <input type="text" placeholder="Company Name" readonly formControlName="companyName">
                            <div *ngIf="f.companyName.touched && f.companyName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyName.errors.required">Company Name is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-building"></i>
                            <input type="text" placeholder="Company phone" readonly formControlName="companyPhone">
                            <div *ngIf="f.companyPhone.touched && f.companyPhone.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyPhone.errors.required">Company Phone is required</div>
                                <div *ngIf="f.companyPhone.errors.pattern">Company Phone not valid must only number.</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-envelope"></i>
                            <input type="text" placeholder="Company Email" readonly formControlName="companyEmail">
                            <div *ngIf="f.companyEmail.touched && f.companyEmail.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyEmail.errors.required">Company Email is required</div>
                                <div *ngIf="f.companyEmail.errors.pattern">Company Email not valid</div>
                                <!-- <div *ngIf="f.companyEmail.errors.invalid">Company E-mail exists please enter another Email</div> -->
                            </div>
                            <!-- <div *ngIf="f.companyEmail.invalid" class="alert alert-danger" role="alert">
                              <div *ngIf="f.companyEmail.errors.required">Company E-mail exists please enter another Email</div>
                          </div> -->
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-globe"></i>
                            <input type="text" placeholder="Website" readonly formControlName="website">
                        </div>
                        <div>
                            <button matStepperPrevious type="button" mdbBtn color="primary">Back</button>
                            <button matStepperNext type="button" mdbBtn color="success">Next</button>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>Company Address</ng-template>
                        <div class="input-filed">
                            <i class="fas fa-address-book"></i>
                            <input type="text" placeholder="Address 1" readonly formControlName="addressOne">
                            <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.addressOne.errors.required">address is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-address-book"></i>
                            <input type="text" placeholder="Address 2" readonly formControlName="addressTow">
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-city"></i>
                            <input type="text" placeholder="City" readonly formControlName="city">
                            <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.city.errors.required">city is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="far fa-building"></i>
                            <input type="text" placeholder="State" readonly formControlName="state">
                            <div *ngIf="f.state.touched && f.state.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.state.errors.required">state is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-address-book"></i>
                            <input type="text" placeholder="Zip Code" readonly formControlName="zip">
                            <div *ngIf="f.zip.touched && f.zip.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.zip.errors.required">zip is required</div>
                                <div *ngIf="f.zip.errors.pattern">Zip Code not valid must only number.</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-flag-usa"></i>
                            <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" formControlName="country">
                            </mat-select-country>
                            <div *ngIf="f.country.touched && f.country.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.country.errors.required">country is required</div>
                            </div>
                        </div>
                        <div>
                            <button matStepperPrevious type="button" mdbBtn color="primary">Back</button>
                            <button matStepperNext type="button" mdbBtn color="success">Next</button>
                            <!-- <button type="button" mdbBtn color="danger" [disabled]="registerFormGroup.invalid" (click)="stepper.reset()">Reset</button> -->
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <div *ngIf="companyObjInfo.companyPlan != planCompanyType.BASIC">
                            <ng-template matStepLabel>Payment</ng-template>
                            <div (click)="openLinkInIphone()">
                                <a class="btn  btn-primary">Add Payment Method</a>
                            </div>
                        </div>
                        <div *ngIf="companyObjInfo.companyPlan == planCompanyType.BASIC">
                            <ng-template matStepLabel>Verify Identity</ng-template>
                            <div (click)="openLinkInIphone()">
                                <a class="btn  btn-primary">Add a Credit card</a> <br>
                                <small>Accordev only uses  your card information to verify your identity. Your card won’t be charged, and you’re not sharing balance or other financial information.</small>
                            </div>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </form>
        </div>
    </div>
    <div class="panels-container">
        <div class="panel left-panel">
            <div class="content">
                <div *ngIf="isCarWash">
                    <h2 class="tittle-carwash"><strong>Car Wash Mobile & Location</strong></h2>
                    <label>By Accordev </label>
                </div>
                <div *ngIf="!isCarWash">
                    <h2 class="mb-1"><strong>Accordev</strong></h2>
                    <label>The Business Software Re-Invented </label>
                </div>
                <p>
                    <small>Version 4.1.1</small>
                    <br>
                    <small>Updated on  07.16.22</small>
                </p>
                <h5></h5>
                <div (click)="openWebsite()">
                    <a class="btn transparent" id="sign-up-btn">Create a busniess <strong>account</strong></a>
                </div>
                <p>
                    If you are a customer or a 3rd party, ask your Service/Product Provider for an Accordev invitation link.
                </p>
            </div>
            <img src="./assets/img/log.svg" class="image" alt="login-img">
        </div>
        <div class="panel right-panel">
            <div class="content">
                <h3>One of us ?</h3>
                <p>
                    Thank you for being our valued customer. We are so grateful for the pleasure of serving you and hope we met your expectations.
                </p>
                <!-- <button class="btn transparent" id="sign-in-btn" (click)="SignInMode()">Log in</button> -->
            </div>
            <img src="./assets/img/register.svg" class="image" alt="register-img">
        </div>
    </div>
</div>
<div mdbModal id="warningOpenWebsiteModal" #warningOpenWebsiteModal="mdbModal" class="modal fade">
    <div class="modal-dialog">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Create a busniess account </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="warningOpenWebsiteModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-2">Go to Accordev.com to sign up as a business.</p>
                </div>
            </div>
        </div>
    </div>
</div>