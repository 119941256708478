import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disable-customer',
  templateUrl: './disable-customer.component.html',
  styleUrls: ['./disable-customer.component.scss']
})
export class DisableCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
