import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgxPrintElementService } from 'ngx-print-element';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment.prod';
import { Browser } from '@capacitor/browser';

// declare var $;
@Component({
  selector: 'app-all-inventories-print',
  templateUrl: './all-inventories-print.component.html',
  styleUrls: ['./all-inventories-print.component.scss']
})
export class AllInventoriesPrintComponent implements OnInit {
  @Input() objFromParent3: any = {};
  baseUrl = environment.baseDomain
  constructor(
    public print: NgxPrintElementService,
    private deviceService: DeviceDetectorService
  ) {
  }

  ngOnInit() {

    // this.jobNumber = this.objFromParent3.inventories[0].jobNumber

  }
  // printAll(divName) {
  //   let printContents: string = document.getElementById(divName).id;
  //   let innerContents = document.getElementById(divName).innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }
  printElment() {
    let printContents = document.getElementById('all-inventories').id;
    this.print.print(printContents, { printMode: 'template', pageTitle: 'Hello World' })
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
  }
  printFixed() {
      let printContents = document.getElementById('all-inventories').innerHTML;
      let popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>print</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
          <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
          <style>
          .print {
            text-align: center;
        }
        body {
          page-break-after: always;
        }
        .print .btn {
            text-transform: capitalize;
        }

        .job-summary .table {
            text-align: center;
        }

        .job-summary .table thead tr th {
            background: #c1c1cc;
            border: 1px solid #e9e9eb;
        }

        .job-summary .table tbody tr td {
            border: 1px solid #e9e9eb;
        }

        .row .col-12 {
            margin: 7px auto;
        }

        .row .col-12 .first-row-header {
            display: flex;
            justify-content: space-between;
            background: #e5e5e5e5;
            padding: 5px;
            border: 1px solid #e5e5e5e5;
        }

        .row .col-12 .tow-row-header,
        .row .col-12 .three-row-header {
            display: flex;
            justify-content: left;
            background: #e5e5e5e5;
            padding: 5px;
            border: 1px solid #a5a5a5;
        }

        .row .col-12 .tow-row,
        .row .col-12 .three-row {
            display: flex;
            justify-content: left;
            padding: 5px;
            border: 1px solid #a5a5a5;
            background-color: transparent;
        }

        .row .item-person {
            display: flex;
            padding: 10px;
            background: #e9e9eb;
            align-items: baseline;
            margin: 5px auto;
        }

        .row .item-person label,
        .row .item-person h6 {
            margin: 0;
        }

        .row .item-person h6 {
            padding-left: 5px;
        }

        .signature .table tbody td {
            border: 1px solid #c1c1c1 !important;
        }

        .signature .table tbody .img-sign {
            text-align: center;
        }

        .signature .table tbody .img-sign img {
            width: 100px;
        }

        .signature .table tbody .status-date {
            display: flex;
            justify-content: space-between;
        }

        .signature .table tbody .last {
            height: 11px;
        }

        .tittle h6 {
            padding: 0.5rem;
            text-align: center;
            background: #c1c1cc;
            margin: 10px auto;
        }
        .back{
          text-align:center;
          margin:auto;
        }
        @media print {
          .page-break  { display:block; page-break-aftar:always; }

          .btn-sm{
            display:none;
          }
        }
          </style>
        </head>
        <body onload="window.print()">
        <div class="back">
        <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
        </div>
    ${printContents}</body>
      </html>`
      );
      popupWin.document.close();
  }
  getBody() {
    let tableDiv:any = document.getElementById('firstTable')
    // let textBody =`<h1>Dear ${this.lastName} , ${this.lastName}</h1>
    // <table>
    //    <tr><td>This is a table</td></tr>
    // </table>`
    // let htmlBody = this.divContainer.nativeElement.innerHtml;
  //  let  tableDiv :HTMLTableElement
    // var tbl = document.createElement('table');
    // tbl.style.border = "1px solid black";
    // for(var i = 0; i < 5; i++)
    // {
    //     var tr = tbl.insertRow();
    //     for(var j = 0; j < 9; j++)
    //     {
    //         var td = tr.insertCell();
    //     }
    // }
    // tableDiv.append("<br>");
    // tableDiv.append(tbl);

  // return "mailto:" + encodeURIComponent('omar@maplly.com') + "?subject=" + encodeURIComponent('omar') + "&body=" + encodeURIComponent(tableDiv.toString);

    return tableDiv
  }
}
