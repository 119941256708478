import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { JobsService } from '../_services/jobs/jobs.service';

@Injectable()

export class JobsResolver implements Resolve<any[]>
{
  constructor(private _jobsService: JobsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
   let id  = !route.params['id'] ? JSON.parse(localStorage.getItem('user')).companyId : route.params['id']
    return this._jobsService.getJobs(id).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
