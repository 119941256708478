import { ToasterCustomService } from './../../../../core/_services/toaster-service/ToasterCustomService.service';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { environment } from 'src/environments/environment.prod';
declare var $:any
@Component({
  selector: 'app-invite-customer-car-wash',
  templateUrl: './invite-customer-car-wash.component.html',
  styleUrls: ['./invite-customer-car-wash.component.scss']
})
export class InviteCustomerCarWashComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  emailCustomer = ''
  phoneCustomer = ''
  companyId = ''
  emailPattern = ValidationModel.emailPattern
  companyInfo:any={}
  baseDomain = environment.baseDomain
  baseWordpressUrl = environment.baseWordpressUrl
  compilationId =[]
  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private _personService: PersonService,
    private _companiesService: CompaniesService,
    private toaster: ToasterCustomService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.compilationId = this.route.snapshot.paramMap.get('id').split('-');
    this.companyInfoFroInvite()
  }
  companyInfoFroInvite(){
    this._companiesService.companyInfoFroInvite(this.compilationId[0],this.compilationId[1]).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.companyInfo = response.data
        $('#myModal').modal({
          backdrop: 'static',
          keyboard: false,
          show: true
         })
      }
    })
  }
  InviteUser() {
    this.emailCustomer = this.getEmailWithoutSpace(this.emailCustomer)
    let model:any = {}
    model.type = 'customer'
    model.role = 'customer'
    model.date = new Date()
    model.dateFormate = this.datePipe.transform(new Date(), 'yyyy-d-M')
    model.timeFormate = this.datePipe.transform(new Date(), 'h:mm a')
    model.email = this.getEmailWithoutSpace(this.emailCustomer)
    model.phone = this.phoneCustomer
    // model.companyId = this.companyId
    model.companyNumber= Number(this.compilationId[0])
    model.signUpDateTimeStamp= Number(this.compilationId[1])
    // model.zip= Number(this.compilationId[2])
    console.log(model)
      this._personService.InviteCustomerPendingGlobal(model).subscribe((response: ResponseModel) => {
        if (response.success) {
          $('#myModal').hide()
          // $("#myModal").modal('show');
          // this.basicModal.show()
           $('#basicModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: true
          })
        }
        if(!response.success){
          this.toaster.error(`${model.email} E-mail already exists, please enter another Email.`,'')
        }
      }, error => {
        console.log(error)
      })
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  goBack(){
    window.location.href = this.baseDomain;
  }
  routeToWordpress(){
    !this.companyInfo.companyWebsite ?
     window.location.href = this.baseWordpressUrl :
     window.location.href = this.companyInfo.companyWebsite
  }
}
