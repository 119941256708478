<div class="customers">
    <div class="card">
        <div class="tittle-section">
            <p> Customers</p>
            <div class=" btn-add-new" *ngIf="customerObj.roleStatus == 'active'|| role == 'admin' ">
                <span class="badge badge-success mr-2" (click)="invite()">
                <i class="fas fa-paper-plane mr-1"></i> Invite
             </span>
                <span class="badge badge-success" (click)="inviteManualCustomer()">
                  <i class="far fa-plus-square pr-1"></i> Add Manually
               </span>
            </div>
        </div>
        <!-- <h4><span>Customers </span></h4> -->
        <mat-tab-group [disableRipple]="false">
            <mat-tab label="Customers ({{customers?.length}})">
                <div class="row" *ngIf="customers.length > 0">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" id="search-text" class="form-control border-serach" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search Customer Name" />
                        </div>
                    </div>
                    <div class="col-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Car_Wash ">Plate Number</th>
                                    <th scope="col">Job(s)</th>
                                    <th scope="col">Customer Status</th>
                                    <th scope="col">Invitation Status</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of customers  | appFilter: searchText | paginate: {id: 'first' , itemsPerPage: 3, currentPage: p }; let i=index ">
                                <tr>
                                    <th scope="row" class="name text-blue">
                                        <span class="badge badge-light" *ngIf="customerObj.roleStatus == 'active'|| role == 'admin' " (click)="routeCustomerDetails(item)">
                                          <storng *ngIf="item.lastName && item.firstName">{{item.lastName}}, {{item.firstName}}</storng>
                                        <storng *ngIf="!item.lastName || !item.firstName">No Name</storng>
                                        </span>
                                        <span class="badge badge-light" *ngIf="customerObj.roleStatus == 'readOnly' ">
                                          <storng *ngIf="item.lastName && item.firstName">{{item.lastName}}, {{item.firstName}}</storng>
                                        <storng *ngIf="!item.lastName || !item.firstName">No Name</storng>
                                        </span>
                                    </th>
                                    <td *ngIf="user.categoryCompany == categoryCompany.Car_Wash ">
                                        <span *ngIf="item.plateNumber">{{item.plateNumber}}</span>
                                        <button *ngIf="!item.plateNumber" class="btn btn-sm custom-success" (click)="addNewVehicle(item.id)"><i class="fas fa-plus-alt pr-1"></i> <i class="far fa-plus-square pr-1"></i> <span >Vehicle</span> </button>
                                    </td>
                                    <td>
                                        <span class="all-job badge badge-light text-blue" *ngIf="customerObj.roleStatus == 'active'|| role == 'admin' " (click)="getAllJobsByCustomers(item.id)">
                                          <small *ngIf="item.totalJobs">{{item.totalJobs}}</small>
                                          <small *ngIf="!item.totalJobs">0</small>
                                        </span>
                                        <span class="all-job badge badge-light text-blue" *ngIf="customerObj.roleStatus == 'readOnly' ">{{item.totalJobs}}</span>
                                    </td>
                                    <td *ngIf="item.uid">
                                        <select name="role" id="role" class="browser-default custom-select" *ngIf="customerObj.roleStatus == 'active'|| role == 'admin' " (change)="bufferStoreDisableId($event.target.value,item.id)">
                                        <option selected disabled *ngIf="item.disable == false">Active</option>
                                        <option selected disabled *ngIf="item.disable == true">Disabled</option>
                                        <option value="disable">Disabled</option>
                                        <option value="customer">Active</option>
                                     </select>
                                        <span *ngIf="customerObj.roleStatus == 'readOnly' ">
                                      <span *ngIf="item.disable == false">Active</span>
                                        <span *ngIf="item.disable == true">Active</span>
                                        </span>
                                    </td>
                                    <td *ngIf="!item.uid">
                                        <button class="btn-sm btn-danger btn-cancel" *ngIf="item.isInvited && customerObj.roleStatus == 'active' || item.isInvited && role == 'admin'" (click)="storeCancelPerson(item)"><i class="fas fa-paper-plane pr-1"></i> Cancel </button>
                                        <button class="btn-sm btn-danger btn-cancel" *ngIf="item.isInvited && customerObj.roleStatus == 'readOnly'" [disabled]="customerObj.roleStatus == 'readOnly'"><i class="fas fa-paper-plane pr-1"></i> Cancel </button>
                                        <button class="btn-canceled" *ngIf="item.isInvited == false && item.isCanceled == true && role != 'admin' "> <i class="fas fa-paper-plane pr-1"></i> Canceled </button>
                                        <span *ngIf="item.isInvited == false  && item.isCanceled == false ">
                                        <span *ngIf="!item.isAddFromLink">Added Manually</span>
                                        <span *ngIf="item.isAddFromLink">Self-Signed up</span>
                                        </span>
                                    </td>
                                    <td>
                                        <div *ngIf="customerObj.roleStatus == 'active' || role == 'admin' ">
                                            <button class="btn-sm btn-success" *ngIf="item.uid"><i class="fas fa-user-check pr-1"></i>
                                              <span *ngIf="item.isAddFromLink">Self-Signed up</span>
                                              <span *ngIf="!item.isAddFromLink">Signed up</span>
                                              </button>
                                            <button class="btn btn-sm" *ngIf="item.isInvited === true && !item.uid" (click)="buffStoreId(item.id,false)"><i class="fas fa-redo-alt pr-1"></i> Re-invite </button>
                                            <button class="btn btn-sm" *ngIf="item.isInvited === false && !item.uid" (click)="storeCustomerManually(item)"><i class="fas fa-paper-plane pr-1"></i> Invite </button>
                                        </div>
                                        <div *ngIf="customerObj.roleStatus == 'readOnly' ">
                                            <button class="btn-sm btn-success" *ngIf="item.uid"><i class="fas fa-user-check pr-1"></i><span *ngIf="item.isAddFromLink">Self-Signed up</span>
                                              <span *ngIf="!item.isAddFromLink">Signed up</span>
                                            </button>
                                            <button class="btn btn-sm" *ngIf="item.isInvited === true && !item.uid" [disabled]="true"><i class="fas fa-redo-alt pr-1"></i> Re-invite </button>
                                            <button class="btn btn-sm" *ngIf="item.isInvited === false && !item.uid" [disabled]="true"><i class="fas fa-paper-plane pr-1"></i> Invite </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-footer text-center ">
                            <pagination-controls previousLabel="" nextLabel="" id="first" (pageChange)="p=$event "></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="ant-empty-image" *ngIf="customers.length <= 0">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </mat-tab>
            <mat-tab label="Pending Invites ({{customersPending?.length}})">
                <div class="row" *ngIf="customersPending.length > 0">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="email" id="search-bending-text" class="form-control border-serach" aria-describedby="search-bending-text" (keydown)="restPaginationPending($event)" [(ngModel)]="searchBendingText" placeholder="Search Invitee Email" />
                        </div>
                    </div>
                    <div class="col-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status </th>
                                    <th scope="col">Cancel</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of customersPending | filterPending: searchBendingText | paginate: { id: 'second',  itemsPerPage: 3, currentPage: p2 }; let j=index ">
                                <tr>
                                    <th scope="row" class="name">{{item.email}}</th>
                                    <td *ngIf="countryCode === true">{{item.date | customDatePipe}}</td>
                                    <td *ngIf="countryCode === false">{{item.date | customDatePipe}}</td>
                                    <td>
                                        <button class="btn btn-sm " *ngIf="customerObj.roleStatus == 'active' || role == 'admin'" (click)="buffStoreId(item.id,true)"><i class="far fa-check-circle pr-1"></i> Re-Invite </button>
                                        <button class="btn btn-sm " *ngIf="customerObj.roleStatus == 'readOnly'" [disabled]="true"><i class="far fa-check-circle pr-1"></i> Re-Invite </button>
                                    </td>
                                    <td>
                                        <button class="btn-sm btn-danger" *ngIf="customerObj.roleStatus == 'active' || role == 'admin'" (click)="storId(item);deleteModal.show()"><i class="fas fa-paper-plane pr-1"></i> Cancel </button>
                                        <button class="btn-sm btn-danger" *ngIf="customerObj.roleStatus == 'readOnly'" [disabled]="true"><i class="fas fa-paper-plane pr-1"></i>Cancel </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="table-footer text-center ">
                            <pagination-controls previousLabel="" nextLabel="" id="second" (pageChange)="p2=$event "></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="ant-empty-image" *ngIf="customersPending.length <= 0">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<!-- Modal to display job details -->
<div mdbModal id="basicModal" #basicModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Jobs Summary</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <!-- <div *ngFor="let item of jobs">
                        <h6>{{item.jobName}}</h6>
                    </div> -->
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#Job</th>
                                <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let job of jobs | paginate: { id: 'third',itemsPerPage: 3, currentPage: p3 };" (click)="redirection(job)">
                                <th scope="row">
                                    <h6> {{job.jobNumber}}</h6>
                                    <hr>
                                    <h6> {{job.jobType}}</h6>
                                </th>
                                <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                    <h6>{{job.jobName}}</h6>
                                    <hr>
                                    <h6> Type Customer</h6>
                                </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                <!-- US Date formate -->
                                <td *ngIf="countryCode === true">
                                    <span class="start-window">{{job.jobDetailsDateTime.startDateWindowFrom | customDatePipe}} {{job.jobDetailsDateTime.startTimeWindowFrom }} </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | customDatePipe }} {{job.jobDetailsDateTime.endTimeWindowTo}} </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == false "> Days, Many Dates</span>
                                </td>
                                <!-- Other Country Date formate -->
                                <td *ngIf="countryCode === false">
                                    <span class="start-window">{{job.jobDetailsDateTime.startDateWindowFrom | customDatePipe}}
                             {{job.jobDetailsDateTime.startTimeWindowFrom }}
                        </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | customDatePipe }}
                           {{job.jobDetailsDateTime.endTimeWindowTo}}
                         </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == false "> Days, Many Dates</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-footer text-center ">
                        <pagination-controls id="third" (pageChange)="p3=$event "></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Customer Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="deleteModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <div class="icon-close">
                        <i class="far fa-times-circle fa-3x"></i>
                    </div>
                    <div class="text-center">
                        <p class="tittle">Cancel invitation? </p>
                        <!-- <p class="text-secondary"><small>You can invite him\her anytime late.</small></p> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-sm btn-danger" (click)="deleteModal.hide()">Cancel</button> -->
                    <button type="button" class="btn btn-sm btn-danger" (click)="cancelPersonPending(itemCustomer);deleteModal.hide() ">Cancel </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Customer Modal -->
<div mdbModal #cancelCustomerModal="mdbModal" class="modal delete">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="cancelCustomerModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body">
                    <div class="icon-close">
                        <i class="far fa-times-circle fa-3x"></i>
                    </div>
                    <div class="text-center">
                        <p class="tittle">Cancel invitation? </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" (click)="cancelPerson(cancelPersonItem);cancelCustomerModal.hide() ">Cancel </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reinvite Customer Modal -->
<div mdbModal #reInviteCustomerModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="reInviteCustomerModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body">

                    <div class="text-center">
                        <p class="tittle">Re-Invite Customer? </p>
                        <!-- <p class="text-secondary"><small>You can invite him\her anytime late.</small></p> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" (click)="reInviteCustomerModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success" *ngIf="!isPending" (click)="ReInvitePerson(buffId);reInviteCustomerModal.hide() ">Yes </button>
                    <button type="button" class="btn btn-sm btn-success" *ngIf="isPending" (click)="ReInvitePersonPending(buffId);reInviteCustomerModal.hide() ">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Disable Customer Modal -->
<div mdbModal #disableCustomerModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="disableCustomerModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body">

                    <div class="text-center">
                        <p class="tittle">Change Customer Status? </p>
                        <!-- <p class="text-secondary"><small>You can invite him\her anytime late.</small></p> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" (click)="disableCustomerModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success" (click)="disableUser(bufferDisable,bufferCustomerId);disableCustomerModal.hide() ">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Disable Customer Modal -->
<div mdbModal #inviteCustomerManuallyModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Customer profile information could be changed</h6>
                    <button type="button" class="close" (click)="inviteCustomerManuallyModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>If customer is invited, the profile information will be replaced with the information filled by the customer when he/she signed up for an Accordev account. </p>
                        <!-- <p class="text-secondary"><small>You can invite him\her anytime late.</small></p> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" (click)="inviteCustomerManuallyModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success" (click)="InviteCustomer(customerManuallyObj);inviteCustomerManuallyModal.hide() ">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>