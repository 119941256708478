<div class="text-center">
    <button class="btn btn-sm btn-primary" (click)="goBack()">Back</button>
</div>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Item Tag 1 </p>
            <div class="title-info">
                <span> Job Number, Item Number, Item Name, Qty</span>
            </div>
        </mat-expansion-panel-header>
        <div class="row pagebreak " id="print-one">
            <div class="col-12 text-center">
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Number</label>
                    <h6>{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Item Tag 2 </p>
            <div class="title-info">
                <span>Job Number, Item Number, Weight , Quantity </span>
            </div>
        </mat-expansion-panel-header>

        <div class="row" id="print-tow">
            <div class="col-12 text-center">

            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Number</label>
                    <h6>{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Weight</label>
                    <h6 *ngIf="objFromParent.weight">{{objFromParent.weight}}</h6>
                    <h6 *ngIf="!objFromParent.weight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 1</p>
            <div class="title-info">
                <span>Job Number,Item Name , Category, Quantity </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-three">
            <div class="col-12 text-center">

            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Category</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 2</p>
            <div class="title-info">
                <span>Job.N,Item.N , Customer.N, Cat </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-four">
            <div class="col-12 text-center">

            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Customer Name</label>
                    <h6 *ngIf="objFromParent.primaryCustomer">{{objFromParent.primaryCustomer}}</h6>
                    <h6 *ngIf="!objFromParent.primaryCustomer"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Category</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Standard</p>
            <div class="title-info">
                <span>All Data </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row pagebreak" id="print-five">
            <div class="col-12 text-center">

            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Customer Name</label>
                    <h6 *ngIf="objFromParent.primaryCustomer">{{objFromParent.primaryCustomer}}</h6>
                    <h6 *ngIf="!objFromParent.primaryCustomer"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Number</label>
                    <h6>#{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Catogary</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Weight</label>
                    <h6 *ngIf="objFromParent.weight">{{objFromParent.weight}}</h6>
                    <h6 *ngIf="!objFromParent.weight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Volume</label>
                    <h6 *ngIf="objFromParent.volume">{{objFromParent.volume}}</h6>
                    <h6 *ngIf="!objFromParent.volume"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Value</label>
                    <h6 *ngIf="objFromParent.price">{{objFromParent.price}}</h6>
                    <h6 *ngIf="!objFromParent.price"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
