export enum HistoryCarWashNames {
  Add_Vehicle = 'Added',
  Update_Vehicle = 'Updated',
  Create_Membership = 'Create Membership',
  Check_In_Action = '(Check In)',
  Check_Out_Action = '(Check Out)',
  Force_Update_Plan = 'Force Update Plan',
  Update_Subscription = 'Update Subscription',
  Cancel_Subscription = 'Cancel Subscription',
  Assign_Qr_Code = 'Assign Qr Code',
}
