<div class="container" [style.display]="isVisible ? 'block' : 'none'">
    <h2><span>User Information</span></h2>
    <div class="card ">
        <form [formGroup]="inviteCompleteForm" (submit)="Invite()">
            <mat-vertical-stepper [linear]="isLinear" #stepper>
                <mat-step [stepControl]="inviteCompleteForm">
                    <ng-template matStepLabel>User Sign Up</ng-template>
                    <div>
                        <div class="form-group">
                            <label for="firstName"> First Name <span>* </span> :</label>
                            <input type="text" id="firstName" formControlName="firstName" placeholder="First Name" class="form-control" [ngClass]="{'is-invalid' : f.firstName.errors && f.firstName.touched}" />
                            <div *ngIf="f.firstName.touched && f.firstName.invalid" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lastName"> Last Name <span>* </span> :</label>
                            <input type="text" id="lastName" formControlName="lastName" placeholder="Last Name" class="form-control" [ngClass]="{'is-invalid' : f.lastName.errors && f.lastName.touched}" />
                            <div *ngIf="f.lastName.touched && f.lastName.invalid" class="invalid-feedback">
                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email"> Email <span>* </span> :</label>
                            <input type="text" id="email" readonly value="{{email}}" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label for="phone"> Phone <span>* </span> :</label>
                            <input type="text" id="phone" formControlName="phone" placeholder="phone" class="form-control" [ngClass]="{'is-invalid' : f.phone.errors && f.phone.touched}" />
                            <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone is required</div>
                                <div *ngIf="f.phone.errors.pattern">Phone not valid must only number.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password"> Password <span>* </span> :</label>
                            <input [type]="isShowPassword ? 'text' : 'password'" id="password" formControlName="password" placeholder="Password" class="form-control" [ngClass]="{'is-invalid' : f.password.errors && f.password.touched}" />
                            <div *ngIf="f.password.touched && f.password.invalid" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                            <div class="invalid-feedback" *ngIf="f.password.errors?.pattern"> must be at least 6 characters and should contain at least one number and one special character and capital letter
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword"> Confirm Password <span>* </span> :</label>
                            <input [type]="isShowPassword ? 'text' : 'password'" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" class="form-control" [ngClass]="{'is-invalid' : f.confirmPassword.errors && f.confirmPassword.touched
                            ||inviteCompleteForm.hasError('mismatch') && f.confirmPassword.touched}" />
                            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors?.required">Confirm Password is required</div>
                                <!-- <div *ngIf="f.confirmPassword.errors.pattern">password must be at least 8 characters and should contain atleast one number and one special character</div> -->
                            </div>
                            <div class="invalid-feedback" *ngIf="f.confirmPassword.touched">
                                <div *ngIf="inviteCompleteForm.hasError('mismatch')"> Password is Mismatch</div>
                                <div *ngIf="f.confirmPassword.errors?.pattern"> must be at least 6 characters and should contain at least one number and one special character and capital letter</div>
                            </div>
                            <!-- <div class="invalid-feedback" >
                          </div> -->
                        </div>
                        <div class="form-check ">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="showPassword()">
                            <label class="form-check-label" for="flexCheckDefault">
                              Show Password
                          </label>
                        </div>
                        <!-- <button type="button" mdbBtn color="primary" [routerLink]="['/admin']">Back</button> -->
                        <button class="btn btn-success" matStepperNext type="button" [disabled]="f.firstName.invalid || f.lastName.invalid ||  f.phone.invalid || f.password.invalid || f.confirmPassword.invalid || inviteCompleteForm.hasError('mismatch') ">Next</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="inviteCompleteForm">
                    <ng-template matStepLabel>User Sign Up</ng-template>
                    <div class="form-group">
                        <label for="addressOne"> Address 1 <span>* </span> :</label>
                        <input type="text" id="addressOne" formControlName="addressOne" #addressText placeholder="Address 1" class="form-control" [ngClass]="{'is-invalid' : f.addressOne.errors && f.addressOne.touched}" />
                        <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="invalid-feedback">
                            <div *ngIf="f.addressOne.errors.required">Address is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="addressTow"> Address 2 :</label>
                        <input type="text" id="addressTow" formControlName="addressTow" placeholder="Address2" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label for="city"> City <span>* </span> :</label>
                        <input type="text" id="city" formControlName="city" placeholder="city" class="form-control" [ngClass]="{'is-invalid' : f.city.errors && f.city.touched}" />
                        <div *ngIf="f.city.touched && f.city.invalid" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">City is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="state"> State <span>* </span> :</label>
                        <input type="text" id="state" formControlName="state" placeholder="state" class="form-control" [ngClass]="{'is-invalid' : f.state.errors && f.state.touched}" />
                        <div *ngIf="f.state.touched && f.state.invalid" class="invalid-feedback">
                            <div *ngIf="f.state.errors.required">State is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="zip"> Zip <span>* </span> :</label>
                        <input type="text" id="zip" formControlName="zip" placeholder="zip" class="form-control" [ngClass]="{'is-invalid' : f.zip.errors && f.zip.touched}" />
                        <div *ngIf="f.zip.touched && f.zip.invalid" class="invalid-feedback">
                            <div *ngIf="f.zip.errors.required">Zip is required</div>
                            <div *ngIf="f.zip.errors.pattern">Zip Code not valid must only number.</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="country"> Country <span>* </span> :</label>
                        <!-- <input type="text" id="country" formControlName="country" placeholder="country" class="form-control" [ngClass]="{'is-invalid' : f.country.errors && f.country.touched}" /> -->
                        <mat-select-country class="control" label="{{defaultValue.name}}" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                        </mat-select-country>
                        <div *ngIf=" f.country.touched && f.country.invalid " class="invalid-feedback ">
                            <div *ngIf="f.country.errors.required ">Country is required</div>
                        </div>
                    </div>
                    <div>
                        <button matStepperPrevious type="button " class="btn btn-primary">Back</button>
                        <button type="submit " class="btn btn-success" [disabled]="inviteCompleteForm.invalid ">Save</button>
                        <button type="button " class="btn btn-danger" [disabled]="inviteCompleteForm.invalid " (click)="stepper.reset() ">Reset</button>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </form>
    </div>
</div>
