<!-- Section Estimates Data Summary -->
<section id="summary" *ngIf="userPermission.value != statusActive.InActive ">
    <!-- <div class="row">
        <div class="col-12 summary">
            <div class="tittle-section">
                <p>
                    <i class="fas fa-indent pr-1"></i>Job Summary
                </p>
                <div class="tittle-name">
                    <label>{{jobName}}</label>
                </div>
                <div class="job-number">
                    <label># {{jobNumber}}
                      <button class="edit-job-number" (click)="editNumberModal.show()"><i class="far fa-edit"></i></button>
                  </label>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Estimates accordion -->
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                Inventory Summary
                <div class="title-info">
                    <span>Qty: {{jobSummary.itemsActualQuantity }}</span>
                </div>
            </mat-expansion-panel-header>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Data Type</th>
                        <th scope="col">Estimate</th>
                        <th class="edit" scope="col">Actual
                            <span (click)="editSummaryModal.show();storeId(jobSummary)" *ngIf="userPermission.value == statusActive.Active "><i class="far fa-edit"></i> </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Quantity</td>
                        <td>{{jobSummary.itemsEstimateQuantity }}</td>
                        <td>{{jobSummary.itemsActualQuantity }}</td>
                    </tr>
                    <tr>
                        <td>Pads</td>
                        <td>{{jobSummary.itemsEstimatePads }}</td>
                        <td>{{jobSummary.itemsActualPads }}</td>
                    </tr>
                    <tr>
                        <td>Boxs</td>
                        <td>{{jobSummary.itemsEstimateBoxed }}</td>
                        <td>{{jobSummary.itemsActualBoxed }}</td>
                    </tr>
                    <tr>
                        <td>Value</td>
                        <td>{{jobSummary.itemsEstimateValue }} $</td>
                        <td>{{jobSummary.itemsActualValue }} $</td>
                    </tr>
                    <tr>
                        <td>Extra Service Fee</td>
                        <td>{{jobSummary.itemsEstimateExtraServiceFee }} $</td>
                        <td>{{jobSummary.itemsActualExtraServiceFee }} $</td>
                    </tr>
                    <tr>
                        <td>Weight</td>
                        <td>{{jobSummary.itemsEstimateWeight }} lbs</td>
                        <td>{{jobSummary.itemsActualWeight }} lbs</td>
                    </tr>
                    <tr>
                        <td>Volume</td>
                        <td>{{jobSummary.itemsEstimateVolume }} CF</td>
                        <td>{{jobSummary.itemsActualVolume }} CF</td>
                    </tr>
                </tbody>
            </table>
        </mat-expansion-panel>
    </mat-accordion>
</section>

<!-- Edit Estimates Data Summary -->
<div mdbModal #editSummaryModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Actual Data Summary</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editSummaryModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form #summaryForm="ngForm" form="summaryForm ">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsQuantity">Quantity:</label>
                                    <input type="text" id="itemsQuantity" class="form-control" name="itemsActualQuantity" [pattern]="numberPattern" #itemsActualQuantity="ngModel" [(ngModel)]="summaryModelNew.itemsActualQuantity">
                                    <div *ngIf="itemsActualQuantity.errors && itemsActualQuantity.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualQuantity.errors.pattern">
                                            Quantity not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsWeight">Weight:</label>
                                    <input type="text" id="itemsWeight" class="form-control" name="itemsActualWeight" [pattern]="numberPattern" #itemsActualWeight="ngModel" [(ngModel)]="summaryModelNew.itemsActualWeight">
                                    <div *ngIf="itemsActualWeight.errors && itemsActualWeight.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualWeight.errors.pattern">
                                            Weight not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsValue">Value:</label>
                                    <input type="text" id="itemsValue" class="form-control" name="itemsActualValue" [pattern]="numberPattern" #itemsActualValue="ngModel" [(ngModel)]="summaryModelNew.itemsActualValue">
                                    <div *ngIf="itemsActualValue.errors && itemsActualValue.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualValue.errors.pattern">
                                            Value not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsVolume">Volume:</label>
                                    <input type="text" id="itemsVolume" class="form-control" name="itemsActualVolume" [pattern]="numberPattern" #itemsActualVolume="ngModel" [(ngModel)]="summaryModelNew.itemsActualVolume">
                                    <div *ngIf="itemsActualVolume.errors && itemsActualVolume.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualVolume.errors.pattern">
                                            Volume not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsActualPads">Pads:</label>
                                    <input type="text" id="itemsActualPads" class="form-control" name="itemsActualPads" [pattern]="numberPattern" #itemsActualPads="ngModel" [(ngModel)]="summaryModelNew.itemsActualPads">
                                    <div *ngIf="itemsActualPads.errors && itemsActualPads.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualPads.errors.pattern">
                                            Pads not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsActualBoxed">Boxs:</label>
                                    <input type="text" id="itemsActualBoxed" class="form-control" name="itemsActualBoxed" [pattern]="numberPattern" #itemsActualBoxed="ngModel" [(ngModel)]="summaryModelNew.itemsActualBoxed">
                                    <div *ngIf="itemsActualBoxed.errors && itemsActualBoxed.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualBoxed.errors.pattern">
                                            Boxs not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsActualExtraServiceFee">Extra Service Fee:</label>
                                    <input type="text" id="itemsActualExtraServiceFee" class="form-control" name="itemsActualExtraServiceFee" [pattern]="numberPattern" #itemsActualExtraServiceFee="ngModel" [(ngModel)]="summaryModelNew.itemsActualExtraServiceFee">
                                    <div *ngIf="itemsActualExtraServiceFee.errors && itemsActualExtraServiceFee.touched" class="alert alert-danger">
                                        <div *ngIf="itemsActualExtraServiceFee.errors.pattern">
                                            Extra Service Fee not valid must only number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label for="suppliesValue">Supplies Value:</label>
                                    <input type="text" id="suppliesValue" class="form-control" name="suppliesValue" [(ngModel)]="summaryModelNew.suppliesValue">
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label for="chargesValue">Charges Value:</label>
                                    <input type="text" id="chargesValue" class="form-control" name="chargesValue" [(ngModel)]="summaryModelNew.chargesValue">
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <div class="form-group">
                                    <label for="itemsBalance">Balance:</label>
                                    <input type="text" id="itemsBalance" class="form-control" name="balance" [(ngModel)]="summaryModelNew.balance">
                                </div>
                            </div> -->
                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-primary " (click)="editSummaryModal.hide()">Cancel</button>
                    <button type="button " class="btn btn-success " form="summaryForm" [disabled]="summaryForm.invalid" (click)="updateSummary(summaryForm);editSummaryModal.hide() ">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
