<div class="card">
    <h2><span>Create a job</span></h2>
    <form [formGroup]="jobFormGroup">
        <div class="form-group job-num">
            <input type="text" id="jobNumber" formControlName="jobNumber" placeholder="Job Number" class="form-control" [ngClass]="{'is-invalid' : f.jobNumber.errors && f.jobNumber.touched}" />
            <div *ngIf="f.jobNumber.touched && f.jobNumber.invalid" class="invalid-feedback">
                <div *ngIf="f.jobNumber.errors.required">Job Number is required</div>
            </div>
        </div>
        <mat-vertical-stepper [linear]="isLinear" #stepper>
            <!-- First Step form front -->
            <mat-step [stepControl]="jobFormGroup">
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                            <option selected>From</option>
                            <option >To</option>
                            <option>Same</option>
                            <option >None</option>
                       </select>
                        <div>
                            <input type="text" id="from" formControlName="from" readonly placeholder="From" class="form-control" [ngClass]="{'is-invalid' : f.from.errors && f.from.touched}" />
                            <div *ngIf="f.from.touched && f.from.invalid" class="invalid-feedback">
                                <div *ngIf="f.from.errors.required">from is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                            <option >From</option>
                            <option selected>To</option>
                            <option>Same</option>
                            <option >None</option>
                        </select>
                        <div>
                            <input type="text" id="to" formControlName="to" placeholder="To" readonly class="form-control" [ngClass]="{'is-invalid' : f.to.errors && f.to.touched}" />
                            <div *ngIf="f.to.touched && f.to.invalid" class="invalid-feedback">
                                <div *ngIf="f.to.errors.required">To is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                            <option >From</option>
                            <option >To</option>
                            <option>Same</option>
                            <option selected >None</option>
                      </select>
                        <div>
                            <input type="text" id="different" formControlName="different" readonly placeholder="Different" class="form-control" [ngClass]="{'is-invalid' : f.different.errors && f.different.touched}" />
                            <div *ngIf="f.different.touched && f.different.invalid" class="invalid-feedback">
                                <div *ngIf="f.different.errors.required">Different is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template matStepLabel>Job Location</ng-template>
                <div class="form-group">
                    <label for="addressOne"> Address 1 <span>* </span> :</label>
                    <input type="text" id="addressOne" formControlName="addressOne" placeholder="Address 1" class="form-control" [ngClass]="{'is-invalid' : f.addressOne.errors && f.addressOne.touched}" />
                    <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="invalid-feedback">
                        <div *ngIf="f.addressOne.errors.required">Address is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="addressTow"> Address 2 :</label>
                    <input type="text" id="addressTow" formControlName="addressTow" placeholder="Address 2" class="form-control" />
                </div>
                <div class="form-group">
                    <label for="city"> City <span>* </span> :</label>
                    <input type="text" id="city" formControlName="city" placeholder="City" class="form-control" [ngClass]="{'is-invalid' : f.city.errors && f.city.touched}" />
                    <div *ngIf="f.city.touched && f.city.invalid" class="invalid-feedback">
                        <div *ngIf="f.city.errors.required">City is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="state"> State <span>* </span> :</label>
                    <input type="text" id="state" formControlName="state" placeholder="State" class="form-control" [ngClass]="{'is-invalid' : f.state.errors && f.state.touched}" />
                    <div *ngIf="f.state.touched && f.state.invalid" class="invalid-feedback">
                        <div *ngIf="f.state.errors.required">State is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="zipCode"> Zip <span>* </span> :</label>
                    <input type="text" id="zipCode" formControlName="zipCode" placeholder="Zip Code" class="form-control" [ngClass]="{'is-invalid' : f.zipCode.errors && f.zipCode.touched}" />
                    <div *ngIf="f.zipCode.touched && f.zipCode.invalid" class="invalid-feedback">
                        <div *ngIf="f.zipCode.errors.required">Zip Code is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="country"> Country :</label>
                    <mat-select-country class="control" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                    </mat-select-country>
                </div>
                <div>
                    <button type="button" matStepperNext mdbBtn color="success" [disabled]="f.addressOne.invalid  ||f.city.invalid || f.zipCode.invalid || f.state.invalid ">Next</button>
                </div>
            </mat-step>
            <!-- Second Step -->
            <mat-step [stepControl]="jobFormGroup">
                <ng-template matStepLabel>Date Window</ng-template>
                <!-- Dating Window -->
                <label class="window-date">Date :</label>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                            <option selected>From</option>
                         </select>
                        <div>
                            <input type="text" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                          <option selected>To</option>
                       </select>
                        <div>
                            <input type="text" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig">
                        </div>
                    </div>
                </div>
                <!-- Time Window -->
                <label class="window-date">Time :</label>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                           <option selected>From</option>
                         </select>
                        <div>
                            <input [ngxTimepicker]="picker" placeholder="HH : MM : AM" class="form-control">
                            <ngx-material-timepicker #picker (timeSet)="setTimeFrom($event)"></ngx-material-timepicker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="from-To">
                        <select class="browser-default custom-select">
                                <option selected>To</option>
                             </select>
                        <div>
                            <input [ngxTimepicker]="picker1" placeholder="HH : MM : PM" class="form-control">
                            <ngx-material-timepicker #picker1 (timeSet)="setTimeTo($event)"></ngx-material-timepicker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="notes" class="window-date">Notes :</label>
                    <textarea class="form-control" id="notes" placeholder="Pick Up Instruction" rows="8"></textarea>
                </div>
                <div>
                    <button matStepperPrevious type="button" mdbBtn color="primary">Back</button>
                    <button type="submit" mdbBtn color="success" (click)="basicModal.show()" [disabled]="jobFormGroup.invalid">Save</button>
                    <button type="button" mdbBtn color="danger" [disabled]="jobFormGroup.invalid" (click)="stepper.reset()">Reset</button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </form>
</div>
<!-- Modal Confirmation  -->
<div mdbModal #basicModal="mdbModal" class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Do you want to save changes to this page before closing?</p>
                    <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="basicModal.hide()">Close</button>
                    <button type="button" class="btn btn-success" (click)="addJob();basicModal.hide()">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>