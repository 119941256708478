import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { JobDetailsService } from './../../../../../core/_services/job-details/job-details.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
interface Summary {
  itemsQuantity: string,
  itemsWeight: string,
  itemsValue: string,
  itemsVolume: string
  suppliesValue: string
  chargesValue: string
  balance: string
  type: string
}
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  summaryModelNew: any = {}
  summaryJobModel = {} as Summary
  jobNumber: string
  jobDetails: JobDetails
  jobDetailsId: string
  globalId: number
  jobSummary: any = []
  jobId: any
  user: any
  role: any = ''
  jobsTools: any = {}
  items: any[] = []
  jobSummaryObj: any = {}
  userPermission: any = {}
  statusActive: any = StatusActive
  numberPattern = ValidationModel.numberPattern;
  constructor(
    private _jobDetailsService: JobDetailsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private socket: Socket,
    private _toolsService: ToolsService,
    private _jobsService: JobsService,
  ) { }

  ngOnInit() {
    this.jobNumber = "0000000001"
    // get job summary
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user.role
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.route.data.subscribe((response: ResponseModel) => {
      // if (response['jobs'].success) {
      //   this.items = response['jobs'].data.showItems
      //   if (response['jobs'].success) {
      //     this.items = response['jobs'].data.showItems
      //     if (this.role == "admin") {
      //       this.getJobSummary()
      //     }
      //     if (this.role != "admin" && this.role != "manager") {
      //       this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
      //       if (this.userPermission.value != this.statusActive.InActive) {
      //         this.getJobSummary()
      //       }
      //     }
      //     if (this.role == "manager") {
      //       this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
      //       if (this.userPermission.value != this.statusActive.InActive) {
      //         this.getJobSummary()
      //       }
      //     }
      //  }

      // }
      // if (response['tools'].success) {
      // }
           // new
           if (response['tools'].success) {
            this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
            if (response['jobs'].success) {
            this.items = response['jobs'].data.showItems
            if (this.role == "admin") {
              this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.inventory)
              if(this.userPermission.itemStatus == 'Active') {
                this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
              }
              this.getJobSummary()
            }
            if (this.role != "admin" && this.role != "manager") {
              this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
              if (this.userPermission.value != this.statusActive.InActive) {
                this.getJobSummary()
              }
            }
            if (this.role == "manager") {
              this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
              if (this.userPermission.value != this.statusActive.InActive) {
                this.getJobSummary()
              }
            }
          }
        }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.InventoryItem_Add, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
       this.getJobSummary()
      }
    });
    this.socket.on(RealTimeKeys.InventoryItem_Update, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this.getJobSummary()
      }
    });
    this.socket.on(RealTimeKeys.Inventory_Item_Trash, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this.getJobSummary()
      }
    });
    this.socket.on(RealTimeKeys.InventoryItem_Restore, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this.getJobSummary()
      }
    });
  }
  checkItemPlan(){
    if(this._jobsService.getToolPlan(this.items,ToolJobsEditItem.inventory) > this.user.companyPlan){
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getJobSummary() {
    this._jobDetailsService.getJobDetailsByIdItem(this.jobId,ToolJobsEditItem.inventory).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.jobSummary = response.data.jobSummary
        this.checkItemPlan()
      }
    })
  }
  storeId(item) {
    this.summaryModelNew = Object.assign({}, item);
    this.globalId = item.id
  }
  updateSummary(summaryForm: NgForm) {
    // let summaryModelNew = Object.assign({}, summaryForm.value);
    let idItem = ToolJobsEditItem.jobSummary
    this.jobSummary = this.summaryModelNew
    if (this.summaryModelNew.itemsActualQuantity == '') {
      this.summaryModelNew.itemsActualQuantity = 0
    }
    if (this.summaryModelNew.itemsActualValue == '') {
      this.summaryModelNew.itemsActualValue = 0
    }
    if (this.summaryModelNew.itemsActualVolume == '') {
      this.summaryModelNew.itemsActualVolume = 0
    }
    if (this.summaryModelNew.itemsActualWeight == '') {
      this.summaryModelNew.itemsActualWeight = 0
    }
    if (this.summaryModelNew.itemsActualPads == '') {
      this.summaryModelNew.itemsActualPads = 0
    }
    if (this.summaryModelNew.itemsActualBoxed == '') {
      this.summaryModelNew.itemsActualBoxed = 0
    }
    if (this.summaryModelNew.itemsActualExtraServiceFee == '') {
      this.summaryModelNew.itemsActualExtraServiceFee = 0
    }
    this._jobDetailsService.updateJobDetails(this.jobSummary, this.jobId, idItem,0).subscribe((response: ResponseModel) => {
      this.toastr.success('', 'Successfully Update ',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        });
      this.jobSummary = this.jobSummary
    })
  }
  updateJobSummary(summaryJobForm: NgForm) {
    this.summaryJobModel = summaryJobForm.value
  }
  updateJobNumber() {
    this.jobNumber = this.jobNumber
  }
  undoJobNumber() {
    this.jobNumber = "0000000001"
  }
}
