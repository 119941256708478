<div id="qr_code_print">
    <div class="item">
        <div class="first-side" *ngIf="companyInfo?.companyLogo">
            <img src="{{companyInfo?.companyLogo}}" alt="logo-img"> <br>
        </div>
        <div class="second-side">
            <div class="info">
                <p>{{companyInfo?.companyName}}</p>
                <p *ngIf="companyInfo.companyPhone">{{companyInfo?.companyPhone}} </p>
                <p class="mb-0" *ngIf="companyInfo.companyWebsite">{{companyInfo?.companyWebsite}}</p>
            </div>
        </div>
    </div>
    <div class="address-section">
        <p class="address-small">{{companyInfo.companyAddress?.addressOne}} <span *ngIf="companyInfo.companyAddress?.addressTow != ''">, {{companyInfo.companyAddress?.addressTow}}</span>, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}} {{companyInfo.companyAddress?.zip}}
        </p>
    </div>
    <div class="qr-code">
        <ng-container *ngIf="companyInfo.isInQueue && companyInfo.queueNumber && companyInfo.queueNumber > 1">
            <h5>Thank you for your recent request</h5>
            <p>We have added you to our queue</p>
        </ng-container>
        <div *ngIf="companyInfo.date && companyInfo.time ">
            <span>
              <ng-container *ngIf="companyInfo.isInQueue && companyInfo.queueNumber && companyInfo.queueNumber > 0">
               <div class="turn-number" *ngIf="companyInfo.queueNumber > 1">
                <span  class="order-turn">{{companyInfo.queueNumber + 1}}</span>
            <!-- <h5>Other customers ahead of you</h5> -->
            <h5>Is your turn, please wait</h5>
        </div>
        <p class="success-turn" *ngIf="companyInfo.queueNumber == 1">
            You are Next
            <span class="order-turn-second"><b>1</b>  Other customer(s) ahead of you </span>
        </p>
        </ng-container>
        <p class="success-turn" *ngIf="companyInfo.isInQueue  && companyInfo.queueNumber == 0">
            It is your turn; Please bring your vehicle
            <span class="order-turn-second"> <b>0</b> Other customer(s) ahead of you</span>
        </p>
        <strong class="danger-turn" *ngIf="!companyInfo.isInQueue && companyInfo.isUnCheckIn">Removed from the queue, Thank you.</strong>
        <strong class="success-turn" *ngIf="!companyInfo.isInQueue && !companyInfo.isUnCheckIn">Service done, Thank you.</strong>
        </span>
        <!-- It is your turn; We are waiting for you. -->
        <!-- Service done, Thank you  -->
        <!-- Removed from the queue, Thank you -->
    </div>
    <ng-container *ngIf="!companyInfo.date && !companyInfo.time ">
        <span>Comming to our car wash </span>
    </ng-container>
</div>
<!-- <div class="qr-code">
    </div> -->
<div class="steps">
    <div class="steps-content">
        <p>
            If you have any urgent concerns or questions, please do not hesitate to reach out to us directly and we'll do our best to assist you.
        </p>
        <p> Thank you,<br> {{companyInfo?.companyName}} Team</p>
    </div>
</div>
</div>
