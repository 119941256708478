<div class="text-center">
    <button class="btn btn-sm btn-primary" (click)="goBack()">Back</button>
</div>
<!-- print tag one  -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Container Tag </p>
            <div class="title-info">
                <span>ID, Empty, Full, Volume </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-container-one">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Container ID</label>
                    <h6 *ngIf="objFromParent1.containerNumber">C: {{objFromParent1.containerNumber}}</h6>
                    <h6 *ngIf="!objFromParent1.containerNumber"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Empty Weight</label>
                    <h6 *ngIf="objFromParent1.emptyWeight">{{objFromParent1.emptyWeight}}</h6>
                    <h6 *ngIf="!objFromParent1.emptyWeight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Full Weight</label>
                    <h6 *ngIf="objFromParent1.fullWeight">{{objFromParent1.fullWeight}}</h6>
                    <h6 *ngIf="!objFromParent1.fullWeight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Volume</label>
                    <h6 *ngIf="objFromParent1.containerVolume">{{objFromParent1.containerVolume}}</h6>
                    <h6 *ngIf="!objFromParent1.containerVolume"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- print tag tow  -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 1</p>
            <div class="title-info">
                <span>ID, <span *ngIf="objFromParent1.description">Desciption,</span> Type, Full% </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-container-two">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Container ID</label>
                    <h6 *ngIf="objFromParent1.containerNumber">C: {{objFromParent1.containerNumber}}</h6>
                    <h6 *ngIf="!objFromParent1.containerNumber"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Description</label>
                    <h6 *ngIf="objFromParent1.description">{{objFromParent1.description}}</h6>
                    <h6 *ngIf="!objFromParent1.description"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Customer(s) Name</label>
                    <h6 *ngIf="objFromParent1.jobsData?.length > 0">
                        <p *ngFor="let item of objFromParent1.jobsData">
                            {{item.primaryCustomerName}}
                        </p>
                    </h6>
                    <h6 *ngIf="objFromParent1.jobsData?.length <= 0"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Full %</label>
                    <h6 *ngIf="objFromParent1.fullPercentage">{{objFromParent1.fullPercentage}}</h6>
                    <h6 *ngIf="!objFromParent1.fullPercentage"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- Summary 1   -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 2</p>
            <div class="title-info">
                <span>ID, Full%, Job Numbers </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-container-three">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Container ID</label>
                    <h6 *ngIf="objFromParent1.containerNumber">C: {{objFromParent1.containerNumber}}</h6>
                    <h6 *ngIf="!objFromParent1.containerNumber"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Full %</label>
                    <h6 *ngIf="objFromParent1.fullPercentage">{{objFromParent1.fullPercentage}}</h6>
                    <h6 *ngIf="!objFromParent1.fullPercentage"><br></h6>
                </div>
            </div>
            <div class="col-12" *ngIf="objFromParent1.jobsData">
                <div class="item-print">
                    <label>job Number</label>
                    <h6 *ngFor="let item of objFromParent1.jobsData">{{item.jobNumber}}</h6>
                    <h6 *ngIf="!objFromParent1.jobsData">{{item.jobNumber}}</h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- Standard -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Standard</p>
            <div class="title-info">
                <span>All Data </span>
            </div>
        </mat-expansion-panel-header>
        <div class="row" id="print-container-five">
            <div class="col-12">
                <div class="item-print">
                    <label>Description</label>
                    <h6 *ngIf="objFromParent1.description">{{objFromParent1.description}}</h6>
                    <h6 *ngIf="!objFromParent1.description"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Container ID</label>
                    <h6 *ngIf="objFromParent1.containerNumber">C: {{objFromParent1.containerNumber}}</h6>
                    <h6 *ngIf="!objFromParent1.containerNumber"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Handled By</label>
                    <h6 *ngIf="objFromParent1.handledBy">{{objFromParent1.handledBy}}</h6>
                    <h6 *ngIf="!objFromParent1.handledBy"><br></h6>
                </div>
            </div>
            <div class="col-12" *ngIf="objFromParent1.containerType">
                <div class="item-print">
                    <label>Customer(s) Name</label>
                    <h6 *ngIf="objFromParent1.jobsData?.length > 0">
                        <p *ngFor="let item of objFromParent1.jobsData">
                            {{item.primaryCustomerName}}
                        </p>
                    </h6>
                    <h6 *ngIf="objFromParent1.jobsData?.length <= 0"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Empty Weight</label>
                    <h6 *ngIf="objFromParent1.emptyWeight">{{objFromParent1.emptyWeight}}</h6>
                    <h6 *ngIf="!objFromParent1.emptyWeight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Full Weight</label>
                    <h6 *ngIf="objFromParent1.fullWeight">{{objFromParent1.fullWeight}}</h6>
                    <h6 *ngIf="!objFromParent1.fullWeight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Container Volume</label>
                    <h6 *ngIf="objFromParent1.containerVolume">{{objFromParent1.containerVolume}}</h6>
                    <h6 *ngIf="!objFromParent1.containerVolume"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Full %</label>
                    <h6 *ngIf="objFromParent1.fullPercentage">{{objFromParent1.fullPercentage}}</h6>
                    <h6 *ngIf="!objFromParent1.fullPercentage"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
