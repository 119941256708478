export enum ToolSettingEditItem {
  Customers_list = 0,
  Users_list = 1,
  Company_Account = 2,
  Trucks_list = 3,
  My_Profile = 4,
  Support_Tickets = 5,
  knowledge_Base = 6,
  Car_Wash_Qr_Code = 7,
  Car_Wash_Vehicle = 8,
  Warehouse_Map = 9,
  Qr_Code = 10,
  Qr_Code_Stickers = 11,
  Car_Wash_Services= 12,
  Car_Was_Website = 13,
}