<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin" [fullScreen]="true">
    <p style="color: white"> Please Wait...... </p>
</ngx-spinner> -->
<div id="company-profile">
    <div class="tittle-section">
        <p> Company Account</p>
        <div class=" btn-add-new" *ngIf="role == 'admin' " (click)="inviteManager()">
            <span class="badge badge-success"> <i class="fas fa-paper-plane"></i> Invite a Manager</span>
        </div>
    </div>
    <div class="card front-face">
        <!-- <div class="card-title" >
            <button class="btn btn-sm btn-primary" ><i class="fas fa-paper-plane"></i> Invite a Manager</button>
        </div> -->
        <div class="img-logo">
            <div class="edit-btn" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">
                <input type="file" accept="image/*" (change)="onFileChanged($event)" #fileInput>
                <!-- <i class="far fa-edit pr-1 " (click)="fileInput.click()"></i> -->
                <i class="uil uil-image-edit uil-medium" (click)="fileInput.click()"></i>
            </div>
            <img [attr.src]="logoUrl ? logoUrl: 'https://via.placeholder.com/150'" alt="img-logo">
        </div>
        <!-- company information -->
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Company Information
                </mat-expansion-panel-header>
                <div class="accordion-body">
                    <ngx-flip [flip]="flipDiv">
                        <div class="fornt-company-info" front [style.display]="!flipDiv ? 'block' : 'none'">
                            <div class="col-sm-12 industry-tittle">
                                <div class="info-tittle">
                                    <strong>Company Information</strong>
                                    <button class="btn btn-sm btn-success" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1" (click)="onClick()">
                                            <i class="far fa-edit pr-1"></i>Edit
                                     </button>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Company Name </h6>
                                    <label>{{companyModel.companyName}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Email </h6>
                                    <label>{{companyModel.companyEmail}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Phone </h6>
                                    <label>{{companyModel.companyPhone}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Currency </h6>
                                    <label>{{companyModel.currency}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Website </h6>
                                    <label class="website">{{companyModel.companyWebsite}}</label>
                                </div>
                            </div>
                            <!-- <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Business Industry </h6>
                                    <label>
                                      <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Car Wash</span>
                                      <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Moving</span>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                        <div back [style.display]="flipDiv ? 'block' : 'none'">
                            <div class="title-back industry-tittle">
                                <button class="btn btn-sm btn-primary" form="form1" [disabled]="!form1.valid" (click)="onClick()">
                                  <i class="fas fa-backward"></i><span>Back</span>
                                 </button>
                                <button class="btn btn-sm btn-success" color="success" [disabled]="!form1.valid" form="form1" (click)="updateCompany()"> <i class="far fa-save"></i> <span>Save</span> </button>
                            </div>
                            <div class="card back-flip">
                                <!-- <div class="img-logo">
                                              <img src="https://via.placeholder.com/150" alt="ing-logo">
                                          </div> -->
                                <form #form1='ngForm' id="form1">
                                    <div class="row">
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="lastName"> Company Name <span class="required"> *</span> :</label>
                                                <input type="text" id="lastName" required name="companyName" [(ngModel)]="companyModal.companyName" class="form-control" #companyName="ngModel" />
                                                <div *ngIf="companyName.errors && companyName.touched" class="alert alert-danger">
                                                    <div *ngIf="companyName.errors.required">
                                                        Company Name required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="emailCompany"> Email <span class="required"> *</span> :</label>
                                                <input type="email" id="emailCompany" name="companyEmail" required [(ngModel)]="companyModal.companyEmail" class="form-control" [pattern]="emailPattern" #emailCompany="ngModel" />
                                                <div *ngIf="emailCompany.errors && emailCompany.touched" class="alert alert-danger">
                                                    <div *ngIf="emailCompany.errors.required">
                                                        Email Company required.
                                                    </div>
                                                    <div *ngIf="emailCompany.errors.pattern">
                                                        Email Company not valid.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="phone"> Phone <span class="required"> *</span> :</label>
                                                <input type="text" id="phone" name="companyPhone" required [(ngModel)]="companyModal.companyPhone" class="form-control" [pattern]="numberPattern" #companyPhone="ngModel" />
                                                <div *ngIf="companyPhone.errors && companyPhone.touched" class="alert alert-danger">
                                                    <div *ngIf="companyPhone.errors.required">
                                                        Company Phone required.
                                                    </div>
                                                    <div *ngIf="companyPhone.errors.pattern">
                                                        Company Phone must only number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="currency"> Currency <span class="required"> *</span> :</label>
                                                <select class="browser-default custom-select form-control" name="currency" [(ngModel)]="companyModal.currency">
                                                    <option *ngFor="let currency of Currencies"> {{currency}} </option>
                                              </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="website"> Website :</label>
                                                <input type="text" id="website" name="companyWebsite" [(ngModel)]="companyModal.companyWebsite" class="form-control" />
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="businessIndustry"> Business Industry :</label>
                                                <select class="browser-default custom-select form-control" name="industry" [(ngModel)]="industry" (change)="changeIndustry($event.target.value)">
                                                <option value="{{categoryCompany.Inventory}}"> Moving </option>
                                                <option value="{{categoryCompany.Car_Wash}}"> Car Wash </option>
                                             </select>
                                            </div>
                                        </div> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ngx-flip>
                    <div *ngIf="!isMobile">
                        <ngx-flip [flip]="flipDiv2">
                            <div class="fornt-company-info" front [style.display]="!flipDiv2 ? 'block' : 'none'">
                                <div class="col-sm-12 industry-tittle">
                                    <div class="info-tittle">
                                        <strong>Business Industry</strong>
                                        <button class="btn btn-sm btn-success" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1" (click)="onClick2()">
                                      <i class="far fa-edit pr-1"></i>Edit
                                    </button>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Business Industry </h6>
                                        <label>
                                          <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Car Wash</span>
                                          <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Moving</span>
                                       </label>
                                    </div>
                                </div>
                            </div>
                            <div back [style.display]="flipDiv2 ? 'block' : 'none'">
                                <div class="title-back industry-tittle">
                                    <button class="btn btn-sm btn-primary" (click)="onClick2()">
                                      <i class="fas fa-backward"></i><span>Back</span>
                                    </button>
                                    <button class="btn btn-sm btn-success" color="success" (click)="changeCategoryModal.show()"> <i class="far fa-save"></i> <span>Save</span> </button>
                                </div>
                                <div class="card pt-1 back-flip">
                                    <div class="row">
                                        <div class="col-lg-12 ">
                                            <div class="px-2">
                                                <label for="businessIndustry"> Business Industry :</label>
                                                <select class="browser-default custom-select form-control" name="industry" [(ngModel)]="industry" (change)="changeIndustry($event.target.value)">
                                              <option value="{{categoryCompany.Inventory}}"> Moving </option>
                                              <option value="{{categoryCompany.Car_Wash}}"> Car Wash </option>
                                          </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-flip>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!--Industry information -->
        <!-- <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Business Industry
                </mat-expansion-panel-header>
                <div class="accordion-body">
                    <ngx-flip [flip]="flipDiv2">
                        <div class="fornt-company-info" front [style.display]="!flipDiv2 ? 'block' : 'none'">
                            <div class="col-sm-12">
                                <div class="info-tittle">
                                    <span></span>
                                    <button class="btn btn-sm btn-success" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1" (click)="onClick2()">
                                      <i class="far fa-edit pr-1"></i>Edit
                                    </button>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Business Industry </h6>
                                    <label>
                            <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Car Wash</span>
                            <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Moving</span>
                          </label>
                                </div>
                            </div>
                        </div>
                        <div back [style.display]="flipDiv2 ? 'block' : 'none'">
                            <div class="title-back">
                                <button class="btn btn-sm btn-primary" (click)="onClick2()">
                                   <i class="fas fa-backward"></i><span>Back</span>
                              </button>
                                <button class="btn btn-sm btn-success" color="success" (click)="changeCategoryModal.show()"> <i class="far fa-save"></i> <span>Save</span> </button>
                            </div>
                            <div class="card pt-1 back-flip">
                                <div class="row">
                                    <div class="col-lg-12 ">
                                        <div class="form-group">
                                            <label for="businessIndustry"> Business Industry :</label>
                                            <select class="browser-default custom-select form-control" name="industry" [(ngModel)]="industry" (change)="changeIndustry($event.target.value)">
                                              <option value="{{categoryCompany.Inventory}}"> Moving </option>
                                              <option value="{{categoryCompany.Car_Wash}}"> Car Wash </option>
                                          </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-flip>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
        <!-- company Address -->
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Company Address
                </mat-expansion-panel-header>
                <div class="accordion-body">
                    <ngx-flip [flip]="flipDiv1">
                        <div front class="fornt-company-info" [style.display]="!flipDiv1 ? 'block' : 'none'">
                            <div class="col-sm-12">
                                <div class="info-tittle">
                                    <span></span>
                                    <button class="btn btn-sm btn-success" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1" (click)="onClick1()"><i
                                      class="far fa-edit pr-1"></i>Edit
                                    </button>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Address 1 </h6>
                                    <label>{{companyAddressModel.companyAddress.addressOne}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> Address 2 </h6>
                                    <label>{{companyAddressModel.companyAddress.addressTow}}</label>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item">
                                    <h6> City </h6>
                                    <label>{{companyAddressModel.companyAddress.city}}</label>
                                </div>
                            </div>
                            <div class="  col-sm-12">
                                <div class="item">
                                    <h6> State </h6>
                                    <label>{{companyAddressModel.companyAddress.state}}</label>
                                </div>
                            </div>
                            <div class="  col-sm-12">
                                <div class="item">
                                    <h6> Zip Code </h6>
                                    <label>{{companyAddressModel.companyAddress.zip}}</label>
                                </div>
                            </div>
                            <div class="  col-sm-12">
                                <div class="item">
                                    <h6> Country </h6>
                                    <label>{{companyAddressModel.companyAddress.country.name}}</label>
                                </div>
                            </div>
                        </div>
                        <div back [style.display]="flipDiv1 ? 'block' : 'none'">
                            <div class="title-back">
                                <button class="btn btn-sm" mdbBtn color="primary" form="form2" [disabled]="!form2.valid" (click)="onClick1()">
                                   <i class="fas fa-backward"></i><span>Back</span>
                                </button>
                                <button class="btn btn-sm" mdbBtn color="success" form="form2" [disabled]="!form2.valid" (click)="updateCompanyAddress()"> <i class="far fa-save"></i> <span>Save</span> </button>
                            </div>
                            <div class="card back-flip">
                                <form #form2='ngForm' id="form2">
                                    <div class="row">
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="adddressOne"> Address <span class="required"> *</span> 1 :</label>
                                                <input type="text" id="adddressOne" name="addressOne" #addressText required [(ngModel)]="companyAddressEdit.companyAddress.addressOne" class="form-control" #addressOne="ngModel" />
                                                <div *ngIf="addressOne.errors && addressOne.touched" class="alert alert-danger">
                                                    <div *ngIf="addressOne.errors.required">
                                                        Address 1 is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="adddressTow"> Address 2 :</label>
                                                <input type="text" id="adddressTow" name="addressTow" [(ngModel)]="companyAddressEdit.companyAddress.addressTow" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="city"> City <span class="required"> *</span> :</label>
                                                <input type="text" id="city" name="city" required [(ngModel)]="companyAddressEdit.companyAddress.city" class="form-control" #city="ngModel" />
                                                <div *ngIf="city.errors && city.touched" class="alert alert-danger">
                                                    <div *ngIf="city.errors.required">
                                                        City is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="form-group">
                                                <label for="state"> State <span class="required"> *</span> :</label>
                                                <input type="text" id="state" name="state" required [(ngModel)]="companyAddressEdit.companyAddress.state" class="form-control" #state="ngModel" />
                                                <div *ngIf="state.errors && state.touched" class="alert alert-danger">
                                                    <div *ngIf="state.errors.required">
                                                        State is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6  col-sm-12">
                                            <div class="form-group">
                                                <label for="zipCode"> Zip Code <span class="required"> *</span> :</label>
                                                <input type="text" id="zipCode" name="zip" required [(ngModel)]="companyAddressEdit.companyAddress.zip" class="form-control" [pattern]="numberPattern" #zip="ngModel" />
                                                <div *ngIf="zip.errors && zip.touched" class="alert alert-danger">
                                                    <div *ngIf="zip.errors.required">
                                                        Zip Code is required.
                                                    </div>
                                                    <div *ngIf="zip.errors.pattern">
                                                        Zip Code must only number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6  col-sm-12">
                                            <div class="form-group">
                                                <label for="country"> Country <span class="required"> *</span> :</label>
                                                <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                                                </mat-select-country>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ngx-flip>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- subscription -->
        <!-- <mat-accordion class="example-headers-align" *ngIf="checkCompany(user.companyId) == true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Company Plan (
                    <small *ngIf="companyPlan == 0 ">Basic</small>
                    <small *ngIf="companyPlan == 1 ">Pro</small>
                    <small *ngIf="companyPlan == 2 ">Premuim </small> /
                    <small class="pl-1" *ngIf="companyPlanType == 0"> Monthly</small>
                    <small class="pl-1" *ngIf="companyPlanType == 1"> Yearly</small> )
                </mat-expansion-panel-header>
                <div class="accordion-body" *ngIf="!isMobile">
                    <div class="pricing py-4">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="card mb-3 mb-lg-0">
                                        <div class="card-body">
                                            <h5 class="card-title text-muted text-uppercase text-center header-switch mt-3" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">Monthly
                                            </h5>
                                            <h5 class="card-title text-muted red-green text-uppercase text-center">Basic(Mover Package)</h5>
                                            <strong class="billed-annually-tittle">
                                              <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                                              <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">CRM Package</span>
                                                <br>
                                            </strong>
                                            <h6 class="card-price text-center " *ngIf="isFree">
                                                <span>Free</span>
                                            </h6>
                                            <h6 class="card-price text-center red-color" *ngIf="!isFree">
                                                <span>Free</span>
                                            </h6>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Jobs </li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Customize</strong> Items Inventory </li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Web Apps, Available on <strong>All Devices</strong></li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Customers
                                                </li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Job
                                                    <strong>Scheduling</strong></li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Calendar View
                                                    <strong>Scheduling</strong></li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Simple Jobs <strong>Status Statistics</strong></li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>
                                                    <strong>Custom</strong> Customer portal </li>
                                                <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Cloud-Based,<strong>Real-Time Updates.</strong>
                                                </li>
                                                <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Off-line Technology</strong>, Connect Later
                                                </li>
                                                <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Real-Time</strong> customize Notifications
                                                </li>
                                                <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span> Customers
                                                    <strong>Permissions Levels</strong></li>
                                                <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span>Individual Tool
                                                    <strong>Permissions Levels</strong></li>
                                                <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>No
                                                   Hidden</strong> Fee or Equipment</li>
                                            </ul>
                                            <div class="text-center more mb-3" *ngIf="!isMore" (click)="toggle()">
                                                <strong><span class="text-center"> Expand for more </span><i class="fas fa-angle-down fa-2x"></i></strong>
                                            </div>
                                            <div class="text-center more" *ngIf="isMore" (click)="toggle()">
                                                <small><span class="text-center"><i class="fas fa-angle-up fa-2x"></i></span></small>
                                            </div>
                                            <div class="text-center mb-3">
                                                <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                                <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                            </div>
                                            <div class="d-grid" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">
                                                <a type="button" *ngIf="companyPlan != 0 || companyPlan == 0 && companyPlanType == 1 && !isFree || companyPlan == 0 && companyPlanType == 0 && isFree" class="btn btn-primary text-uppercase" (click)="createCustomerPortal()">Get Started</a>
                                                <a *ngIf="companyPlan == 0 && companyPlanType == 1 && isFree || companyPlan == 0 && companyPlanType == 0 && !isFree " class="btn btn-primary text-uppercase current">Current</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card mb-3 mb-lg-0">
                                        <div class="card-body">
                                            <h5 class="card-title text-muted text-uppercase text-center header-switch" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">Monthly
                                                <span>
                                                <mat-slide-toggle *ngIf="isPro" class="mat-checked pl-2 "  (click)="changePlanYearly(2)">
                                                </mat-slide-toggle>
                                                <mat-slide-toggle *ngIf="!isPro"  (click)="changePlanYearly(2)"></mat-slide-toggle>
                                                Yearly (Save 18%)
                                              </span>
                                            </h5>
                                            <h5 class="card-title text-muted text-uppercase text-center red-green">Pro(Fleet Package)</h5>
                                            <strong class="billed-annually-tittle">
                                              <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                                              <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Mobile Car Package</span>
                                                <small *ngIf="isPro && user.categoryCompany == categoryCompany.Inventory">(Billed Annually)</small>
                                                <small *ngIf="!isPro && user.categoryCompany == categoryCompany.Inventory">(Billed Monthly)</small>
                                                <small *ngIf="user.categoryCompany == categoryCompany.Car_Wash">(Per Car, Billed Annually)</small>
                                            </strong>
                                            <div class="text-center mt-2"> <strong class="required">Book A Free Demo, Get An %80 Lifetime Discount</strong></div>
                                            <h6 class="card-price text-center red-color" *ngIf="isPro">
                                                <span>${{pricingObj.proYearly}}</span>
                                                <span>  / Month</span>
                                            </h6>
                                            <h6 class="card-price text-center red-color" *ngIf="!isPro">
                                                <span>${{pricingObj.proMonthly}} </span>
                                                <span>  / Month</span>
                                            </h6>
                                            <div class="text-center mb-3">
                                                <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                                <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                            </div>
                                            <div class="d-grid" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">
                                                <a *ngIf="companyPlan != 1 || companyPlan == 1 && companyPlanType == 1 && !isPro || companyPlan == 1 && companyPlanType == 0 && isPro" class="btn btn-primary text-uppercase" (click)="createCustomerPortal()" data-backdrop="static" data-keyboard="false">
                                                  Get Started
                                                </a>
                                                <a *ngIf="companyPlan == 1 && companyPlanType == 1 && isPro || companyPlan == 1 && companyPlanType == 0 && !isPro " class="btn btn-primary text-uppercase current">Current</a>
                                            </div>
                                            <div class="text-center">
                                                <strong> Fully Refunded Within 14 Days</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title text-muted text-uppercase text-center header-switch" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">Monthly
                                                <span>
                                                <mat-slide-toggle *ngIf="!isPremium"  (click)="changePlanYearly(3)"></mat-slide-toggle>
                                                  <mat-slide-toggle *ngIf="isPremium" class="mat-checked pl-2 "  (click)="changePlanYearly(3)">
                                                  </mat-slide-toggle>
                                                  Yearly (Save 18%)
                                                </span>
                                            </h5>
                                            <h5 class="card-title text-muted text-uppercase text-center red-green">Premuim(Warehouse Package)</h5>
                                            <strong class="billed-annually-tittle">
                                              <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                                              <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Location Package</span>
                                                <small *ngIf="isPremium && user.categoryCompany == categoryCompany.Inventory">(Billed Annually)</small>
                                                <small *ngIf="!isPremium &&  user.categoryCompany == categoryCompany.Inventory">(Billed Monthly)</small>
                                                <small *ngIf="user.categoryCompany == categoryCompany.Car_Wash">(Per Location, Billed Annually)</small>
                                            </strong>
                                            <div class="text-center mt-2"> <strong class="required">Book A Free Demo, Get An %80 Lifetime Discount</strong></div>
                                            <h6 class="card-price text-center red-color" *ngIf="isPremium">
                                                <span>${{pricingObj.premiumYearly}} </span>
                                                <span>  / Month</span>
                                            </h6>
                                            <h6 class="card-price text-center red-color" *ngIf="!isPremium">
                                                <span>${{pricingObj.premiumMonthly}} </span>
                                                <span>  / Month</span>
                                            </h6>
                                            <div class="text-center mb-3">
                                                <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                                <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                                            </div>
                                            <div class="d-grid" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">
                                                <a *ngIf="companyPlan != 2 || companyPlan == 2 && companyPlanType == 1 && !isPremium || companyPlan == 2 && companyPlanType == 0 && isPremium " class="btn btn-primary text-uppercase" (click)="createCustomerPortal()">
                                                  Get Started
                                                </a>
                                                <a *ngIf="companyPlan == 2 && companyPlanType == 1 && isPremium || companyPlan == 2 && companyPlanType == 0 && !isPremium" class="btn btn-primary text-uppercase current">Current</a>
                                            </div>
                                            <div class="text-center">
                                                <strong> Fully Refunded Within 14 Days</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-body pt-3 pb-3 text-center" *ngIf="isMobile">
                    <strong>To change the company plan, visit app.accordev.com.</strong>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
        <!-- Billing Accordion -->
        <mat-accordion class="example-headers-align " *ngIf="checkCompany(user.companyId) == true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Billing
                </mat-expansion-panel-header>
                <div class="accordion-body billing">
                    <!-- <div class="text-center mt-2 customer-portal"> -->
                    <button class="btn btn-primary" (click)="createCustomerPortal()">
                          Accordev Subscription Portal <br> Payment method, Billing , Invoices <i class="uil uil-external-link-alt uil-small pl-1"></i>
                    </button>
                    <!-- </div> -->
                    <!-- <div class="card"> -->
                    <!-- <table class="table" *ngIf="allInvoice.length > 0 ">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Payment Date & Time</th>
                                <th scope="col">Invoice Number</th>
                                <th scope="col">Amount</th>
                                <th scope="col">View/Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of allInvoice | paginate: { id: 'billing-id', itemsPerPage:3, currentPage: p2}">
                                <th scope="row">{{timeConverter(item.status_transitions.paid_at) | date :'short'}}</th>
                                <td>{{item.number}}</td>
                                <td>{{getPrice(item.lines?.data[0].amount) | currency }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-success" (click)="getInvoiceById(item.id)">
                                      <i class="uil uil-eye uil-small"></i>
                                    </button>
                                    <a target="_blank" class="btn btn-sm btn-primary" href="{{item.invoice_pdf}}" download="invoice.pdf">
                                        <i class="uil uil-import uil-small"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center mt-3" *ngIf="allInvoice.length > 0 ">
                        <pagination-controls id="billing-id" (pageChange)="p2 = $event"></pagination-controls>
                    </div>
                    <div class="ant-empty-image" *ngIf="allInvoice.length <= 0 ">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div> -->

                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- strip-key -->
        <div class="strip-key mb-2" *ngIf="user.categoryCompany == categoryCompany.Car_Wash && user.role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel (opened)="getCompanyStripeKey();isProductsCompanyById()">
                    <mat-expansion-panel-header>
                        Stripe Integration
                    </mat-expansion-panel-header>
                    <div class="accordion-body">
                        <mat-tab-group [disableRipple]="false">
                            <mat-tab label="Step 1">
                                <h6>Create a stripe account<span class="text-blue">*</span></h6>
                                <div class="strip-key-note mt-2">
                                    <!-- <strong class="required pl-4">Note: </strong> -->
                                    <ol>
                                        <li>Create a free stripe account for your business: <a href="https://stripe.com" target="_blank">Stripe<i class="uil uil-external-link-alt uil-medium pr-2"></i></a></li>
                                        <li>Keep stripe account in <span class="required">the test mode</span>, in order to try the Accordev software.</li>
                                        <li>If you choose to move forward with the Accordev software , you can activate stripe account to the live data mode .</li>
                                    </ol>
                                    <small class="text-blue"> *stripe is a payment gate for your business customers.</small>
                                </div>
                            </mat-tab>
                            <mat-tab label="Step 2">
                                <h6>Import Stripe Secret Key</h6>
                                <div class="tittle-section">
                                    <p> Stripe Secret Key</p>
                                    <span *ngIf="secret_keyObj">{{secret_keyObj.updateTime | date:'short'}}</span>
                                    <div class="btn-add-new">
                                        <span class="badge badge-success" (click)="stripKeyModal.show();storeStripKey()">
                                      <span *ngIf="secret_keyObj?.keyValue == ''">Import</span>
                                        <span *ngIf="secret_keyObj?.keyValue != ''">Import</span>
                                        </span>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Step 3">
                                <h6>Add Products Perks</h6>
                                <div *ngIf="products.length > 0">
                                    <div class="tittle-section" *ngFor="let product of products">
                                        <p> {{product.name}} (Stripe)</p>
                                        <div class="btn-add-new">
                                            <span class="badge badge-success" (click)="storeProduct(product);stripDescriptionModal.show()">Edit Perks</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="products.length <= 0">
                                    <p>No product added in the stripe yet.</p>
                                </div>
                            </mat-tab>
                            <!-- <mat-tab label="Step 4">
                                <h6>Add an email for the cash membership (One time only).</h6>
                                <div class="tittle-section" *ngIf="secret_keyObj">
                                    <p> a stripe cash email</p>
                                    <span *ngIf="isCustomerCash">{{customerCash.creationDate | date:'short'}}</span>
                                    <div class="btn-add-new">
                                        <span class="badge badge-success" (click)="customerCashModal.show();storeStripKey()">
                                         <span *ngIf="!isCustomerCash">Create</span>
                                        <span *ngIf="isCustomerCash">View</span>
                                        </span>
                                    </div>
                                </div>
                                <span *ngIf="!secret_keyObj">
                                  Fisrt, import the secret key in step 2
                                </span>
                            </mat-tab> -->
                        </mat-tab-group>
                        <!-- <div class="view-secret-key">
                            <h6>Secret Key</h6>
                            <p>{{secret_keyObj.keyValue}}</p>
                        </div> -->
                        <!-- <div class="mx-auto">
                            <button class="btn btn-sm btn-success" *ngIf="secret_key == ''" [disabled]="secret_key == ''" (click)="addSecretKey()">Add Key</button>
                            <button class="btn btn-sm btn-success" *ngIf="secret_key != ''" [disabled]="secret_key == ''" (click)="addSecretKey()">Update Key</button>
                        </div> -->
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="roles" *ngIf="role == 'admin' ">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Manager(s) Who Can Access Company Account
                    </mat-expansion-panel-header>
                    <div class="accordion-body">
                        <h6>Override Managers Default Permission For Company Account</h6>
                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th scope="col">Manager</th>
                                    <th scope="col">Defualt</th>
                                    <th scope="col">Custom</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of managers">
                                    <th>{{item.lastName}}, {{item.firstName}}</th>
                                    <th>
                                        <span *ngIf="item.defaultPermission.value == 0">No Access</span>
                                        <span *ngIf="item.defaultPermission.value == 2">View Only</span>
                                        <span *ngIf="item.defaultPermission.value == 1">Can Edit</span>
                                    </th>
                                    <th>
                                        <div *ngIf="item.disable">
                                            <span>Disdable </span>
                                        </div>
                                        <div *ngIf="!item.disable">
                                            <select #mySelect class="form-control" *ngIf="role == 'admin' || mangerObjPermission != null && mangerObjPermission.permission == 1" [(ngModel)]="item.permission" (change)="storeMangerItem(mySelect.value,item);changePermissionModal.show()">
                                            <option value="0">No Access</option>
                                            <option value="1">Can Edit</option>
                                        </select>
                                            <span *ngIf="role == 'manager' && mangerObjPermission != null && mangerObjPermission.permission == 2 &&  item.permission == 0">No Access</span>
                                            <span *ngIf="role == 'manager' && mangerObjPermission != null && mangerObjPermission.permission == 2 &&  item.permission == 1">Can Edit</span>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ant-empty-image mt-2" *ngIf="managers.length <= 0 ">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No User with a Manager Role Yet.</span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal modal-billing" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Change Company Plan?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="restoreCompanySubscription()">
                    <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body">
                    <!-- <p class="text-secondary"></p> -->
                    <!-- <div class="invoice-tittle">
                        <p *ngIf="invoiceUpcoming.number">INVOICE #{{invoiceUpcoming.number}} </p>
                        <h4>${{invoiceUpcoming.amount_paid}}</h4>
                    </div> -->
                    <p class="text-secondary1">SUMMARY</p>
                    <hr>
                    <div class="summary">
                        <div class="item-summary">
                            <strong>To</strong> <span>	{{invoiceUpcoming.customer_email}}</span>
                            <span></span>
                        </div>
                        <div class="item-summary">
                            <strong>From</strong> <span>	{{invoiceUpcoming.account_name}}</span>
                            <span></span>
                        </div>
                        <div class="item-summary" *ngIf="invoiceUpcoming.number">
                            <strong>Invoice</strong> <span>	#{{invoiceUpcoming.number}}</span>
                            <span></span>
                        </div>
                    </div>
                    <br>
                    <p class="text-secondary1">Upcoming invoice</p>
                    <hr>
                    <!-- <span>This is a preview of the invoice that will be billed on <strong>Jul 25, 2023</strong> . It may change if the subscription is updated.</span> -->
                    <!-- <hr> -->
                    <!-- <div class="items">
                        <span class="item-date">{{period_start1 |date :'MMM d, y'}} - {{period_end1 |date :'MMM d, y'}}</span>
                        <div class="item-summary">
                            <strong>Trial period for Basic</strong>
                            <span>${{invoiceUpcoming.subtotal_excluding_tax}} </span>
                        </div>
                        <br>
                        <div>
                            <span><b>Subtotal:</b> 	${{invoiceUpcoming.subtotal_excluding_tax}} </span> <br>
                            <span><b>Total:</b> 	${{invoiceUpcoming.subtotal_excluding_tax}} </span> <br>
                            <span> <b>Amount due :</b>	${{invoiceUpcoming.amount_due}} </span> <br>
                        </div>
                    </div> -->
                    <table class="table mt-3">
                        <thead>
                            <tr>
                                <th scope="col">DESCRIPTION</th>
                                <th scope="col">QTY</th>
                                <th scope="col">UNIT PRICE </th>
                                <th scope="col">AMOUNT </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{{period_start1 |date :'MMM d, y'}} - {{period_end1 |date :'MMM d, y'}}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Plan Name</th>
                                <th>{{invoiceUpcoming.lines?.data[0].quantity}}</th>
                                <th>${{invoiceUpcoming.lines?.data[0].price.unit_amount}}</th>
                                <th>${{invoiceUpcoming.lines?.data[0].plan.amount}}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <td>Subtotal</td>
                                <td>${{invoiceUpcoming.subtotal}}</td>
                            </tr>
                            <tr class="no-border">
                                <th></th>
                                <th></th>
                                <td>Total</td>
                                <td>${{invoiceUpcoming.total}}</td>
                            </tr>
                            <tr class="no-border">
                                <th></th>
                                <th></th>
                                <td>Applied balance</td>
                                <td>${{invoiceUpcoming.subtotal_excluding_tax}}</td>
                            </tr>
                            <tr class="no-border">
                                <th></th>
                                <th></th>
                                <td>Amount due</td>
                                <td>${{invoiceUpcoming.amount_due}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="total">
                        <hr>
                        <div class="item-summary">
                            <span>Amount due</span> <span>	${{invoice.amount_due}} </span>
                        </div>
                    </div> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="restoreCompanySubscription()">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success" data-dismiss="modal" (click)="updateCompanyForce(typePlan)">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal Tag -->
<div mdbModal #changePermissionModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="changePermissionModal.hide()">
                       <span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body text-center">
                    <div *ngIf="permissionValue == 0">
                        <p>Change manager access?</p>
                        <small class="btn-danger">If a user with the role manager,have the permission [No Access]</small>
                        <p class="text-secondary"><small>- User will no longer be able to access and edit company account.</small></p>
                    </div>
                    <div *ngIf="permissionValue == 1">
                        <p>Change manager access?</p>
                        <small class="btn-danger">If a user with the role manager,have the permission [Can Edit]</small>
                        <p class="text-secondary">
                            <small>- User will be able to access and edit company plan.</small><br>
                            <small>- User will be able to edit company information and address.</small><br>
                            <small>- User will not be able to invite a manager.</small><br>
                            <small>- User will not be able to manage other managers access to company account.</small><br>
                            <small>- User will not be able to access to Stripe Integration.</small>
                        </p>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button " class="btn btn-sm btn-danger " (click)="changePermissionModal.hide()">Cancel</button>
                    <button type="button " class="btn btn-sm btn-success " (click)="updateMangerPermission(permissionValue,mangerId);changePermissionModal.hide()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div id="LogoModal" mdbModal #LogoModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                <button type="button" class="close" data-dismiss="modal" (click)="LogoModal.hide()">
          <span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <p>Do you want to save changes to this page before closing?</p>
                <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="LogoModal.hide()">Close</button>
                <button type="button" class="btn btn-sm btn-success" (click)="changeLogo();LogoModal.hide() ">Save </button>
                <!-- <button class="btn btn-success btn-block" data-dismiss="modal" (click)="changeLogo()">OK</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Modal billing strip -->
<div id="stripBillingModal" mdbModal #stripBillingModal="mdbModal" class="modal modal-billing">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Invoice Summary</h5>
                <button type="button" class="close" data-dismiss="modal" (click)="stripBillingModal.hide()">
                    <span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="invoice-tittle">
                    <p>INVOICE #{{invoice.number}} </p>
                    <h4>{{getPrice(invoice.lines?.data[0].amount) | currency }}</h4>
                    <!-- <h4>{{600 | currency }}</h4> -->
                </div>
                <p class="text-secondary">SUMMARY</p>
                <hr>
                <div class="summary">
                    <div class="item-summary">
                        <strong>To</strong> <span>	{{invoice.customer_email}}</span>
                        <span></span>
                    </div>
                    <div class="item-summary">
                        <strong>From</strong> <span>{{invoice.account_name}}</span>
                        <span></span>
                    </div>
                    <div class="item-summary">
                        <strong>Invoice</strong> <span>	#{{invoice.number}}</span>
                        <span></span>
                    </div>
                </div>
                <br>
                <p class="text-secondary">ITEMS</p>
                <hr>
                <div class="items">
                    <span class="item-date">{{period_start |date :'MMM d, y'}} - {{period_end |date :'MMM d, y'}}</span>
                    <div class="item-summary">
                        <strong>{{invoice.lines?.data[0].description}}</strong> <span> {{getPrice(invoice.lines?.data[0].amount) | currency }} </span>
                    </div>
                </div>
                <div class="total">
                    <hr>
                    <div class="item-summary">
                        <span>Amount due</span> <span>	{{getPrice(invoice.lines?.data[0].amount) | currency }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #myModal="mdbModal" class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="myModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p>Change logo?</p>
                    <!-- <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="myModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-success" (click)="changeLogo();myModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- strip key modal -->
<div id="myModal" mdbModal #stripKeyModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Update Stripe Secret Key</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="stripKeyModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body strip_key-body">
                <div class="date-update pl-2 mb-2">
                    <small>Last Updated: <small *ngIf="secret_keyObj">{{secret_keyObj.updateTime | date:'short'}}</small></small>
                </div>
                <ol>
                    <li>Sign In to your strip account.</li>
                    <li>Go to: Developers> APIs Keys >Secret Key> Reveal Key</li>
                    <li>Copy the secret key.</li>
                    <li>
                        Open this window again and paste the secret key below:
                        <div class="form-group mt-2">
                            <textarea name="secret-key" id="secret-key" class="form-control" placeholder="Secret Key" [(ngModel)]="secret_key_edit" cols="30" rows="3"></textarea>
                            <!-- <p class="mt-2">
                                <small class="required"> How to look it up: </small> <small>Sign In to your strip account> Developers> APIs Keys >Secret Key> Reveal Key</small>
                            </p> -->
                        </div>
                    </li>
                    <li>Click [Update Key]</li>
                </ol>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger" (click)="stripKeyModal.hide()">Cancel</button>
                <button class="btn btn-sm btn-success" *ngIf="secret_keyObj?.secret_key == ''" [disabled]="secret_key_edit == ''" (click)="addSecretKey();stripKeyModal.hide()">Add Key</button>
                <button class="btn btn-sm btn-success" *ngIf="secret_keyObj?.secret_key != ''" [disabled]="secret_key_edit == ''" (click)="addSecretKey();stripKeyModal.hide()">Update Key</button>
            </div>
        </div>
    </div>
</div>
<!-- descripion strip key modal -->
<div id="myModal" mdbModal #stripDescriptionModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Add product perks and prices for {{bufferProduct.name}}</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="stripDescriptionModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body strip_key-body">
                <div class="date-update pl-1 mb-2">
                    <small>Last Updated: <small *ngIf="secret_keyObj">{{secret_keyObj.updateTime | date:'short'}}</small></small>
                </div>
                <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
                    <mat-tab label="prices">
                        <div class="single-wash">
                            <h6>Single wash prices </h6>
                            <div class="single-wash-item single-wash-item-first">
                                <p class="single-wash-item-label">Single wash price <span class="text-bold">Before</span> discount</p>
                                <span class="single-wash-item-input">
                          <div class="input-group input-group-sm ">
                            <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                                <input type="text" class="form-control" [ngModel]="bufferProduct?.priceSingleWash?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Single_Wash_Price_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                                <span></span>
                            </div>
                            </span>
                            <div>
                                <mat-slide-toggle (change)="handleChange($event,ProductEditType.Single_Wash_Price_Before)" [disabled]="bufferProduct?.priceSingleWash?.priceBefore === ''" [checked]="bufferProduct?.priceSingleWash?.isShow"> </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="single-wash-item">
                            <p class="single-wash-item-label">Single wash price <span class="text-bold">After</span> discount</p>
                            <span class="single-wash-item-input">
                          <div class="input-group input-group-sm ">
                            <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                            <input type="text" class="form-control" [ngModel]="bufferProduct?.priceSingleWash?.priceAfter" (ngModelChange)="updatePriceBefore($event,ProductEditType.Single_Wash_Price_After)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                        </div>
                        </span>
                        <span class="btn-fake"></span>
            </div>
        </div>
        <div class="single-wash">
            <h6>Membership prices </h6>
            <div class="single-wash-item single-wash-item-first">
                <p class="single-wash-item-label">Membership price <span class="text-bold">Before</span> discount</p>
                <span class="single-wash-item-input">
                      <div class="input-group input-group-sm ">
                        <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                <input type="text" class="form-control" [ngModel]="bufferProduct?.priceBeforeDiscount?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Price_Product_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                <span></span>
            </div>
            </span>
            <div>
                <mat-slide-toggle (change)="handleChange($event,ProductEditType.Price_Product_Before)" [disabled]="bufferProduct?.priceBeforeDiscount?.priceBefore === ''" [checked]="bufferProduct?.priceBeforeDiscount?.isShow"> </mat-slide-toggle>
            </div>
        </div>
        <div class="single-wash-item">
            <p class="single-wash-item-label">Membership price <span class="text-bold">After</span> discount</p>
            <span class="single-wash-item-input">
            <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b>
                      <!-- <div class="input-group input-group-sm ">
                        <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div> -->
        </span>
        <span class="btn-fake"></span>
    </div>
</div>
<div class="single-wash mb-0">
    <h6>Max Car</h6>
    <div class="single-wash-item single-wash-item-first">
        <p class="single-wash-item-label"> Max car price <span class="text-bold">Before</span> discount</p>
        <span class="single-wash-item-input">
                  <div class="input-group input-group-sm ">
                    <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
        <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Price_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        <span></span>
    </div>
    </span>
    <div>
        <mat-slide-toggle (change)="handleChange($event,ProductEditType.Car_Max_Price_Before)" [disabled]="bufferProduct?.productsMaxCar?.priceBefore === ''" [checked]="bufferProduct?.productsMaxCar?.isShow"> </mat-slide-toggle>
    </div>
</div>
<div class="single-wash-item single-wash-item-first">
    <p class="single-wash-item-label"> Max car price <span class="text-bold">After</span> discount</p>
    <span class="single-wash-item-input">
        <!-- <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b> -->
                  <div class="input-group input-group-sm ">
                    <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
    <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.priceAfter" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Price_After)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
</div>
</span>
<span class="btn-fake"></span>
</div>
<div class="single-wash-item ">
    <p class="single-wash-item-label"> Number of Cars <span class="text-bold">(Max Cars)</span> </p>
    <span class="single-wash-item-input">
      <!-- <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b> -->
                <div class="input-group input-group-sm ">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Num</span>
    <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.countCar" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Num_Of_Cars)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
</div>
</span>
<span class="btn-fake"></span>
</div>
</div>
</mat-tab>
<mat-tab label="Description & Perks">
    <div class="date-update strip-description pl-2 mb-2">
        <p>Product Description (Stripe)</p>
        <small>{{bufferProduct.description}}.</small>
    </div>
    <form class="form">
        <ol>
            <li *ngFor="let item of bufferProduct.customDescription">
                <input type="text" class="form-control" name="{{item.id}}" [(ngModel)]="item.description" placeholder="Add Perk here">
            </li>
        </ol>
    </form>
</mat-tab>
</mat-tab-group>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-danger" (click)="stripDescriptionModal.hide()">Cancel</button>
    <button class="btn btn-sm btn-success" (click)="addDesorptionProduct();stripDescriptionModal.hide()">Save</button>
</div>
</div>
</div>
</div>
<div id="myModal" mdbModal #changeCategoryModal="mdbModal" class="modal ">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Change Business Industry</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="changeCategoryModal.hide();resetChangeIndustry()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body text-center">
                <p>If you change the bussiness industry, <br> - Add-Ins Store. <br> - Knowledge Base. <br> - Jobs List. <br> will be customized in order to be compatible with that industry selected.</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger" (click)="changeCategoryModal.hide();resetChangeIndustry()">Cancel</button>
                <button class="btn btn-sm btn-success" (click)="updateCompanyCategory();changeCategoryModal.hide()">Confirm</button>
            </div>
        </div>
    </div>
</div>
<!-- Email Customer Cash modal -->
<div id="myModal" mdbModal #customerCashModal="mdbModal" class="modal cashModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel">Set stripe cash payment method (One time only).</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="customerCashModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
            </div>

            <div class="modal-body strip_key-body">
                <p>In order for your business to receive cash from customers, the business must: </p>
                <ol>
                    <li>Have a cash card, which will be used by the business whenever cash is received.</li>
                    <li> - Have stripe cash membership, which is a regular stripe membership (similar to the one usually created by a customer), but it is created by the business owner, where the business can list all cash subscriptions under that membership.</li>
                </ol>
                <form #cashFrom="ngForm">
                    <div class="form-group mt-2">
                        <label for="v">Provide a new email</label>
                        <!-- <input name="customerEmailCash" id="customerEmailCash" *ngIf="isCustomerCash" readonly class="form-control" [(ngModel)]="customerCash.email" placeholder="Email must be different than the main stripe account email"> -->
                        <input name="email" id="customerEmail" class="form-control" required placeholder="Email must be different than the main stripe account email" [(ngModel)]="customerEmailCash" #customerEmail="ngModel" [pattern]="emailPattern">
                        <div *ngIf="customerEmail.errors && customerEmail.touched" class="alert alert-danger">
                            <div *ngIf="customerEmail.errors.required">
                                Email is required.
                            </div>
                            <div *ngIf="customerEmail.errors.pattern">
                                Email not valid.
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                    <div class="modal-footer">
                        <button class="btn btn-sm btn-danger" (click)="customerCashModal.hide()">Cancel</button>
                        <button class="btn btn-sm btn-success" *ngIf="!isCustomerCash" [disabled]="cashFrom.invalid" (click)="createMemberShip(bufferVehicleId);customerCashModal.hide()">Create Cash Membership</button>
                        <button class="btn btn-sm btn-success" *ngIf="isCustomerCash" [disabled]="cashFrom.invalid" (click)="updateCashEmailCustomer(customerCash.id);customerCashModal.hide()">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- success add Customer Cash modal -->
<div id="myModal" mdbModal #successCustomerCashModal="mdbModal" class="modal successCashModal">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Email successfully added</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="successCustomerCashModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
            </div>
            <div class="modal-body text-center">
                <strong>Cash card information will be added, when the first cash subscription is maid.</strong>
            </div>
        </div>
    </div>
</div>