import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";

export const ValidationModel ={
  emailPattern : "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
  numberPattern : "^[0-9]*$",
  complexPattern : "^[a-zA-Z0-9]+$",
  passwordPattern:  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
}

