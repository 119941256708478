import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let user  = JSON.parse(localStorage.getItem('user'))
      // console.log('this is logging from interceptor :')
      // console.log(this._authService.LoggedIn())
      if ([401].indexOf(err.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        this._authService.RefreshToken().subscribe((response:ResponseModel)=>{
          user.token = response.data.token
          localStorage.setItem('user', JSON.stringify(user))
          // window.location.reload();
          window.location.reload();
        },error=>{
        });
        // this._authService.logout()
      }
      else if([500].indexOf(err.status) !== -1){
         this.toastr.error('please try again', 'An unexpected error occurred',
         {
           progressBar: true,
           progressAnimation: "increasing",
           timeOut: 3000
         });
       }
      else if([403].indexOf(err.status) !== -1){
        this.toastr.error('You do NOT have the permission.', '',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
      }
      else if([405].indexOf(err.status) !== -1){
        this.toastr.error('You do NOT have the permission.', '',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
      }
      else if([409].indexOf(err.status) !== -1){
        this.toastr.error(' please check email', 'User is disable',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
      }
      // const error = err.error.message || err.statusText;
      const error = err.error || err.statusText;
      // console.error('error from interceptors',err.error.message)
      // console.log('error from interceptors',err.status)
      return throwError(error);
    }))
  }
}
