import { OthersTool } from './../permissions/ToolsObj/data-tool';
import { ToastrService } from 'ngx-toastr';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { Socket } from 'ngx-socket-io';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { EmptyJobDetails } from './../../../../models/job-details/emptyJobDetails';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { JobsService } from './../../../../core/_services/jobs/jobs.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig, DateFormatter } from 'ngx-bootstrap/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from 'src/app/models/person/Person';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { DatePipe } from '@angular/common';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { ToolDashboardEditItem } from 'src/app/models/tools-model/ToolDashboardEditItem';
import { ModalDirective } from 'angular-bootstrap-md';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
import { JobStatusEnum } from 'src/app/models/job-details/JobStatusEnum';
import { CategoryCompany } from '../permissions/Enum-Category/CategoryCompany';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment.prod';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
declare var $
interface Date {
  date: string
}
@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.scss']
})
export class CompanyAdminComponent implements OnInit {
  aAftarTomorrow
  aftarTomorrow
  tomorrow
  today
  yesterday
  beforeYesterday
  bBeforeYesterday
  // Start moment Date
  datevalue: any;
  days: any = [];
  date = moment();
  // weekno = Math.ceil(this.date.date() / 7);
  // weekno = Math.ceil(this.date.date());
  weekno = moment(this.date).isoWeek();
  // End Moment Date
  dates: Date[] = new Array<Date>();
  events: string[] = [];
  bsConfig: Partial<BsDatepickerConfig>;
  jobs: any[] = []
  usersJobs: any[] = []
  publicId: any
  emptyJobDetails: EmptyJobDetails
  usersToCompany: Person[] = []
  customersToCompany: Person[] = []
  trucksToCompany: any[] = []
  jobModel: any = {}
  flipDiv = false;
  editModel: any = {}
  role: any
  email: any
  company: any
  phone: any
  user: any
  jobStatus: any = {}
  jobsStatus: any = {}
  datesByToday: any[] = []
  countJobsByDate: any = []
  jobDetailsModel: any = {}
  jobsInYear: any = []
  globalTool: any
  dashboardTools: any[]
  settingsTools: any
  newJobPermission: any = {}
  carMangerPermission: any = {}
  calendarPermission: any = {}
  // whorehousePermission: any = {}
  jobsListPermission: any = {}
  myAccountPermission: any = {}
  searchSpacePermission: any = {}
  jobStatisticsPermission: any = {}
  subscription: Subscription;
  textSerach = ''
  dateItemPicker: any = ''
  selected: any = {}
  dateObj: any
  p: number = 1
  p1: number = 1
  p2: number = 1
  p3: number = 1
  p4: number = 1
  p7: number = 1
  p8: number = 1
  countryCode = true
  admins = [] = []
  yearStatistic: any
  person: any = {}
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('userAgentModal') userAgentModal: ModalDirective;
  jobsSearch: any[] = []
  jobsCalender: any[] = []
  jobsStatistic: any[] = []
  jobText: string = ''
  planCompanyType: any = PlanCompanyType
  jobStatusEnum: any = JobStatusEnum
  jobsPagination: any[] = []
  paginationObj: any = {}
  bufferPage: number
  categoryCompany = CategoryCompany
  userAgentObj: any = {}
  platformStr = ''
  isMobile = false
  baseDomain = environment.baseDomain
  bufferJobId: string = ''
  jobsClone: any = []
  constructor(
    private route: ActivatedRoute,
    private _jobsService: JobsService,
    private _companiesService: CompaniesService,
    private _jobDetailsService: JobDetailsService,
    private _personService: PersonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _toolsService: ToolsService,
    public datePipe: DatePipe,
    private socket: Socket,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.yearStatistic = this.datePipe.transform(new Date(), 'y')
    this.countryCode = this._companiesService.getCountry()
    this.dateObj = new Date();
    this.today = this.dateObj.toLocaleString()
    this.getweeks(this.date);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.bsConfig = {
      containerClass: 'theme-blue',
      showClearButton: true,
      clearPosition: 'left',
      showTodayButton: true,
      todayPosition: 'center',
      adaptivePosition: true
    };
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user.role
    this.email = this.user.email
    // get jobs resolver
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['company'].success) {
        this.company = response['company'].data
        this.jobsStatus = response['company'].data.jobsStatus
      }
      if (response['tools'].success) {
        let roleName = this.role
        this.dashboardTools = response['tools'].data.find(d => d.toolType === ToolType.Dashboard_Tools)
        this.settingsTools = response['tools'].data.find(d => d.toolType === ToolType.Setting_Tools)
        this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
            this.newJobPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job, ToolType.Dashboard_Tools, permissionList)
            this.carMangerPermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
            this.searchSpacePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Search_Space, ToolType.Dashboard_Tools, permissionList)
            //  console.log(this.searchSpacePermission)
            this.jobStatisticsPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Job_status_Statistics, ToolType.Dashboard_Tools, permissionList)
            this.calendarPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Calendar, ToolType.Dashboard_Tools, permissionList)
            // this.whorehousePermission = this._toolsService.getOverridePermission(roleName,this.settingsTools, ToolSettingEditItem.Warehouse_Map, ToolType.Setting_Tools, permissionList)
          }
        })
        // this.newJobPermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job)
      }
      // if(response['adminsInCompany'].success){
      //   this.admins =  response['adminsInCompany'].data
      //   console.log('admins in company')
      //   console.log(this.admins)
      // }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Tool_Change, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
          let roleName = this.role
          this.dashboardTools = response.data.find(d => d.toolType === ToolType.Dashboard_Tools)
          this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
              this.newJobPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job, ToolType.Dashboard_Tools, permissionList)
              this.searchSpacePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Search_Space, ToolType.Dashboard_Tools, permissionList)
              this.jobStatisticsPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Job_status_Statistics, ToolType.Dashboard_Tools, permissionList)
              this.calendarPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Calendar, ToolType.Dashboard_Tools, permissionList)
              this.carMangerPermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
              // this.whorehousePermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Warehouse_Map, ToolType.Setting_Tools, permissionList)
            }
          })
          // this.newJobPermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job)
          // this.searchSpacePermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Search_Space)
          // this.jobStatisticsPermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Job_status_Statistics)
          // this.calendarPermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Calendar)
          // this.whorehousePermission = this._toolsService.getPermission(roleName, this.dashboardTools, ToolDashboardEditItem.Warehouse_Map)
        }, error => {
          console.log(error)
        })
      }
    });
    // this.emptyJobDetails = new EmptyJobDetails()
    this.getJobsByDate(this.dateObj)
    this.getAllJobsByUser()
    this.getCountJobsByDate()
    this.getJobStaticsYear()
    // realtime jobs list
    this.socket.on(RealTimeKeys.Job_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._jobsService.getJobSummaryById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.jobsCalender.unshift(response.data)
          }
        }, error => {
          console.log(error)
        })
      }
    });
    // this.socket.on(RealTimeKeys.Switch_Account, (data: any) => {
    //   console.log('Switch_Account data',data)
    //   if (this.user.companyId != data.id) {
    //     console.log('enter',data)
    //     this.user = JSON.parse(localStorage.getItem('user'));
    //     this.dateObj = new Date();
    //     this.today = this.dateObj.toLocaleString()
    //     this.getJobsByDate(this.dateObj)
    //     this.getAllJobsByUser()
    //     this.getCountJobsByDate()
    //     this.getJobStaticsYear()
    //     window.location.reload()
    //   }
    // });
    this.getAllJobsByPagination(3, 0, 0)
  }
  getCountJobsByDate() {
    this.datesByToday = []
    for (let index = 0; index < this.days.length; index++) {
      this.datesByToday.push(this.days[index].year + '-' + this.days[index].month + '-' + this.days[index].value);
    }
    let model = {
      dates: this.datesByToday
    }
    this._jobsService.getCountJobsByDate(this.user.companyId, model).subscribe((response: ResponseModel) => {
      this.countJobsByDate = response.data
    })
  }
  // getAllJobsBySearch
  getAllJobsBySearch() {
    let textSerach = this.textSerach.trim()
    this._jobsService.getAllJobsBySearch(textSerach).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (response.data == null) {
          this.jobsSearch = []
        }
        else {
          this.jobsSearch = response.data
        }
      }
    }, error => {
      console.log(error)
    })
  }
  // getAllJobsByPagination(pageSize: any, last: any) {
  //   this._jobsService.getAllJobsByPagination(pageSize, last).subscribe((response: ResponseModel) => {
  //     console.log('getAllJobsByPagination',response)
  //   })
  // }
  getAllJobsByUser() {
    return this._jobsService.getJobs(this.user.companyId).subscribe((response: ResponseModel) => {
      this.jobs = response.data
      this.jobsClone = this.jobs.filter(j => j.isPinToClone && j.isPinToClone == true)
    }, error => {
    })
  }
  getWeek() {
    // yesterday
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    this.yesterday = yesterday.toLocaleString()
    this.dates.push({ date: this.yesterday });
    // before yesterday
    let beforeYesterday = new Date()
    beforeYesterday.setDate(beforeYesterday.getDate() - 2)
    this.beforeYesterday = beforeYesterday.toLocaleString()
    this.dates.push({ date: this.beforeYesterday });
    // before before yesterday
    let bBeforeYesterday = new Date()
    bBeforeYesterday.setDate(bBeforeYesterday.getDate() - 3)
    this.bBeforeYesterday = bBeforeYesterday.toLocaleString()
    this.dates.push({ date: this.bBeforeYesterday });
    // Today
    this.dateObj = new Date();
    this.today = this.dateObj.toLocaleString()
    this.dates.push({ date: this.today });
    // tomorrow
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    this.tomorrow = tomorrow.toLocaleString()
    this.dates.push({ date: this.tomorrow });
    // aftar  tomorrow
    let aftarTomorrow = new Date()
    aftarTomorrow.setDate(aftarTomorrow.getDate() + 2)
    this.aftarTomorrow = aftarTomorrow.toLocaleString()
    this.dates.push({ date: this.aftarTomorrow });
    // aftar aftar  tomorrow
    let aAftarTomorrow = new Date()
    aAftarTomorrow.setDate(aAftarTomorrow.getDate() + 3)
    this.aAftarTomorrow = aAftarTomorrow.toLocaleString()
    this.dates.push({ date: this.aAftarTomorrow });
  }
  getAllJobsByPagination(pageSize, last, pageNumber) {
    this._jobsService.getAllJobsByPagination(this.user.companyId, pageSize, last, pageNumber).subscribe((response: ResponseModel) => {
      // console.log('getAllJobsByPagination',response)
      this.paginationObj = response
      this.jobsPagination = response.data
    })
  }
  handlePageChange(event) {
    // console.log('event', event)
    this.bufferPage = this.p7
    this.p7 = event
    if (event == 1) {
      this.paginationObj.previous = 0
    }
    if (this.bufferPage > event) {
      // console.log('previous')
      this.getAllJobsByPagination(3, this.paginationObj.previous, this.p7)
    }
    else {
      // console.log('next')
      this.getAllJobsByPagination(3, this.paginationObj.next, this.p7)
    }
  }
  printDay() {
  }
  change(event) {
  }
  chooseDate(event) {
  }
  serachJob(event: any) {
    // console.log(event.value)
    this.p = 1
  }
  // Functions Moment Date
  nextweek() {
    // this.date = moment();
    this.date.add(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
  }
  previousweek() {
    this.date.subtract(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
  }
  getweeks(currentDate) {
    this.days = [];
    var weekStart = currentDate.clone().startOf('week');
    var weekEnd = currentDate.clone().endOf('week');
    // console.log('start week' + moment(weekStart).add(i, 'days').format("ddd[\r\n]DD"))
    for (var i = 0; i <= 6; i++) {
      this.days.push(
        {
          name: moment(weekStart).add(i, 'days').format("ddd"),
          value: moment(weekStart).add(i, 'days').format("DD"),
          year: moment(weekStart).add(i, 'days').format("YYYY"),
          month: moment(weekStart).add(i, 'days').format("MM")
        }
      );
    };
  }
  previousYear() {
    this.yearStatistic = moment(this.yearStatistic).subtract(1, 'year').format('YYYY').toString();
    this.getJobStaticsYear();
  }
  nextYear() {
    this.yearStatistic = moment(this.yearStatistic).add(1, 'year').format('YYYY').toString();
    this.getJobStaticsYear();
  }
  storeId(id, type: number) {
    this.publicId = id
    switch (type) {
      case 0: {
        this.jobModel = this.jobs.find(j => j.id == id)
        break;
      }
      case 1: {
        this.jobModel = this.jobsSearch.find(j => j.id == id)
        break;
      }
      case 2: {
        this.jobModel = this.jobsStatistic.find(j => j.id == id)
        break;
      }
      case 3: {
        this.jobModel = this.jobsCalender.find(j => j.id == id)
        break;
      }
    }
    // this.jobModel = this.jobsCalender.find(j => j.id == id)
    let jobModel = {
      jobNumber: this.jobModel.jobNumber,
      primaryCustomerName: this.jobModel.primaryCustomerName,
      jobId: this.jobModel.id,
      jobType: this.jobModel.jobType
    }
    localStorage.setItem('jobModel', JSON.stringify(jobModel))
    // this._jobsService.getJobById(id).subscribe((response: ResponseModel) => {
    //   this.jobModel.steps = response.data.steps
    //   this.jobModel.datesAndTimes = response.data.jobDetails
    //   if (response.data.primaryCustomer != 'Customer Name') {
    //     this._personService.getPersonById(response.data.primaryCustomer).subscribe((personResponse: ResponseModel) => {
    //       if (personResponse.success) {
    //         this.person = personResponse.data
    //         this.basicModal.show()
    //       }
    //     })
    //   }
    //   else {
    //     this.person = {}
    //     this.basicModal.show()
    //   }
    // })
    this.router.navigate(['/admin/job-details/', this.publicId])
  }
  newJob() {
    this.emptyJobDetails = new EmptyJobDetails()
    let todayDate = new Date();
    let creationDate = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate());
    this.emptyJobDetails.dateAndTime.push(
      {
        id: 1,
        startDateWindowFrom: creationDate,
        startDateWindowTo: creationDate,
        startTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        startTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        endDateWindowFrom: creationDate,
        endDateWindowTo: creationDate,
        endTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        endTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        jobDescription: " ",
        summaryDate: creationDate + ' --> ' + creationDate
      }
    )
    let users = []
    users.push(this.user.id)
    let model = {
      companyId: this.user.companyId,
      primaryCustomer: 'Customer Name',
      companyNumber: this.user.companyNumber,
      jobType: 'Job Type',
      isPinToClone: false,
      assignedCustomers: [],
      assignedUsers: users,
      assignedTrucks: [],
      creationDate: creationDate,
      creationTime: todayDate.toLocaleTimeString(),
      creationUserId: this.user.id,
      jobDetails: this.emptyJobDetails
    }
    this._jobsService.addJob(model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let jobModel = {
          jobNumber: response.data.jobNumber,
          jobName: response.data.jobName,
          jobId: response.data.id,
          jobType: response.data.jobType,
          primaryCustomerName: response.data.primaryCustomer
        }
        localStorage.setItem('jobModel', JSON.stringify(jobModel))
        this.router.navigate(['/admin/job-details', response.data.id])
        this.toastr.success('', 'Successfully Add  Draft Job', {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        })
      }
      else {
        this.toastr.error('', 'Error Add  Draft Job', {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        })
      }
    })
  }
  activatePermission() {
    this.newJobPermission.itemStatus === 'Activate' ? true : false
  }
  datePicker() {
  }
  redirection() {
    this.router.navigate(['/admin/job-details', this.publicId])
  }
  getJobsByDate(date: any) {
    // console.log(date)
    this.dateObj = date
    let dateJob
    dateJob = this.datePipe.transform(date, 'yyyy-M-dd')
    this._jobsService.getAllJobsByDate(this.user.companyId, dateJob).subscribe((response: ResponseModel) => {
      this.jobsCalender = response.data
      this.p2 = 1
    }, error => {
    })
  }
  resetPaginationCalender(event) {
    this.p2 = 1
  }
  getCompanyJobsByYear() {
    this._jobsService.getCompanyJobsByYear(this.user.companyId, this.yearStatistic).subscribe((response: ResponseModel) => {
      this.jobsStatistic = response.data
    })
  }
  // getJobsCalenderByDate(date: any) {
  //   this.dateObj = date
  //   let companyId
  //   let dateJob
  //   companyId = this.user.companyId
  //   dateJob = this.datePipe.transform(date, 'yyyy-M-dd')
  //   this._jobsService.getAllJobsByDate(companyId, dateJob).subscribe((response: ResponseModel) => {
  //     this.jobsCalender = response.data.sort((a, b) => (a.jobNumber < b.jobNumber ? -1 : 1))
  //   }, error => {
  //   })
  // }
  getJobsByDatePicker(event: any) {
  }
  getJobsByType(type: number) {
    this.spinner.show()
    this._jobsService.getCountJobsByType(this.user.companyId, type).subscribe((response: ResponseModel) => {
      this.jobs = response.data
      this.spinner.hide()
    }, error => {
      this.spinner.hide()
    })
  }
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  getJobStaticsYear() {
    this._jobsService.getJobStaticsYear(this.yearStatistic, this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobsInYear = response.data
      }
    }, error => {
      console.log(error)
    })
  }
  getCountJobsByTypeInYear(type: any) {
    // console.log(type)
    let date = this.datePipe.transform(this.dateObj, 'yyyy')
    this._jobsService.getCountJobsByTypeInYear(type, this.yearStatistic, this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobsStatistic = response.data
        this.p4 = 1
      } else {
      }
    }, error => {
      // console.log(error)
      this.spinner.hide()
    })
  }
  getJobs() {
    return this._jobsService.getJobs(this.user.companyId).subscribe((response: ResponseModel) => {
      this.jobsStatistic = response.data
    }, error => {
    })
  }
  addTodo() {
    let model = {
      id: 1,
      value: 'test',
      done: true
    }
    // this._companiesService.addTodo(model);
  }
  isChooseMap() {
    this.router.navigate(['/map'])
  }
  getTodayDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }
  onClick() {
    this.isMobile = true
    this.userAgentObj = this.deviceService.getDeviceInfo()
    console.log(this.deviceService.getDeviceInfo())
    this.userAgentModal.show()
  }
  onClick1() {
    this.isMobile = false
    if (Capacitor.isNativePlatform()) {
      this.platformStr = 'mobile'
    } else {
      this.platformStr = 'browser'
    }
    this.userAgentModal.show()
  }
  storeBufferJobId(jobId: string) {
    this.bufferJobId = jobId
  }
  getJobsClone() {
    this.getAllJobsByUser()
  }
  coloneJob(jobId: string) {
    let url = ''
    let id = jobId
    let todayDate = new Date();
    let creationDate = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate());
    let dateAndTime =
      [{
        id: 1,
        startDateWindowFrom: creationDate,
        startDateWindowTo: creationDate,
        startTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        startTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        endDateWindowFrom: creationDate,
        endDateWindowTo: creationDate,
        endTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        endTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        jobDescription: " ",
        summaryDate: creationDate + ' --> ' + creationDate
      }]
    let model = {
      creationDate: creationDate,
      creationTime: todayDate.toLocaleTimeString(),
      // companyNumber: this.user.companyNumber,
      dateAndTime: dateAndTime,
      creationUserId: this.user.id,
    }
    this._jobsService.cloneJobById(id, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobModel.jobId = response.data.jobId
        this.jobModel.jobNumber = response.data.jobNumber
        this.jobModel.jobType = response.data.jobType
        this.jobModel.primaryCustomerName = response.data.primaryCustomerName
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          url = `${this.baseDomain}admin/job-details/${response.data.jobId}`
        }
        else {
          url = `${this.baseDomain}user/job-details/${response.data.jobId}`
        }
        window.location.href = url;
      } else {
        this.toastr.error('Please try again', 'Error Clone Job')
      }
    }, error => {
    })
  }
  unpinCloneJob(jobId: string) {
    this._jobsService.pinCloneJob(jobId, false).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.toastr.success('', 'Job Successfully Unpinned')
      }
    })
  }
}
