import { GlobalService } from 'src/app/core/_services/global/global.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-container-mobile-print',
  templateUrl: './container-mobile-print.component.html',
  styleUrls: ['./container-mobile-print.component.scss']
})
export class ContainerMobilePrintComponent implements OnInit {
  objFromParent1: any = {};
  id:string=''
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.objFromParent1 = JSON.parse(params['data'])
      this.id = params['id']
     });
     setTimeout(() => {
      let  printContents = document.getElementById(this.id).innerHTML;
      let popupWin = window.open('','top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <title>print</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
            <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
            <style>
            body {
             margin : 0.5rem auto;
             text-align : center;
             page-break-after: auto;
           }
           .item-print {
             border : 2px solid #000;
             margin: 8px;
           }
           h6 {
             font-size: 3rem;
             padding-top: 6px;
             margin: 0;
             color: #4285f4;
             font-weight: 700;
           }
           label {
             font-size: 3rem ;
             font-weight: 700;
           }
           @media print {
             .pagebreak  {
                 page-break-before: always;
             }
             /* page-break-after works, as well */
         }
            </style>
          </head>
          <body onload="window.print()">${printContents}</body>
        </html>`
        );
        popupWin.document.close();
     }, 1000);
  }
  goBack(){
    this.globalService.goBack()
  }
}
