<div class="modal">
    <div class=" modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
                <h4 class="modal-title w-100">Payment Failure</h4>
            </div>
            <div class="modal-body">
                <!-- <p class="text-center">Lorem ipsum, dolor sit amet consectetur adipisicing elit</p> -->
                <br>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-block" data-dismiss="modal" (click)="goBack()">Go Back</button>
            </div>
        </div>
    </div>
</div>
