<section class="section-tool-job" id="job-status" *ngIf="userPermission.value != statusActive.InActive || role == 'admin'">
    <div class="customer-details ">
        <!-- <a> -->
        <p>
            <!-- <img src="/assets/img/icons/Job_status.svg" alt="Jobstatus-icon" /> -->
            <!-- <img src="./assets/img/icons/crm_icons/Job_status_1.svg" class="pr-1" alt="Job_status.svg"> -->
            <!-- <i class="fas fa-cogs pr-1"></i> -->
            <i class="uil uil-spinner-alt uil-medium pr-1"></i>
            <span> Job Status </span>
        </p>
        <!-- </a> -->
        <div *ngIf="userPermission.value == statusActive.Active || role == 'admin'">
            <select #mySelect class="browser-default custom-select form-control" (change)='statusJobModal.show();bufferStoreId(mySelect.value)'>
                <option value="" selected disabled>Change Status</option>
                <option *ngFor="let item of statusJobs" [value]="item.id">{{item.name}}</option>
            </select>
        </div>
    </div>
    <div class="items inner-section">
        <div class="item-status">
            <label *ngFor="let item of statusJobs" [ngClass]="item.isActive === 'true' ? 'active': 'not-active'">
        <span>{{item.name}}</span>
      </label>
        </div>
    </div>
</section>
<!-- Confirm Status Job -->
<div mdbModal id="statusJobModal" #statusJobModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="statusJobModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Change Job Status? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="statusJobModal.hide() "> Cancel </button>
                    <button type="button " class="btn btn-success " (click)="storeId(globalId);statusJobModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send Status Job -->
<div mdbModal id="statusJobSendModal" #statusJobSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="statusJobSendModal.hide()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="onOptionsSelected();statusJobSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="sendEmailToCustomer();statusJobSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
