<div class="customers">
    <div class="card">
        <div class="tittle">Accordev Accounts </div>
        <mat-tab-group [disableRipple]="false">
            <mat-tab label="Shown ({{showAccounts.length}})">
                <div class="row">
                    <div class="col-12" *ngIf="showAccounts.length > 0">
                        <div class="form-group">
                            <input type="text" id="search-text1" class="form-control" aria-describedby="search-text1" [(ngModel)]="searchText1" placeholder="Search" />
                        </div>
                    </div>
                    <div class="col-12" *ngFor="let account of showAccounts | AccountsFilter: searchText1 |  paginate: {id: 'showAccounts_id' , itemsPerPage: 3, currentPage: p2 }; let i=index ">
                        <div class="item-company">
                            <div class="company-img">
                                <img [attr.src]="account.companyLogo ? account.companyLogo: 'https://via.placeholder.com/150'" alt="{{account.companyName}}Logo">
                            </div>
                            <div class="company-content">
                                <h6>{{account.companyName}} | {{account.role}} </h6>
                                <small>{{account.companyAddress?.addressOne}}, <small
                    *ngIf="account.companyAddress?.addressTow">{{account.companyAddress?.addressTow}},</small> {{account.companyAddress?.city}} {{account.companyAddress?.state}} {{account.companyAddress?.zip}}
                                </small>
                            </div>
                            <div class="company-btn">
                                <button class="btn btn-sm btn-pink" *ngIf="account.companyId != companyId" (click)="HideInSwitchCompanyPerson(account.id)">hide</button>
                                <button class="btn btn-sm btn-pink" *ngIf="account.companyId == companyId" [disabled]="true">Current</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mx-auto mt-3" *ngIf="showAccounts.length > 0">
                        <pagination-controls id="showAccounts_id" (pageChange)="p2=$event "></pagination-controls>
                    </div>
                    <div class="ant-empty-image " *ngIf="showAccounts.length <= 0">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Hidden ({{hiddenAccounts.length}})">
                <div class="row">
                    <div class="col-12" *ngIf="hiddenAccounts.length > 0">
                        <div class="form-group">
                            <input type="text" id="search-text2" class="form-control" aria-describedby="search-text2" [(ngModel)]="searchText2" placeholder="Search" />
                        </div>
                    </div>
                    <div class="col-12" *ngFor="let account of hiddenAccounts | AccountsFilter: searchText2 |  paginate: {id: 'hiddenAccounts_id' , itemsPerPage: 3, currentPage: p1 }; let i=index ">
                        <div class="item-company">
                            <div class="company-img">
                                <img [attr.src]="account.companyLogo ? account.companyLogo: 'https://via.placeholder.com/150'" alt="{{account.companyName}}Logo">
                            </div>
                            <div class="company-content">
                                <h6>{{account.companyName}} | {{account.role}} </h6>
                                <small>{{account.companyAddress?.addressOne}}, <small
                    *ngIf="account.companyAddress?.addressTow">{{account.companyAddress?.addressTow}},</small> {{account.companyAddress?.city}} {{account.companyAddress?.state}} {{account.companyAddress?.zip}}
                                </small>
                            </div>
                            <div class="company-btn">
                                <button class="btn btn-sm btn-primary" (click)="ShowInSwitchCompanyPerson(account.id)">Unhide</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mx-auto mt-3" *ngIf="hiddenAccounts.length > 0">
                        <pagination-controls id="hiddenAccounts_id" (pageChange)="p1=$event "></pagination-controls>
                    </div>
                    <div class="ant-empty-image " *ngIf="hiddenAccounts.length <= 0">
                        <img src="./assets/img/empty.svg" alt="empty-img">
                        <span>No Data</span>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>