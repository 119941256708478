<!-- You are offline. Some Functionality may be unavailable.  -->
<div class="container">
    <!-- <h2><span>invite</span></h2> -->
    <div class="card ">
        <div class="tittle-section">
            <p>
                <!-- <i class="fas fa-paper-plane mr-1"></i> -->
                <span>invite</span>
            </p>
        </div>
        <form [formGroup]="inviteFormGroup" (submit)="InviteUser()">
            <div class="form-group">
                <label for="email"> Email <span>* </span> :</label>
                <input type="text" id="email" formControlName="email" placeholder="Please Enter Email" class="form-control" [ngClass]="{'is-invalid' : f.email.errors && f.email.touched}" />
                <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">Email not valid</div>
                </div>
            </div>
            <div class="form-group">
                <label for="role"> Role <span>* </span> :</label>
                <!-- admin  -->
                <select *ngIf="role == 'admin' && isCustomer == 'false' && isThirdParty == 'false' && isAdmin == 'false' || role == 'manager' && isCustomer == 'false' && isThirdParty == 'false' && isAdmin == 'false' || role == 'teamleader' && isCustomer == 'false' && isThirdParty == 'false' && isAdmin == 'false' || role === 'crew' && isCustomer == 'false' && isThirdParty == 'false' && isAdmin == 'false' "
                    class="browser-default custom-select form-control" id="role" formControlName="role" placeholder="Please Choose Role" [ngClass]="{'is-invalid' : f.role.errors && f.role.touched}">
          <option value="" selected disabled>Choose Role</option>
          <!-- <option   value="manager">Manager</option> -->
          <option *ngIf="role == 'admin' || role == 'manager'" value="teamleader">Team Leader</option>
          <option *ngIf="role == 'admin' || role == 'manager'|| role == 'teamleader'" value="crew">Crew</option>
          <option *ngIf="role == 'admin' || role == 'manager'|| role == 'teamleader'|| role == 'crew'" value="3rdparty">
            3Rd Party</option>
          <!-- <option value="customer">Customer</option> -->
        </select>
                <!-- cusotmer link -->
                <select *ngIf="isCustomer == 'true'" class="browser-default custom-select form-control" id="role" formControlName="role" placeholder="Please Choose Role" [ngClass]="{'is-invalid' : f.role.errors && f.role.touched}">
          <option value="" selected disabled>Choose Role</option>
          <option value="customer">Customer</option>
        </select>
                <!-- cusotmer link -->
                <select *ngIf="isThirdParty == 'true'" class="browser-default custom-select form-control" id="role" formControlName="role" placeholder="Please Choose Role" [ngClass]="{'is-invalid' : f.role.errors && f.role.touched}">
          <option value="" selected disabled>Choose Role</option>
          <option value="3rdparty">3Rd Party</option>
        </select>
                <!-- admin link -->
                <select *ngIf="isAdmin == 'true'" class="browser-default custom-select form-control" id="role" formControlName="role" placeholder="Please Choose Role" [ngClass]="{'is-invalid' : f.role.errors && f.role.touched}">
          <option value="" selected disabled>Choose Role</option>
          <option value="manager">Manager</option>
        </select>
                <div *ngIf="f.role.touched && f.role.invalid" class="invalid-feedback">
                    <div *ngIf="f.role.errors.required">Role is required</div>
                </div>
            </div>
            <div class="group-button ">
                <!-- <button type="button" mdbBtn color="primary" [routerLink]="['/admin']" routerLinkActive="router-link-active">back</button> -->
                <button type="button" mdbBtn color="success" [disabled]="inviteFormGroup.invalid" (click)="InviteUser()" data-toggle="modal">Invite</button>
            </div>
        </form>
    </div>
</div>
<!-- Modal -->
<div id="myModal" mdbModal #basicModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <h4 class="modal-title w-100">Invitation Successfully Sent</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">Invitee need to check his\her email box.</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-block" data-dismiss="modal" (click)="changeUserRole()">OK</button>
            </div>
        </div>
    </div>
</div>