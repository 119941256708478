import { NgxSpinnerService } from 'ngx-spinner';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ModalDirective } from 'angular-bootstrap-md';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
interface Status {
  id: number,
  name: string,
  isActive: string
}
@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss']
})
export class JobStatusComponent implements OnInit {
  statusJobs: any[]
  jobStatus: string
  jobId: string
  jobDetails: JobDetails
  jobDetailsId: string
  globalId: any
  user: any = {}
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  role: string = ''
  jobsTools: any = {}
  jobStatusObj: any = {}
  @ViewChild('statusJobModal') statusJobModal: ModalDirective;
  @ViewChild('statusJobSendModal') statusJobSendModal: ModalDirective;
  constructor(
    private route: ActivatedRoute,
    private _jobDetailsService: JobDetailsService,
    private _jobsService: JobsService,
    private _toolsService: ToolsService,
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
      }
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        if(this.role == 'admin'){
          this.getJobStatus()
        }
        if (this.role != 'admin' && this.role != 'manager') {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.jobStatus, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getJobStatus()
          }
        }
        if (this.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.jobStatus, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getJobStatus()
          }
        }
      }
    }, error => {
      console.log(error)
    })
  }
  getJobStatus() {
    this._jobDetailsService.getJobDetailsByIdItem(this.jobId, ToolJobsEditItem.jobStatus).subscribe((response: ResponseModel) => {
      this.statusJobs = response.data.statusJobs
      this.jobDetailsId = response.data.id
    })
  }
  bufferStoreId(id){
    this.globalId = id
  }
  storeId(id) {
    // this.globalId = id
    let item = this.statusJobs.find(s => s.id == id)
    let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.jobStatus, item.type)
    if ( notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.statusJobSendModal.show()
    }
    else {
      this.onOptionsSelected()
    }
  }
  onOptionsSelected() {
    let idItem = ToolJobsEditItem.jobStatus
    this.statusJobs.forEach((item) => item.isActive = "false");
    this.statusJobs[this.globalId - 1].isActive = 'true'
    this.statusJobs = this.statusJobs
    this._jobDetailsService.updateJobDetails(this.statusJobs, this.jobId, idItem, Is_Customer_Email.Not_Send_Email).subscribe((response: ResponseModel) => {
    }, error => {
      console.log(error)
    })
  }
  sendEmailToCustomer() {
    let idItem = ToolJobsEditItem.jobStatus
    this.statusJobs.forEach((item) => item.isActive = "false");
    this.statusJobs[this.globalId - 1].isActive = 'true'
    this.statusJobs = this.statusJobs
    this._jobDetailsService.updateJobDetails(this.statusJobs,this.jobId, idItem, Is_Customer_Email.Send_Email).subscribe((response: ResponseModel) => {
    }, error => {
      console.log(error)
    })
  }
}
