import { Socket } from 'ngx-socket-io';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ToasterCustomService } from './../../../../../core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ModalDirective } from 'angular-bootstrap-md';
import { PaymentType } from '../Enum/PaymentType';
import { Capacitor } from '@capacitor/core';
import { DatePipe } from '@angular/common';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { Observable } from 'rxjs';
import { BaseConfig } from 'ngx-scanner-qrcode';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-complete-membership',
  templateUrl: './complete-membership.component.html',
  styleUrls: ['./complete-membership.component.scss']
})
export class CompleteMembershipComponent implements OnInit, OnDestroy {
  @ViewChild('warningKeyModal') warningKeyModal: ModalDirective;
  @ViewChild('warningCashModal') warningCashModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
  @ViewChild('warningKeyStripModal') warningKeyStripModal: ModalDirective;
  @ViewChild('scanWebModal') scanWebModal: ModalDirective;
  addVehicleFormGroup: FormGroup;
  emailPattern = ValidationModel.emailPattern
  numCharPattern = ValidationModel.complexPattern
  isLinear = false;
  // qrCodeId:string =''
  vehicleId: string = ''
  vehicle: any = {}
  user: any = {}
  customer: any = {}
  isHaveSecretKey = false
  paymentType: any = PaymentType.card
  paymentTypeEnum = PaymentType
  customerEmailCash = ''
  bufferVehicleId = ''
  countMonth: any = 'forever'
  monthsCash = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  monthsCard = ['forever', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  isEmailCashExists = false
  timer: any
  isExists = false
  historyCarWash = HistoryCarWash
  countPrintBranches = ''
  adminId = ''
  // scan qr code variable
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;
  public config: BaseConfig = {
    text: { font: '25px serif' }, // Hiden { font: '0px', bottom: 40 },
    frame: { lineWidth: 4 },
  };
  @ViewChild('action') action: any;
  selectedIndex = 0
  //  Cash variables
  products = []
  packageProducts = []
  isDateCheck = false
  isBack = false
  isBack1 = false
  daysInMonth = 30
  totalDue = 0
  buffProductPrice = 0
  receivedAmount = 0
  cashBackAmount = 0
  startDate: any = new Date()
  endDate: any = new Date()
  diffInDays = 0
  date = moment();
  buffProduct: any = {}
  selected: any = {}
  // for packaging products
  pricePackageProduct = 0
  receivedAmountPackageProduct = 0
  cashBackPackageProduct = 0
  buffProductPackaging: any = {}
  selectedID = 0
  selectedPackageIndex = 0
  countrySelected: any = {}
  jobModel: any = {}
  isDetailing: any = false
  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _carWashService: CarWashService,
    private _toaster: ToasterCustomService,
    private router: Router,
    public datePipe: DatePipe,
    public _globalService: GlobalService,
    private _companiesService: CompaniesService,
    private socket: Socket

  ) { }
  ngOnInit() {
    this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
    let isDetailing = localStorage.getItem('isDetailing')
    if (isDetailing != undefined && isDetailing != null) {
      this.isDetailing = isDetailing == 'false' ? false : true;
    }
    console.log(this.isDetailing)
    this.storePaymentType(this.paymentType)
    localStorage.setItem('countMonth', 'forever')
    this.createAddVehicleForm()
    localStorage.removeItem('customerId')
    this.vehicleId = this.route.snapshot.paramMap.get('id');
    // this.vehicleId = localStorage.getItem('vehicleId')
    // console.log( this.vehicleId)
    this.user = JSON.parse(localStorage.getItem('user'))
    this._carWashService.getVehicleById(this.vehicleId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.getProducts()
        this.vehicle = response.data
        console.log(this.vehicle)
        if (!this.vehicle.cashMembershipInfo?.isPackage) {
          this.diffInDays = this.vehicle.cashMembershipInfo?.numOfDays != undefined ? Number(this.vehicle.cashMembershipInfo.numOfDays) : 0
          this.totalDue = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.totalDue : 0
          this.receivedAmount = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.receivedAmount : 0
          this.cashBackAmount = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.cashBack : 0
          this.getTotalDuePrice()
        }
        else {
          this.pricePackageProduct = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.totalDue : 0
          this.receivedAmountPackageProduct = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.receivedAmount : 0
          this.cashBackPackageProduct = this.vehicle.cashMembershipInfo != undefined ? this.vehicle.cashMembershipInfo?.cashBack : 0
        }

        // if(!this.vehicle.histories){
        //   localStorage.setItem('historyLength',this.vehicle?.histories?.length + 1 ??  2 )
        // }
        // else{
        //   localStorage.setItem('historyLength', this.vehicle.histories.length + 1)
        // }
        localStorage.setItem('historyLength', this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2)
        !this.vehicle.histories
        this.paymentType = (localStorage.getItem('type') != undefined) ? Number(localStorage.getItem('type')) : PaymentType.card
        this.selectedIndex = Number(localStorage.getItem('selectedIndex')) ? Number(localStorage.getItem('selectedIndex')) : 0
        // this.route.queryParams.subscribe(params => {
        //   this.paymentType = Number(params['type']);
        //   this.selectedIndex = Number(params['index']);
        // });
        // if(this.vehicle.paymentType != null && this.vehicle.paymentType != undefined  ){
        // this.paymentType = this.vehicle.paymentType
        this.storePaymentType(this.paymentType)
        if (this.paymentType == this.paymentTypeEnum.card) {
          localStorage.setItem('countMonth', 'forever')
          // if (this.vehicle.countMonth && !this.vehicle.isForever) {
          //   localStorage.setItem('countMonth', this.vehicle.countMonth)
          // }
          // if(!this.vehicle.countMonth && this.vehicle.isForever ) {
          //   localStorage.setItem('countMonth', 'forever')
          // }
        }
        else {
          localStorage.setItem('countMonth', '1')
          if (this.vehicle.countMonth) {
            localStorage.setItem('countMonth', this.vehicle.countMonth)
          }
          this.getProducts()
        }
        // }
        // console.log(this.vehicle)
        this.getActiveStep()
        this.setValueVehicle(response.data)
        // if (this.vehicle.customer != null) {
        //   if (response.data.paymentType == this.paymentTypeEnum.card) {
        //     this.storePaymentType(response.data.paymentType)
        //   }
        //   else {
        //     this.storePaymentType(response.data.paymentType)
        //     localStorage.setItem('customerId', this.vehicle.customer.id)
        //   }
        // }
      }
    })
    // this._carWashService.assignVehicle(this.vehicleId,this.qrCodeId).subscribe((response:ResponseModel)=>{
    //   console.log('Assigned Car Wash',response)
    //   if(response.success){
    //     this.vehicle = response.data
    //     this.setValueVehicle(response.data)
    //   }
    // })
    // this.addVehicleFormGroup.get("qrCode").setValue(this.qrCodeId);
    this.socket.on(RealTimeKeys.Vehicle_Update, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.vehicle = response.data
          }
        })
      }
    });
  }
  getCompanyCountPrint() {
    this._companiesService.getCompanyCountPrint(this.user.companyId).subscribe((response: ResponseModel) => {
      console.log(response)
      if (response.success) {
        this.countPrintBranches = response.data.countPrintBranches
        this.adminId = response.data.id
      }
    })
  }
  // assignQR(){
  //   if(Capacitor.isNativePlatform()) {
  //     this.printScanMobile.show()
  //   } else {
  //     localStorage.setItem('isAssignedQR','true')
  //     localStorage.setItem('vehicleId',this.vehicleId)
  //     this._carWashService.assignQR()
  //   }
  // }
  createAddVehicleForm() {
    this.addVehicleFormGroup = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['', Validators.required, Validators.pattern(this.numCharPattern)],
      vehicleModel: [''],
      // color: [''],
      // vinCar: ['',[Validators.pattern(this.numberPattern)]],
      condition: [''],
      phone: [''],
      // qrCodeId: ['', Validators.required],
      // firstName: ['',Validators.required],
      // lastName: ['',Validators.required],
      // email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }
  setValueVehicle(vehicle: any) {
    // console.log('vehicle.customer',vehicle.customer)
    // if (vehicle.customer != null) {
    // console.log('vehicle.customer')
    this.addVehicleFormGroup.setValue({
      vehicleType: vehicle.vehicleType,
      plateNumber: vehicle.plateNumber,
      vehicleModel: vehicle.vehicleModel,
      // color:vehicle.color,
      // vinCar:vehicle.vinCar,
      condition: vehicle.condition,
      phone: vehicle.phone  ? vehicle.phone : '',
      // qrCodeId:vehicle.qrCodeId,
      // firstName:vehicle.customer.firstName,
      // lastName:vehicle.customer.lastName,
      // email: vehicle.customer.email,
    })
    // }
    // else {
    // this.addVehicleFormGroup.setValue({
    //   vehicleType: vehicle.vehicleType,
    //   plateNumber: vehicle.plateNumber,
    //   vehicleModel: vehicle.vehicleModel,
    //   // color:vehicle.color,
    //   condition: vehicle.condition,
    //   // qrCodeId:vehicle.qrCodeId,
    //   // firstName:'',
    //   // lastName:'',
    //   // email: '',
    // // })
  }
  get f() {
    return this.addVehicleFormGroup.controls;
  }
  isPlateNumberExist() {
  console.log('addVehicleFormGroup',this.f)
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (this.f.plateNumber.status == "PENDING" || this.f.plateNumber.status == "VALID") {
        this._carWashService.isPlateNumberExist(this.user.companyId, this.f.plateNumber.value.toUpperCase()).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExists = false
          }
          else {
            this.isExists = true
          }
        })
      }
      else {
        this.isExists = false
        return null
      }
    }, 500)
  }
  // addCustomer() {
  //   let item = this.addVehicleFormGroup.value
  //   // console.log(item)
  //   let model = {
  //     firstName: item.firstName,
  //     lastName: item.lastName,
  //     email: item.email,
  //     companyId: this.user.companyId,
  //     isInvited: false,
  //     role: "customer",
  //     name: item.lastName + ', ' + item.firstName,
  //     type: "customer",
  //     status: "Unassigned",
  //     disable: false,
  //     isCanceled: false,
  //     country: {
  //       name: "United States",
  //       alpha2Code: "US",
  //       alpha3Code: "US",
  //       numericCode: "840"
  //     }
  //   }
  //   this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
  //     if (response.success) {
  //       this._carWashService.addCustomerVehicle(this.vehicleId, model).subscribe((response: ResponseModel) => {
  //         if (response.success) {
  //           this.vehicle.customer = response.data
  //           this.addVehicleFormGroup.setValue({
  //             firstName: response.data.firstName,
  //             lastName: response.data.lastName,
  //             email: response.data.email,
  //           })
  //           this._toaster.success('It is optional to do the next step', 'Customer Information Successfully Added')
  //         }
  //         else {
  //           this._toaster.error('', 'Customer already exists')
  //         }
  //       })
  //     }
  //     else {
  //       this.warningKeyModal.show()
  //     }
  //   })
  // }
  getActiveStep() {
    // if(this.vehicle.qrCodeId == "" && this.vehicle.customer == null || this.vehicle.qrCodeId == "" && this.vehicle.customer != null){
    //   return 2
    // }
    // else if(this.vehicle.qrCodeId != "" && this.vehicle.customer == null){
    //   return 1
    // }
    // else{
    //   return 1
    // }
    return this.selectedIndex
  }
  isProductsCompanyById(vehicleId) {
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.isHaveSecretKey = false
        if (this.user.role == 'manager' || this.user.role == 'admin') {
          this.router.navigate(['/admin/products-strip', vehicleId])
        }
        else {
          this.router.navigate(['/user/products-strip', vehicleId])
        }
      }
      else {
        this.isHaveSecretKey = true
      }
    })
  }
  createMemberShip(vehicleId) {
    let item = this.addVehicleFormGroup.value
    let model: any = {
      firstName: '',
      lastName: '',
      email: item.email,
      companyId: this.user.companyId,
      isInvited: false,
      role: "customer",
      name: '',
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      country: {
        name: "United States",
        alpha2Code: "US",
        alpha3Code: "US",
        numericCode: "840"
      }
    }
    if (this.paymentType == this.paymentTypeEnum.cash || this.paymentType == this.paymentTypeEnum.card_exists) {
      model.isCustomerCash = true
    }
    else {
      model.isCustomerCash = false
    }
    if (this.paymentType == this.paymentTypeEnum.card_exists) {
      model.email = this.getEmailWithoutSpace(this.customerEmailCash)
    }
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (this.vehicle.customer) {
          if (this.user.role == 'manager' || this.user.role == 'admin') {
            this.router.navigate(['/admin/products-strip', vehicleId])
          }
          else {
            this.router.navigate(['/user/products-strip', vehicleId])
          }
        }
        else {
          this._carWashService.addCustomerVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
            if (response.success) {
              this.customer = response.data
              this.isHaveSecretKey = false
              if (this.user.role == 'manager' || this.user.role == 'admin') {
                this.router.navigate(['/admin/products-strip', vehicleId])
              }
              else {
                this.router.navigate(['/user/products-strip', vehicleId])
              }
              // this._toaster.success('It is optional to do the next step','Customer Information Successfully Added')
            }
            else {
              this._toaster.error('', 'Customer already exists')
            }
          })
        }

      }
      else {
        this.warningKeyModal.show()
      }
    })
  }
  createMemberShipCash(vehicleId) {
    // localStorage.setItem('renewMembership', 'false')
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.isHaveSecretKey = false
        if (this.vehicle.customer && this.paymentType == this.paymentTypeEnum.card) {
          localStorage.setItem('renewMembership', 'true')
        }
        if (this.user.role == 'manager' || this.user.role == 'admin') {
          this.router.navigate(['/admin/products-strip', vehicleId])
        }
        else {
          this.router.navigate(['/user/products-strip', vehicleId])
        }
      }
      else {
        this.isHaveSecretKey = true
        this.warningKeyStripModal.show()
      }
    })
  }
  updateVehicleInformation() {
  console.log(this.addVehicleFormGroup,this.addVehicleFormGroup.status)
  console.log(this.f)
    let item = this.addVehicleFormGroup.value
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      key: this.historyCarWash.Update_Vehicle,
      eventName: 'updated'
    }
    let model = {
      vehicleType:this.f.vehicleType.value,
      plateNumber: this.f.plateNumber.value.toUpperCase().replace(/\s/g, ''),
      vehicleModel: this.f.vehicleModel.value,
      history: history,
      condition: this.f.condition.value,
      phone:this.f.phone.value,
      qrCodeNumber: this.vehicle.qrCodeNumber
    }
    console.log(model)
    if (this.isDetailing) {
      this._carWashService.updateVehicleJob(this.vehicleId, model, this.jobModel.jobId).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.setValueVehicle(response.data)
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Car Profile Successfully Updated')
        }
      })
    }
    else {
      this._carWashService.updateVehicle(this.vehicleId, model).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.setValueVehicle(response.data)
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Car Profile Successfully Updated')
        }
      })
    }
  }
  updateCustomerInformation(customerId: string) {
    let item = this.addVehicleFormGroup.value
    let model = {
      firstName: item.firstName,
      lastName: item.lastName,
    }
    this._carWashService.updateCustomerVehicle(customerId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.vehicle.customer = response.data
        this.setValueVehicle(this.vehicle)
        this._toaster.success('', 'Customer Information Successfully Updated')
      }
    })
  }
  checkMobile() {
    this.createPortalCustomerStripeCustom()
  }
  createPortalCustomerStripeCustom() {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      // id : this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
    }
    this._carWashService.createPortalCustomerStripeCustom(this.vehicleId, this.vehicle.paymentType, history).subscribe((response: ResponseModel) => {
      if (response.success) {
        // window.open(response.data)
        this._globalService.openLink(response.data)
      }
    })
  }
  handleChange(event) {
    if (event.target.defaultValue == "Card") {
      this.paymentType = this.paymentTypeEnum.card
      this.storePaymentType(this.paymentType)
      localStorage.setItem('countMonth', 'forever')
      this.isEmailCashExists = false
      localStorage.removeItem('customerId')
      // localStorage.setItem('emailCard',this.vehicle.customer.email)
    }
    else {
      // this.getCustomerCash()
      this.paymentType = this.paymentTypeEnum.cash
      // this.storePaymentType(this.paymentType)
      localStorage.setItem('countMonth', '1')
      this.getProducts()
    }
  }
  getProducts() {
    this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
      console.log(response)
      this.products = response.data.filter(p => p.isPackage == false)
      console.log(this.products)
      this.packageProducts = response.data.filter(p => p.isPackage == true)
      this.storeBuffProductPrice(this.products[0])
      if (!this.vehicle.cashMembershipInfo?.isPackage) {
        this.buffProduct = this.products.find(prod => prod.id == this.vehicle.cashMembershipInfo?.id) ? this.products.find(prod => prod.id == this.vehicle.cashMembershipInfo?.id) : this.products[0]
        this.selectedID = this.products.findIndex(prod => prod.id == this.vehicle.cashMembershipInfo?.id) != -1 ? this.products.findIndex(prod => prod.id == this.vehicle.cashMembershipInfo?.id) : null
        console.log('this.selectedID', this.selectedID)
        this.storeBuffProductPrice(this.buffProduct)
      }
      else {
        this.buffProductPackaging = this.packageProducts.find(prod => prod.id == this.vehicle.cashMembershipInfo?.id) ? this.packageProducts.find(prod => prod.id == this.vehicle.cashMembershipInfo?.id) : this.packageProducts[0]
        this.storeBuffProductPackagingPrice(this.buffProduct)
        this.selectedPackageIndex = this.products.findIndex(prod => prod.id == this.vehicle.cashMembershipInfo?.id) != -1 ? this.products.findIndex(prod => prod.id == this.vehicle.cashMembershipInfo?.id) : null
        console.log('this.selectedPackageIndex', this.selectedPackageIndex)
      }
    })
  }
  storePaymentType(paymentType) {
    localStorage.setItem('paymentType', paymentType)
  }
  getCustomerCash() {
    this._carWashService.getCustomerCash(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success && response.data != null) {
        console.log(response.data)
        this.paymentType = this.paymentTypeEnum.cash
        // localStorage.setItem('customerId', response.data.id)
        this.isEmailCashExists = false
        this.storePaymentType(this.paymentTypeEnum.cash)
      }
      else {
        // console.log(response.data)
        this.warningCashModal.show()
        this.isEmailCashExists = true
        this.storePaymentType(this.paymentTypeEnum.cash)
      }
    })
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  chooseCountMonth(event) {
    localStorage.setItem('countMonth', event)
  }
  storeVehicleId(vehicleId) {
    console.log(vehicleId)
    this.bufferVehicleId = vehicleId
  }
  renewMembership() {
    localStorage.setItem('paymentType', this.paymentType)
    if (this.paymentType == this.paymentTypeEnum.card && !this.vehicle.customerId) {
      localStorage.removeItem('renewMembership')
    } else {
      localStorage.setItem('renewMembership', 'true')
    }
  }
  assignQR() {
    this.handle(this.action, 'start')
    // if(Capacitor.isNativePlatform()) {
    //   this.printScanMobile.show()
    // } else {
    // localStorage.setItem('isAssignedQR','true')
    // this._carWashService.assignQR()
    // }

  }
  // scan qr code
  public handle(action: any, fn: string): void {
    if (fn == 'start') {
      this.scanWebModal.show()
    }
    else if (fn == 'stop') {
      this.scanWebModal.hide()
      action['stop']().subscribe(res => {
        action.data._value.data = ""
      });
    }
    action[fn]().subscribe(console.log, console.log);
  }
  public onEvent(e: any): void {
    if (e.data != "" && e != null && e != undefined) {
      let data = e.data.split('/')[4].split(',')
      console.log(data)
      this._companiesService.checkVehicleQrNumber(data[0], Number(data[1])).subscribe((response: ResponseModel) => {
        if (response.success && !response.data.isFound) {
          this.vehicle.qrCodeNumber = Number(data[1])
          this.updateVehicleInformationScan(this.vehicle)
        }
        else {
          // this.router.navigate(['view-vehicle-global',this.vehicle.id])
          this._toaster.warning('Please choose another Qr code', 'Qr code assigned to another vehicle')
        }
      })
      // if (Number(data[0]) != Number(this.jobNumber) && Number(data[2]) == this.qrCodeType.Qr_Not_Generic) {
      //   this._toaster.warning('QR code Does Not belong to this job', '',
      //     {
      //       progressBar: true,
      //       titleClass: 'toast-title',
      //       progressAnimation: "increasing",
      //       timeOut: 4000
      //     });
      // }
      // else if (Number(data[0]) == Number(this.jobNumber)) {
      //   if (this.isAddScan) {
      //     this.inventoryForm.patchValue({ itemNumber: Number(data[1]) })
      //     this.qrCodeNumberAdd = Number(data[1])
      //     this.qrCodeTypeAdd = Number(data[2])
      //     this.isNumberItemExist('')
      //   }
      //   else {
      //     this.qrCodeNumberUpdate = data[1]
      //     this.qrCodeTypeUpdate = data[2]
      //     this.inventoryUpdateForm.patchValue({ itemNumber: Number(data[1]) })
      //     this.isNumberItemExistEdit('')
      //   }
      // }
      this.handle(this.action, 'stop')
      this.scanWebModal.hide()
    }
  }
  public onError(e: any): void {
    this.scanWebModal.hide()
  }
  updateVehicleInformationScan(vehicle: any) {
    let item = vehicle
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      key: this.historyCarWash.Assign_Qr_Code,
      eventName: 'Assigned Qr Code'
    }
    let model = {
      vehicleType: item.vehicleType,
      plateNumber: item.plateNumber.toUpperCase().replace(/\s/g, ''),
      vehicleModel: item.vehicleModel,
      history: history,
      phone: item.phone,
      condition: item.condition,
      qrCodeNumber: item.qrCodeNumber
    }
    // console.log(model)
    if (this.isDetailing) {
      this._carWashService.updateVehicleJob(this.vehicle.id, model, this.jobModel.jobId).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.vehicle = response.data
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Qr code is assigned Successfully')
        }
      })
    }
    else {
      this._carWashService.updateVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.vehicle = response.data
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Qr code is assigned Successfully')
        }
      })
    }
  }
  redirection() {
    if (this.isDetailing) {
      if (this.user.role == 'admin' || this.user.role == 'manager') {
        this.router.navigate(['/admin/job-details', this.jobModel.jobId])
      }
      else {
        this.router.navigate(['/user/job-details', this.jobModel.jobId])
      }
    }
    else {
      if (this.user.role == 'admin' || this.user.role == 'manager') {
        this.router.navigate(['/admin/vehicles-list'])
      }
      else {
        this.router.navigate(['/user/vehicles-list'])
      }
    }
  }
  ngOnDestroy() {
    localStorage.setItem('isAssignedQR', 'false')
    localStorage.removeItem('type')
    localStorage.removeItem('selectedIndex')
    localStorage.removeItem('isDetailing')
  }
  // cash functions
  storeBuffProductPrice(product: any) {
    this.buffProduct = product
    this.buffProductPrice = this.getPrice(product.price)
    this.getTotalDuePrice()
  }
  getPrice(price) {
    return price / 100
  }
  getTotalDuePrice() {
    this.totalDue = Math.round((this.buffProductPrice / this.daysInMonth) * this.diffInDays)
    this.getCashBack()
    return this.totalDue;
  }
  getCashBack() {
    this.cashBackAmount = Math.round(this.receivedAmount - this.totalDue)
    if (this.cashBackAmount < 0) {
      this.isBack = true
    }
    else {
      this.isBack = false
    }
    return this.cashBackAmount
  }
  accountReceived(event) {
    this.receivedAmount = Number(event)
    this.getCashBack()
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.receivedAmount = 0
    this.startDate = moment(event.value);
    if (moment(event.value) >= moment(this.endDate)) {
      this.isDateCheck = true
      this.diffInDays = 0
    }
    else {
      this.isDateCheck = false
      this.startDate = moment(event.value);
      // this.endDate  ?  this.endDate : new Date()
      this.endDate = moment(this.endDate);
      this.diffInDays = Math.abs(this.startDate.diff(this.endDate, 'days'));
      this.getTotalDuePrice()
      console.log(this.diffInDays)
    }
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.receivedAmount = 0
    console.log(moment(event.value))
    console.log(moment(this.startDate))
    this.endDate = moment(event.value)
    if (moment(this.startDate) >= moment(event.value)) {
      this.isDateCheck = true
      this.diffInDays = 0
    }
    else {
      this.isDateCheck = false
      this.endDate = moment(event.value)
      // this.startDate  ?  this.startDate : new Date()
      this.startDate = moment(this.startDate);
      this.diffInDays = Math.abs(this.startDate.diff(this.endDate, 'days'));
      this.getTotalDuePrice()
      console.log(this.diffInDays)
    }
  }
  createNewCashSubscription() {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model = {
      cashMembershipInfo: {
        id: this.buffProduct.id,
        priceId: this.buffProduct.default_price,
        planName: this.buffProduct.name,
        price: this.buffProduct.price,
        startDate: this.startDate,
        startDateFormate: this.datePipe.transform(this.startDate, 'yyyy-M-d'),
        endDate: this.endDate,
        endDateFormate: this.datePipe.transform(this.endDate, 'yyyy-M-d'),
        numOfDays: this.diffInDays,
        totalDue: this.totalDue,
        receivedAmount: this.receivedAmount,
        cashBack: this.cashBackAmount,
        isPackage: false
      },
      history: {
        id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        startDate: this.startDate,
        endDate: this.endDate,
        planName: this.buffProduct.name,
        paymentType: this.paymentTypeEnum.cash,
        price: this.buffProduct.price,
        dateHistory: new Date(),
        timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
        key: this.historyCarWash.Create_Membership,
      }
    }
    if (this.isDetailing) {
      this._carWashService.createVehicleCashInJob(this.vehicle.id,model,this.jobModel.jobId).subscribe((res:ResponseModel)=>{
        if(this.user.role == 'admin' || this.user.role == 'manager'){
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if(this.user.role == 'customer' ){
          this.router.navigate(['/user/view-vehicle/',this.user.id])
        }
        else{
        this.router.navigate(['/user/vehicles-list'])
        }
    })
    }else{
      this._carWashService.createVehicleCash(this.vehicle.id, model).subscribe((res: ResponseModel) => {
        // if(res.success){
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if (this.user.role == 'customer') {
          this.router.navigate(['/user/view-vehicle/', this.user.id])
        }
        else {
          this.router.navigate(['/user/vehicles-list'])
        }
        // }
      })
    }
  }
  select(item) {
    this.selected = item;
  }
  isActive(item) {
    return this.selected === item;
  };
  storeBuffProductPackagingPrice(product: any) {
    this.buffProductPackaging = product
    this.pricePackageProduct = this.getPrice(product.price)
    this.cashBackPackageProduct = Math.round(this.receivedAmountPackageProduct - this.pricePackageProduct)
    if (this.cashBackPackageProduct < 0) {
      this.isBack1 = true
    }
    else {
      this.isBack1 = false
    }
  }
  accountReceivedPackaging(event) {
    this.receivedAmountPackageProduct = Number(event)
    this.cashBackPackageProduct = Math.round(event - this.pricePackageProduct)
    if (this.cashBackPackageProduct < 0) {
      this.isBack1 = true
    }
    else {
      this.isBack1 = false
    }
    return this.cashBackPackageProduct
  }
  createNewCashPackageSubscription() {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model = {
      cashMembershipInfo: {
        id: this.buffProductPackaging.id,
        priceId: this.buffProductPackaging.default_price,
        planName: this.buffProductPackaging.name,
        price: this.buffProductPackaging.price,
        // startDate :this.startDate,
        // startDateFormate:this.datePipe.transform(this.startDate, 'yyyy-M-d'),
        // endDate : this.endDate,
        // endDateFormate:this.datePipe.transform(this.endDate, 'yyyy-M-d'),
        // numOfDays : this.diffInDays,
        totalDue: this.pricePackageProduct,
        receivedAmount: this.receivedAmountPackageProduct,
        cashBack: this.cashBackPackageProduct,
        isPackage: true
      },
      history: {
        id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        dateHistory: new Date(),
        // endDate : this.endDate,
        planName: this.buffProduct.name,
        paymentType: this.paymentTypeEnum.cash,
        price: this.buffProduct.price,
        timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
        key: this.historyCarWash.Create_Membership,
      }
    }
    if (this.isDetailing) {
      this._carWashService.createVehicleCashInJob(this.vehicle.id,model,this.jobModel.jobId).subscribe((res:ResponseModel)=>{
        if(this.user.role == 'admin' || this.user.role == 'manager'){
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if(this.user.role == 'customer' ){
          this.router.navigate(['/user/view-vehicle/',this.user.id])
        }
        else{
        this.router.navigate(['/user/vehicles-list'])
        }
    })
    }
    else {
      this._carWashService.createVehicleCash(this.vehicle.id, model).subscribe((res: ResponseModel) => {
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if (this.user.role == 'customer') {
          this.router.navigate(['/user/view-vehicle/', this.user.id])
        }
        else {
          this.router.navigate(['/user/vehicles-list'])
        }
      })
    }
  }
  selectItem(i) {
    this.selectedID = i;
  }
  selectItemPackage(i) {
    this.selectedPackageIndex = i;
  }
  yourComponentMethodToTreatyCountryChangedEvent(event) {
    this.countrySelected = event
  }
  getCurrency() {
    return this._globalService.getCurrency()
  }
}
