import { environment } from 'src/environments/environment.prod';
import { CompanyPlanType } from './../../Enum/CompanyPlanType';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PricingService } from 'src/app/core/_services/pricing/pricing.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { CategoryCompany } from 'src/app/modules/company-admin/pages/permissions/Enum-Category/CategoryCompany';

@Component({
  selector: 'app-company-plans',
  templateUrl: './company-plans.component.html',
  styleUrls: ['./company-plans.component.scss']
})
export class CompanyPlansComponent implements OnInit {
  user: any = {}
  isFree = true
  isPro = true
  isPremium = true
  pricingObj: any = {}
  companyPlan: any
  isMore = false
  isMorePro = false
  role: any=''
  Car_Wash_Plans = environment.Car_Wash_Plans
  Inventory_Plans  = environment.Inventory_Plans
  categoryCompany = CategoryCompany

  constructor(
    private router: Router,
    private _pricingService: PricingService,

  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.companyPlan = this.user.companyPlan
    this.role =this.user.role
    if (this.user.role != 'admin') {
      this.router.navigate(['/login'])
    }
    this._pricingService.getPricing().subscribe((response: ResponseModel) => {
      if (response.success) {
        this.pricingObj = response.data
      }
    })
  }
  changePlanYearly(plan: number) {
    if (plan == 1) {
      this.isFree = !this.isFree
      // console.log(this.isFree )
    }
    else if (plan == 2) {
      this.isPro = !this.isPro
    }
    else if (plan == 3) {
      this.isPremium = !this.isPremium
    }
  }
  toggle() {
    this.isMore = !this.isMore
  }
  togglePro() {
    this.isMorePro = !this.isMorePro
  }
  storePlanType(plan:any){
    // console.log('plan',plan)
    let companyPlanType:any = 0
    switch(plan){
      case 0 : {
        if(this.isFree){
          companyPlanType = CompanyPlanType.Monthly
        }
        else if(!this.isFree){
          companyPlanType = CompanyPlanType.Yearly
        }
      break ;
      }
      case 1 : {
        if(this.isPro){
          companyPlanType = CompanyPlanType.Yearly
        }
        else if(!this.isPro){
          companyPlanType = CompanyPlanType.Monthly
        }
      break ;
      }
      case 2 : {
        if(this.isPremium){
          companyPlanType = CompanyPlanType.Yearly
        }
        else if(!this.isPremium){
          companyPlanType = CompanyPlanType.Monthly
        }
      break ;
      }
    }
    // console.log(companyPlanType)
    localStorage.setItem('companyPlanType',companyPlanType)
    localStorage.setItem('bufferPlan',plan)
    localStorage.setItem('companyCategoryType',this.user.categoryCompany)
    this.router.navigate(['/new-branch'])
  }
}
