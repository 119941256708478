import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';

@Component({
  selector: 'app-Disabled-Company',
  templateUrl: './Disabled-Company.component.html',
  styleUrls: ['./Disabled-Company.component.scss']
})
export class DisabledCompanyComponent implements OnInit {

  user:any={}
  companyInfo:any = {}
    constructor(private _globalService:GlobalService) { }

    ngOnInit() {
    this.companyInfo = JSON.parse(localStorage.getItem('user'))
      // this.user = JSON.parse(localStorage.getItem('user'))
      // this._globalService.companyFullSummary(this.user.companyId).subscribe((response:ResponseModel)=>{
      //   this.companyInfo = response.data
      // })
    }
}
