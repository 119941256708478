<div class="card">
    <div class="tittle-section">
        <p>
            Company Products
        </p>
    </div>
    <div class="products">
        <div class="row">
            <div class="col-lg-6" *ngFor="let product of products;let i=index">
                <div class="product">
                    <h5>{{product.name}} </h5>
                    <!-- <h6>{{getPrice(product.price)}} USD Billed Monthly</h6> -->
                    <p>{{product.description}}</p>
                    <hr>
                    <ul class="fa-ul">
                        <li *ngFor="let item of product.customDescription">
                            <span *ngIf="item.description != ''" class="fa-li"><i class="far fa-check-circle"></i></span>
                            <span *ngIf="item.description != ''">{{item.description}}</span>
                            <!-- <span *ngIf="item.description == ''">Not Added Yet</span> -->
                        </li>
                    </ul>
                    <div class="terms-condition" *ngIf="product.termAndConditionTemplate">
                        <strong>Terms & Conditions</strong>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="handleChange($event,i)">
                            <label class="form-check-label" for="flexCheckDefault" (click)="storeProduct(product);termsModal.show()"> Check here to indicate that you have read and agree to the
                                <a >Terms and Conditions</a>
                          </label>
                        </div>
                    </div>
                    <form #addForm="ngForm">
                        <!-- Enter email card  -->
                        <!-- <div class="form-group" *ngIf="!reNew && paymentType == paymentTypeEnum.card">
                          <mat-form-field appearance="outline">
                              <mat-label>Customer Email</mat-label>
                              <input matInput type="email" id="email{{i+1}}" required name="email{{i+1}}" [(ngModel)]="product.email" [pattern]="emailPattern">
                          </mat-form-field>
                      </div> -->
                        <!--  -->
                        <div class="form-group" *ngIf="paymentType == paymentTypeEnum.card">
                            <!-- <mat-form-field appearance="outline"> -->
                            <input type="email" id="emailCard" class="form-control" [readonly]="true" required name="emailCard" [(ngModel)]="emailCard">
                            <!-- </mat-form-field> -->
                        </div>
                        <button class="btn btn-sm btn-primary" [disabled]="!product.isCheck || addForm.invalid " (click)="createMemberShip(product)">Accept & Select</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #termsModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Terms & Conditions for {{product.name}}</h5>
                    <button type="button" class="close" (click)="termsModal.hide()" aria-label="Close">
                  <span>&times;</span>
               </button>
                </div>
                <div class="modal-body ">
                    <div class="form-control" readonly [innerHtml]="product.termAndConditionTemplate | safeHtml"> </div>
                </div>
            </div>
        </div>
    </div>
</div>