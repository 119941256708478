export enum RealTimeKeys {
  Job_Add = "addJob",
  User_Add = "addUser",
  Customer_Add = "addCustomer",
  InventoryItem_Add = "InventoryItem",
  InventoryItem_Update = "UpdateInventoryItem",
  Customer_Primary_Change = "CustomerPrimary",
  Job_Number_Change = "JobNumber",
  Tool_Change = "UpdateTool",
  Invite_User = "InviteUser",
  Invite_Customer = "InviteCustomer",
  Add_Truck = "addTruck",
  Company_Update = "CompanyUpdate",
  Company_Update_Logo = "CompanyUpdateLogo",
  Job_Add_Sig = "JobAddSig",
  Container_Use = "ContainerUse",
  Container_Trash = "ContainerTrash",
  Container_Add = "ContainerAdd",
  Container_Add_Inventory = "ContainerAddInventory",
  Inventory_Item_Trash = "InventoryItemTrash",
  Container_Restore = "ContainerRestore",
  Container_Update = "ContainerUpdate",
  InventoryItem_Restore = "InventoryItemRestore",
  Map_Update = "MapUpdate",
  Map_Update_Color = "MapUpdateColor",
  Map_Update_Column = "MapUpdateColumn",
  Map_Update_Rows = "MapUpdateRows",
  Hub_Update = "UpdateHub",
  Hub_Sort = "SortHub",
  Ticket_Add = "AddTicket",
  Ticket_Update = "UpdateTicket",
  Company_Update_Force = "ForceUpdateCompany",
  Ticket_Update_Status = "UpdateStatusTicket",
  Map_Update_Action = "MapUpdateAction",
  Job_Update_showItems = "UpdateShowItemsJob",
  UpdateHub = "UpdateHub",
  Show_Hide_Update = "ShowHideUpdate",
  Assigned_User = "UserAssigned",
  Assigned_Customer = "CustomerAssigned",
  Assigned_Truck = "TruckAssigned",
  Switch_Account = "AccountSwitch",
  Step_Delete = "DeleteStep",
  Person_Update = "UpdatePerson",
  Company_Update_Category = "UpdateCompanyCategory",
  Job_Delete_Sig = "DeleteSig",
  Company_Add_Key = "AddKeyCompany",
  InventoryItem_Add_Print = "InventoryItemPrint",
  Vehicle_Check_In = "CheckedInVehicle",
  Vehicle_Un_Check_In = "UnCheckedInVehicle",
  Vehicle_Check_Out = "CheckedOutVehicle",
  Vehicle_Add = "AddVehicle",
  Vehicle_Update = "UpdateVehicle",
  Vehicle_Delete = "DeleteVehicle",
  Vehicle_Change_Subscription = "ChangeSubscriptionVehicle",
  User_Disable = "disableUser",
  Service_Add = "AddService",
  Service_Update = "UpdateService",
  Service_Achieve = "AchieveService",
  Service_Assigned = "AssignedService",
  Vehicle_Assigned = "AssignedVehicle",
}
