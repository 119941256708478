import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { InventoryItemsService } from './../../../../../core/_services/InventoryItems/inventoryItems.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { InventoryItem } from 'src/app/models/inventory-item/InventoryItem';
import { ActivatedRoute } from '@angular/router';

interface model {
  quality: string,
  delivery: string,
  checked: boolean
}
@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {
  estimates: InventoryItem[] = []
  estimateModel: InventoryItem = {}
  storEstimates: InventoryItem;
  flipDiv1 = false;
  uniqId: string = ""
  isLinear = false;
  estimatesForm: FormGroup
  estimateDrivenForm: model = {} as model
  userName: string = "User Name"
  jobId: any
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private _inventoryItemsService: InventoryItemsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.createEstimateForm()
    this.estimateDrivenForm.checked = false
    // get Estimates
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['inventoryItems'].success) {
        this.estimates = response['inventoryItems'].data.filter(i => i.type === "estimate")
      }
    }, error => {
      console.log(error)
    })
    this.jobId = this.route.snapshot.paramMap.get('id');
  }
  createEstimateForm() {
    this.estimatesForm = this.fb.group({
      itemNumber: ['', Validators.required],
      itemName: ['', Validators.required],
      category: ['', Validators.required],
      volume: ['', Validators.required],
      weight: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      quality: [''],
      delivery: [''],
    })
  }

  onClick1() {
    this.flipDiv1 = !this.flipDiv1;
  }
  //********* Methods Inventory *******
  storeEstimate(item) {

    this.storEstimates = Object.assign({},item)
    this.uniqId = item.id
    this.estimateModel = Object.assign({},item)
  }
  addEstimate() {
    this.spinner.show()
    let estimateModel = this.estimatesForm.value
    estimateModel.type = "estimate"
    estimateModel.date = new Date()
    estimateModel.quality = this.estimateDrivenForm.quality
    estimateModel.delivery = this.estimateDrivenForm.delivery
    estimateModel.jobId = this.jobId
    setTimeout(() => {
      this._inventoryItemsService.AddInventoryItem(estimateModel).subscribe((response: ResponseModel) => {
        this.estimateDrivenForm.checked = false
        this.estimatesForm.reset()
        this.estimateDrivenForm.quality = ""
        this.estimateDrivenForm.delivery = ""
        this.estimates.push(response.data)
        this.spinner.hide();
      }, error => {
        console.log(error)
        this.spinner.hide();
      })
    }, 3000);
  }
  updateEstimate() {
    this.spinner.show()
    let inventoryModelNew = Object.assign({}, this.estimateModel);
    this.estimateModel.jobId = this.jobId
    inventoryModelNew.id = this.uniqId
    setTimeout(() => {
      this._inventoryItemsService.updateInventoryItem(this.estimateModel, this.uniqId,this.jobId).subscribe((response: ResponseModel) => {
        let item = response.data
        let index = this.estimates.findIndex(item => item.id == this.estimateModel.id);
        this.estimates[index] = item
        this.spinner.hide();
      }, error => {
        console.log(error)
      })
    }, 3000);
  }
  deleteEstimate() {
    this.spinner.show()
    setTimeout(() => {
      this.estimates.splice(this.estimates.findIndex(p => p.id == this.uniqId), 1);
      this.spinner.hide();
    }, 3000);
  }
  print() {
    window.print()
  }

  toggleEditable(event) {
    this.estimateDrivenForm.checked = true
    if (event.target.checked) {
      this.estimateDrivenForm.delivery = this.estimateDrivenForm.quality;
    }
    else {
      this.estimateDrivenForm.delivery = "",
        this.estimateDrivenForm.checked = false
    }
  }

}
