export enum ActionsCar {
  Edit_Vehicle_Profile,
  View_Card_Subscription,
  Create_Card_Subscription,
  Create_Cash_Subscription,
  View_Cash_Subscription,
  Force_Plan_Change,
  Delete_Vehicle_Profile,
  Disabled_Payment_Type,
  Customer_Route,
  Qr_Code,
}
