import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDatePipe'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string) {
    var datePipe = new DatePipe("en-US");
    try{
    //  15, Oct 2022 | date:'M/d/yy, h:mm a'
     value = datePipe.transform(value, 'MMM d,  y');
     return value;
    }
    catch(exp){
      let myVar = JSON.parse(value);
      let myDate = new Date(myVar.year, myVar.month, myVar.day)
      value = datePipe.transform(myDate, 'M/d/yy');
      return value;
    }
  }
}
