import { ToolSettingEditItem } from './../../../models/tools-model/ToolSettingEditItem';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Injectable } from '@angular/core';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';

@Injectable({
  providedIn: 'root'
})
export class TrucksService {
  baseUrl = environment.baseUrl
  truckId
  truckJobId
  constructor(private http: HttpClient) {
    this.truckId = ToolSettingEditItem.Trucks_list
    this.truckJobId = ToolJobsEditItem.assigned_Truck
  }
  getAllTrucksByCompany(companyId: any) {
    return this.http.get(this.baseUrl + 'allTrucks/' + companyId + '/' + this.truckId)
  }
  getTruckById(id) {
    // let truckId = ToolJobsEditItem.trucks_List
    return this.http.get(this.baseUrl + 'truck/' + id + "/" + this.truckId)
  }
  getAllTrucksFiltersByCompany(companyId: string) {
    // let truckId = ToolJobsEditItem.trucks_List
    return this.http.get(this.baseUrl + 'trucks/' + companyId + "/" + this.truckId)
  }
  getAllTrucksByJob(jobId: string) {
    // let truckId = ToolJobsEditItem.trucks_List
    return this.http.get(this.baseUrl + 'trucksByJob/' + jobId + "/" + this.truckId)
  }
  getAllInventoryItemsByTruck(id: string) {
    return this.http.get(this.baseUrl + 'truckItems/' + id + "/" + this.truckJobId)
  }
  getAllInventoryItemsByTruckInJob(id: string, jobId: string) {
    return this.http.get(this.baseUrl + 'truckItemsInJob/' + id + "/" + jobId + '/' + this.truckJobId)
  }
  getAllTrucksView(companyId: string) {
    return this.http.get(this.baseUrl + 'allTrucksView/' + companyId + '/' + this.truckId)
  }
  isTruckExist(companyId: string, truckNumber: any) {
    return this.http.get(this.baseUrl + 'isTruckExist/' + companyId + '/' + this.truckId + '?truckNumber=' + truckNumber)
  }
  addTruck(model) {
    // let truckId = ToolJobsEditItem.trucks_List
    // model.idItem = ToolJobsEditItem.trucks_List
    return this.http.post(this.baseUrl + 'truck/' + this.truckId, model)
  }
  updateTruck(id: string, newTruck: any) {
    return this.http.put(this.baseUrl + 'truck/' + id + "/" + this.truckId, newTruck)
  }
  getTrucksByCompany(companyId: any) {
    // let truckId = ToolJobsEditItem.trucks_List
    return this.http.get(this.baseUrl + 'allTrucks/' + companyId + "/" + this.truckId)
  }
  updateTruckService(id: string, inService: any) {
    // let truckId = ToolJobsEditItem.trucks_List/
    return this.http.put(this.baseUrl + 'truckService/' + id + "/" + this.truckId, { inService: inService })
  }
  updateTruckDriver(id: string, driver: any) {
    // let truckId = ToolJobsEditItem.trucks_List
    return this.http.put(this.baseUrl + 'truckDriver/' + id + "/" + this.truckId, { primaryDriver: driver })
  }
}
