import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { Router } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyStatus } from 'src/app/models/company/company';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  baseUrl = environment.baseUrl
  items: ResponseModel
  private messageSource: BehaviorSubject<any>;
  public currentItems: Observable<any>
  constructor(
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private _toaster: ToasterCustomService,
  ) {
    // this.getAllToolsByCompany()
  }
  getAllToolsByCompany() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user != null) {
      return this.http.get(this.baseUrl + 'tools/' + user.companyId).subscribe((response: ResponseModel) => {
        this.items = response
        this.messageSource = new BehaviorSubject<any>(this.items);
        this.currentItems = this.messageSource.asObservable()
      })
    }
  }
  companyFullSummary(id: any) {
    return this.http.get(this.baseUrl + "companyFullSummary/" + id)
  }
  logout() {
    // localStorage.removeItem('user')
    // console.log('aftar remove')
    let user = JSON.parse(localStorage.getItem('user'))
    this.http.post(this.baseUrl + 'logout/' + user.id + '/' + user.uid, {}).subscribe((response:ResponseModel)=>{
      if(response.success){
        localStorage.clear();
        this.router.navigate(['/Login']);
      }
    })
  }
  logoutSuperAdmin() {
        localStorage.clear();
        this.router.navigate(['/Login']);
  }
  getComapnyInfo(company:any,type:number){
    localStorage.clear()
    let user :any = {}
    if(type == CompanyStatus.Canceled){
      user = {
          companyStatus: CompanyStatus.Canceled,
          companyName: company.companyName,
          companyAddress: company.companyAddress,
          }
        localStorage.setItem('user',JSON.stringify(user))
        this.router.navigate(['/disabled-company'])
      }
      if(type == CompanyStatus.Deactivated) {
        user = {
          companyStatus: CompanyStatus.Canceled,
          companyName: company.companyName,
          companyAddress: company.companyAddress,
          }
        localStorage.setItem('user',JSON.stringify(user))
          this.router.navigate(['/deactivated-company'])
      }
      if(type == CompanyStatus.NotPayPeriod) {
        user = {
          companyStatus: CompanyStatus.Canceled,
          companyName: company.companyName,
          companyAddress: company.companyAddress,
          companyPlan: company.companyPlan,
          url: company.url,
          }
          localStorage.setItem('user',JSON.stringify(user))
          this.router.navigate(['/not-period'])
      }
  }
  goBack(){
      window.history.back();
  }
   openLink(medUrl:any){
    console.log(medUrl)
    if(Capacitor.isNativePlatform()) {
      //  console.log('mobile')
      //  Browser.open({ url: medUrl });
       this._toaster.warning('open from browser','')
    } else {
      // console.log('desktop')
      window.open(medUrl,'_blank')
      // window.location.href = medUrl
    }
    // let device = this.deviceService.getDeviceInfo()
    // if(device.browser == "Safari" ){
    //   let windowReference = window.open();
    //   windowReference.location = url;
    // }
    // else{
      // window.open(url,'_blank')
    // }
  }
  getCurrency(){
    let currency = localStorage.getItem('currency')
    switch(currency){
      case 'USD': {return '$'}
      case 'GBP': {return '£'}
      case 'AUD': {return '$'}
      case 'EUR': {return '€'}
      case 'ZAR': {return 'R'}
      case 'AED': {return 'AED'}
    }
  }
}

