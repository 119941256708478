<!-- Services Additional main -->
<div class="card mb-3">
    <div class="tittle-section">
        <p> Car Wash Services</p>
        <div class=" btn-add-new" *ngIf="carWashServicesObj.roleStatus == 'active'|| user.role == 'admin' " (click)="addServiceModal.show()">
            <span class="badge badge-success">Add New</span>
        </div>
    </div>
    <div class="services" *ngIf="carWashServices.length > 0">
        <div class="col-lg-12 ">
            <div class="form-group">
                <input type="text" id="search-text" class="form-control" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search service name" />
            </div>
        </div>
        <mat-accordion *ngFor="let service of carWashServices | serviceFilter: searchText | paginate: {id: 'ser_normal_id' , itemsPerPage: itemsPerPage, currentPage: p };let i=index">
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>{{service.serviceName}}
                    <strong class="required" *ngIf="service.isAchieve">(Archive)</strong>
                </mat-expansion-panel-header>
                <div class="service-info">
                    <div class="service-header">
                        <p class="date-time"><strong>Last Updated:</strong> {{service.date | date: 'MMM d, y'}}, {{service.time}}</p>
                    </div>
                    <div class="item-service">
                        <p>Description</p>
                        <span *ngIf="service.serviceDesc">{{service.serviceDesc}}</span>
                        <span *ngIf="!service.serviceDesc">No Data</span>
                    </div>
                    <div class="item-service" *ngFor="let item of service.sizes; let i=index">
                        <p>size / price </p>
                        <span class="size-price">
                    <span>{{item['size' + i ]}}</span>
                        <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{item['price' + i ]}}</span>
                        </span>
                    </div>
                    <div class="item-service" *ngFor="let item of service.perks; let i=index">
                        <p>Perk{{i + 1}}</p>
                        <span *ngIf="item['perk' + i ] != ''">{{item['perk' + i ]}}</span>
                        <span *ngIf="item['perk' + i ] == ''">No Data</span>
                    </div>
                </div>
                <!-- <mat-tab-group>
                    <mat-tab label="Service Details">
                        <div class="service-header">
                            <p class="date-time"><strong>Last Updated:</strong> {{service.date | date: 'MMM d, y'}}, {{service.time}}</p>
                        </div>
                        <div class="item-service">
                            <p>Description</p>
                            <span *ngIf="service.serviceDesc">{{service.serviceDesc}}</span>
                            <span *ngIf="!service.serviceDesc">No Data</span>
                        </div>
                        <div class="item-service" *ngFor="let item of service.sizes; let i=index">
                            <p>size / price </p>
                            <span class="size-price">
                            <span>{{item['size' + i ]}}</span>
                            <span class="badge bg-primary rounded-pill">${{item['price' + i ]}}</span>
                            </span>
                        </div>
                        <div class="item-service" *ngFor="let item of service.perks; let i=index">
                            <p>Perk{{i + 1}}</p>
                            <span *ngIf="item['perk' + i ] != ''">{{item['perk' + i ]}}</span>
                            <span *ngIf="item['perk' + i ] == ''">No Data</span>
                        </div>
                    </mat-tab>
                    <mat-tab label="Sizes & Prices">
                        <div class="item" *ngFor="let item of service.sizes; let i=index">
                            <label>
                              <span>size </span>
                              <span>price </span>
                            </label>
                            <h6>
                                <span>{{item['size' + i ]}}</span>
                                <span class="badge bg-primary rounded-pill">{{item['price' + i ]}} $</span>
                            </h6>
                        </div>
                    </mat-tab>
                </mat-tab-group> -->
                <div class="service-footer">
                    <button class="btn btn-sm btn-success" (click)="storeEdit(service,i)">Edit</button>
                    <button class="btn btn-sm btn-danger" (click)="carWashServiceStatus(service.id)">
                      <span>{{service.isAchieve ? 'UnArchive' : 'Archive'}}</span>
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="mt-1" *ngIf="carWashServices.length > 0">
        <pagination-controls previousLabel="" nextLabel="" id="ser_normal_id" (pageChange)="handlingNormalPagination($event) "></pagination-controls>
    </div>
    <div class="ant-empty-image" *ngIf="carWashServices.length <= 0">
        <img src="./assets/img/icons/empty.svg" alt="empty-img">
        <span>No Data</span>
    </div>
</div>
<!-- Services Additional section -->
<div class="card">
    <div class="tittle-section">
        <p>Other Services</p>
        <div class=" btn-add-new" *ngIf="carWashServicesObj.roleStatus == 'active'|| user.role == 'admin' " (click)="addServiceAdditionalModal.show()">
            <span class="badge badge-success">Add New</span>
        </div>
    </div>
    <div class="services" *ngIf="additionalServices.length > 0">
        <div class="col-lg-12 ">
            <div class="form-group">
                <input type="text" id="search-text1" class="form-control" aria-describedby="search-text1" (keydown)="restOtherPagination($event)" [(ngModel)]="searchText1" placeholder="Search service name" />
            </div>
        </div>
        <mat-accordion *ngFor="let service of additionalServices | serviceFilter: searchText1 | paginate: {id: 'ser_other_id' , itemsPerPage: itemsPerPage, currentPage: p1 };let i=index">
            <mat-expansion-panel>
                <mat-expansion-panel-header>{{service.serviceName}}</mat-expansion-panel-header>
                <div>
                    <div class="service-header">
                        <p class="date-time"><strong>Last Updated:</strong> {{service.date | date: 'MMM d, y'}}, {{service.time}}</p>
                        <button class="btn btn-sm btn-success" (click)="storeAdditionalEdit(service,i)">Edit</button>
                    </div>
                    <div class="item-service">
                        <p>Name/ price </p>
                        <span class="size-price">
                        <span>{{service.serviceName}}</span>
                        <span class="badge bg-primary rounded-pill">{{getCurrency()}}{{service.price}}</span>
                        </span>
                    </div>
                    <!-- <div class="item">
                        <label>
                        <span>Service Name</span>
                        <span>Price</span>
                     </label>
                        <h6>
                            <span>{{service.serviceName}}</span>
                            <span class="badge bg-primary rounded-pill">{{service.price}} $</span>
                        </h6>
                    </div> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="mt-1" *ngIf="additionalServices.length > 0">
        <pagination-controls previousLabel="" nextLabel="" id="ser_other_id" (pageChange)="handlingOtherPagination($event) "></pagination-controls>
    </div>
    <div class="ant-empty-image" *ngIf="additionalServices.length <= 0">
        <img src="./assets/img/icons/empty.svg" alt="empty-img">
        <span>No Data</span>
    </div>
</div>
<!-- add car wash normal service -->
<div mdbModal #addServiceModal="mdbModal" class="modal services-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Service</h5>
                    <button type="button" class="close" form="formServiceInfo" data-dismiss="modal" (click)="addServiceModal.hide();restoreService();formServiceInfo.reset()" aria-label="Close">
                      <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
                        <mat-tab label="Service Details">
                            <form #formServiceInfo="ngForm" id="formServiceInfo">
                                <h6>Service Information</h6>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Service Name</mat-label>
                                        <input matInput type="text" required name="serviceName" (input)="checkServiceName($event.target.value)" #ref_name_ser="ngModel" [(ngModel)]="serviceInfoObj.serviceName">
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_name_ser.errors && ref_name_ser.touched && ref_name_ser.errors.required ">
                                        service name is required
                                    </mat-error>
                                    <mat-error *ngIf="isServiceNameExists">
                                        service name is exists
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Service Description</mat-label>
                                        <textarea rows="2" cols="26" matInput type="text" name="serviceDesc" [(ngModel)]="serviceInfoObj.serviceDesc"> </textarea>
                                    </mat-form-field>
                                </div>
                                <h6>
                                    <span>Service Perks</span>
                                    <span class="plus" (click)="addPerk()">
                                    <i class="fas fa-pluse"></i>
                                    <i class="uil uil-plus-circle uil-extra-small success"></i>
                                  </span>
                                </h6>
                                <div class="form-group" *ngFor="let item of carWashServicesPerks; let i=index">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" [name]="'item.perk' + i" [(ngModel)]="item['perk' + i]">
                                        <button mat-icon-button matSuffix><i class="uil uil-trash-alt required" (click)="removePerk(i)"></i></button>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk2" [(ngModel)]="serviceInfoObj.perk2">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk3" [(ngModel)]="serviceInfoObj.perk3">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk4" [(ngModel)]="serviceInfoObj.perk4">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk5" [(ngModel)]="serviceInfoObj.perk5">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk6" [(ngModel)]="serviceInfoObj.perk6">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk7" [(ngModel)]="serviceInfoObj.perk7">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk8" [(ngModel)]="serviceInfoObj.perk8">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk9" [(ngModel)]="serviceInfoObj.perk9">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk10" [(ngModel)]="serviceInfoObj.perk10">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk11" [(ngModel)]="serviceInfoObj.perk11">
                                    </mat-form-field>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" name="perk12" [(ngModel)]="serviceInfoObj.perk12">
                                    </mat-form-field>
                                </div> -->
                            </form>
                            <div class="add-btn">
                                <!-- <button class="btn btn-success" form="formServiceInfo" [disabled]="!formServiceInfo.valid" (click)="addInfoService();addServiceModal.hide()">Save</button> -->
                                <button class="btn btn-success" form="formServiceInfo" [disabled]="!formServiceInfo.valid" (click)="nextTab()">Next</button>
                            </div>
                        </mat-tab>
                        <mat-tab label="Prices for Different sizes">
                            <form #formServiceInfoSizes="ngForm" id="formServiceInfoSizes">
                                <div class="col-12 px-0">
                                    <h6>
                                        <span>Sizes & Prices</span>
                                        <span class="plus" (click)="addSizeAndPrice()">
                                          <i class="fas fa-pluse"></i>
                                          <i class="uil uil-plus-circle uil-extra-small success"></i>
                                        </span>
                                    </h6>
                                </div>
                                <div class="row mt-2" *ngFor="let item of carWashServicesSizes; let i=index">
                                    <div class="col-8">
                                        <div class="form-group ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>type/size</mat-label>
                                                <input matInput type="text" required [name]="'item.size' + i" [(ngModel)]="item['size' + i]">
                                                <button mat-icon-button matSuffix><i class="uil uil-trash-alt required" (click)="removeSize(i)"></i></button>
                                            </mat-form-field>
                                            <!-- <mat-error *ngIf="ref_size.errors && ref_size.touched && ref_size.errors.required ">
                                                size is required
                                            </mat-error> -->
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <!-- <mat-label>Price</mat-label> -->
                                                <input matInput type="number" required [name]="'item.price' + i" [(ngModel)]="item['price' + i]">
                                                <span matSuffix>{{getCurrency()}}</span>
                                            </mat-form-field>
                                            <!-- <mat-error *ngIf="ref_price.errors && ref_price.touched && ref_price.errors.required ">
                                                price is required
                                            </mat-error> -->
                                        </div>
                                    </div>
                                    <!-- <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size2" [(ngModel)]="serviceInfoObj.size2">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price2" [(ngModel)]="serviceInfoObj.price2">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size3" [(ngModel)]="serviceInfoObj.size3">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price3" [(ngModel)]="serviceInfoObj.price3">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size4" [(ngModel)]="serviceInfoObj.size4">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price4" [(ngModel)]="serviceInfoObj.price4">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size5" [(ngModel)]="serviceInfoObj.size5">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price5" [(ngModel)]="serviceInfoObj.price5">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size6" [(ngModel)]="serviceInfoObj.size6">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price6" [(ngModel)]="serviceInfoObj.price6">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size7" [(ngModel)]="serviceInfoObj.size7">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price7" [(ngModel)]="serviceInfoObj.price7">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size8" [(ngModel)]="serviceInfoObj.size8">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price8" [(ngModel)]="serviceInfoObj.price8">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size9" [(ngModel)]="serviceInfoObj.size9">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price9" [(ngModel)]="serviceInfoObj.price9">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size10" [(ngModel)]="serviceInfoObj.size10">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price10" [(ngModel)]="serviceInfoObj.price10">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size11" [(ngModel)]="serviceInfoObj.size11">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price11" [(ngModel)]="serviceInfoObj.price11">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Add Vehicle type/size here</mat-label>
                                                <input matInput type="text" name="size12" [(ngModel)]="serviceInfoObj.size12">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Price</mat-label>
                                                <input matInput type="number" name="price12" [(ngModel)]="serviceInfoObj.price12">
                                                <span matSuffix>$</span>
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="add-btn">
                                    <button class="btn btn-success" form="formServiceInfo" [disabled]="!formServiceInfo.valid || !formServiceInfoSizes.valid || isServiceNameExists" (click)="addInfoServiceSizes(formServiceInfo);addServiceModal.hide()">Save</button>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="editVehicleModal.hide()">Cancel</button>
                  <button type="button" class="btn btn-success" (click)="editVehicleModal.hide() ">Save </button>
              </div> -->
            </div>
        </div>
    </div>
</div>
<!-- add additional car wash  service -->
<div mdbModal #addServiceAdditionalModal="mdbModal" class="modal services-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Other Service</h5>
                    <button type="button" form="formAdditional" class="close" data-dismiss="modal" (click)="addServiceAdditionalModal.hide();resetOther();formAdditional.reset()" aria-label="Close">
                      <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <form #formAdditional="ngForm" id="formAdditional">
                        <div class="row mt-2">
                            <div class="col-lg-9">
                                <div class="form-group ">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Service Name</mat-label>
                                        <input matInput type="text" #ref_name="ngModel" (input)="checkOtherServiceName($event.target.value)" name="serviceName" required [(ngModel)]="additionalServiceObj.serviceName">
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_name.errors && ref_name.touched && ref_name.errors.required">
                                        service name is required
                                    </mat-error>
                                    <mat-error *ngIf="isServiceNameExists2">
                                        service name is exist
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput type="number" #ref_price="ngModel" name="price" required [(ngModel)]="additionalServiceObj.price">
                                        <span matSuffix>{{getCurrency()}}</span>
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_price.errors && ref_price.touched && ref_price.errors.required">
                                        price is required
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="add-btn">
                        <button class="btn btn-success" form="formAdditional" [disabled]="!formAdditional.valid || isServiceNameExists2" (click)="addAdditionalService(formAdditional);addServiceAdditionalModal.hide()">Save</button>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="editVehicleModal.hide()">Cancel</button>
                  <button type="button" class="btn btn-success" (click)="editVehicleModal.hide() ">Save </button>
              </div> -->
            </div>
        </div>
    </div>
</div>
<!-- edit car wash normal service -->
<div mdbModal #editServiceModal="mdbModal" class="modal services-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Service</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editServiceModal.hide();restoreService()" aria-label="Close">
                    <span>&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <mat-tab-group [selectedIndex]="selectedIndex1" (selectedTabChange)="onTabChangedEdit($event)">
                        <mat-tab label="Service Details">
                            <form #formServiceInfoEdit="ngForm" id="formServiceInfoEdit">
                                <h6>Service Information</h6>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Service Name</mat-label>
                                        <input matInput type="text" required name="serviceName" (input)="checkServiceName($event.target.value)" #ref_name_ser1="ngModel" [(ngModel)]="carWashServicesObjEdit.serviceName">
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_name_ser1.errors && ref_name_ser1.touched && ref_name_ser1.errors.required">
                                        service name is required
                                    </mat-error>
                                    <mat-error *ngIf="isServiceNameExists">
                                        service name is exist
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Product Description</mat-label>
                                        <textarea rows="2" cols="26" matInput type="text" name="serviceDesc" [(ngModel)]="carWashServicesObjEdit.serviceDesc"> </textarea>
                                    </mat-form-field>
                                </div>
                                <h6>
                                    <span>Service Perks</span>
                                    <span class="plus" (click)="addPerkEdit()">
                                  <i class="fas fa-pluse"></i>
                                  <i class="uil uil-plus-circle uil-extra-small success"></i>
                                </span>
                                </h6>
                                <div class="form-group" *ngFor="let item of carWashServicesObjEdit.perks; let i=index">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Add Perk here</mat-label>
                                        <input matInput type="text" [name]="'item.perk' + i" [(ngModel)]="item['perk' + i]">
                                        <button mat-icon-button matSuffix><i class="uil uil-trash-alt required" (click)="checkRemove(false,i)"></i></button>
                                    </mat-form-field>
                                </div>
                            </form>
                            <div class="add-btn">
                                <!-- <button class="btn btn-success" form="formServiceInfo" [disabled]="!formServiceInfo.valid" (click)="addInfoService();addServiceModal.hide()">Save</button> -->
                                <button class="btn btn-success" form="formServiceInfoEdit" [disabled]="!formServiceInfoEdit.valid" (click)="nextTabEdit()">Next</button>
                            </div>
                        </mat-tab>
                        <mat-tab label="Prices for Different sizes">
                            <form #formServiceInfoSizesEdit="ngForm" id="formServiceInfoSizesEdit">
                                <div class="col-12 px-0">
                                    <h6>
                                        <span>Sizes & Prices</span>
                                        <span class="plus" (click)="addSizeAndPriceEdit()">
                                      <i class="fas fa-pluse"></i>
                                      <i class="uil uil-plus-circle uil-extra-small success"></i>
                                    </span>
                                    </h6>
                                </div>
                                <div class="row mt-2" *ngFor="let item1 of carWashServicesObjEdit.sizes; let i=index">
                                    <div class="col-8">
                                        <div class="form-group ">
                                            <mat-form-field appearance="outline">
                                                <mat-label>type/size</mat-label>
                                                <input matInput type="text" [required]="item1.isEdit" [value]="'item1.size' + i" [readonly]="!item1.isEdit" [name]="'item1.size' + i" [(ngModel)]="item1['size' + i]">
                                                <button mat-icon-button matSuffix *ngIf="item1.isEdit"><i class="uil uil-trash-alt required" (click)="checkRemove(true,i)"></i></button>
                                            </mat-form-field>
                                            <!-- <mat-error *ngIf="ref_size{{i}}.errors && ref_size{{i}}.touched && ref_size{{i}}.errors.required ">
                                                size is required
                                            </mat-error> -->
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <mat-form-field appearance="outline">
                                                <!-- <mat-label>Price</mat-label> -->
                                                <input matInput type="number" [required]="item1.isEdit" [readonly]="!item1.isEdit" min="0" [value]="'item1.price' + i" [name]="'item1.price' + i" [(ngModel)]="item1['price' + i]">
                                                <span matSuffix>{{getCurrency()}}</span>
                                            </mat-form-field>
                                            <!-- <mat-error *ngIf="ref_price1.errors && ref_price1.touched && ref_price1.errors.required ">
                                                price is required
                                            </mat-error> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="add-btn">
                                    <button class="btn btn-success" form="formServiceInfoEdit" [disabled]="!formServiceInfoEdit.valid || !formServiceInfoSizesEdit.valid  ||  isServiceNameExists" (click)="updateInfoServiceSizes();editServiceModal.hide()">Save</button>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="editVehicleModal.hide()">Cancel</button>
                <button type="button" class="btn btn-success" (click)="editVehicleModal.hide() ">Save </button>
            </div> -->
            </div>
        </div>
    </div>
</div>
<!-- edit additional car wash  service -->
<div mdbModal #editServiceAdditionalModal="mdbModal" class="modal services-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Other Service</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editServiceAdditionalModal.hide();resetOther()" aria-label="Close">
                    <span>&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <form #formAdditionalEdit="ngForm" id="formAdditionalEdit">
                        <div class="row mt-2">
                            <div class="col-lg-9">
                                <div class="form-group ">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Service Name</mat-label>
                                        <input matInput type="text" #ref_name="ngModel" name="serviceName" (input)="checkOtherServiceName($event.target.value)" required [(ngModel)]="additionalServiceObjEdit.serviceName">
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_name.errors && ref_name.touched && ref_name.errors.required">
                                        service name is required
                                    </mat-error>
                                    <mat-error *ngIf="isServiceNameExists2">
                                        service name is exist
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput type="number" #ref_price="ngModel" name="price" required [(ngModel)]="additionalServiceObjEdit.price">
                                        <span matSuffix>{{getCurrency()}}</span>
                                    </mat-form-field>
                                    <mat-error *ngIf="ref_price.errors && ref_price.touched && ref_price.errors.required">
                                        price is required
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="add-btn">
                        <button class="btn btn-success" form="formAdditionalEdit" [disabled]="!formAdditionalEdit.valid" (click)="updateAdditionalService();editServiceAdditionalModal.hide()">Save</button>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="editVehicleModal.hide()">Cancel</button>
                <button type="button" class="btn btn-success" (click)="editVehicleModal.hide() ">Save </button>
            </div> -->
            </div>
        </div>
    </div>
</div>
<div mdbModal #removeModal="mdbModal" class="modal delete-modal mt-5" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Confiramtion</h6>
                    <button type="button" class="close" (click)="removeModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body text-center">
                    <span> Do you want to remove? </span><br>
                    <div class="pt-3">
                        <button class="btn btn-sm btn-danger-custom" (click)="removeModal.hide()">Cancel</button>
                        <button class="btn btn-sm btn-success-custom" *ngIf="isRemoveSize" (click)="removeModal.hide();removeSizeEdit(bufferIndex)">Confirm</button>
                        <button class="btn btn-sm btn-success-custom" *ngIf="!isRemoveSize" (click)="removeModal.hide();removePerkEdit(bufferIndex)">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- In Queue -->
<div mdbModal #inQueueModal="mdbModal" class="modal delete-modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Action can NOT be completed</h6>
                    <button type="button" class="close" (click)="inQueueModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center">
                    <span> Queue Must have no Vehicles to proceed.
                     </span><br>
                    <!-- <div class="pt-3">
                      <button class="btn btn-sm btn-danger-custom" (click)="removeModal.hide()">Cancel</button>
                      <button class="btn btn-sm btn-success-custom" *ngIf="isRemoveSize" (click)="removeModal.hide();removeSizeEdit(bufferIndex)">Confirm</button>
                      <button class="btn btn-sm btn-success-custom" *ngIf="!isRemoveSize" (click)="removeModal.hide();removePerkEdit(bufferIndex)">Confirm</button>
                  </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
