import { NgxPrintElementService } from 'ngx-print-element';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment.prod';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-container-tag',
  templateUrl: './container-tag.component.html',
  styleUrls: ['./container-tag.component.scss']
})
export class ContainerTagComponent implements OnInit {
  @Input() objFromParent1: any = {};
  date: any = new Date()
  baseUrl = environment.baseDomain
  constructor(
    public print: NgxPrintElementService,
    private deviceService: DeviceDetectorService
    ) { }

  ngOnInit() {
  }
  printFixed(id) {
    //  if (this.deviceService.isDesktop()) {
      let  printContents = document.getElementById(id).innerHTML;
      let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
       popupWin.document.write(`
       <html>
         <head>
           <title>print</title>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
           <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
           <style>
           body {
            margin : 0.5rem auto;
            text-align : center;
            page-break-after: auto;
          }
          .item-print {
            border : 2px solid #000;
            margin: 8px;
          }
          h6 {
            font-size: 3rem;
            padding-top: 6px;
            margin: 0;
            color: #4285f4;
            font-weight: 700;
          }
          label {
            font-size: 3rem ;
            font-weight: 700;
          }
          @media print {
            .pagebreak  {
                page-break-before: always;
            }
            .btn-sm{
              display:none;
            }
            /* page-break-after works, as well */
        }
           </style>
         </head>
         <body onload="window.print()">
         <button class="btn btn-sm btn-primary" onclick="window.close()">Back</button>
         ${printContents}</body>
       </html>`
       );
       popupWin.document.close();
    //  } else {
    //   let url:any =`${this.baseUrl}container-tag-print?isAccess=true&id=${id}&data=${JSON.stringify(this.objFromParent1)}`
    //   Browser.open({ url: url });
    //  }
   }
}
