import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { JobsService } from '../../_services/jobs/jobs.service';

@Injectable()

export class UsersJobsResolver implements Resolve<any[]>
{
  constructor(private _jobsService: JobsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user.role == 'teamleader' || user.role == 'crew' || user.role == '3rdparty'){
      return this._jobsService.getAllJobsByUser(user.companyId, user.id).pipe(
        catchError(error => {
          console.log(error)
          return of(null);
        })
      );
    }
    else if (user.role == 'customer') {
      return this._jobsService.getAllJobsByCustomer(user.companyId, user.id).pipe(
        catchError(error => {
          console.log(error)
          return of(null);
        })
      );
    }
  }
}
