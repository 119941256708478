import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { OnlineOfflineServiceService } from '../onlineoffline/onlineofflineService.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryItemsService {
  baseUrl = environment.baseUrl
  idItem: any
  constructor(
    private http: HttpClient,
    public onlineOfflineService: OnlineOfflineServiceService
  ) {
    this.idItem = ToolJobsEditItem.inventory
  }

  getInventoryItems() {
    return this.http.get(this.baseUrl + 'inventory' + '/' + this.idItem);
  }
  getAllInventoryItemsByJob(id: any) {
    return this.http.get(this.baseUrl + 'inventoryJob/' + id + '/' + this.idItem);
  }
  getInventoryItemById(id: any) {
    return this.http.get(this.baseUrl + 'inventory/' + id + '/' + this.idItem);
  }
  getAllInventoryItemsByJobWithType(jobId: string, status: any) {
    return this.http.get(this.baseUrl + 'inventoryJobType/' + jobId + '/' + this.idItem + '/?status=' + status);
  }
  getAllInventoryItemsByFrontSpaceInJob(id: string, jobId: string) {
    return this.http.get(this.baseUrl + 'FrontSpaceItemsJob/' + id + '/' + jobId);
  }
  getAllInventoryItemMixed(companyId: string, jobId: string) {
    return this.http.get(this.baseUrl + 'inventoryItemMixed/' + companyId + '/' + jobId + '/' + this.idItem);
  }
  getAllInventoryItemMixedSummary(companyId: string, jobId: string) {
    return this.http.get(this.baseUrl + 'inventoryItemMixedSummary/' + companyId + '/' + jobId);
  }
  getAllInventoryItemsByStep(stepId: string) {
    return this.http.get(this.baseUrl + 'inventoryStep/' + stepId + '/' + this.idItem)
  }
  getPrintInventoryItemsTemplate(companyId: string, jobId: string) {
    return this.http.get(this.baseUrl + 'inventoryItemsTemplate/' + companyId + '/' + jobId + '/' + this.idItem)
  }
  isInventoryItemExist(jobId: string, itemNumber: any) {
    return this.http.get(this.baseUrl + 'isInventoryItemExist/' + jobId + '/' + this.idItem + '?itemNumber=' + itemNumber)
  }
  getInventoryTrashedJob(jobId: string) {
    return this.http.get(this.baseUrl + 'inventoryTrashedJob/' + jobId + '/' + this.idItem);
  }
  getAllInventoryItemsForPrintByJob(jobId: string,idItem:number) {
    return this.http.get(this.baseUrl + 'inventoryJobPrint/' + jobId + '/' + idItem);
  }
  AddInventoryItem(InventoryItem) {
    if (!this.onlineOfflineService.isOnline) {
      this.onlineOfflineService.addToIndexedDb(InventoryItem, 'inventory');
    }
    else {
      return this.http.post(this.baseUrl + 'inventory' + '/' + InventoryItem.jobId + '/' + this.idItem, InventoryItem);
    }
  }
  updateInventoryItem(newInventoryItem: any, jobId: string, id: any) {
    return this.http.put(this.baseUrl + 'inventory/' + jobId + '/' + id + '/' + this.idItem, newInventoryItem);
  }
  printInventoryItemsByJob(companyId: string, jobId: string,model:any) {
    return this.http.post(this.baseUrl + 'printInventoryItems/'  + companyId +'/' + jobId  + '/' + this.idItem,model);
  }
}
