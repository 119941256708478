import { TrucksService } from '../_services/trucks/trucks.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { PersonPendingService } from '../_services/PendingPersons/personPending.service';

@Injectable()

export class getAllMangerUsersResolver implements Resolve<any[]>
{
  constructor(private _personPendingService: PersonPendingService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    return this._personPendingService.getAllMangers(user.companyId).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
