<section class="customer-profile">
    <div class="card">
        <div class="card-title">
            <i class="fas fa-arrow-left fa-1x" *ngIf="role == 'crew' || role =='teamleader' " [routerLink]="['/user/customers-list']"></i>
            <i class="fas fa-arrow-left fa-1x" *ngIf="role == 'admin' || role == 'manager'" [routerLink]="['/admin/customers-list']"></i>
            <h6 *ngIf="!isUpdated">New Customer </h6>
            <h6 *ngIf="isUpdated">Customer Details </h6>
        </div>
        <form #customerForm="ngForm">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="firstName"> First Name <span>* </span>:</label>
                        <input type="firstName" id="firstName" class="form-control" required name="firstName" [(ngModel)]="customerModel.firstName" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="lastName"> Last Name <span>* </span>:</label>
                        <input type="text" id="lastName" class="form-control" required name="lastName" [(ngModel)]="customerModel.lastName" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="email"> Email <span>* </span>:</label>
                        <div>
                            <input id="offEmail" type="email" [readonly]="customerModel.isCustomerCarWash || customerModel.isInvited" (keyup)="isEmailExist()" [ngClass]="{'is-invalid' : offEmail.errors && offEmail.touched}" class="form-control" name="email" [(ngModel)]="customerModel.email"
                                required [pattern]="emailPattern" #offEmail="ngModel" />
                            <div *ngIf="offEmail.errors && offEmail.touched" class="alert alert-danger">
                                <div *ngIf="offEmail.errors.required">
                                    Official Email required.
                                </div>
                                <div *ngIf="offEmail.errors.pattern">
                                    Official Email not valid.
                                </div>
                            </div>
                            <div *ngIf="isExists" class="alert alert-danger">
                                Customer E-mail exists please enter another Email.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="customPersonType"> Customer Type :</label>
                        <input type="text" id="customPersonType" class="form-control" name="customPersonType" [(ngModel)]="customerModel.customPersonType" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="phone"> Phone :</label>
                        <input type="text" id="phone" class="form-control" [ngClass]="{'is-invalid' : phone.errors && phone.touched}" name="phone" [(ngModel)]="customerModel.phone" [pattern]="numberPattern" #phone='ngModel' />
                        <div *ngIf="phone.errors && phone.touched" class="alert alert-danger">
                            <div *ngIf="phone.errors.required">
                                Phone required.
                            </div>
                            <div *ngIf="phone.errors.pattern">
                                Phone not valid must only number.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="adddraddressOneessOne"> Address 1 :</label>
                        <input type="text" id="adddressOne" #addressText class="form-control" name="addressOne" [(ngModel)]="customerModel.addressOne" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="addressTow"> Address 2 :</label>
                        <input type="text" id="addressTow" class="form-control" name="addressTow" [(ngModel)]="customerModel.addressTow" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="city"> City :</label>
                        <input type="text" id="city" class="form-control" name="city" [(ngModel)]="customerModel.city" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="state"> State :</label>
                        <input type="text" id="state" class="form-control" name="state" [(ngModel)]="customerModel.state" />
                    </div>
                </div>
                <div class="col-lg-6  col-sm-12">
                    <div class="form-group">
                        <label for="zipCode"> Zip Code :</label>
                        <input type="text" id="zipCode" [ngClass]="{'is-invalid' : zipCode.errors && zipCode.touched}" class="form-control" name="zip" [(ngModel)]="customerModel.zip" [pattern]="numberPattern" #zipCode="ngModel" />
                        <div *ngIf="zipCode.errors && zipCode.touched" class="alert alert-danger">
                            <div *ngIf="zipCode.errors.required">
                                Zip Code required.
                            </div>
                            <div *ngIf="zipCode.errors.pattern">
                                Zip Code not valid must only number.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="country"> Country :</label>
                        <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                        </mat-select-country>
                    </div>
                </div>
                <div class="button-group">
                    <button *ngIf="!isUpdated" class="btn" [disabled]="customerForm.invalid || isExists" color="success" (click)="addPerson()"><i class="far fa-save"></i><span>Add Customer</span></button>
                    <button *ngIf="isUpdated" class="btn" [disabled]="customerForm.invalid || isExists" color="success" (click)="updateCustomer()"><i class="far fa-save"></i><span>Update Customer</span></button>
                </div>
            </div>
        </form>
    </div>
</section>
