<div class="AssignedUsers section-tool-job" *ngIf="userPermission.value != statusActive.InActive || role == 'admin'">
    <div class="tittle-section">
        <p>
            <!-- <i class="fas fa-users pr-1"></i> -->
            <i class="uil uil-users-alt uil-medium pr-1"></i>
            <!-- <img src="./assets/img/icons/crm_icons/assigned_customers.svg" class="pr-1" alt="assigned_customers"> -->
            <span> Assigned Customer(s)</span>
        </p>
        <button class="btn btn-success" (click)="invite()" *ngIf="userPermission.value == statusActive.Active && role !='customer' || role == 'admin' ">
      <i class="fas fa-paper-plane mr-1"></i>Invite
    </button>
    </div>
    <div class="inner-section">
        <div class="ant-empty-image" *ngIf="customers.length <= 0 ">
            <img src="./assets/img/empty.svg" alt="empty-img">
            <span>No customer add yet</span>
        </div>
        <div class="card">
            <!-- Customers Assigned -->
            <mat-accordion class="example-headers-align " *ngIf="customers?.length >  0 ">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Company Customers
                            <div class="title-info">
                                <span>({{assignedCustomers?.length}}) Customer(s)</span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-tab-group [disableRipple]="false">
                        <!-- one tab -->
                        <mat-tab label="UnAssigned ({{customers?.length - assignedCustomers?.length}} )">
                            <div class="form-group">
                                <input type="email" class="form-control border-serach" id="searchUnAssignedText" aria-describedby="searchUnAssignedText" (keydown)="resetPaginationCustomer($event)" [(ngModel)]="searchUnAssignedText" placeholder="Search Name" autofocus>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Customer Type</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of unAssignedCustomerArr | appFilter: searchUnAssignedText | paginate: {id:'unAssignedCust', itemsPerPage: 3, currentPage: p2 }">
                                        <td *ngIf="checkActive(item) === false ">{{item.lastName}}, {{item.firstName}}
                                            <span class="badge badge-danger" *ngIf="item.disable == true && item.id != creationUserId">disable</span>
                                            <span class="badge badge-primary" *ngIf="item.disable == false && item.id == creationUserId">creator </span>
                                        </td>
                                        <td *ngIf="checkActive(item) === false ">{{item.customPersonType}}</td>
                                        <td *ngIf="checkActive(item) === false ">
                                            <span *ngIf="checkActive(item) === false">UnAssigned</span>
                                            <span *ngIf="userPermission.value == statusActive.Active || role == 'admin'">
                                            <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == false " class="pl-2 " (click)="storeItem(item,true)"></mat-slide-toggle>
                                            <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == true " class="pl-2 " [disabled]="item.disable"></mat-slide-toggle>
                                        </span>
                                            <span *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' ">
                                            <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == false " class="pl-2 " [disabled]="true"></mat-slide-toggle>
                                            <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == true " class="pl-2 " [disabled]="item.disable"></mat-slide-toggle>
                                        </span>
                                        </td>
                                        <!-- <td>
                                        <span *ngIf="checkActive(item) === false && userPermission.value == statusActive.Read_Only && role != 'admin' ">UnAssigned</span>
                                        <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == false " class="pl-2 " (click)="storeItem(item,true)"></mat-slide-toggle>
                                        <mat-slide-toggle *ngIf="checkActive(item) === false && item.disable == true  " class="pl-2 " [disabled]="item.disable"></mat-slide-toggle>
                                    </td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-footer text-center " *ngIf="customers.length > 0">
                                <pagination-controls id="unAssignedCust" (pageChange)="p2 = $event "></pagination-controls>
                            </div>
                        </mat-tab>
                        <mat-tab label="Assigned ({{assignedCustomers?.length}})">
                            <div class="form-group">
                                <input type="email" class="form-control border-serach" id="search-text" aria-describedby="search-text" (keydown)="resetPaginationUnAssignedCustomer($event)" [(ngModel)]="searchText" placeholder="Search Name" autofocus>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item1 of assignedCustomerArr | appFilter: searchText | paginate: {id:'assCust', itemsPerPage: 3, currentPage: p  } ">
                                        <td *ngIf="checkActive(item1) === true ">{{item1.lastName}}, {{item1.firstName}}
                                            <span class="badge badge-danger" *ngIf="item1.disable == true && item1.id != creationUserId">disable</span>
                                            <span class="badge badge-primary" *ngIf="item1.disable == false && item1.id == creationUserId">creator </span>
                                        </td>
                                        <td *ngIf="checkActive(item1) === true ">{{item1.role}}</td>
                                        <td *ngIf="checkActive(item1) === true ">
                                            <span *ngIf="checkActive(item1) === true ">Assigned</span>
                                            <span *ngIf="userPermission.value == statusActive.Active || role == 'admin'">
                                        <mat-slide-toggle *ngIf="checkActive(item1) === true && item1.disable == false " class="mat-checked pl-2 " (click)="storeItem(item1,false)"></mat-slide-toggle>
                                        <mat-slide-toggle *ngIf="checkActive(item1) === true && item1.disable == true " class="mat-checked pl-2 " [disabled]="item1.disable"></mat-slide-toggle>
                                      </span>
                                            <span *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' ">
                                        <mat-slide-toggle *ngIf="checkActive(item1) === true && item1.disable == false " class="mat-checked pl-2 " [disabled]="true" ></mat-slide-toggle>
                                        <mat-slide-toggle *ngIf="checkActive(item1) === true && item1.disable == true " class="mat-checked pl-2 " [disabled]="item1.disable"></mat-slide-toggle>
                                      </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-footer text-center " *ngIf="assignedCustomerArr.length > 0">
                                <pagination-controls id="assCust" (pageChange)="p = $event "></pagination-controls>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<!-- Confirm assigned -->
<div mdbModal id="assignedCustomerModal" #assignedCustomerModal="mdbModal" class="modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();assignedCustomerModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary" *ngIf="checkActive(bufferItem) === false">Assign Customer? </p>
                    <p class="text-secondary" *ngIf="checkActive(bufferItem) === true">UnAssign Customer? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="assignedCustomerModal.hide() "> Cancel </button>
                    <button type="button " class="btn btn-sm btn-success " (click)="assignedFun(bufferItem);assignedCustomerModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm assigned Send -->
<div mdbModal id="sendEmailassignedCustomerModal" #sendEmailassignedCustomerModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();sendEmailassignedCustomerModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();assignedFun(bufferItem);sendEmailassignedCustomerModal.hide() "> No </button>
                    <button type="button " class="btn btn-sm btn-success " (click)="assignedFun(bufferItem);sendEmailassignedCustomerModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
