<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#4285f4" type="ball-atom" [fullScreen]="true">
    <p style="color: white"> Please wait... </p>
</ngx-spinner> -->
<div class="card">
    <div class="tittle-section">
        <p> <strong class="pr-1">Members </strong> <small><i> (Employees & 3rd Parties)</i></small></p>
        <div class=" btn-add-new" *ngIf="userObj.roleStatus == 'active'|| role == 'admin' " (click)="inviteUser()">
            <span class="badge badge-success">
                <i class="fas fa-paper-plane mr-1"></i> Invite a New Member
             </span>
        </div>
    </div>
    <mat-tab-group [disableRipple]="false">
        <mat-tab label="Members ( {{users?.length}})">
            <div class="row" *ngIf="users.length > 0">
                <div class="col-12 ">
                    <div class="form-group">
                        <input type="text" id="search-text" class="form-control border-serach" aria-describedby="search-text" [(ngModel)]="searchText" (keydown)="restPagination($event)" placeholder="Search Member Name" />
                    </div>
                </div>
                <div class="col-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Jobs</th>
                                <th scope="col">Date</th>
                                <th scope="col">Role</th>
                                <!-- <th scope="col">Invite</th> -->
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of users | appFilter: searchText | paginate: { id: 'first',itemsPerPage: 5, currentPage: p }; let i=index ">
                            <tr>
                                <th scope="row" class="name text-blue">
                                    <span class="badge badge-light" *ngIf="userObj.roleStatus == 'active'|| role == 'admin' " (click)="routeCustomerDetails(item.id)">{{item.lastName}}, {{item.firstName}}</span>
                                    <span class="badge badge-light" *ngIf="userObj.roleStatus == 'readOnly' ">{{item.lastName}}, {{item.firstName}}</span>
                                </th>
                                <td (click)="getAllJobsByUser(item.id)">
                                    <label class="mx-0"><span class="all-job badge badge-light text-blue">
                                      <small *ngIf="item.totalJobs">{{item.totalJobs}}</small>
                                      <small *ngIf="!item.totalJobs">0</small>
                                     </span>
                                    </label>
                                </td>
                                <td *ngIf="countryCode === true">{{item.date | customDatePipe}}</td>
                                <td *ngIf="countryCode === false">{{item.date | customDatePipe}}</td>
                                <td>
                                    <span *ngIf="userObj.roleStatus == 'readOnly' ">{{item.role}}</span>
                                    <select id="role" class="browser-default custom-select" *ngIf="userObj.roleStatus == 'active'|| role == 'admin' " (change)="disableUserConfirm($event.target.value,item.id);changeRoleModal.show()">
                                        <option class="role-view" selected  disabled *ngIf="item.disable == false">{{item.role}}</option>
                                        <option selected disabled *ngIf="item.disable == true">Disable</option>
                                        <option class="role-view" *ngFor="let roleUser of roleUsers" [value]="roleUser.value">{{roleUser.key}}</option>
                                        <!-- <option value="manager">Manager</option>
                                        <option value="teamleader">Team Leader</option>
                                        <option value="crew">Crew</option>
                                        <option value="3rdparty">3Rd Party</option> -->
                                        <!-- <option value="customer">Customer</option> -->
                                    </select>
                                </td>
                                <!-- <td>
                                    <button class="btn btn-sm " (click)="reInvitePerson(item.email)"><i class="fas fa-redo-alt pr-2"></i>Re-Invite</button>
                                </td> -->
                                <!-- <td><button class="btn"><i class="fas fa-redo-alt"></i> Resend</button></td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-footer text-center ">
                        <pagination-controls id="first" (pageChange)="p=$event "></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="ant-empty-image" *ngIf="users.length <= 0">
                <img src="./assets/img/icons/empty.svg" alt="empty-img">
                <span>No Data</span>
            </div>
        </mat-tab>
        <mat-tab label="Pending Invites ( {{usersPending?.length}})">
            <div class="row" *ngIf="usersPending.length > 0">
                <div class="col-md-12 px-0">
                    <div class="form-group">
                        <input type="email" id="search-bending-text" class="form-control border-serach" aria-describedby="search-bending-text" (keydown)="restPaginationPending($event)" [(ngModel)]="searchBendingText" placeholder="Search Invitee Email" />
                    </div>
                </div>
                <div class="col-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <!-- <th scope="col">Jobs</th> -->
                                <th scope="col">Role</th>
                                <th scope="col">Status</th>
                                <th scope="col">Cancel</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of usersPending | filterPending: searchBendingText | paginate: {id: 'second', itemsPerPage: 5, currentPage: p2 }; let i=index ">
                            <tr>
                                <th scope="row" class="name"><span>{{item.email}}</span>
                                </th>
                                <td>{{item.role}}</td>
                                <td>
                                    <button class="btn btn-sm " *ngIf="userObj.roleStatus == 'active' || role == 'admin'" (click)="storeReInvitePersonPending(item.id);reInviteUserModal.show()"><i class="fas fa-redo-alt pr-2"></i>Re-Invite</button>
                                    <button class="btn btn-sm " *ngIf="userObj.roleStatus == 'readOnly'" [disabled]="userObj.roleStatus == 'readOnly'"><i class="fas fa-redo-alt pr-2"></i>Re-Invite</button>
                                </td>
                                <td>
                                    <button class="btn-sm btn-danger" *ngIf="userObj.roleStatus == 'active' || role == 'admin'" (click)="storId(item);deleteModal.show()"><i class="far fa-trash-alt pr-1"></i> Cancel </button>
                                    <button class="btn-sm btn-danger" *ngIf="userObj.roleStatus == 'readOnly'" [disabled]="userObj.roleStatus == 'readOnly'"><i class="far fa-trash-alt pr-1"></i> Cancel </button>
                                </td>
                                <!-- <td><button class="btn"><i class="fas fa-redo-alt"></i> Resend</button></td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-footer text-center ">
                        <pagination-controls id="second" (pageChange)="p2=$event "></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="ant-empty-image" *ngIf="usersPending.length <= 0">
                <img src="./assets/img/icons/empty.svg" alt="empty-img">
                <span>No Data</span>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<!-- Modal to display job details -->
<div mdbModal id="basicModal" #basicModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Jobs Summary</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#Job</th>
                                <th scope="col" *ngIf="user.categoryCompany == categoryCompany.Inventory">Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let job of jobs | paginate: { id: 'third',itemsPerPage: 3, currentPage: p3 };" (click)="redirection(job)">
                                <th scope="row">
                                    <h6> {{job.jobNumber}}</h6>
                                    <hr>
                                    <h6> {{job.jobType}}</h6>
                                </th>
                                <td *ngIf="user.categoryCompany == categoryCompany.Inventory">
                                    <h6>{{job.jobName}}</h6>
                                    <hr>
                                    <h6> Type Customer</h6>
                                </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Draft' "><img src="/assets/img/icons/draft_new.svg" alt="Draft-img"></td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Deal' "><img src="/assets/img/icons/deal_new.svg" alt="Deal-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Canceled' "><img src="/assets/img/icons/canceled_new.svg" alt="Canceled-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'Done' "><img src="/assets/img/icons/done_new.svg" alt="Done-img"> </td>
                                <td class="img-status" *ngIf="job.jobStatus === 'In Progress' "><img src="/assets/img/icons/in_progress_new.svg" alt="InProcess-img"> </td>
                                <!-- US Date formate -->
                                <td *ngIf="countryCode === true">
                                    <span class="start-window">{{job.jobDetailsDateTime.startDateWindowFrom | customDatePipe}}
                               {{job.jobDetailsDateTime.startTimeWindowFrom }}
                          </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | customDatePipe }}
                             {{job.jobDetailsDateTime.endTimeWindowTo}}
                           </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == false "> Days, Many Dates</span>
                                </td>
                                <!-- Other Country Date formate -->
                                <td *ngIf="countryCode === false">
                                    <span class="start-window">{{job.jobDetailsDateTime.startDateWindowFrom | customDatePipe}}
                             {{job.jobDetailsDateTime.startTimeWindowFrom }}
                        </span> <br>
                                    <span class="end-window"> {{job.jobDetailsDateTime.endDateWindowTo | customDatePipe }}
                           {{job.jobDetailsDateTime.endTimeWindowTo}}
                         </span><br> {{job.jobDetailsDateTime.day}}
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == true "> Day, One Date</span>
                                    <span *ngIf="job.jobDetailsDateTime.isNewSequence == false "> Days, Many Dates</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-footer text-center ">
                        <pagination-controls id="third" (pageChange)="p3=$event "></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reinvite User -->
<div mdbModal id="reInviteUserModal" #reInviteUserModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="reInviteUserModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                    <p>Re-Invite Member?</p>
                    <!-- <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="reInviteUserModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-sm btn-success" (click)="ReInvitePersonPending(bufferId);reInviteUserModal.hide() ">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete user Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="deleteModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="icon-close">
                        <i class="far fa-times-circle fa-3x"></i>
                    </div>
                    <div class="text-center">
                        <p class="tittle">Cancel invitation? </p>
                        <!-- <p class="text-secondary"><small>You can invite him\her anytime late.</small></p> -->
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-sm btn-danger" (click)="cancelPersonPending(userItem);deleteModal.hide() ">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Status  Job -->
<div mdbModal #changeRoleModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="changeRoleModal.hide()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-center">Change Member Role?</p>
                </div>
                <div class="modal-footer">
                    <button type="button " class="btn btn-sm btn-danger" (click)="changeRoleModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-sm btn-success" (click)="disableUser(roleUser,idUser);changeRoleModal.hide() ">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>