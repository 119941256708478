import { Role } from 'src/app/models/role/RoleModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { catchError, map } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {
  baseUrl = environment.baseUrl
  constructor(
    private http: HttpClient
  ) { }

  getAllCompanies() {
    return this.http.get(this.baseUrl + 'companies')
  }
  getCompanyByPlan(plan: number) {
    return this.http.get(this.baseUrl + 'companies?plan=' + plan)
  }
  getCompanyStaticsYear(q: any) {
    return this.http.get(this.baseUrl + 'companiesStatics?q=' + q)
  }
  loginSuperAdmin(model: any) {
    return this.http.post(this.baseUrl + 'SuperUserLogin', model).pipe(
      map((response: ResponseModel) => {
        if (response.success) {
          response.data.role = Role.Super_Admin
          localStorage.setItem('user', JSON.stringify(response.data))
          // this.userSubject.next(response);
        }
        return response
      }
      ), catchError(this.errorHandler)
    )
  }
  getCountCompaniesByDate(dates:any){
    return this.http.post(this.baseUrl + 'companiesCountByDate',dates)
  }
  getCompaniesByDate(date:any){
    return this.http.get(this.baseUrl + 'companiesByDate?q='+date)
  }
  getJobStaticsYear(q: string ,companyId:string) {
    return this.http.get(this.baseUrl + 'jobStatics/' + companyId + '/?q=' + q);
  }
  getCountJobsByTypeInYear(type: any, date: any,comapnyId:string) {
    return this.http.get(this.baseUrl + 'jobByType/'+comapnyId +'?type=' + type + '&q=' + date);
  }
  getJobs(companyId: any): Observable<any> {
    return this.http.get(this.baseUrl + 'jobs/' + companyId);
  }
  getJobById(id: any): Observable<any> {
    return this.http.get(this.baseUrl + 'job/' + id);
  }
  updateCompanyForce(companyId:string , model:any ){
    return this.http.put(this.baseUrl + 'companyForce/' + companyId,model);
  }
  errorHandler(error: Error): Observable<any> {
    return null;
  }
}
