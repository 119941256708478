import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';
import { TrucksService } from '../_services/trucks/trucks.service';
import { ToolsService } from '../_services/tools/tools.service';
import { GlobalService } from '../_services/global/global.service';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';

@Injectable()

export class TrucksResolver implements Resolve<any[]>
{
  jobsTools: any = {}
  truckObj: any = {}
  user: any = {}
  constructor(
    private _trucksService: TrucksService,
    private _toolsService: ToolsService,
    private _globalService: GlobalService,
  ) { }
  resolve(): Observable<any[]> {
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this._globalService.items != undefined && this._globalService.items.success) {
      this.jobsTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
      this.truckObj = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolSettingEditItem.Trucks_list)
      if (this.user.role =='admin' || this.truckObj.itemStatus == "Active" && this.truckObj.roleStatus == "active" || this.truckObj.itemStatus == "Active" && this.truckObj.roleStatus == "readOnly") {
        return this._trucksService.getAllTrucksByCompany(this.user.companyId).pipe(
          catchError(error => {
            console.log(error)
            return of(null);
          })
        );
      }
    }
    else if(this._globalService.items == undefined){
      if (this._globalService.items != undefined && this._globalService.items.success) {
        this.jobsTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
        this.truckObj = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolSettingEditItem.Trucks_list)
        if (this.user.role =='admin' || this.truckObj.itemStatus == "Active" && this.truckObj.roleStatus == "active" || this.truckObj.itemStatus == "Active" && this.truckObj.roleStatus == "readOnly") {
          return this._trucksService.getAllTrucksByCompany(this.user.companyId).pipe(
            catchError(error => {
              console.log(error)
              return of(null);
            })
          );
        }
      }
    }
  }
}
