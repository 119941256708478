// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isOnline: true,
  isCarWash: true,
  Test_Tittle: 'Accordev_Test',
  Online_Tittle: 'Accordev',
  Print_QrCode_stickers: 'https://accordev.com/qrcode-stickers',
  Car_Wash_Stickers: 'https://accordev.com/car-wash-stickers',
  Car_Wash_Plans: 'https://accordev.com/car_wash_pricing',
  Inventory_Plans: 'https://accordev.com/inventory-pricing',
  logo_online: './assets/img/logo/logo1.png',
  logo_test: './assets/img/logo/New_Logo.svg',
  baseDirectionGoogle: 'https://www.google.com/maps/dir/?api=1&origin=',
  baseWordpressUrl: 'https://accordev.com',
  Api_vehicle: "https://api.platerecognizer.com/v1/plate-reader",
  token_platerecognizer: '95b11f0c9ec1a1d6119d742f58c7a8c6f95fd46a',
  google_jump_location: 'https://www.google.com/maps/place/',
  // Live
  baseUrl: "https://accordev.herokuapp.com/",
  baseDomain: "https://app.accordev.com/",
  // Test
  // baseUrl:"https://accordev-test.herokuapp.com/",
  // baseDomain:"https://accordev-test.web.app/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
