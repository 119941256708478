import { ToasterCustomService } from './../../../../../core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ModalDirective } from 'angular-bootstrap-md';
import { PaymentType } from '../Enum/PaymentType';
@Component({
  selector: 'app-assigned-vehicle-qr',
  templateUrl: './assigned-vehicle-qr.component.html',
  styleUrls: ['./assigned-vehicle-qr.component.scss']
})
export class AssignedVehicleQrComponent implements OnInit {
  @ViewChild('warningKeyModal') warningKeyModal: ModalDirective;
  @ViewChild('warningCashModal') warningCashModal: ModalDirective;
  addVehicleFormGroup: FormGroup;
  emailPattern = ValidationModel.emailPattern
  isLinear = false;
  qrCodeId:string =''
  vehicleId:string =''
  vehicle:any={}
  user:any = {}
  customer:any ={}
  isHaveSecretKey = false
  paymentType = PaymentType.card
  paymentTypeEnum = PaymentType
  customerEmailCash = ''
  bufferVehicleId =''
  countMonth :any = 'forever'
  monthsCash = ['1','2','3','4','5','6','7','8','9','10','11','12']
  monthsCard = ['forever','1','2','3','4','5','6','7','8','9','10','11','12']
  isEmailCashExists = false
  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _carWashService:CarWashService,
    private _toaster:ToasterCustomService,
    private router:Router,
    ) { }
  ngOnInit() {
    this.storePaymentType(this.paymentType)
    localStorage.setItem('countMonth','forever')
    this.createAddVehicleForm()
    this.qrCodeId = this.route.snapshot.paramMap.get('id');
    this.vehicleId = localStorage.getItem('vehicleId')
    console.log( this.vehicleId)
    this.user = JSON.parse(localStorage.getItem('user'))
    // this._carWashService.getVehicleById(this.vehicleId).subscribe((response:ResponseModel)=>{
    //   if(response.success){
    //     if(response.data.customer != null){
    //      this.setValueVehicle(response.data)
    //     }
    //   }
    // })
    this._carWashService.assignVehicle(this.vehicleId,this.qrCodeId).subscribe((response:ResponseModel)=>{
      console.log('Assigned Car Wash',response)
      if(response.success){
        this.vehicle = response.data
        this.setValueVehicle(response.data)
        this.getActiveStep()
        if(this.vehicle.customer != null){
          if(response.data.paymentType == this.paymentTypeEnum.card){
            this.storePaymentType(response.data.paymentType)
          }
          else{
            this.storePaymentType(response.data.paymentType)
            localStorage.setItem('customerId',this.vehicle.customer.id)
          }
        }
        this._toaster.success('It is optional to do the next step','QR Code Successfully Assigned')
      }
    })
    // this.addVehicleFormGroup.get("qrCode").setValue(this.qrCodeId);
  }
  createAddVehicleForm() {
    this.addVehicleFormGroup = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['',Validators.required],
      vehicleModel: [''],
      // color: [''],
      // vinCar: ['',[Validators.pattern(this.numberPattern)]],
      condition: [''],
      // qrCodeId: ['', Validators.required],
      // firstName: ['',Validators.required],
      // lastName: ['',Validators.required],
      // email: ['',[Validators.required,Validators.pattern(this.emailPattern)]],
    });
  }
  setValueVehicle(vehicle:any)
  {
    console.log('vehicle.customer',vehicle.customer)
    if(vehicle.customer != null){
      console.log('vehicle.customer')
      this.addVehicleFormGroup.setValue({
        vehicleType:vehicle.vehicleType,
        plateNumber:vehicle.plateNumber,
        vehicleModel:vehicle.vehicleModel,
        // color:vehicle.color,
        // vinCar:vehicle.vinCar,
        condition:vehicle.condition,
        // qrCodeId:vehicle.qrCodeId,
        // firstName:vehicle.customer.firstName,
        // lastName:vehicle.customer.lastName,
        // email:vehicle.customer.email,
      })
    }
    else{
      this.addVehicleFormGroup.setValue({
        vehicleType:vehicle.vehicleType,
        plateNumber:vehicle.plateNumber,
        vehicleModel:vehicle.vehicleModel,
        color:vehicle.color,
        condition:vehicle.condition,
        // qrCodeId:vehicle.qrCodeId,
        // firstName:'',
        // lastName:'',
        // email:'',
      })
    }
  }
  get f() {
    return this.addVehicleFormGroup.controls;
  }
  addCustomer(){
    let item = this.addVehicleFormGroup.value
    console.log(item)
    let model = {
      firstName : item.firstName,
      lastName : item.lastName,
      email : item.email,
      companyId :this.user.companyId,
      isInvited : false,
      role: "customer",
      name:item.lastName +', '+item.firstName,
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      country: {
          name: "United States",
          alpha2Code: "US",
          alpha3Code: "US",
        numericCode: "840"
      }
    }
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this._carWashService.addCustomerVehicle(this.vehicleId,model).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.vehicle.customer = response.data
            this.addVehicleFormGroup.setValue({
              firstName:response.data.firstName,
              lastName:response.data.lastName,
              email:response.data.email,
            })
            this._toaster.success('It is optional to do the next step','Customer Information Successfully Added')
          }
          else{
            this._toaster.error('','Customer already exists')
          }
        })
      }
      else{
        this.warningKeyModal.show()
      }
    })
  }
  getActiveStep(){
    if(this.vehicle.qrCodeId == "" && this.vehicle.customer == null || this.vehicle.qrCodeId == "" && this.vehicle.customer != null){
      return 2
    }
    else if(this.vehicle.qrCodeId != "" && this.vehicle.customer == null){
      return 1
    }
    else{
      return 1
    }
  }
  isProductsCompanyById(vehicleId){
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.isHaveSecretKey = false
        if(this.user.role == 'manager' || this.user.role == 'admin'){
          this.router.navigate(['/admin/products-strip',vehicleId])
        }
        else{
          this.router.navigate(['/user/products-strip',vehicleId])
        }
      }
      else{
        this.isHaveSecretKey = true
      }
    })
  }
  // createPortalCustomerStripeCustom() {
  //   this._carWashService.createPortalCustomerStripeCustom(this.vehicleId).subscribe((response: ResponseModel) => {
  //     if (response.success) {
  //       // window.open(response.data)
  //       window.location.href = response.data
  //     }
  //   })
  // }
  createMemberShip(vehicleId){
    let item = this.addVehicleFormGroup.value
    let model:any = {
      firstName : '',
      lastName : '',
      email : item.email,
      companyId :this.user.companyId,
      isInvited : false,
      role: "customer",
      name:'',
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      country: {
          name: "United States",
          alpha2Code: "US",
          alpha3Code: "US",
        numericCode: "840"
      }
    }
    if(this.paymentType == this.paymentTypeEnum.cash || this.paymentType == this.paymentTypeEnum.card_exists){
      model.isCustomerCash = true
    }
    else{
      model.isCustomerCash = false
    }
    if(this.paymentType == this.paymentTypeEnum.card_exists){
      model.email = this.getEmailWithoutSpace(this.customerEmailCash)
    }
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        if(this.vehicle.customer){
          if(this.user.role == 'manager' || this.user.role == 'admin'){
            this.router.navigate(['/admin/products-strip',vehicleId])
          }
          else{
            this.router.navigate(['/user/products-strip',vehicleId])
          }
        }
        else{
          this._carWashService.addCustomerVehicle(this.vehicle.id,model).subscribe((response:ResponseModel)=>{
            if(response.success){
              this.customer = response.data
              this.isHaveSecretKey = false
              if(this.user.role == 'manager' || this.user.role == 'admin'){
                this.router.navigate(['/admin/products-strip',vehicleId])
              }
              else{
                this.router.navigate(['/user/products-strip',vehicleId])
              }
              // this._toaster.success('It is optional to do the next step','Customer Information Successfully Added')
              }
            else{
              this._toaster.error('','Customer already exists')
            }
          })
        }

      }
      else{
        this.warningKeyModal.show()
      }
    })
  }
  handleChange(event){
    if(event.target.defaultValue == "Card"){
      this.paymentType = this.paymentTypeEnum.card
      this.storePaymentType(this.paymentType)
      localStorage.setItem('countMonth','forever')
      this.isEmailCashExists = false
      // localStorage.setItem('emailCard',this.vehicle.customer.email)
    }
    else{
      this.getCustomerCash()
      // this.storePaymentType(this.paymentType)
      localStorage.setItem('countMonth','1')
    }
  }
  getCustomerCash(){
    this._carWashService.getCustomerCash(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success && response.data != null){
        console.log(response.data)
        this.paymentType =  this.paymentTypeEnum.cash
        localStorage.setItem('customerId',response.data.id)
        // localStorage.setItem('emailCash',response.data.email)
        this.storePaymentType(this.paymentTypeEnum.cash)
        this.isEmailCashExists = false
      }
      else{
        // console.log(response.data)
        // this.paymentType = this.paymentTypeEnum.card_exists
        this.warningCashModal.show()
        this.storePaymentType(this.paymentTypeEnum.cash)
        this.isEmailCashExists = true
      }
    })
  }
  storePaymentType(paymentType){
    localStorage.setItem('paymentType',paymentType)
  }
  createMemberShipCash(vehicleId){
    if(this.user.role == 'manager' || this.user.role == 'admin'){
      this.router.navigate(['/admin/products-strip',vehicleId])
    }
    else{
      this.router.navigate(['/user/products-strip',vehicleId])
    }
  }
  chooseCountMonth(event){
    localStorage.setItem('countMonth',event)
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  renewMembership(){
    localStorage.setItem('renewMembership','true')
  }
  storeVehicleId(vehicleId){
    console.log(vehicleId)
    this.bufferVehicleId = vehicleId
  }
  }
