import { ActivatedRoute } from '@angular/router';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { SuppliesService } from './../../../../../core/_services/supplies/supplies.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Supply } from 'src/app/models/supply/Supply';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent implements OnInit {
  supplyModel = {} as Supply
  supplies: Supply[] = []
  uniqId
  jobId:any
  constructor(
    private spinner: NgxSpinnerService,
    private _suppliesService: SuppliesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // get  all Supplies
    this.route.data.subscribe((response: ResponseModel) => {
      if(response['supplies'].success){
        this.supplies = response['supplies'].data
      }
    }, error => {
      console.log(error)
    })
    this.jobId =this.route.snapshot.paramMap.get('id');
  }
  isEditable(item) {
    this.uniqId = item.id
  }
  addSupply(supplyForm: NgForm) {
    let item = supplyForm.value
    item.jobId = this.jobId
    this.spinner.show()
    setTimeout(() => {
      this._suppliesService.addSupply(item).subscribe((response: ResponseModel) => {
        this.supplies.push(response.data)
        this.spinner.hide();
      }, error => {
        console.log(error)
        this.spinner.hide();
      });
    }, 2000);
    supplyForm.reset()
  }
  deleteSupply() {
    this.spinner.show()
    setTimeout(() => {
      this.supplies.splice(this.supplies.findIndex(p => p.id == this.uniqId), 1);
      this.spinner.hide();
    }, 2000);
  }
}
