import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FrontSpaceService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  getAllFrontSpaceByCompany(companyId: string) {
    return this.http.get(this.baseUrl + 'AllFrontSpace/' + companyId);
  }

  getAllInventoryItemsByFrontSpace(id: string) {
    return this.http.get(this.baseUrl + 'FrontSpaceItems/' + id);
  }
}
