import { GlobalService } from 'src/app/core/_services/global/global.service';
import { Socket } from 'ngx-socket-io';
import { Browser } from '@capacitor/browser';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { DatePipe } from '@angular/common';
// import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { ModalDirective } from 'angular-bootstrap-md';
import { PaymentType } from '../Enum/PaymentType';
import { Capacitor } from '@capacitor/core';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseConfig } from 'ngx-scanner-qrcode';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { CompanyPlan } from 'src/app/models/company/company';
import { WebcamInitError } from 'ngx-webcam';
import { environment } from 'src/environments/environment.prod';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { PersonService } from 'src/app/core/_services/person/person.service';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVehicleComponent implements OnInit, OnDestroy {
  @ViewChild('warningKeyModal') warningKeyModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
  @ViewChild('warningCashModal') warningCashModal: ModalDirective;
  @ViewChild('warningKeyStripModal') warningKeyStripModal: ModalDirective;
  @ViewChild('scanWebModal') scanWebModal: ModalDirective;
  @ViewChild('cameraScanModal') cameraScanModal: ModalDirective;
  @ViewChild('summaryCashModal') summaryCashModal: ModalDirective;
  addVehicleFormGroup: FormGroup;
  numberPattern = ValidationModel.numberPattern
  emailPattern = ValidationModel.emailPattern
  numCharPattern = ValidationModel.complexPattern
  isLinear = false;
  // qrCodeId:string =''
  vehicle: any = {}
  user: any = {}
  customer: any = {}
  isHaveSecretKey = false
  paymentType = PaymentType.card
  paymentTypeEnum = PaymentType
  customerEmailCash = ''
  bufferVehicleId = ''
  countMonth: any = 'forever'
  monthsCash = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  monthsCard = ['forever', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  isEmailCashExists = false
  isExists = false
  isAnotherVehicle = false
  timer: any
  historyCarWash = HistoryCarWash
  carsList: any = []
  countPrintBranches = ''
  adminId = ''
  products = []
  packageProducts = []
  buffProduct: any = {}
  buffProductPackaging: any = {}
  // scan qr code variable
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;
  public config: BaseConfig = {
    text: { font: '25px serif' },
    frame: { lineWidth: 4 },
  };
  @ViewChild('action') action: any;
  companyPlan = CompanyPlan
  startDate: any = new Date()
  endDate: any = new Date()
  diffInDays = 0
  date = moment();
  //  Variables to open camera
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: any = null;
  public showWebcam = false;
  public errors: WebcamInitError[] = [];
  token_platerecognizer = environment.token_platerecognizer
  Api_vehicle = environment.Api_vehicle
  isMobile = false
  isDateCheck = true
  isBack = true
  isBack1 = true
  daysInMonth = 30
  totalDue = 0
  buffProductPrice = 0
  receivedAmount = 0
  cashBackAmount = 0
  // for packaging products
  pricePackageProduct = 0
  receivedAmountPackageProduct = 0
  cashBackPackageProduct = 0
  selected: any = {}
  selectedID
  selectedPackageIndex
  // this is code country variables
  // phoneForm = new FormGroup({
  // 	phone: new FormControl(undefined, [Validators.required])
  // });
  countrySelectedArr:any = []
  countrySelected:any = {}
  isDetailing :any = false
  jobModel : any ={}
  customerEmail = ''
  isErrorEmailCustomer = false
  isExistsCustomer  = false
  // currentCountry:any =
  constructor(
    private _formBuilder: FormBuilder,
    private _toaster: ToasterCustomService,
    private _carWashService: CarWashService,
    private router: Router,
    public datePipe: DatePipe,
    public http: HttpClient,
    private _companiesService: CompaniesService,
    private socket: Socket,
    private deviceService: DeviceDetectorService,
    private _globalService : GlobalService,
    private _personService : PersonService
  ) { }
  ngOnInit() {
    // this.filteredOptions = this.addVehicleFormGroup.get('vehicleType').valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );
    // document.getElementById('cars_List').style.display = "none";
    this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
    let isDetailing = localStorage.getItem('isDetailing')
    if(isDetailing != undefined && isDetailing != null){
      this.isDetailing = isDetailing == 'false' ? false  : true;
    }
    (Capacitor.isNativePlatform() || !this.deviceService.isDesktop()) ? this.isMobile = true : this.isMobile = false
    this.getData()
    localStorage.removeItem('customerId')
    this.storePaymentType(this.paymentType)
    localStorage.setItem('countMonth', 'forever')
    this.user = JSON.parse(localStorage.getItem('user'))
    this.countrySelectedArr.unshift(localStorage.getItem('country').toLowerCase())
    // console.log(this.countrySelected)
    // console.log('this.currentCountry',this.currentCountry)
    this.createAddVehicleForm()
    this.getCompanyCountPrint()
    // this.filteredOptions = this.addVehicleFormGroup.get('vehicleType').valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );
    // console.log(this.addVehicleFormGroup.get('vehicleType'))
    this.filteredOptions = this.addVehicleFormGroup.get('vehicleType').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.socket.on(RealTimeKeys.Vehicle_Update, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.vehicle = response.data
          }
        })
      }
    });
  }
  getCompanyCountPrint() {
    this._companiesService.getCompanyCountPrint(this.user.companyId).subscribe((response: ResponseModel) => {
      // console.log(response)
      if (response.success) {
        this.countPrintBranches = response.data.countPrintBranches
        this.adminId = response.data.id
      }
    })
  }
  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.carsList.filter(option => option.Make.toLowerCase().indexOf(filterValue) === 0);
    // return this.carsList;
  }
  getData() {
    return this.http.get('/assets/cars_list.json').subscribe((response: any) => {
      // console.log(response)
      this.carsList = response
    });
  }
  // filterCarList(){
  //   this.f.vehicleType.status == "VALID" ? document.getElementById('cars_List').style.display = "block" : document.getElementById('cars_List').style.display = "none";
  // }
  createAddVehicleForm() {
   if(this.isDetailing){
    this.addVehicleFormGroup = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['', [Validators.required,Validators.pattern(this.numCharPattern)]],
      vehicleModel: [''],
      customerEmail: ['',[Validators.required,Validators.pattern(this.emailPattern)]],
      condition: [''],
      phone: [''],
      // firstName: ['',Validators.required],
      // lastName: ['',Validators.required],
      // email: ['',[Validators.required,Validators.pattern(this.emailPattern)]],
      // qrCodeId: [''],
    });
   }
   else{
    this.addVehicleFormGroup = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['', [Validators.required,Validators.pattern(this.numCharPattern)]],
      vehicleModel: [''],
      // color: [''],
      condition: [''],
      phone: [''],
      // firstName: ['',Validators.required],
      // lastName: ['',Validators.required],
      // email: ['',[Validators.required,Validators.pattern(this.emailPattern)]],
      // qrCodeId: [''],
    });
   }
  }
  get f() {
    return this.addVehicleFormGroup.controls;
  }
  chooseCar(car: any) {
    this.addVehicleFormGroup.patchValue({
      vehicleType: car.Make,
      vehicleModel: car.Model,
    })
  }
  saveCustomerEmail(event){
    console.log(event)
    let email = String(event).toLowerCase().match(this.emailPattern);
    this.customerEmail = event ;
     (email == null || email == null) ?  this.isErrorEmailCustomer  = true :  this.isErrorEmailCustomer  = false
     clearTimeout(this.timer)
     if(email){
      this.timer  = setTimeout(()=>{
        let model = {
          email: this.getEmailWithoutSpace(this.customerEmail ),
          companyId: this.user.companyId
        }
         this._personService.isEmailInPersonAndInPendingExists(model).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExistsCustomer = false
          }
          else {
            this.isExistsCustomer = true
          }
        })
      },2000)
     }
  }
  addVehicle() {
    console.log(this.addVehicleFormGroup.value)
    let item = this.addVehicleFormGroup.value
    let todayDate = new Date()
    this.datePipe.transform(todayDate, 'shortTime')
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model:any = {
      vehicleType: item.vehicleType,
      plateNumber: item.plateNumber.toUpperCase().replace(/\s/g,''),
      vehicleModel: item.vehicleModel,
      // color : item.color,
      condition: item.condition,
      phone:`${item.phone}`,
      qrCodeNumber: 0,
      // qrCodeId :'',
      date: (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      creationTime: this.datePipe.transform(todayDate, 'H:mm'),
      createdDate: new Date(),
      companyId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      isDetailing : this.isDetailing,
      histories: [{
        id: 1,
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        dateHistory: new Date(),
        timeHistory: this.datePipe.transform(todayDate, 'H:mm'),
        key: this.historyCarWash.Add_Vehicle,
      }]
    }
    if(this.isDetailing){
      model.customerObj =  {
        firstName : '',
        lastName : '',
        email: item.customerEmail.trim().toLocaleLowerCase(),
        companyId: this.user.companyId,
        isInvited : false,
        role: "customer",
        name:'',
        type: "customer",
        status: "Unassigned",
        disable: false,
        isCanceled: false,
        isCustomerCash :false,
        isCustomerCarWash :true,
        date: new Date(),
        dateFormate :  this.datePipe.transform(new Date(), 'yyyy-d-M'),
        timeFormate :  this.datePipe.transform(new Date(), 'h:mm a'),
        country: {
            name: "United States",
            alpha2Code: "US",
            alpha3Code: "US",
          numericCode: "840"
        }
      }
    }
    console.log(model)
    let buff = localStorage.getItem('customerExists')
    console.log(buff)
    if (!buff) {
      if(this.isDetailing){
        this._carWashService.addVehicleJob(model,this.jobModel.jobId).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.vehicle = response.data
            localStorage.setItem('vehicleId', this.vehicle.id)
            this._toaster.success('It is optional to do the next step', 'Car Profile Successfully Added')
          }
        })
      }
      else{
        this._carWashService.addVehicle(model).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.vehicle = response.data
            localStorage.setItem('vehicleId', this.vehicle.id)
            this._toaster.success('It is optional to do the next step', 'Car Profile Successfully Added')
          }
        })
      }

    }
    else {
      this._carWashService.addVehicleGlobal(model, buff).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.vehicle = response.data
          localStorage.setItem('vehicleId', this.vehicle.id)
          this._toaster.success('It is optional to do the next step', 'Car Profile Successfully Added')
        }
      })
    }

  }
  assignQR() {
    this.handle(this.action, 'start')
    // if(Capacitor.isNativePlatform()) {
    //   this.printScanMobile.show()
    // } else {
    // localStorage.setItem('isAssignedQR','true')
    // this._carWashService.assignQR()
    // }

  }
  addCustomer() {
    let item = this.addVehicleFormGroup.value
    let model = {
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      companyId: this.user.companyId,
      isInvited: false,
      role: "customer",
      name: item.lastName + ', ' + item.firstName,
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      country: {
        name: "United States",
        alpha2Code: "US",
        alpha3Code: "US",
        numericCode: "840"
      }
    }
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._carWashService.addCustomerVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.customer = response.data
            this._toaster.success('It is optional to do the next step', 'Customer Information Successfully Added')
          }
          else {
            this._toaster.error('', 'Customer already exists')
          }
        })
      }
      else {
        this.warningKeyModal.show()
      }
    })
  }
  isPlateNumberExist() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (this.f.plateNumber.status == "VALID") {
        this._carWashService.isPlateNumberExist(this.user.companyId, this.f.plateNumber.value.toUpperCase()).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExists = false
            // this.isAnotherVehicle = false
          }
          else {
            this.isExists = true
            // this.isAnotherVehicle = true
          }
        })
      }
      else {
        this.isExists = false
        return null
      }
    }, 500)
  }
  checkAddAnotherVehicle(event) {
    if (event.target.checked) {
      this.isExists = false
    }
    else {
      this.isExists = true
    }
  }
  createMemberShip(vehicleId) {
    let item = this.addVehicleFormGroup.value
    let model: any = {
      firstName: '',
      lastName: '',
      email: item.email,
      companyId: this.user.companyId,
      isInvited: false,
      role: "customer",
      name: '',
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      country: {
        name: "United States",
        alpha2Code: "US",
        alpha3Code: "US",
        numericCode: "840"
      }
    }
    if (this.paymentType == this.paymentTypeEnum.cash || this.paymentType == this.paymentTypeEnum.card_exists) {
      model.isCustomerCash = true
    }
    else {
      model.isCustomerCash = false
    }
    if (this.paymentType == this.paymentTypeEnum.card_exists) {
      model.email = this.getEmailWithoutSpace(this.customerEmailCash)
    }
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._carWashService.addCustomerVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.customer = response.data
            this.isHaveSecretKey = false
            if (this.user.role == 'manager' || this.user.role == 'admin') {
              this.router.navigate(['/admin/products-strip', vehicleId])
            }
            else {
              this.router.navigate(['/user/products-strip', vehicleId])
            }
          }
          else {
            this._toaster.error('', 'Customer already exists')
          }
        })
      }
      else {
        this.warningKeyModal.show()
      }
    })
  }
  createMemberShipCash(vehicleId) {
    let buff = localStorage.getItem('customerExists')
    buff ? localStorage.setItem('renewMembership', 'true') : ''
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.isHaveSecretKey = false
        if (this.user.role == 'manager' || this.user.role == 'admin') {
          this.router.navigate(['/admin/products-strip', vehicleId])
        }
        else {
          this.router.navigate(['/user/products-strip', vehicleId])
        }
      }
      else {
        this.isHaveSecretKey = true
        this.warningKeyStripModal.show()
      }
    })
  }
  isProductsCompanyById(vehicleId) {
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.isHaveSecretKey = false
        if (this.user.role == 'manager' || this.user.role == 'admin') {
          this.router.navigate(['/admin/products-strip', vehicleId])
        }
        else {
          this.router.navigate(['/user/products-strip', vehicleId])
        }
      }
      else {
        this.isHaveSecretKey = true
      }
    })
  }
  //   async startScan (){
  //     let res = await BarcodeScanner.startScan()
  //     console.log('res scanner ',res)
  // }
  handleChange(event) {
    if (event.target.defaultValue == "Card") {
      this.paymentType = this.paymentTypeEnum.card
      this.storePaymentType(this.paymentType)
      localStorage.setItem('countMonth', 'forever')
      // localStorage.removeItem('customerId')
      this.isEmailCashExists = false
    }
    else {
      this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.isHaveSecretKey = false
          this.paymentType = this.paymentTypeEnum.cash
          this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
            this.products = response.data.filter(p => p.isPackage == false)
            this.packageProducts = response.data.filter(p => p.isPackage == true)
          })
          localStorage.setItem('countMonth', '1')
        }
        else {
          this.isHaveSecretKey = true
          this.warningKeyStripModal.show()
        }
      })
    }
  }
  getPrice(price) {
    return price / 100
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = moment(event.value);
    if (moment(event.value) >= moment(this.endDate)) {
      this.isDateCheck = true
      this.diffInDays = 0
    }
    else {
      this.isDateCheck = false
      this.startDate = moment(event.value);
      // this.endDate  ?  this.endDate : new Date()
      this.endDate = moment(this.endDate);
      this.diffInDays = Math.abs(this.startDate.diff(this.endDate, 'days'));
      this.getTotalDuePrice()
      console.log(this.diffInDays)
    }
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(moment(event.value))
    console.log(moment(this.startDate))
    this.endDate = moment(event.value)
    if (moment(this.startDate) >= moment(event.value)) {
      this.isDateCheck = true
      this.diffInDays = 0
    }
    else {
      this.isDateCheck = false
      this.endDate = moment(event.value)
      // this.startDate  ?  this.startDate : new Date()
      this.startDate = moment(this.startDate);
      this.diffInDays = Math.abs(this.startDate.diff(this.endDate, 'days'));
      this.getTotalDuePrice()
      console.log(this.diffInDays)
    }
  }
  storeBuffProductPrice(product: any) {
    this.buffProduct = product
    this.buffProductPrice = this.getPrice(product.price)
    this.getTotalDuePrice()
  }
  select(item) {
    this.selected = item;
  }
  isActive(item) {
    return this.selected === item;
  }
  selectItem(i) {
    this.selectedID = i;
  }
  selectItemPackage(i) {
    this.selectedPackageIndex = i;
  }
  storeBuffProductPackagingPrice(product: any) {
    this.buffProductPackaging = product
    this.pricePackageProduct = this.getPrice(product.price)
    this.cashBackPackageProduct = Math.round(this.receivedAmountPackageProduct - this.pricePackageProduct)
    if (this.cashBackPackageProduct < 0) {
      this.isBack1 = true
    }
    else {
      this.isBack1 = false
    }
  }
  getTotalDuePrice() {
    this.totalDue = Math.round((this.buffProductPrice / this.daysInMonth) * this.diffInDays)
    this.getCashBack()
    return this.totalDue;
  }
  accountReceivedPackaging(event) {
    this.receivedAmountPackageProduct = Number(event)
    this.cashBackPackageProduct = Math.round(event - this.pricePackageProduct)
    if (this.cashBackPackageProduct < 0) {
      this.isBack1 = true
    }
    else {
      this.isBack1 = false
    }
    return this.cashBackPackageProduct
  }
  accountReceived(event) {
    this.receivedAmount = Number(event)
    this.getCashBack()
  }
  getCashBack() {
    this.cashBackAmount = Math.round(this.receivedAmount - this.totalDue)
    if (this.cashBackAmount < 0) {
      this.isBack = true
    }
    else {
      this.isBack = false
    }
    return this.cashBackAmount
  }
  createNewCashSubscription() {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model = {
      cashMembershipInfo: {
        id: this.buffProduct.id,
        priceId: this.buffProduct.default_price,
        planName: this.buffProduct.name,
        price: this.buffProduct.price,
        startDate: this.startDate,
        startDateFormate: this.datePipe.transform(this.startDate, 'yyyy-M-d'),
        endDate: this.endDate,
        endDateFormate: this.datePipe.transform(this.endDate, 'yyyy-M-d'),
        numOfDays: this.diffInDays,
        totalDue: this.totalDue,
        receivedAmount: this.receivedAmount,
        cashBack: this.cashBackAmount,
        isPackage: false
      },
      history: {
        id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        startDate: this.startDate,
        endDate: this.endDate,
        planName: this.buffProduct.name,
        paymentType: this.paymentTypeEnum.cash,
        price: this.buffProduct.price,
        dateHistory: new Date(),
        timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
        key: this.historyCarWash.Create_Membership,
      }
    }
    this._carWashService.createVehicleCash(this.vehicle.id, model).subscribe((res: ResponseModel) => {
      // if(res.success){
      if (this.user.role == 'admin' || this.user.role == 'manager') {
        this.router.navigate(['/admin/vehicles-list'])
      }
      else if (this.user.role == 'customer') {
        this.router.navigate(['/user/view-vehicle/', this.user.id])
      }
      else {
        this.router.navigate(['/user/vehicles-list'])
      }
      // }
    })
  }
  createNewCashPackageSubscription() {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let model = {
      cashMembershipInfo: {
        id: this.buffProductPackaging.id,
        priceId: this.buffProductPackaging.default_price,
        planName: this.buffProductPackaging.name,
        price: this.buffProductPackaging.price,
        // startDate :this.startDate,
        // startDateFormate:this.datePipe.transform(this.startDate, 'yyyy-M-d'),
        // endDate : this.endDate,
        // endDateFormate:this.datePipe.transform(this.endDate, 'yyyy-M-d'),
        // numOfDays : this.diffInDays,
        totalDue: this.pricePackageProduct,
        receivedAmount: this.receivedAmountPackageProduct,
        cashBack: this.cashBackPackageProduct,
        isPackage: true
      },
      history: {
        id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        dateHistory: new Date(),
        // endDate : this.endDate,
        planName: this.buffProduct.name,
        paymentType: this.paymentTypeEnum.cash,
        price: this.buffProduct.price,
        timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
        key: this.historyCarWash.Create_Membership,
      }
    }
    if(this.isDetailing){
      this._carWashService.createVehicleCashInJob(this.vehicle.id, model,this.jobModel.jobId).subscribe((res: ResponseModel) => {
        // if(res.success){
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if (this.user.role == 'customer') {
          this.router.navigate(['/user/view-vehicle/', this.user.id])
        }
        else {
          this.router.navigate(['/user/vehicles-list'])
        }
        // }
      })
    }
    else{
      this._carWashService.createVehicleCash(this.vehicle.id, model).subscribe((res: ResponseModel) => {
        // if(res.success){
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          this.router.navigate(['/admin/vehicles-list'])
        }
        else if (this.user.role == 'customer') {
          this.router.navigate(['/user/view-vehicle/', this.user.id])
        }
        else {
          this.router.navigate(['/user/vehicles-list'])
        }
        // }
      })
    }
  }
  // async startScan() {
  //   BarcodeScanner.hideBackground(); // make background of WebView transparent
  //   const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
  //   // if the result has content
  //   if (result.hasContent) {
  //     // console.log(result.content); // log the raw scanned content
  //   }
  // }
  storeVehicleId(vehicleId) {
    console.log(vehicleId)
    this.bufferVehicleId = vehicleId
  }
  getCustomerCash() {
    this._carWashService.getCustomerCash(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success && response.data != null) {
        console.log(response.data)
        this.paymentType = this.paymentTypeEnum.cash
        // localStorage.setItem('customerId',response.data.id)
        // localStorage.setItem('emailCash',response.data.email)
        this.storePaymentType(this.paymentTypeEnum.cash)
        this.isEmailCashExists = false
      }
      else {
        this.warningCashModal.show()
        // this.paymentType = this.paymentTypeEnum.card_exists
        this.storePaymentType(this.paymentTypeEnum.cash)
        this.isEmailCashExists = true
      }
    })
  }
  storePaymentType(paymentType) {
    localStorage.setItem('paymentType', paymentType)
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  chooseCountMonth(event) {
    localStorage.setItem('countMonth', event)
  }
  ngOnDestroy() {
    localStorage.setItem('isAssignedQR', 'false')
    localStorage.removeItem('customerExists')
    localStorage.removeItem('isDetailing')
  }
  // scan qr code
  public handle(action: any, fn: string): void {
    if (fn == 'start') {
      this.scanWebModal.show()
    }
    else if (fn == 'stop') {
      this.scanWebModal.hide()
      action['stop']().subscribe(res => {
        action.data._value.data = ""
      });
    }
    action[fn]().subscribe(console.log, console.log);
  }
  public onEvent(e: any): void {
    if (e.data != "" && e != null && e != undefined) {
      let data = e.data.split('/')[4].split(',')
      console.log(data)
      this._companiesService.checkVehicleQrNumber(data[0], Number(data[1])).subscribe((response: ResponseModel) => {
        if (response.success && !response.data.isFound) {
          this.vehicle.qrCodeNumber = Number(data[1])
          this.updateVehicleInformation(this.vehicle)
        }
        else {
          // this.router.navigate(['view-vehicle-global',this.vehicle.id])
          this._toaster.warning('Please choose another Qr code', 'Qr code assigned to another vehicle')
        }
      })
      // if (Number(data[0]) != Number(this.jobNumber) && Number(data[2]) == this.qrCodeType.Qr_Not_Generic) {
      //   this._toaster.warning('QR code Does Not belong to this job', '',
      //     {
      //       progressBar: true,
      //       titleClass: 'toast-title',
      //       progressAnimation: "increasing",
      //       timeOut: 4000
      //     });
      // }
      // else if (Number(data[0]) == Number(this.jobNumber)) {
      //   if (this.isAddScan) {
      //     this.inventoryForm.patchValue({ itemNumber: Number(data[1]) })
      //     this.qrCodeNumberAdd = Number(data[1])
      //     this.qrCodeTypeAdd = Number(data[2])
      //     this.isNumberItemExist('')
      //   }
      //   else {
      //     this.qrCodeNumberUpdate = data[1]
      //     this.qrCodeTypeUpdate = data[2]
      //     this.inventoryUpdateForm.patchValue({ itemNumber: Number(data[1]) })
      //     this.isNumberItemExistEdit('')
      //   }
      // }
      this.handle(this.action, 'stop')
      this.scanWebModal.hide()
    }
  }
  public onError(e: any): void {
    this.scanWebModal.hide()
  }
  updateVehicleInformation(vehicle: any) {
    console.log('this is vehicle ',vehicle)
    let item = vehicle
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      key: this.historyCarWash.Assign_Qr_Code,
      eventName: 'Assigned Qr Code'
    }
    let model = {
      vehicleType: item.vehicleType,
      plateNumber: item.plateNumber.toUpperCase(),
      vehicleModel: item.vehicleModel,
      history: history,
      condition: item.condition,
      phone: item.phone,
      qrCodeNumber: item.qrCodeNumber
    }
    // console.log(model)
    if(this.isDetailing){
      this._carWashService.updateVehicleJob(this.vehicle.id, model,this.jobModel.jobId).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.vehicle = response.data
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Qr code is assigned Successfully')
        }
      })
    }
    else{
      this._carWashService.updateVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
        // console.log(response)
        if (response.success) {
          this.vehicle = response.data
          localStorage.setItem('historyLength', response.data.histories.length + 1)
          this._toaster.success('', 'Qr code is assigned Successfully')
        }
      })
    }
  }
  redirection() {
      if(this.isDetailing){
        if(this.user.role == 'admin' || this.user.role == 'manager'){
          this.router.navigate(['/admin/job-details',this.jobModel.jobId])
        }
        else{
        this.router.navigate(['/user/job-details',this.jobModel.jobId])
        }
      }
      else{
        if(this.user.role == 'admin' || this.user.role == 'manager'){
          this.router.navigate(['/admin/vehicles-list'])
        }
        else{
        this.router.navigate(['/user/vehicles-list'])
        }
      }
  }
  // functions for open camera
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public handleImage(webcamImage: any): void {
    this.webcamImage = webcamImage;
    this.trigger = new Subject<void>();
    this._carWashService.getVehicleInformationFromAPI(this.webcamImage._imageAsDataUrl).subscribe((response: ResponseModel) => {
      console.log(response)
      if (response.success) {
        if (response.data.results.length > 0) {
          this.cameraScanModal.hide()
          this.addVehicleFormGroup.patchValue({
            plateNumber: response.data.results[0].plate ? response.data.results[0].plate.toString().toLowerCase() : '',
          })
        }
        else {
          this.cameraScanModal.hide()
          this._toaster.warning('Try again', 'plate number NOT detect ')
        }

      }
    })
    // let body = new FormData();
    // body.append('upload', this.webcamImage._imageAsDataUrl);
    // fetch("https://api.platerecognizer.com/v1/plate-reader/", {
    //   method: "POST",
    //   headers: {
    //     Authorization: "Token 95b11f0c9ec1a1d6119d742f58c7a8c6f95fd46a",
    //   },
    //   body: body,
    // })
    //   .then((res) => res.json())
    //   .then((json) => {
    //     console.log(json)
    //     this.cameraScanModal.hide()
    //     this.searchText = json.results[0].plate.toString().toLowerCase()
    //     this.step = 0
    //     this.p= 1
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  openCamera() {
    this.showWebcam = true
    this.trigger.next();
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = !this.showWebcam
  }
  closeCamera() {
    this.showWebcam = false
  }
  hasError(event) {
    console.log('hasError', event)
  }
  getNumber(event) {
    console.log('getNumber', event)
  }
  telInputObject(event) {
    console.log('telInputObject', event)
  }
  onCountryChange(event) {
    console.log('onCountryChange', event)
  }
  yourComponentMethodToTreatyCountryChangedEvent(event) {
    this.countrySelected = event
  }
  getCurrency(){
    return this._globalService.getCurrency()
  }
}
