import { JobsService } from './../_services/jobs/jobs.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class jobByIdResolver implements Resolve<any[]>
{
  constructor(private _jobsService: JobsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this._jobsService.getJobById(route.params['id']).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
