import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-scan-mobile',
  templateUrl: './print-scan-mobile.component.html',
  styleUrls: ['./print-scan-mobile.component.scss']
})
export class PrintScanMobileComponent implements OnInit {
  @Input() isPrint: boolean = false;
  constructor() { }

  ngOnInit() {
    // console.log(this.isPrint)
  }

}
