import { Company } from 'src/app/models/company/company';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { EmptyJobDetails } from 'src/app/models/job-details/emptyJobDetails';
import { DatePipe } from '@angular/common';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolDashboardEditItem } from 'src/app/models/tools-model/ToolDashboardEditItem';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToastrService } from 'ngx-toastr';
import { AssignedType } from 'src/app/models/Enum';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
import { UpdateInfo } from 'src/app/modules/company-admin/pages/edit-company/Enum/UpdateInfo';
import { JobStatusEnum } from 'src/app/models/job-details/JobStatusEnum';
import { CategoryCompany } from 'src/app/modules/company-admin/pages/permissions/Enum-Category/CategoryCompany';
import { environment } from 'src/environments/environment.prod';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
declare var $;
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  person: any = {}
  today: any
  bsConfig: Partial<BsDatepickerConfig>;
  date = moment();
  weekno = moment(this.date).isoWeek();
  days = [];
  jobs: any[] = []
  publicId: any
  flipDiv = false;
  userModel: any = {}
  editModel: any = {}
  // subscription: Subscription;
  emptyJobDetails: EmptyJobDetails
  user: any = {}
  selected: any = {}
  newJobPermission: any = {}
  dashboardTools: any[]
  searchSpacePermission: any = {}
  jobStatisticsPermission: any = {}
  jobsListPermission: any = {}
  calendarPermission: any = {}
  // whorehousePermission: any = {}
  textSerach = ''
  datesByToday: any[] = []
  countJobsByDate: any = []
  role: any
  dateObj: any
  p: number = 1
  p1: number = 1
  p2: number = 1
  p4: number = 1
  p5: number = 1
  yearStatistic: any
  jobsInYear: any = []
  jobModel: any = {}
  email: any
  jobsSearch: any[] = []
  jobsCalender: any[] = []
  jobsStatistic: any[] = []
  jobsClone: any[] = []
  jobText: string = ''
  countryCode = true
  planCompanyType: any = PlanCompanyType
  jobStatusEnum:any = JobStatusEnum
  categoryCompany = CategoryCompany
  bufferJobId:string= ''
  baseDomain = environment.baseDomain
  carMangerPermission: any = {}
  settingsTools:any = []
  // baseDomain = 'http://localhost:4200/'
  constructor(
    private _jobsService: JobsService,
    private route: ActivatedRoute,
    private _personService: PersonService,
    private spinner: NgxSpinnerService,
    public datePipe: DatePipe,
    private _jobDetailsService: JobDetailsService,
    private router: Router,
    private _toolsService: ToolsService,
    private socket: Socket,
    public _companiesService: CompaniesService,
    private toastr: ToastrService
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user.role
    this.email = this.user.email
    this.dateObj = new Date();
    this.today = this.dateObj.toLocaleString()
    this.yearStatistic = this.datePipe.transform(new Date(), 'y')
    this.bsConfig = {
      containerClass: 'theme-blue',
      showClearButton: true,
      clearPosition: 'left',
      showTodayButton: true,
      todayPosition: 'center'
    };
    this.getweeks(this.date);
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['userJobs'].success) {
        this.jobs = response['userJobs'].data
      }
      if (response['user'].success) {
        this.userModel = response['user'].data
      }
      if (response['tools'].success) {
        let roleName = this.role
        this.dashboardTools = response['tools'].data.find(d => d.toolType === ToolType.Dashboard_Tools)
        this.settingsTools = response['tools'].data.find(d => d.toolType === ToolType.Setting_Tools)
        this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
          if(response.success){
            let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
            this.newJobPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job,ToolType.Dashboard_Tools,permissionList)
            this.searchSpacePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Search_Space,ToolType.Dashboard_Tools,permissionList)
            this.jobStatisticsPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Job_status_Statistics,ToolType.Dashboard_Tools,permissionList)
            this.carMangerPermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
            this.calendarPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Calendar,ToolType.Dashboard_Tools,permissionList)
            // this.whorehousePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Warehouse_Map,ToolType.Dashboard_Tools,permissionList)
          }
        })
      }
    })
    this.emptyJobDetails = new EmptyJobDetails()
    if (this.role != 'customer') {
      this.getJobsByDate(this.dateObj)
      this.getAllJobsByUser()
      this.getCountJobsByDate()
    }
    this.getJobStaticsYear()
    // realtime jobs list
    this.socket.on(RealTimeKeys.Job_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._jobsService.getJobSummaryById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.jobs.unshift(response.data)
          }
        }, error => {
          console.log(error)
        })
      }
    });
    this.socket.on(RealTimeKeys.Tool_Change, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
          let roleName = this.role
          this.dashboardTools = response.data.find(d => d.toolType === ToolType.Dashboard_Tools)
          this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
            if(response.success){
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
              this.newJobPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Create_New_Job,ToolType.Dashboard_Tools,permissionList)
              this.searchSpacePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Search_Space,ToolType.Dashboard_Tools,permissionList)
              this.jobStatisticsPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Job_status_Statistics,ToolType.Dashboard_Tools,permissionList)
              this.calendarPermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Calendar,ToolType.Dashboard_Tools,permissionList)
              this.carMangerPermission = this._toolsService.getOverridePermission(roleName, this.settingsTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
              // this.whorehousePermission = this._toolsService.getOverridePermission(roleName, this.dashboardTools, ToolDashboardEditItem.Warehouse_Map,ToolType.Dashboard_Tools,permissionList)
            }
          })
        }, error => {
          console.log(error)
        })
      }
    });
    // this.socket.on(RealTimeKeys.Switch_Account, (data: any) => {
    //   console.log('Switch_Account data',data)
    //   if (this.user.companyId != data.id) {
    //     if(this.role != 'customer'){
    //       this.user = JSON.parse(localStorage.getItem('user'));
    //       this.dateObj = new Date();
    //       this.today = this.dateObj.toLocaleString()
    //       this.getJobsByDate(this.dateObj)
    //       this.getAllJobsByUser()
    //       this.getCountJobsByDate()
    //       window.location.reload()
    //      }
    //      this.getJobStaticsYear()
    //   }
    // });
  }
  getJobs() {
    let companyId = this.user.companyId
    this._jobsService.getJobs(companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobsStatistic = response.data
      }
    })
  }
  getCountJobsByDate() {
    let userType: number
    this.datesByToday = []
    for (let index = 0; index < this.days.length; index++) {
      this.datesByToday.push(this.days[index].year + '-' + this.days[index].month + '-' + this.days[index].value);
    }
    let model = {
      dates: this.datesByToday
    }
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    this._jobsService.getCountJobsUserByDate(this.user.companyId, model, this.user.id, userType).subscribe((response: ResponseModel) => {
      this.countJobsByDate = response.data
    })
  }
  getAllJobsBySearch() {
    let userType: number
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    let textSerach = this.textSerach.trim()
    this._jobsService.getAllJobsUserBySearch(this.user.id, textSerach, userType).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (response.data == null) {
          this.jobsSearch = []
        }
        else {
          this.jobsSearch = response.data
        }
      }
    }, error => {
      console.log(error)
    })
  }
  storeId(id, type: number) {
    this.publicId = id
    switch (type) {
      case 0: {
        this.jobModel = this.jobs.find(j => j.id == id)
        break;
      }
      case 1: {
        this.jobModel = this.jobsSearch.find(j => j.id == id)
        break;
      }
      case 2: {
        this.jobModel = this.jobsStatistic.find(j => j.id == id)
        break;
      }
      case 3: {
        this.jobModel = this.jobsCalender.find(j => j.id == id)
        break;
      }
    }
    let jobModel = {
      jobNumber: this.jobModel.jobNumber,
      primaryCustomerName: this.jobModel.primaryCustomerName,
      jobId: this.jobModel.id,
      jobType: this.jobModel.jobType
    }
    localStorage.setItem('jobModel', JSON.stringify(jobModel))
    // this._jobsService.getJobById(id).subscribe((response: ResponseModel) => {
    //   this.jobModel.steps = response.data.steps
    //   this.jobModel.datesAndTimes = response.data.jobDetails
    //   if (response.data.primaryCustomer != 'Customer Name') {
    //     this._personService.getPersonById(response.data.primaryCustomer).subscribe((personResponse: ResponseModel) => {
    //       if (personResponse.success) {
    //         this.person = personResponse.data
    //         this.basicModal.show()
    //       }
    //     })
    //   }
    //   else {
    //     this.person = {}
    //     this.basicModal.show()
    //   }
    // })
    // this._jobsService.getJobById(id).subscribe((response: ResponseModel) => {
    //   this.jobModel.steps = response.data.steps.sort((a, b) => (a.priority.toLowerCase() < b.priority.toLowerCase() ? -1 : 1))
    //   this.jobModel.datesAndTimes = response.data.jobDetails
    //   $("#basicModal").modal('show');
    // })
    this.router.navigate(['/user/job-details/', this.publicId])
  }
  getUserJobsByYear() {
    let userType: number
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    this._jobsService.getUserJobsByYear(this.user.companyId, this.user.id, userType,this.yearStatistic).subscribe((response: ResponseModel) => {
      this.jobsStatistic = response.data
    })
  }
  getJobsByDate(date: any) {
    this.dateObj = date
    let companyId
    let dateJob
    companyId = this.user.companyId
    dateJob = this.datePipe.transform(date, 'yyyy-M-dd')
    let userType: number
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    this._jobsService.getAllJobsUserByDate(companyId, dateJob, this.user.id, userType).subscribe((response: ResponseModel) => {
      this.jobsCalender = response.data
      this.spinner.hide()
    }, error => {
      this.spinner.hide()
    })
  }
  isChooseMap() {
    this.router.navigate(['/map'])
  }
  getCountJobsByTypeInYear(type: any) {
    let userType: number
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    let date = this.datePipe.transform(this.dateObj, 'yyyy')
    this._jobsService.getCountUserJobsByTypeInYear(type, this.yearStatistic, this.user.companyId, this.user.id, userType).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobsStatistic = response.data
        this.p4 = 1
      } else {
        this.spinner.hide()
      }
    }, error => {
      console.log(error)
      this.spinner.hide()
    })
  }
  getJobStaticsYear() {
    let userType: number
    if (this.role == 'customer') {
      userType = AssignedType.CUSTOMER
    }
    if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty') {
      userType = AssignedType.USER
    }
    this._jobsService.getJobUserStaticsYear(this.yearStatistic, this.user.companyId, this.user.id, userType).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobsInYear = response.data
      }
    }, error => {
      console.log(error)
    })
  }
  resetPaginationCalender(event) {
    this.p2 = 1
  }
  previousYear() {
    this.yearStatistic = moment(this.yearStatistic).subtract(1, 'year').format('YYYY').toString();
    this.getJobStaticsYear();
  }
  nextYear() {
    this.yearStatistic = moment(this.yearStatistic).add(1, 'year').format('YYYY').toString();
    this.getJobStaticsYear();
  }
  onClick() {
    this.flipDiv = !this.flipDiv;
    this.editModel = Object.assign({}, this.userModel)
  }
  printDay() {
  }
  getAllJobsByUser() {
    if(this.user.role == 'customer'){
      this._jobsService.getAllJobsByCustomer(this.user.companyId, this.user.id).subscribe((response: ResponseModel) => {
        this.jobs = response.data
        this.jobsClone = this.jobs.filter(j => j.isPinToClone && j.isPinToClone == true)
      }, error => {
      })
    }
    else{
      this._jobsService.getAllJobsByUser(this.user.companyId, this.user.id).subscribe((response: ResponseModel) => {
        this.jobs = response.data
        this.jobsClone = this.jobs.filter(j => j.isPinToClone && j.isPinToClone == true)
      }, error => {
      })
    }
  }
  getAllJobsByUserFilter() {
    this._jobsService.getAllJobsByUser(this.user.companyId, this.user.id).subscribe((response: ResponseModel) => {
      this.jobsStatistic = response.data
    }, error => {
    })
  }
  // storeId(id) {
  //   this.publicId = id
  // }
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  // Functions Moment Date
  nextweek() {
    this.date.add(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
  }
  previousweek() {
    this.date.subtract(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
  }
  getweeks(currentDate) {
    this.days = [];
    var weekStart = currentDate.clone().startOf('week');
    var weekEnd = currentDate.clone().endOf('week');
    // console.log('start week' + moment(weekStart).add(i, 'days').format("ddd[\r\n]DD"))
    for (var i = 0; i <= 6; i++) {
      this.days.push(
        {
          name: moment(weekStart).add(i, 'days').format("ddd"),
          value: moment(weekStart).add(i, 'days').format("DD"),
          year: moment(weekStart).add(i, 'days').format("YYYY"),
          month: moment(weekStart).add(i, 'days').format("MM")
        }
      );
    };
  }
  newJob() {
    let todayDate = new Date();
    let creationDate = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate());
    this.emptyJobDetails.dateAndTime.push(
      {
        id: 1,
        startDateWindowFrom: creationDate,
        startDateWindowTo: creationDate,
        startTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        startTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        endDateWindowFrom: creationDate,
        endDateWindowTo: creationDate,
        endTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        endTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        jobDescription: " ",
        summaryDate: creationDate + ' --> ' + creationDate
      }
    )
    let users = []
    let customers = []
    if (this.user.role != 'customer') {
      users.push(this.user.id)
    } else {
      customers.push(this.user.id)
    }
    let model = {
      companyId :this.user.companyId,
      primaryCustomer: 'Customer Name',
      companyNumber: this.user.companyNumber,
      jobType: 'Job Type',
      assignedCustomers: customers,
      assignedUsers: users,
      assignedTrucks: [],
      creationDate: creationDate,
      creationTime: todayDate.toLocaleTimeString(),
      creationUserId: this.user.id,
      jobDetails : this.emptyJobDetails
    }
    this._jobsService.addJob(model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let jobModel = {
          jobNumber: response.data.jobNumber,
          jobName: response.data.jobName,
          jobId: response.data.id,
          jobType: response.data.jobType,
          primaryCustomerName: response.data.primaryCustomer
        }
        localStorage.setItem('jobModel', JSON.stringify(jobModel))
        this.router.navigate(['/user/job-details', response.data.id])
        this.toastr.success('', 'Successfully Add  Draft Job', {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        })
      }
      else{
        this.toastr.error('', 'Error Add  Draft Job', {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        })
      }
    })
  }
  serachJob(event) {
    this.p = 1
  }
  redirection() {
    this.router.navigate(['/user/job-details', this.publicId])
    $("#basicModal").modal('hide');
  }
  update() {
    this.spinner.show()
    setTimeout(() => {
      this._personService.updatePerson(this.editModel, this.editModel.id,UpdateInfo.All).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.userModel = response.data
          this.spinner.hide();
          this.flipDiv = !this.flipDiv;
        }
      })
    }, 2000);
  }
  getTodayDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }
  getJobsClone() {
    this.getAllJobsByUser()
  }
  storeBufferJobId(jobId: string) {
    this.bufferJobId = jobId
  }
  unpinCloneJob(jobId: string) {
    this._jobsService.pinCloneJob(jobId, false).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.toastr.success('', 'Job Successfully Unpinned')
      }
    })
  }
  coloneJob(jobId: string) {
    let url = ''
    let id = jobId
    let todayDate = new Date();
    let creationDate = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate());
    let dateAndTime =
      [{
        id: 1,
        startDateWindowFrom: creationDate,
        startDateWindowTo: creationDate,
        startTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        startTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        endDateWindowFrom: creationDate,
        endDateWindowTo: creationDate,
        endTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        endTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        jobDescription: " ",
        summaryDate: creationDate + ' --> ' + creationDate
      }]
    let model = {
      creationDate: creationDate,
      creationTime: todayDate.toLocaleTimeString(),
      // companyNumber: this.user.companyNumber,
      dateAndTime: dateAndTime,
      creationUserId: this.user.id,
    }
    this._jobsService.cloneJobById(id, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobModel.jobId = response.data.jobId
        this.jobModel.jobNumber = response.data.jobNumber
        this.jobModel.jobType = response.data.jobType
        this.jobModel.primaryCustomerName = response.data.primaryCustomerName
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          url = `${this.baseDomain}admin/job-details/${response.data.jobId}`
        }
        else {
          url = `${this.baseDomain}user/job-details/${response.data.jobId}`
        }
        window.location.href = url;
      } else {
        this.toastr.error('Please try again', 'Error Clone Job')
      }
    }, error => {
    })
  }
}
