import { ResponseModel } from './../../../models/response/ResponseModel';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { OnlineOfflineServiceService } from '../onlineoffline/onlineofflineService.service';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  baseUrl = environment.baseUrl
  private messageSource: BehaviorSubject<any>;
  public currentMessage: Observable<any>
  sharesItems: any = []
  idItem: any

  constructor(
    private http: HttpClient,
    public onlineOfflineService: OnlineOfflineServiceService
  ) {
    // this.filterItems()
    this.idItem = ToolJobsEditItem.ToolBox
  }
  getJobs(companyId: any): Observable<any> {
    return this.http.get(this.baseUrl + 'jobs/' + companyId);
  }
  getAllJobsByUser(companyId: any, userId: any): Observable<any> {
    return this.http.get(this.baseUrl + 'userJobs/' + companyId + '/' + userId);
  }
  getAllJobsByCustomer(companyId: any, customerId: any): Observable<any> {
    return this.http.get(this.baseUrl + 'customerJobs/' + companyId + '/' + customerId);
  }
  getJobById(id: any): Observable<any> {
    return this.http.get(this.baseUrl + 'job/' + id);
  }
  getJobSummaryById(id: any): Observable<any> {
    return this.http.get(this.baseUrl + 'JobDashboardSummary/' + id);
  }
  getAllJobsByDate(companyId: any, date: any) {
    return this.http.get(this.baseUrl + 'jobByDate/' + companyId + '/' + date);
  }
  getCountJobsByDate(companyId: any, dates: any) {
    return this.http.post(this.baseUrl + 'jobCountByDate/' + companyId, dates);
  }
  getCountJobsByType(companyId: any, typeJob: any) {
    return this.http.get(this.baseUrl + 'jobCountByType/' + companyId + '/' + typeJob);
  }
  getCountJobsByTypeInYear(type: any, date: any, comapnyId: string) {
    return this.http.get(this.baseUrl + 'jobByType/' + comapnyId + '?type=' + type + '&q=' + date);
  }
  getAllJobsBySearch(q: string) {
    return this.http.get(this.baseUrl + 'jobBySearch?q=' + q);
  }
  // user jobs
  getAllJobsUserBySearch(userId: string, q: string, userType: number) {
    return this.http.get(this.baseUrl + 'jobUserBySearch/' + userId + '/?q=' + q + '&userType=' + userType);
  }
  getCountJobsUserByDate(companyId: any, dates: any, userId: string, userType: number) {
    return this.http.post(this.baseUrl + 'jobCountUserByDate/' + companyId + '/' + userId + '?userType=' + userType, dates);
  }
  getJobUserStaticsYear(q: string, companyId: string, userId: string, userType: number) {
    return this.http.get(this.baseUrl + 'jobStaticsUser/' + companyId + '/' + userId + '/?q=' + q + '&userType=' + userType);
  }
  getAllJobsUserByDate(companyId: any, date: any, userId: string, userType: number) {
    return this.http.get(this.baseUrl + 'jobUserByDate/' + companyId + '/' + userId + '/' + date + '?userType=' + userType);
  }
  getCountUserJobsByTypeInYear(type: any, date: any, comapnyId: string, userId: string, userType: number) {
    return this.http.get(this.baseUrl + 'jobByType/' + comapnyId + '/' + userId + '?type=' + type + '&q=' + date + '&userType=' + userType);
  }
  getAllJobsByPagination(companyId: string, pageSize: any, last: any, pageNumber: any) {
    return this.http.get(this.baseUrl + 'jobByPagination/' + companyId + '?pageSize=' + pageSize + '&last=' + last  + '&pageNumber=' + pageNumber);
  }
  pinCloneJob(jobId:string,isPinToClone:boolean){
    return this.http.put(this.baseUrl + 'updateJobPinClone/' + jobId,{isPinToClone:isPinToClone});
  }
  getJobStaticsYear(q: string, companyId: string) {
    return this.http.get(this.baseUrl + 'jobStatics/' + companyId + '?q=' + q);
  }
  getShowItemsJobBy(id: string) {
    return this.http.get(this.baseUrl + 'ShowItemsJobBy/' + id);
  }
  getCompanyJobsByYear(companyId: string, q: any) {
    return this.http.get(this.baseUrl + 'jobsByYear/' + companyId + '?q=' + q);
  }
  getUserJobsByYear(companyId: string, userId: string, userType: any, q: any) {
    return this.http.get(this.baseUrl + 'jobsUserByYear/' + companyId + '/' + userId + '?q=' + q + '&userType=' + userType);
  }
  isJobNumberExist(companyId: string, jobNumber: any) {
    return this.http.get(this.baseUrl + 'jobNumberExist/' + companyId + '/' + '?jobNumber=' + jobNumber);
  }
  addJob(model) {
    // console.log('this is online status' , this.onlineOfflineService.isOnline)
    // if (!this.onlineOfflineService.isOnline) {
    //   this.onlineOfflineService.addToIndexedDb(model);
    // }
    // else{
    //   console.log('send to backend')
    //   return this.http.post(this.baseUrl + 'job', model)
    // }
    return this.http.post(this.baseUrl + 'job', model)

  }
  cloneJobById(jobId: string, model: any) {
    return this.http.post(this.baseUrl + 'cloneJob/' + jobId, model)
  }
  updateJobAssigned(jobId: string, model: any, itemId: number, isCustomerEmail: number) {
    return this.http.put(this.baseUrl + 'jobAssigned/' + jobId + '/' + itemId + '?isCustomerEmail=' + isCustomerEmail, model)
  }
  updateJob(newJob: any, id: any) {
    return this.http.put(this.baseUrl + 'job/' + id, newJob)
  }
  updateJobName(id: any, nameJob: any) {
    return this.http.put(this.baseUrl + 'updateJobName/' + id + '/' + nameJob, {})
  }
  updateJobNumber(id: any, numberJob: any) {
    return this.http.put(this.baseUrl + 'updateJobNumber/' + id + '/' + numberJob, {})
  }
  // filterItems() {
  //   let companyId = localStorage.getItem('companyId')
  //   return this.http.get(this.baseUrl + 'tools/' + companyId).subscribe((response: ResponseModel) => {
  //     let items = response.data.find(i => i.toolType === ToolType.Jobs_Tools).items
  //       .filter(j => j.toolStatus === 'Active' && j.itemId != ToolJobsEditItem.ToolBox)
  //     for (let index = 0; index < items.length; index++) {
  //       this.sharesItems.push({
  //         itemName: items[index].itemName,
  //         show: false
  //       })
  //     }
  //     // this.messageSource = this.sharesItems
  //     this.messageSource = new BehaviorSubject<any>(this.sharesItems);
  //     this.currentMessage = this.messageSource.asObservable()
  //   })
  // }
  changeMessage(newItems: any) {
    this.messageSource.next(newItems)
  }
  updateJobType(jobId: string, jobType: string) {
    return this.http.put(this.baseUrl + 'updateJobType/' + jobId, { jobType: jobType })
  }
  updateJobPrimaryCustomer(jobId: string, customerId: string, isCustomerEmail: number) {
    return this.http.put(this.baseUrl + 'updatePrimaryCustomer/' + jobId + '/' + customerId + '?isCustomerEmail=' + isCustomerEmail, {})
  }
  updateJobDetailsShowItems(id: string, items: any) {
    return this.http.put(this.baseUrl + 'updateShowItems/' + id + '/' + this.idItem, { showItems: items })
  }
  getLayerPermission(items: any[], itemId: any, userId: string) {
    let userPermission: any = {}
    let jobStatus = items.find(i => i.itemId == itemId)
    if (jobStatus != undefined) {
      if (jobStatus.users.length != 0) {
        userPermission = jobStatus.users.find(p => p.id == userId).permission
      }
    }
    return userPermission;
  }
  getDefaultLayerPermission(items: any[], itemId: any, userId: string) {
    let userPermission: any = {}
    let jobStatus = items.find(i => i.itemId == itemId)
    if (jobStatus != undefined) {
      if (jobStatus.users.length != 0) {
        userPermission = jobStatus.users.find(p => p.id == userId)
      }
    }
    return userPermission.defaultPermission;
  }
  getToolPlan(showItems: any, itemId: number) {
    return showItems.find(s => s.itemId == itemId).planName
  }
  getStaticPermission(type: number) {
    let userPermission = {}
    if (type == StatusActive.Active) {
      userPermission = {
        value: StatusActive.Active,
      }
    }
    else if (type == StatusActive.Read_Only) {
      userPermission = {
        value: StatusActive.Read_Only,
      }
    }
    return userPermission
  }
}
