export class Company {
  comapnyAddress: CompanyAddress;
  comapnyEmail: string;
  companyName: string;
  comapnyPhone: string;
  companyWebsite: string;
  id:string;
  jobs:string[];
  ownerName: string;
  totalJobs: number;
  totalTrucks: number;
  totalUsers: number;
  userInformation:UserInformation
  warehouseMap:string;
}
interface CompanyAddress {
  country: string;
  addressOne: string;
  addressTow: string;
  city: string;
  state: string;
  zip: string;
}
interface UserInformation {
  firstName:string;
  lastName:string;
  password:string;
  confirmPassword:string
  userEmail:string;
  userPhone:string
}
export enum UpdateCompany {
  status,
  plan,
}
export enum CompanyPlan {
  Free,
  Pro,
  Premium,
}
export enum CompanyStatus {
  Deactivated,
  Canceled,
  Active,
  NotPay,
  NotPayPeriod,
}
