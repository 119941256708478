import { QRCodeModule } from 'angularx-qrcode';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { CustomersListComponent } from './pages/customers-list/customers-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { CompanyAdminComponent } from './pages/company-admin/company-admin.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CompanyAdminRoutingModule } from './company-admin-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InviteCompleteComponent } from './pages/invite-complete/invite-complete.component';
import { EditCompanyComponent } from './pages/edit-company/edit-company.component';
import { FlipModule } from 'ngx-flip';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AddJobComponent } from './pages/add-job/add-job.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureComponent } from './pages/job-details/signature/signature.component';
import { AssignedUsersComponent } from './pages/job-details/assigned-users/assigned-users.component';
import { StopsComponent } from './pages/job-details/stops/stops.component';
import { NotesComponent } from './pages/job-details/notes/notes.component';
import { DraftJobComponent } from './pages/job-details/draft-job/draft-job.component';
import { CustomersMessagingComponent } from './pages/customers-messaging/customers-messaging.component';
import { SuppliesComponent } from './pages/job-details/supplies/supplies.component';
import { MapPageComponent } from './pages/map-page/map-page.component';
import { ChargeComponent } from './pages/job-details/charge/charge.component';
import { SummaryComponent } from './pages/job-details/summary/summary.component';
import { EstimateComponent } from './pages/job-details/estimate/estimate.component';
import { ItemsComponent } from './pages/job-details/items/items.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { JobStatusComponent } from './pages/job-details/job-status/job-status.component';
import { WarehouseMapComponent } from './pages/job-details/warehouse-map/warehouse-map.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AsyncEmailValidator } from '../auth/async.validator';
import { MatTabsModule } from '@angular/material/tabs';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FilterPipe } from './pages/filter-pipe/filter-pipe.pipe';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { FilterTruckPipe } from './pages/filter-truck-pipe/filter-truck-pipe.pipe';
import { LoaderComponent } from './pages/Loader/Loader.component';
import { ContainerFilterPipe } from './pages/filters/container-filter.pipe';
import { HubFilterPipe } from './pages/filters/hub-filter.pipe';
import { NoteFilterPipe } from './pages/filters/note-filter.pipe';
import { InventoryFilterPipe } from './pages/filters/inventory-filter.pipe';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrintElementModule } from 'ngx-print-element';
import { SubmitTicketComponent } from './pages/submit-ticket/submit-ticket.component';
import { JobFilterPipe } from './pages/filters/job-filter.pipe';
import { TicketFilterPipe } from './pages/submit-ticket/filter/ticket-filter.pipe';
import { PrintModule } from '../print-tage/print.module';
import { SecondInviteComponent } from './pages/invite-complete/second-invite/second-invite.component';
import { DisableCustomerComponent } from './pages/invite-complete/disable-customer/disable-customer.component';
import { ManageAccountsComponent } from './pages/manage-accounts/manage-accounts.component';
import { AccountsFilterPipe } from './pages/manage-accounts/filter-accounts/accounts-filter.pipe';
import { NewBranchComponent } from '../auth/pages/new-branch/new-branch.component';
import { CancelPersonComponent } from './pages/invite-complete/cancel-person/cancel-person.component';
import { CompanyPlansComponent } from '../auth/pages/plans/company-plans/company-plans.component';
import { AddCustomerManuallyComponent } from './pages/add-customer-manually/add-customer-manually.component';
import { FilterPendingPipe } from './pages/filter-pipe-pending/filter-pending.pipe';
import { ProgressStatusComponent } from './pages/job-details/progress-status/progress-status.component';
import { CarWashTemplatesComponent } from './pages/car-wash-folder/car-wash-templates/car-wash-templates.component';
import { AddVehicleComponent } from './pages/car-wash-folder/add-vehicle/add-vehicle.component';
import { ViewVehicleComponent } from './pages/car-wash-folder/view-vehicle/view-vehicle.component';
import { ViewVehicleGlobalComponent } from './pages/car-wash-folder/view-vehicle-global/view-vehicle-global.component';
// import { MedRouteQrComponent } from './pages/car-wash-folder/med-route-qr/med-route-qr.component';
import { NotAssignedQrComponent } from './pages/car-wash-folder/not-assigned-qr/not-assigned-qr.component';
import { AssignedVehicleQrComponent } from './pages/car-wash-folder/assigned-vehicle-qr/assigned-vehicle-qr.component';
import { VehiclesListComponent } from './pages/car-wash-folder/vehicles-list/vehicles-list.component';
import { VehiclePipe } from './pages/car-wash-folder/filters/vehicle.pipe';
import { ProductsStripComponent } from './pages/car-wash-folder/products-strip/products-strip.component';
import { CompleteMembershipComponent } from './pages/car-wash-folder/complete-membership/complete-membership.component';
import { CarWashComponent } from './pages/job-details/car-wash/car-wash.component';
// import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrScannerComponent } from './pages/car-wash-folder/qr-scanner/qr-scanner.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PermissionFilterPipe } from './pages/permissions/filter/permission.pipe';
import { TagInputModule } from 'ngx-chips';
import { DateFormatPipe } from 'src/app/models/pips/DateFormatPipe';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
// import { NgxTagsInputBoxModule } from 'ngx-tags-input-box';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgTimePastPipeModule } from 'ng-time-past-pipe';
import { AddCarWashFilterPipe } from './pages/filters/add-car-wash-filter.pipe ';
import { InviteCustomerCarWashComponent } from './pages/invite-customer-car-wash/invite-customer-car-wash.component';
import { AddVehicleCustomerComponent } from './pages/car-wash-folder/add-vehicle-customer/add-vehicle-customer.component';
import { ProductsCustomerComponent } from './pages/car-wash-folder/products-customer/products-customer.component';
import { QrCodeMovingComponent } from './pages/qr-code-moving/qr-code-moving.component';
import { QrCodeInventoryComponent } from './pages/job-details/qr-code-inventory/qr-code-inventory.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import {WebcamModule} from 'ngx-webcam';
import { QrCodeStickersComponent } from './pages/car-wash-folder/qr-code-stickers/qr-code-stickers/qr-code-stickers.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { SafeHtmlPipe } from './pages/filters/safe-pipe/SafeHtmlPipe.pipe';
import { AssignedCustomersComponent } from './pages/job-details/assigned-customers/assigned-customers.component';
import { CarWashServicesComponent } from './pages/car-wash-folder/car-wash-services/car-wash-services.component';
import { ServicePipe } from './pages/car-wash-folder/filters/service.pipe';
import { CustomizeWebsiteComponent } from './pages/car-wash-folder/customize-website/customize-website.component';
import { LightgalleryModule } from 'lightgallery/angular/10';
@NgModule({
  declarations: [
    CompanyAdminComponent,
    // InviteUserComponent,
    InviteCompleteComponent,
    EditCompanyComponent,
    CustomerProfileComponent,
    UserProfileComponent,
    AddJobComponent,
    CustomersListComponent,
    UsersListComponent,
    JobDetailsComponent,
    SignatureComponent,
    AssignedUsersComponent,
    StopsComponent,
    NotesComponent,
    DraftJobComponent,
    CustomersMessagingComponent,
    SuppliesComponent,
    MapPageComponent,
    ChargeComponent,
    SummaryComponent,
    EstimateComponent,
    ItemsComponent,
    PermissionsComponent,
    JobStatusComponent,
    WarehouseMapComponent,
    MyProfileComponent,
    ManageAccountsComponent,
    CancelPersonComponent,
    AddCustomerManuallyComponent,
    // InventoryTagComponent,
    // ContainerTagComponent,
    // ItemsListTagComponent,
    // AllInventoriesPrintComponent,
    SubmitTicketComponent,
    SecondInviteComponent,
    NewBranchComponent,
    CompanyPlansComponent,
    ProgressStatusComponent,
    // this is car wash components
    CarWashTemplatesComponent,
    AddVehicleComponent,
    ViewVehicleComponent,
    ViewVehicleGlobalComponent,
    ProductsStripComponent,
    CompleteMembershipComponent,
    CarWashComponent,
    CustomizeWebsiteComponent,
    FilterPipe, // -> added filter pipe to use it inside the component,
    FilterTruckPipe, // -> added filter pipe to use it inside the component,
    ContainerFilterPipe, // -> added filter pipe to use it inside the component,
    HubFilterPipe, // -> added filter pipe to use it inside the component,
    NoteFilterPipe, // -> added filter pipe to use it inside the component,
    InventoryFilterPipe, // -> added filter pipe to use it inside the component,
    JobFilterPipe, // -> added filter pipe to use it inside the component,
    TicketFilterPipe, // -> added filter pipe to use it inside the component,
    AccountsFilterPipe, // -> added filter pipe to use it inside the component,
    FilterPendingPipe, // -> added filter pipe to use it inside the component,
    VehiclePipe, // -> added filter pipe to use it inside the component,
    ServicePipe, // -> added filter pipe to use it inside the component,
    PermissionFilterPipe,
    // MedRouteQrComponent,
    NotAssignedQrComponent,
    AssignedVehicleQrComponent,
    VehiclesListComponent,
    QrScannerComponent,
    InviteCustomerCarWashComponent,
    DateFormatPipe,
    AddCarWashFilterPipe,
    AddVehicleCustomerComponent,
    ProductsCustomerComponent,
    QrCodeMovingComponent,
    QrCodeInventoryComponent,
    QrCodeStickersComponent,
    SafeHtmlPipe,
    AssignedCustomersComponent,
    CarWashServicesComponent
  ],
  imports: [
    CommonModule,
    CompanyAdminRoutingModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    FlipModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    SignaturePadModule,
    NgxPaginationModule,
    MatTabsModule,
    DragDropModule,
    PrintModule,
    QRCodeModule,
    // ZXingScannerModule,
    TagInputModule,
    NgxSliderModule,
    NgTimePastPipeModule,
    TimepickerModule.forRoot(),
    NgxScannerQrcodeModule,
    WebcamModule,
    NgxMatIntlTelInputModule,
    LightgalleryModule
    // MatGoogleMapsAutocompleteModule
  ],
  exports: [
    CommonModule,
    CompanyAdminRoutingModule,
    SharedModule,
    BsDatepickerModule,
    TimepickerModule,
    FormsModule,
    NgTimePastPipeModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgxPaginationModule,
    MatTabsModule,
    DragDropModule,
    NgxPrintElementModule,
    NgxPrintModule,
    QRCodeModule,
    // ZXingScannerModule,
    DateFormatPipe,
    NgxSliderModule,
    NgxScannerQrcodeModule,
    WebcamModule,
    NgxMatIntlTelInputModule,
    LightgalleryModule
  ],
  providers: [
    AsyncEmailValidator,
    DatePipe,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class CompanyAdminModule { }
