import { PersonService } from './../../../../core/_services/person/person.service';
import { Person } from 'src/app/models/person/Person';
import { MapService } from './../../../../core/_services/map-service/map.service';
import { PricingService } from './../../../../core/_services/pricing/pricing.service';
import { ToasterCustomService } from './../../../../core/_services/toaster-service/ToasterCustomService.service';
import { CompanyPlan } from './../../../../layout/pages/super-admin/pages/companies/super-enum/company-enum';
import { InitialRoles, ToolNames } from './../../../../models/tools-model/InitialRoles';
import { Socket } from 'ngx-socket-io';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolDashboardEditItem } from 'src/app/models/tools-model/ToolDashboardEditItem';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'angular-bootstrap-md';
import { HomePageTool, JobDashboardModel, OthersTool } from './ToolsObj/data-tool';
import { CategoryCompany } from './Enum-Category/CategoryCompany';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
interface ItemPermission {
  id: number,
  item: any,
  toolType: string,
  toolStatus: string,
  roles: any[],
  notifications: any[]
}
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit,OnDestroy {
  @ViewChild('changeBackupTime') changeBackupTime: ModalDirective;
  @ViewChild('tagModal') tagModal: ModalDirective;
  @ViewChild('tagSettingsModal') tagSettingsModal: ModalDirective;
  @ViewChild('overridePermissionModal') overridePermissionModal: ModalDirective;
  @ViewChild('toolModal') toolModal: ModalDirective;
  itemsPermission: ItemPermission[] = []
  explanations: any[] = []
  explanationsSettings: any[] = []
  pattern: any = "<<Job Number>>"
  patternSettings: any = "<<Company Logo>>"
  patternSettings1: any = "<<Product name>>"
  uniqId: number
  Status: any[] = []
  statusTool = "InActive";
  statusDraft: any = "Active"
  statusDeal: any = "Active"
  statusInProcess: any = "Active"
  statusDone: any = "Active"
  statusCancelled: any = "Active"
  p: number[] = [1];
  p1: 1
  p2 = 2
  totalPages: number
  index: number
  toolStatus: string
  isActivate: string
  isCopied = false;
  tools: any[] = []
  globalTools: any = []
  jobsTools: any[] = []
  CRMTools: any[] = []
  DashboardTools: any[] = []
  draftModel: any
  indexPrimary: any = 0
  indexSecondary: any = 0
  user: any
  companyPlan: number
  backUpTime = '20:00'
  jobDashboardTools: any = JobDashboardModel
  othersTools: any = OthersTool
  homePageTools: any = HomePageTool
  toolSettingEditItem = ToolSettingEditItem
  toolType = ToolType
  role: string = ''
  isCanEdit = true
  isOverrideEdit = true
  allCustomer: any[] = []
  allUsers: any[] = []
  bufferItem: any = {}
  bufferToolType: number
  bufferItemId: number
  panelOpenState = false;
  searchText = ''
  searchText1 = ''
  searchText2 = ''
  isHasPermission = true
  isExistsCustomer = true
  isExistsThirdParty = true
  bufferRoles: any
  isChangePermission = false
  categoryCompany = CategoryCompany
  // isLoading = false
  isChangeToolAddIn = false
  companyPlanFilterValue = 4
  categoryCompanyFilterValue = 0
  tagsTerms: any
  termsCondition = "• As a Monthly Member, you authorize &lt;&lt; Company Name &gt;&gt; to charge your credit card account &lt;&lt;Product Price&gt;&gt; on a monthly basis for the selected plan. &#013;  • Member understands that this Automatic Recharge Authorization&#013; shall remain in force until I cancel by written notice. The plan will remain in effect for 30 full days at a time. No refunds or credits will be given for partial periods. To cancel your membership, visit the&#013;local Car Wash location where you signed up.&#013;&#013;  • The member may cancel membership at any time. Cancellations must be received at least seven (7) days before the next billing date. Otherwise, you may&#013;be charged for that month, and the cancellation will be effective the following billing date. &#013;&#013;  • QR Code stickers are applied to the front windshield of the registered vehicle and must be placed by&#013;a &lt;&lt;Company Name&gt;&gt; employee. Tampering with a QR code sticker can result in the cancellation of membership. Member must notify &lt;&lt;Company Name&gt;&gt; of any changes in vehicle ownership. &#013;&#013;  • Member understands that&#013;circumstances may affect the availability of services such as preventive maintenance, including equipment failure or inclement weather. (companyName) the right to cancel, amend or change the program from time&#013; to time as necessary and can cancel your monthly membership plan if the card processing system declines the monthly charge. &#013;&#013;  • As a Monthly Member, you understand that monthly rates may be increased,&#013; with notice posted at the site at least 30 days in advance. &#013;&#013;• As a Member, you agree to receive other communications from &lt;&lt;Company Name&gt;&gt;. You can unsubscribe from these communications at any time. &lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company 1 line address&gt;&gt;&#013; "
  template
  emailReply: string = ''
  emailReplyLabel = "<<Contact  email>>"
  emailPattern = ValidationModel.emailPattern
  selectedIndex: any = 0
  emailNotificationEnum = EmailNotification
  constructor(
    private spinner: NgxSpinnerService,
    private _toolsService: ToolsService,
    private route: ActivatedRoute,
    private socket: Socket,
    private toastr: ToastrService,
    private _toasterCustomService: ToasterCustomService,
    private _mapService: MapService,
    private _personService: PersonService,
    private _companyService: CompaniesService,
  ) { }

  ngOnInit() {
    this.template = "&#013; Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in draft status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team"
    this.user = JSON.parse(localStorage.getItem('user'))
    this.companyPlan = this.user.companyPlan
    let selectedIndex = localStorage.getItem('selectedIndex')
    this.selectedIndex = !selectedIndex ? 0 : Number(selectedIndex)
    // this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        let i = this.tools.findIndex(to => to.toolType === ToolType.Setting_Tools)
        let j = this.tools[i].items.findIndex(it => it.itemId === ToolSettingEditItem.Company_Account)
        this.tools[i].items.splice(j, 1);
        this.jobsTools = this.tools.find(t => t.toolType === ToolType.Jobs_Tools)
        this.CRMTools = response['tools'].data.find(t => t.toolType === ToolType.Setting_Tools).items
        this.DashboardTools = response['tools'].data.find(t => t.toolType === ToolType.Dashboard_Tools).items
        this.globalTools = this.tools
        this.filterDefault()
      }
    })
    this.explanations = [{
      name: "Job Number",
      value: "<<Job Number>>"
    },
    {
      name: "Job Date and Time",
      value: "<<Job Date and Time>>"
    },
    {
      name: "Job Status",
      value: "<<Job Status>>"
    },
    {
      name: "Customer first and last name",
      value: "<<Customer Name>>"
    },
    {
      name: "User Portal Signup Link",
      value: "<<User Portal Signup Link>>"
    },
    {
      name: "Company Logo",
      value: "<<Company Logo>>"
    },
    {
      name: "Company name",
      value: "<<Company name>>"
    },
    {
      name: "Company 1 line address",
      value: "<<Company 1 line address>>"
    },
    {
      name: "Company many lines address",
      value: "<<Company many lines address>>"
    },
    {
      name: "Company phone number",
      value: "<<Company Phone>>"
    },
    {
      name: "Company website Link",
      value: "<<Company website Link>>"
    }
    ]
    this.explanationsSettings = [
      {
        name: "User Portal Signup Link",
        value: "<<User Portal Signup Link>>"
      },
      {
        name: "Customer first and last name",
        value: "<<Customer Name>>"
      },
      {
        name: "Company Logo",
        value: "<<Company Logo>>"
      },
      {
        name: "Company name",
        value: "<<Company name>>"
      },
      {
        name: "Company 1 line address",
        value: "<<Company 1 line address>>"
      },
      {
        name: "Company many lines address",
        value: "<<Company many lines address>>"
      },
      {
        name: "Company phone number",
        value: "<<Company Phone>>"
      },
      {
        name: "Company website Link",
        value: "<<Company website Link>>"
      }
    ]
    this.tagsTerms = [{
      name: "Product name",
      value: "<<Product name>>"
    },
    {
      name: "Product price",
      value: "<<Product price>>"
    },
    {
      name: "Company name.",
      value: "<<Company name>>"
    },
    {
      name: "Company 1 line address",
      value: "<<Company 1 line address>>"
    },
    {
      name: "Company Logo",
      value: "<<Company Logo>>"
    }
    ]
    this.Status = [
      { name: "Active" },
      { name: "InActive" }
    ]
    this.itemsPermission = [
      {
        id: 1,
        toolType: "Job Tools",
        item: {
          mainTittle: "Job Status",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            name: ":Draft",
            status: "InActive",
            message: "Dear Draft"
          },
          {
            id: 2,
            name: ":Deal",
            status: "InActive",
            message: "Deal"
          },
          {
            id: 3,
            name: ":InProcess",
            status: "InActive",
            message: "InProcess"
          },
          {
            id: 4,
            name: ":Done",
            status: "InActive",
            message: "Done"
          },
          {
            id: 5,
            name: ":Canceled",
            status: "InActive",
            message: "Canceled"
          }
        ]
      },
      {
        id: 2,
        toolType: "Job Tools",
        item: {
          mainTittle: "Job Summary",
          // secondaryTittle:"Estimates",
          // summaryTittle:"Actual: Yes || Balance: 00000"
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 3,
        toolType: "Job Tools",
        item: {
          mainTittle: "Assigned Customers",
          secondaryTittle: "Assigned Customers",
          addBtn: "Invite Customer",
          summaryTittle: "(2 Customer)"
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 4,
        toolType: "Job Tools",
        item: {
          mainTittle: "Assigned Users",
          secondaryTittle: "Assigned Users",
          addBtn: "Invite Customer",
          summaryTittle: "(4 Users | 2 Customer)"
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 5,
        toolType: "Job Tools",
        item: {
          mainTittle: "Assigned Truck(s)",
          secondaryTittle: "Assigned Truck(s)",
          addBtn: "Add New",
          summaryTittle: "3 Truck(s)"
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 6,
        toolType: "Job Tools",
        item: {
          mainTittle: "Steps",
          secondaryTittle: "Steps",
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 7,
        toolType: "Job Tools",
        item: {
          mainTittle: "Date and Time",
          secondaryTittle: "Date and Time",
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 8,
        toolType: "Job Tools",
        item: {
          mainTittle: "Notes",
          secondaryTittle: "Notes",
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 9,
        toolType: "Job Tools",
        item: {
          mainTittle: "Estimate",
          secondaryTittle: "Estimate",
          btnGroup: {
            emailTo: "Email To",
            print: "Print",
          },
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 10,
        toolType: "Job Tools",
        item: {
          mainTittle: "Inventory",
          secondaryTittle: "Inventory",
          btnGroup: {
            emailTo: "Email To",
            print: "Print",
          },
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 11,
        toolType: "Job Tools",
        item: {
          mainTittle: "Containers",
          secondaryTittle: "Containers",
          addBtn: "Add New",
          sharingBtn: "Use Existing"
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 12,
        toolType: "Job Tools",
        item: {
          mainTittle: "Signatures",
          secondaryTittle: "Signatures",
          addBtn: "Add New",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 13,
        toolType: "Job Tools",
        item: {
          mainTittle: "Customers List",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 14,
        toolType: "Job Tools",
        item: {
          mainTittle: "Users List",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 15,
        toolType: "Job Tools",
        item: {
          mainTittle: "Trucks List",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      },
      {
        id: 16,
        toolType: "Job Tools",
        item: {
          mainTittle: "Warehouse Map",
        },
        toolStatus: "Disactivate",
        roles: [
          {
            id: 1,
            key: "Manger",
            value: "InActive"
          },
          {
            id: 2,
            key: "TeamL",
            value: "InActive"
          },
          {
            id: 3,
            key: "Crew",
            value: "InActive"
          },
          {
            id: 4,
            key: "3rd Party",
            value: "InActive"
          },
          {
            id: 5,
            key: "customer",
            value: "InActive"
          },
        ],
        notifications: [
          {
            id: 1,
            status: "InActive",
          }
        ]
      }
    ]
    this.totalPages = this.itemsPermission.length;
    this.socket.on(RealTimeKeys.Tool_Change, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._toolsService.getToolItem(data.id,data.toolItemId).subscribe((response:ResponseModel)=>{
          if(response.success){
          let i =   this.tools.findIndex(t=>t.id == data.id)
          let j =   this.tools[i].items.findIndex(t=>t.itemId == data.toolItemId)
          this.tools[i].items[j]=response.data
          }
        })
        // this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
        //   this.tools = response.data
        //   let i = this.tools.findIndex(to => to.toolType === ToolType.Setting_Tools)
        //   let j = this.tools[i].items.findIndex(it => it.itemId === ToolSettingEditItem.Company_Account)
        //   this.tools[i].items.splice(j, 1);
        //   this.jobsTools = this.tools.find(t => t.toolType === ToolType.Jobs_Tools)
        //   this.CRMTools = this.tools.find(t => t.toolType === ToolType.Setting_Tools).items
        //   this.DashboardTools = this.tools.find(t => t.toolType === ToolType.Dashboard_Tools).items
        //   this.globalTools = this.tools
        //   this.filterDefault()
        // }, error => {
        //   console.log(error)
        // })
      }
    });

  }
  filterDefault() {
    this.companyPlanFilterValue = 4
    this.categoryCompanyFilterValue = this.user.categoryCompany
    this.filterToolsPlan(4)
    this.filterToolsCategory(this.user.categoryCompany)
  }
  saveTool(newValue: any, z, id) {
    // let index = this.tools[z].items.findIndex(i => i.itemId == id)
    // this.tools[z].items[index].isLoading = true
    // setTimeout(() => {
    // this.tools[z].items[index].isLoading = false
    // console.log('aftar',this.tools[z].items[index].isLoading)
    this.isChangeToolAddIn = true
    this.toolModal.show()
    let i = this.tools[z].items.findIndex(i => i.itemId === id);
    this.toolStatus = newValue;
    this.index = i;
    this.isActivate = newValue == "Active" ? "InActive" : "Active"
    // this.tools[z].items[i].toolStatus = newValue
    this.indexPrimary = z
    this.indexSecondary = i
    // if (this.tools[z].items[i].toolStatus === 'Active') {
    //   this.tools[z].items[i].toolStatus = "InActive"
    // }
    // else {
    //   this.tools[z].items[i].toolStatus = "Active"
    // }
    // }, 1500);
  }
  reset(i, j) {
    if (this.isActivate === 'Active') {
      this.tools[i].items[j].toolStatus = "InActive"
    }
    else {
      this.tools[i].items[j].toolStatus = "Active"
    }
  }
  changeTool() {
    // this.statusTool = newTool.split(' ')[0];
    this.itemsPermission[this.index].toolStatus = this.toolStatus;
  }
  changeStatus(newValue, indexPrimary, id, j) {
    let indexSecondary = this.tools[indexPrimary].items.findIndex(i => i.itemId === id);
    this.tools[indexPrimary].items[indexSecondary].permissionSettings[j].value = Number(newValue);
    this.indexPrimary = indexPrimary
    this.indexSecondary = indexSecondary
    this.isChangePermission = true
  }
  onOptionsSelected(value: string) {
    this.pattern = value
  }
  onOptionsSelectedSettings(value: string) {
    this.patternSettings = value
  }
  onOptionsSelectedSettings1(value: string) {
    this.patternSettings1 = value
  }
  onOptionsDraft(value, z, j) {
    this.tools[z].items[j].emailNotificationDraft.status = value
    this.indexPrimary = z
    this.indexSecondary = j
  }
  onOptionsDeal(value, z, j) {
    this.tools[z].items[j].emailNotificationDeal.status = value
    this.indexPrimary = z
    this.indexSecondary = j
  }
  onOptionsInProcess(value, z, j) {
    this.tools[z].items[j].emailNotificationInProcess.status = value
    this.indexPrimary = z
    this.indexSecondary = j
  }
  onOptionsDone(value, z, j) {
    this.tools[z].items[j].emailNotificationInDone.status = value
    this.indexPrimary = z
    this.indexSecondary = j
  }
  onOptionsCancelled(value, z, j) {
    this.tools[z].items[j].emailNotificationCanceled.status = value
    this.indexPrimary = z
    this.indexSecondary = j
  }
  copyNotify() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
  addTool() {
    let model = this.getJobTool();
    // let model = this.getSettingsTool();
    // let model = this.getDashboardTool();
    // this._toolsService.addTool(model).subscribe((response: ResponseModel) => {
    //   this.tools.push(response.data)
    // })
  }
  getJobTool() {
    let model = {
      companyId: localStorage.getItem('companyId'), // this is company id from you
      toolName: ToolNames.Job_Tool,
      toolType: ToolType.Jobs_Tools,
      toolCategory: 'Moving',
      items: [
        {
          itemId: ToolJobsEditItem.ToolBox,
          itemName: 'Toolbox',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
        },
        {
          itemId: ToolJobsEditItem.Repeat,
          itemName: 'Repeat (Coming Soon)',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan,
        },
        {
          itemId: ToolJobsEditItem.jobStatus,
          itemName: 'Job Status',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "emailNotification:Draft",
              status: "InActive",
              description: "Email Assigned Users and Customers When Job Status has been changed to [Draft]",
              type: EmailNotification.Email_Notification_Job_Status_Draft,
              template: "Dear &lt;&lt;customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "emailNotification:Deal",
              status: "InActive",
              description: "Email Assigned Users and Customers When Job Status has been changed to [Deal]",
              type: EmailNotification.Email_Notification_Job_Status_Deal,
              template: "Dear &lt;&lt;customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "emailNotificationIn:InProcess",
              status: "InActive",
              description: "Email Assigned Users and Customers When Job Status has been changed to [InProcess]",
              type: EmailNotification.Email_Notification_Job_Status_InProcess,
              template: "Dear &lt;&lt;customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "emailNotificationIn:Done",
              status: "InActive",
              description: "Email Assigned Users and Customers When Job Status has been changed to [Done]",
              type: EmailNotification.Email_Notification_Job_Status_Done,
              template: "Dear &lt;&lt;customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "emailNotificationIn:Canceled",
              status: "InActive",
              description: "Email Assigned Users and Customers When Job Status has been changed to [Canceled]",
              type: EmailNotification.Email_Notification_Job_Status_Canceled,
              template: "Dear &lt;&lt;customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.assignedCustomers,
          itemName: 'Assigned Customers ',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email customer When he is Assigned to A Job",
              type: EmailNotification.Email_Notification_Customers_Assigned_Job,
              status: "InActive",
              template: "Dear  &lt;&lt;Customer Name&gt;&gt; ,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email customer When he is UnAssigned",
              type: EmailNotification.Email_Notification_Customers_Unassigned_Job,
              status: "InActive",
              template: "Dear &lt;&lt;Customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email customers When There is a New Primary Customer",
              type: EmailNotification.Email_Notification_Customer_Primary,
              status: "InActive",
              template: "Dear &lt;&lt;Customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.assignedUsers,
          itemName: 'Assigned Users',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email User When he is Assigned",
              type: EmailNotification.Email_Notification_Users_Assigned_Job,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email User When he is UnAssigned",
              type: EmailNotification.Email_Notification_Users_Unassigned_Job,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.assigned_Truck,
          itemName: 'Assigned Truck(s)',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email Primary Driver When he is assigned to a truck",
              type: EmailNotification.Email_Notification_Trucks_Assigned_Job,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.steps,
          itemName: 'Steps',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email Users & Customers When a new Step has been Change or Added",
              type: EmailNotification.Email_Notification_Step_Add_Or_Changed,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.Date_and_Time,
          itemName: 'Date and Time',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email Users & Customers When Time & Date has been Change or Added",
              type: EmailNotification.Email_Notification_Date_Add_Or_Changed,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.notes,
          itemName: 'Notes',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email Users & Customers When a new Note has been Change or Added",
              type: EmailNotification.Email_Notification_Note_Add_Or_Changed,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.inventory,
          itemName: 'Inventory',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Pro,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email Primary Customer the Inventory List",
              type: EmailNotification.Email_Notification_Inventory_Send_Email,
              status: "InActive",
              template: "Dear &lt;&lt;User Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }
          ]
        },
        {
          itemId: ToolJobsEditItem.containers,
          itemName: 'Containers',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Pro,
          // emailNotification: this.getEmailNotification()
        },
        {
          itemId: ToolJobsEditItem.signatures,
          itemName: 'Signatures',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Pro,
          // emailNotification: this.getEmailNotification()
        },
      ]
    }
    return model
  }
  getDashboardTool() {
    let model = {
      companyId: localStorage.getItem('companyId'), // this is comapnyId from you
      toolName: ToolNames.Home_Page_Tool,
      toolType: ToolType.Dashboard_Tools,
      toolCategory: 'Moving',
      items: [
        {
          itemId: ToolDashboardEditItem.Create_New_Job,
          itemName: 'Draft Job',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
        },
        {
          itemId: ToolDashboardEditItem.Search_Space,
          itemName: 'Search',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
        },
        {
          itemId: ToolDashboardEditItem.Job_status_Statistics,
          itemName: 'Job status Statistics ',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
        },
        {
          itemId: ToolDashboardEditItem.Calendar,
          itemName: 'Calendar',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
        },
        {
          itemId: ToolSettingEditItem.Warehouse_Map,
          itemName: 'Warehouse Map',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer),
          toolStatus: "InActive",
          planName: CompanyPlan.Premium,
        }
      ]
    }
    return model;
  }
  getSettingsTool() {
    let model = {
      companyId: localStorage.getItem('companyId'), // comapny id from you
      toolType: ToolType.Setting_Tools,
      toolName: ToolNames.Others_Tool,
      toolCategory: 'Moving',
      items: [
        {
          itemId: ToolSettingEditItem.Customers_list,
          itemName: 'Customers',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [
            {
              name: "EmailNotification",
              description: "Email customer When he is invited or Re-Invited",
              type: EmailNotification.Email_Notification_Invite_Reinvite,
              status: "InActive",
              template: "Dear  &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
              // template: "Dear &lt;span .text-blue&gt;  &lt;&lt;Name&gt;&gt; &lt;/span&gt;   ,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email customer When his Invite was Canceled",
              type: EmailNotification.Email_Notification_Canceled,
              status: "InActive",
              template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email customer When he is Disabled",
              type: EmailNotification.Email_Notification_Disable,
              status: "InActive",
              template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            },
            {
              name: "EmailNotification",
              description: "Email customer When he Signed Up",
              type: EmailNotification.Email_Notification_SignedUp,
              status: "InActive",
              template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
            }]
        },
        {
          itemId: ToolSettingEditItem.Users_list,
          itemName: 'Users',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [{
            name: "EmailNotification",
            description: "Email User When he is invited or Re-Invited",
            type: EmailNotification.Email_Notification_Invite_Reinvite,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          },
          {
            name: "EmailNotification",
            description: "Email User When his role has been changed",
            type: EmailNotification.Email_Notification_Role_Change,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          },
          {
            name: "EmailNotification",
            description: "Email User When Signed Up",
            type: EmailNotification.Email_Notification_SignedUp,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          }]
        },
        {
          itemId: ToolSettingEditItem.Trucks_list,
          itemName: 'Trucks',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer_And_ThirdParty),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [{
            name: "EmailNotification",
            description: "Email Primary Driver When he is assigned to a truck",
            type: EmailNotification.Email_Notification_Primary_Driver,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          }]
        },
        {
          itemId: ToolSettingEditItem.Company_Account,
          itemName: 'Company Account',
          permissionSettings: [
            {
              id: 1,
              key: "manager",
              view: "Manger",
              value: StatusActive.InActive,
              selected: true
            }
          ],
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [{
            name: "EmailNotification",
            description: "Email Admin or Manager When a new information is Saved",
            type: EmailNotification.Email_Notification_Information_Saved,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          },
          {
            name: "EmailNotification",
            description: "Email Manager When he has R&W Permission Level",
            type: EmailNotification.Email_Notification_Manger_Read_Write,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          },
          {
            name: "EmailNotification",
            description: "Email Manager When he his R&W Permission Level is Disabled",
            type: EmailNotification.Email_Notification_Manger_Disable,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          }
          ]
        },
        {
          itemId: ToolSettingEditItem.My_Profile,
          itemName: 'My Profile',
          permissionSettings: this.getRoles(InitialRoles.All_Roles),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: [{
            name: "EmailNotification",
            description: "Email User When a New Change to his Profile is Saved",
            type: EmailNotification.Email_Notification_My_Profile_Change,
            status: "InActive",
            template: "Dear &lt;&lt;Name&gt;&gt;,&#013; &#013;Text &lt;&lt;User Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
          }
          ]
        },
        {
          itemId: ToolSettingEditItem.Support_Tickets,
          itemName: 'Support Tickets',
          permissionSettings: this.getRoles(InitialRoles.All_Roles_Without_Customer),
          toolStatus: "InActive",
          planName: CompanyPlan.Free,
          emailNotification: []
        }
      ]
    }
    return model;
  }
  // all roles
  get Roles() {
    let roles =
      [
        {
          id: 1,
          key: "manager",
          view: "Manger",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 2,
          key: "teamleader",
          view: "Team Leader",
          value: StatusActive.InActive,
          selected: true

        },
        {
          id: 3,
          key: "crew",
          view: "Crew",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 4,
          key: "3rdparty",
          view: "3Rd Party",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 5,
          key: "customer",
          view: "Customer",
          value: StatusActive.InActive,
          selected: true
        },
      ]
    return roles
  }
  // roles without customer
  get RolesNotCustomer() {
    let roles =
      [
        {
          id: 1,
          key: "manager",
          view: "Manger",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 2,
          key: "teamleader",
          view: "Team Leader",
          value: StatusActive.InActive,
          selected: true

        },
        {
          id: 3,
          key: "crew",
          view: "Crew",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 4,
          key: "3rdparty",
          view: "3Rd Party",
          value: StatusActive.InActive,
          selected: true
        },
      ]
    return roles
  }
  // roles without customer and 3rdparty
  get RolesNotCustomerAndThirdParty() {
    let roles =
      [
        {
          id: 1,
          key: "manager",
          view: "Manger",
          value: StatusActive.InActive,
          selected: true
        },
        {
          id: 2,
          key: "teamleader",
          view: "Team Leader",
          value: StatusActive.InActive,
          selected: true

        },
        {
          id: 3,
          key: "crew",
          view: "Crew",
          value: StatusActive.InActive,
          selected: true
        }
      ]
    return roles
  }
  getRoles(role: number) {
    let roles = []
    switch (role) {
      case InitialRoles.All_Roles:
        {
          roles =
            [
              {
                id: 1,
                key: "manager",
                view: "Manger",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 2,
                key: "teamleader",
                view: "Team Leader",
                value: StatusActive.InActive,
                selected: true

              },
              {
                id: 3,
                key: "crew",
                view: "Crew",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 4,
                key: "3rdparty",
                view: "3Rd Party",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 5,
                key: "customer",
                view: "Customer",
                value: StatusActive.InActive,
                selected: true
              },
            ]
          break;
        }
      case InitialRoles.All_Roles_Without_Customer:
        {
          roles =
            [
              {
                id: 1,
                key: "manager",
                view: "Manger",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 2,
                key: "teamleader",
                view: "Team Leader",
                value: StatusActive.InActive,
                selected: true

              },
              {
                id: 3,
                key: "crew",
                view: "Crew",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 4,
                key: "3rdparty",
                view: "3Rd Party",
                value: StatusActive.InActive,
                selected: true
              },
            ]
          break;
        }
      case InitialRoles.All_Roles_Without_Customer_And_ThirdParty:
        {
          roles =
            [
              {
                id: 1,
                key: "manager",
                view: "Manger",
                value: StatusActive.InActive,
                selected: true
              },
              {
                id: 2,
                key: "teamleader",
                view: "Team Leader",
                value: StatusActive.InActive,
                selected: true

              },
              {
                id: 3,
                key: "crew",
                view: "Crew",
                value: StatusActive.InActive,
                selected: true
              }
            ]
          break;
        }
    }
    return roles
  }
  getEmailNotification() {
    let emailNotification = {
      name: "emailNotification",
      status: "InActive",
      template: "Dear &lt;&lt;Customer Name&gt;&gt;,&#013; &#013;Text &lt;&lt;Customer Name&gt;&gt; has begun. You can view all your items after you sign up for a moving portal account using this link:  &lt;&lt;Customer Portal Signup Link&gt;&gt; . If you have already signed up for an account, then just sign in here:  &lt;&lt;Portal Link&gt;&gt; . Thanks for using choosing us. &#013;&#013; thank you yu can call us anytime &#013;&#013;&lt;&lt;Company Name&gt;&gt;&#013; &lt;&lt;Company Multi Line Address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&#013;&lt;&lt;Company Logo&gt;&gt;"
    }
    return emailNotification
  }
  changeStatusEmail(value, z, idItem, type) {
    let j = this.tools[z].items.findIndex(i => i.itemId === idItem);
    let m = this.tools[z].items[j].emailNotification.findIndex(e => e.type === type);
    this.tools[z].items[j].emailNotification[m].status = value
    let toolUpdated = this.tools[z].items[j];
    let toolId = this.tools[z].id
    let itemId = this.tools[z].items[j].itemId
    this._toolsService.updateTool(toolId, itemId, toolUpdated).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (this.tools[z].items[j].emailNotification[m].status == 'Active') {
          this._toasterCustomService.success('This settings will be a default', 'Email Notification Successfully activated')
        }
        if (this.tools[z].items[j].emailNotification[m].status == 'InActive') {
          this._toasterCustomService.success('This settings will be a default', 'Email Notification Successfully deactivated')
        }
      }
      else {
        this._toasterCustomService.success('Please try again', 'Email Notification Error')
      }
    }, error => {
      console.log(error)
      this._toasterCustomService.success('Please try again', 'Email Notification Error')
    })
  }
  changeSmsStatusEmail(value, z, idItem, type) {
    let j = this.tools[z].items.findIndex(i => i.itemId === idItem);
    let m = this.tools[z].items[j].SmsNotification.findIndex(e => e.type === type);
    this.tools[z].items[j].SmsNotification[m].status = value
    let toolUpdated = this.tools[z].items[j];
    let toolId = this.tools[z].id
    let itemId = this.tools[z].items[j].itemId
    this._toolsService.updateTool(toolId, itemId, toolUpdated).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (this.tools[z].items[j].SmsNotification[m].status == 'Active') {
          this._toasterCustomService.success('This settings will be a default', 'Sms  Notification Successfully activated')
        }
        if (this.tools[z].items[j].SmsNotification[m].status == 'InActive') {
          this._toasterCustomService.success('This settings will be a default', 'Sms Notification Successfully deactivated')
        }
      }
      else {
        this._toasterCustomService.success('Please try again', 'Sms Notification Error')
      }
    }, error => {
      console.log(error)
      this._toasterCustomService.success('Please try again', 'Sms Notification Error')
    })
  }
  save(z, idItem, type, id: string) {
    if (this.isChangeToolAddIn) {
      if (this.toolStatus === 'Active') {
        this.tools[this.indexPrimary].items[this.indexSecondary].toolStatus = "InActive"
        // console.log(this.tools[this.indexPrimary].items[this.indexSecondary].toolStatus)
      }
      else {
        this.tools[this.indexPrimary].items[this.indexSecondary].toolStatus = "Active"
        // console.log(this.tools[this.indexPrimary].items[this.indexSecondary].toolStatus)

      }
      // }, 1500);
      this.tools[z].items[idItem].isLoading = true
      setTimeout(() => {
        this.tools[z].items[idItem].isLoading = false
        this.globalSave(z, idItem, type, id)
      }, 1000);
    } else {
      this.globalSave(z, idItem, type, id)
    }
  }
  globalSave(z, idItem, type, id: string) {
    let j
    let m
    if (type != null) {
      j = this.tools[z].items.findIndex(i => i.itemId === idItem);
      m = this.tools[z].items[j].emailNotification.findIndex(e => e.type === type);
    }
    else {
      j = this.indexSecondary
    }
    if (this.tools[z].items[j].emailNotification && m != null && id != '') {
      let TextArea = (document.getElementById(id) as HTMLInputElement)
      let view: any = TextArea.value
      let model: string = TextArea.value
      model = model.replace(/<</g, '&lt;&lt;')
      model = model.replace(/>>/g, '&gt;&gt;')
      model = model.replace(/[\n\r]+/g, '&#013;')
      this.tools[z].items[j].emailNotification[m].template = model
    }
    let toolUpdated = this.tools[z].items[j];
    let toolId = this.tools[z].id
    let itemId = this.tools[z].items[j].itemId
    this._toolsService.updateTool(toolId, itemId, toolUpdated).subscribe((response: ResponseModel) => {
      if (response.success) {
        // Notification Home Page
        if (!this.isChangePermission && this.tools[z].toolType == ToolType.Dashboard_Tools && this.tools[z].items[j].toolStatus == 'Active') {
          this._toasterCustomService.success('Please Review tool permission home page', 'Tool Successfully Added to Home Page')
        }
        if (!this.isChangePermission && this.tools[z].toolType == ToolType.Dashboard_Tools && this.tools[z].items[j].toolStatus == 'InActive') {
          this._toasterCustomService.success('You can unhide it anytime later', 'Tool Successfully Hidden  in Home Page')
        }
        // Notification Job Dashboard
        if (!this.isChangePermission && this.tools[z].toolType == ToolType.Jobs_Tools && this.tools[z].items[j].toolStatus == 'Active') {
          this._toasterCustomService.success('Please Review tool permission job dashboard', 'Tool Successfully Added to Toolbox')
        }
        if (!this.isChangePermission && this.tools[z].toolType == ToolType.Jobs_Tools && this.tools[z].items[j].toolStatus == 'InActive') {
          this._toasterCustomService.success('You can unhide it anytime later', 'Tool Successfully Hidden  in Toolbox')
        }
        // Notification Settings
        if (!this.isChangePermission && !this.isChangePermission && this.tools[z].toolType == ToolType.Setting_Tools && this.tools[z].items[j].toolStatus == 'Active') {
          this._toasterCustomService.success('Please Review tool permission others', 'Tool Successfully Added to Others')
        }
        if (!this.isChangePermission && this.tools[z].toolType == ToolType.Setting_Tools && this.tools[z].items[j].toolStatus == 'InActive') {
          this._toasterCustomService.success('You can unhide it anytime later', 'Tool Successfully Hidden  in Others')
        }
        if (this.isChangePermission) {
          this._toasterCustomService.success('', 'Permission Successfully Changed, This settings will be a default')
          this.isChangePermission = false
        }
      }
      else {
        this._toasterCustomService.error(response.message, 'Error save tools')
      }
      this.isChangeToolAddIn = false
    }, error => {
      console.log(error)
      this._toasterCustomService.error('', 'Error save tools')
    })
  }
  saveJobStatus(value, z, j) {
    let TextArea: any
    if (value === 'draft') {
      TextArea = (document.getElementById("myTextareaDraft") as HTMLInputElement)
      this.tools[z].items[j].emailNotificationDraft.template = TextArea.value.replace(/[\n\r]+/g, ' ')
    }
    else if (value === 'deal') {
      TextArea = (document.getElementById("myTextareaDeal") as HTMLInputElement)
      this.tools[z].items[j].emailNotificationDeal.template = TextArea.value.replace(/[\n\r]+/g, ' ')
    }
    else if (value === 'inProcess') {
      TextArea = (document.getElementById("myTextareaInProcess") as HTMLInputElement)
      this.tools[z].items[j].emailNotificationInProcess.template = TextArea.value.replace(/[\n\r]+/g, ' ')
    }
    else if (value === 'inDone') {
      TextArea = (document.getElementById("myTextareaDone") as HTMLInputElement)
      this.tools[z].items[j].emailNotificationInDone.template = TextArea.value.replace(/[\n\r]+/g, ' ')
    }
    else if (value === 'canceled') {
      TextArea = (document.getElementById("myTextareaCanceled") as HTMLInputElement)
      this.tools[z].items[j].emailNotificationCanceled.template = TextArea.value.replace(/[\n\r]+/g, ' ')
    }
    let toolUpdated = this.tools[z].items[j];
    let toolId = this.tools[z].id
    let itemId = this.tools[z].items[j].itemId
    this.spinner.show()
    this._toolsService.updateTool(toolId, itemId, toolUpdated).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.toastr.success('', 'Successfully saved tools changes',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
      else {
        this.toastr.error(response.message, 'Error save tools',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
    }, error => {
      console.log(error)
      this.toastr.error('', 'Error save tools',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
    })
  }
  // map functions
  changedBackUp(event) {
    this.changeBackupTime.show()
  }
  updateMapTimeBackUp() {
    if (this.backUpTime == null || this.backUpTime == undefined || this.backUpTime == '') {
      this.backUpTime = '20:00'
    }
    this._mapService.updateMapTimeBackUp(this.user.companyId, this.backUpTime).subscribe((response: ResponseModel) => {
      this.backUpTime = response.data
    })
  }
  // getMapBackUpTime() {
  //   this._mapService.getMapBackUpTime(this.user.companyId).subscribe((response: ResponseModel) => {
  //     this.backUpTime = response.data.backUpTime
  //   })
  // }
  getPermissionSettings(permissionSettings: any) {
    if (this.user.role == 'admin') {
      return permissionSettings
    }
    else if (this.user.role == 'manager') {
      return permissionSettings.filter(p => p.key != 'manager')
    }
  }
  getTittleNotification(itemType: any, itemId: any, typeNotify: any) {
    let obj: any = {}
    if (itemType != undefined && itemId != undefined && typeNotify != undefined) {
      if (itemType == ToolType.Jobs_Tools) {
        obj = this.jobDashboardTools.items.find(i => i.itemId == itemId).emailNotification.find(e => e.type == typeNotify)
      }
      if (itemType == ToolType.Setting_Tools) {
        obj = this.othersTools.items.find(i => i.itemId == itemId).emailNotification.find(e => e.type == typeNotify)
      }
    }
    return obj
  }
  getTittleSmsNotification(itemType: any, itemId: any, typeNotify: any) {
    let obj: any = {}
    if (itemType != undefined && itemId != undefined && typeNotify != undefined) {
      if (itemType == ToolType.Jobs_Tools) {
        obj = this.jobDashboardTools.items.find(i => i.itemId == itemId).SmsNotification.find(e => e.type == typeNotify)
      }
      if (itemType == ToolType.Setting_Tools) {
        obj = this.othersTools.items.find(i => i.itemId == itemId).SmsNotification.find(e => e.type == typeNotify)
      }
    }
    return obj
  }
  getPreviewObject(itemType: any, itemId: any) {
    // console.log(itemType)
    // console.log(itemId)
    // console.log(typeNotify)
    let obj: any = {}
    if (itemType != undefined) {
      if (itemType == ToolType.Jobs_Tools) {
        obj = this.jobDashboardTools.items.find(d => d.itemId == itemId)
      }
      if (itemType == ToolType.Setting_Tools) {
        obj = this.othersTools.items.find(o => o.itemId == itemId)
      }
      if (itemType == ToolType.Dashboard_Tools) {
        obj = this.homePageTools.items.find(h => h.itemId == itemId)
      }
    }
    if (obj == undefined || obj == null) {
      obj = {
        preview: {
          tittle: "About this Add-In",
          description: 'Coming Soon'
        }
      }
    }
    return obj.preview
  }
  checkItemInCustomer(toolType: number, itemId: number, role: string) {
    if (toolType == ToolType.Jobs_Tools && itemId == 2 && role == 'customer' || toolType == ToolType.Jobs_Tools && itemId == 3 && role == 'customer'
      || toolType == ToolType.Jobs_Tools && itemId == 4 && role == 'customer' || toolType == ToolType.Setting_Tools && itemId == 6 ||
      toolType == ToolType.Jobs_Tools && itemId == ToolJobsEditItem.jobInformation && role == 'customer'
      || toolType == ToolType.Jobs_Tools && itemId == ToolJobsEditItem.jobInformation && role == '3rdparty'
      || toolType == ToolType.Jobs_Tools && itemId == ToolJobsEditItem.jobStatus && role == 'customer'
      || toolType == ToolType.Jobs_Tools && itemId == ToolJobsEditItem.assignedCustomers && role == 'customer'
      || toolType == ToolType.Jobs_Tools && itemId == ToolJobsEditItem.assignedUsers && role == 'customer') {
      this.isCanEdit = false
    }
    else {
      this.isCanEdit = true
    }
  }
  openTagModal(planTool: number, companyPlan: number) {
    if (planTool > companyPlan) {

    }
    else {
      this.tagModal.show()
    }
  }
  openTagSettingToolModal(planTool: number, companyPlan: number) {
    if (planTool > companyPlan) {

    }
    else {
      this.tagSettingsModal.show()
    }
  }
  // override functions
  getAllPersonInCompanies(item: any, toolType: number, itemId: number) {
    this.bufferItem = item
    this.bufferRoles = item.permissionSettings
    // console.log(this.bufferItem)
    this.bufferToolType = toolType
    this.bufferItemId = itemId
    if (toolType == ToolType.Dashboard_Tools || toolType == ToolType.Setting_Tools && itemId == ToolSettingEditItem.knowledge_Base) {
      this.isOverrideEdit = false
    }
    else {
      this.isOverrideEdit = true
    }
    this._personService.getAllPersonInCompanies(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.p1 = 1
        this.p2 = 1
        this.isExistsCustomer = true
        // console.log('this.allUsers',response.data.filter(u => u.role == 'teamleader' ))
        this.allUsers = response.data.filter(u => u.role != 'admin' && u.role != 'customer')


        if (this.user.role == 'manager') {
          this.allUsers = this.allUsers.filter(u => u.role != 'manager')
        }
        this.allCustomer = response.data.filter(c => c.type == 'customer')
        let indexCustomer = this.bufferRoles.findIndex(r => r.key == 'customer')
        let indexThirdParty = this.bufferRoles.findIndex(r => r.key == '3rdparty')
        if (indexCustomer < 0) {
          this.isExistsCustomer = false
          // this.allUsers = this.allUsers.filter(a => a.type != 'customer')
        }
        if (indexThirdParty < 0) {
          this.isExistsCustomer = false
          this.allUsers = this.allUsers.filter(a => a.role != '3rdparty')
        }
        this.overridePermissionModal.show()
      }
    })
  }
  getValuePermission(userId: string, role: string) {
    let buff: any
    if (role != 'customer') {
      buff = this.allUsers.find(u => u.id == userId).permissionList
    }
    else {
      buff = this.allCustomer.find(u => u.id == userId).permissionList
    }
    if (buff != undefined) {
      let override = buff.find(p => p.itemId == this.bufferItemId && p.toolType == this.bufferToolType)
      if (override != undefined) {
        this.isHasPermission = false
        return override.overridePermission
      }
      else {
        this.isHasPermission = true
      }
    }
    else {
      this.isHasPermission = true
    }
    // return null
  }
  changeUserPermission(value: any, userId: string) {
    // console.log(value)
    // console.log(userId)
    // console.log(this.bufferItem)
    let model = {
      overridePermission: Number(value),
      itemId: this.bufferItem.itemId,
      itemName: this.bufferItem.itemName,
      toolType: this.bufferToolType
    }
    // console.log(model)
    this._personService.updatePersonPermission(userId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toasterCustomService.success('', 'Permission Successfully Changed, This settings will be a default')
      }
    })
  }
  getDefaultPermission(role: string) {
    let defaultRole = 'Non'
    let buffRole = this.bufferRoles.find(r => r.key == role)
    if (buffRole != null || buffRole != undefined) {
      switch (buffRole.value) {
        case 0:
          {
            defaultRole = 'No Access';
            break;
          }
        case 1:
          {
            defaultRole = 'Can Edit';
            break;
          }
        case 2:
          {
            defaultRole = 'View Only';
            break;
          }
        default: {
          defaultRole = 'No Access';
          break
        }
      }
    }
    return defaultRole
  }
  restPagination(event) {
    this.p1 = 1
  }
  restPagination1(event) {
    this.p2 = 1
  }
  filterToolsPlan(value: any) {
    // console.log(this.categoryCompany)
    if (Number(value) == 4) {
      this.tools = this.globalTools
    }
    else {
      let bufferItems: any = []
      for (let i = 0; i < this.globalTools.length; i++) {
        bufferItems[i] = Object.assign({}, this.globalTools[i])
        bufferItems[i].items = []
        for (let j = 0; j < this.globalTools[i].items.length; j++) {
          if (this.globalTools[i].items[j].toolCategory.includes(Number(this.user.categoryCompany)) && this.globalTools[i].items[j].planName == Number(value)) {
            bufferItems[i].items.push(this.globalTools[i].items[j])
          }
        }
      }
      this.tools = bufferItems
      this.p = [1];
    }
  }
  filterToolsCategory(value: any) {
    // console.log(value)
    let bufferItems: any = []
    for (let i = 0; i < this.globalTools.length; i++) {
      bufferItems[i] = Object.assign({}, this.globalTools[i])
      bufferItems[i].items = []
      for (let j = 0; j < this.globalTools[i].items.length; j++) {
        if (this.globalTools[i].items[j].toolCategory.includes(Number(value))) {
          bufferItems[i].items.push(this.globalTools[i].items[j])
        }
      }
    }
    this.tools = bufferItems
    this.p = [1];
  }
  changeTermsCondition(event) {
    console.log(event)
  }
  getCompanyTermAndCondition() {
    this._companyService.getCompanyTermAndCondition(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.termsCondition = response.data
      }
    })
  }
  updateCompanyTermAndCondition() {
    let TextArea: any = (document.getElementById('terms_Id') as HTMLInputElement)
    console.log(TextArea)
    // let view: any = TextArea.value
    let model: string = TextArea.value
    model = model.replace(/<</g, '&lt;&lt;')
    model = model.replace(/>>/g, '&gt;&gt;')
    model = model.replace(/[\n]+/g, '&#013;')
    model = model.replace(/[\n]+/g, '&#013;')
    console.log(model)
    let modelUpdated = {
      termAndCondition: model
    }
    this._companyService.updateCompanyTermAndCondition(this.user.companyId, modelUpdated).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.termsCondition = response.data
      }
    })
  }
  updateCompanyContactEmail() {
    this._companyService.updateCompanyContactEmail(this.user.companyId, this.getEmailWithoutSpace(this.emailReply)).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toasterCustomService.success('', 'Reply email successfully added')
      }
    })
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  getCompanyContactEmail() {
    this._companyService.getCompanyContactEmail(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.emailReply = response.data
      }
    })
  }
  ngOnDestroy(){
   localStorage.removeItem('selectedIndex')
  }
}
