import { Router } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { error } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl
  jwtHelper = new JwtHelperService();
  // private userSubject: BehaviorSubject<any>;
  // public user: Observable<any>;
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    // this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    // this.user = this.userSubject.asObservable();
  }
  // public get userValue(): any {
  //   return this.userSubject.value;
  // }
  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: ResponseModel) => {
        localStorage.setItem('user', JSON.stringify(response.data))
        return response
      }
      ), catchError(this.errorHandler)
    )
  }
  loginToAnotherCompany(model: any) {
    return this.http.post(this.baseUrl + 'switch', model).pipe(
      map(
        (response: ResponseModel) => {
          localStorage.setItem('user', JSON.stringify(response.data))
          return response
        }
      )
    )
  }
  RefreshToken() {
    let user = JSON.parse(localStorage.getItem('user'))
    return this.http.get(this.baseUrl + 'RefreshToken/' + user.companyId + '/' + user.uid)
  }
  passwordResetEmail(email: any) {
    return this.http.get(this.baseUrl + 'ResetPassword/' + email)
  }
  isEmailExists(email) {
    return this.http.get(this.baseUrl + 'isEmailUserRestExists/' + email)
  }
  isEmailUserCompanyExists(email) {
    return this.http.get(this.baseUrl + 'isEmailUserCompanyExists/' + email)
  }
  LoggedIn() {
    let user: any = JSON.parse(localStorage.getItem('user'))
    // console.log(" i am Token from logIn :" + this.jwtHelper.isTokenExpired(localStorage.getItem(user.token)));
    // console.log(user.token)
    // return !this.jwtHelper.isTokenExpired(localStorage.getItem(user.token));
  }
  errorHandler(error: any): Observable<any> {
    return null;
  }
}
