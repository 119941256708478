import { ToasterCustomService } from './../../../../../core/_services/toaster-service/ToasterCustomService.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolType } from 'src/app/models/tools-model/ToolType';

@Component({
  selector: 'app-progress-status',
  templateUrl: './progress-status.component.html',
  styleUrls: ['./progress-status.component.scss']
})
export class ProgressStatusComponent implements OnInit {
  statusProgressModel:any = {}
  editStatusProgressModel:any = {}
  user:any ={}
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  jobsTools: any = {}
  jobId: any
  jobDetailsId: string
 statusProgressArr: any = []
 uniqId = ''
 isCustomerEmail = Is_Customer_Email.Not_Send_Email
 indexDelete = 0
 @ViewChild('progressSendModal') progressSendModal: ModalDirective;
  constructor(
    private route: ActivatedRoute,
    public _jobsService: JobsService,
    private _jobDetailsService: JobDetailsService,
    public _toolsService: ToolsService,
    public _toaster: ToasterCustomService
  ) { }

  ngOnInit() {
  this.jobId = this.route.snapshot.paramMap.get('id');
    this.user = JSON.parse(localStorage.getItem('user'))
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
      }
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        if (this.user.role == 'admin') {
        }
        if (this.user.role == "admin") {
          this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.progressStatus)
          if(this.userPermission.itemStatus == 'Active') {
            this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
           this.getStatusProgress()
          }
        }
        if (this.user.role != 'admin' && this.user.role != 'manager') {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.progressStatus, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getStatusProgress()
          }
        }
        if (this.user.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.progressStatus, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getStatusProgress()
          }
        }
      }
    }, error => {
      console.log(error)
    })
  }
  storeItem(item:any) {
    // console.log(item)
    this.uniqId = item.id
    this.editStatusProgressModel = Object.assign({},item)
  }
  checkItemPlan(){
    if(this._jobsService.getToolPlan(this.items,ToolJobsEditItem.progressStatus) > this.user.companyPlan){
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getStatusProgress() {
    this._jobDetailsService.getJobDetailsByIdItem(this.jobId, ToolJobsEditItem.progressStatus).subscribe((response: ResponseModel) => {
      if (response.success) {
          this.statusProgressArr = response.data.progressStatus
        this.jobDetailsId = response.data.id
        if(this.statusProgressArr == undefined || this.statusProgressArr == null){
        this.statusProgressArr = []
      }
      this.checkItemPlan()
      }
    })
  }
  checkAddSendEmail() {
    let notify = this._toolsService.getEmailNotification(this.user.role, this.jobsTools, ToolJobsEditItem.progressStatus, EmailNotification.Email_Notification_Progress_Status_Added)
    if (notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.progressSendModal.show()
      this.isCustomerEmail = Is_Customer_Email.Send_Email
    }
    else {
      this.addStatusProgress()
    }
  }
  addStatusProgress(){
    this.statusProgressModel.date = new Date()
    this.statusProgressModel.addBy = this.user.displayName
    this.statusProgressModel.id = (this.statusProgressArr.length) + 1
    this.statusProgressArr.push(this.statusProgressModel)
    this._jobDetailsService.updateJobDetails(this.statusProgressArr, this.jobId, ToolJobsEditItem.progressStatus, this.isCustomerEmail).subscribe((response: ResponseModel) => {
    if(response.success){
      this.statusProgressModel ={}
      this._toaster.success('','Progress Status Successfully Added')
    }
    }, error => {
      console.log(error)
    })
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  updateStatusProgress(){
    // console.log(this.editStatusProgressModel)
    let index = this.statusProgressArr.findIndex(d => d.id == this.uniqId);
    let editItem:any = Object.assign({}, this.editStatusProgressModel)
    editItem.date = new Date()
    editItem.id =this.uniqId
    this.statusProgressArr[index] = editItem
    this._jobDetailsService.updateJobDetails(this.statusProgressArr, this.jobId, ToolJobsEditItem.progressStatus, this.isCustomerEmail).subscribe((response: ResponseModel) => {
    if(response.success){
      this.statusProgressArr = response.data.progressStatus
      this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
      this._toaster.success('','Progress Status Successfully Updated')
    }
    }, error => {
      console.log(error)
    })
  }
  storeIndex(i) {
    this.indexDelete = i
    // console.log(i)
  }
  deleteStatusProgress() {
    // if (this.statusProgressArr.length == 1) {
    // }
    // else {
      this.statusProgressArr.splice(this.indexDelete, 1);
      this._jobDetailsService.updateJobDetails(this.statusProgressArr, this.jobId, ToolJobsEditItem.progressStatus, 0).subscribe((response: ResponseModel) => {
        if(response.success){
          this.statusProgressArr = response.data.progressStatus
          this._toaster.success('','Progress Status Successfully Deleted')
        }
      }, error => {
        // console.log(error)
      })
    // }
  }
}
