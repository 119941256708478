import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient,) { }
  updateHub(model: any, jobId) {
    let itemId = ToolJobsEditItem.inventory
    return this.http.put(this.baseUrl + 'updateHub/' + jobId + '/' + itemId, model)
  }
}
