<div class="card">
    <div class="tittle-section">
        <span (click)="redirection()"><i class="fas fa-arrow-left pr-2" ></i></span>
        <p>Update a Vehicle</p>
    </div>
    <div class="section-stepper">
        <form [formGroup]="addVehicleFormGroup">
            <mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]="getActiveStep()">
                <mat-step state="edit">
                    <ng-template matStepLabel>Vehicle information <i class="required italic-required">(required)</i></ng-template>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Vehicle Make</mat-label>
                            <input matInput required type="text" formControlName="vehicleType">
                        </mat-form-field>
                        <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.vehicleType.errors.required">Vehicle Make is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Plate / #VIN( Last 4 Digits)</mat-label>
                            <input matInput required type="text" class="text-uppercase" formControlName="plateNumber" (keyup)="isPlateNumberExist()">
                        </mat-form-field>
                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                            <div *ngIf="f.plateNumber.errors.pattern">plate number only characters & numbers</div>
                        </div>
                        <div *ngIf="isExists" class="alert alert-danger" role="alert">Plate Number is Exists.</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Model</mat-label>
                            <input matInput type="text" formControlName="vehicleModel">
                        </mat-form-field>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input matInput type="number" formControlName="phone">
                        </mat-form-field>
                    </div> -->
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <ngx-mat-intl-tel-input formControlName="phone" [preferredCountries]="['us', 'gb']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" (countryChanged)="yourComponentMethodToTreatyCountryChangedEvent($event)">
                            </ngx-mat-intl-tel-input>
                        </mat-form-field>
                        <mat-error *ngIf="f.phone?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Condition</mat-label>
                            <textarea rows="1" cols="26" matInput type="text" formControlName="condition"> </textarea>
                        </mat-form-field>
                    </div>
                    <button matStepperNext type="button" class="btn btn-sm btn-success">Next</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid || isExists || f.phone?.errors?.validatePhoneNumber" (click)="updateVehicleInformation()">Update a Car Profile</button>
                </mat-step>
                <mat-step state="edit">
                    <ng-template matStepLabel>QR Code <i class="italic-optional">(Optional, It will provided at the car wash site)</i></ng-template>
                    <div class="tittle-section-qr">
                        <p>QR Code</p>
                        <div class="btn-add-new">
                            <span class="badge badge-success" (click)="assignQR()" *ngIf="vehicle.id">
                              <a *ngIf="!vehicle.qrCodeNumber">Assign</a>
                              <a *ngIf="vehicle.qrCodeNumber">Re-Assign</a>
                      </span>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <input type="text" class="form-control qr-input" readonly placeholder="QR Code Id" formControlName="qrCodeId">
                    </div> -->
                    <div>
                        <button matStepperPrevious type="button" class="btn btn-sm btn-primary">Back</button>
                        <button matStepperNext type="button" class="btn btn-sm btn-success">Next</button>
                    </div>
                </mat-step>
                <!-- <mat-step state="edit">
                    <ng-template matStepLabel>Customer Information <i class="italic-optional">(optinal)</i></ng-template>
                    <div class="form-group">
                        <label for="firstName">First Name<span class="required">* </span> :</label>
                        <input type="text" id="firstName" class="form-control" placeholder="Customer Fisrt Name" formControlName="firstName">
                        <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lastName">Last Name<span class="required">* </span> :</label>
                        <input type="text" id="lastName" class="form-control" placeholder="Customer Last name" formControlName="lastName">
                        <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">E-mail<span class="required">* </span> :</label>
                        <input type="text" id="email" *ngIf="vehicle.customer" class="form-control" readonly placeholder="Customer E-mail" formControlName="email">
                        <input type="text" id="email" *ngIf="!vehicle.customer" class="form-control" placeholder="Customer E-mail" formControlName="email">
                        <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email not valid</div>
                        </div>
                    </div>
                    <button matStepperPrevious type="button" class="btn btn-sm btn-primary">Back</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" *ngIf="!vehicle.customer" [disabled]="f.firstName.invalid || f.lastName.invalid || f.email.invalid  " (click)="addCustomer()">Add customer Information</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" *ngIf="vehicle.customer" [disabled]="f.firstName.invalid || f.lastName.invalid || f.email.invalid  " (click)="updateCustomerInformation(vehicle.customer.id)">Update</button>
                    <button matStepperNext type="button" class="btn btn-sm btn-success" *ngIf="vehicle.customer">Next</button>
                </mat-step> -->
                <mat-step state="edit">
                    <ng-template matStepLabel>Membership <i class="italic-optional">(Optional)</i></ng-template>
                    <span class="no-vehicle" *ngIf="!vehicle.id">First, you MUST add vehicle information.</span>
                    <!-- check box section -->
                    <div class="form-group">
                        <div class="check-section" *ngIf="vehicle.id && !vehicle.isMemberShip || vehicle.id && vehicle.isMemberShip && vehicle.isSubscriptionEnd">
                            <!-- Card checked radio -->
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Card" (change)="handleChange($event)" id="flexRadioDefault2" [checked]="paymentType == paymentTypeEnum.card" />
                                <label class="form-check-label" for="flexRadioDefault2">Card </label>
                            </div>
                            <!-- Cash checked radio -->
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Cash" (change)="handleChange($event)" id="flexRadioDefault1" [checked]="paymentType == paymentTypeEnum.cash" />
                                <label class="form-check-label" for="flexRadioDefault1">Cash </label>
                            </div>
                        </div>
                        <!-- cash Payment -->
                        <div class="cash-month" *ngIf="vehicle.id && !vehicle.isMemberShip && paymentType == paymentTypeEnum.card || vehicle.isSubscriptionEnd && vehicle.stripeObj && paymentType == paymentTypeEnum.card">
                            <span class="pr-2">Expires aftar </span>
                            <select class="browser-default custom-select form-control" (change)="chooseCountMonth($event.target.value)">
                              <option *ngFor="let month of monthsCard" value="{{month}}"> {{month}} </option>
                             </select>
                            <span class="pl-2"> Months</span>
                        </div>
                        <!-- <button class="btn btn-sm btn-success mt-3" *ngIf="paymentType == paymentTypeEnum.card_exists && vehicle.id" (click)="storeVehicleId(vehicle.id);customerCashModal.show()">Create Cash Account (One time only)</button> -->
                        <!-- <label for="email" *ngIf="paymentType == paymentTypeEnum.card && vehicle.id">E-mail <span class="required">* </span>  :</label> -->
                        <!-- <input type="text" id="email" class="form-control" readonly *ngIf="paymentType == paymentTypeEnum.card && vehicle.id && vehicle.customer" placeholder="Customer E-mail" formControlName="email"> -->
                        <!-- <input type="text" id="email" class="form-control" *ngIf="paymentType == paymentTypeEnum.card && vehicle.id && !vehicle.customer" placeholder="Customer E-mail" formControlName="email"> -->
                        <!-- <div *ngIf="f.email.touched && f.email.invalid && paymentType == paymentTypeEnum.card && vehicle.id" class="alert alert-danger" role="alert">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Email not valid</div>
                        </div> -->
                    </div>
                    <div *ngIf="paymentType == paymentTypeEnum.card && vehicle.id ">
                        <!-- <small class="required" *ngIf="isHaveSecretKey">Make sure that the payment gate is integrated in the company account.<br></small> -->
                        <!-- <button class="btn btn-sm btn-primary" *ngIf="paymentType == paymentTypeEnum.card && !vehicle.isMemberShip" [disabled]="!vehicle.id || f.email.invalid " (click)="createMemberShip(vehicle.id)">Create Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button> -->
                        <button class="btn btn-sm btn-primary" *ngIf="!vehicle.isMemberShip  " [disabled]="!vehicle.id" (click)="createMemberShipCash(vehicle.id)">Create Subscription <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                        <button class="btn btn-sm btn-primary" *ngIf="vehicle.isMemberShip &&  !vehicle.isSubscriptionEnd" (click)="checkMobile()">Edit/View Subscription <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                        <button class="btn btn-sm btn-primary" *ngIf="vehicle.isMemberShip &&  vehicle.isSubscriptionEnd && vehicle.stripeObj " [disabled]="!vehicle.id " (click)="createMemberShipCash(vehicle.id);renewMembership()">Renew Subscription <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                    </div>
                    <mat-tab-group [disableRipple]="false" *ngIf="vehicle.id && paymentType == paymentTypeEnum.cash">
                        <mat-tab label="Membership">
                            <!-- cash payment section -->
                            <div class="cash-payment">
                                <!-- <h6>Membership period</h6> -->
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12">
                                        <ul class="list-group" *ngFor="let product of products;let i= index">
                                            <li (click)="storeBuffProductPrice(product);selectItem(i)" [ngClass]="{'selected-item': selectedID == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action ">
                                                <span>
                                                  <input type="radio" id="product.id"  name="product.id" value="product?.name" [checked]="selectedID == i"> {{product.name}}
                                                </span>
                                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{getPrice(product.price)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 left-statistic">
                                        <!-- <div class="card"> -->
                                        <div class="form-group date ">
                                            <mat-form-field class="example-full-width mt-0" appearance="outline">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="picker" [value]="vehicle.cashMembershipInfo?.startDate" (dateChange)="addEvent('change', $event)">
                                                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                            <div class="form-group date">
                                                <mat-form-field class="example-full-width  mt-0" appearance="outline">
                                                    <mat-label>End Date</mat-label>
                                                    <input matInput [matDatepicker]="picker1" [value]="vehicle.cashMembershipInfo?.endDate" (dateChange)="addEvent1('change', $event)">
                                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <span class="alert alert-danger error-date" *ngIf="isDateCheck">The end date must be after</span>
                                        <!-- <span>Total Days 00</span> -->
                                        <div class="date-statistic">
                                            <strong>Total days:
                                            <div class="input-group ">
                                              <input type="text" class="form-control " name="diffInDays" [value]="diffInDays" readonly >
                                            </div>
                                          </strong>
                                            <strong>Total due:
                                    <div class="input-group ">
                                      <input type="text" class="form-control " [value]="getTotalDuePrice()" readonly >
                                      <div class="input-group-append">
                                        <span class="input-group-text">({{getCurrency()}})</span>
                                      </div>
                                    </div>
                                  </strong>
                                            <strong><span class="success">Received:</span>
                                    <div class="input-group ">
                                    <input type="number" class="form-control "  min="0"  [value]="receivedAmount" (input)="accountReceived($event.target.value)">
                                    <div class="input-group-append">
                                      <span class="input-group-text">({{getCurrency()}})</span>
                                    </div>
                                  </div>
                                  </strong>
                                            <strong><span class="required">Cash Back: </span>
                                      <div class="input-group ">
                                    <input type="text" class="form-control" [value]="cashBackAmount" readonly >
                                    <div class="input-group-append">
                                      <span class="input-group-text">({{getCurrency()}})</span>
                                    </div>
                                  </div>
                                </strong>
                                        </div>
                                        <span class="alert alert-danger" *ngIf="isBack">The received amount must be equal to or greater than the due amount.                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-cash">
                                <button class="btn btn-primary" [disabled]="isBack  || isDateCheck" type="button" (click)="summaryCashModal.show()">Create Cash Membership </button>
                            </div>
                        </mat-tab>
                        <mat-tab label="Packages">
                            <div class="cash-payment">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-12">
                                        <ul class="list-group" *ngFor="let product of packageProducts;let i= index">
                                            <li (click)="storeBuffProductPackagingPrice(product);selectItemPackage(i)" [ngClass]="{'selected-item': selectedPackageIndex == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                                <span>
                                                  <input type="radio" id="product.id"  name="product?.id" value="product?.name" [checked]="selectedPackageIndex == i">
                                                   {{product.name}}
                                                  </span>
                                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{getPrice(product.price)}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 left-statistic">
                                        <div class="date-statistic">
                                            <strong>Total due:
                                              <div class="input-group ">
                                                <input type="text" class="form-control " [value]="pricePackageProduct" readonly >
                                                <div class="input-group-append">
                                                  <span class="input-group-text">({{getCurrency()}})</span>
                                                </div>
                                              </div>
                                           </strong>
                                            <strong> <span class="success">Received: </span>
                                                <div class="input-group ">
                                                <input type="number" class="form-control " [value]="receivedAmountPackageProduct"  min="0" (input)="accountReceivedPackaging($event.target.value)">
                                                <div class="input-group-append">
                                                  <span class="input-group-text">({{getCurrency()}})</span>
                                                </div>
                                              </div>
                                         </strong>
                                            <strong><span class="required">Cash Back: </span>
                                                <div class="input-group ">
                                                <input type="text" class="form-control"  min="0" [value]="cashBackPackageProduct" readonly >
                                                <div class="input-group-append">
                                                  <span class="input-group-text">({{getCurrency()}})</span>
                                                </div>
                                              </div>
                                          </strong>
                                        </div>
                                        <span class="alert alert-danger " *ngIf="isBack1">The received amount must be equal to or greater than the due amount.                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-cash" *ngIf="vehicle.id && paymentType == paymentTypeEnum.cash">
                                <button class="btn btn-primary" [disabled]="isBack1" type="button" (click)="summaryCashPackageModal.show()">Create Cash Membership </button>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-step>
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
            </mat-vertical-stepper>
        </form>
    </div>
</div>
<!--  Warning key  -->
<div mdbModal #warningKeyModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warning</h5>
                    <button type="button" class="close" (click)="warningKeyModal.hide()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                    <p>The payment gate is NOT integrated in the company account.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Cash key  -->
<div mdbModal #warningCashModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningCashModal.hide()" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p>Please add an email for the cash membership first. <br> Menu > Company account> Stripe integration.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningCashModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Email Customer Cash modal -->
<div id="myModal" mdbModal #customerCashModal="mdbModal" class="modal cashModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Add a stripe cash account (One time only)</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="customerCashModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body strip_key-body">
                <ol>
                    <li>Create a regular new email (must be different than the one stripe account created with).</li>
                    <li>Add the email below.
                        <div class="form-group mt-2">
                            <input name="customerEmailCash" id="customerEmailCash" class="form-control" placeholder="Email" [(ngModel)]="customerEmailCash">
                        </div>
                    </li>
                    <li>Click [Create cash Membership].</li>
                </ol>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-danger" (click)="customerCashModal.hide()">Cancel</button>
                <button class="btn btn-sm btn-success" (click)="createMemberShip(bufferVehicleId);customerCashModal.hide()">Create Cash Membership</button>
            </div>
        </div>
    </div>
</div>
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='false'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #warningKeyStripModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningKeyStripModal.hide()" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-left">
                    <p>Ensure that the payment gate is integrated into the company account: <br> Accordev Home Page> Menu > Company account> Stripe integration.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyStripModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="scanWebModal" mdbModal #scanWebModal="mdbModal" class="modal tag-modal scan-web-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Scan</h5>
                    <button type="button" class="close" (click)="scanWebModal.hide();handle(action, 'stop')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body px-0 text-center">
                    <!-- <div class="container"> -->
                    <!-- ngx-scanner-qrcode -->
                    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event)" (error)="onError($event)"></ngx-scanner-qrcode>
                    <!-- data  -->
                    <!-- <p class="data">{{ (action.data | async)['data'] }}</p> -->
                    <!-- Loading -->
                    <span *ngIf="action.isLoading">⌛ Loading...</span> <br>
                    <!-- <button class="btn btn-sm" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, 'start')">Start</button> -->
                    <!-- <button class="btn btn-sm" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, 'stop')" data-dismiss="modal">Stop</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- summaryCashModal-->
<div mdbModal #summaryCashModal="mdbModal" class="modal summary-cash-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Summary</h5>
                    <button type="button" class="close" (click)="summaryCashModal.hide()" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body summary-modal">
                    <div class="text-left ">
                        <span>Your selected paln is: </span> <strong>{{buffProduct?.name}} / {{buffProduct?.price / 100}} ({{getCurrency()}})</strong>
                    </div>
                    <div class="date-statistic-modal">
                        <strong>Total days: {{diffInDays}}</strong>
                        <strong>Total due: {{getTotalDuePrice()}} ({{getCurrency()}})</strong>
                        <strong class="success">Received: {{receivedAmount}} ({{getCurrency()}})</strong>
                        <strong class="required">Cash Back: {{cashBackAmount}} ({{getCurrency()}}) </strong>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button class="btn btn-success" (click)="createNewCashSubscription()">Create Cash Subscription</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- summaryCashModal-->
<div mdbModal #summaryCashPackageModal="mdbModal" class="modal summary-cash-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Summary</h5>
                    <button type="button" class="close" (click)="summaryCashPackageModal.hide()" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body summary-modal">
                    <div class="text-left ">
                        <span>Your selected paln is: </span> <strong>{{buffProductPackaging?.name}} / {{buffProductPackaging?.price / 100}} ({{getCurrency()}})</strong>
                    </div>
                    <div class="date-statistic-modal">
                        <strong>Total due: {{pricePackageProduct}} ({{getCurrency()}})</strong>
                        <strong class="success">Received: {{receivedAmountPackageProduct}} ({{getCurrency()}})</strong>
                        <strong class="required">Cash Back: {{cashBackPackageProduct}} ({{getCurrency()}}) </strong>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button class="btn btn-success" (click)="createNewCashPackageSubscription()">Create Cash Subscription</button>
                </div>
            </div>
        </div>
    </div>
</div>