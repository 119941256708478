export enum HistoryCarWash {
  Add_Vehicle,
  Update_Vehicle,
  Create_Membership,
  Check_In_Action,
  Check_Out_Action,
  Force_Update_Plan,
  Update_Vehicle_Subscription,
  Cancel_Vehicle_Subscription,
  Assign_Qr_Code,
  Cash_Subscription,
}
