import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class AsyncEmailValidator implements AsyncValidator {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> {
    let model = {
      companyEmail: ctrl.value
    }
    if (ctrl.value) {
      return this.http.post<{ status: string }>(this.baseUrl + 'isEmailCompanyExists', model)
        .toPromise()
        .then((result: any) => {
          if (!result.success) {
            return { invalid: true };
          } else {
            // status is valid
            return null;
          }
        });
    } else {
      return Promise.resolve({ invalid: true });
    }
  }
}
