<div class="signature section-tool-job" *ngIf="userPermission.value != statusActive.InActive  ">
    <div class="tittle-section">
        <p>
            <!-- <i class="fas fa-signature"></i> -->
            <i class="uil uil-file-medical-alt uil-medium pr-1"></i> Signature(s)
        </p>
        <!-- <button *ngIf="userPermission.value == statusActive.Active  " class="btn " (click)="addSignatureModal.show()">
          <i class="far fa-plus-square"></i> Add New
       </button> -->

        <div class="btn-add-new" *ngIf="userPermission.value == statusActive.Active  " (click)="addSignatureModal.show()">
            <span class="badge badge-success">
          <i class="far fa-plus-square"></i> Add New
          </span>
        </div>

    </div>
    <div class="inner-section" *ngIf="userPermission.value == statusActive.Active || userPermission.value == statusActive.Read_Only  ">
        <div class="ant-empty-image" *ngIf="signatures.length <= 0 ">
            <img src="./assets/img/empty.svg" alt="empty-img">
            <span>No Data</span>
        </div>
        <mat-accordion class="example-headers-align" multi *ngFor="let item of signatures | paginate: {id:'signatu', itemsPerPage: 3, currentPage: p  }">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    {{item.status}} Signature By <span class="pl-1">({{item.name}})</span>
                    <div class="title-info" *ngIf="model != null">
                        <span>{{item.date | customDatePipe}}</span>
                    </div>
                </mat-expansion-panel-header>
                <div class="card ">
                    <div class="row">
                        <div class="col-12">
                            <div class="item">
                                <div class="date">
                                    <label>{{item.name}}
                                  <span class="role" *ngIf="item.role == 'TeamLader'">
                                    (Team Leader)
                                  </span>
                                  <span class="role" *ngIf="item.role != 'TeamLader'">
                                    ({{item.role}})
                                  </span>
                                </label>
                                    <span>{{item.date | customDatePipe}}</span>
                                </div>
                                <img *ngIf="item.image" [src]="item.image" alt="signature-image">
                            </div>
                        </div>
                        <div class="col-12 delete-btn" *ngIf="role === 'admin'">
                            <button class="btn btn-sm btn-danger" (click)="storeItem(item);deleteModal.show()">Delete</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="text-center mt-4" *ngIf="signatures.length > 0 ">
            <pagination-controls id="signatu" (pageChange)="p = $event "></pagination-controls>
        </div>
    </div>
</div>

<!--Signature Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary"><small>Delete Signature?</small></p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light " (click)="deleteModal.hide()">Cancel</button>
                    <button type="button " class="btn btn-sm btn-danger " (click)="deleteSignature();deleteModal.hide()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div mdbModal #addSignatureModal="mdbModal" class="modal signatureModal fade">
    <div class="modal-dialog modal-md">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New Signature</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addSignatureModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form #form='ngForm'>
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="jobStatus">Job Status:</label>
                            </div>
                            <div class="col-sm-12">
                                <select class="browser-default custom-select" name="status" [(ngModel)]="model.status">
                                     <option *ngFor="let item of statusItems" [value]="item.status">{{item.status}}</option>
                                 </select>
                            </div>
                            <div class="col-sm-12 item">
                                <select class="browser-default custom-select text-capitalize" name="role" [(ngModel)]="model.role">
                                     <option class="text-capitalize" *ngFor="let item of roles" [value]="item.role">{{item.role}}</option>
                               </select>
                            </div>
                            <div class="col-sm-12 item">
                                <input type="text" placeholder="Enter Name *" required name="name" [(ngModel)]="model.name" class="form-control">
                            </div>
                            <div class="col-12 mt-3">
                                <div class="signature-pad">
                                    <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
                                    <div class="signature-pad-footer"></div>
                                    <div class="description">Sign above</div>
                                    <div class="signature-pad-actions">
                                        <button class="btn btn-sm btn-primary" (click)="clear()">clear</button>
                                        <button type="submit" class="btn btn-sm btn-success" [disabled]="!model.status || !model.name || isDrawImageSignature" (click)="addSignature();addSignatureModal.hide()">accept</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
