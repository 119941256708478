import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';
import { TrucksService } from '../_services/trucks/trucks.service';

@Injectable()

export class TruckByIdResolver implements Resolve<any[]>
{
  constructor(private _trucksService: TrucksService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    return this._trucksService.getTruckById(route.params['id']).pipe(
      catchError(error => {
        console.log(error)
        return of(null);
      })
    );
  }
}
