import { environment } from 'src/environments/environment.prod';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { PersonService } from './../../../../../core/_services/person/person.service';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { StepsService } from './../../../../../core/_services/steps/steps.service';
import { ToastrService } from 'ngx-toastr';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import { AfterViewInit, Component, OnInit, ViewChild ,ElementRef} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { AddressType } from 'src/app/models/person/AddressType';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import PlaceResult = google.maps.places.PlaceResult;
import { Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { AddressMapsService } from 'src/app/core/_services/address-maps/address-maps.service';
import { lang } from 'moment';
declare var google: any;
var map;
// import {} from '@types/googlemaps';
interface Stop {
  id: string,
  priority: string,
  addressOne: string,
  addressTow: string,
  city: string,
  zip: string,
  country: string,
  state: string,
  jobId: string
}
@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss']
})
export class StopsComponent implements OnInit,AfterViewInit {
  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  // map:any;
  isLinear = false;
  StopForm: FormGroup
  step: Stop = {} as Stop
  editStepModel: any = {}
  oldStep: Stop = {} as Stop
  letters: any = []
  uniqId
  jobDetails: JobDetails
  steps: any[] = []
  jobId: string
  stepsArr: any[] = []
  user: any
  p: number = 1
  stepText = ''
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  role: string
  numberPattern = ValidationModel.numberPattern
  jobsTools: any = {}
  stepObj: any = {}
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  stepBufferForm: NgForm
  defaultValue: any = {}
  // public appearance = Appearance;
  // for update
  @ViewChild('stepNotSendModal') stepNotSendModal: ModalDirective;
  @ViewChild('stepSendModal') stepSendModal: ModalDirective;
  // for add
  @ViewChild('addStepSendModal') addStepSendModal: ModalDirective;
  @ViewChild('addStepNotSendModal') addStepNotSendModal: ModalDirective;
  @ViewChild('notDeleteModal') notDeleteModal: ModalDirective;
  // auto
  // @Input() adressType: string;
  // @Output() setAddress: EventEmitter<any> = new EventEmitter();
  // @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  isEdit = false
  company:any = {}
  companyAddress:any = {}
  // autocompleteInput: string;
  @ViewChild('addressText') addressText: any;
  @ViewChild('addressText1') addressText1: any;
  baseUrlGoogle = environment.baseDirectionGoogle
  // google maps zoom level
  lat = 40.730610;
  lng = -73.935242;
  coordinates = new google.maps.LatLng(this.getTittleCompany().companyAddress.lat, this.getTittleCompany().companyAddress.lng);
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 12,
  };
  marker = new google.maps.Marker({
    position: this.coordinates,
    url  : `${this.baseUrlGoogle}${this.getTittleCompany().companyAddress.formatted_address}`,
    map: map,
    title : `Company Location: ${this.getTittleCompany().companyAddress.formatted_address}`
  });
  markers = [
    // {
    //   position: new google.maps.LatLng(40.73061, 73.935242),
    //   map: map,
    //   title: "Marker 1"
    // }
  ];

  getTittleCompany(){
    let  user = JSON.parse(localStorage.getItem('user'))
    this.company = user.companyData.find(c => c.companyId == user.companyId)
    this.companyAddress = `${this.company.companyAddress.addressOne}, ${this.company.companyAddress.city}, ${this.company.companyAddress.state} ${ this.company.companyAddress.zip}`
    // return `Company Location :${this.companyAddress}`
    return this.company
  }
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private _stepsService: StepsService,
    private _personService: PersonService,
    private route: ActivatedRoute,
    private _jobsService: JobsService,
    private _toolsService: ToolsService,
    private toaster: ToastrService,
    private socket: Socket,
  ) { }
  ngOnInit() {
    this.isEdit = false
    this.createStopForm()
    this.letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    this.user = JSON.parse(localStorage.getItem('user'))
    // this.company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    // this.marker.title = `${this.company.companyAddress.addressOne}, ${this.company.companyAddress.city}, ${this.company.companyAddress.state} ${this.company.companyAddress.zip},${this.company.companyAddress.country.name} `
    this.role = this.user.role
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.defaultValue = {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    this.stepsArr = [{
      id: this.user.id,
      name: 'Customer Address',
      type: AddressType.USER
    },
    {
      id: this.user.companyId,
      name: 'Comapny Address',
      type: AddressType.COMPANY
    }
    ]
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        if (response['jobs'].success) {
          this.items = response['jobs'].data.showItems
          if (this.role == 'admin') {
            this.userPermission = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolJobsEditItem.steps)
            if (this.userPermission.itemStatus == 'Active') {
              this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
            }
            this.getSteps()
          }
          if (this.role != 'admin' && this.role != 'manager') {
            this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.steps, this.user.id)
            if (this.userPermission.value != this.statusActive.InActive) {
              this.getSteps()
            }
          }
          if (this.role == "manager") {
            this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.steps, this.user.id)
            if (this.userPermission.value != this.statusActive.InActive) {
              this.getSteps()
            }
          }
        }
      }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Step_Delete, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this.getSteps()
      }
    });
  }
  mapInitializer() {
    map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    //Adding Click event to default marker
    this.marker.addListener("click", () => {
      const contentString =
      `${this.marker.getTitle()} <br> <br> <strong><a href="${this.marker.url}" target="_blank">Go to Location</a></strong>`
      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });
      // console.log(this.marker.url)
      // console.log(this.marker)
      // window.open(this.marker.url,'_blank')
      infoWindow.open(this.marker.getMap(), this.marker);
    });
    //Adding default marker to map
    this.marker.setMap(map);
    //Adding other markers
    this.loadAllMarkers();
   }
   loadAllMarkers(): void {
    this.markers.forEach((markerInfo,i) => {
      //Creating a new marker object
      const marker = new google.maps.Marker({
        ...markerInfo
      });
      const contentString =
      `${marker.getTitle()} <br>  <br> <strong><a href="${marker.url}" target="_blank">Go to Location</a> </strong>`
      //creating a new info window with markers info
      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });
      //Add click event to open info window on marker
      marker.addListener("click", () => {
        // console.log(marker.url)
        infoWindow.open(marker.getMap(), marker);
        // window.open(marker.url,'_blank')
      });
      //Adding marker to google map
      marker.setMap(map);
    });
  }
  checkItemPlan() {
    if (this._jobsService.getToolPlan(this.items, ToolJobsEditItem.steps) > this.user.companyPlan) {
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getSteps() {
    this._stepsService.getAllStepsByJob(this.jobId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.steps = response.data
        this.insertSetps(this.steps)
        this.checkItemPlan()
        this.getMarkers()
      }
    })
  }
  getMarkers(){
    for (let index = 0; index < this.steps.length; index++) {
      // this.getLocation(this.steps[index].zip,this.steps[index].city)
      this.markers.push({
        position: new google.maps.LatLng(this.steps[index].lat,this.steps[index].lng),
        map: map,
        url  : `${this.baseUrlGoogle}${this.companyAddress}&destination=${this.getAddress(this.steps[index])}`,
        title: `(Location ${this.steps[index].priority}) ${this.steps[index].formatted_address}`
      })
    }
    console.log(this.markers)
   this.mapInitializer()
  }
  getAddress(address){
    return `${address.addressOne}, ${address.city}, ${address.state} ${ address.zip}`
  }
  createStopForm() {
    this.StopForm = this.fb.group({
      priority: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTow: [''],
      city: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      country: [''],
      state: ['', Validators.required],
      lat: [''],
      lng: [''],
      formatted_address :[''],
      place_id : ['']
    })
  }
  get f() {
    return this.StopForm.controls
  }
  insertSetps(steps: any) {
    //  this.stepsArr = []
    for (let index = 0; index < steps.length; index++) {
      this.stepsArr.push({
        id: steps[index].id,
        name: 'Location ' + '(' + steps[index].priority + ') ' + steps[index].addressOne + ',' + steps[index].city + ',' + steps[index].state + '' + steps[index].zip,
        type: AddressType.STEP
      })
    }
  }
  selectAddressExists(id: any, isEdit: boolean) {
    let item = this.stepsArr.find(i => i.id == id)
    if (item.type == AddressType.STEP) {
      this._stepsService.getStepById(item.id, this.jobId).subscribe((response: ResponseModel) => {
        if (isEdit) {
          if (response.success) {
            this.editStepModel = response.data
            this.defaultValue = response.data.country
          }
        }
        else {
          if (response.success) {
            this.defaultValue = response.data.country
            this.StopForm.patchValue(response.data)
          }
        }
      })
    }
    else {
      this._personService.getAddressInfo(item.id, item.type).subscribe((response: ResponseModel) => {
        if (isEdit) {
          if (response.success) {
            this.editStepModel = response.data
            this.defaultValue = response.data.country
          }
        }
        else {
          if (response.success) {
            this.StopForm.patchValue(response.data)
            this.defaultValue = response.data.country
          }
        }
      })
    }

  }
  storeId(item) {
    this.uniqId = item.id
    this.editStepModel = Object.assign({}, item);
    this.oldStep = Object.assign({}, item);
    this.defaultValue = item.country
  }
  isEditFlag() {
    this.isEdit = true
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  checkEditSendEmail(stepForm: NgForm) {
    this.stepBufferForm = stepForm
    // let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.steps, EmailNotification.Email_Notification_Step_Add_Or_Changed)
    // console.log('notify', notify)
    // if (notify.roleStatus && notify.roleStatus == 'active' && notify.notifyStatus && notify.notifyStatus == 'Active') {
    //   this.stepSendModal.show()
    //   this.isCustomerEmail = Is_Customer_Email.Send_Email
    // }
    // else {
    this.stepNotSendModal.show()
    // }
  }
  checkAddSendEmail() {
    // let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.steps, EmailNotification.Email_Notification_Step_Add_Or_Changed)
    // console.log('notify', notify)
    // if (notify.roleStatus && notify.roleStatus == 'active' && notify.notifyStatus && notify.notifyStatus == 'Active') {
    //   this.addStepSendModal.show()
    //   this.isCustomerEmail = Is_Customer_Email.Send_Email
    // }
    // else {
    this.addStepNotSendModal.show()
    // }
  }
  resetAddForm() {
    this.StopForm.reset()
  }
  onCountrySelected(country: any) {
    this.defaultValue = country
  }
  addStep() {
    let step = this.StopForm.value
    step.country = this.defaultValue
    step.jobId = this.jobId;
    this._stepsService.addStep(step, this.jobId, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      let item = { ...response.data }
      this.steps.push(item)
      this.StopForm.reset()
      this.resetCountry()
    }, error => {
      console.log(error)
    })
  }
  deleteStop() {
    if (this.steps.length == 1) {
      this.notDeleteModal.show()
    }
    else {
      this._stepsService.deleteStep(this.uniqId, this.jobId).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.toaster.success('', 'Location Successfully deleted')
        }
      })
    }
  }
  editStep(stepForm: NgForm) {
    let stepModelNew = Object.assign({}, stepForm.value);
    stepModelNew.country = this.defaultValue
    stepModelNew.jobId = this.jobId
    stepModelNew.id = this.uniqId
    stepModelNew.lat = this.editStepModel.lat
    stepModelNew.lng  = this.editStepModel.lng
    stepModelNew.formatted_address  = this.editStepModel.formatted_address
    stepModelNew.place_id  = this.editStepModel.place_id
    this._stepsService.updateStep(stepModelNew, this.uniqId, this.jobId, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      this.isEdit = false
      let item = response.data
      let index = this.steps.findIndex(item => item.id == stepModelNew.id);
      this.steps[index] = item
      this.defaultValue = {
        name: 'United States',
        alpha2Code: 'US',
        alpha3Code: 'US',
        numericCode: '840'
      };
    }, error => {
      console.log(error)
    })
  }
  resetCountry() {
    this.defaultValue = {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    // this.getSteps()
  }
  ngAfterViewInit() {
    this.getPlaceAutocomplete()
    this.getPlaceAutocomplete1()
    this.mapInitializer();
  }
  getPlaceAutocomplete() {
    let place: any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: {  },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      place = autocomplete.getPlace();
      console.log(place)
         //  console.log(this.getAddressFromComponents(place.address_components) )
      this.patchValues(this.getAddressFromComponents(place.address_components),place.geometry.location.lat(),place.geometry.location.lng(),place.formatted_address,place.place_id)
    });
  }
  getPlaceAutocomplete1() {
    let place: any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText1.nativeElement,
      {
        // componentRestrictions: {  },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      place = autocomplete.getPlace();
      //  console.log(this.getAddressFromComponents(place.address_components) )
      this.patchValuesEdit(this.getAddressFromComponents(place.address_components),place.geometry.location.lat(),place.geometry.location.lng(),place.formatted_address,place.place_id)
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber: any = geo.find(g => g.types.find(t => t === 'street_number')) != undefined ? geo.find(g => g.types.find(t => t === 'street_number')).long_name : '';
    const streetName = geo.find(g => g.types.find(t => t === 'route')) != undefined ? geo.find(g => g.types.find(t => t === 'route')).long_name : '';
    const cityName = geo.find(g => g.types.find(t => t === 'locality') != undefined && g.types.find(t => t === 'political')) != undefined ? geo.find(g => g.types.find(t => t === 'locality') && g.types.find(t => t === 'political')).long_name : '';
    const stateName = geo.find(g => g.types.find(t => t === 'administrative_area_level_1') && g.types.find(t => t === 'political')) != undefined ? geo.find(g => g.types.find(t => t === 'administrative_area_level_1') && g.types.find(t => t === 'political')).long_name : '';
    const countryName = geo.find(g => g.types.find(t => t === 'country') && g.types.find(t => t === 'political')) != undefined ? geo.find(g => g.types.find(t => t === 'country') && g.types.find(t => t === 'political')).long_name : '';
    const zip = geo.find(g => g.types.find(t => t === 'postal_code')) != undefined ? geo.find(g => g.types.find(t => t === 'postal_code')).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      city: cityName,
      state: stateName,
      country: countryName,
      zip: zip
    };
  }
  patchValues(address: any,lat,lng,formatted_address,place_id) {
    console.log(lat + ' ' + lng)
      this.StopForm.patchValue({
        addressOne: address.addressOne,
        city: address.city,
        country: address.country,
        state: address.state,
        zip: address.zip,
        lat: lat,
        lng: lng,
        formatted_address : formatted_address,
        place_id : place_id
      });
  }
  patchValuesEdit(address: any,lat,lng,formatted_address,place_id) {
      this.editStepModel.addressOne = address.addressOne
      this.editStepModel.city = address.city
      this.editStepModel.country = address.country
      this.editStepModel.state = address.state
      this.editStepModel.zip = address.zip
      this.editStepModel.lat = lat
      this.editStepModel.lng = lng
      this.editStepModel.formatted_address = formatted_address
      this.editStepModel.place_id = place_id
  }
    async getLocation(zipCode:any,city:string){
    var lat = '';
    var lng = '';
    const GeoCoder = new google.maps.Geocoder();
     let address = String(zipCode)
    await GeoCoder.geocode( { 'address':address }, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        lat = results[0].geometry.location.lat();
        lng = results[0].geometry.location.lng();
       }
       else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
    (lat && lng) ?
    this.markers.push({
     position: new google.maps.LatLng(lat, lng),
     map: map,
     title: city
   }) : '';
   this.mapInitializer()

  }
}
