import { GlobalService } from 'src/app/core/_services/global/global.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { PaymentType } from '../Enum/PaymentType';

@Component({
  selector: 'app-products-customer',
  templateUrl: './products-customer.component.html',
  styleUrls: ['./products-customer.component.scss']
})
export class ProductsCustomerComponent implements OnInit {
  products: any[] = []
user:any = {}
historyCarWash = HistoryCarWash
paymentType  = PaymentType.card
paymentTypeEnum = PaymentType
vehicleId = ''
emailCard = ''
product:any={}
  constructor(
    public datePipe: DatePipe,
    private _companiesService: CompaniesService,
    private route: ActivatedRoute,
    private _carWashService: CarWashService,
    public router: Router,
    public _globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getProductsCompanyById()
    this.vehicleId = this.route.snapshot.paramMap.get('id');
    this.emailCard = this.user.email
  }
  getProductsCompanyById() {
    this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
        this.products = response.data
    })
  }
  createMemberShip(product:any){
   if(!Capacitor.isNativePlatform()) {
    let todayDate = new Date()
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id : 2,
      userId : this.user.id,
      branchId : this.user.companyId,
      addedBy : this.user.displayName,
      branchName:currentCompany.companyName,
      dateHistory : todayDate,
      timeHistory : this.datePipe.transform(todayDate,'H:mm'),
      key: this.historyCarWash.Create_Membership,
      paymentType : Number(this.paymentType)
      // eventName: 'Create membership'
    }
    let model:any ={
      date :  (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      time :this.datePipe.transform(todayDate,'H:mm'),
      paymentType :Number(this.paymentType),
      // priceId : product.default_price,
      customerId :this.user.id
    }
    model.history = history
      localStorage.getItem('countMonth') == 'forever' ? model.countMonth = localStorage.getItem('countMonth') : model.countMonth = Number(localStorage.getItem('countMonth'))
    console.log(model)
    this._carWashService.vehicleSubscriptionGlobal(this.vehicleId,product.default_price,model).subscribe((response:ResponseModel)=>{
      if(response.success){
          // this.router.navigate(['/user/view-vehicle',this.user.id])
          // console.log(response.data)
          // window.location.href = response.data
          this._globalService.openLink(response.data)
      }
    })
  }
  }
  handleChange(event,index:number){
    if(event.target.checked){
      this.products[index].isCheck = true
    }
    else{
      this.products[index].isCheck = false
    }
  }
  storeProduct(product){
    this.product = product
  }
  ngOnDestroy(){
    localStorage.removeItem('paymentType')
    localStorage.removeItem('countMonth')
  }
}
