import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  getPricing() {
    return this.http.get(this.baseUrl + 'pricing')
  }
  // // getSubscriptions(companyId: string) {
  // //   return this.http.get(this.baseUrl + 'subscriptions/' + companyId)
  // }
  getInvoicesSubscription(companyId: string) {
    return this.http.get(this.baseUrl + 'invoicesSubscription/' + companyId)
  }
}
