<div class="section-tool-job" *ngIf="userPermission.value != statusActive.InActive || role == 'admin'">
    <div class="tittle-section">
        <p>
            <!-- <i class="fas fa-calendar-alt pr-1"></i> -->
            <i class="uil uil-calendar-alt  uil-medium pr-1"></i>
            <!-- <img src="./assets/img/icons/crm_icons/date_and_time.svg" style=" width: 2.5rem;  " class="pr-1" alt="note-img"> -->
            <span>Date and Time</span>
        </p>
        <div class="btn-add-new" *ngIf="userPermission.value == statusActive.Active || role == 'admin'" (click)="addTimeModal.show()">
            <span class="badge badge-success">
            <i i class="far fa-plus-square"></i>
            Add New
           </span>
        </div>
        <!-- <div class="btn-add-new" *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
            <span class=" badge badge-success ">
          <i i class="far fa-plus-square "></i>
          Add New
         </span>
        </div> -->
    </div>
    <div class="inner-section">
        <mat-accordion class="example-headers-align " multi *ngFor="let item of datesAndTimes | paginate: {id: 'date', itemsPerPage: 3, currentPage: p }; let i=index ">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Date & Time
                        <div class="title-info ">
                            <span>({{item.startDateWindowFrom | customDatePipe}}) </span> <strong class="pl-1 pr-1">To</strong>
                            <span> ({{item.endDateWindowTo | customDatePipe}})</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="card ">
                    <div class="row ">
                        <div class=" col-sm-12 ">
                            <div class="item ">
                                <h6>Job Description</h6>
                                <label> {{item.jobDescription}} </label>
                            </div>
                        </div>
                        <div class=" col-sm-12 ">
                            <div class="item ">
                                <h6> Start Date Window </h6>
                                <label>
                                   <span class="start-time ">{{item.startDateWindowFrom | customDatePipe}}</span> - {{item.startDateWindowTo | customDatePipe}}
                               </label>
                            </div>
                        </div>
                        <div class=" col-sm-12 ">
                            <div class="item ">
                                <h6> Start Time Window </h6>
                                <label> <span class="start-time ">{{item.startTimeWindowFrom}}</span> - {{item.startTimeWindowTo}} </label>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class=" col-sm-12 ">
                            <div class="item ">
                                <h6> End Date Window </h6>
                                <label>{{item.endDateWindowFrom | customDatePipe}} - <span
                class="end-time ">{{item.endDateWindowTo | customDatePipe}}</span></label>
                            </div>
                        </div>
                        <div class=" col-sm-12 ">
                            <div class="item ">
                                <h6> End Time Window </h6>
                                <label> {{item.endTimeWindowFrom}} - <span class="end-time ">{{item.endTimeWindowTo}}</span> </label>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <div class="button-group " *ngIf="userPermission.value == statusActive.Active || role == 'admin'">
                                <!-- <button class="btn btn-success ">Edit Stop</button> -->
                                <button class="btn btn-success " (click)="storeId(item);editTimeModal.show() ">Edit Date & Time</button>
                                <button class="btn btn-danger " (click)="storeIndex(i);storeId(item);deleteModal.show() ">Delete Date & Time</button>
                            </div>
                        </div>
                        <!-- <div class="col-12 ">
                        <div class="button-group " *ngIf="userPermission.value == statusActive.Read_Only && role != 'admin' || userPermission.value == statusActive.Read_Only && role != 'manager'">
                            <button class="btn btn-success ">Edit Date & Time</button>
                            <button class="btn btn-danger ">Delete Date & Time</button>
                        </div>
                    </div> -->
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="text-center mt-4 " *ngIf="datesAndTimes.length> 0 ">
            <pagination-controls id="date" (pageChange)="p = $event "></pagination-controls>
        </div>
        <div class="ant-empty-image " *ngIf="datesAndTimes.length <=0 ">
            <embed type="image/svg+xml " src="./assets/img/empty.svg " />
            <span>No Data</span>
        </div>
    </div>
</div>
<!-- Add Time && Date Modal -->
<div mdbModal #addTimeModal="mdbModal" class="modal TimeModal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New Date & Time</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addTimeModal.hide();resetError()">
                       <span aria-hidden="true">&times;</span>
                     </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="timeDateFrom">
                        <div class="row draft-time">
                            <div class="row">
                                <div class="col-12 item-desc">
                                    <h6> Job Description :</h6>
                                </div>
                                <div class="col-12 mt-2">
                                    <!-- <div class=" header-window mb-2">
                                    <h6> Job Description <span class="required">*</span> :</h6>
                                </div> -->
                                    <textarea formControlName="jobDescription" id="jobDescription" cols="30" rows="3" placeholder="Packing, Pick Up, Delivery, ETC...." class="form-control" [ngClass]="{'is-invalid' : f.jobDescription.errors && f.jobDescription.touched}"></textarea>
                                    <div *ngIf="f.jobDescription.touched && f.jobDescription.invalid" class="invalid-feedback">
                                        <div *ngIf="f.jobDescription.errors.required">Job Description is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 item-start">Start</div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>Start Date Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-date-from"> From <span class="required">*</span> :</label>
                                    <input type="text" id="start-date-from" formControlName="startDateWindowFrom" placeholder="MM/DD/YYYY" (ngModelChange)="dateFrom($event);compareTwoDates()" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : f.startDateWindowFrom.errors && f.startDateWindowFrom.touched}"
                                    />
                                    <div *ngIf="f.startDateWindowFrom.touched && f.startDateWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="f.startDateWindowFrom.errors.required">Start Date From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-date-to" class="option-filed"> To<span > (Optional For making a Start Date Window) </span>:</label>
                                    <input type="text" id="start-date-to" formControlName="startDateWindowTo" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : f.startDateWindowTo.errors && f.startDateWindowTo.touched}">
                                    <div *ngIf="f.startDateWindowTo.touched && f.startDateWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="f.startDateWindowTo.errors.required">Start Date To is required</div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>Start Time Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-time-from"> From <span class="required">*</span> :</label>
                                    <input [ngxTimepicker]="pickerStartFrom" id="start-time-from" formControlName="startTimeWindowFrom" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : f.startTimeWindowFrom.errors && f.startTimeWindowFrom.touched}">
                                    <ngx-material-timepicker #pickerStartFrom (timeSet)="setStartTimeFrom($event)"> </ngx-material-timepicker>
                                    <div *ngIf="f.startTimeWindowFrom.touched && f.startTimeWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="f.startTimeWindowFrom.errors.required">Start Time From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-time-to" class="option-filed"> To <span > (Optional For making a Start Time Window)</span> :</label>
                                    <input [ngxTimepicker]="pickerStartTo" id="start-time-to" formControlName="startTimeWindowTo" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : f.startTimeWindowTo.errors && f.startTimeWindowTo.touched}">
                                    <ngx-material-timepicker #pickerStartTo></ngx-material-timepicker>
                                    <div *ngIf="f.startTimeWindowTo.touched && f.startTimeWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="f.startTimeWindowTo.errors.required">Start Time To is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 item-end">End</div>
                                <div class="col-12 mt-2 ">
                                    <div class=" header-window">
                                        <h6>End Date Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-date-from" class="option-filed"> From <span>(Optional For making a End Date Window)</span> :</label>
                                    <input type="text" id="end-date-from" formControlName="endDateWindowFrom" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : f.endDateWindowFrom.errors && f.endDateWindowFrom.touched}" />
                                    <div *ngIf="f.endDateWindowFrom.touched && f.endDateWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="f.endDateWindowFrom.errors.required">End Date From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-date-to"> To <span class="required">*</span> :</label>
                                    <input type="text" id="end-date-to" formControlName="endDateWindowTo" (ngModelChange)="dateEndTo($event);compareTwoDates()" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : f.endDateWindowTo.errors && f.endDateWindowTo.touched}"
                                    />
                                    <div *ngIf="f.endDateWindowTo.touched && f.endDateWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="f.endDateWindowTo.errors.required">End Date To is required</div>
                                    </div>
                                    <div *ngIf="error.isError" class="alert alert-danger">
                                        {{ error.errorMessage }}
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>End Time Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-time-from" class="option-filed"> From <span>(Optional For making a End Time Window)</span> :</label>
                                    <input [ngxTimepicker]="pickerEndFrom" id="end-time-from" formControlName="endTimeWindowFrom" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : f.endTimeWindowFrom.errors && f.endTimeWindowFrom.touched}" />
                                    <ngx-material-timepicker #pickerEndFrom></ngx-material-timepicker>
                                    <div *ngIf="f.endTimeWindowFrom.touched && f.endTimeWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="f.endTimeWindowFrom.errors.required">End Time From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-time-to">To <span class="required">*</span>  :</label>
                                    <input [ngxTimepicker]="pickerEndTo" id="end-time-to" formControlName="endTimeWindowTo" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : f.endTimeWindowTo.errors && f.endTimeWindowTo.touched}" />
                                    <ngx-material-timepicker #pickerEndTo (timeSet)="setEndTimeTo($event)"></ngx-material-timepicker>
                                    <div *ngIf="f.endTimeWindowTo.touched && f.endTimeWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="f.endTimeWindowTo.errors.required">End Time To is required</div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 text-center">
                                <button type="button" mdbBtn class="btn" color="success" [disabled]="timeDateFrom.invalid || error.isError" (click)="addDateAndTimeNotSendModal.show();addTimeModal.hide()">Add </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Edit Time && Date Modal -->
<!-- <div mdbModal #editTimeModal="mdbModal" class="modal TimeModal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Date && Time</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editTimeModal.hide()">
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <form #form=ngForm>
                        <mat-horizontal-stepper [linear]="isLinear" #stepper>
                            <mat-step>
                                <ng-template matStepLabel>Start Date & Time</ng-template>
                                <div class="row">
                                    <div class="col-lg-12  mt-2">
                                        <h6> Job Description:</h6>
                                        <textarea name="jobDescription" rows="2" placeholder="Packing, Pick Up, Unloading, Delivery ETC...." [(ngModel)]="editItem.jobDescription" class="form-control"></textarea>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <h6>Start Date Window:</h6>
                                    </div>
                                    <div class="col-lg-6">
                                        <label> From:</label>
                                        <input type="text" name="startDateWindowFrom" [(ngModel)]="editItem.startDateWindowFrom" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" />
                                    </div>
                                    <div class="col-lg-6">
                                        <label> To:</label>
                                        <input type="text" name="startDateWindowTo" [(ngModel)]="editItem.startDateWindowTo" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig">
                                    </div>
                                    <div class="col-12 mt-2">
                                        <h6>Start Time Window:</h6>
                                    </div>
                                    <div class="col-lg-6">
                                        <label> From:</label>
                                        <input [ngxTimepicker]="editPickerStartFrom" name="startTimeWindowFrom" [(ngModel)]="editItem.startTimeWindowFrom" placeholder="HH:MM:AM" class="form-control">
                                        <ngx-material-timepicker #editPickerStartFrom (timeSet)="setStartTimeFrom($event)">
                                        </ngx-material-timepicker>
                                    </div>
                                    <div class="col-lg-6">
                                        <label> To:</label>
                                        <input [ngxTimepicker]="editPickerStartTo" name="startTimeWindowTo" [(ngModel)]="editItem.startTimeWindowTo" placeholder="HH:MM:AM" class="form-control">
                                        <ngx-material-timepicker #editPickerStartTo (timeSet)="setStartTimeTo($event)">
                                        </ngx-material-timepicker>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" mdbBtn class="btn" matStepperNext color="success">Next</button>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>End Date & Time</ng-template>
                                <div class="row">
                                    <div class="row">
                                        <div class="col-12 mt-2">
                                            <h6>End Date Window:</h6>
                                        </div>
                                        <div class="col-lg-6">
                                            <label> From:</label>
                                            <input type="text" name="endDateWindowFrom" [(ngModel)]="editItem.endDateWindowFrom" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" />
                                        </div>
                                        <div class="col-lg-6">
                                            <label> To:</label>
                                            <input type="text" name="endDateWindowTo" [(ngModel)]="editItem.endDateWindowTo" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" />
                                        </div>
                                        <div class="col-12 mt-2">
                                            <h6>End Time Window:</h6>
                                        </div>
                                        <div class="col-lg-6">
                                            <label> From:</label>
                                            <input [ngxTimepicker]="editPickerEndFrom" name="endTimeWindowFrom" [(ngModel)]="editItem.endTimeWindowFrom" placeholder="HH:MM:AM" class="form-control" />
                                            <ngx-material-timepicker #editPickerEndFrom (timeSet)="setEndTimeFrom($event)">
                                            </ngx-material-timepicker>
                                        </div>
                                        <div class="col-lg-6">
                                            <label>To:</label>
                                            <input [ngxTimepicker]="editPickerEndTo" name="endTimeWindowTo" [(ngModel)]="editItem.endTimeWindowTo" placeholder="HH:MM:AM" class="form-control" />
                                            <ngx-material-timepicker #editPickerEndTo (timeSet)="setEndTimeTo($event)">
                                            </ngx-material-timepicker>
                                        </div>
                                        <div class="col-12">
                                            <button type="button" mdbBtn class="btn" matStepperPrevious color="primary">Back</button>
                                            <button type="button" mdbBtn class="btn" color="success" (click)="checkEditSendEmail();editTimeModal.hide()">Update </button>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Edit Time && Date Modal -->
<div mdbModal #editTimeModal="mdbModal" class="modal TimeModal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Date && Time</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editTimeModal.hide();resetError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="editTimeDateFrom">
                        <div class="row draft-time">
                            <div class="row">
                                <div class="col-12 item-desc">
                                    <h6> Job Description :</h6>
                                </div>
                                <div class="col-12 mt-2">
                                    <textarea formControlName="jobDescription" id="jobDescription" cols="30" rows="3" placeholder="Packing, Pick Up, Delivery, ETC...." class="form-control" [ngClass]="{'is-invalid' : ff.jobDescription.errors && ff.jobDescription.touched}"></textarea>
                                    <div *ngIf="ff.jobDescription.touched && ff.jobDescription.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.jobDescription.errors.required">Job Description is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 item-start">Start</div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>Start Date Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-date-from"> From <span class="required">*</span> :</label>
                                    <input type="text" id="start-date-from" formControlName="startDateWindowFrom" placeholder="MM/DD/YYYY" (ngModelChange)="editDateFrom($event);compareTwoEditDates()" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : ff.startDateWindowFrom.errors && ff.startDateWindowFrom.touched}"
                                    />
                                    <div *ngIf="ff.startDateWindowFrom.touched && ff.startDateWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.startDateWindowFrom.errors.required">Start Date From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-date-to" class="option-filed"> To<span > (Optional For making a Start Date Window) </span>:</label>
                                    <input type="text" id="start-date-to" formControlName="startDateWindowTo" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : ff.startDateWindowTo.errors && ff.startDateWindowTo.touched}">
                                    <div *ngIf="ff.startDateWindowTo.touched && ff.startDateWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.startDateWindowTo.errors.required">Start Date To is required</div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>Start Time Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-time-from"> From <span class="required">*</span> :</label>
                                    <input [ngxTimepicker]="pickerStartEditFrom" id="start-time-from" formControlName="startTimeWindowFrom" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : ff.startTimeWindowFrom.errors && ff.startTimeWindowFrom.touched}">
                                    <ngx-material-timepicker #pickerStartEditFrom (timeSet)="setStartTimeEditFrom($event)"> </ngx-material-timepicker>
                                    <div *ngIf="ff.startTimeWindowFrom.touched && ff.startTimeWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.startTimeWindowFrom.errors.required">Start Time From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="start-time-to" class="option-filed"> To <span > (Optional For making a Start Time Window)</span> :</label>
                                    <input [ngxTimepicker]="pickerStartEditTo" id="start-time-to" formControlName="startTimeWindowTo" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : ff.startTimeWindowTo.errors && ff.startTimeWindowTo.touched}">
                                    <ngx-material-timepicker #pickerStartEditTo></ngx-material-timepicker>
                                    <div *ngIf="ff.startTimeWindowTo.touched && ff.startTimeWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.startTimeWindowTo.errors.required">Start Time To is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 item-end">End</div>
                                <div class="col-12 mt-2 ">
                                    <div class=" header-window">
                                        <h6>End Date Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-date-from" class="option-filed"> From <span >(Optional For making a End Date Window)</span> :</label>
                                    <input type="text" id="end-date-from" formControlName="endDateWindowFrom" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : ff.endDateWindowFrom.errors && ff.endDateWindowFrom.touched}" />
                                    <div *ngIf="ff.endDateWindowFrom.touched && ff.endDateWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.endDateWindowFrom.errors.required">End Date From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-date-to"> To <span class="required">*</span> :</label>
                                    <input type="text" id="end-date-to" formControlName="endDateWindowTo" (ngModelChange)="editDateEndTo($event);compareTwoEditDates()" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [bsConfig]="bsConfig" [ngClass]="{'is-invalid' : ff.endDateWindowTo.errors && ff.endDateWindowTo.touched}"
                                    />
                                    <div *ngIf="ff.endDateWindowTo.touched && ff.endDateWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.endDateWindowTo.errors.required">End Date To is required</div>
                                    </div>
                                    <div *ngIf="error.isError" class="alert alert-danger">
                                        {{ error.errorMessage }}
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class=" header-window">
                                        <h6>End Time Window:</h6>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-time-from" class="option-filed"> From <span >(Optional For making a End Time Window)</span> :</label>
                                    <input [ngxTimepicker]="pickerEndEditFrom" id="end-time-from" formControlName="endTimeWindowFrom" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : ff.endTimeWindowFrom.errors && ff.endTimeWindowFrom.touched}" />
                                    <ngx-material-timepicker #pickerEndEditFrom></ngx-material-timepicker>
                                    <div *ngIf="ff.endTimeWindowFrom.touched && ff.endTimeWindowFrom.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.endTimeWindowFrom.errors.required">End Time From is required</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="end-time-to">To <span class="required">*</span>  :</label>
                                    <input [ngxTimepicker]="pickerEndEditTo" id="end-time-to" formControlName="endTimeWindowTo" placeholder="HH : MM : AM" class="form-control" [ngClass]="{'is-invalid' : ff.endTimeWindowTo.errors && ff.endTimeWindowTo.touched}" />
                                    <ngx-material-timepicker #pickerEndEditTo (timeSet)="setEndTimeEditTo($event)"></ngx-material-timepicker>
                                    <div *ngIf="ff.endTimeWindowTo.touched && ff.endTimeWindowTo.invalid" class="invalid-feedback">
                                        <div *ngIf="ff.endTimeWindowTo.errors.required">End Time To is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button type="button" mdbBtn class="btn" color="success" [disabled]="editTimeDateFrom.invalid || error.isError" (click)="dateAndTimeNotSendModal.show();editTimeModal.hide()">Update </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Time && Date Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete-modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Time & Date</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center mt-2">
                    <p>Delete Date & Time?</p>
                    <!-- <p class="text-secondary"><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-light " data-dismiss="modal " (click)="deleteModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-danger " (click)="deleteTime();deleteModal.hide() ">Delete
          </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm No tSend Date and time -->
<div mdbModal id="dateAndTimeNotSendModal" #dateAndTimeNotSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="dateAndTimeNotSendModal.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Change Date & Time? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="dateAndTimeNotSendModal.hide()"> Cancel </button>
                    <button type="button " class="btn btn-success " (click)="checkEditSendEmail();dateAndTimeNotSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send Date and time -->
<div mdbModal id="dateAndTimeSendModal" #dateAndTimeSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();dateAndTimeSendModal.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();updateTimeDate();dateAndTimeSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="updateTimeDate();dateAndTimeSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send date and time -->
<div mdbModal id="addDateAndTimeSendModal" #addDateAndTimeSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();addDateAndTimeSendModal.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();addTimeDate();addDateAndTimeSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="addTimeDate();addDateAndTimeSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Not Send date and time -->
<div mdbModal id="addDateAndTimeNotSendModal" #addDateAndTimeNotSendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addDateAndTimeNotSendModal.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Add a New Date & Time?</p>
                    <!-- <p  ><small>If you don't remove, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="resetAddForm();addDateAndTimeNotSendModal.hide() "> Cancel </button>
                    <button type="button " class="btn btn-success " (click)="checkAddSendEmail();addDateAndTimeNotSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- can't delete Date and time -->
<div mdbModal id="notDeleteModal" #notDeleteModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warining</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="notDeleteModal.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Can't Delete Date & Time!</p>
                    <!-- <p  ><small></small></p> -->
                </div>
            </div>
        </div>
    </div>
</div>