import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PersonPendingService {
  baseUrl = environment.baseUrl

  constructor(private http: HttpClient) { }

  /************** persons pending ***************/

  getAllPendingUsers(companyId: string) {
    return this.http.get(this.baseUrl + 'pendingUsers/' + companyId)
  }
  getAllPendingCustomers(companyId: string) {
    return this.http.get(this.baseUrl + 'pendingCustomer/' + companyId)
  }
  getAllMangers(companyId: string) {
    return this.http.get(this.baseUrl + 'AllMangers/' + companyId)
  }
  getPendingUser(id: string) {
    return this.http.get(this.baseUrl + 'pendingUser/' + id)
  }
}
