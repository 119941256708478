<div class="modal">
    <div class=" modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <h4 class="modal-title w-100">Payment Success!</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">Thank You</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-block" data-dismiss="modal" (click)="updateVehicleMemberShip()">Continue</button>
            </div>
        </div>
    </div>
</div>