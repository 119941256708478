<div class="charge" id="charge">
    <div class="tittle-section">
        <p>Charge(s)</p>
        <button class="btn bnt-success">
            <i class="far fa-plus-square"></i> Add New
         </button>
    </div>
    <div class="ant-empty-image" *ngIf="charges.length <= 0 ">
        <img src="./assets/img/empty.svg" alt="empty-img">
        <span>No Data</span>
    </div>
</div>
