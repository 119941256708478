import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { CompanyPlan } from 'src/app/models/company/company';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
import { ResponseModel } from 'src/app/models/response/ResponseModel';

@Component({
  selector: 'app-NotPay-Company',
  templateUrl: './NotPay-Company.component.html',
  styleUrls: ['./NotPay-Company.component.scss']
})
export class NotPayCompanyComponent implements OnInit {
  user:any={}
  companyInfo:any = {}
  companyPlanType = CompanyPlan
  planCompanyType = PlanCompanyType

  constructor(private _globalService:GlobalService) { }

  ngOnInit() {
    this.companyInfo = JSON.parse(localStorage.getItem('user'))
    // this._globalService.companyFullSummary(this.user.companyId).subscribe((response:ResponseModel)=>{
    //   this.companyInfo = response.data
    // })
  }

}
