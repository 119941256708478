import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { InventoryTagComponent } from './inventory-tag/inventory-tag.component';
import { ContainerTagComponent } from './container-tag/container-tag.component';
import { ItemsListTagComponent } from './items-list-tag/items-list-tag.component';
import { AllInventoriesPrintComponent } from './all-inventories-print/all-inventories-print.component';
import { NgxPrintElementModule } from 'ngx-print-element';
import { NgxPrintModule } from 'ngx-print';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AllInventoriesPrintMapComponent } from './all-inventories-print-map/all-inventories-print-map.component';
import { QrMobilePrintComponent } from './qr-mobile-print/qr-mobile-print/qr-mobile-print.component';
// import { QRCodeModule } from 'angularx-qrcode';
import { InventoryTagMobileComponent } from './inventory-mobile-print/inventory-tag-mobile/inventory-tag-mobile.component';
import { AllInventoriesMobilePrintComponent } from './inventory-mobile-print/all-inventories-mobile-print/all-inventories-mobile-print.component';
import { ContainerMobilePrintComponent } from './container-mobile-print/container-mobile-print/container-mobile-print.component';
import { ItemsListContainerMobileComponent } from './container-mobile-print/items-list-container-mobile/items-list-container-mobile.component';
import { AllInventoriesPrintMapMobileComponent } from './inventory-mobile-print/all-inventories-print-map-mobile/all-inventories-print-map-mobile.component';
import { PrintScanMobileComponent } from './print-scan-mobile/print-scan-mobile.component';
@NgModule({
  declarations: [
    InventoryTagComponent,
    ContainerTagComponent,
    ItemsListTagComponent,
    AllInventoriesPrintComponent,
    AllInventoriesPrintMapComponent,
    QrMobilePrintComponent,
    InventoryTagMobileComponent,
    AllInventoriesMobilePrintComponent,
    ContainerMobilePrintComponent,
    ItemsListContainerMobileComponent,
    AllInventoriesPrintMapMobileComponent,
    PrintScanMobileComponent
  ],
  imports:[
    CommonModule,
    NgxPrintElementModule,
    NgxPrintModule,
    SharedModule,
    // QRCodeModule,
  ],
  exports:[
    InventoryTagComponent,
    ContainerTagComponent,
    ItemsListTagComponent,
    AllInventoriesPrintComponent,
    AllInventoriesPrintMapComponent,
    PrintScanMobileComponent
  ]
})
export class PrintModule { }
