import { StripService } from './../../../../core/_services/strip/strip.service';
import { ToasterCustomService } from './../../../../core/_services/toaster-service/ToasterCustomService.service';
import { SuperAdminService } from './../../../../core/_services/super-admin/super-admin.service';
import { ToolType } from './../../../../models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyPlan, UpdateCompany } from 'src/app/models/company/company';
import { PersonPendingService } from 'src/app/core/_services/PendingPersons/personPending.service';
import { PricingService } from 'src/app/core/_services/pricing/pricing.service';
import { UpdateInfo } from './Enum/UpdateInfo';
import { environment } from 'src/environments/environment.prod';
import { loadStripe } from '@stripe/stripe-js';
import { CompanyPlanType } from 'src/app/modules/auth/pages/Enum/CompanyPlanType';
import * as moment from 'moment';
import { ModalDirective } from 'angular-bootstrap-md';

import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { CategoryCompany } from '../permissions/Enum-Category/CategoryCompany';
import { HttpErrorResponse } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { DatePipe } from '@angular/common';
import { ProductEditType } from '../car-wash-folder/Enum/ProductEditType';
declare var google: any;
declare var $:any
interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
}
@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})

export class EditCompanyComponent implements OnInit {
  defaultValue: Country = {} as Country;
  flipDiv = false;
  flipDiv1 = false;
  flipDiv2 = false;
  companyModal: any = {}
  companyModel: any = {}
  Currencies: any[] = []
  roles: any[]
  Status: any[] = []
  explanations: any[] = []
  status = "InActive"
  pattern: any = "<<Job Number>>"
  isCopied = false;
  user: any
  role: any
  settingsTools: any
  index: number
  tools: any
  companyAccount: any
  toolsSettings: any;
  public selectedIndex: number = 0;
  base64textString = [];
  logoUrl: string = 'https://via.placeholder.com/150'
  users: any[] = []
  usersPending: any[] = []
  searchText: string = ""
  searchBendingText: string = ""
  p: number = 1
  p2: number = 1
  isMore = false
  isMorePro = false
  companyPlan: any
  inviteModel: any = {}
  userItem: any = {}
  isFree = true
  isPro = true
  isPremium = true
  numberPattern = ValidationModel.numberPattern
  emailPattern = ValidationModel.emailPattern
  managers: any[] = []
  typePlan: number
  mangerPermissionObj: any = {}
  mangerId: string
  permissionValue: any
  mangerObjPermission: any = {}
  pricingObj: any = {}
  companyAddressModel: any = {}
  companyAddressEdit: any = {}
  bufferCompanyAddress: any = {}
  allInvoice: any = []
  invoice: any = {}
  invoiceUpcoming: any = {}
  period_start: any
  period_end: any
  period_start1: any
  period_end1: any
  @ViewChild('stripBillingModal') stripBillingModal: ModalDirective;
  @ViewChild('stripDescriptionModal') stripDescriptionModal: ModalDirective;
  @ViewChild('changeCategoryModal') changeCategoryModal: ModalDirective;
  @ViewChild('successCustomerCashModal') successCustomerCashModal: ModalDirective;
  companyPlanType:number
  secret_keyObj : any = {}
  secret_key_edit=''
  bufferProduct:any={}
  categoryCompany = CategoryCompany
  // car wash variables
  products:any=[]
  industry:number = 0
  fixedCompanyIds:any = []
  customerEmailCash = ''
  customerCash:any={}
  isCustomerCash = false
  @ViewChild('addressText') addressText: any;
  isMobile = false
  place_id = ''
  formatted_address = ''
  lng = 0
  lat = 0
  Car_Wash_Plans = environment.Car_Wash_Plans
  Inventory_Plans  = environment.Inventory_Plans
  ProductEditType = ProductEditType
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _companiesService: CompaniesService,
    private router: Router,
    private _toolsService: ToolsService,
    public socket: Socket,
    private _personService: PersonService,
    private _personPendingService: PersonPendingService,
    private toastr: ToastrService,
    private _superAdmin: SuperAdminService,
    private _toasterCustomService: ToasterCustomService,
    private _pricingService: PricingService,
    private _stripService: StripService,
    private _carWashService: CarWashService,
    private _toaster:ToasterCustomService,
    private datePipe: DatePipe,
  ) {}
  countryFormGroup: FormGroup;
  ngOnInit() {
    if(Capacitor.isNativePlatform()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.industry = this.user.categoryCompany
    this.companyPlan = this.user.companyPlan
    this.companyPlanType = this.user.companyPlanType
    this._companiesService.getCompanyFullSummaryWithRole(this.user.companyId, this.user.id).subscribe((response: ResponseModel) => {
      if (response.success) {
          this.user.companyPlan = response.data.companyPlan
          this.user.companyPlanType = response.data.companyPlanType
          this.user.companyStatus = response.data.companyStatus
          localStorage.setItem('user', JSON.stringify(this.user))
          this.user = JSON.parse(localStorage.getItem('user'))
          this.companyPlan = this.user.companyPlan
          this.companyPlanType = this.user.companyPlanType
      }
    })
    this.Status = [
      { name: "Active" },
      { name: "InActive" }
    ]
    this.defaultValue = {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    this.roles = [
      {
        id: 1,
        key: "manager",
        view: "Manager",
        value: "InActive"
      },
    ]
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.index = response['tools'].data.findIndex(t => t.toolType === ToolType.Setting_Tools)
        this.tools = response['tools'].data
        this.settingsTools = response['tools'].data.find(t => t.toolType === ToolType.Setting_Tools).items.find(i => i.itemId === ToolSettingEditItem.Company_Account)
        // this.toolsSettings = response['tools'].data.find(d => d.toolType === ToolType.Settings_Tools)
        // console.log(this.toolsSettings)
        // this.companyAccount = this._toolsService.getPermission(this.role, this.settingsTools, "My Account")
        // console.log(this.companyAccount)
      }
      if (response['comapny'].success) {
        this.companyAddressModel = response['comapny'].data
        this.companyModel = response['comapny'].data
        this.bufferCompanyAddress = Object.assign({}, this.companyAddressModel.companyAddress)
        this.companyAddressEdit = Object.assign({}, this.companyAddressModel)
        this.logoUrl = this.companyModel.companyLogo
        this.companyModal = Object.assign({}, this.companyModel)
        this.companyModal['currency'] = "USD"
        this.companyModal['convert'] = "235"
      }
    }, error => {
      console.log(error)
    })
    this.Currencies = [
      "USD",
      "GBP",
      "CAD",
      "AUD",
      "EUR",
      "ZAR",
      "AED"
    ]
    this.explanations = [{
      name: "Job Number",
      value: "<<Job Number>>"
    },
    {
      name: "Job Date and Time",
      value: "<<Job Date and Time>>"
    },
    {
      name: "Job Status",
      value: "<<Job Status>>"
    },
    {
      name: "Customer first and last name",
      value: "<<Customer first and last name>>"
    },
    {
      name: "Customer porta Link to signup",
      value: "<<Customer porta Link to signup>>"
    },
    {
      name: "Company Logo",
      value: "<<Company Logo>>"
    },
    {
      name: "Company name",
      value: "<<Company name>>"
    },
    {
      name: "Company 1 line address",
      value: "<<Company 1 line address>>"
    },
    {
      name: "Company many lines address",
      value: "<<Company many lines address>>"
    },
    {
      name: "Company phone number",
      value: "<<Company phone number>>"
    },
    {
      name: "Company web site Link",
      value: "<<Company web site Link>>"
    }
    ]
    this.socket.on(RealTimeKeys.Company_Update_Logo, (data: any) => {
      this._companiesService.getCompanyById(data.id).subscribe((response: ResponseModel) => {
        this.logoUrl = response.data.companyLogo
        this.user.companyLogo = response.data.companyLogo
        localStorage.setItem('user', JSON.stringify(this.user))
      })
    });
    this.socket.on(RealTimeKeys.Company_Add_Key, (data: any) => {
      this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: ResponseModel) => {
        if(response.success){
          this.products = response.data
          // console.log('products',this.products)
        }

     })
    });
    // this.socket.on(RealTimeKeys.Company_Update_Force, (data: any) => {
    //  if(this.user.companyId == data.companyId){
    //    if(data.type == UpdateCompany.plan){
    //     this.user.companyPlan = data.value
    //     localStorage.setItem('user', JSON.stringify( this.user))
    //    }
    //  }
    // });
    if (this.role == 'manager') {
      this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.mangerObjPermission = response.data
          console.log(' this.mangerObjPermissionnnnnnnnnnnnnn', this.mangerObjPermission)
        }
      })
    }
    this._pricingService.getPricing().subscribe((response: ResponseModel) => {
      if (response.success) {
        this.pricingObj = response.data
      }
    })
    this.getALlManager()
    // this.getInvoicesSubscription()
    // this.getSubscriptions()
    this.checkCompanyPlanAndType()
    this.getCompanyIds()
    // console.log('*******************companyPlanType*******************',this.companyPlanType)
  }
  getCompanyIds(){
    this._companiesService.getCompanyIds().subscribe((response:ResponseModel)=>{
      if(response.success){
       this.fixedCompanyIds = response.data
      }
    })
  }
  checkCompanyPlanAndType(){
    switch(this.companyPlan){
      case CompanyPlan.Free : {
        if(this.companyPlanType == CompanyPlanType.Monthly){
          this.isFree = false;
        }
        else{
          this.isFree = true;
        }
        break;
      }
      case CompanyPlan.Pro : {
        if(this.companyPlanType == CompanyPlanType.Monthly){
          this.isPro = false;
        }
        else{
          this.isPro = true;
        }
        break;
      }
      case CompanyPlan.Premium : {
        if(this.companyPlanType == CompanyPlanType.Monthly){
          this.isPremium = false;
        }
        else{
          this.isPremium = true;
        }
        break;
      }
    }
  }
  // getSubscriptions() {
  //   this._pricingService.getSubscriptions(this.user.companyId).subscribe((response: ResponseModel) => {
  //     // console.log('getSubscriptions', response)
  //   })
  // }
  getInvoicesSubscription() {
    this._pricingService.getInvoicesSubscription(this.user.companyId).subscribe((response: ResponseModel) => {
      // console.log(response)
      this.allInvoice = response.data
      // console.log(this.allInvoice)
    })
  }
  getInvoiceById(id) {
    this.invoice = this.allInvoice.find(i => i.id == id)
    this.period_start = this.timeConverter(this.invoice.lines.data[0].period.start)
    this.period_end = this.timeConverter(this.invoice.lines.data[0].period.end)
    // console.log(this.invoice)
    if (this.invoice != null || this.invoice != undefined) {
      this.stripBillingModal.show()
    }
  }
  createCustomerPortal(){
    this._companiesService.createCustomerPortal(this.user.companyId).subscribe((response:ResponseModel)=>{
      // console.log('response',response)
      // window.open(response.data.url);
      window.location.href = response.data.url
    })
  }
  timeConverter(UNIX_timestamp) {
    return new Date(UNIX_timestamp * 1000)
  }
  getPrice(price:number){
    return price / 100 ^ 0
  }
  getALlManager() {
    this._personPendingService.getAllMangers(this.user.companyId).subscribe((response: ResponseModel) => {
      if (this.role == 'admin') {
        this.managers = response.data.managers
      }
      if (this.role == 'manager') {
        this.managers = response.data.managers.filter(m => m.id != this.user.id)
      }
    })
  }
  storeMangerItem(value, id) {
    this.mangerId = id
    this.permissionValue = value
  }
  updateMangerPermission(value: any, mangerItem: any) {
    let model = {
      permission: Number(value)
    }
    this._personService.updateMangerPermission(mangerItem.id, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toasterCustomService.success('', 'Permission Successfully Changed')
      }
      else {
        this._toasterCustomService.error('Please try again', 'Permission Error Changed')
      }
    }, error => {
      this._toasterCustomService.success('Please try again', 'Permission Error Changed')
    })
  }
  onClick() {
    this.flipDiv = !this.flipDiv;
    this.companyModal = Object.assign({}, this.companyModel)
  }
  onClick1() {
    this.flipDiv1 = !this.flipDiv1;
    this.companyAddressEdit = Object.assign({}, this.companyAddressModel)
    this.defaultValue = this.companyAddressEdit.companyAddress.country
  }
  onClick2() {
    this.flipDiv2 = !this.flipDiv2;
  }
  getOriginalPrice(price) {
    if(price){
    return price / 100
    } else{
        return 0
    }
  }
  // storePlanType(num: number) {
  //   this.typePlan = num
  //   let buffModel = this.getComapnyPlan(num)
  //   let model = {
  //     companyPlan: buffModel.companyPlan,
  //     companyPlanType: buffModel.companyPlanType
  //   }
  //   this._companiesService.updateCompanySubscription(this.user.companyId,model).subscribe((response: ResponseModel) => {
  //     // console.log('updateCompanySubscription', response)
  //     if (response.success) {
  //       this._stripService.getInvoicesSubscriptionUpcoming(this.user.companyId).subscribe((res: ResponseModel) => {
  //         // console.log('updateCompanySubscription', res)
  //         if (res.success) {
  //           // console.log('this is invoices', res.data)
  //           this.invoiceUpcoming = res.data
  //           this.period_start1 = this.timeConverter(this.invoiceUpcoming.lines.data[0].period.start)
  //           this.period_end1 = this.timeConverter( this.invoiceUpcoming.lines.data[0].period.end)
  //           // this.basicModal.show()
  //           // const element = document.getElementById('staticBackdrop') as HTMLElement;
  //           // const myModal = new Modal(element);
  //           // myModal.show();
  //         //   $('#staticBackdrop').modal({
  //         //     backdrop: 'static',
  //         //     keyboard: false
  //         // })
  //         }
  //       })
  //     }
  //   })
  // }
  restoreCompanySubscription() {
    this._companiesService.restoreCompanySubscription(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {

      }
    })
  }
  onCountrySelected(country: any) {
    this.defaultValue = country
    this.companyAddressEdit.companyAddress.country = country
  }
  updateCompany() {
    let item: any = {
      // id: this.companyModal.id,
      companyName: this.companyModal.companyName,
      companyEmail: this.companyModal.companyEmail,
      companyPhone: this.companyModal.companyPhone,
      currency: this.companyModal.currency,
      companyWebsite: this.companyModal.companyWebsite
    }
    // item.companyAddress = this.bufferCompanyAddress
    // item.ownerName = item
    // console.log('save')
    this._companiesService.updateCompany(item,this.companyModal.id, UpdateInfo.Information).subscribe((response: ResponseModel) => {
      let companies = this.user.companyData
      let comapnyId = localStorage.getItem("companyId")
      companies.find(item => item.companyId === this.user.companyId).companyName = response.data.companyName
      localStorage.setItem('user', JSON.stringify(this.user))
      localStorage.setItem('companyName', companies.find(item => item.companyId === comapnyId).companyName)
      this.companyModel = response.data
      this.flipDiv = !this.flipDiv
    }, error => {
      console.log(error)
    })
  }
  updateCompanyAddress() {
    let item: any = {
      id: this.companyAddressEdit.id,
      addressOne: this.companyAddressEdit.companyAddress.addressOne,
      addressTow: this.companyAddressEdit.companyAddress.addressTow,
      city: this.companyAddressEdit.companyAddress.city,
      state: this.companyAddressEdit.companyAddress.state,
      zip: this.companyAddressEdit.companyAddress.zip,
      country: this.defaultValue,
      lat :this.lat ,
      lng :this.lng ,
      formatted_address :this.formatted_address ,
      place_id :this.place_id
    }
    let bufferItem = {
      companyAddress: item
    }
    this._companiesService.updateCompany(bufferItem, item.id, UpdateInfo.Address).subscribe((response: ResponseModel) => {
      let companies = this.user.companyData
      let comapnyId = localStorage.getItem("companyId")
      companies.find(item => item.companyId === this.user.companyId).companyName = response.data.companyName
      localStorage.setItem('user', JSON.stringify(this.user))
      localStorage.setItem('companyName', companies.find(item => item.companyId === comapnyId).companyName)
      this.flipDiv1 = !this.flipDiv1
    }, error => {
      console.log(error)
    })
  }
  updateCompanyForce(plan) {
    let companyPlanType: any = 0
    switch (plan) {
      case 0: {
        if (this.isFree) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isFree) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
      case 1: {
        if (this.isPro) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isPro) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
      case 2: {
        if (this.isPremium) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isPremium) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
    }
    let model = {
      value: plan,
      value2: companyPlanType,
      type: UpdateCompany.plan
    }
    this._superAdmin.updateCompanyForce(this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.user.companyPlan = plan
        this.companyPlan = plan
        localStorage.setItem('user', JSON.stringify(this.user))
      }
    })
  }
  getComapnyPlan(plan: any) {
    let companyPlanType: any = 0
    switch (plan) {
      case 0: {
        if (this.isFree) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isFree) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
      case 1: {
        if (this.isPro) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isPro) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
      case 2: {
        if (this.isPremium) {
          companyPlanType = CompanyPlanType.Yearly
        }
        else if (!this.isPremium) {
          companyPlanType = CompanyPlanType.Monthly
        }
        break;
      }
    }
    let model = {
      companyPlan: plan,
      companyPlanType: companyPlanType,
    }
    return model
  }
  changeStatus(newValue) {
    let toolId = this.tools[this.index].id
    let itemId = 2
    let toolUpdated = this.tools[this.index].items[3];
    this.tools[this.index].items[3].permissionSettings[0].value = newValue;
    this.spinner.show()
    this._toolsService.updateTool(toolId, itemId, toolUpdated).subscribe(response => {
      this.spinner.hide()
    }, error => {
      console.log(error)
      this.spinner.hide()
    })
  }
  onOptionSelected(value: any) {
    this.status = value
  }
  onExplanationsSelected(value: string) {
    this.pattern = value
  }
  copyNotify() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
      this.spinner.hide();
    }, 3000);
  }
  inviteManager() {
    localStorage.setItem('isAdmin', 'true')
    this.router.navigate(['/admin/invite'])
  }
  onFileChanged(event) {
    var files = event.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    let companyLogo = 'data:image/png;base64,' + btoa(e.target.result)
    this.logoUrl = companyLogo
    $("#myModal").modal('show');
  }
  changeLogo() {
    this._companiesService.changeLogo(this.logoUrl, this.user.companyId).subscribe((response: ResponseModel) => {
      this.logoUrl = response.data.companyLogo
      $("#myModal").modal('hide');
    }, error => {
      console.log(error)
      $("#myModal").modal('hide');
    })

  }
  invite() {
    this.inviteModel.role = 'manager'
    this.inviteModel.date = new Date();
    this.inviteModel.type = 'user'
    this.inviteModel.companyId = this.user.companyId
    this._personService.InvitePerson(this.inviteModel).subscribe((response: ResponseModel) => {
      if (response.success) {
        // $("#myModal").modal('show');
        this.toastr.success(response.message, 'Success Invitation',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
      else {
        this.toastr.error(response.message, 'Invitation Failed',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
    }, error => {
      console.log(error)
    })
  }
  ReInvitePersonPending(id: string) {
    this._personService.ReInvitePersonPending(id).subscribe(data => {
      this.toastr.success('Please check your email', 'Successfully Invitation',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        });
    }, error => {
      this.toastr.error('Please try again', 'Error Invitation',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 2000
        });
    }
    )
  }
  // cancelPersonPending(item: any) {
  //   let index = this.usersPending.indexOf(this.userItem)
  //   this.spinner.show()
  //   this._personService.cancelPersonPending(item.id).subscribe((response: ResponseModel) => {
  //     if (response.success) {
  //       this.usersPending.splice(index, 1)
  //     }
  //     this.spinner.hide()
  //   }, error => {
  //     console.log(error)
  //     this.spinner.hide()
  //   })
  // }
  storId(item: any) {
    this.userItem = item
  }
  toggle() {
    this.isMore = !this.isMore

  }
  togglePro() {
    this.isMorePro = !this.isMorePro

  }
  changePlanYearly(plan: number) {
    if (plan == 1) {
      this.isFree = !this.isFree
    }
    else if (plan == 2) {
      this.isPro = !this.isPro
    }
    else if (plan == 3) {
      this.isPremium = !this.isPremium
    }
  }
  addSecretKey(){
    let model ={
      keyValue : this.secret_key_edit,
      updateTime : new Date()
    }
    // console.log('addSecretKey')
    this._companiesService.updateCompanyStripeKey(this.user.companyId,model).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.secret_keyObj = response.data
        this._toasterCustomService.success('','Stripe Secret Key Successfully Added')
      }
      else{
        this._toasterCustomService.error('','Invalid Secret Key ')
      }
    })
  }
  storeStripKey(){
    if(this.secret_keyObj){
      this.secret_key_edit = this.secret_keyObj.keyValue
    }
  }
  getCompanyStripeKey(){
      this._companiesService.getCompanyStripeKey(this.user.companyId).subscribe((response:ResponseModel)=>{
        if(response.success){
          this.secret_keyObj = response.data
        }
      })
      this.getCustomerCash()
  }
  // car wash functions
  isProductsCompanyById(){
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
           this.products = response.data
          //  console.log('products',this.products)
        })
      }
    })
  }
  addDesorptionProduct(){
    // console.log( this.bufferProduct )
    let model ={
      description : this.bufferProduct.customDescription,
      id:this.bufferProduct.id,
      date :new Date(),
      priceBeforeDiscount:{
        isShow: this.bufferProduct?.priceBeforeDiscount?.isShow ?? false,
         priceBefore:Number(this.bufferProduct?.priceBeforeDiscount?.priceBefore ?? 0)
      },
      priceSingleWash:{
        isShow: this.bufferProduct?.priceSingleWash?.isShow ?? false,
        priceBefore:Number(this.bufferProduct?.priceSingleWash?.priceBefore ?? 0),
        priceAfter:Number(this.bufferProduct?.priceSingleWash?.priceAfter ?? 0)
      },
      productsMaxCar:{
        countCar:Number(this.bufferProduct?.productsMaxCar?.countCar ?? 0),
        isShow: this.bufferProduct?.productsMaxCar?.isShow ?? false,
        priceBefore:Number(this.bufferProduct?.productsMaxCar?.priceBefore ?? 0),
        priceAfter:Number(this.bufferProduct?.productsMaxCar?.priceAfter ?? 0)
      },
    }
    console.log(model)
    this._carWashService.addDescritionProduct(this.user.companyId,model).subscribe((response:ResponseModel)=>{
      if(response.success){
      }
    })
  }

  storeProduct(product){
    this.bufferProduct = product
    // this.bufferProduct.updated = this.timeConverter(this.bufferProduct.updated)
    // console.log(this.bufferProduct)
  }
  changeIndustry(event){
    // this.changeCategoryModal.show()
    this.industry =  Number(event)
  }
  updateCompanyCategory(){
    this._companiesService.updateCompanyCategory(this.user.companyId,this.industry).subscribe((response:ResponseModel)=>{
      if(response.success){
        // console.log(response)
        this.user.categoryCompany = this.industry
        localStorage.setItem('user',JSON.stringify(this.user))
        this.flipDiv2 =!this.flipDiv2
      }
    })
  }
  resetChangeIndustry()
  {
      this.industry = this.user.categoryCompany
  }
  checkCompany(id:string){
    if(this.fixedCompanyIds.includes(id)){
      return false
    }
    else{
      return true
    }
  }
  createMemberShip(){
    let model:any = {
      firstName : '',
      lastName : '',
      email : this.getEmailWithoutSpace(this.customerEmailCash),
      companyId :this.user.companyId,
      isInvited : false,
      role: "customer",
      name:'',
      type: "customer",
      status: "Unassigned",
      disable: false,
      isCanceled: false,
      isCustomerCash : true,
      isCustomerCarWash :true,
      creationDate: new Date(),
      date: new Date(),
      dateFormate :  this.datePipe.transform(new Date(), 'yyyy-d-M'),
      timeFormate :  this.datePipe.transform(new Date(), 'h:mm a'),
      country: {
          name: "United States",
          alpha2Code: "US",
          alpha3Code: "US",
        numericCode: "840"
      }
    }
    this._carWashService.createCustomerCash(model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.successCustomerCashModal.show()
        this.customerCash = response.data
        this.isCustomerCash = true
        this.customerEmailCash =  response.data.email
      }
      else {
        this._toaster.error('','Customer already exists')
      }
    }, error => {
      this._toaster.error('','Customer already exists')
    })

  }
  updateCashEmailCustomer(id:string){
    this._carWashService.updateCustomerCash(id,this.getEmailWithoutSpace(this.customerEmailCash)).subscribe((response:ResponseModel)=>{
      if(response.success){
        this._toaster.success('','Successfully Email Updated')
      }
      else{
        this._toaster.error('','Error Email Updated')
      }
    })
  }
  getCustomerCash(){
    this._carWashService.getCustomerCash(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success && response.data != null){
        this.isCustomerCash = true
        this.customerCash = response.data
        this.customerEmailCash = response.data.email
      }
      else{
        this.isCustomerCash = false
      }
    })
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       this.lat =  place.geometry.location.lat()
       this.lng = place.geometry.location.lng()
       this.formatted_address = place.formatted_address
       this.place_id =  place.place_id
       this.companyAddressEdit.companyAddress = this.getAddressFromComponents(place.address_components)
    });
  }
  handleChange(event,type) {
    if(type === ProductEditType.Price_Product_Before){
      if (event.checked) {
        this.bufferProduct.priceBeforeDiscount.isShow = true
      }
      else{
        this.bufferProduct.priceBeforeDiscount.isShow = false
      }
    }
    if(type === ProductEditType.Car_Max_Price_Before){
      if (event.checked) {
        this.bufferProduct.productsMaxCar.isShow = true
      }
      else{
        this.bufferProduct.productsMaxCar.isShow = false
      }
    }
    else{
      if (event.checked) {
        this.bufferProduct.priceSingleWash.isShow = true
      }
      else{
        this.bufferProduct.priceSingleWash.isShow = false
      }
    }

    }
    onTabChanged(event: any) {
      this.selectedIndex = event.index
    }
  updatePriceBefore(event: any,type): void {
    if(type === ProductEditType.Price_Product_Before){
    if (this.bufferProduct && this.bufferProduct.priceBeforeDiscount) {
      this.bufferProduct.priceBeforeDiscount.priceBefore = event;
    }
  }
  if(type === ProductEditType.Single_Wash_Price_Before){
    if (this.bufferProduct && this.bufferProduct.priceSingleWash) {
      this.bufferProduct.priceSingleWash.priceBefore = event;
    }
  }
  if(type === ProductEditType.Single_Wash_Price_After){
    if (this.bufferProduct && this.bufferProduct.priceSingleWash) {
      this.bufferProduct.priceSingleWash.priceAfter = event;
    }
  }
  if(type === ProductEditType.Car_Max_Price_Before){
    if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
      this.bufferProduct.productsMaxCar.priceBefore = event;
    }
  }
  if(type === ProductEditType.Car_Max_Price_After){
    if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
      this.bufferProduct.productsMaxCar.priceAfter = event;
    }
  }
  if(type === ProductEditType.Car_Max_Num_Of_Cars){
    if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
      this.bufferProduct.productsMaxCar.countCar = event;
    }
  }
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      country: countryName,
      zip: zip
    };
  }
}


