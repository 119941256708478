import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StripService {
  baseUrl = environment.baseUrl
constructor(private http:HttpClient) { }

getInvoicesSubscriptionUpcoming(companyId: any) {
  return this.http.get(this.baseUrl + "invoicesSubscriptionUpcoming/" + companyId)
}
}
