import { PersonService } from './../../../../../core/_services/person/person.service';
import { Socket } from 'ngx-socket-io';
import { ToolsService } from './../../../../../core/_services/tools/tools.service';
import { ToolDashboardEditItem } from 'src/app/models/tools-model/ToolDashboardEditItem';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { AssignedType } from 'src/app/models/Enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { ModalDirective } from 'angular-bootstrap-md';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';

@Component({
  selector: 'app-assigned-customers',
  templateUrl: './assigned-customers.component.html',
  styleUrls: ['./assigned-customers.component.scss']
})
export class AssignedCustomersComponent implements OnInit {
  assignedCustomers: any[] = []
  status = "Unassigned"
  isAssigned = false
  jobDetails: any = {}
  role: any
  jobId: string
  customers = []
  assCustomers = []
  assignedCustomerArr: any = []
  unAssignedCustomerArr: any = []
  // unAssignedCustomer: any = []
  searchText = '';
  searchUnAssignedText = '';
  total = 1
  totalAssigned = 1
  p: number = 1;
  p2: number = 1;
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  user: any = {}
  bufferItem: any = {}
  jobsTools: any = {}
  settingsTools: any = {}
  assignedCustomersObj: any = {}
  customersObj: any = {}
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  creationUserId:string=''
  @ViewChild('sendEmailassignedCustomerModal') sendEmailassignedCustomerModal: ModalDirective;
  @ViewChild('assignedCustomerModal') assignedCustomerModal: ModalDirective;
  constructor(
    private route: ActivatedRoute,
    private _jobsService: JobsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _toolsService: ToolsService,
    private socket: Socket,
    private _personService: PersonService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['jobs'].success) {
        this.assignedCustomers = response['jobs'].data.assignedCustomers
        this.jobDetails = response['jobs'].data
        this.jobId = response['jobs'].data.id
        this.creationUserId = response['jobs'].data.creationUserId
        this.items = response['jobs'].data.showItems
        if (this.role == 'admin') {
            this.getCustomers()
        }
        if (this.role != 'admin' && this.role != "manager") {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getCustomers()
          }
        }
        if (this.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getCustomers()
          }
        }
      }
      if (response['tools'].success) {
        // assigned customers
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        // this.assignedCustomersObj = this._toolsService.getPermission(this.role, this.jobsTools, ToolJobsEditItem.assignedCustomers)
        // console.log('this is assignedCustomersObj', this.assignedCustomersObj)
        // if (this.role == 'admin' || this.assignedCustomersObj.itemStatus == "Active" && this.assignedCustomersObj.roleStatus == "active" || this.assignedCustomersObj.itemStatus == "Active" && this.assignedCustomersObj.roleStatus == "readOnly") {
        //   if (response['jobs'].success) {
        //     this.assignedCustomers = response['jobs'].data.assignedCustomers
        //     this.jobDetails = response['jobs'].data
        //     this.jobId = response['jobs'].data.id
        //     this.items = response['jobs'].data.showItems
        //     if (this.role != 'admin') {
        //       this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
        //     }
        //     if (this.role == "manager") {
        //       this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
        //     }
        //   }
        // }
        // customers list
        // this.settingsTools = response['tools'].data.find(d => d.toolType === ToolType.Setting_Tools)
        // this.customersObj = this._toolsService.getPermission(this.role, this.settingsTools, ToolSettingEditItem.Customers_list)
        // console.log('this is TruckObj', this.customersObj)
        // if (this.role == 'admin' || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "active" || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "readOnly") {
        //   if (response['customersInCompany'].success) {
        //     this.customers = response['customersInCompany'].data
        //     this.assCustomers = this.customers
        //     this.checkCustomersAssigned()
        //   }
        // }
      }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Assigned_Customer, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this._jobsService.getJobById(this.jobId).subscribe((response: ResponseModel) => {
          this.assignedCustomers = response.data.assignedCustomers
          this.getCustomers()
        })
      }
    });
  }
  getCustomers(){
      this._personService.getCustomersByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
        if(response.success){
          this.customers = response.data
          // this.assCustomers = this.customers
          this.checkCustomersAssigned()
        }
      })
    // this.customers = response['customersInCompany'].data
    // this.assCustomers = this.customers
    // this.checkCustomersAssigned()
  }
  checkCustomersAssigned() {
    this.assignedCustomerArr = []
    this.unAssignedCustomerArr = []
    this.assignedCustomerArr= this.customers.filter((f:any) => this.assignedCustomers.includes(f.id))
    this.unAssignedCustomerArr= this.customers.filter((f:any) => !this.assignedCustomers.includes(f.id))
    // for (let index = 0; index < this.customers.length; index++) {
    //   for (let j = 0; j < this.assignedCustomers.length; j++) {
    //     if (this.assignedCustomers[j] === this.customers[index].id) {
    //       this.assignedCustomerArr.push(this.customers[index])
    //     }
    //   }
    // }
    // console.log('assignedCustomerArrrrrrrrrr',this.customers.filter((f:any) => !this.assignedCustomers.includes(f.id)))
  }
  storeItem(item, isAssigned: boolean) {
    this.bufferItem = item
    // let notify: any = {}
    // if (isAssigned) {
    //   notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.assignedCustomers, EmailNotification.Email_Notification_Customers_Assigned_Job)
    // } else {
    //   notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.assignedCustomers, EmailNotification.Email_Notification_Customers_Unassigned_Job)
    // }
    // if (notify.notifyStatus && notify.notifyStatus == 'Active') {
    //   this.sendEmailassignedCustomerModal.show()
    //   this.isCustomerEmail = Is_Customer_Email.Send_Email
    // }
    // else {
      this.assignedCustomerModal.show()
      this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
    // }
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  assignedFun(item: any) {
    if (this.assignedCustomers.length <= 0) {
      this._jobsService.updateJobPrimaryCustomer(this.jobId, item.id, this.isCustomerEmail).subscribe((response: ResponseModel) => {
        this.total = this.customers.length - this.assignedCustomers.length
        this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
      })
    }
    let isExists = false;
    for (let index = 0; index < this.assignedCustomers.length; index++) {
      if (this.assignedCustomers[index] === item.id) {
        // this.assignedCustomers.splice(index, 1);
        isExists = true
      }
    }
    if (!isExists) {
      // this.assignedCustomers.push(item.id)
      isExists = false;
    }

    let model = {
      isAssigned : !isExists,
      assignedType: AssignedType.CUSTOMER,
      id:item.id
    }
    let itemId = ToolJobsEditItem.assignedCustomers
    if (this.assignedCustomers.length <= 0) {

    }
    this._jobsService.updateJobAssigned(this.jobId, model, itemId, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      this.assignedCustomers = response.data.assignedCustomers
      this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
    }, error => {
      console.log(error)
    })
  }
  invite() {
    if (this.role === 'manager' || this.role === 'admin') {
      localStorage.setItem("isCustomer", 'true')
      this.router.navigate(['/admin/invite'])
    }
    else {
      localStorage.setItem("isCustomer", 'true')
      this.router.navigate(['/user/invite'])
    }
  }
  checkActive(item) {
    for (let index = 0; index < this.assignedCustomers.length; index++) {
      if (this.assignedCustomers[index] === item.id) {
        return true
      }
    }
    return false
  }
  resetPaginationCustomer(event){
    this.p2 = 1
  }
  resetPaginationUnAssignedCustomer(){
    this.p = 1
  }
}
