import { LoaderService } from './../_services/loader/loader.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Injectable } from '@angular/core';

import { catchError, finalize } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count: number = 0;
  constructor(
    private _loaderService: LoaderService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loaderService.isLoading.next(true)
    this.count++;
    return next.handle(req).pipe(
        tap((event:any) => {
            if (event instanceof HttpResponse) {
                this.count--;
                if (this.count === 0) {
                  this._loaderService.isLoading.next(false)
                }
                return of(event);
            }
        }),
        catchError(error => {
          this._loaderService.isLoading.next(false)
            this.count--;
            return of(error);
        })
    );
}
}


