import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobDetailsService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  getJobDetailsByIdItem(id: string, itemId: number) {
    return this.http.get(this.baseUrl + 'JobDetailsByIdItem/' + id + '/' + itemId)
  }
  getPrintMilitaryBillTemplate(companyId: string,jobId:string, itemId: number,type:number) {
    return this.http.get(this.baseUrl + 'militaryBillTTemplate/' + companyId + '/'+jobId+'/' + itemId +'?type='+type)
  }
  updateJobDetails(model: any, id: string, idItem: any, isCustomerEmail: number) {
    return this.http.put(this.baseUrl + 'jobDetails/' + id + '/' + idItem + '?isCustomerEmail=' +isCustomerEmail, model)
  }
  printMilitaryBillTemplate(companyId: string,jobId:string, itemId: number,model:any,type:number){
    return this.http.post(this.baseUrl + 'printMilitaryBill/'  + companyId + '/'+jobId+'/' + itemId+'?type='+type, model)
  }
  // updateJobDetailsShowItems(id: string, items: any) {
  //   return this.http.put(this.baseUrl + 'updateShowItems/' + id, { showItems: items })
  // }
  updateJobDetailsDeleteSignature(jobDetailsId: string, id: string, itemId: number) {
    return this.http.delete(this.baseUrl + 'DeleteSignature/' + jobDetailsId + '/' + itemId + '?imgId=' + id)
  }
  updateMilitaryBillJobDetails(jobId:string,idItem:number,step:number,type:number,model:any){
    return this.http.put(this.baseUrl + 'militaryBill/' + jobId + '/' + idItem + '?step=' +step + '&type='+type, model)
  }
  updateMilitaryBillDeleteDocImage(jobId:string,idItem: number,type:number,imgId:any){
    return this.http.delete(this.baseUrl + 'militaryBill/' + jobId + '/' + idItem + '?imgId=' +imgId + '&type='+type)
  }
}
