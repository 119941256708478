import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  baseUrl = environment.baseUrl
  constructor(
    private http: HttpClient
  ) { }
  getTicketsByCompany(companyId: string) {
    return this.http.get(this.baseUrl + 'ticketsByCompany/' + companyId)
  }
  getTicket(id: string) {
    return this.http.get(this.baseUrl + 'ticket/' + id)
  }
  // get History
  getHistory(){
    return this.http.get(this.baseUrl + 'ticket/')
  }
  AddTicket(model: any) {
    return this.http.post(this.baseUrl + 'ticket', model)
  }
  updateTicket(id: string, model: any) {
    return this.http.put(this.baseUrl + 'updateTicket/' + id, model)
  }
  updateTicketStatus(id: string, model: any) {
    return this.http.put(this.baseUrl + 'updateTicketStatus/' + id, model)
  }

}
