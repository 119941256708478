<div class="header mb-3">
    <div>
        <!-- {{date | date:'short'}} -->
    </div>
    <!-- <button class="badge badge-light mx-1" (click)="print.print('items-list', {printMode: 'template', pageTitle: 'Hello World'})">Print</button> -->
    <button class="badge badge-light mx-1" (click)="printFixed()">Print</button>
</div>
<div id="items-list">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#Job Number</th>
                <th scope="col">Num</th>
                <th scope="col">Item Name</th>
                <th scope="col">Quantity</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of objFromParent2">
                <th scope="row">{{item.jobNumber}}</th>
                <td>{{item.itemNumber}}</td>
                <td>{{item.name}}</td>
                <td>@{{item.quantity}}</td>
            </tr>
        </tbody>
    </table>
</div>