<div class="card mb-3">
    <div class="tittle-section">
        <p> Car Wash Website </p>
        <div class=" btn-add-new">
            <span class="badge badge-success" (click)="inviteModal.show()">Share Website</span>
            <span class="badge badge-success ml-1" (click)="openWebsite()">View</span>
        </div>
    </div>
    <div class="blocks">
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>Domain</mat-expansion-panel-header>
                <div class="block-body">
                    <div class="form-group mt-2">
                        <label for="basic-url">Custom Domain</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon3">{{static_link}}</span>
                            </div>
                            <input type="text" class="form-control" a-ng id="basic-url" placeholder="Company User Name" name="websiteName" [value]="websiteName" (input)="checkServiceName($event.target.value)" aria-describedby="basic-addon3">
                        </div>
                        <span class="alert alert-danger" *ngIf="isUserExists">website name is exists</span>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-sm btn-success" [disabled]="isUserExists" (click)="updateDomain()">Save</button>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>Home</mat-expansion-panel-header>
                <div class="block-body">
                    <ngx-flip [flip]="flipDiv">
                        <div class="fornt-company-info" front [style.display]="!flipDiv ? 'block' : 'none'">
                            <div class="header-block">
                                <button class="btn btn-sm btn-success" (click)="eventFlip()">Edit</button>
                                <div class="switch-toggle">
                                    <span class="pr-2"> {{headerView.isShow ? 'Active' : 'In Active'}}</span>
                                    <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Header)" [checked]="headerView.isShow"> </mat-slide-toggle>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item-block">
                                    <p> Header </p>
                                    <span>{{headerView.tittleHeader}}</span>
                                </div>
                            </div>
                            <div class=" col-sm-12">
                                <div class="item-block">
                                    <p> Text </p>
                                    <span>{{headerView.textHeader}}</span>
                                </div>
                            </div>
                        </div>
                        <div back [style.display]="flipDiv ? 'block' : 'none'">
                            <div class="header-block">
                                <button class="btn btn-sm btn-primary" form="form1" [disabled]="!form1.valid" (click)="eventFlip()">
                                  <span>Back</span>
                               </button>
                                <button class="btn btn-sm btn-success" color="success" [disabled]="!form1.valid" form="form1" (click)="updateHeader()">
                                  <span>Save</span>
                                </button>
                            </div>
                            <div class="back-flip">
                                <form #form1='ngForm' id="form1">
                                    <!-- <div class="row"> -->
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="tittleHeader">Header <span class="required"> *</span> :</label>
                                            <textarea type="text" #headerInput id="header" required name="tittleHeader" minlength="1" maxlength="70" #hTitttle="ngModel" [(ngModel)]="headerObj.tittleHeader" class="form-control"></textarea>
                                            <div *ngIf="hTitttle.errors && hTitttle.touched" class="alert alert-danger">
                                                <div *ngIf="hTitttle.errors.required">
                                                    tittle header is required.
                                                </div>
                                            </div>
                                            <mat-hint class="d-flex justify-content-between align-items-center mt-1" align="end">
                                                <span>Max 70 characters </span>
                                                <span>{{headerInput.value.length}}/70 </span>
                                            </mat-hint>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="textHeader"> Text <span class="required"> *</span> :</label>
                                            <textarea type="text" #textInput id="textHeader" name="textHeader" required minlength="1" maxlength="200" [(ngModel)]="headerObj.textHeader" class="form-control" #txtHeader="ngModel"> </textarea>
                                            <div *ngIf="txtHeader.errors && txtHeader.touched" class="alert alert-danger">
                                                <div *ngIf="txtHeader.errors.required">
                                                    Text Header required.
                                                </div>
                                            </div>
                                            <mat-hint class="d-flex justify-content-between align-items-center mt-1" align="end">
                                                <span>Max 200 characters </span>
                                                <span>{{textInput.value.length}}/200 </span>
                                            </mat-hint>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </form>
                            </div>
                        </div>
                    </ngx-flip>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false" (opened)="getCompanyStripeKey();isProductsCompanyById()">
                <mat-expansion-panel-header>
                    <span>Express Services</span>
                    <div class="upgrade">
                        <button class="badge badge-light" *ngIf="user.companyPlan < planCompanyType.PREMIUM" [routerLink]="['/admin/edit-company']">Upgrade</button>
                    </div>
                </mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <!-- <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button> -->
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{pricingView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Pricing)" [disabled]="user.companyPlan < planCompanyType.PREMIUM" [checked]="pricingView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="strip-products">
                        <div class="strip-products-items" *ngIf="products?.length > 0">
                            <div class="strip-products-item" *ngFor="let product of products">
                                <p> {{product.name}} (Stripe)</p>
                                <div class="btn-add-new">
                                    <span class="badge badge-success" (click)="storeProduct(product);stripDescriptionModal.show()">Edit Perks</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mb-3" *ngIf="products?.length <= 0">
                            <strong>You can manage to show or hide membership in the stripe settings.</strong>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>
                    <span>Our Services</span>
                    <div class="upgrade">
                        <button class="badge badge-light" *ngIf="user.companyPlan < planCompanyType.PREMIUM" [routerLink]="['/admin/edit-company']">Upgrade</button>
                    </div>
                </mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <!-- <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button> -->
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{servicesView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Services)" [disabled]="user.companyPlan < planCompanyType.PREMIUM" [checked]="servicesView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="text-center mb-3">
                        <strong>You can manage to show or hide a service in the services Add-In <a [routerLink]="['/admin/carwash-services']"  >here</a></strong>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>
                    <span>Some Works</span>
                    <div class="upgrade">
                        <button class="badge badge-light" *ngIf="user.companyPlan < planCompanyType.PREMIUM" [routerLink]="['/admin/edit-company']">Upgrade</button>
                    </div>
                </mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <!-- <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button> -->
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{worksView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Some_Works)" [disabled]="user.companyPlan < planCompanyType.PREMIUM" [checked]="worksView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-lg-4 mt-3" *ngFor="let item of imagesArr">
                                <div class="img-add-ticket">
                                    <div class="remove-btn" (click)="removeImage(item.id)">
                                        <i class="fas fa-times"></i>
                                    </div>
                                    <img *ngIf="item" src="{{item.src}}" alt="item-img"> <br>
                                    <p class="mt-3">{{item.dateClaim | date:'medium'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="ant-empty-image" *ngIf="imagesArr.length <= 0 ">
                            <img src="./assets/img/empty.svg" alt="empty-img">
                            <span>No photos addedd yet!</span>
                        </div>
                        <div class="upload-buttons">
                            <div class="btn-group">
                                <div class="btn-upload" *ngIf="user.companyPlan >= planCompanyType.PREMIUM">
                                    <input id="actual-btn" type="file" class="form-control" accept="image/*" multiple hidden (change)="selectFiles($event)">
                                    <label for="actual-btn" class="btn btn-success"><i class="uil uil-upload"></i></label>
                                </div>
                                <button class="btn btn-success" (click)="saveSomeWork()" [disabled]="user.companyPlan < planCompanyType.PREMIUM">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>Contact Us</mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{contactView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Contact_Address)" [checked]="contactView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="tittle-section ">
                            <p>Email Contact</p>
                            <div class=" btn-add-new">
                                <span class="badge badge-success" (click)="contactModal.show()">Edit</span>
                            </div>
                        </div>
                        <div class="tittle-section">
                            <p>Phone, email & Address</p>
                            <div class=" btn-add-new">
                                <span class="badge badge-success" (click)="phoneModal.show()">Edit</span>
                            </div>
                        </div>
                        <div class="tittle-section">
                            <p>Map</p>
                            <div class=" btn-add-new">
                                <span class="pr-2"> {{AddressView.isMapShow ? 'Active' : 'In Active'}}</span>
                                <mat-slide-toggle (change)="handleChangeMap($event)" [checked]="AddressView.isMapShow"> </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>About Our Company</mat-expansion-panel-header>
                <div class="service-info">
                    <div class="block-body">
                        <ngx-flip [flip]="flipDiv1">
                            <div class="fornt-company-info" front [style.display]="!flipDiv1 ? 'block' : 'none'">
                                <div class="header-block">
                                    <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button>
                                    <div class="switch-toggle">
                                        <span class="pr-2"> {{aboutView.isShow ? 'Active' : 'In Active'}}</span>
                                        <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.About)" [checked]="aboutView.isShow"> </mat-slide-toggle>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item-block">
                                        <p> Header </p>
                                        <span>{{aboutView.tittleAbout}}</span>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item-block">
                                        <p> Text </p>
                                        <span>{{aboutView.textAbout}}</span>
                                    </div>
                                </div>
                            </div>
                            <div back [style.display]="flipDiv1 ? 'block' : 'none'">
                                <div class="header-block">
                                    <button class="btn btn-sm btn-primary" form="form2" [disabled]="!form2.valid" (click)="eventFlipAbout()">
                                  <span>Back</span>
                               </button>
                                    <button class="btn btn-sm btn-success" form="form2 " color="success" [disabled]="!form2.valid" (click)="updateAbout()">
                                  <span>Save</span>
                                </button>
                                </div>
                                <div class="back-flip">
                                    <form #form2='ngForm' id="form2">
                                        <!-- <div class="row"> -->
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="tittleAbout">Header <span class="required"> *</span> :</label>
                                                <textarea type="text" id="tittleAbout" #tittleAbout required name="tittleAbout" minlength="1" maxlength="65" [(ngModel)]="aboutObj.tittleAbout" class="form-control" #tittleAbout="ngModel"></textarea>
                                                <div *ngIf="tittleAbout.errors && tittleAbout.touched" class="alert alert-danger">
                                                    <div *ngIf="tittleAbout.errors.required">
                                                        tittle About is required.
                                                    </div>
                                                </div>
                                                <mat-hint class="d-flex justify-content-between align-items-center mt-1" align="end">
                                                    <span>Max 65 characters </span>
                                                    <span>{{tittleAbout.value.length}}/65 </span>
                                                </mat-hint>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="textAbout"> Text <span class="required"> *</span> :</label>
                                                <textarea type="text" id="textAbout" name="textAbout" #textAbout inlength="1" maxlength="520" required [(ngModel)]="aboutObj.textAbout" class="form-control" #txtAbout="ngModel"> </textarea>
                                                <div *ngIf="txtAbout.errors && txtAbout.touched" class="alert alert-danger">
                                                    <div *ngIf="txtAbout.errors.required">
                                                        Text About required.
                                                    </div>
                                                </div>
                                                <mat-hint class="d-flex justify-content-between align-items-center mt-1" align="end">
                                                    <span>Max 520 characters </span>
                                                    <span>{{textAbout.value.length}}/520 </span>
                                                </mat-hint>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </form>
                                </div>
                            </div>
                        </ngx-flip>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>Opening Times</mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <!-- <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button> -->
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{AddressView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Address_Map)" [checked]="AddressView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="tittle-section ">
                            <p>Hours</p>
                            <div class=" btn-add-new">
                                <span class="badge badge-success" (click)="hoursModal.show()">Add/Edit </span>
                            </div>
                        </div>
                        <!-- <div class="tittle-section">
                            <p>Map</p>
                            <div class=" btn-add-new">
                              <span class="badge badge-success">Switch</span>
                          </div>
                        </div> -->
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>Social Media (Follow us)</mat-expansion-panel-header>
                <div class="block-body">
                    <div class="header-block">
                        <!-- <button class="btn btn-sm btn-success" (click)="eventFlipAbout()">Edit</button> -->
                        <span></span>
                        <div class="switch-toggle">
                            <span class="pr-2"> {{socialView.isShow ? 'Active' : 'In Active'}}</span>
                            <mat-slide-toggle (change)="handleChangeSwitch($event,sectionType.Social_Media)" [checked]="socialView.isShow"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="block-item">
                        <div *ngFor="let item of socialView.items">
                            <div class="tittle-section mb-2" *ngIf="item.id != '3'">
                                <p>{{item.name}}</p>
                                <div class=" btn-add-new ">
                                    <mat-slide-toggle (change)="handleChangeSocialMedia($event,item.id)" [checked]="item.isShow"> </mat-slide-toggle>
                                    <span class="badge badge-success ml-2" (click)="storeSocialMedia(item);socialModal.show()">Add/Edit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div mdbModal #inviteModal="mdbModal" class="modal invite-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Share Website</h6>
                    <button type="button" class="close" (click)="inviteModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body ">
                    <mat-tab-group>
                        <!-- <mat-tab label="Enter email">
                          <div class="text-center">
                              <form (ngSubmit)="InviteUser()">
                                  <div class="modal-body text-center">
                                      <p>Add Customer email in order to send the membership sign up link</p>
                                      <div class="form-group">
                                          <input type="email" class="form-control" name="emailCustomer" [(ngModel)]="emailCustomer" placeholder="Add customer email" required [pattern]="emailPattern" #emailCu="ngModel">
                                          <div *ngIf="emailCu.errors && emailCu.touched" class="alert alert-danger">
                                              <div *ngIf="emailCu.errors.required">
                                                  Email required.
                                              </div>
                                              <div *ngIf="emailCu.errors.pattern">
                                                  Email not valid.
                                              </div>
                                          </div>
                                          <input type="submit" [disabled]="emailCu.errors" class="btn btn-primary btn-block" value="Send">
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </mat-tab> -->
                        <mat-tab label="Share link">
                            <div class="share-link">
                                <div *ngIf="isCopied" class="alert alert-success">
                                    <i class="fas fa-check"></i> Copied to Clipboard
                                </div>
                                <div class="input-group">
                                    <input type="url" class="form-control" readonly value="{{getCopyLink()}}">
                                    <div class="input-group-append copy-icon" [cdkCopyToClipboard]="getCopyLink()" (click)="copyLink()">
                                        <span class="input-group-text"> <i class="uil uil-copy"></i>  </span>
                                    </div>
                                    <!-- <div class="input-group-append" (click)="shareLink()">
                                      <span class="input-group-text"> <i class="uil uil-share-alt"></i> </span>
                                  </div> -->
                                </div>
                                <p>Share the website link</p>
                                <!-- <strong [cdkCopyToClipboard]="sharedLink" (click)="copyLink()">
                                  <i class="uil uil-link"></i>
                              </strong> -->
                            </div>
                        </mat-tab>
                        <mat-tab label="Scan QR Code">
                            <div id="qr_code_print">
                                <div class="item">
                                    <div class="first-side">
                                        <img src="{{companyInfo.companyLogo}}" alt="logo-img"> <br>
                                    </div>
                                    <div class="second-side">
                                        <div class="info">
                                            <p>{{companyInfo.companyName}}</p>
                                            <p *ngIf="companyInfo.companyPhone">{{companyInfo.companyPhone}} </p>
                                            <p class="mb-0" *ngIf="companyInfo.companyWebsite">{{companyInfo.companyWebsite}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="address-section">
                                    <p class="address-small">{{companyInfo.companyAddress.addressOne}} <span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span>, {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}}
                                        {{companyInfo.companyAddress.zip}}
                                    </p>
                                </div>
                                <div class="qr-code">
                                    <h5>Scan QR Code</h5>
                                    <qrcode [qrdata]="getCopyLink()" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                </div>
                                <div class="steps">
                                    <div class="steps-content">
                                        <p><span class="num">1</span> Scan the QR Code</p>
                                        <p><span class="num">2</span>Sign up as a customer</p>
                                        <p><span class="num">3</span>Buy a membership or a package</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="btn-print">
                                <button class="btn btn-sm btn-primary" (click)="printFixed()">Print</button>
                            </div> -->
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #phoneModal="mdbModal" class="modal invite-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Edit Phone, email & Address</h6>
                    <button type="button" class="close" (click)="phoneModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body ">
                    <form #phoneForm="ngForm">
                        <div class="form-group">
                            <label for="phone_id">Phone</label>
                            <input type="text" name="phone" class="form-control" [(ngModel)]="contactView.phoneEmailObj.phone">
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" name="email" class="form-control" [(ngModel)]="contactView.phoneEmailObj.email">
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <input type="email" name="address" #address class="form-control" [(ngModel)]="contactView.phoneEmailObj.address">
                        </div>
                        <div class="text-center">
                            <button class="btn btn-sm btn-success" [disabled]="!phoneForm.valid" (click)="phoneModal.hide();UpdateContactEmail()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #contactModal="mdbModal" class="modal invite-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Edit Contact Information</h6>
                    <button type="button" class="close" (click)="contactModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body ">
                    <form #contactForm="ngForm">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" name="email" class="form-control" [(ngModel)]="contactView.contactObj.email">
                        </div>
                        <div class="text-center">
                            <button class="btn btn-sm btn-success" [disabled]="!contactForm.valid" (click)="contactModal.hide();UpdateContactEmail()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #hoursModal="mdbModal" class="modal invite-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md " role="document">
        <div class="container container-over">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Edit Business Hours</h6>
                    <button type="button" class="close" (click)="hoursModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center">
                    <div class="col-12" *ngFor="let day of days; let i=index">
                        <div class="item-hour">
                            <span>{{day.name}}</span>
                            <input type="time" class="form-control" [value]="day.start" [(ngModel)]="day.start">
                            <input type="time" class="form-control" [value]="day.end" [(ngModel)]="day.end">
                            <mat-slide-toggle (change)="handleChangeSwitchHours($event,i)" [checked]="day.isOpen"> </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="buttons-group mt-2 mb-2">
                        <button class="btn btn-sm btn-danger" (click)="hoursModal.hide()">Cancel</button>
                        <button class="btn btn-sm btn-success" (click)="hoursModal.hide();updateHours()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #confirmationModal="mdbModal" class="modal invite-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-sm " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Confirmation</h6>
                    <button type="button" class="close" (click)="confirmationModal.hide();resetSwitch(this.bufferSection)" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-2">Activate Block?</p>
                    <div class="buttons-group">
                        <button class="btn btn-sm btn-danger" (click)="confirmationModal.hide();resetSwitch(this.bufferSection)">Cancel</button>
                        <button class="btn btn-sm btn-success" (click)="confirmSave(this.bufferSection);confirmationModal.hide()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #socialModal="mdbModal" class="modal invite-modal">
    <div class="modal-dialog modal-md " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Edit {{socialItem.name}}</h6>
                    <button type="button" class="close" (click)="socialModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body ">
                    <form #socialForm="ngForm">
                        <div class="form-group">
                            <label for="url">{{socialItem.id == 2 ? 'Phone Number' : 'Link'}}</label>
                            <input type="url" name="link" *ngIf="socialItem.id == 2" required placeholder="1XXXXXXXXXX" class="form-control" [(ngModel)]="socialItem.link" [pattern]="numberPattern">
                            <input type="url" name="link" *ngIf="socialItem.id != 2" required class="form-control" [(ngModel)]="socialItem.link">
                            <!-- <div *ngIf="whNumberValid.errors?.pattern" class="alert alert-danger">
                                Phone Number not valid must only number.
                            </div> -->
                        </div>
                        <div class="text-center">
                            <button class="btn btn-sm btn-success" [disabled]="!socialForm.valid" (click)="socialModal.hide();updateSocialLink(socialItem.id)">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- descripion strip key modal -->
<div id="myModal" mdbModal #stripDescriptionModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel"> Add product perks and prices for {{bufferProduct.name}}</h6>
                <button type="button" class="close" data-dismiss="modal" (click)="stripDescriptionModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body strip_key-body">
                <div class="date-update pl-1 mb-2">
                    <small>Last Updated: <small *ngIf="secret_keyObj">{{secret_keyObj.updateTime | date:'short'}}</small></small>
                </div>
                <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
                    <mat-tab label="prices">
                        <div class="single-wash">
                            <h6>Single wash prices </h6>
                            <div class="single-wash-item single-wash-item-first">
                                <p class="single-wash-item-label">Single wash price <span class="text-bold">Before</span> discount</p>
                                <span class="single-wash-item-input">
                          <div class="input-group input-group-sm ">
                            <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                                <input type="text" class="form-control" [ngModel]="bufferProduct?.priceSingleWash?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Single_Wash_Price_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                                <span></span>
                            </div>
                            </span>
                            <div>
                                <mat-slide-toggle (change)="handleChange($event,ProductEditType.Single_Wash_Price_Before)" [disabled]="bufferProduct?.priceSingleWash?.priceBefore === ''" [checked]="bufferProduct?.priceSingleWash?.isShow"> </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="single-wash-item">
                            <p class="single-wash-item-label">Single wash price <span class="text-bold">After</span> discount</p>
                            <span class="single-wash-item-input">
                          <div class="input-group input-group-sm ">
                            <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                            <input type="text" class="form-control" [ngModel]="bufferProduct?.priceSingleWash?.priceAfter" (ngModelChange)="updatePriceBefore($event,ProductEditType.Single_Wash_Price_After)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                        </div>
                        </span>
                        <span class="btn-fake"></span>
            </div>
        </div>
        <div class="single-wash">
            <h6>Membership prices </h6>
            <div class="single-wash-item single-wash-item-first">
                <p class="single-wash-item-label">Membership price <span class="text-bold">Before</span> discount</p>
                <span class="single-wash-item-input">
                              <div class="input-group input-group-sm ">
                                <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
                <input type="text" class="form-control" [ngModel]="bufferProduct?.priceBeforeDiscount?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Price_Product_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
                <span></span>
            </div>
            </span>
            <div>
                <mat-slide-toggle (change)="handleChange($event,ProductEditType.Price_Product_Before)" [disabled]="bufferProduct?.priceBeforeDiscount?.priceBefore === ''" [checked]="bufferProduct?.priceBeforeDiscount?.isShow"> </mat-slide-toggle>
            </div>
        </div>
        <div class="single-wash-item">
            <p class="single-wash-item-label">Membership price <span class="text-bold">After</span> discount</p>
            <span class="single-wash-item-input">
                    <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b>
                              <!-- <div class="input-group input-group-sm ">
                                <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        </div> -->
        </span>
        <span class="btn-fake"></span>
    </div>
</div>
<div class="single-wash mb-2">
    <h6>Max Car</h6>
    <div class="single-wash-item single-wash-item-first">
        <p class="single-wash-item-label"> Max car price <span class="text-bold">Before</span> discount</p>
        <span class="single-wash-item-input">
                                  <div class="input-group input-group-sm ">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
        <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.priceBefore" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Price_Before)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
        <span></span>
    </div>
    </span>
    <div>
        <mat-slide-toggle (change)="handleChange($event,ProductEditType.Car_Max_Price_Before)" [disabled]="bufferProduct?.productsMaxCar?.priceBefore === ''" [checked]="bufferProduct?.productsMaxCar?.isShow"> </mat-slide-toggle>
    </div>
</div>
<div class="single-wash-item single-wash-item-first">
    <p class="single-wash-item-label"> Max car price <span class="text-bold">After</span> discount</p>
    <span class="single-wash-item-input">
                        <!-- <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b> -->
                                  <div class="input-group input-group-sm ">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
    <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.priceAfter" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Price_After)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
</div>
</span>
<span class="btn-fake"></span>
</div>
<div class="single-wash-item ">
    <p class="single-wash-item-label"> Number of Cars <span class="text-bold">(Max Cars)</span> </p>
    <span class="single-wash-item-input">
    <!-- <b class="py-2 mx-auto">$ {{getOriginalPrice(bufferProduct?.price)}} </b> -->
              <div class="input-group input-group-sm ">
                <span class="input-group-text" id="inputGroup-sizing-sm">Num</span>
    <input type="text" class="form-control" [ngModel]="bufferProduct?.productsMaxCar?.countCar" (ngModelChange)="updatePriceBefore($event,ProductEditType.Car_Max_Num_Of_Cars)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
</div>
</span>
<span class="btn-fake"></span>
</div>
</div>
</mat-tab>
<mat-tab label="Description & Perks">
    <div class="date-update strip-description pl-2 mb-2">
        <p>Product Description (Stripe)</p>
        <small>{{bufferProduct.description}}.</small>
    </div>
    <form class="form">
        <ol>
            <li *ngFor="let item of bufferProduct.customDescription">
                <input type="text" class="form-control" name="{{item.id}}" [(ngModel)]="item.description" placeholder="Add Perk here">
            </li>
        </ol>
    </form>
</mat-tab>
</mat-tab-group>



</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-danger" (click)="stripDescriptionModal.hide()">Cancel</button>
    <button class="btn btn-sm btn-success" (click)="addDesorptionProduct();stripDescriptionModal.hide()">Save</button>
</div>
</div>
</div>
</div>
