import { PersonService } from 'src/app/core/_services/person/person.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.scss']
})
export class ManageAccountsComponent implements OnInit {
  allAccounts: any[] = []
  showAccounts: any[] = []
  hiddenAccounts: any[] = []
  user: any = {}
  p1: number = 1
  p2: number = 1
  searchText1: string = ""
  searchText2: string = ""
  companyId: string = ''
  constructor(
    private route: ActivatedRoute,
    private _personService: PersonService,
    public socket: Socket,


  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.companyId = this.user.companyId
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['accounts'].success) {
        this.allAccounts = response['accounts'].data
        this.showAccounts = this.allAccounts.filter(a => a.isHideInSwitch == false)
        this.hiddenAccounts = this.allAccounts.filter(a => a.isHideInSwitch == true)
      }
    })
    this.socket.on(RealTimeKeys.Show_Hide_Update, (data: any) => {
      let isExists = this.allAccounts.find(c => c.companyId === data.companyId)
      if (isExists != undefined || isExists != null) {
        this._personService.getAllUserCompanies(this.user.uid).subscribe((response: ResponseModel) => {
          this.allAccounts = response.data
          this.showAccounts = this.allAccounts.filter(a => a.isHideInSwitch == false)
          this.hiddenAccounts = this.allAccounts.filter(a => a.isHideInSwitch == true)
        })
      }
    });
  }
  HideInSwitchCompanyPerson(id: string) {
    this._personService.HideInSwitchCompanyPerson(id).subscribe((response: ResponseModel) => {
    })
  }
  ShowInSwitchCompanyPerson(id: string) {
    this._personService.ShowInSwitchCompanyPerson(id).subscribe((response: ResponseModel) => {
    })
  }
}
