<div id="myModal" class="modal fade" data-toggle="modal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-newsletter">
        <div class="modal-content">
            <form (ngSubmit)="InviteUser()">
                <div class="modal-header">
                    <img src="{{companyInfo.companyLogo}}" alt="logo-img">
                    <div class="join-us">
                        <h4>Join Us </h4>
                        <h4>{{companyInfo.companyName}}</h4>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="routeToWordpress()"><span>&times;</span></button>
                </div>
                <div class="modal-body text-center">
                    <p>Join to be able to create car wash membership for your own vehicle.</p>
                    <div class="form-group">
                        <input type="email" class="form-control" name="emailCustomer" [(ngModel)]="emailCustomer" placeholder="Enter your email" required [pattern]="emailPattern" #emailCu="ngModel">
                        <div *ngIf="emailCu.errors && emailCu.touched" class="alert alert-danger">
                            <div *ngIf="emailCu.errors.required">
                                Email required.
                            </div>
                            <div *ngIf="emailCu.errors.pattern">
                                Email not valid.
                            </div>
                        </div>
                        <input type="text" class="form-control mt-3" name="phoneCustomer" [(ngModel)]="phoneCustomer" placeholder="Enter your phone number" required [pattern]="emailPattern" #emailCu="ngModel">
                        <input type="submit" [disabled]="emailCu.errors" class="btn btn-primary btn-block" value="Join">
                    </div>

                    <!-- <div class="footer-link"><a href="/">No Thanks</a></div> -->
                </div>
            </form>
        </div>
    </div>
</div>
<!-- <p class="hint-text"><strong>Note:</strong> Please refresh the page to reload the Invitation.</p> -->
<div id="basicModal" mdbModal #basicModal="mdbModal" class="modal confirm-modal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <h4 class="modal-title w-100">A confirmation email sent</h4>
            </div>
            <div class="modal-body text-center">
                <p class="text-center">Please check your email inbox or the spam box </p>
                <!-- <p> -->
                <a class="link" href="mailto:{{emailCustomer}}">{{emailCustomer}}</a>
                <!-- </p> -->
            </div>
            <div class="modal-footer" (click)="goBack()">
                <a class="btn btn-success " data-dismiss="modal" aria-hidden="true">OK</a>
            </div>
        </div>
    </div>
</div>