<div class="col-12 print">
    <button class="btn btn-sm btn-primary" (click)="printFixed()">Print </button>
</div>
<div id="all-inventories">
    <div class="tittle">
        <h6>Job Number: #{{objFromParent3?.inventories[0]?.jobNumber}}</h6>
    </div>
    <!-- jobsummary  -->
    <div class="row job-summary">
        <div class="col-12" *ngIf="objFromParent3?.jobSummary">
            <table class="table " id="firstTable">
                <thead>
                    <tr>
                        <th>Data Type</th>
                        <th>Actual</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Quantity</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualQuantity }}</td>
                    </tr>
                    <tr>
                        <td>Pads</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualPads }}</td>
                    </tr>
                    <tr>
                        <td>Boxs</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualBoxed }}</td>
                    </tr>
                    <tr>
                        <td>Value</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualValue }} $</td>
                    </tr>
                    <tr>
                        <td>Extra Service Fee</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualExtraServiceFee }} $</td>
                    </tr>
                    <tr>
                        <td>Weight</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualWeight }} lbs</td>
                    </tr>
                    <tr>
                        <td>Volume</td>
                        <td>{{objFromParent3?.jobSummary.itemsActualVolume }} CF</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Company information -->
    <div class="row" *ngIf="objFromParent3?.companyData">
        <div class="col-12">
            <div class="tittle">
                <h6>Company information</h6>
            </div>
            <div class="item-person">
                <label>Name : </label>
                <h6>{{objFromParent3?.companyData.companyName}}</h6>
            </div>
            <div class="item-person">
                <label>Address : </label>
                <h6>{{objFromParent3?.companyData.companyAddress?.addressOne}}, <span *ngIf="objFromParent3?.companyData.companyAddress?.addressTow">{{objFromParent3?.companyData.companyAddress?.addressTow}}, </span> {{objFromParent3?.companyData.companyAddress?.city}},
                    {{objFromParent3?.companyData.companyAddress?.state}} {{objFromParent3?.companyData.companyAddress?.zip}}
                </h6>
            </div>
            <div class="item-person">
                <label>Email : </label>
                <h6> <a href="mailto:{{objFromParent3?.companyData.companyEmail}}">{{objFromParent3?.companyData.companyEmail}}</a></h6>
            </div>
        </div>
    </div>
    <!-- customers information -->
    <div class="row" *ngIf="objFromParent3?.personData">
        <div class="col-12">
            <div class="tittle">
                <h6>Customer information</h6>
            </div>
            <div class="item-person">
                <label>Name : </label>
                <h6>{{objFromParent3?.personData.lastName}},{{objFromParent3?.personData.firstName}}</h6>
            </div>
            <div class="item-person">
                <label>Phone : </label>
                <h6>{{objFromParent3?.personData.phone}}</h6>
            </div>
            <div class="item-person">
                <label>Email : </label>
                <h6> <a href="mailto:{{objFromParent3?.personData.email}}">{{objFromParent3?.personData.email}}</a></h6>
            </div>
        </div>
    </div>
    <!-- Steps information -->
    <div class="row" *ngIf="objFromParent3?.stepData">
        <div class="col-12">
            <div class="tittle">
                <h6>Location(s) information</h6>
            </div>
            <div class="item-person" *ngFor="let item of objFromParent3.stepData">
                <label>Location {{item.priority}} : </label>
                <h6>{{item.nameNew}}</h6>
            </div>
        </div>
    </div>
    <!-- inventorires -->
    <div class="row mb-2 inventories page-break" *ngIf="objFromParent3?.inventories">
        <div class="col-12">
            <div class="tittle">
                <h6>Item(s) List</h6>
            </div>
        </div>
        <div class="col-12 " *ngFor="let item of objFromParent3?.inventories">
            <div class="first-row-header ">
                <span>N: {{item.itemNumber}}</span>
                <span *ngIf="item.category"> {{item.category}}</span>
                <span>{{item.name}}</span>
                <span>Qty: {{item.quantity}}</span>
            </div>
            <div class="tow-row" *ngIf="item.preExistingDamage">
                <h6 class="pr-1">Pre Existing Damage: </h6>
                <span>{{item.preExistingDamage}}</span>
                <!-- <span *ngIf="!item.preExistingDamage"><br></span> -->
            </div>
            <!-- <div class="three-row">
                <h6 class="pr-1">Delivery Condition: </h6>
                <span *ngIf="item.delivery">{{item.delivery}}</span>
                <span *ngIf="!item.delivery"><br></span>
            </div> -->
        </div>
    </div>
    <!-- signature-->
    <div class="row" *ngIf="objFromParent3?.jobSignature">
        <div class="col-12 signature">
            <div class="tittle">
                <h6>Signature(s)</h6>
            </div>
            <table class="table">
                <tbody class="mb-3" *ngFor="let item of objFromParent3?.jobSignature">
                    <tr>
                        <td> <strong>{{item.name}}</strong></td>
                        <td class="img-sign" rowspan="3">
                            <img src="{{item.image}}" alt="">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong class="role" *ngIf="item.role == 'TeamLader'">
                            Team Leader
                          </strong>
                            <strong class="role" *ngIf="item.role != 'TeamLader'">
                            {{item.role}}
                          </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="status-date">
                                <strong>{{item.status}}</strong>
                                <strong>{{item.date | date:'d/M/yy'}}</strong>
                            </div>
                        </td>
                    </tr>
                    <tr class="last">
                    </tr>
                </tbody>
                <br>
            </table>
        </div>
    </div>
</div>