<div class="card" id="job_details_id">
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <app-items></app-items>
            </div>
            <div class="col-12" *ngIf="companyPlan === 0  || companyPlan === 1  || companyPlan === 2 ">
                <app-job-status *ngIf="checkShow(toolJobsEditItem.jobStatus) == true && jobLayerStatusObj.value != statusActive.InActive"></app-job-status>
            </div>
            <div class="col-12" id="app-car-wash" *ngIf="role != 'customer' && role != '3rdparty'">
                <!-- *ngIf="checkShow(toolJobsEditItem?.Car_Wash) == true &&  carWashObj?.value != statusActive.InActive " -->
                <app-car-wash *ngIf="checkShow(toolJobsEditItem?.Assigned_Vehicle) == true &&  carWashObj?.value != statusActive.InActive "></app-car-wash>
            </div>
            <div class="col-12">
                <app-progress-status *ngIf="checkShow(toolJobsEditItem.progressStatus) == true &&  progressObj.value != statusActive.InActive"></app-progress-status>
            </div>
            <div class="col-sm-12" id="AssignCustomer" *ngIf="companyPlan === 0   || companyPlan === 1  || companyPlan === 2 ">
                <app-assigned-customers *ngIf="checkShow(toolJobsEditItem.assignedCustomers) == true && assignedCustomersObj.value != statusActive.InActive"></app-assigned-customers>
            </div>
            <div class="col-sm-12" id="AssignUser">
                <app-assigned-users *ngIf="checkShow(toolJobsEditItem.assignedUsers) == true  && assignedUsersObj.value != statusActive.InActive"></app-assigned-users>
            </div>
            <!-- <div class="col-12 mt-2" id="AssignTruck">
                <app-assigned-truck *ngIf="checkShow(toolJobsEditItem.assigned_Truck) == true &&  assignedTrucksObj.value != statusActive.InActive"></app-assigned-truck>
            </div> -->
            <!-- <div class="col-12 summary" *ngIf="companyPlan === 1">
                <app-summary *ngIf="checkShow('Inventory') == true"></app-summary>
            </div> -->
            <!-- <div class="col-sm-12" id="warehouse-map" *ngIf="companyPlan === 2 ">
                <app-warehouse-map></app-warehouse-map>
            </div> -->
            <div class="col-12" id="Stop">
                <app-stops *ngIf="checkShow(toolJobsEditItem.steps) == true &&  stepsObj.value != statusActive.InActive"></app-stops>
            </div>
            <div class="col-12 mt-2" id="DateTime" *ngIf="companyPlan === 0 || companyPlan === 1 || companyPlan === 2">
                <app-draft-job *ngIf="checkShow(toolJobsEditItem.Date_and_Time) == true &&  dateAndTimeObj.value != statusActive.InActive "></app-draft-job>
            </div>
            <div class="col-12" id="Note">
                <app-notes *ngIf="checkShow(toolJobsEditItem.notes) == true &&  notesObj.value != statusActive.InActive"></app-notes>
            </div>
            <!-- <div class="col-12" id="qr_code_id">
                <app-qr-code-inventory *ngIf="checkShow(toolJobsEditItem.Qr_Code_Inv) == true &&  qrCodeInventoryObj.value != statusActive.InActive"></app-qr-code-inventory>
            </div> -->
            <!-- <div class="col-12" id="Inventory">
                <app-Inventory *ngIf="checkShow(toolJobsEditItem.inventory) == true &&  inventoryObj.value != statusActive.InActive"></app-Inventory>
            </div> -->
            <!-- <div class="col-12" id="Supplies">
                <app-supplies></app-supplies>
            </div> -->
            <!-- <div class="col-12" id="Container" *ngIf="companyPlan === 1"> -->
            <!-- <div class="col-12" id="Container">
                <app-container *ngIf="checkShow(toolJobsEditItem.containers) == true &&  containersObj.value != statusActive.InActive"></app-container>
            </div> -->
            <div class="col-12" id="Signature">
                <app-signature *ngIf="checkShow(toolJobsEditItem.signatures) == true &&  signatureObj.value != statusActive.InActive "></app-signature>
            </div>
            <!-- <div class="col-12" id="MilitaryBill">
                <app-military-bill *ngIf="checkShow(toolJobsEditItem.Military_Bill) == true &&  militaryBill.value != statusActive.InActive "></app-military-bill>
            </div> -->
        </div>
    </div>
</div>