<div class="status-progress section-tool-job" *ngIf="userPermission.value != statusActive.InActive  ">
    <div class="tittle-section">
        <p>
            <!-- <i class="uil uil-notebooks  uil-medium pr-1"></i> -->
            <img src="./assets/img/icons/crm_icons/working-hours.png" alt="progress-status-img">
            <span>Progress Status</span>
            <!-- Limited to 12 characters -->
        </p>
        <div class="btn-add-new" (click)="addStatusProgressModal.show()" *ngIf="userPermission.value == statusActive.Active ">
            <span class="badge badge-success"><i class="far fa-plus-square pr-1" ></i>New Update</span>
        </div>
    </div>
    <div class="inner-section">
        <div *ngIf="statusProgressArr.length > 0">
            <mat-accordion class="example-headers-align " *ngFor="let item of statusProgressArr; let i=index">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.subject}}
                            <span class="title-info ">{{item.date | customDatePipe}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="add-by-date">
                            <span>By: {{item.addBy}}</span>
                            <span>{{item.date | customDatePipe}}</span>
                        </div>
                        <div class="row mb-2">
                            <div class="col-lg-4 col-sm-12 item-subject">
                                <span> {{item.subject}}</span>
                            </div>
                            <div class="col-lg-8  col-sm-12 item-description">
                                <span> {{item.description}}</span>
                            </div>
                            <div class="col-12 button-group" *ngIf="userPermission.value == statusActive.Active ">
                                <button class="btn btn-success" (click)="storeItem(item);editStatusProgressModal.show()">Edit </button>
                                <button class="btn  btn-danger" (click)="storeIndex(i);deleteModal.show()">Delete </button>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="ant-empty-image " *ngIf="statusProgressArr.length <=0 ">
            <img src="./assets/img/empty.svg " />
            <span>No Data</span>
        </div>
    </div>
</div>
<!-- Add Status Progress Modal  -->
<div mdbModal #addStatusProgressModal="mdbModal" class="modal " id="addStatusProgressModal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">New Progress Status</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addStatusProgressModal.hide()" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form #addForm="ngForm" id="addForm">
                        <div class=" form-group">
                            <label for="subject">Subject <span class="required">*</span> :</label>
                            <input type="text " id="subject" placeholder="Subject " required name="subject" [(ngModel)]="statusProgressModel.subject " minlength="2" maxlength="12" #usubject="ngModel" class="form-control " />
                            <div *ngIf="usubject.errors && usubject.touched">
                                <div *ngIf="usubject.errors.required" class="alert alert-danger">
                                    Subject is required
                                </div>
                                <div *ngIf="usubject.errors.minlength" class="alert alert-danger">
                                    Limited to 12 characters
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label for="description ">Description <span class="required">*</span> :</label>
                            <input name="description" required [(ngModel)]="statusProgressModel.description " class="form-control " placeholder="Description " minlength="2" maxlength="50" #uDescription="ngModel">
                            <div *ngIf="uDescription.errors && uDescription.touched">
                                <div *ngIf="uDescription.errors.required" class="alert alert-danger">
                                    Description is required
                                </div>
                                <div *ngIf="uDescription.errors.minlength" class="alert alert-danger">
                                    Limited to 50 characters
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-light " data-dismiss="modal " (click)="addStatusProgressModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-success " form="addForm " [disabled]="addForm.invalid " (click)="addProgressNotSendModal.show();addStatusProgressModal.hide()">Add </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Edit Status Progress Modal  -->
<div mdbModal #editStatusProgressModal="mdbModal" class="modal " id="editStatusProgressModal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Progress Status</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editStatusProgressModal.hide()" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <form #editForm="ngForm" id="editForm">
                        <div class=" form-group">
                            <label for="subject">Subject <span class="required">*</span> :</label>
                            <input type="text " placeholder="Subject " required name="subject" [(ngModel)]="editStatusProgressModel.subject " minlength="2" maxlength="12" #eSubject="ngModel" class="form-control " />
                            <div *ngIf="eSubject.errors && eSubject.touched">
                                <div *ngIf="eSubject.errors.required" class="alert alert-danger">
                                    Subject is required
                                </div>
                                <div *ngIf="eSubject.errors.minlength" class="alert alert-danger">
                                    Limited to 12 characters
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label for="description ">Description <span class="required">*</span> :</label>
                            <input name="description" required [(ngModel)]="editStatusProgressModel.description " class="form-control " placeholder=" Description " minlength="2" maxlength="50" #eDescription="ngModel">
                            <div *ngIf="eDescription.errors && eDescription.touched">
                                <div *ngIf="eDescription.errors.required" class="alert alert-danger">
                                    Description is required
                                </div>
                                <div *ngIf="eDescription.errors.minlength" class="alert alert-danger">
                                    Limited to 50 characters
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-light " data-dismiss="modal " (click)="editStatusProgressModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-success " form="editForm " [disabled]="editForm.invalid " (click)="updateStatusProgress();editStatusProgressModal.hide()">Update </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Delete Modal -->
<div mdbModal #deleteModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Progress Status</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteModal.hide()" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <p> Delete a Progress Status ?</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-light " data-dismiss="modal " (click)="deleteModal.hide() ">Cancel</button>
                    <button type="button " class="btn btn-danger " (click)="deleteStatusProgress();deleteModal.hide() ">Delete </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Send Progress  -->
<div mdbModal id="progressSendModal" #progressSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();progressSendModal.hide()">
                     <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();addStatusProgress();progressSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="addStatusProgress();progressSendModal.hide() "> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Confirm Not Send progress status -->
<div mdbModal id="addProgressNotSendModal" #addProgressNotSendModal="mdbModal" class="modal ">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addProgressNotSendModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Add a New Progress Status? </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="addProgressNotSendModal.hide() "> No </button>
                    <button type="button " class="btn btn-success " (click)="checkAddSendEmail();addProgressNotSendModal.hide() ">Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
