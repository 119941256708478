export enum CompanyPlan {
  Free,
  Pro,
  Premium,
}

export enum CompanyStatus {
  Deactivated,
  Canceled,
  Active,
  NotPay,
  NotPayPeriod,
}
