import { AuthService } from './../../../../core/_services/auth/auth.service';
import { PersonPendingService } from './../../../../core/_services/PendingPersons/personPending.service';
import { ResponseModel } from './../../../../models/response/ResponseModel';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
declare var google: any;
@Component({
  selector: 'app-invite-complete',
  templateUrl: './invite-complete.component.html',
  styleUrls: ['./invite-complete.component.scss']
})
export class InviteCompleteComponent implements OnInit {
  @ViewChild('addressText') addressText: any;
  inviteCompleteForm: FormGroup
  isLinear = false;
  email: any
  type: any
  role: any
  link: any
  companyId: any
  customerId: any
  idPerson :string = ''
  passwordPattern = ValidationModel.passwordPattern
  numberPattern = ValidationModel.numberPattern
  defaultValue :any= {}
  isShowPassword = false
  isVisible = false;
  place_id = ''
  formatted_address = ''
  lng = 0 
  lat = 0
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _personService: PersonService,
    private _personPendingService: PersonPendingService,
    public datePipe: DatePipe,
    public _authService: AuthService,
  ) { }

  ngOnInit() {
    this.defaultValue={
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.createInviteCompleteForm()
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      // this.type = params['type'];
      // this.role = params['role'];
      // this.companyId = params['companyId'];
      this.customerId = params['customerId'];
      this.idPerson = params['id'];
    });
    this.link = window.location.href
    if (this.customerId != null) {
      this._personService.getPersonById(this.customerId).subscribe((response: ResponseModel) => {
       if(response.success && response.data.uid == null && response.data.disable == false && response.data.isCanceled == false || response.success && response.data.uid == undefined && response.data.isCanceled == false ){
       this.isVisible = true
        this.email = response.data.email
        this.type = response.data.type
        this.companyId = response.data.companyId
        this.role = response.data.role
        this.inviteCompleteForm.patchValue(response.data)
       }
       else if (response.data.isCanceled == true){
        this.router.navigate(['/cancel-person'])
       }
       else if (response.data.uid != null && response.data.disable == false){
        this.router.navigate(['/second-invite'])
       }
       else if(response.data.data.disable == true){
        this.router.navigate(['/disable-customer'])
       }
      })
    }
    else{
      if (this.idPerson != null) {
        this._personPendingService.getPendingUser(this.idPerson).subscribe((response: ResponseModel) => {
        if(response.success){
          this.email = response.data.email
          this.type = response.data.type
          this.companyId = response.data.companyId
          this.role = response.data.role
         this.isVisible = true
        }
        else if(!response.success){
          this._personService.isEmailUserExists(this.email).subscribe((response:ResponseModel)=>{
            if(response.success){
              this.router.navigate(['/cancel-person'])
            }
            else{
              this.router.navigate(['/second-invite'])
            }
          })
        }
        })
      }
      // else if (this.idPerson == undefined || this.idPerson == null) {
      //   this.router.navigate(['/cancel-person'])
      // }
    }
  }
  createInviteCompleteForm() {
    this.inviteCompleteForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      phone: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      addressOne: ['', Validators.required],
      addressTow: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      country: [null],
    }, { validators: this.passwordMachValidetor })
  }
  passwordMachValidetor(g: FormGroup) {
    var password = g.get('password').value,
      confimePassword = g.get('confirmPassword').value;
    return password === confimePassword ? null : { 'mismatch': true };
  }
  get f() {
    return this.inviteCompleteForm.controls;
  }
    onCountrySelected(country: any) {
    this.defaultValue= country
    this.defaultValue = country
  }
  Invite() {
    // let role: any
    let model = Object.assign({}, this.inviteCompleteForm.value)
    // let current_date = new Date()
    // let date = this.datePipe.transform(current_date, 'M/d/yy, h:mm a')
    let userModel: any = {
      companyId: this.companyId,
      firstName: model.firstName,
      lastName: model.lastName,
      name: model.lastName + ', ' + model.firstName,
      email: this.email.trim().toLocaleLowerCase(),
      password: model.password,
      confirmPassword: model.confirmPassword,
      phone: model.phone,
      addressOne: model.addressOne,
      addressTow: model.addressTow,
      city: model.city,
      state: model.state,
      zip: model.zip,
      country: this.defaultValue,
      link: this.link,
      type: this.type,
      status: 'Unassigned',
      role: this.role,
      customPersonType: "Customer Type",
      isInvited: true,
      isCanceled: false,
      date: new Date(),
      dateFormate : this.datePipe.transform(new Date(), 'yyyy-d-M'),
      timeFormate : this.datePipe.transform(new Date(), 'h:mm a'),
      id:this.idPerson,
      lat :this.lat ,
      lng :this.lng ,
      formatted_address :this.formatted_address ,
      place_id :this.place_id 
    }
    if (this.customerId != null) {
      userModel.id = this.customerId
      this._personService.addCustomerFromInvite(userModel).subscribe((response: ResponseModel) => {
        this.router.navigate(['/login'])
      }, error => {
        console.log(error)
      })
    } else {
      this._personService.addInvitePerson(userModel).subscribe((response: ResponseModel) => {
        this.router.navigate(['/login'])
      }, error => {
        console.log(error)
      })
    }
  }
  showPassword() {
    this.isShowPassword = !this.isShowPassword
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       this.lat =  place.geometry.location.lat()
       this.lng = place.geometry.location.lng()
       this.formatted_address = place.formatted_address
       this.place_id =  place.place_id
      this.patchValuesAddress(this.getAddressFromComponents(place.address_components))
    });
  }
  patchValuesAddress(item:any){
    this.inviteCompleteForm.patchValue({
      addressOne: item.addressOne,
      addressTow: item.addressTow,
      state: item.state,
      zip: item.zip,
      city: item.city,
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      // country: countryName,
      zip: zip
    };
  }
}
