import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToasterCustomService } from './../../../../core/_services/toaster-service/ToasterCustomService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonService } from './../../../../core/_services/person/person.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { PersonPendingService } from 'src/app/core/_services/PendingPersons/personPending.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { CategoryCompany } from '../permissions/Enum-Category/CategoryCompany';
declare var $
@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {
  customers: any[] = []
  customersPending: any[] = []
  user: any
  jobs: any = []
  itemCustomer: string
  searchText = ''
  searchBendingText = ''
  p: number = 1;
  p2: number = 1;
  p3: number = 1;
  countryCode: boolean = true
  role: string = ''
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('reInviteCustomerModal') reInviteCustomerModal: ModalDirective;
  @ViewChild('disableCustomerModal') disableCustomerModal: ModalDirective;
  @ViewChild('cancelCustomerModal') cancelCustomerModal: ModalDirective;
  @ViewChild('inviteCustomerManuallyModal') inviteCustomerManuallyModal: ModalDirective;
  customerObj: any = {}
  tools: any = {}
  othersTools: any = {}
  buffId: string = ''
  isPending: boolean
  bufferDisable: string = ''
  bufferCustomerId: string = ''
  cancelPersonItem: any = {}
  customerManuallyObj: any = {}
  categoryCompany = CategoryCompany
  constructor(
    private _personService: PersonService,
    private route: ActivatedRoute,
    private _companiesService: CompaniesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private socket: Socket,
    private _toasterCustomService: ToasterCustomService,
    private _personPendingService: PersonPendingService,
    private _toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.countryCode = this._companiesService.getCountry()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        this.othersTools = this.tools.find(t => t.toolType == ToolType.Setting_Tools)
        if (this.role == 'admin' || this.role == 'manager' || this.role == 'teamleader' || this.role == 'crew') {
          this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
              this.customerObj = this._toolsService.getOverridePermission(this.role, this.othersTools, ToolSettingEditItem.Customers_list, ToolType.Setting_Tools, permissionList)
              // console.log( this.customerObj)
              if (this.role == 'admin' || this.customerObj.itemStatus == "Active" && this.customerObj.roleStatus == "active" || this.customerObj.itemStatus == "Active" && this.customerObj.roleStatus == "readOnly") {
                this.getCustomers()
                this.getCustomersPending()
              }
            }
          })
        }
      }
    }, error => {
      console.log(error)
    })
    // customers lists
    this.socket.on(RealTimeKeys.Customer_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._personService.getPersonById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.customers.unshift(response.data)
          }
        }, error => {
          console.log(error)
        })
      }
    });
    // customer pending realtime
    this.socket.on(RealTimeKeys.Invite_Customer, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._personPendingService.getPendingUser(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.customersPending.unshift(response.data)
          }
        }, error => {
          console.log(error)
        })
      }
    });
  }
  storeCustomerManually(item: any) {
    this.customerManuallyObj = item
    this.inviteCustomerManuallyModal.show()
  }
  getCustomers() {
    this._personService.getCustomersWithCountJobsByCompany(this.user.companyId,this.user.categoryCompany).subscribe((response: ResponseModel) => {
      this.customers = response.data
    })
  }
  getCustomersPending() {
    this._personPendingService.getAllPendingCustomers(this.user.companyId).subscribe((response: ResponseModel) => {
      this.customersPending = response.data
    })
  }
  routeCustomerDetails(item: any) {
    if (item.uid) {
      if (this.role == 'admin' || this.role == 'manager') {
        this.router.navigate(['/admin/customer-profile', item.id])
      }
      if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty' || this.role == 'customer') {
        this.router.navigate(['/user/customer-profile', item.id])
      }
    }
    else {
      localStorage.setItem('bufferCustomer',JSON.stringify(item))
      if (this.role == 'admin' || this.role == 'manager') {
        this.router.navigate(['/admin/add-customer-manually'])
      }
      if (this.role == 'teamleader' || this.role == 'crew' || this.role == '3rdparty' || this.role == 'customer') {
        this.router.navigate(['/user/add-customer-manually'])
      }
    }
    }
  invite() {
    localStorage.setItem("isCustomer", 'true')
    if (this.role == 'admin' || this.role == 'manager') {
      this.router.navigate(['/admin/invite'])
    }
    else {
      this.router.navigate(['/user/invite'])
    }
  }
  inviteManualCustomer() {
    if (this.role == 'admin' || this.role == 'manager') {
      this.router.navigate(['/admin/add-customer-manually'])
    }
    else {
      this.router.navigate(['/user/add-customer-manually'])
    }
  }
  ReInvitePerson(id: string) {
    this._personService.ReInviteCustomer(id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toasterCustomService.success('You can Cancel Invitation anytime later.', 'Customer Successfully Re-invited')
      }
      else {
        this._toasterCustomService.error('Please try again.', 'Error Re-invited')
      }
    }, error => {
      this._toasterCustomService.error('Please try again.', 'Error Re-invited')
    }
    )
  }
  InviteCustomer(item: any) {
    let id = item.id
    let index = this.customers.findIndex(c => c.id === item.id)
    this.spinner.show()
    this._personService.InviteCustomer(id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.customers[index] = response.data
        this._toasterCustomService.success('You can Invite anytime later.', 'Customer Successfully Invited')
      }
      else {
        this._toasterCustomService.error('Please try again', 'Customer Error Invited')
      }
    }, error => {
      this._toasterCustomService.error('Please try again', 'Customer Error Invited')
    })
  }
  ReInvitePersonPending(id: string) {
    this._personService.ReInviteCustomerPending(id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toasterCustomService.success('You can Cancel Invitation anytime later.', 'Pending Invite Successfully Re-invited')
      }
      else {
        this._toasterCustomService.error('Please try again', 'Pending Invite Error Re-invited')
      }
    }, error => {
      this._toasterCustomService.error('Please try again', 'Pending Invite Error Re-invited')
    }
    )
  }
  getAllJobsByCustomers(customerId: string) {
    this._personService.getAllJobsByCustomer(this.user.companyId, customerId).subscribe((response: ResponseModel) => {
      this.jobs = response.data
      this.basicModal.show()
    }, error => {
      console.log(error)
    })
  }
  storId(item: any) {
    this.itemCustomer = item
  }
  buffStoreId(id: string, isPending: boolean) {
    this.buffId = id
    this.isPending = isPending
    this.reInviteCustomerModal.show()
  }
  bufferStoreDisableId(event: any, id: string) {
    this.bufferCustomerId = id
    this.bufferDisable = event
    this.disableCustomerModal.show()
  }
  storeCancelPerson(item: any) {
    this.cancelPersonItem = item
    this.cancelCustomerModal.show()
  }
  cancelPerson(item: any) {
    let index = this.customers.indexOf(item)
    this._personService.cancelCustomerPerson(item.id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.customers[index] = response.data
        this._toasterCustomService.success('Link Sent is No More Valid. You can invite him\her anytime late.', 'Customer Successfully Canceled')
      }
      else {
        this._toasterCustomService.success('Please try again', 'Customer Error Canceled')
      }
    }, error => {
      console.log(error)
      this._toasterCustomService.success('Please try again', 'Customer Error Canceled')
    })
  }
  cancelPersonPending(item: any) {
    let index = this.customersPending.indexOf(item)
    this._personService.cancelCustomerPending(item.id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.customersPending.splice(index, 1)
        this._toasterCustomService.success('Link Sent is No More Valid. You can invite him\her anytime late.', 'Pending Invite Successfully Canceled')
      }
      else {
        this._toasterCustomService.error('Please try again', 'Pending Invite Error Canceled')
      }
    }, error => {
      console.log(error)
      this._toasterCustomService.error('Please try again', 'Pending Invite Error Canceled')
    })
  }
  disableUser(event: any, id: string) {
    if (event === 'disable') {
      this._personService.disableCustomer(id).subscribe((response: ResponseModel) => {
        if (response.success) {
          this._toasterCustomService.success('You can Disable anytime later.', 'Customer Successfully Disable')
        } else {
          this._toasterCustomService.error('Please try again', 'Customer Error Disable')
        }
      }, error => {
        this._toasterCustomService.error('Please try again', 'Customer Error Disable')
      })
    }
    else {
      this._personService.updateCustomerRole(id, event).subscribe((response: ResponseModel) => {
        if (response.success) {
          this._toasterCustomService.success('You can change role anytime later', 'Customer Role Successfully Changed')
        } else {
          this._toasterCustomService.error('Please try again', 'Customer Role Error Changed')
        }
      }, error => {
        this._toasterCustomService.error('Please try again', 'Customer Role Error Changed')
      })
    }
  }
  redirection(jobItem: any) {
    if (this.customerObj.roleStatus == "readOnly") {
    }
    else if (this.customerObj.roleStatus == "active" || this.role == 'admin') {
      let job: any = this.jobs.find(j => j.id == jobItem.id)
      let jobModel = {
        jobNumber: job.jobNumber,
        primaryCustomerName: job.primaryCustomerName,
        jobId: job.id,
        jobType: job.jobType
      }
      localStorage.setItem('jobModel', JSON.stringify(jobModel))
      if (this.role == 'admin' || this.role == 'manager') {
        this.router.navigate(['/admin/job-details/', jobItem.id])
      }
      else {
        this.router.navigate(['/user/job-details/', jobItem.id])
      }
      this.basicModal.hide()
    }
  }s
  restPagination(event) {
    this.p = 1
  }
  restPaginationPending(event) {
    this.p2 = 1
  }
  addNewVehicle(id:string){
    localStorage.setItem('customerExists',id)
    this.router.navigate(['/admin/add-vehicle'])
  }
}

