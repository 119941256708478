import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { ToolsService } from '../_services/tools/tools.service';

@Injectable()

export class GetToolsByCompanyIdResolver implements Resolve<any[]>
{
  constructor(private _toolsService: ToolsService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    return this._toolsService.getAllToolsByCompany(user.companyId).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
