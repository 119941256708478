export enum EmailNotification {
  Email_Notification_Invite_Reinvite = 1,
  Email_Notification_Canceled = 2,
  Email_Notification_Disable = 3,
  Email_Notification_SignedUp = 4,
  Email_Notification_Role_Change = 5,
  Email_Notification_Primary_Driver = 6,
  Email_Notification_Information_Saved = 7,
  Email_Notification_Manger_Read_Write = 8,
  Email_Notification_Manger_Disable = 9,
  Email_Notification_Customers_Assigned_Job = 10,
  Email_Notification_Customers_Unassigned_Job = 11,
  Email_Notification_Customer_Primary = 12,
  Email_Notification_Step_Add_Or_Changed = 13,
  Email_Notification_Date_Add_Or_Changed = 14,
  Email_Notification_Note_Add_Or_Changed = 15,
  Email_Notification_My_Profile_Change = 16,
  Email_Notification_Job_Status_Draft = 17,
  Email_Notification_Job_Status_Deal = 18,
  Email_Notification_Job_Status_InProcess = 19,
  Email_Notification_Job_Status_Done = 20,
  Email_Notification_Job_Status_Canceled = 21,
  Email_Notification_Users_Assigned_Job = 22,
  Email_Notification_Users_Unassigned_Job = 23,
  Email_Notification_Trucks_Assigned_Job = 24,
  Email_Notification_Inventory_Send_Email = 25,
  Email_Notification_Trucks_UnAssigned_Job = 26,
  Email_Notification_Active = 27,
  Email_Notification_Invitation_Canceled = 28,
  Email_Notification_Progress_Status_Added = 29,
  Email_Notification_New_Vehicle_Add = 30,
  Email_Notification_Military_Bill_Sent = 31,
  Email_Notification_Military_Bill_Storage_Expired = 32,
  Email_Notification_Military_Bill_PDF_Email = 33,
  Email_Notification_Assigned_Vehicle_Send_Email = 34,
}
