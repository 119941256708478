import { CompanyAdminModule } from '../modules/company-admin/company-admin.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModuleRoutingModule } from './layout-module-routing.module';
import { AdminLayoutComponent } from './pages/admin-layout/admin-layout.component';
import { CompanyUserModule } from '../modules/company-user/company-user.module';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    // UserLayoutComponent
  ],
  imports: [
    CommonModule,
    LayoutModuleRoutingModule,
    CompanyAdminModule,
    CompanyUserModule
  ],
  providers:[

  ]
})
export class LayoutModule { }
