import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, ValidationErrors } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class AsyncEmailUserValidator implements AsyncValidator {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> {
    // let model = {
    //   companyEmail: ctrl.value
    // }
    if (ctrl.value) {
      let  email = ctrl.value.toString().toLowerCase()
      return this.http.get<{ status: string }>(this.baseUrl + 'isEmailUserExists/'+ email)
        .toPromise()
        .then((result: any) => {
          if (result != undefined && !result.success) {
            return { invalid: true,mes:result.message};
          } else {
            // status is valid
            return null;
          }
        });
    } else {
      return Promise.resolve({ invalid: true, });
    }
  }
}
