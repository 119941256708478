import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/_services/loader/loader.service';

@Component({
  selector: 'app-Loader',
  templateUrl: './Loader.component.html',
  styleUrls: ['./Loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loading: boolean
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
   }

  ngOnInit() {
  }

}
