<!-- <mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Company Plan
        </mat-expansion-panel-header>
        <div class="accordion-body">

        </div>
    </mat-expansion-panel>
</mat-accordion> -->
<div class="pricing ">
    <div class="container">
        <div class="row">
            <!-- Free Tier -->
            <div class="col-lg-4">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center header-switch mt-3" *ngIf="role == 'admin'">Monthly
                            <!-- <mat-slide-toggle *ngIf="isFree" class="mat-checked pl-2 " (click)="changePlanYearly(1)">
                            </mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!isFree" (click)="changePlanYearly(1)"></mat-slide-toggle>
                            Yearly(Save 18%) -->
                        </h5>
                        <h5 class="card-title text-muted red-green text-uppercase text-center" *ngIf="user.categoryCompany == categoryCompany.Inventory">Basic</h5>
                        <h5 class="card-title text-muted red-green text-uppercase text-center" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Basic</h5>
                        <strong class="billed-annually-tittle">
                          <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                          <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">CRM Package</span>
                            <!-- <small *ngIf="isFree">(Billed Annually)</small>
                            <small *ngIf="!isFree">(Billed Monthly)</small> -->
                            <small>
                            <br>
                            </small>
                        </strong>
                        <!-- <h6 class="card-price text-center red-color" *ngIf="isFree"> -->
                        <!-- <span class="period">Normally : </span> -->
                        <!-- <span>Basic</span> -->
                        <!-- <span>  / Month</span> -->
                        <!-- </h6> -->
                        <!-- <h6 class="card-price text-center" *ngIf="isFree">Limited time offer: ${{pricingObj.basicYearlyDiscount}}
                            <small class="period"> / Month</small>
                        </h6> -->
                        <!-- <h6 class="card-price text-center red-color" *ngIf="!isFree"> -->
                        <!-- <span class="period">Normally : </span> -->
                        <!-- <span>Basic</span> -->
                        <!-- <span>  / Month</span> -->
                        <!-- </h6> -->
                        <h6 class="card-price text-center">Free
                            <!-- <small class="period"> / Month</small> -->
                        </h6>
                        <div class="text-center mb-3">
                            <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                            <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                        </div>
                        <!-- <hr> -->
                        <!-- <ul class="fa-ul">
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Jobs </li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Customize</strong> Items Inventory </li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Web Apps, Available on <strong>All Devices</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Customers
                            </li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Job
                                <strong>Scheduling</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Calendar View
                                <strong>Scheduling</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Simple Jobs <strong>Status Statistics</strong></li>
                            <li><span class="fa-li "><i class="far fa-check-circle"></i></span>
                                <strong>Custom </strong> Customer portal </li>
                            <li class="pb-3"><span class="fa-li"><i class="far fa-check-circle"></i></span>Cloud-Based,<strong>Real-Time Updates.</strong>
                            </li>
                            <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Off-line Technology</strong>, Connect Later
                            </li>
                            <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Real-Time</strong> customize Notifications
                            </li>
                            <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span> Customers
                                <strong>Permissions Levels</strong></li>
                            <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span>Individual Tool
                                <strong>Permissions Levels</strong></li>
                            <li *ngIf="isMore"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>No Hidden</strong> Fee or Equipment</li>
                        </ul> -->
                        <!-- <div class="text-center more mb-3" *ngIf="!isMore" (click)="toggle()">
                            <strong><span class="text-center"> Expand for more </span><i class="fas fa-angle-down fa-2x"></i></strong>
                        </div>
                        <div class="text-center more" *ngIf="isMore" (click)="toggle()">
                            <small><span class="text-center"><i class="fas fa-angle-up fa-2x"></i></span></small>
                        </div> -->
                        <div class="d-grid" *ngIf="role == 'admin' ">
                            <a class="btn btn-primary text-uppercase" (click)="storePlanType(0)">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Plus Tier -->
            <div class="col-lg-4">
                <div class="card mb-5 mb-lg-0">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center header-switch" *ngIf="role == 'admin' ">Monthly
                            <mat-slide-toggle *ngIf="isPro" class="mat-checked pl-2 " (click)="changePlanYearly(2)">
                            </mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!isPro" (click)="changePlanYearly(2)"></mat-slide-toggle>
                            Yearly (Save 18%)
                        </h5>
                        <h5 class="card-title text-muted text-uppercase text-center red-green">PRO PASS</h5>
                        <strong class="billed-annually-tittle">
                          <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                          <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Mobile Car Package</span>
                            <small *ngIf="isPro && user.categoryCompany == categoryCompany.Inventory">(Billed Annually)</small>
                            <small *ngIf="!isPro && user.categoryCompany == categoryCompany.Inventory">(Billed Monthly)</small>
                            <small *ngIf="user.categoryCompany == categoryCompany.Car_Wash">(Per Car, Billed Annually)</small>
                        </strong>
                        <h6 class="card-price text-center red-color" *ngIf="isPro">
                            <!-- <span class="period">Normally : </span> -->
                            <span>${{pricingObj.proYearly}}</span>
                            <span>  / Month</span>
                        </h6>
                        <!-- <h6 class="card-price text-center" *ngIf="isPro">
                            Limited time offer: ${{pricingObj.proYearlyDiscount}}
                            <small class="period"> / Month</small>
                        </h6> -->
                        <h6 class="card-price text-center red-color" *ngIf="!isPro">
                            <!-- <span class="period">Normally : </span> -->
                            <span>${{pricingObj.proMonthly}} </span>
                            <span>  / Month</span>
                        </h6>
                        <div class="text-center mb-3">
                            <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                            <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                        </div>
                        <!-- <h6 class="card-price text-center" *ngIf="!isPro">
                            Limited time offer: ${{pricingObj.proMonthlyDiscount}}
                            <small class="period"> / Month</small>
                        </h6> -->
                        <!-- <hr> -->
                        <!-- <ul class="fa-ul">
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span> <strong>Everything In Basic Plan</strong></li>
                        </ul> -->
                        <!-- <div class="plus"><strong>+</strong></div> -->
                        <!-- <ul class="fa-ul">
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Users</li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span> <strong>Multi-user</strong> Real-time</li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span> <strong>Assign</strong> Company Users</li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span> Job<strong> Scheduling</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Users <strong> Permissions Levels</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Reassign</strong> Jobs to 3rd Parties</li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Unlimited</strong> Trucks </li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span>Item
                                <strong>Label Templates</strong></li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span>
                                <strong>Customize</strong> Items Inventory</li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Truck Inventory Managment</strong></li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Inventory Managment by Address</strong>
                            </li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span>US-Based Premium <strong>support 24/7.</strong></li>
                            <li *ngIf="isMorePro"><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Full Access </strong>To All Accordev Pro Tools</li>
                        </ul> -->
                        <!-- <div class="text-center more mb-3" *ngIf="!isMorePro" (click)="togglePro()">
                            <strong><span class="text-center"> Expand for more </span><i class="fas fa-angle-down fa-2x"></i></strong>
                        </div>
                        <div class="text-center more" *ngIf="isMorePro" (click)="togglePro()">
                            <small><span class="text-center"><i class="fas fa-angle-up fa-2x"></i></span></small>
                        </div> -->
                        <div class="d-grid" *ngIf="role == 'admin' ">
                            <a class="btn btn-primary text-uppercase" (click)="storePlanType(1)">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pro Tier -->
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center header-switch" *ngIf="role == 'admin'">Monthly
                            <mat-slide-toggle *ngIf="!isPremium" (click)="changePlanYearly(3)"></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="isPremium" class="mat-checked pl-2 " (click)="changePlanYearly(3)">
                            </mat-slide-toggle>
                            Yearly (Save 18%)
                        </h5>
                        <h5 class="card-title text-muted text-uppercase text-center red-green">PREMIUM PASS
                        </h5>
                        <strong class="billed-annually-tittle">
                          <span *ngIf="user.categoryCompany == categoryCompany.Inventory">Per Location</span>
                          <span *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Location Package</span>
                            <small *ngIf="isPremium && user.categoryCompany == categoryCompany.Inventory">(Billed Annually)</small>
                            <small *ngIf="!isPremium && user.categoryCompany == categoryCompany.Inventory">(Billed Monthly)</small>
                            <small *ngIf="user.categoryCompany == categoryCompany.Car_Wash" >(Per Location, Billed Annually)</small>
                        </strong>
                        <h6 class="card-price text-center red-color" *ngIf="isPremium">
                            <!-- <span class="period">Normally : </span> -->
                            <span>${{pricingObj.premiumYearly}} </span>
                            <span>  / Month</span>
                        </h6>
                        <!-- <h6 class="card-price text-center" *ngIf="isPremium">
                            Limited time offer: ${{pricingObj.premiumYearlyDiscount}}
                            <small class="period"> / Month</small>
                        </h6> -->
                        <h6 class="card-price text-center red-color" *ngIf="!isPremium">
                            <!-- <span class="period">Normally : </span> -->
                            <span>${{pricingObj.premiumMonthly}} </span>
                            <span>  / Month</span>
                        </h6>
                        <!-- <h6 class="card-price text-center" *ngIf="!isPremium">
                            Limited time offer: ${{pricingObj.premiumMonthlyDiscount}}
                            <small class="period"> / Month</small>
                        </h6> -->
                        <!-- <hr> -->
                        <!-- <ul class="fa-ul">
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Everything in Pro Plan</strong>
                            </li>
                        </ul> -->
                        <!-- <div class="plus"><strong>+</strong></div> -->
                        <!-- <ul class="fa-ul">
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Customize</strong> Containers
                            </li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Containers <strong>Inventory Managment</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span>Container <strong>Label Templates</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Customize Virtual Warehouse Map</strong></li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong> Automated </strong> Military Invoicing</li>
                            <li><span class="fa-li"><i class="far fa-check-circle"></i></span><strong>Full Access </strong>To All Premuim Tools
                            </li>
                            <li class="mb-5"><br></li>
                        </ul> -->
                        <div class="text-center mb-3">
                            <a href="{{Car_Wash_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Car_Wash">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                            <a href="{{Inventory_Plans}}" target="_blank" *ngIf="user.categoryCompany == categoryCompany.Inventory">Learn More <i class="fas fa-arrow-right pl-2"></i></a>
                        </div>
                        <div class="d-grid" *ngIf="role == 'admin' ">
                            <a class="btn btn-primary text-uppercase" (click)="storePlanType(2)">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
