import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ActivatedRoute } from '@angular/router';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { ModalDirective } from 'angular-bootstrap-md';

interface Note {
  id: string,
  addBy: string,
  subject: string,
  description: string,
  date: Date
}
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  noteModel: Note = {} as Note
  notes: Note[] = []
  flipDiv = false;
  uniqId: string = ""
  oldNote: any ={}
  editNoteModel: any ={}
  userName: string = "User Name"
  jobDetails: JobDetails = {}
  jobId: string
  companyId: string
  p: number = 1
  user: any
  // notes: any
  noteText = ''
  userPermission: any = {}
  statusActive: any = StatusActive
  items: any[] = []
  role: string = ''
  jobsTools: any = {}
  noteObj: any = {}
  jobDetailsId: string
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  noteBufferForm: NgForm
  step;
  // for update
  @ViewChild('noteNotSendModal') noteNotSendModal: ModalDirective;
  @ViewChild('noteSendModal') noteSendModal: ModalDirective;
  // for add
  @ViewChild('addNoteSendModal') addNoteSendModal: ModalDirective;
  @ViewChild('addNoteNotSendModal') addNoteNotSendModal: ModalDirective;
  constructor(
    private spinner: NgxSpinnerService,
    private _jobDetailsService: JobDetailsService,
    private route: ActivatedRoute,
    private _jobsService: JobsService,
    public _toolsService: ToolsService
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user.role
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.companyId = this.user.companyId
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        if (this.role == "admin") {
          this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.notes)
          if(this.userPermission.itemStatus == 'Active') {
            this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
          }
          this.getNotes()
        }
        if (this.role != "admin" && this.role != "manager") {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.notes, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getNotes()
          }
        }
        if (this.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.notes, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getNotes()
          }
        }
      }
     }
    }, error => {
      console.log(error)
    })
  }
  checkItemPlan(){
    if(this._jobsService.getToolPlan(this.items,ToolJobsEditItem.notes) > this.user.companyPlan){
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getNotes() {
    this._jobDetailsService.getJobDetailsByIdItem(this.jobId,ToolJobsEditItem.notes).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.notes = response.data.notes
        this.jobDetailsId = response.data.id
        this.checkItemPlan()
      }
    })
  }
  onClick() {
    this.flipDiv = !this.flipDiv;

  }
  //********* Methods Note *******
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  checkEditSendEmail() {
    // this.noteBufferForm = noteForm
    let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.notes, EmailNotification.Email_Notification_Note_Add_Or_Changed)
    if (notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.noteSendModal.show()
      this.isCustomerEmail = Is_Customer_Email.Send_Email
    }
    else {
      this.updateNote()
    }
  }
  storeFrom(noteForm: NgForm){
    this.noteBufferForm = noteForm
  }
  checkAddSendEmail(noteForm:NgForm) {
    this.noteBufferForm = noteForm
    let notify = this._toolsService.getEmailNotification(this.role, this.jobsTools, ToolJobsEditItem.notes, EmailNotification.Email_Notification_Note_Add_Or_Changed)
    if (notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.addNoteSendModal.show()
      this.isCustomerEmail = Is_Customer_Email.Send_Email
    }
    else {
      this.addNote(this.noteBufferForm)
    }
  }
  resetAddForm() {
    this.noteBufferForm.reset()
  }
  addNote(noteForm: NgForm) {
    let note:any = Object.assign({},this.noteModel)
    note.addBy = this.user.displayName
    note.userId = this.user.id
    note.date = new Date()
    let idItem = ToolJobsEditItem.notes
    // note.jobId = this.jobId
    // note.companyId = this.companyId
    this.notes.push(note)
    // this.jobDetails.notes = this.notes
    // setTimeout(() => {
    this._jobDetailsService.updateJobDetails(this.notes, this.jobId, idItem, this.isCustomerEmail).subscribe((response: ResponseModel) => {
    }, error => {
      console.log(error)
    })
    // }, 2000);
    noteForm.reset()
  }
  storId(item:any) {
    this.editNoteModel = Object.assign({},item)
    // this.oldNote = Object.assign({},item)
    this.uniqId = item.id
  }
  deleteNote() {
    this.spinner.show()
    setTimeout(() => {
      this.notes.splice(this.notes.findIndex(p => p.id == this.uniqId), 1);
      this.spinner.hide();
    }, 3000);
  }
  setStep(index: number) {
    this.step = index;
  }
  pageChanged(event) {
    this.p = event
    this.step = ''
  }
  updateNote() {
    let index = this.notes.findIndex(p => p.id == this.uniqId);
    // console.log(index)
    this.notes[index] = this.editNoteModel
    this._jobDetailsService.updateJobDetails(this.notes, this.jobId, ToolJobsEditItem.notes, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      this.flipDiv = !this.flipDiv;
    }, error => {
      console.log(error)
    })
  }
}
