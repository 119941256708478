<!-- print tag one  -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Item Tag 1 </p>
            <div class="title-info">
                <span> Job Number, Item Number, Item Name, Qty</span>
            </div>
        </mat-expansion-panel-header>
        <!-- <ngx-print-element #element2="element"> -->
        <!-- <button class="badge badge-light mx-1" (click)="print.print('print-one', {printMode: 'template', pageTitle: 'Hello World'})">Print</button> -->
        <button class="badge badge-light mx-1" (click)="printFixed('print-one')">Print</button>
        <!-- </ngx-print-element> -->
        <div class="row pagebreak " id="print-one">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Number</label>
                    <h6>{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- print tag tow  -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Item Tag 2 </p>
            <div class="title-info">
                <span>Job Number, Item Number, Weight , Quantity </span>
            </div>
        </mat-expansion-panel-header>
        <!-- <ngx-print-element #element3="element">
            <button class="badge badge-light mx-1" (click)="element3.print('print-tow', {printMode: 'template', pageTitle: 'Hello World'})">Print</button>
        </ngx-print-element> -->
        <button class="badge badge-light mx-1" (click)="printFixed('print-tow')">Print</button>
        <div class="row" id="print-tow">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Number</label>
                    <h6>{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Weight</label>
                    <h6 *ngIf="objFromParent.weight">{{objFromParent.weight}}</h6>
                    <h6 *ngIf="!objFromParent.weight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- Summary 1   -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 1</p>
            <div class="title-info">
                <span>Job Number,Item Name , Category, Quantity </span>
            </div>
        </mat-expansion-panel-header>
        <!-- <ngx-print-element #element3="element">
            <button class="badge badge-light mx-1" (click)="element3.print('print-three', {printMode: 'template', pageTitle: 'Hello World'})">Print</button>
        </ngx-print-element> -->
        <button class="badge badge-light mx-1" (click)="printFixed('print-three')">Print</button>
        <div class="row" id="print-three">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Category</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- Summary 2   -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Summary 2</p>
            <div class="title-info">
                <span>Job.N,Item.N , Customer.N, Cat </span>
            </div>
        </mat-expansion-panel-header>
        <!-- <ngx-print-element #element4="element">
            <button class="badge badge-light mx-1" (click)="element4.print('print-four', {printMode: 'template', pageTitle: 'Hello World'})">Print</button>
        </ngx-print-element> -->
        <button class="badge badge-light mx-1" (click)="printFixed('print-four')">Print</button>
        <div class="row" id="print-four">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Job Number</label>
                    <h6>#{{objFromParent.jobNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Customer Name</label>
                    <h6 *ngIf="objFromParent.primaryCustomer">{{objFromParent.primaryCustomer}}</h6>
                    <h6 *ngIf="!objFromParent.primaryCustomer"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Category</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<!-- Standard   -->
<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <p>Standard</p>
            <div class="title-info">
                <span>All Data </span>
            </div>
        </mat-expansion-panel-header>
        <!-- <ngx-print-element #element5="element">
            <button class="badge badge-light mx-1" (click)="element5.print('print-five', {printMode: 'template', pageTitle: 'Hello World'})">Print</button>
        </ngx-print-element> -->
        <button class="badge badge-light mx-1" (click)="printFixed('print-five')">Print</button>
        <div class="row pagebreak" id="print-five">
            <div class="col-12 text-center">
                <!-- {{date | date:'short'}} -->
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Customer Name</label>
                    <h6 *ngIf="objFromParent.primaryCustomer">{{objFromParent.primaryCustomer}}</h6>
                    <h6 *ngIf="!objFromParent.primaryCustomer"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Number</label>
                    <h6>#{{objFromParent.itemNumber}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Item Name</label>
                    <h6>{{objFromParent.name}}</h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Catogary</label>
                    <h6 *ngIf="objFromParent.category">{{objFromParent.category}}</h6>
                    <h6 *ngIf="!objFromParent.category"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Weight</label>
                    <h6 *ngIf="objFromParent.weight">{{objFromParent.weight}}</h6>
                    <h6 *ngIf="!objFromParent.weight"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Volume</label>
                    <h6 *ngIf="objFromParent.volume">{{objFromParent.volume}}</h6>
                    <h6 *ngIf="!objFromParent.volume"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Quantity</label>
                    <h6 *ngIf="objFromParent.quantity">{{objFromParent.quantity}}</h6>
                    <h6 *ngIf="!objFromParent.quantity"><br></h6>
                </div>
            </div>
            <div class="col-12">
                <div class="item-print">
                    <label>Value</label>
                    <h6 *ngIf="objFromParent.price">{{objFromParent.price}}</h6>
                    <h6 *ngIf="!objFromParent.price"><br></h6>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
