import { ToolsService } from './../../../../core/_services/tools/tools.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { UpdateInfo } from '../edit-company/Enum/UpdateInfo';
declare var google: any;
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @ViewChild('addressText') addressText: any;
  userModel: any = {}
  informationModel: any = {}
  userAddressModel: any = {}
  addressModel: any = {}
  flipDiv = false;
  flipDiv1 = false;
  editModel: any = {}
  emailValidation = ValidationModel.emailPattern
  numberValidation = ValidationModel.numberPattern
  defaultValue: any = {} ;
  tools:any=[]
  othersTools:any={}
  myProfileObj:any={}
  role:string=''
  user:any={}
  place_id = ''
  formatted_address = ''
  lng = 0 
  lat = 0
  constructor(
    private route: ActivatedRoute,
    private _personService: PersonService,
    private spinner: NgxSpinnerService,
    private _toolsService: ToolsService,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role= this.user.role
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['user'].success) {
        this.userModel = response['user'].data
        this.userAddressModel = response['user'].data
        this.informationModel = Object.assign({}, this.userAddressModel)
        this.addressModel = Object.assign({}, this.userAddressModel)
      }
    })
    this.defaultValue = {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        this.othersTools = this.tools.find(t => t.toolType == ToolType.Setting_Tools)
        this._personService.getPersonById(this.user.id).subscribe((response:ResponseModel)=>{
          if(response.success){
            let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined  ) ? [] : response.data.permissionList ;
            this.myProfileObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.My_Profile,ToolType.Setting_Tools,permissionList)
          }
        })
        }
    }, error => {
      console.log(error)
    })
  }
  onClick() {
    this.flipDiv = !this.flipDiv;
    this.informationModel = Object.assign({}, this.userModel)
  }
  onClick1() {
    this.flipDiv1 = !this.flipDiv1;
    this.addressModel = Object.assign({}, this.userAddressModel)
    this.defaultValue = this.addressModel.country
  }
  onCountrySelected(country: any) {
    this.defaultValue = country
    this.addressModel.country = country
  }
  updateInformation() {
    let item:any= {
      firstName :  this.informationModel.firstName,
      lastName :  this.informationModel.lastName,
      email :  this.informationModel.email,
      phone :  this.informationModel.phone,
      customPersonType :  this.informationModel.customPersonType,
      name:this.informationModel.lastName + ', ' +  this.informationModel.firstName
    }
    this._personService.updatePerson( item, this.informationModel.id,UpdateInfo.Information).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.userModel = response.data
        this.addressModel = Object.assign({}, this.userModel)
        this.defaultValue = this.addressModel.country
        this.flipDiv = !this.flipDiv;
      }
    },error=>{
    })
  }
  updateAddress() {
    let item:any= {
      addressOne :  this.addressModel.addressOne,
      addressTow :  this.addressModel.addressTow,
      city :  this.addressModel.city,
      state :  this.addressModel.state,
      zip :  this.addressModel.zip,
      country : this.defaultValue,
      lat :this.lat ,
      lng :this.lng ,
      formatted_address :this.formatted_address ,
      place_id :this.place_id 
    }
    this._personService.updatePerson( item, this.userModel.id,UpdateInfo.Address).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.userAddressModel = response.data
        // this.userModel = this.userAddressModel
        this.informationModel = Object.assign({}, this.userAddressModel)
        this.flipDiv1 = !this.flipDiv1;
      }
    },error=>{
    })
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       this.lat =  place.geometry.location.lat()
       this.lng = place.geometry.location.lng()
       this.formatted_address = place.formatted_address
       this.place_id =  place.place_id
       this.addressModel = this.getAddressFromComponents(place.address_components)
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      // country: countryName,
      zip: zip
    };
  }
}
