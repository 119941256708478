import { PersonService } from '../_services/person/person.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class GetUserFilterByCompanyIdResolver implements Resolve<any[]>
{
  constructor(private _personService: PersonService) { }
  resolve(): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    return this._personService.getUsersFiltered(user.companyId).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
