import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';

@Component({
  selector: 'app-view-vehicle-global',
  templateUrl: './view-vehicle-global.component.html',
  styleUrls: ['./view-vehicle-global.component.scss']
})
export class ViewVehicleGlobalComponent implements OnInit {
  qrCodeId:string =''
  vehicle:any
  constructor(
    private _carWashService:CarWashService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.qrCodeId = this.route.snapshot.paramMap.get('id');
    this.getVehicleById()
  }
  getVehicleById(){
    let data = this.qrCodeId.split(',')
    this._carWashService.getVehiclePublicByQrCodeId(data[0],data[1]).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.vehicle = response.data
        // console.log(' vehicle', this.vehicle)
      }
    })
  }
}
