import { ToastrService } from 'ngx-toastr';
import { CompaniesService } from './../../../../core/_services/companies/companies.service';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
interface Status {
  id: number,
  name: string,
  isActive: string
}
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  user: any = {}
  companyPlan: any
  childData: string[];
  items: any
  subscription: Subscription;
  jobTools: any = {}
  toolBox: any = {}
  jobId: string = ''
  jobsTools: any = {}
  othersTools: any = {}
  homeTools: any = {}
  jobStatusObj:any = {}
  jobLayerStatusObj:any = {}
  assignedCustomersObj:any = {}
  assignedUsersObj:any = {}
  // assignedTrucksObj:any = {}
  stepsObj:any = {}
  dateAndTimeObj:any = {}
  notesObj:any = {}
  progressObj:any = {}
  // inventoryObj:any = {}
  // containersObj:any = {}
  signatureObj:any = {}
  carWashObj:any = {}
  // militaryBill:any = {}
  statusActive: any = StatusActive
  toolJobsEditItem: any = ToolJobsEditItem
  // qrCodeInventoryObj: any = {}
  role :string
  constructor(
    private _jobService: JobsService,
    private route: ActivatedRoute,
    private _companiesService: CompaniesService,
    private socket: Socket,
    private _toastrService: ToastrService,
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.role = this.user.role
    this.companyPlan =  this.user.companyPlan
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
      }
      if(response['tools'].success){
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        if (this.role != "admin" && this.role != "manager") {
          this.jobLayerStatusObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.jobStatus, this.user.id)
          this.assignedCustomersObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
          this.assignedUsersObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.assignedUsers, this.user.id)
          // this.assignedTrucksObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.assigned_Truck, this.user.id)
          this.stepsObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.steps, this.user.id)
          this.dateAndTimeObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Date_and_Time, this.user.id)
          this.notesObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.notes, this.user.id)
          this.progressObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.progressStatus, this.user.id)
          // this.inventoryObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
          // this.containersObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.containers, this.user.id)
          this.signatureObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.signatures, this.user.id)
          this.carWashObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Assigned_Vehicle, this.user.id)
          // this.militaryBill = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Military_Bill, this.user.id)
          // this.qrCodeInventoryObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Qr_Code_Inv, this.user.id)
        }
        if (this.role == "manager") {
          this.jobLayerStatusObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.jobStatus, this.user.id)
          this.assignedCustomersObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assignedCustomers, this.user.id)
          this.assignedUsersObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assignedUsers, this.user.id)
          // this.assignedTrucksObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.assigned_Truck, this.user.id)
          this.stepsObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.steps, this.user.id)
          this.dateAndTimeObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.Date_and_Time, this.user.id)
          this.notesObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.notes, this.user.id)
          this.progressObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.progressStatus, this.user.id)
          // this.inventoryObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.inventory, this.user.id)
          // this.containersObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.containers, this.user.id)
          this.signatureObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.signatures, this.user.id)
          this.carWashObj = this._jobService.getDefaultLayerPermission(this.items, ToolJobsEditItem.Assigned_Vehicle, this.user.id)
          // this.militaryBill = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Military_Bill, this.user.id)
          // this.qrCodeInventoryObj = this._jobService.getLayerPermission(this.items, ToolJobsEditItem.Qr_Code_Inv, this.user.id)
        }
      }
    })
    this.socket.on(RealTimeKeys.Job_Update_showItems, (data: any) => {
      if (this.jobId == data.id) {
        this._jobService.getShowItemsJobBy(this.jobId).subscribe((response: ResponseModel) => {
          this.items = response.data
        })
      }
    });
    // this.subscription = this._jobService.currentMessage.subscribe(items => {
    //   this.items = items
    // })
    // get jobs resolver
    // this.route.data.subscribe((response: ResponseModel) => {

    //   if (response['tools'].success) {
    //     let roleName = this.user.role
    //     this.jobTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
    //   }
    // }, error => {
    //   console.log(error)
    // })
  }

  // getCompanyPlan() {
  //   this.user = JSON.parse(localStorage.getItem('user'))
  //   this.companyPlan = this.user.companyPlan
  //   return this.companyPlan
  // }

  eventHandler(event: string[]) {
    this.childData = event;
  }
  checkShow(itemId: number) {
    let obj: any = this.items.find(i => i.itemId === itemId)
    if (obj == null)
      return false
    return obj.isShow
  }
  ngOnDestroy() {
    // this._toastrService.success('Successfully  Update draft job','')
  }

}
