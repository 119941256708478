<!-- My Account -->
<mdb-card class="my-account">
    <div class="my-profile-header">
        <h6>My Profile</h6>
    </div>
    <mdb-card-body>
        <mdb-card-title>
            <!-- <h4>My Profile</h4> -->
        </mdb-card-title>
        <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header>My Information</mat-expansion-panel-header>
                <ngx-flip [flip]="flipDiv">
                    <div front [style.display]="!flipDiv ? 'block' : 'none'">
                        <div class="card front-face">
                            <div class="title-card">
                                <button class="btn btn-success" (click)="onClick()" *ngIf="myProfileObj.itemStatus == 'Active' && myProfileObj.roleStatus == 'active' || role == 'admin' "><i class="fas fa-edit" ></i> Edit</button>
                                <!-- <button class="btn btn-primary" *ngIf="role === 'admin'" (click)="invite()"><i class="fas fa-paper-plane"></i>  Invite a Manager</button> -->
                                <span class="role">{{userModel.role}}</span>
                            </div>
                            <div class="row">
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> First Name </h6>
                                        <label>{{userModel.firstName}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Last Name </h6>
                                        <label>{{userModel.lastName}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Email </h6>
                                        <label>{{userModel.email}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Phone </h6>
                                        <label>{{userModel.phone}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> User Type </h6>
                                        <label>{{userModel.customPersonType}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div back [style.display]="flipDiv ? 'block' : 'none'">
                        <div class="card back">
                            <div class="title-card">
                                <button class="btn btn-primary" (click)="onClick()"><i class="fas fa-backward"></i> Back</button>
                                <button class="btn btn-success" form="accountForm1" [disabled]="!accountForm1.valid" (click)="updateInformation()"> <i class="far fa-save pr-1"></i>Save </button>
                            </div>
                            <form #accountForm1="ngForm" id="accountForm1">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <label for="fullName">First Name<span class="required">*</span></label>
                                        <input type="text" id="fullName" name="firstName" required class="form-control" [(ngModel)]="informationModel.firstName" #fullName="ngModel">
                                        <div *ngIf="fullName.errors && fullName.touched" class="alert alert-danger">
                                            <div *ngIf="fullName.errors.required">
                                                First Name required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="lastName">Last Name<span class="required">*</span></label>
                                        <input type="text" id="lastName" name="lastName" required class="form-control" [(ngModel)]="informationModel.lastName" #lastName="ngModel">
                                        <div *ngIf="lastName.errors && lastName.touched" class="alert alert-danger">
                                            <div *ngIf="lastName.errors.required">
                                                Last Name required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="email">Email<span class="required">*</span></label>
                                        <input type="text" id="email" name="email" readonly class="form-control" [(ngModel)]="informationModel.email" [pattern]="emailValidation" #email="ngModel">
                                        <div *ngIf="email.errors && email.touched" class="alert alert-danger">
                                            <div *ngIf="email.errors.required">
                                                Email is required.
                                            </div>
                                            <div *ngIf="email.errors.pattern">
                                                Email not valid.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12" *ngIf="role != 'customer'">
                                        <label for="type">User Type<span class="required">*</span></label>
                                        <input type="text" id="type" name="type" class="form-control" required [(ngModel)]="informationModel.customPersonType" #type="ngModel">
                                        <div *ngIf="type.errors && type.touched" class="alert alert-danger">
                                            <div *ngIf="type.errors.required">
                                                Type is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 mx-0">
                                        <label for="phone">Phone<span class="required">*</span></label>
                                        <input type="text" id="email" name="phone" required class="form-control" [(ngModel)]="informationModel.phone" [pattern]="numberValidation" #phone="ngModel">
                                        <div *ngIf="phone.errors && phone.touched" class="alert alert-danger">
                                            <div *ngIf="phone.errors.required">
                                                Phone is required.
                                            </div>
                                            <div *ngIf="phone.errors.pattern">
                                                Phone should be a number only
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ngx-flip>
            </mat-expansion-panel>
        </mat-accordion>
        <!-- Address -->
        <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false">
                <mat-expansion-panel-header> My Address</mat-expansion-panel-header>
                <ngx-flip [flip]="flipDiv1">
                    <div front [style.display]="!flipDiv1 ? 'block' : 'none'">
                        <div class="card front-face">
                            <div class="title-card">
                                <button class="btn btn-success" (click)="onClick1()" *ngIf="myProfileObj.itemStatus == 'Active' && myProfileObj.roleStatus == 'active' || role == 'admin' "><i class="fas fa-edit"></i> Edit</button>
                                <!-- <button class="btn btn-primary" *ngIf="role === 'admin'" (click)="invite()"><i class="fas fa-paper-plane"></i>  Invite a Manager</button> -->
                                <!-- <span class="role">{{userModel.role}}</span> -->
                            </div>
                            <div class="row">
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Address 1 </h6>
                                        <label>{{userAddressModel.addressOne}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Address 2 </h6>
                                        <label>{{userAddressModel.addressTow}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> City </h6>
                                        <label>{{userAddressModel.city}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> State </h6>
                                        <label>{{userAddressModel.state}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Zip Code </h6>
                                        <label>{{userAddressModel.zip}}</label>
                                    </div>
                                </div>
                                <div class=" col-sm-12">
                                    <div class="item">
                                        <h6> Country </h6>
                                        <label>{{userAddressModel.country?.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div back [style.display]="flipDiv1 ? 'block' : 'none'">
                        <div class="card back">
                            <div class="title-card">
                                <button class="btn btn-primary" (click)="onClick1()"><i class="fas fa-backward"></i> Back</button>
                                <button class="btn btn-success" form="accountForm2" [disabled]="!accountForm2.valid" (click)="updateAddress()"> <i class="far fa-save pr-1"></i>Save </button>
                            </div>
                            <form #accountForm2="ngForm" id="accountForm2">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <label for="addressOne">Address 1<span class="required">*</span></label>
                                        <input type="text" id="addressOne" name="addressOne" #addressText required class="form-control" [(ngModel)]="addressModel.addressOne" #addressOne="ngModel">
                                        <div *ngIf="addressOne.errors && addressOne.touched" class="alert alert-danger">
                                            <div *ngIf="addressOne.errors.required">
                                                Address One required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="addressTow">Address 2</label>
                                        <input type="text" id="addressTow" name="addressTow" class="form-control" [(ngModel)]="addressModel.addressTow">
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="city">City<span class="required">*</span></label>
                                        <input type="text" id="city" name="city" required class="form-control" [(ngModel)]="addressModel.city" #city="ngModel">
                                        <div *ngIf="city.errors && city.touched" class="alert alert-danger">
                                            <div *ngIf="city.errors.required">
                                                City required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="state">State<span class="required">*</span></label>
                                        <input type="text" id="state" name="state" required class="form-control" [(ngModel)]="addressModel.state" #state="ngModel">
                                        <div *ngIf="state.errors && state.touched" class="alert alert-danger">
                                            <div *ngIf="state.errors.required">
                                                State required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="zipCode">Zip Code<span class="required">*</span></label>
                                        <input type="text" id="zipCode" name="zip" required class="form-control" [(ngModel)]="addressModel.zip" [pattern]="numberValidation" #zip="ngModel">
                                        <div *ngIf="zip.errors && zip.touched" class="alert alert-danger">
                                            <div *ngIf="zip.errors.required">
                                                Zip Code required.
                                            </div>
                                            <div *ngIf="zip.errors.pattern">
                                                Zip code should be a number only.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label for="country">Country</label>
                                        <mat-select-country class="control" required [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                                        </mat-select-country>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ngx-flip>
            </mat-expansion-panel>
        </mat-accordion>
    </mdb-card-body>
</mdb-card>