import { environment } from 'src/environments/environment.prod';
import { PersonService } from './../../../../../core/_services/person/person.service';
import { UpdateCompany } from 'src/app/models/company/company';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { NgForm } from '@angular/forms';
import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { EmailNotification } from 'src/app/models/tools-model/EmailNotification';
import { Is_Customer_Email } from 'src/app/models/person/isCustomerEmail';
import { setInterval } from 'timers';
import { DatePipe } from '@angular/common';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { ToolDashboardEditItem } from 'src/app/models/tools-model/ToolDashboardEditItem';
import { PaymentType } from '../../car-wash-folder/Enum/PaymentType';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { Capacitor } from '@capacitor/core';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { SMSNotification } from 'src/app/models/tools-model/SmsNotification';
interface Item {
  id: number,
  name: string,
}
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @Output() data: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild('permissionsModal') permissionsModal: ModalDirective;
  @ViewChild('changePrimarySendModal') changePrimarySendModal: ModalDirective;
  @ViewChild('changePrimaryModal') changePrimaryModal: ModalDirective;
  @ViewChild('upgradePlanModal') upgradePlanModal: ModalDirective;
  @ViewChild('clonePinModal') clonePinModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
  @ViewChild('inventorySendModal') inventorySendModal: ModalDirective;
  @ViewChild('noPhoneModal') noPhoneModal: ModalDirective;
  @ViewChild('sendMessageModal') sendMessageModal: ModalDirective;
  @ViewChild('notSendMessageModal') notSendMessageModal: ModalDirective;
  @ViewChild('assignModal') assignModal: ModalDirective;

  primaryCustomerName: any = " "
  jobNumber: any
  jobModel: any = {}
  assignedCustomers: any
  itemsModel: any = []
  subscription: Subscription;
  typeJob: any
  jobType: any
  jobDetails: any = {}
  showItems: any = []
  customers: any = []
  customersList: any = []
  user: any = {}
  jobId: string = ''
  jobTools: any = {}
  dashboardTools: any = {}
  newJobPermission: any = {}
  settingsTools: any = {}
  // assignedCustomersObj: any = {}
  customersObj: any = {}
  toolBox: any = {}
  toolJobs = ToolJobsEditItem
  layerJobPermissions: any[] = []
  users: any[] = []
  index: number = 0
  defaultPermissionObj: any = {}
  countItemsShow: number = 0
  role: string
  numberPattern = ValidationModel.numberPattern
  isJobNumberExists = false
  jobObj: any = {}
  jobInformationTool: any = {}
  customerInformationTool: any = {}
  statusActive: any = StatusActive
  jobInfoShow: any = {}
  customerInfoShow: any = {}
  countToolsActive: number = 0
  itemTool: any = {}
  isCanEdit = true
  customerId: string = ''
  companyPlan: number
  isCustomerEmail = Is_Customer_Email.Not_Send_Email
  timer: any
  isAllItemsActive = true
  baseDomain = environment.baseDomain
  vehicle :any
  paymentTypeEnum = PaymentType
  emailPattern = ValidationModel.emailPattern
  tags: any[] = []
  bodySend: any ='any body'
  // isPinToClone = false
  // baseDomain ='http://localhost:4200/'
  carWashPermission: any={}
  bufferVehicle: any = {};
  servicesObj:any ={}
  isPrint = true
  currentCompany :any = {}
  dateObj: any
  modelSendItem: any = {}
  smsType :any
  buffVehicle:any = {}
  smsNotification = SMSNotification
  jobsTools:any={}
  carWashServices:any=[]
  additionalServices:any=[]
  usersToAssigned:any = []
  servicesSelected :any = []
  servicesOthersSelected :any = []
  totalDuNormal: number = 0
  totalDueOthers: number = 0
  receivedAmount = 0
  selectedUsers :any =[]
  isDisabled: boolean = false;
  selectedPackageIndex1

  constructor(
    private _jobService: JobsService,
    private _jobDetailsService: JobDetailsService,
    private _toolsService: ToolsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToasterCustomService,
    private socket: Socket,
    private _jobsService: JobsService,
    private _personService: PersonService,
    private datePipe: DatePipe,
    private _globalService : GlobalService,
    private _carWashService : CarWashService
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.dateObj = new Date();
    this.currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyPlan = this.user.companyPlan
    this.role = this.user.role
    this.layerJobPermissions = [
      {
        toolName: 'Users',
        isShow: false,
        assignedUser: [{
          id: 1,
          firstName: 'morhaf',
          lastName: 'Barmo',
          role: 'Admin',
          default: 'canEdit',
          permission: 'View Only | Can Edit | No Access'
        }]
      }
    ]
    this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
    this.primaryCustomerName = this.jobModel.primaryCustomerName
    this.jobNumber = this.jobModel.jobNumber
    this.jobType = this.jobModel.jobType
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.dashboardTools = response['tools'].data.find(d => d.toolType === ToolType.Dashboard_Tools)
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        this._personService.getPersonById(this.user.id).subscribe((res:ResponseModel)=>{
          if(res.success){
            let permissionList = (res.data.permissionList == null || res.data.permissionList == undefined  ) ? [] : res.data.permissionList ;
            this.newJobPermission = this._toolsService.getOverridePermission(this.user.role, this.dashboardTools, ToolDashboardEditItem.Create_New_Job,ToolType.Dashboard_Tools,permissionList)
          }
      })
        this.jobTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
        if (response['jobs'].success) {
          this.jobObj = response['jobs'].data
          this.vehicle =  this.jobObj.jobVehicleObj ??  this.vehicle
          this.modelSendItem.subject = 'Invoice for Job  #' + this.jobNumber + ' Plate: ' + this.vehicle?.plateNumber
          if(this.jobObj.isPinToClone == undefined || this.jobObj.isPinToClone == null){
            this.jobObj.isPinToClone = false
          }
          this.assignedCustomers = response['jobs'].data.assignedCustomers
          this.getCustomers()
          this.jobModel.jobNumber = response['jobs'].data.jobNumber
          this.jobModel.primaryCustomerName = response['jobs'].data.jobCustomerName
          this.primaryCustomerName = response['jobs'].data.jobCustomerName
          this.jobNumber = response['jobs'].data.jobNumber
          this.showItems = response['jobs'].data.showItems
          this.jobInfoShow = this.showItems.find(s => s.itemId == ToolJobsEditItem.jobInformation)
          this.customerInfoShow = this.showItems.find(s => s.itemId == ToolJobsEditItem.primaryCustomerInformation)
          this.itemsModel = this.showItems
          localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
          this.checkItems()
          if (this.role != "admin") {
            this.getCountItemsShowWithNoAccess()
          }
          if (this.role == 'admin') {
            // this.assignedCustomersObj = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.assignedCustomers)
            this.jobInformationTool = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.jobInformation)
            this.customerInformationTool = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.primaryCustomerInformation)
            this.carWashPermission = this._toolsService.getPermission(this.user.role,  this.jobTools,ToolJobsEditItem.Assigned_Vehicle)
            if (this.jobInformationTool.itemStatus == 'Active') {
              this.jobInformationTool = this._jobsService.getStaticPermission(this.statusActive.Active)
            }
            if (this.customerInformationTool.itemStatus == 'Active') {
              this.customerInformationTool = this._jobsService.getStaticPermission(this.statusActive.Active)
            }
          }
          if (this.role != 'admin' && this.role != "manager") {
            this.jobInformationTool = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.jobInformation, this.user.id)
            this.customerInformationTool = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.primaryCustomerInformation, this.user.id)
            // this.assignedCustomersObj = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.assignedCustomers, this.user.id)
            this.carWashPermission = this._toolsService.getPermission(this.user.role,  this.jobTools,ToolJobsEditItem.Assigned_Vehicle)
            if (this.jobInformationTool == undefined || this.jobInformationTool == null) {
              this.jobInformationTool = {}
            }
            if (this.customerInformationTool == undefined || this.customerInformationTool == null) {
              this.customerInformationTool = {}
            }
            // if (this.assignedCustomersObj == undefined || this.assignedCustomersObj == null) {
            //   this.assignedCustomersObj = {}
            // }
          }
          if (this.role == "manager") {
            this.jobInformationTool = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.jobInformation, this.user.id)
            this.customerInformationTool = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.primaryCustomerInformation, this.user.id)
            this.carWashPermission = this._jobsService.getDefaultLayerPermission(this.showItems,ToolJobsEditItem.Assigned_Vehicle,this.user.id)
            // this.assignedCustomersObj = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.assignedCustomers, this.user.id)
            if (this.jobInformationTool == undefined || this.jobInformationTool == null) {
              this.jobInformationTool = {}
            }
            if (this.customerInformationTool == undefined || this.customerInformationTool == null) {
              this.customerInformationTool = {}
            }
            // if (this.assignedCustomersObj == undefined || this.assignedCustomersObj == null) {
            //   this.assignedCustomersObj = {}
            // }
          }
          this.checkItemsShow()
        }
      }
      // if (response['jobDetails'].success) {
      //   this.jobDetails = response['jobDetails'].data
      // }
    })
    this.jobId = this.route.snapshot.paramMap.get('id');
    // realtime
    this.socket.on(RealTimeKeys.Customer_Primary_Change, (data: any) => {
      // console.log('data',data)
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this._jobsService.getJobById(data.jobId).subscribe((response: ResponseModel) => {
          // console.log('job by id ',response.data)
          if (response.success) {
            this.jobObj = response.data
            this.primaryCustomerName = data.name
            this.jobModel.primaryCustomerName = data.name
            localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
          }
        })
      }
    });
    this.socket.on(RealTimeKeys.Job_Number_Change, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.id) {
        this.jobNumber = data.numberJob
        this.jobModel.jobNumber = data.numberJob
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
      }
    });
    this.socket.on(RealTimeKeys.Assigned_Customer, (data: any) => {
      // console.log('Assigned_Customer',data)
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        this._jobService.getJobById(data.jobId).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.jobObj = response.data
            this.assignedCustomers = response.data.assignedCustomers
            this.jobModel.primaryCustomerName = response.data.jobCustomerName
            this.primaryCustomerName = response.data.jobCustomerName
            localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
            this.getCustomers()
            // console.log('job by id ',response.data)
            // if (this.assignedCustomers.length <= 0) {
            //   console.log(this.assignedCustomers.length)
            //   this.primaryCustomerName = 'Customer Name'
            //   this.jobModel.primaryCustomerName = 'Customer Name'
            //   localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
            // }
            // if (this.customersList.length == 1 && this.assignedCustomers.length > 0) {
            // console.log('this.customersList.length ',this.customersList.length)
            //   this.jobModel.primaryCustomerName = this.customersList[0].name
            //   this.primaryCustomerName = this.customersList[0].name
            //   localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
            // }
          }
        })
      }
    });
    this.socket.on(RealTimeKeys.Job_Update_showItems, (data: any) => {
      if (this.jobId == data.id) {
        this._jobService.getShowItemsJobBy(this.jobId).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.itemsModel = response.data
            this.showItems = response.data
            this.jobInfoShow = this.showItems.find(s => s.itemId == ToolJobsEditItem.jobInformation)
            // console.log('jobInfoShow',this.jobInfoShow)
            this.customerInfoShow = this.showItems.find(s => s.itemId == ToolJobsEditItem.primaryCustomerInformation)
            // console.log('primaryCustomerInformation',this.customerInfoShow)
            this.getCountToolsActivated()
            this.checkItemsShow()
            if (this.role != "admin") {
              this.getCountItemsShowWithNoAccess()
            }
            if (this.role == 'admin') {
              // this.assignedCustomersObj = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.assignedCustomers)
              this.jobInformationTool = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.jobInformation)
              this.customerInformationTool = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.primaryCustomerInformation)
              if (this.jobInformationTool.itemStatus == 'Active') {
                this.jobInformationTool = this._jobsService.getStaticPermission(this.statusActive.Active)
                // console.log('jobInformationTool',this.jobInformationTool)
              }
              if (this.customerInformationTool.itemStatus == 'Active') {
                this.customerInformationTool = this._jobsService.getStaticPermission(this.statusActive.Active)
                // console.log('customerInformationTool',this.customerInformationTool)
              }
            }
            if (this.role != 'admin' && this.role != "manager") {
              this.jobInformationTool = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.jobInformation, this.user.id)
              this.customerInformationTool = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.primaryCustomerInformation, this.user.id)
              // this.assignedCustomersObj = this._jobsService.getLayerPermission(this.showItems, ToolJobsEditItem.assignedCustomers, this.user.id)
              if (this.jobInformationTool == undefined || this.jobInformationTool == null) {
                this.jobInformationTool = {}
              }
              if (this.customerInformationTool == undefined || this.customerInformationTool == null) {
                this.customerInformationTool = {}
              }
              // if (this.assignedCustomersObj == undefined || this.assignedCustomersObj == null) {
              //   this.assignedCustomersObj = {}
              // }
            }
            if (this.role == "manager") {
              this.jobInformationTool = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.jobInformation, this.user.id)
              this.customerInformationTool = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.primaryCustomerInformation, this.user.id)
              // this.assignedCustomersObj = this._jobsService.getDefaultLayerPermission(this.showItems, ToolJobsEditItem.assignedCustomers, this.user.id)
              if (this.jobInformationTool == undefined || this.jobInformationTool == null) {
                this.jobInformationTool = {}
              }
              if (this.customerInformationTool == undefined || this.customerInformationTool == null) {
                this.customerInformationTool = {}
              }
              // if (this.assignedCustomersObj == undefined || this.assignedCustomersObj == null) {
              //   this.assignedCustomersObj = {}
              // }
            }
            this.checkShow(this.jobInfoShow.itemName)
            this.checkShow1(this.customerInfoShow.itemName)
          }
        })
      }
    });
      //  Vehicle_Check_In Check_Out and Subscription Realtime
      this.socket.on(RealTimeKeys.Vehicle_Assigned, (data: any) => {
        if (this.user.companyId == data.companyId && this.jobId ==  data.id) {
          this._jobsService.getJobById(data.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              this.jobObj =  response.data
              this.vehicle =  this.jobObj.jobVehicleObj ??  null
              this.getServices(response.data.jobVehicleObj,false)
            }
          })
        }
        })
        this.socket.on(RealTimeKeys.Service_Assigned, (data: any) => {
          if (this.user.companyId == data.companyId && this.jobId ==  data.id) {
            this._jobsService.getJobById(data.id).subscribe((response: ResponseModel) => {
              if (response.success) {
                this.jobObj =  response.data
                this.vehicle =  this.jobObj.jobVehicleObj ?? null
              }
            })
          }
          })
    this.getCountToolsActivated()
  }
  getCustomers() {
    this._personService.getCustomersByCompany(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.customers = response.data
        this.checkCustomersAssigned()
      }
    })
  }
  getCountItemsShowWithNoAccess() {
    for (let index = 0; index < this.showItems.length; index++) {
      for (let j = 0; j < this.showItems[index].users.length; j++) {
        if (this.showItems[index].users[j].id == this.user.id && this.showItems[index].users[j].defaultPermission != null && this.showItems[index].users[j].defaultPermission.value != 0) {
          this.countItemsShow++
        }
      }
    }
    return this.countItemsShow
  }
  // getDefaultPermission(itemId: number) {
  //   this.defaultPermissionObj = this._jobsService.getDefaultLayerPermission(this.itemsModel, itemId, this.user.id)
  //   return this.defaultPermissionObj
  // }
  getLayerPermission(itemId: number) {
    this.defaultPermissionObj = this._jobsService.getLayerPermission(this.itemsModel, itemId, this.user.id)
    return this.defaultPermissionObj
  }
  checkItems() {
    for (let i = 0; i < this.itemsModel.length; i++) {
      for (let j = 0; j < this.showItems.length; j++) {
        if (this.itemsModel[i].itemName === this.showItems[j].itemName && this.showItems[j].show == true)
          this.itemsModel[i].show = true
      }
    }
  }
  isJobNumberExist(value) {
    clearInterval(this.timer)
    this.timer = setTimeout(() => {
      if (value != '' && isNaN(+value) == false) {
        this._jobService.isJobNumberExist(this.user.companyId, Number(value)).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isJobNumberExists = false
          }
          else {
            this.isJobNumberExists = true
          }
        })
      }
      else {
        this.isJobNumberExists = false
        return null
      }
    }, 500);
  }
  updateJobNumber() {
    this.jobNumber = this.jobNumber
    this._jobService.updateJobNumber(this.jobModel.jobId, this.jobNumber).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobModel.jobNumber = response.data.jobNumber
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
      }
    })
  }
  isNotSendCustomer() {
    this.isCustomerEmail = Is_Customer_Email.Not_Send_Email
  }
  storeId(id: string) {
    this.customerId = id
  }
  checkAddSendEmail() {
    let notify = this._toolsService.getEmailNotification(this.user.role, this.jobTools, ToolJobsEditItem.assignedCustomers, EmailNotification.Email_Notification_Customer_Primary)
    if (notify.notifyStatus && notify.notifyStatus == 'Active') {
      this.isCustomerEmail = Is_Customer_Email.Send_Email
      this.changePrimarySendModal.show()
    }
    else {
      this.updateJobName(this.customerId)
    }
  }
  updateJobName(id: string) {
    this.primaryCustomerName = this.customersList.find(c => c.id === id).name
    this._jobService.updateJobPrimaryCustomer(this.jobModel.jobId, id, this.isCustomerEmail).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.jobModel.primaryCustomerName = response.data.primaryCustomerName
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
      }
      else {
        this.primaryCustomerName = this.jobModel.primaryCustomerName
      }
    })
  }
  undoJobNumber() {
    this.jobNumber = this.jobModel.jobNumber
  }
  changeShow(index: number) {
    this.itemsModel[index].isShow = !this.itemsModel[index].isShow
    this.refreshPage()
  }
  changeTypeJob(form: NgForm) {
    // console.log(this.typeJob)
    this._jobService.updateJobType(this.jobModel.jobId, this.typeJob).subscribe((response: ResponseModel) => {
      this.jobType = response.data.jobType
      this.jobModel.jobType = this.jobType
      localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
    }, error => {
      this.jobType = this.jobModel.jobType
    })
    form.reset()
  }
  checkCustomersAssigned() {
    this.customersList = []
    for (let index = 0; index < this.customers.length; index++) {
      for (let j = 0; j < this.assignedCustomers.length; j++) {
        if (this.assignedCustomers[j] == this.customers[index].id) {
          this.customersList.push({
            id: this.customers[index].id,
            name: this.customers[index].name
          })
        }
      }
    }
  }
  refreshPage() {
    this._jobService.updateJobDetailsShowItems(this.jobId, this.itemsModel).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.itemsModel = response.data
      }
    })
  }
  coloneJob() {
    let url = ''
    let id = this.route.snapshot.paramMap.get('id');
    let todayDate = new Date();
    let creationDate = (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate());
    let dateAndTime =
      [{
        id: 1,
        startDateWindowFrom: creationDate,
        startDateWindowTo: creationDate,
        startTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        startTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        endDateWindowFrom: creationDate,
        endDateWindowTo: creationDate,
        endTimeWindowFrom: this.datePipe.transform(todayDate, 'h:mm a'),
        endTimeWindowTo: this.datePipe.transform(todayDate, 'h:mm a'),
        jobDescription: " ",
        summaryDate: creationDate + ' --> ' + creationDate
      }]
    let model = {
      creationDate: creationDate,
      creationTime: todayDate.toLocaleTimeString(),
      // companyNumber: this.user.companyNumber,
      dateAndTime: dateAndTime,
      creationUserId : this.user.id,
    }
    this._jobService.cloneJobById(id, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        // this.toastr.success('Welcome in new job', 'Successfully Clone Job')
        this.jobModel.jobId = response.data.jobId
        this.jobModel.jobNumber = response.data.jobNumber
        this.jobModel.jobType = response.data.jobType
        this.jobModel.primaryCustomerName = response.data.primaryCustomerName
        localStorage.setItem('jobModel', JSON.stringify(this.jobModel))
        if (this.user.role == 'admin' || this.user.role == 'manager') {
          // this.router.navigate(['/admin/job-details/', response.data.jobId])
          url =`${this.baseDomain}admin/job-details/${response.data.jobId}`
        }
        else {
          // this.router.navigate(['/user/job-details/', response.data.jobId])
          url =`${this.baseDomain}user/job-details/${response.data.jobId}`
        }
        // console.log(url)
        // setTimeout(() => {
          window.location.href = url;
        //  }, 1000);
      } else {
        this.toastr.error('Please try again', 'Error Clone Job')
      }
    }, error => {
      this.spinner.hide()
    })
  }
  checkPermission(toolType: any) {
    this.toolBox = this._toolsService.getPermission(this.user.role, this.jobTools, toolType)
    return this.toolBox
  }
  permissionUsersShow(index: any) {
    this.index = index
    this.itemTool = this.itemsModel[index]
    this.users = this.itemsModel[index].users.filter(u => u.role != 'manager')
    if (this.role == "admin" || this.role == 'manager') {
      this.users = this.itemsModel[index].users.filter(u => u.role != 'manager')
    }
    else if (this.role == "teamleader") {
      this.users = this.itemsModel[index].users.filter(u => u.role != 'manager' && u.role != "teamleader")
    }
    else if (this.role == "crew") {
      this.users = this.itemsModel[index].users.filter(u => u.role != 'manager' && u.role != "teamleader" && u.role != "crew")    }
    else{
      this.users = []
    }
    this.permissionsModal.show()
    console.log(this.users)
  }
  checkItemInCustomer(itemTool: any, role: string) {
    if (itemTool.itemId == ToolJobsEditItem.assignedUsers && role == 'customer'
      || itemTool.itemId == ToolJobsEditItem.assignedCustomers && role == 'customer'
      || itemTool.itemId == ToolJobsEditItem.assigned_Truck && role == 'customer'
      || itemTool.itemId == ToolJobsEditItem.jobInformation && role == 'customer'
      || itemTool.itemId == ToolJobsEditItem.jobInformation && role == '3rdparty'
      || itemTool.itemId == ToolJobsEditItem.jobStatus && role == 'customer'
    ) {
      this.isCanEdit = false
    }
    else {
      this.isCanEdit = true
    }
  }
  changeUserPermission(newValue: any, id: string) {
    let j = this.itemsModel[this.index].users.findIndex(u => u.id == id)
    this.itemsModel[this.index].users[j].permission.value = Number(newValue)
    this.itemsModel[this.index].users[j].isUserUpdated = true
    this.itemsModel[this.index].isUpdated = true
    this.itemsModel[this.index] = this.itemsModel[this.index]
  }
  checkShow(item: string) {
    if (this.jobInfoShow == null || this.jobInfoShow == undefined) {
      return false
    } else {
      let obj: any = this.showItems.find(i => i.itemName === item)
      if (obj == null || obj == undefined)
        return false
      return obj.isShow
    }
  }
  checkShow1(item: string) {
    if (this.customerInfoShow == null || this.customerInfoShow == undefined) {
      return false
    } else {
      let obj: any = this.showItems.find(i => i.itemName === item)
      if (obj == null || obj == undefined)
        return false
      return obj.isShow
    }
  }
  getCountToolsActivated() {
    this.countToolsActive = 0
    for (let index = 0; index < this.showItems.length; index++) {
      if (this.showItems[index].isShow == true) {
        for (let j = 0; j < this.showItems[index].users.length; j++) {
          if (this.showItems[index].users[j].id == this.user.id && this.showItems[index].users[j].permission.value != 0) {
            this.countToolsActive++
          }
        }
      }
    }
    return this.countToolsActive
  }
  upgradePlan() {
    if (this.user.role == 'admin') {
      this.router.navigate(['/admin/edit-company'])
    }
    else if (this.user.role == 'manager') {
      // this._toolsService.getAllToolsByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
      //   let othersTools = response.data.find(t=>t.toolType =  )
      //  let mangerObj = this._toolsService.getPermission(this.user.role, this.jobTools, ToolJobsEditItem.assignedCustomers)

      // })
      this.upgradePlanModal.show()
    }
    else {
      this.upgradePlanModal.show()
    }
  }
  checkItemsShow(){
    // let count = 0
    // for (let index = 0; index < this.showItems.length; index++) {
    //   if(this.showItems[index].isShow = true){
    //    count++
    //   }
    // }
   this.isAllItemsActive = this.showItems.every(element => element.isShow === true)
    // console.log(this.showItems.every(element => element.isShow === true))
    // return this.showItems.every(element => element.isShow === true);
  }
  handleChange(event) {
    if (event.checked) {
      for (let index = 0; index < this.itemsModel.length; index++) {
        this.itemsModel[index].isShow = true
      }
    }
    else {
      for (let index = 0; index < this.itemsModel.length; index++) {
        this.itemsModel[index].isShow = false
      }
    }
    this.refreshPage()
  }

  handleChangeClone(event) {
    if (event.checked) {
      this.jobObj.isPinToClone = true
      this.clonePinModal.show()
    }
    else{
      this.jobObj.isPinToClone = false
      this.clonePinModal.show()
    }
    }
    pinCloneJob(){
      this._jobService.pinCloneJob(this.jobId,this.jobObj.isPinToClone).subscribe((response:ResponseModel)=>{
        if(response.success){
          this.jobObj.isPinToClone  = response.data.isPinToClone
          if(this.jobObj.isPinToClone){
            this.toastr.success('','Job Successfully Pinned')
          }else{
            this.toastr.success('','Job Successfully Unpinned')
          }
        }
      })
    }
    routeToTools(){
      localStorage.setItem('selectedIndex','1')
      this.router.navigate(['/admin/tools'])
    }
    getCurrency(){
      return this._globalService.getCurrency()
    }
    // getDateAssigned(vehicle:any){
    //   return vehicle.servicesDetailing.find(v =>v.jobId == this.jobId).servicesObj.date
    // }
    getDateAssigned(vehicle:any){
      let buffVehicle :any = {}
      if(vehicle?.servicesDetailing && vehicle.servicesDetailing.length > 0) {
         buffVehicle =  vehicle.servicesDetailing.find(v =>v.jobId == this.jobId)
         buffVehicle = buffVehicle ? buffVehicle.servicesObj : {}
      }
      return buffVehicle.date
    }
    getService(vehicle:any){
      let servicesObj :any = {}
      if(vehicle.servicesDetailing && vehicle.servicesDetailing.length > 0){
        servicesObj =   vehicle.servicesDetailing.find(v => v.jobId == this.jobId)
        servicesObj = servicesObj ? servicesObj.servicesObj : {}
      }
      return servicesObj
     }
     storeValuesToPrint(vehicle:any){
      console.log(vehicle)
      this.bufferVehicle = vehicle
      this.servicesObj = this.getService(vehicle)
      setTimeout(() => {
          this.printInvoice()
      }, 500);
    }
    printInvoice() {
      if (Capacitor.isNativePlatform()) {
        this.isPrint = true
        this.printScanMobile.show()
      }
      else{
        let printContents: any = ''
        printContents = document.getElementById('receipt_id').innerHTML
        let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
           <html>
             <head>
               <title>print</title>
               <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
               <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
               <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
               <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
               <style>
               body {
                text-align: center;
                margin-top: 4rem;
              }
            .tittle-invoice{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0;
              width: 50%;
              margin: auto;
             }
            .item {
              display: flex;
              justify-content: revert;
              align-items: flex-start;
              margin: 1rem auto;
              width: 50%;
          }
          .first-side  img {
            width: 175px;
            height: 175px;
            }
            .second-side .info {
              text-align: left;
              margin-top: 1rem;

            }
            .second-side .info p{
              margin-bottom: .3rem !important
            }
            .services-block{
              border: 1px solid #ccc;
              padding: 11px;
              border-radius: 3px;
              width: 50%;
              margin: 4rem auto;
            }
            .services-block  .tittle-service{
              display: flex;
              justify-content: space-between;
              align-items: center;
              transform: translateY(-35px);
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 10px  8px;
              background: #fff;
              width: 67%;
              margin: auto ;
            }
            .services-block .vehicle-info{
              justify-content: center ;
            }
            .services-block  .tittle-service h5 {
              margin: 0 ;
            }
            hr{
              width: 100%;
              margin: 1rem 0;
            }
            .others-normal-services p{
                  display: flex;
                  justify-content: space-between;
            }
               </style>
             </head>
             <body onload="window.print()">${printContents}</body>
           </html>`
        );
        popupWin.document.close();
      }
    }
    sendEmailItems(sendForm: NgForm) {
      this.modelSendItem.ccVal = this.tags
      // this.modelSendItem.emailBody = this.bodySend
      this.modelSendItem.email = this.jobObj.jobCustomerObj.email
      this.modelSendItem.date = this.datePipe.transform(new Date(), 'MMM d, y, h:mm:ss a'),
      this._carWashService.sendVoiceByEmail(this.jobId, this.modelSendItem).subscribe((response: any) => {
        this.toastr.success('Email successfully sent', '')
        this.bodySend = 'any body'
        // this.modelSendItem.emailBody = this.bodySend
        this.modelSendItem.subject = 'Invoice for job number #' + this.jobNumber
      })
      sendForm.reset()
    }
    onNameChange(val) {
      this.modelSendItem.emailBody = val
    }
    timeConverter(UNIX_timestamp) {
  return new Date(UNIX_timestamp * 1000)
}
sendModal() {
  this._carWashService.getPrintBodyTemplate(this.user.companyId, this.jobId).subscribe((response: ResponseModel) => {
    this.bodySend = response.data
    // this.modelSendItem.emailBody = this.bodySend
    this.modelSendItem.subject = 'Invoice for Job  #' + this.jobNumber + ' Plate: ' + this.vehicle?.plateNumber
    this.inventorySendModal.show()
  })
}
checkAction(event: any, vehicle: any){
  this.smsType = event
  if(vehicle.phone == undefined ||  vehicle.phone == '' || vehicle.phone == null){
    this.noPhoneModal.show()
  }
  else{
    this.buffVehicle = vehicle
    this.smsType = event
    if(event == this.smsNotification.Car_Wash_Ready_to_Served){
      let car_Wash_Ready_to_Served = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Ready_to_Served.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Ready){
      let car_ready = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_ready.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Service_Canceled){
      let car_Wash_Service_Canceled = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Service_Canceled.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
    if(event == this.smsNotification.Car_Wash_Job_Started){
      let car_Wash_Job_Started = this.jobsTools.items.find(t => t.itemId == ToolJobsEditItem.Assigned_Vehicle).SmsNotification.find(e => e.type == event)
      car_Wash_Job_Started.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
    }
  }
}
sendSMSMessage(){
  let model = {
    smsType: this.smsType,
    date: (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1)) + '-' + new Date().getDate()),
  }
  this._carWashService.sendSmsVehicleDetailing(this.buffVehicle.id,this.user.companyId,model,this.jobModel.jobId).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.toastr.success('',response.message)
    }
  })
}
getServices(vehicle:any,isOpen:boolean){
  this.buffVehicle = vehicle
  this._carWashService.getServices(this.user.companyId).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.carWashServices = response.data.normalServices
      this.additionalServices = response.data.otherServices
       this._personService.getUsersByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
        if(response.success){
          response.data = response.data.filter(r =>r.role != 'admin')
          this.usersToAssigned = response.data.map(s => ({name: s.name,id:s.id}))
          console.log(this.usersToAssigned)
          if(this.jobObj.jobVehicleServiceObj){
            let  servicesObj = JSON.parse(JSON.stringify(this.jobObj.jobVehicleServiceObj.servicesObj))
            this.servicesSelected = servicesObj.servicesNormalSelected
            this.servicesOthersSelected = servicesObj.servicesOthersSelected
            this.selectedUsers = servicesObj.usersSelected
            this.receivedAmount = servicesObj.receivedAmount
            this.totalDuNormal = servicesObj.totalDu
            this.totalDueOthers = servicesObj.totalDueOthers
          }
          isOpen  ? this.assignModal.show() : ''
        }
      })
    }
  })
}
resetServices(){
  this.servicesSelected = []
  this.carWashServices = []
  this.additionalServices = []
  this.usersToAssigned = []
  this.servicesOthersSelected =[]
  this.selectedUsers =[]
  this.receivedAmount = 0
  this.totalDuNormal = 0
  this.totalDueOthers = 0
  this.isDisabled = true
}
isChecked(service:any){
  let isChecked = false
  if(this.servicesSelected.length> 0){
    let item = this.servicesSelected.find(s => s.id == service.id )
    item ? isChecked = true : isChecked = false
  }
  return isChecked
}
getSummary(){
  let sum: number = 0;
  if(this.servicesSelected.length > 0) {
    this.servicesSelected.forEach(a => sum += a.price);
    this.totalDuNormal = sum > 0  ? sum : 0
  }
  else{
    this.totalDuNormal = 0
  }
  // let results = this.servicesSelected.concat(this.servicesOthersSelected);
  // console.log(results)
}
isCheckedSize(service:any){
  let size = ''
  if(this.servicesSelected.length> 0){
    let itemBuff = this.servicesSelected.find(s => s.id == service.id)
    if(itemBuff){
      size =  itemBuff.size
    }
  }
  return size
}
handleChangeVehicle(event,service){
  console.log(service)
  if(event.target.checked){
    this.servicesSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      time: service.time,
      date: service.date,
      price: 0,
      size: '' ,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    // this.totalDuNormal  += 0
  }
  else{
    let index = this.servicesSelected.findIndex(s =>s.serviceName == service.serviceName)
    // this.totalDuNormal  -= this.servicesSelected[index].price
    this.servicesSelected.splice(index,1)
  }
  console.log(this.servicesSelected)
  this.getSummary()
  this.isCheckedSize(service)
}
checkServiceName(service){
  if(this.servicesSelected.length> 0){
  let itemBuff = this.servicesSelected.find(s => s.id == service.id)
    if(itemBuff){
      if(itemBuff.serviceName != service.serviceName){
        return itemBuff.serviceName
      }
    }
  }
}
isCheckedSizePrice(service:any){
  let itemBuff
  if(this.servicesSelected.length> 0){
     itemBuff = this.servicesSelected.find(s => s.id == service.id)
  }
  return itemBuff
}
selectedNormalService(event,item:any,service,i){
  let index = this.servicesSelected.findIndex(s =>s.id == service.id)
  if(index != -1 ){
  this.servicesSelected[index].price = Number(item['price'+i])
  this.servicesSelected[index].size =  item['size'+i]
  // this.totalDuNormal  += this.servicesSelected[index].price
  }
  else{
    // let event ={target:{checked:true}
    // }
    this.servicesSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      time: service.time,
      date: service.date,
      price: Number(item['price'+i]),
      size: item['size'+i] ,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    this.isChecked(service)
    // this.handleChange(event,service,i)
  }
  console.log(this.servicesSelected)
  this.getSummary()
}
selectedOtherService(event,service:any){
  console.log(service)
  if(event.target.checked){
    this.servicesOthersSelected.push({
      id:service.id,
      serviceName: service.serviceName,
      price: service.price,
      time: service.time,
      date: service.date,
      isActive: service.isActive,
      isShowJob: service.isShowJob,
    })
    this.totalDueOthers  += service.price
    this.totalDueOthers > 0 ? this.totalDueOthers  : 0
  }
  else{
    let index = this.servicesOthersSelected.findIndex(s =>s.id == service.id)
    this.servicesOthersSelected.splice(index,1)
    this.totalDueOthers  -= service.price
    this.totalDueOthers > 0 ? this.totalDueOthers  : 0
  }
  console.log(this.servicesOthersSelected)
}
selectItemPackage1(i) {
  this.selectedPackageIndex1 = i;
}
isCheckedOther(service){
  let isChecked = false
  if(this.servicesOthersSelected.length> 0){
    let item = this.servicesOthersSelected.find(s => s.id == service.id)
    item ? isChecked = true : isChecked = false
  }
  return isChecked
}
changeReceivedAmount(event){
  this.receivedAmount =  Number(event)
}
getCashBack(){
  (this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)) < 0 ? this.isDisabled = true : this.isDisabled = false
  return this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)
}
assignedService(){
  let model = {
    servicesNormalSelected: this.servicesSelected,
    servicesOthersSelected: this.servicesOthersSelected,
    usersSelected:this.selectedUsers,
    totalDu: this.totalDuNormal ,
    receivedAmount: this.receivedAmount,
    cashBack:this.getCashBack(),
    totalDueOthers:this.totalDueOthers,
    date: new Date()
   }
  this._carWashService.assignedJobService(this.buffVehicle.id,this.jobId,model).subscribe((response:ResponseModel)=>{
    if(response.success){
      this.resetServices()
    }
  })
}

}

