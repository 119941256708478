import { Supply } from './../../../models/supply/Supply';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';

@Injectable({
  providedIn: 'root'
})
export class SuppliesService {
  baseUrl = environment.baseUrl

  constructor(private http: HttpClient) { }

  getSupplies() {
    return this.http.get(this.baseUrl + 'supplies')
  }
  getAllSuppliesByJob(id: any) {
    return this.http.get(this.baseUrl + 'supplies/' + id)
  }
  getSuppllyById(id: any) {
    return this.http.get(this.baseUrl + 'supplie/' + id)
  }
  addSupply(supply: any) {
    return this.http.post(this.baseUrl + 'supplie', supply)
  }
  updateSupply(newSupply: Supply, id: any) {
    return this.http.post(this.baseUrl + 'supplie/' + id, newSupply)
  }

}
