import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class QrService {
  baseUrl = environment.baseUrl
  constructor(
    private http: HttpClient
  ) { }

  getQrCodeById(id:any){
    return this.http.get(this.baseUrl + 'qrCode/' + id)
    }
  // addQrCodes(companyId: string, quantity: number,template:any) {
  //   return this.http.post(this.baseUrl + 'qrCodes/' + companyId + '/' + quantity, {template})
  // }

}
