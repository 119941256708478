import { PersonService } from '../_services/person/person.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';
import { ToolsService } from '../_services/tools/tools.service';
import { GlobalService } from '../_services/global/global.service';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';

@Injectable()

export class GetUserViewByCompanyIdResolver implements Resolve<any[]>
{
  settingTools: any = {}
  usersObj: any = {}
  user: any = {}
  constructor(
    private _personService: PersonService,
    private _toolsService: ToolsService,
    // private _globalService: GlobalService,
  ) { }
  resolve(): Observable<any[]> {
    this.user = JSON.parse(localStorage.getItem('user'))
        return this._personService.getUsersViewByCompany(this.user.companyId).pipe(
          catchError(error => {
            // console.log(error)
            return of(null);
          })
        );
  }
}
