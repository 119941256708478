import { Socket } from 'ngx-socket-io';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';

@Component({
  selector: 'app-Queue-Order',
  templateUrl: './Queue-Order.component.html',
  styleUrls: ['./Queue-Order.component.scss']
})
export class QueueOrderComponent implements OnInit {
  companyInfo: any = {}
  compilationId =[]
  plateNumber : any
  constructor(
    private _companiesService: CompaniesService,
    private route: ActivatedRoute,
    private socket: Socket,
  ) { }

  ngOnInit() {
    this.compilationId = this.route.snapshot.paramMap.get('id').split('-')
    console.log( this.compilationId)
    this.route.queryParams.subscribe(params => {
      this.plateNumber = params['pt'];
      console.log(this.plateNumber )
  })
  if(this.compilationId.length > 0){
    this.companyInfoWithQueue()
  }
  this.socket.on(RealTimeKeys.Vehicle_Check_In, (data: any) => {
    this.companyInfoWithQueue()
  })
  this.socket.on(RealTimeKeys.Vehicle_Check_Out, (data: any) => {
    this.companyInfoWithQueue()
  })
  this.socket.on(RealTimeKeys.Vehicle_Un_Check_In, (data: any) => {
    this.companyInfoWithQueue()
  })
  }
  companyInfoWithQueue(){
    this._companiesService.companyInfoWithQueue(this.compilationId[0],this.compilationId[1],this.plateNumber).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.companyInfo = response.data
        console.log( this.companyInfo)
      }
    })
  }
}
