export enum HistoryTicketType {
  Status,
  Priority,
  Severity,
  Edited
}
export enum HistoryTicketLabel{
  Status ='Status',
  Priority='Priority',
  Severity='Severity',
  Edited='Data',
}
