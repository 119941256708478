import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit {
charges:any=[]
  constructor() { }

  ngOnInit() {
  }

}
