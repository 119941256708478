<div class="row" id="qr_codes_Id">
    <div class="col-6 " *ngFor="let item of qrCodes ;let i = index; let first = first ;let last = last;">
        <div class="full-item">
            <div class="info ">
                <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                    <p>{{companyInfo?.companyName}}</p>
                    <p>{{companyInfo?.companyPhone}}</p>
                </div>
                <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                    <p>{{companyInfo?.companyName}}</p>
                    <p>{{companyInfo?.companyPhone}}</p>
                    <p>{{companyInfo?.companyWebsite}}</p>
                </div>
                <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                    <p>{{companyInfo?.companyName}}</p>
                    <p>{{companyInfo?.addressOne}} <span *ngIf="companyInfo?.addressTow != ''">, {{companyInfo?.addressTow}}</span></p>
                    <p>{{companyInfo?.city}}, {{companyInfo?.state}} {{companyInfo?.zip}}</p>
                    <p>{{companyInfo?.companyWebsite}}</p>
                </div>
                <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                    <p>{{companyInfo?.companyName}}</p>
                    <p>{{companyInfo?.addressOne}}<span *ngIf="companyInfo?.addressTow != ''">, {{companyInfo?.addressTow}}</span></p>
                    <p> {{companyInfo?.city}}, {{companyInfo?.state}} {{companyInfo?.zip}}</p>
                    <p>{{companyInfo?.companyPhone}} </p>
                    <p>{{companyInfo?.companyWebsite}}</p>
                </div>
            </div>
            <!-- <div class="qr-img">
                <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
            </div> -->
        </div>
    </div>
</div>
<!-- <div class="row" #qr_codes_Id id="qr_codes_Id">
  <div class="col-6 " *ngFor="let item of qrCodes ;let i = index; let first = first ;let last = last;">
      <div class="full-item">
          <div class="info ">
              <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                  <p>{{companyInfo.companyName}}</p>
                  <p>{{companyInfo.companyPhone}}</p>
              </div>
              <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                  <p>{{companyInfo.companyName}}</p>
                  <p>{{companyInfo.companyPhone}}</p>
                  <p>{{companyInfo.companyWebsite}}</p>
              </div>
              <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                  <p>{{companyInfo.companyName}}</p>
                  <p>{{companyInfo.companyAddress.addressOne}}, {{companyInfo.companyAddress.addressTow}}</p>
                  <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                  <p>{{companyInfo.companyWebsite}}</p>
              </div>
              <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                  <p>{{companyInfo.companyName}}</p>
                  <p>{{companyInfo.companyAddress.addressOne}}, {{companyInfo.companyAddress.addressTow}}</p>
                  <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                  <p>{{companyInfo.companyPhone}} </p>
                  <p>{{companyInfo.companyWebsite}}</p>
              </div>
          </div>
          <div class="qr-img">
              <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
          </div>
      </div>
  </div>
</div> -->