import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { userInfo } from 'os';
import { environment } from 'src/environments/environment.prod';
import { SectionType } from '../Enum/SectionType';
import { ModalDirective } from 'angular-bootstrap-md';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { ProductEditType } from '../Enum/ProductEditType';
declare var google: any;

@Component({
  selector: 'app-customize-website',
  templateUrl: './customize-website.component.html',
  styleUrls: ['./customize-website.component.scss']
})
export class CustomizeWebsiteComponent implements OnInit {
  @ViewChild('confirmationModal') confirmationModal: ModalDirective;
  planCompanyType: any = PlanCompanyType
  isCopied = false
  sharedLink = environment.baseDomain
  static_link = environment.baseDomain + 'web/'
  companyInfo: any = {}
  user:any={}
  products:[]
  isUserExists = false
  websiteName:any = ''
  flipDiv = false;
  secret_keyObj : any = {}
  flipDiv1 = false;
  sectionType  = SectionType
  public selectedIndex: number = 0;
  headerObj : any ={}
  bufferSection
  websiteObj:any = {}
  bufferProduct:any={}
  ProductEditType = ProductEditType
  headerView : any = {
    isShow:false,
    tittleHeader : "Get your car looking like new again with our expert car wash services!",
    textHeader : "At our car wash, we use only the highest quality equipment and cleaning products to ensure that every vehicle receives a thorough and effective clean, leaving it looking shiny and spotless.",
    dynamicObj:{}
  }
  aboutObj : any ={}
  aboutView : any ={
    isShow:false,
    tittleAbout : "Get your car looking like new again with our expert car wash services!",
    textAbout : "We pride ourselves on providing exceptional services, with a commitment to quality, reliability, and customer satisfaction that sets us apart from the competition.",
    dynamicObj:{}
  }
  servicesView : any ={
    isShow:false,
    items:[],
    dynamicObj:{}
  }
  pricingView : any ={
    isShow:false,
    items:[],
    dynamicObj:{}
  }
  worksView : any ={
    isShow:false,
    items:[],
    dynamicObj:{}
  }
  socialView : any ={
    isShow:false,
    items:[
      {id:1,isShow:true,link:'',name:'facebook'},
      {id:2,isShow:true,link:'',name:'whatsapp'},
      {id:3,isShow:false,link:'',name:'youtube'},
      {id:4,isShow:true,link:'',name:'yelp'},
      {id:5,isShow:true,link:'',name:'instagram'},
      {id:6,isShow:true,link:'',name:'twitter'}
    ],
    dynamicObj:{}
  }
  AddressView : any ={
    isShow:false,
    items:[],
    dynamicObj:{},
    isHoursShow: false,
    isMapShow: false,
  }
  contactView : any = {
    isShow:false,
    contactObj:{},
    phoneEmailObj:{},
    items:[],
    dynamicObj:{}
  }
  days = [
    {id:1,name:'Mon',start:'07:00',end:'20:00',isOpen:true},
    {id:2,name:'Tue',start:'07:00',end:'20:00',isOpen:true},
    {id:3,name:'Wed',start:'07:00',end:'20:00',isOpen:true},
    {id:4,name:'Thu',start:'07:00',end:'20:00',isOpen:true},
    {id:5,name:'Fri',start:'07:00',end:'20:00',isOpen:true},
    {id:6,name:'Sat',start:'07:00',end:'20:00',isOpen:true},
    {id:7,name:'Sun',start:'07:00',end:'20:00',isOpen:false}
  ]
  socialItem:any={}
  selectedFiles?: FileList;
  imagesArr = []
  imagesDeleted = []
  formatted_address = ''
  @ViewChild('address') address: any;
  numberPattern = ValidationModel.numberPattern
  constructor(
    private _carwashService:CarWashService,
    private _globalService:GlobalService,
    private _toaster:ToasterCustomService,
    private _companiesService:CompaniesService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getCompanyAddress()
    this.websiteName = this.user.companyNumber + this.user.signUpDateTimeStamp
    this.getWebsiteInfo()
    console.log(this.socialView)
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getWebsiteInfo(){
    this._carwashService.getCarWashWebsiteByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.websiteObj = response.data
       this.headerView  = this.websiteObj.header
       this.aboutView  = this.websiteObj.about
       this.servicesView  = this.websiteObj.services
       this.worksView  = this.websiteObj.someWorks
       this.imagesArr  = this.websiteObj.someWorks.images
       this.pricingView  = this.websiteObj.pricing
       this.socialView  = this.websiteObj.socialMedia.items.length <= 0 ? this.socialView : this.websiteObj.socialMedia
       this.AddressView  = this.websiteObj.addressMap
       this.contactView  = this.websiteObj.contactAddress
      //  this.contactView.contactObj = {
      //   email:this.user.email
      // }
      // this.contactView.phoneEmailObj = {
      //   email:this.user.email,
      //   phone:this.user.companyPhone,
      //   address:this.companyInfo.formatted_address
      // }
      // this.socialView.items =
      // [
      //   {id:1,isShow:true,link:'',name:'facebook'},
      //   {id:2,isShow:false,link:'',name:'whatsapp'},
      //   {id:3,isShow:false,link:'',name:'youtube'},
      //   {id:4,isShow:true,link:'',name:'yelp'},
      //   {id:5,isShow:true,link:'',name:'instagram'},
      //   {id:6,isShow:false,link:'',name:'google'}
      // ]
       this.days =  response.data.addressMap.days ? response.data.addressMap.days : this.days
       console.log(this.websiteObj)
       this.websiteName = this.websiteObj.companyWebsiteLinkName ? this.websiteObj.companyWebsiteLinkName : this.user.companyNumber + this.user.signUpDateTimeStamp
      }
    })
  }
  getCopyLink(){
    return this.sharedLink + 'web/'+ this.websiteName
  }
  copyLink() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
  getCompanyAddress() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyInfo = {
      companyName: company.companyName,
      companyPhone: this.user.companyPhone,
      companyWebsite: this.user.companyWebsite,
      companyAddress: company.companyAddress,
      companyLogo: company.companyLogo,
      formatted_address: company.companyAddress.formatted_address
    }
  }
  updateDomain(){
    this._carwashService.companyWebsiteLinkName(this.user.companyId,this.websiteName.toLowerCase().replace(/\s/g,'')).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.websiteName = response.data
        this.getCopyLink()
      }
    })
  }
  checkServiceName(event){
    this.websiteName = event
    setTimeout(() => {
      this._carwashService.isWebsiteNameExist(event.trim().toLocaleLowerCase()).subscribe((response:ResponseModel)=>{
        if (response.success) {
          this.isUserExists = false
        }
        else {
          this.isUserExists = true
        }
      })
    }, 1000);
  }
  eventFlip() {
    this.flipDiv = !this.flipDiv;
    this.headerObj = Object.assign({},this.headerView)
  }
  eventFlipAbout() {
    this.flipDiv1 = !this.flipDiv1;
    this.aboutObj = Object.assign({},this.aboutView)
  }
  updateHeader(){
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Header,this.headerObj).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.headerView = response.data.header
        this.flipDiv = !this.flipDiv
      }
    })
  }
  updateAbout(){
    // let model = Object.assign({},this.aboutObj)
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.About,this.aboutObj).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.aboutView = response.data.about
        this.flipDiv1 = !this.flipDiv1
      }
    })
  }
  carWashWebsite(){
    this._carwashService
  }
  handleChangeSwitch(event,sectionType:number) {
      switch(sectionType){
        case this.sectionType.Header : {
          this.bufferSection = sectionType
          this.headerView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.About : {
          this.bufferSection = sectionType
          this.aboutView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Services : {
          this.bufferSection = sectionType
          this.servicesView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Pricing : {
          this.bufferSection = sectionType
          this.pricingView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Some_Works : {
          this.bufferSection = sectionType
          this.worksView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Social_Media : {
          this.bufferSection = sectionType
          this.socialView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Address_Map : {
          this.bufferSection = sectionType
          this.AddressView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
        case this.sectionType.Contact_Address : {
          this.bufferSection = sectionType
          this.contactView.isShow = event.checked
          this.confirmationModal.show()
          break;
        }
      }
  }
  confirmSave(sectionType:number){
    switch(sectionType){
      case this.sectionType.Header : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Header,this.headerView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.headerView = response.data.header
          }
        })
        break;
      }
      case this.sectionType.About : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.About,this.aboutView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.aboutView = response.data.about
          }
        })
        break;
      }
      case this.sectionType.Services : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Services,this.servicesView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.servicesView = response.data.services
          }
        })
        break;
      }
      case this.sectionType.Pricing : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Pricing,this.pricingView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.pricingView = response.data.pricing
          }
        })
        break;
      }
      case this.sectionType.Some_Works : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Some_Works,this.worksView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.worksView = response.data.someWorks
          }
        })
        break;
      }
      case this.sectionType.Social_Media : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Social_Media,this.socialView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.socialView = response.data.socialMedia
          }
        })
        break;
      }
      case this.sectionType.Address_Map : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Address_Map,this.AddressView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.AddressView = response.data.addressMap
          }
        })
        break;
      }
      case this.sectionType.Contact_Address : {
        this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Contact_Address,this.contactView).subscribe((response:ResponseModel)=>{
          if(response.success){
            this.contactView = response.data.contactAddress
          }
        })
        break;
      }
    }
  }
  resetSwitch(sectionType:number){
    switch(sectionType){
      case this.sectionType.Header : {
        this.headerView.isShow = !this.headerView.isShow
        break;
      }
      case this.sectionType.About : {
        this.aboutView.isShow = !this.aboutView.isShow
        break;
      }
      case this.sectionType.Services : {
        this.servicesView.isShow = !this.servicesView.isShow
        break;
      }
      case this.sectionType.Pricing : {
        this.pricingView.isShow = !this.pricingView.isShow
        break;
      }
      case this.sectionType.Some_Works : {
        this.worksView.isShow = !this.worksView.isShow
        break;
      }
      case this.sectionType.Social_Media : {
        this.socialView.isShow = !this.socialView.isShow
        break;
      }
      case this.sectionType.Address_Map : {
        this.AddressView.isShow = !this.AddressView.isShow
        break;
      }
      case this.sectionType.Contact_Address : {
        this.contactView.isShow = !this.contactView.isShow
        break;
      }
    }
  }
  // changedHour(event,index,isStart){
  //   // console.log('')
  //   // if (event !== "") {
  //   //   let hours = event.split(":")[0];
  //   //   let minutes = event.split(":")[1];
  //   //   let suffix = hours >= 12 ? "pm" : "am";
  //   //   hours = hours % 12 || 12;
  //   //   hours = hours < 10 ? "0" + hours : hours;
  //   //   let  formatTime = hours + ":" + minutes + " " + suffix;
  //   //   // isStart ? this.days[index].start  = formatTime  : this.days[index].end  = formatTime
  //   // }

  // }
  updateHours(){
    console.log(this.days)
    this.websiteObj.addressMap.days = this.days
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Address_Map,this.websiteObj.addressMap).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.days = response.data.addressMap.days
      }
    })
  }
  handleChangeSwitchHours(event,index){
    this.days[index].isOpen = event.checked
  }
  UpdateContactEmail(){
    this.websiteObj.contactAddress = this.contactView
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Contact_Address,this.websiteObj.contactAddress).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.contactView  = response.data.contactAddress
      }
    })
  }
  storeSocialMedia(item:any){
   console.log( this.getLastPart(item.link))
   item.link = (item.id == 2) ?  this.getLastPart(item.link) : item.link ;
    this.socialItem = item
    console.log(this.socialItem)
  }
 getLastPart(url) {
    const parts = url.split('/');
    return parts.at(-1);
  }
  updateSocialLink(id){
    let index = this.socialView.items.findIndex(s => s.id == id)
    this.socialView.items[index].link = this.socialItem.id != 2 ?  this.socialItem.link :  'https://wa.me/' + this.socialItem.link
    console.log(this.socialView.items )
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Social_Media,this.socialView).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.socialView  = response.data.socialMedia
       this.socialItem ={}
      }
    })
  }
  handleChangeSocialMedia(event,id){
    let index = this.socialView.items.findIndex(s => s.id == id)
    this.socialView.items[index].isShow = event.checked
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Social_Media,this.socialView).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.socialView  = response.data.socialMedia
      }
    })
  }
  handleChangeMap(event){
    this.AddressView.isMapShow = event.checked
    this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Address_Map,this.AddressView).subscribe((response:ResponseModel)=>{
      if(response.success){
       this.AddressView  = response.data.addressMap
      }
    })
  }

  openWebsite(){
    this._globalService.openLink(this.getCopyLink())
  }
  isProductsCompanyById(){
    this._carwashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this._companiesService.getProductsCompanyById(this.user.companyId).subscribe((response: any) => {
          console.log('this.products',response.data)
          this.products = response.data
          //  console.log('products',this.products)
        })
      }
    })
  }
  storeProduct(product){
    this.bufferProduct = product
    // this.bufferProduct.updated = this.timeConverter(this.bufferProduct.updated)
    // console.log(this.bufferProduct)
  }
  getCompanyStripeKey(){
    this._companiesService.getCompanyStripeKey(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.secret_keyObj = response.data
      }
    })
}
updatePriceBefore(event: any,type): void {
  if(type === ProductEditType.Price_Product_Before){
  if (this.bufferProduct && this.bufferProduct.priceBeforeDiscount) {
    this.bufferProduct.priceBeforeDiscount.priceBefore = event;
  }
}
if(type === ProductEditType.Single_Wash_Price_Before){
  if (this.bufferProduct && this.bufferProduct.priceSingleWash) {
    this.bufferProduct.priceSingleWash.priceBefore = event;
  }
}
if(type === ProductEditType.Single_Wash_Price_After){
  if (this.bufferProduct && this.bufferProduct.priceSingleWash) {
    this.bufferProduct.priceSingleWash.priceAfter = event;
  }
}
if(type === ProductEditType.Car_Max_Price_Before){
  if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
    this.bufferProduct.productsMaxCar.priceBefore = event;
  }
}
if(type === ProductEditType.Car_Max_Price_After){
  if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
    this.bufferProduct.productsMaxCar.priceAfter = event;
  }
}
if(type === ProductEditType.Car_Max_Num_Of_Cars){
  if (this.bufferProduct && this.bufferProduct.productsMaxCar) {
    this.bufferProduct.productsMaxCar.countCar = event;
  }
}
}
handleChange(event,type) {
  if(type === ProductEditType.Price_Product_Before){
    if (event.checked) {
      this.bufferProduct.priceBeforeDiscount.isShow = true
    }
    else{
      this.bufferProduct.priceBeforeDiscount.isShow = false
    }
  }
  if(type === ProductEditType.Car_Max_Price_Before){
    if (event.checked) {
      this.bufferProduct.productsMaxCar.isShow = true
    }
    else{
      this.bufferProduct.productsMaxCar.isShow = false
    }
  }
  else{
    if (event.checked) {
      this.bufferProduct.priceSingleWash.isShow = true
    }
    else{
      this.bufferProduct.priceSingleWash.isShow = false
    }
  }

}
addDesorptionProduct(){
  let model = {
    description : this.bufferProduct.customDescription,
    id:this.bufferProduct.id,
    date :new Date(),
    priceBeforeDiscount:{
      isShow: this.bufferProduct?.priceBeforeDiscount?.isShow ?? false,
       priceBefore:Number(this.bufferProduct?.priceBeforeDiscount?.priceBefore ?? 0)
    },
    priceSingleWash:{
      isShow: this.bufferProduct?.priceSingleWash?.isShow ?? false,
      priceBefore:Number(this.bufferProduct?.priceSingleWash?.priceBefore ?? 0),
      priceAfter:Number(this.bufferProduct?.priceSingleWash?.priceAfter ?? 0)
    },
    productsMaxCar:{
      countCar:Number(this.bufferProduct?.productsMaxCar?.countCar ?? 0),
      isShow: this.bufferProduct?.productsMaxCar?.isShow ?? false,
      priceBefore:Number(this.bufferProduct?.productsMaxCar?.priceBefore ?? 0),
      priceAfter:Number(this.bufferProduct?.productsMaxCar?.priceAfter ?? 0)
    },
  }
  this._carwashService.addDescritionProduct(this.user.companyId,model).subscribe((response:ResponseModel)=>{
    if(response.success){
    }
  })
}
getOriginalPrice(price) {
  if(price){
  return price / 100
  } else{
      return 0
  }
}
onTabChanged(event: any) {
  this.selectedIndex = event.index
}
    // upload  image Added
    selectFiles(event: any) {
      // this.progressInfos = []
      this.selectedFiles = event.target.files;
      // this.previews = []
      // let medModel: any = []
      console.log(this.selectedFiles)
      if (this.selectedFiles) {
        const numberOfFiles = this.selectedFiles.length > 5 ? 5 :this.selectedFiles.length
        for (let i = 0; i < numberOfFiles; i++) {
        if (this.imagesArr.length < 3) {
          if(this.selectedFiles[i].size / 1024 / 1024 <= 3){
            const reader = new FileReader();
            reader.onload = (e: any) => {
                console.log(this.selectedFiles[i].size / 1024 /1024)
                  this.imagesArr.push({
                    id: this.imagesArr.length + (new Date().getMilliseconds()),
                    src: e.target.result,
                    isUpdated: true,
                    dateClaim: new Date()
                  })
            };
            reader.readAsDataURL(this.selectedFiles[i])
          }
          else{
          this._toaster.error('', 'Photo size must be 3 M or less')
          }
        }
        else {
          this._toaster.error('', 'Choose only three photos ')
        }
        }
      }
    }
    removeImage(id) {
      let index = this.imagesArr.findIndex(a => a.id == id)
      if(this.imagesArr[index].isUpdated){
        this.imagesArr.splice(index, 1)
      }
      else{
        this.imagesDeleted.push(this.imagesArr[index])
        this.imagesArr.splice(index, 1)
        this.websiteObj.someWorks.imagesDeleted = this.imagesDeleted
        this.saveSomeWork()
      }

    }
    saveSomeWork(){
      this.websiteObj.someWorks.images = this.imagesArr
      this._carwashService.updateCarWashWebsite(this.websiteObj.id,SectionType.Some_Works,this.websiteObj.someWorks).subscribe((response:ResponseModel)=>{
        if(response.success){
         this.worksView  = response.data.someWorks
        }
      })
    }
    getPlaceAutocomplete() {
      let place:any
      const autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement,
        {
          // componentRestrictions: { country: 'US' },
          types: ['establishment']  // 'establishment' / 'address' / 'geocode'
        }
        );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
         place = autocomplete.getPlace();
         this.formatted_address = place.formatted_address
         this.contactView.phoneEmailObj.address = place.formatted_address
         console.log(this.contactView.phoneEmailObj.address)
        //  this.companyAddressEdit.companyAddress = this.getAddressFromComponents(place.address_components)
      });
    }
}
