<section class="customer-profile">
    <div class="card">
        <div class="card-title">
            <i class="fas fa-arrow-left fa-1x" *ngIf="role == 'crew' || role =='teamleader' " [routerLink]="['/user/customers-list']"></i>
            <i class="fas fa-arrow-left fa-1x" *ngIf="role == 'admin' || role == 'manager'" [routerLink]="['/admin/customers-list']"></i>
            <h6>Customer Details</h6>
        </div>
        <form #customerForm="ngForm">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="firstName"> First Name :</label>
                        <input type="firstName" id="firstName" class="form-control" name="firstName" [(ngModel)]="customerModel.firstName" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="lastName"> Last Name :</label>
                        <input type="text" id="lastName" class="form-control" name="lastName" [(ngModel)]="customerModel.lastName" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="email"> Email <span>* </span>:</label>
                        <div>
                            <input type="email" class="form-control" readonly name="email" [(ngModel)]="customerModel.email" required />
                            <!-- <input *ngIf=" customerModel.isInvited == false && (!customerModel.uid && user.categoryCompany == categoryCompany.Inventory ) " id="offEmail" type="email" class="form-control" [ngClass]="{'is-invalid' : offEmail.errors && offEmail.touched}" name="email"
                                [(ngModel)]="customerModel.email" (blur)="isEmailExist()" required [pattern]="emailPattern" #offEmail="ngModel" />
                            <div *ngIf="isRequired" class="alert alert-danger">
                                Official Email required.
                            </div>
                            <div *ngIf="isNotEmail" class="alert alert-danger">
                                Official Email not valid.
                            </div>
                            <div *ngIf="isExists" class="alert alert-danger">
                                Customer E-mail exists please enter another Email.
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="customPersonType"> Customer Type :</label>
                        <input type="text" id="customPersonType" class="form-control" name="customPersonType" [(ngModel)]="customerModel.customPersonType" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="phone"> Phone :</label>
                        <input type="text" id="phone" class="form-control" name="phone" [(ngModel)]="customerModel.phone" />
                        <!-- <div *ngIf="phone.errors && phone.touched" class="alert alert-danger">
                            <div *ngIf="phone.errors.required">
                                Phone required.
                            </div>
                            <div *ngIf="phone.errors.pattern">
                                Phone not valid must only number.
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="adddraddressOneessOne"> Address 1 :</label>
                        <input type="text" id="adddressOne" #addressText class="form-control" name="addressOne" [(ngModel)]="customerModel.addressOne" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="addressTow"> Address 2 :</label>
                        <input type="text" id="addressTow" class="form-control" name="addressTow" [(ngModel)]="customerModel.addressTow" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="city"> City :</label>
                        <input type="text" id="city" class="form-control" name="city" [(ngModel)]="customerModel.city" />
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label for="state"> State :</label>
                        <input type="text" id="state" class="form-control" name="state" [(ngModel)]="customerModel.state" />
                    </div>
                </div>
                <div class="col-lg-6  col-sm-12">
                    <div class="form-group">
                        <label for="zipCode"> Zip Code :</label>
                        <input type="text" id="zipCode" class="form-control" name="zip" [(ngModel)]="customerModel.zip" />
                        <!-- <div *ngIf="zipCode.errors && zipCode.touched" class="alert alert-danger">
                            <div *ngIf="zipCode.errors.required">
                                Zip Code required.
                            </div>
                            <div *ngIf="zipCode.errors.pattern">
                                Zip Code not valid must only number.
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="country"> Country  :</label>
                        <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" (onCountrySelected)="onCountrySelected($event)">
                        </mat-select-country>
                    </div>
                </div>
                <div class="button-group">
                    <button class="btn" color="success" [disabled]="customerForm.invalid || isExists" (click)="basicModal.show()"><i class="far fa-save"></i><span>Update Customer</span></button>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- Modal -->
<div mdbModal #basicModal="mdbModal" class="modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="basicModal.hide()" aria-label="Close">
            <span>&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p>Save changes?</p>
                    <!-- <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p> -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="basicModal.hide()">Cancel</button>
                    <button type="button" class="btn btn-success" (click)="updateCustomer();basicModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>