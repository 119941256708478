<!-- <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="default" color="#4285f4" type="ball-spin" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->
<div class="contianer">
    <div class="forms-container">
        <div class="signin-signup">
            <!-- sign in form -->
            <form [formGroup]="signInFormGroup" (submit)="SignIn()" class="sign-in-form">
                <h2 class="title text-uppercase">Login</h2>
                <div class="input-filed">
                    <i class="fas fa-user"></i>
                    <!-- <input type="text" *ngIf="emailUser" readonly name="emailUser" value="{{emailUser}}"> -->
                    <input type="text" placeholder="user email" formControlName="email">
                    <div *ngIf="ff.email.touched && ff.email.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="ff.email.errors.required">User email is required</div>
                        <div *ngIf="ff.email.errors.pattern">User email not valid</div>
                    </div>
                </div>
                <div class="input-filed">
                    <i class="fas fa-eye" *ngIf="showSignIn" (click)="passwordSignIn()"></i>
                    <i class="fas fa-eye-slash" *ngIf="!showSignIn" (click)="passwordSignIn()"></i>
                    <input [type]="showSignIn ? 'text' : 'password'" placeholder="password" formControlName="password">
                    <div *ngIf="ff.password.touched && ff.password.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="ff.password.errors.required">Password is required </div>
                    </div>
                </div>
                <div class="forget-password" (click)="resetPasswordModal.show()">
                    <a>Forgot my password</a>
                </div>
                <button type="submit" [disabled]="signInFormGroup.invalid" class="btn mt-2 btn-solid">Login </button>
                <footer>
                    <div>
                        <p>By continuing, you agree to Accordev's <strong class="text-blue"> Terms of Service </strong> and acknowledge you've read our <strong class="text-blue"> Privacy Policy</strong></p>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary">Help</button>
                </footer>
            </form>
            <!-- Sign Up Form -->
            <form [formGroup]="registerFormGroup" (submit)="SignUp()" class="sign-up-form">
                <h2 class="title mt-3">Sign up</h2>
                <mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]="1">
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>User Information</ng-template>
                        <div class="input-filed input-filed-read">
                            <i class="fas fa-user"><span> *</span></i>
                            <input type="text" placeholder="First Name" readonly formControlName="firstName">
                            <!-- <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.firstName.errors.required">firstName is required</div>
                            </div> -->
                        </div>
                        <div class="input-filed input-filed-read">
                            <i class="fas fa-user"><span> *</span></i>
                            <input type="text" placeholder="Last Name" readonly formControlName="lastName">
                            <!-- <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.lastName.errors.required">LastName is required</div>
                            </div> -->
                        </div>
                        <div class="input-filed input-filed-read">
                            <i class="fas fa-envelope"><span> *</span></i>
                            <input type="email" placeholder="User Email (For Logging In Later)" readonly formControlName="email">
                            <!-- <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.email.errors.required">email is required</div>
                                <div *ngIf="f.email.errors.invalid">User E-mail exists please enter another Email</div>
                                <div *ngIf="f.email.errors.pattern">Email not valid</div>
                            </div> -->
                        </div>
                        <div class="input-filed input-filed-read">
                            <i class="fas fa-phone-square"><span> *</span></i>
                            <input type="text" placeholder="User Phone" readonly formControlName="phone">
                            <!-- <div *ngIf="f.phone.touched && f.phone.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.phone.errors.required">phone is required</div>
                                <div *ngIf="f.phone.errors.pattern">Phone not valid must only number.</div>
                            </div> -->
                        </div>
                        <!-- <div class="input-filed">
                            <i class="fas fa-lock"><span> *</span></i>
                            <input [type]="showSignUp ? 'text' : 'password'" placeholder="Password" formControlName="passwrod">
                            <div *ngIf="f.passwrod.touched && f.passwrod.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.passwrod.errors.required">passwrod is required</div>
                                <div *ngIf="f.passwrod.errors.pattern">password must be at least 6 characters and should contain atleast one number and one special character</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-lock"><span> *</span></i>
                            <input [type]="showSignUp ? 'text' : 'password'" id="myInput" placeholder="Confirm Password" formControlName="confirmPassword">
                            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.confirmPassword.errors.required">confirm password required</div>
                                <div *ngIf="f.confirmPassword.errors.pattern">password must be at least 6 characters and should contain atleast one number and one special character</div>
                            </div>
                            <div class="alert  alert-danger" *ngIf="f.confirmPassword.touched &&registerFormGroup.hasError('mismatch')">
                                Password is Mismatch</div>
                        </div> -->
                        <!-- <div class="check-password">
                            <mat-checkbox class="example-margin" (click)="passwordSignUp()"><span>Show Password</span></mat-checkbox>
                        </div> -->
                        <button matStepperNext type="button" [disabled]="f.firstName.invalid || f.lastName.invalid || f.email.invalid  || f.phone.invalid" mdbBtn color="success">Next</button>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>Company Information</ng-template>
                        <div class="input-filed">
                            <i class="fas fa-building"><span> *</span></i>
                            <input type="text" placeholder="Company Name" formControlName="companyName">
                            <div *ngIf="f.companyName.touched && f.companyName.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyName.errors.required">Company Name is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-building"><span> *</span></i>
                            <input type="text" placeholder="Company phone" formControlName="companyPhone">
                            <div *ngIf="f.companyPhone.touched && f.companyPhone.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyPhone.errors.required">Company Phone is required</div>
                                <div *ngIf="f.companyPhone.errors.pattern">Company Phone not valid must only number.</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-envelope"><span> *</span></i>
                            <input type="text" placeholder="Company Email" formControlName="companyEmail">
                            <div *ngIf="f.companyEmail.touched && f.companyEmail.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.companyEmail.errors.required">Company Email is required</div>
                                <div *ngIf="f.companyEmail.errors.pattern">Company Email not valid</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-globe"></i>
                            <input type="text" placeholder="Website" formControlName="website">
                        </div>
                        <!-- <div class="group-button">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="companyStatus" id="BasicRadio" formControlName="companyStatus" [value]=0 checked>
                                <label class="form-check-label" for="BasicRadio"> Basic </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="companyStatus" id="ProRadio" formControlName="companyStatus" [value]=1>
                                <label class="form-check-label" for="ProRadio"> Pro</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="companyStatus" id="PriemumRadio" formControlName="companyStatus" [value]=2>
                                <label class="form-check-label" for="PriemumRadio">Priemum</label>
                            </div>
                        </div> -->
                        <div>
                            <button matStepperPrevious type="button" mdbBtn color="primary">Back</button>
                            <button matStepperNext type="button" mdbBtn color="success" [disabled]="f.companyEmail.invalid || f.companyName.invalid || f.companyPhone.invalid ">Next</button>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <ng-template matStepLabel>Company Address</ng-template>
                        <div class="input-filed">
                            <i class="fas fa-address-book"><span> *</span></i>
                            <input type="text" placeholder="Address 1" formControlName="addressOne" #addressText>
                            <div *ngIf="f.addressOne.touched && f.addressOne.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.addressOne.errors.required">address is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-address-book"></i>
                            <input type="text" placeholder="Address 2" formControlName="addressTow">
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-city"><span> *</span></i>
                            <input type="text" placeholder="City" formControlName="city">
                            <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.city.errors.required">city is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="far fa-building"><span> *</span></i>
                            <input type="text" placeholder="State" formControlName="state">
                            <div *ngIf="f.state.touched && f.state.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.state.errors.required">state is required</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-address-book"><span> *</span></i>
                            <input type="text" placeholder="Zip Code" formControlName="zip">
                            <div *ngIf="f.zip.touched && f.zip.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.zip.errors.required">zip is required</div>
                                <div *ngIf="f.zip.errors.pattern">Zip Code not valid must only number.</div>
                            </div>
                        </div>
                        <div class="input-filed">
                            <i class="fas fa-flag-usa"><span> *</span></i>
                            <mat-select-country class="control" label="{{defaultValue.name}}" [_value]="defaultValue" formControlName="country" (onCountrySelected)="onCountrySelected($event)">
                            </mat-select-country>
                            <div *ngIf="f.country.touched && f.country.invalid" class="alert alert-danger" role="alert">
                                <div *ngIf="f.country.errors.required">country is required</div>
                            </div>
                        </div>
                        <div>
                            <button matStepperPrevious type="button" mdbBtn color="primary">Back</button>
                            <button type="submit" mdbBtn color="success" [disabled]="registerFormGroup.invalid">Save</button>
                            <button type="button" mdbBtn color="danger" [disabled]="registerFormGroup.invalid" (click)="stepper.reset()">Reset</button>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="registerFormGroup">
                        <div *ngIf="planType != planCompanyType.BASIC">
                            <ng-template matStepLabel>Payment</ng-template>
                            <div>
                                <button [disabled]="true" class="btn  btn-primary">Add Payment Method</button>
                            </div>
                        </div>
                        <div *ngIf="planType == planCompanyType.BASIC">
                            <ng-template matStepLabel>Verify Identity</ng-template>
                            <div>
                                <button [disabled]="true" class="btn  btn-primary">Add a Credit card</button> <br>
                                <small>Accordev only uses  your card information to verify your identity. Your card won’t be charged, and you’re not sharing balance or other financial information.</small>
                            </div>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </form>
        </div>
    </div>
    <div class="panels-container">
        <div class="panel left-panel">
            <div class="content">
                <h2 class="mb-1"><strong>Accordev</strong></h2>
                <label class="mb-5">Easy and Smart or Nothing</label>
                <!-- <p class="mb-3">
                  Welcome to the web app, where you can digitalize your business and make your job mush Easier and Smarter
              </p> -->
                <h5></h5>
                <button class="btn transparent" id="sign-up-btn" (click)="SignUpMode()">Create a business Account</button>
                <p>
                    If you are a customer or a 3rd party, ask your Service Product Provider for an Accordev invitation link
                </p>
            </div>
            <img src="./assets/img/log.svg" class="image" alt="login-img">
        </div>
        <div class="panel right-panel">
            <div class="content">
                <h3>One of us ?</h3>
                <p>
                    Thank you for being our valued customer. We are so grateful for the pleasure of serving you and hope we met your expectations.
                </p>
                <!-- <button class="btn transparent" id="sign-in-btn" (click)="SignInMode()">Log in</button> -->
            </div>
            <img src="./assets/img/register.svg" class="image" alt="register-img">
        </div>
    </div>
</div>
<!-- Modal reset password  -->
<div mdbModal id="resetPassword" #resetPasswordModal="mdbModal" class="modal fade">
    <div class="modal-dialog">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Reset Password</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="resetPasswordModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="tittle">
                        <h5>Forgot Password </h5>
                        <p class="text-secondary"><small>If you don't remove, your changes will be lost.</small></p>
                    </div>
                    <form #resetForm="ngForm">
                        <input type="email" id="emailRest" name="emailReset" required [(ngModel)]="emailReset" class="form-control" placeholder="Your e-mail address " [pattern]="emailPattern" #emailRest="ngModel" />
                        <div *ngIf="emailRest.errors && emailRest.touched" class="alert alert-danger">
                            <div *ngIf="emailRest.errors.required">
                                Email user required.
                            </div>
                            <div *ngIf="emailRest.errors.pattern">
                                Email user not valid.
                            </div>
                        </div>
                        <div *ngIf="error.isError && !emailRest.errors" class="alert alert-danger">
                            {{ error.errorMessage }}
                        </div>
                        <div class="reset">
                            <button type="submit" class="btn btn-primary" [disabled]='resetForm.invalid' (click)="forgotPassword(resetForm)"> Reset my Password</button>
                        </div>
                    </form>
                </div>
                <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-primary " data-dismiss="modal " (click)="resetPasswordModal.hide() ">Close</button>
                  <button type="button " class="btn btn-danger " (click)="resetPasswordModal.hide() ">Delete </button>
              </div> -->
            </div>
        </div>
    </div>
</div>
<!-- Verify email modal  -->
<div mdbModal id="verifyEmailModal" #verifyEmailModal="mdbModal" class="modal fade">
    <div class="modal-dialog">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"> Verify your Account</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="verifyEmailModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="tittle">
                        <h5>Check your email </h5>
                        <p class="text-secondary"><small>Sign in to you email to verify your account.</small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>