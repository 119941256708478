import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { CustomersListComponent } from './pages/customers-list/customers-list.component';
import { UsersListComponent } from './pages/users-list/users-list.component';
import { AddJobComponent } from './pages/add-job/add-job.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { EditCompanyComponent } from './pages/edit-company/edit-company.component';
import { InviteUserComponent } from './pages/invite-user/invite-user.component';
import { CompanyAdminComponent } from './pages/company-admin/company-admin.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from 'src/app/layout/pages/admin-layout/admin-layout.component';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { jobByIdResolver } from 'src/app/core/_resolver/getJobById-resolver';
import { GetCompanyByIdResolver } from 'src/app/core/_resolver/getCompanyById-resolver';
import { GetPersonByIdResolver } from 'src/app/core/_resolver/getPersonById-resolver';
import { AuthGuard } from 'src/app/core/_helper/auth.guard.ts';
import { Role } from 'src/app/models/role/RoleModel';
import { GetToolsByCompanyIdResolver } from 'src/app/core/_resolver/getToolsByCompanyId-resolver';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SubmitTicketComponent } from './pages/submit-ticket/submit-ticket.component';
import { getAllInventoryItemMixedResolver } from 'src/app/core/_resolver/getAllInventoryItemMixed-resolver';
import { GetAllInventoryItemMixedSummaryResolver } from 'src/app/core/_resolver/getAllInventoryItemMixedSummary-resolver';
import { ManageAccountsComponent } from './pages/manage-accounts/manage-accounts.component';
import { GetAllUserCompaniesResolver } from 'src/app/core/_resolver/getAllUserCompanies-resolver';
import { AddCustomerManuallyComponent } from './pages/add-customer-manually/add-customer-manually.component';
import { CarWashTemplatesComponent } from './pages/car-wash-folder/car-wash-templates/car-wash-templates.component';
import { AddVehicleComponent } from './pages/car-wash-folder/add-vehicle/add-vehicle.component';
import { ViewVehicleComponent } from './pages/car-wash-folder/view-vehicle/view-vehicle.component';
// import { AssignedVehicleQrComponent } from './pages/car-wash-folder/assigned-vehicle-qr/assigned-vehicle-qr.component';
import { VehiclesListComponent } from './pages/car-wash-folder/vehicles-list/vehicles-list.component';
import { ProductsStripComponent } from './pages/car-wash-folder/products-strip/products-strip.component';
import { CompleteMembershipComponent } from './pages/car-wash-folder/complete-membership/complete-membership.component';
import { QrCodeStickersComponent } from './pages/car-wash-folder/qr-code-stickers/qr-code-stickers/qr-code-stickers.component';
import { CarWashServicesComponent } from './pages/car-wash-folder/car-wash-services/car-wash-services.component';
import { CustomizeWebsiteComponent } from './pages/car-wash-folder/customize-website/customize-website.component';

const routes: Routes = [
  {
    path: '', component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Manger] },
    children: [
      {
        path: '', component: CompanyAdminComponent
      },
      {
        path: 'edit-company', component: EditCompanyComponent,
        resolve: {
          'comapny': GetCompanyByIdResolver,
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      { path: 'invite', component: InviteUserComponent },
      {
        path: 'customer-profile/:id', component: CustomerProfileComponent,
        resolve: {
          'customer': GetPersonByIdResolver
        }
      },
      {
        path: 'add-customer-manually', component: AddCustomerManuallyComponent
      },
      {
        path: 'user-profile/:id', component: UserProfileComponent,
        resolve: { 'user': GetPersonByIdResolver }
      },
      { path: 'add-job', component: AddJobComponent },
      {
        path: 'users-list', component: UsersListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'customers-list', component: CustomersListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'job-details/:id', component: JobDetailsComponent,
        resolve:
        {
          'jobs': jobByIdResolver,
          'tools': GetToolsByCompanyIdResolver,
          // 'mixItemsSummary': GetAllInventoryItemMixedSummaryResolver,
        }
      },
      {
        path: 'tools', component: PermissionsComponent,
        resolve: { 'tools': GetToolsByCompanyIdResolver }
      },
      {
        path: 'my-profile', component: MyProfileComponent,
        resolve: {
          'user': GetPersonByIdResolver,
          'tools': GetToolsByCompanyIdResolver
        }
      },
      {
        path: 'add-ticket', component: SubmitTicketComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver
        }
      },
      {
        path: 'manage-accounts', component: ManageAccountsComponent,
        resolve: { 'accounts': GetAllUserCompaniesResolver }
      },
      {
        path: 'vehicles-list', component: VehiclesListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'vehicles-list/:id', component: VehiclesListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'carwash-services', component: CarWashServicesComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      { path: 'add-vehicle', component: AddVehicleComponent },
      // {path:'add-assigned-vehicle/:id',component:AssignedVehicleQrComponent},
      {
        path: 'car-wash-template', component: CarWashTemplatesComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'qr-code-stickers', component: QrCodeStickersComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      { path: 'products-strip/:id', component: ProductsStripComponent },
      { path: 'view-vehicle/:id', component: ViewVehicleComponent },
      { path: 'complete-membership/:id', component: CompleteMembershipComponent },
      { path: 'customize-website', component: CustomizeWebsiteComponent },
      // {
      //   path: 'qr-codes-template', component: QrCodeMovingComponent,
      //   resolve: {
      //     'tools': GetToolsByCompanyIdResolver,
      //   }
      // },
    ]
  },
  { path: '**', redirectTo: 'admin', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyAdminRoutingModule { }
