<div class="card">
    <div class="tittle">
        <h2>Accordev Car wash</h2>
    </div>
    <div class="body">
        <h6>QR Code has not been assigned yet</h6>
        <p>Please Assign a QR code, in order to be able access the QR code information.</p>
    </div>
</div>
<div class="text-center">
    <button class="btn btn-sm btn-primary" (click)="goBack()">Back</button>
</div>
