import { Router } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dexie } from 'dexie';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
declare const window: any;

@Injectable()
export class OnlineOfflineServiceService {
  internalConnectionChanged = new Subject<boolean>();
  db: any
  baseUrl = environment.baseUrl;
  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }
  get isOnline() {
    return !!window.navigator.onLine;
  }
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.getAction()
    this.createDatabase();
  }

  updateOnlineStatus() {
    this.internalConnectionChanged.next(window.navigator.onLine);
  }
  getAction() {
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }
  createDatabase() {
    this.db = new Dexie('WarehouseDB');
    this.db.version(1).stores({
      persons: 'idIndexedDB,companyId,date,email,role,type',
      inventory: 'idIndexedDB,InventoryItemNumber,category,delivery,itemNumber,jobId,jobNumber,name,price,quality,quantity,type,volume,weight',
      container: 'idIndexedDB,isTrash,inventoryIds,companyId,companyNumber,containerType,containerVolume,jobId,date,description,emptyWeight,fullPercentage,handledBy,type,handledBy,jobNumber'
    });
  }
  addToIndexedDb(model: any, type: string) {
    if (type == 'person') {
      this.db.persons
        .add(model)
        .then(async () => {
          const allItems: any[] = await this.db.persons.toArray();
        })
        .catch(e => {
          alert('Error: ' + (e.stack || e));
        });
    }
    if (type == 'inventory') {
      this.db.inventory
        .add(model)
        .then(async () => {
          const allItems: any[] = await this.db.inventory.toArray();
        })
        .catch(e => {
          alert('Error: ' + (e.stack || e));
        });
    }
    if (type == 'container') {
      this.db.container
        .add(model)
        .then(async () => {
          const allItems: any[] = await this.db.container.toArray();
        })
        .catch(e => {
          alert('Error: ' + (e.stack || e));
        });
    }
  }

  async sendItemsFromIndexedDb() {
    const personsItems: any[] = await this.db.persons.toArray();
    const inventoryItems: any[] = await this.db.inventory.toArray();
    const containerItems: any[] = await this.db.container.toArray();
    if (personsItems) {
      personsItems.forEach((item: any) => {
        // send items to backend...
        this.http.post(this.baseUrl + 'InvitePerson', item).subscribe((data: ResponseModel) => {
          if (data.success) {
            this.db.persons.delete(item.idIndexedDB).then(() => {
            });
          }
        })
      })
      // this.router.navigate(['/admin/users-list']);
    }
    if (inventoryItems) {
      inventoryItems.forEach((item: any) => {
        // send items to backend...
        let idItem = ToolJobsEditItem.inventory
        this.http.post(this.baseUrl + 'inventory' + '/' + item.jobId + '/' + idItem, item).subscribe((data: ResponseModel) => {
          if (data.success) {
            this.db.inventory.delete(item.idIndexedDB).then(() => {
            });
          }
        })
      })
    }
    if (containerItems) {
      containerItems.forEach((item: any) => {
        // send items to backend...
        let idItem = ToolJobsEditItem.containers
        this.http.post(this.baseUrl + 'container/' +  item.jobId + '/' + idItem, item).subscribe((data: ResponseModel) => {
          if (data.success) {
            this.db.container.delete(item.idIndexedDB).then(() => {
            });
          }
        })
      })
    }
  }
}

