import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ShowItemsService {
  baseUrl = environment.baseUrl
  showsItems: ResponseModel
  private messageSource: BehaviorSubject<any>;
  public currentItems: Observable<any>
constructor(
  private http: HttpClient,
) {
  this.getAllShowItemsByCompany()
 }
  async getAllShowItemsByCompany() {
  let user = JSON.parse(localStorage.getItem('user'))
  let jobModel = JSON.parse(localStorage.getItem('jobModel'))
  if (user != null) {
    return await this.http.get(this.baseUrl + 'ShowItemsJobBy/' + jobModel.jobId ).subscribe((response: ResponseModel) => {
      this.showsItems = response
      this.messageSource = new BehaviorSubject<any>(this.showsItems);
      this.currentItems = this.messageSource.asObservable()
      });
  }
}
}
