import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
}
@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit {
  isLinear = false;
  jobFormGroup: FormGroup
  bsConfig: Partial<BsDatepickerConfig>;
  defaultValue: Country = {} as Country;
    constructor(
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private router:Router
      ) { }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.defaultValue= {
      name: 'United States',
      alpha2Code: 'US',
      alpha3Code: 'US',
      numericCode: '840'
    };
    this.createJobForm()
    this.bsConfig = {
      containerClass: 'theme-blue',
      showClearButton: true,
      clearPosition: 'left',
      showTodayButton: true,
      todayPosition: 'center'
    };
  }
  createJobForm() {
    this.jobFormGroup = this.fb.group({
      jobNumber: ['', Validators.required],
      from: ['Customer', Validators.required],
      to: ['Company', Validators.required],
      different: ['Different', Validators.required],
      addressOne: ['', Validators.required],
      addressTow: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
    })
  }
  get f() {
    return this.jobFormGroup.controls;
  }
  onCountrySelected(country: any) {

  }
  addJob(){
    this.jobFormGroup.reset()
    this.spinner.show()
    setTimeout(() => {
      this.spinner.hide();
      this.router.navigate(['admin/job-details'])
    }, 3000);
  }
  setTimeTo(event){
  }
  setTimeFrom(event){
  }
}
