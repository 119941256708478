import { CompanyPlan } from "src/app/models/company/company";
import { EmailNotification } from "src/app/models/tools-model/EmailNotification";
import { InitialRoles, ToolNames } from "src/app/models/tools-model/InitialRoles";
import { SMSNotification } from "src/app/models/tools-model/SmsNotification";
import { ToolDashboardEditItem } from "src/app/models/tools-model/ToolDashboardEditItem";
import { ToolJobsEditItem } from "src/app/models/tools-model/ToolJobsEditItem";
import { ToolSettingEditItem } from "src/app/models/tools-model/ToolSettingEditItem";
import { ToolType } from 'src/app/models/tools-model/ToolType';

export const JobDashboardModel = {
  toolName: ToolNames.Job_Tool,
  toolType: ToolType.Jobs_Tools,
  toolCategory: "Moving",
  items: [
    {
      itemId: ToolJobsEditItem.jobInformation,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
    },
    {
      itemId: ToolJobsEditItem.primaryCustomerInformation,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
    },
    {
      itemId: ToolJobsEditItem.ToolBox,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
    },
    {
      itemId: ToolJobsEditItem.jobStatus,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members and customers",
          subject: "Accordev: Your Job has been in draft status!",
          status: "InActive",
          description: "When Job status has been changed to [Draft]",
          type: EmailNotification.Email_Notification_Job_Status_Draft,
          template:
            "&#013; Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in draft status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team",
        },
        {
          name: "Notify assigned members and customers",
          subject: "Accordev: Your Job has been in Deal status!",
          status: "InActive",
          description: "When Job Status has been changed to [Deal]",
          type: EmailNotification.Email_Notification_Job_Status_Deal,
          template:
            "Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in Deal status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team",
        },
        {
          name: "Notify assigned members and customers",
          subject: "Accordev: Your Job has been In Progress status!",
          status: "InActive",
          description: "When Job Status has been changed to [In Progress]",
          type: EmailNotification.Email_Notification_Job_Status_InProcess,
          template:
            "Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in in progress status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team",
        },
        {
          name: "Notify assigned members and customers",
          subject: "Accordev: Your Job has been in Done status!",
          status: "InActive",
          description: "When Job Status has been changed to [Done]",
          type: EmailNotification.Email_Notification_Job_Status_Done,
          template:
            "Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in done status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team",
        },
        {
          name: "Notify assigned members and customers",
          subject: "Accordev: Your Job has been in Canceled status!",
          status: "InActive",
          description: "When Job Status has been changed to [Canceled]",
          type: EmailNotification.Email_Notification_Job_Status_Canceled,
          template:
            "Dear &lt;&lt;Customer Name&gt;&gt; ,&#013;Your Job (&lt;&lt;Job Number&gt;&gt;) has been in canceled status .&#013;&lt;&lt;Company Name&gt;&gt;&#013;&lt;&lt;Company many lines address&gt;&gt;&#013;&lt;&lt;Company Phone&gt;&gt;&#013;&lt;&lt;Company Logo&gt;&gt;&#013;Best,&#013;The &lt;&lt;Company Name&gt;&gt; team",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.assignedCustomers,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members and Customers",
          subject:
            "Accordev: A Member is Assigned to a job as a primary Customer!",
          description: "When there is a new primary customer",
          type: EmailNotification.Email_Notification_Customer_Primary,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.assignedUsers,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify member via email",
          description: "When a member is assigned to a job",
          subject: "Accordev: You’re Assigned to a job!",
          type: EmailNotification.Email_Notification_Users_Assigned_Job,
          status: "InActive",
        },
        {
          name: "Notify member via email",
          description: "When a member is UnAssigned to a job",
          subject: " Accordev: You’re UnAssigned to a job!",
          type: EmailNotification.Email_Notification_Users_Unassigned_Job,
          status: "InActive",
        },
        // {
        //   name: "Notify assigned users and Customers",
        //   subject:
        //     "Accordev: A User is Assigned to a job as a primary Customer!",
        //   description: "When There is a New Primary Customer",
        //   type: EmailNotification.Email_Notification_Customer_Primary,
        //   status: "InActive",
        // },
      ],
    },
    {
      itemId: ToolJobsEditItem.assigned_Truck,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned member via email",
          subject: "Accordev: A truck is assigned to a job!",
          description: "When a truck is assigned to a job",
          type: EmailNotification.Email_Notification_Trucks_Assigned_Job,
          status: "InActive",
        },

        {
          name: "Notify assigned member via email",
          subject: "Accordev: A truck is unassigned to a job!",
          description: "When a truck is unassigned to a job",
          type: EmailNotification.Email_Notification_Trucks_UnAssigned_Job,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.steps,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members via email",
          subject: "Accordev: A New Step has been Changed or Added!",
          description: "When a new location has been edited or added",
          type: EmailNotification.Email_Notification_Step_Add_Or_Changed,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.Date_and_Time,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members and Customers",
          subject: "Accordev: A New Time or Date has been Changed or Added!",
          description: "When Time & Date has been edited or Added",
          type: EmailNotification.Email_Notification_Date_Add_Or_Changed,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.notes,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members and Customers",
          subject: "Accordev: A New Note has been Changed or Added!",
          description: "When a new Note has been edited or Added",
          type: EmailNotification.Email_Notification_Note_Add_Or_Changed,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.inventory,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify inventory list receiver via email",
          description: "When an inventory list is sent via email",
          type: EmailNotification.Email_Notification_Inventory_Send_Email,
          status: "Active",
        },
      ],
    },
    {
      itemId: ToolJobsEditItem.containers,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
    },
    {
      itemId: ToolJobsEditItem.signatures,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
    },
    {
      itemId: ToolJobsEditItem.progressStatus,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify assigned members and customers",
          description: "When a new progress status has been Added",
          type: EmailNotification.Email_Notification_Progress_Status_Added,
          status: "InActive",
        },
      ]
    },
    {
      itemId: ToolJobsEditItem.Assigned_Vehicle,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify a Manager via email",
          description: "When a new vehicle is added!",
          type: EmailNotification.Email_Notification_New_Vehicle_Add,
          status: "InActive",
        },
        {
          name: "Notify Customer via email",
          description: "When an invoice is sent",
          type: EmailNotification.Email_Notification_Assigned_Vehicle_Send_Email,
          status: "InActive",
        },
      ],
      SmsNotification: [
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is ready to be served",
          type: SMSNotification.Car_Wash_Ready_to_Served,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When job started",
          type: SMSNotification.Car_Wash_Job_Started,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is ready",
          type: SMSNotification.Car_Wash_Ready,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When the service is canceled",
          type: SMSNotification.Car_Wash_Service_Canceled,
          status: "InActive",
        }
      ]
    },
    {
      itemId: ToolJobsEditItem.Military_Bill,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify admin via email",
          subject: "Accordev: A New Note has been Changed or Added!",
          description: "When an invoice is sent",
          type: EmailNotification.Email_Notification_Military_Bill_Sent,
          status: "InActive",
        },
        {
          name: "Notify admin and manager via email",
          subject: "Accordev: A New Note has been Changed or Added!",
          description: "A week before storage is expired",
          type: EmailNotification.Email_Notification_Military_Bill_Storage_Expired,
          status: "InActive",
        },
        {
          name: "Notify emails inserted",
          subject: "Accordev: A New Note has been Changed or Added!",
          description: "Send an invoice  attached  as a PDF via email",
          type: EmailNotification.Email_Notification_Military_Bill_PDF_Email,
          status: "InActive",
        },
      ],
    }
  ],
};
// this is others tools
export const OthersTool = {
  toolType: ToolType.Setting_Tools,
  toolName: ToolNames.Others_Tool,
  toolCategory: "Moving",
  items: [
    {
      itemId: ToolSettingEditItem.Customers_list,
      preview: {
        tittle: "About this Add-In",
        description: `<div>
        <ul style='padding-left: 15px;'>
        <li>Add customer Manually, invite later or then cancel invitation if needed.</li>
                  <li>Invite a customer, then cancel the invitation if needed.</li>
                  <li>Edit customer profile.</li>
                  <li>Access customer jobs.</li>
                <li>Disable or Re-Able a signed up customer.</li>
        </ul>
        </div>
        <strong>Important:</strong> <br>
          <p>-When customers add-In is activated, the admin will always have access {Can edit}. <br>
            In order for the Add-In to be accessible by a member, in the Add-Ins Store that add-in:<br>
      A-  Must be activated. <br>
      B- Its permission settings for that member role is {Can Access} or {View Only}.
      </p>
         <p>Step 1: Home Page > Menu> Customers.</p>
            <p>- You can search for customers by customer name</p>
            <p>- When adding a new customer, you can choose between the two options:
            </p>
            <ul style="list-style-type: none;">
              <li>Option 1: Invite a New Customer.</li>
              <li>Option 2: Add a Customer Manually.</li>
            </ul>
            <p>
               <strong> For option 1: </strong> <br>
              Step 1. 1.1: Click on [Invite a New Customer]. <br>
                Step 1. 1.2 : In the Invite a Customer page:
              </p>
                 <ul style="list-style-type:upper-latin;">
                  <li> Enter customer email.</li>
                  <li>Role will be a customer by default.</li>
                  <li> Click [Invite].</li>
              </ul>
            <p>You can make sure that invitation is sent by clicking the invited customers list, in the pending invites tab.
                </p>
                <p>You can re-invite customers or cancel the invitation. </p>
                <p>In the customers tab, you can change customer status between [Customer] and [Disable], edit customer profile and view/edit assigned jobs.</p>
              <strong>Notes: </strong> <br>
              <ul style="list-style-type: square; margin-bottom:0;">
                <li>When a customer signs up, the customer invitation record in the pending invites tab disappears, and a new record will be added in the members tab.</li>
                          <li>When the admin sets the company account for the first time, the customer's Add-In will be inactive and the default permission settings for managers will be {Can Edit}, but for team leaders and crews is {No Access}.
      </li>
              </ul>
                <p>
                  <strong> For option 2: </strong> <br>
                  <span>Step 1. 2.1: Click on [Add a Customer Manually].</span> <br>
                  <span>Step 1.2.2 : In adding a customer page:</span> </p>
                 <ul style="list-style-type:upper-latin;">
                  <li>  Enter customer information.</li>
                  <li>Role will be a customer by default.</li>
                  <li>Click [Add Add Customer].</li>
              </ul>
              <strong>Notes: </strong> <br>
              <ul style="list-style-type: square; margin-bottom:0;">
                <li>You can make sure that a customer is added by clicking the customers list in the customers page.</li>
                          <li>You can invite and re-invite customers added manually.</li>
                          <li>After inviting customer, once the customer signs up, in the same customer record in customer list, the button [Re-invite] turns to [Signed Up].</li>
                          <li>After a customer signed up, you can switch customer status between [Active] and [Disabled].
      </li>
              </ul>`
      },
      emailNotification: [
        {
          name: "Notify customer via email",
          description: "When customer is invited or re-invited",
          subject: "You’re invited to join our Accordev portal!",
          type: EmailNotification.Email_Notification_Invite_Reinvite,
          status: "Active",
        },
        {
          name: "Notify customer via email",
          subject: "Your Accordev Invitation was canceled!",
          description: "When Customer invitation was canceled",
          type: EmailNotification.Email_Notification_Invitation_Canceled,
          status: "InActive",
        },
        {
          name: "Notify customer via email",
          description: "When customer account is disabled",
          subject: "Your Accordev account was disabled!",
          type: EmailNotification.Email_Notification_Disable,
          status: "InActive",
        },
        {
          name: "Notify customer via email",
          description: "When a customer account is activated",
          subject: "Accordev: Your Account has been Activated!",
          type: EmailNotification.Email_Notification_Active,
          status: "InActive",
        },
        {
          name: "Notify customer via email",
          description: "When customer has signed Up",
          subject: "You have just signed up to Accordev!",
          type: EmailNotification.Email_Notification_SignedUp,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolSettingEditItem.Users_list,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  			<li>Invite a member, then cancel the invitation if needed.</li>
            <li>Edit member profile.</li>
            <li>Disable or change member role.</li>
      </ul>
    <p>  <strong>Important:</strong> <br></p>
    <ul style="list-style-type: square;">
     <li>Members Add-In is available in Pro and Premium Accordev plans.</li>
     <li> Make sure the Members Add-In is already activated in the Add-Ins Store.</li>
     <li>When Members Add-In is activated, the admin always has the access {Can edit}, but lower roles (Manager, Team Leader, or Crew) their access is based on Add-In Settings  (No Access, Can Edit, or View Only) in the Add-Ins Store.</li><br>
      <p>Home Page > Menu> Members <br>
        - You can search members by member name <br>
        - You can invite a member :
       </p>
        <p>Step 1: Click on [Invite a new member]. <br>
          Step 2: In the invite a new member page:</p>
             <ul style="list-style-type:upper-latin;">
                  <li>Enter member email.</li>
                  <li>Choose Member Role.</li>
                  <li>Click [Invite].</li>
              </ul>
          <p>
            You can make sure that invitation is sent by clicking the 				invited Members Page> Pending Invites Tab
          </p>
             <p>You can re-invite members or cancel the invitation.</p>
             <p>In the members tab, you can change member role, disable member                   account, edit member profile.</p>
            </ul>
             <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>When member signs up, member invitation record in pending invites tab disappears, and a new record will added in members tab</li>
                    <li>When the admin sets the company account for the first time, the members add-in will be inactive and the default permission settings for managers will be {Can Edit}, but for team leaders and crews is {No Access}.</li>
        </ul>`
      },
      emailNotification: [
        {
          name: "Notify member via email",
          subject: "You’re Invited to Join our Team!",
          description: "When member is invited or re-Invited",
          type: EmailNotification.Email_Notification_Invite_Reinvite,
          status: "Active",
        },
        {
          name: "Notify member via email",
          subject: "Your Accordev Role has been Changed!",
          description: "When member role has been changed",
          type: EmailNotification.Email_Notification_Role_Change,
          status: "InActive",
        },
        {
          name: "Notify member via email",
          subject: "Your Accordev Account was canceled!",
          description: "When member invitation was canceled",
          type: EmailNotification.Email_Notification_Invitation_Canceled,
          status: "InActive",
        },

        {
          name: "Notify member via email",
          subject: "Your Accordev Account was Disabled!",
          description: " Your Accordev Account was Disabled!",
          type: EmailNotification.Email_Notification_Disable,
          status: "InActive",
        },
        {
          name: "Notify member via email",
          description: "When a member account is activated",
          subject: "Accordev: Your Account has been Activated!",
          type: EmailNotification.Email_Notification_Active,
          status: "InActive",
        },
        {
          name: "Notify member via email",
          subject: "You have just signed up to Accordev!",
          description: "When member has Signed Up",
          type: EmailNotification.Email_Notification_SignedUp,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolSettingEditItem.Trucks_list,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  			<li>Add a Truck</li>
            <li>Activate or deactivate a truck.</li>
            <li>Edit Truck profile.</li>
            <li>Assign a driver to a truck.</li>
  </ul>

    <p>  <strong>Important:</strong> <br></p>
    <ul style="list-style-type: square;">
     <li>Trucks Add-In is available in Pro and Premium Accordev plans</li>
     <li>Make sure the Trucks Add-In is already activated in the Add-Ins Store.</li>
     <li> When Trucks Add-In is activated, the admin always has the access {Can edit}, but lower roles (Manager, Team Leaders, or Crew) their access is based on Add-In Settings  (No access, Can edit or View only) in the Add-Ins Store.</li><br>
      <p>Home Page > Menu> Trucks <br>
        - You can search trucks by truck number <br>
        - You can add a truck:
       </p>
        <p>Step 1: Click on [Add a new Truck]. <br>
          Step 2: Fill truck information:</p>
             <ul style="list-style-type:upper-latin;">
                  <li>Enter Truck information.</li>
                  <li>Choose Truck type.</li>
                  <li> Click [Add Truck].</li>
              </ul>
          <p>You can make sure that a truck is added by clicking the trucks list in the trucks page.
          </p>
             <p>In the trucks page, you can change any truck type, switch its status between [In-service] and [Out of Service], or edit truck information.</p>
            </ul>
             <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>Only when truck status is in service, in any job dashboard you will be able to find a truck listed in unassigned trucks tab, where you can assign or unassign a driver for it.</li>
                    <li>When the admin sets the company account for the first time, the Trucks Add-In will be inactive and the default permission settings for managers will be {Can Edit}, but for team leaders and crews is {No Access}.</li>
        </ul>`
      },
      emailNotification: [
        {
          name: "Notify Assigned Driver via email",
          description: "When member (driver) is assigned to a truck",
          type: EmailNotification.Email_Notification_Primary_Driver,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolSettingEditItem.Company_Account,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      },
      emailNotification: [
        {
          name: "Notify Admin via email",
          description: "When company account information is edited",
          subject:
            "There have been some changes to Your Accordev Company Account Information!",
          type: EmailNotification.Email_Notification_Information_Saved,
          status: "InActive",
        },
        {
          name: "Notify Manager via email",
          description: "When manager can edit the company account",
          subject: "Now You Can Edit The  Accordev Company Account!",
          type: EmailNotification.Email_Notification_Manger_Read_Write,
          status: "InActive",
        },
        {
          name: "Notify a Manager via email",
          description: "When no longer able  to edit Accordev company account!",
          subject:
            "Now You are no longer able to edit  Accordev Company Account!",
          type: EmailNotification.Email_Notification_Manger_Disable,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolSettingEditItem.My_Profile,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  			<li>Edit profile information.</li>
        <li>Edit address.</li>
       </ul>
    <p>  <strong>Important:</strong> <br></p>
    <ul style="list-style-type: square;">
     <li>My Profile Add-In is available in any Accordev plan.</li>
     <li>When my profile Add-in is activated, the member or customer will have access {Can Edit}.</li>
     </ul>
        <p>Step 1: Home Page> Menu> My Profile.</p>
        <p>You can view or edit your information.  Your access to your               profile is based on the permission settings in the Add-Ins Store.          </p>
        <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>When the admin sets the company account for the first time, the My Profile Add-In will be active and the default permission settings for all roles will be {Can Edit}.</li>
        </ul>`
      },
      emailNotification: [
        {
          name: "Notify member via email",
          subject: "There have been some changes to Your Accordev Profile!",
          description: "When a change to his profile information is saved",
          type: EmailNotification.Email_Notification_My_Profile_Change,
          status: "InActive",
        },
      ],
    },
    {
      itemId: ToolSettingEditItem.knowledge_Base,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  			<li>Installing the Accordev App.</li>
            <li>Customize software with the Add-Ins Store.</li>
            <li>Adding or Inviting a Customer.</li>
            <li>Inviting a member.</li>
           <li>Adding a truck.</li>
           <li>Creating a New Job.</li>
           <li>Notifying a Customer or a Member via Email.</li>
           <li>Changing an Add-Ins Permission Settings.</li>
           <li>Overriding job default permissions.</li>
           <li>Submitting a Support Ticket.</li>
  </ul>

    <p>  <strong>Important:</strong><br>
      When Knowledge Base Add-In is activated: <br></p>
      <ul style="list-style-type: square;">
        <li>Customer can only access some of the Knowledge Base Accordions (List): </li>
           <ul style="list-style-type: none;">
        <li>Installing the Accordev App</li>
        <li>Inviting a member</li>
        <li>Adding a truck</li>
        <li>Creating a new job</li>
      </ul>
        <li>Third Parties can only access some of the Knowledge Base Accordions (List):</li>
        <ul style="list-style-type: none;">
        <li>Installing the Accordev App</li>
        <li>Adding or inviting a customer</li>
        <li>Inviting a member</li>
        <li>Creating a new job</li>
        <li>Notifying a customer or a member via Email</li>
        <li>Submitting a support ticket</li>
      </ul>
      </ul>
       <ul style="list-style-type: none; padding-left:15px">
        <li>Step 1: Make sure that the Knowledge Base Add-In is activated in the Add-Ins Store.</li>
        <li>Step 2: Home Page> [?] Icon.</li>
        <li>Step 3: Click on any Accordion.</li>
      </ul><br>
        <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>Information list in the Knowledge Base Window is for basic actions, if you see them not.</li>
                    <li>When the admin sets the company account for the first time, the Knowledge Base Add-In will be {Active} all roles will have the access {View only}.
</li>
        </ul>`
      },
    },
    {
      itemId: ToolSettingEditItem.Support_Tickets,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  			<li>Submit, discard or close a ticket</li>
            <li>Discard or close an already submitted ticket.</li>
            <li>Edit ticket.</li>
            <li>Check ticket status history.</li>
  </ul>

    <p>  <strong>Important:</strong> <br></p>
    <ul style="list-style-type: square;">
     <li>Support Tickets Add-In is available in any Accordev plan.</li>
     <li>In order for the member to submit a support ticket, a member must be        logged in and  able to access the Support Ticket Add-in.</li>
     <li>Make sure the Support Tickets Add-In is already activated in the          Add-Ins Store.</li>
        <li> When support tickets Add-In is activated, The admin always                has the access {Can edit}, but lower roles (Manager, Team                  Leaders, Crew, or Third party) their access is based on Add-              In Settings  (No access, Can edit or View only) in the Add-                Ins Store.</li><br>
     </ul>
      <p>Step 1: Login to your Accordev account. <br>
       Step 2: Go to Menu> Support Tickets. <br>
       Step 3: Click on [Add New]. <br>
       Step 4: Fill in the required information.<br>
       Step 5: Take a screenshot and upload it (if possible).<br>
       Step 6: Click on [Add Ticket].<br>
      </p>
        <p>The Accordev support team will respond as soon as possible.</p>
        <p>There are two tabs in the support tickets page, all tickets that are not resolved yet will be found under [In Progress] tab, and all resolved tickets will be found under [Closed] tab.</p>
        <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>When a ticket is submitted the member will receive a confirmation email.</li>
                    <li>When a ticket is resolved the member will receive another confirmation email.</li>
        </ul>`
      },
    },
    {
      itemId: ToolSettingEditItem.Warehouse_Map,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
        <li>Customize a warehouse map.</li>
        <li>Color code map cells.</li>
        <li>Create a new container, move it to trash if needed.</li>
        <li>Drag and drop containers or Items into the map cells.</li>
        <li>Set the daily  time which the map image will be stored at.</li>
</ul>

  <p>  <strong>Important:</strong> <br></p>
  <ul style="list-style-type: square;">
   <li> Warehouse Map is an Add-In that is only included in the Premium Subscription Plan.</li>
   <li> In order for the Add-In to be accessible by a member in the Home Page, in the Add-Ins Store that permission settings for the member role is {Can Access}.</li>
         <li>If a member has access to the Warehouse Map, then he has access to all functions in the Warehouse Map. </li>
   </ul>
      <p>Home Page > WH Map.</p>
      <p>If the Warehouse Map Add-in is activated, and your access is {Can edit}, then you can view the [WH Map] in the menu and access through it to the Warehouse Map, but if your permission is {No Access}, then it will be invisible for you.  </p>
      <strong>Notes: </strong> <br>
      <ul style="list-style-type: square; margin-bottom:0;">
        <li>As soon as you click on the [Warehouse  Map] button, Accordev will take you directly to the Warehouse Map Dashboard.</li>
      </ul>`
      }
    },
    {
      itemId: ToolSettingEditItem.Car_Wash_Vehicle,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  <li>Add a new car profile.</li>
  <li>Invite a customer (Via email, share a link, or scan a QR Code).</li>
  <li>Create a new Card Membership.</li>
  <li>Create a new CashMembership.</li>
  <li>Sell a service package.</li>
  <li>Check in/out cars when served.</li>
  <li>Check day, monthly, or yearly statistics.</li>
</ul>

<p> <strong>Important:</strong> <br></p>
<ul style="list-style-type: square;">
  <li> Car Wash Manager an Add-In is only included in the Premium Subscription Plan.</li>
  <li>In order for the Add-In to be accessible by a member in the menu list, in the Add-Ins Store that permission settings for the member role is {Can Access} or {View Only}. </li>
</ul>
 <p>Home Page > Car Wash Manager.</p>
 <strong>Notes: </strong> <br>
 <ul style="list-style-type: square; margin-bottom:0;">
 <li>As soon as you click on the [Car Wash Manager] button, Accordev will take you directly to the Car Wash Manager Dashboard.</li>
   <li>When the admin sets the company account for the first time, the Car Wash Manager Add-In will be inactive and the default permission settings for managers will be {Can Edit}, but for team leaders and crews is {No Access}
</li>
  </ul>`
      },
      emailNotification: [
        {
          name: "Notify a Manager via email",
          description: "When a new vehicle is added!",
          type: EmailNotification.Email_Notification_New_Vehicle_Add,
          status: "InActive",
        },
      ],
      SmsNotification: [
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is ready",
          type: SMSNotification.Car_Wash_Ready,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is ready to be served",
          type: SMSNotification.Car_Wash_Ready_to_Served,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is checked in",
          type: SMSNotification.Car_Wash_Checked_In,
          status: "InActive",
        },
        {
          name: "Notify customer via  text message",
          description: "When the customer's vehicle is out of Queue",
          type: SMSNotification.Car_Wash_Out_Of_Queue,
          status: "InActive",
        },
      ]
    },
    {
      itemId: ToolSettingEditItem.Car_Wash_Qr_Code,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  <li>Print a membership Sticker.</li>
  <li>Access the link to buy new sticker sheets.</li>
</ul>

<p> <strong>Important:</strong> <br></p>
<ul style="list-style-type: square;">
  <li> Membership Stickers Add-In is only included in the Premium Subscription Plan.</li>
  <li> In order for the member to print, a member must be logged in and  able to access the Membership Stickers Add-in. </li>
    <li>When Membership Stickers Add-In is activated, The admin and manager will have the access {Can edit}, but lower roles (Team Leaders, Crew) their access is based on Add-In Settings  (No access, Can edit or View only). </li>
</ul>
 <p>
  Step 1: Go to Menu> Membership Stickers. <br>
  Step 1: Select the template. <br>
  Step 3: Choose the sticker size. <br>
  Step 4: Click [print].
  </p>
   <p>Make sure the paper size is A4.</p>
 <strong>Notes: </strong> <br>
 <ul style="list-style-type: square; margin-bottom:0;">
 <li>Make sure the paper size is [A4].</li>
 <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.
</li>
  </ul>`
      }
    }
  ],
};
// this is others tools
export const HomePageTool = {
  toolType: ToolType.Dashboard_Tools,
  toolName: ToolNames.Home_Page_Tool,
  toolCategory: "Moving",
  items: [
    {
      itemId: ToolDashboardEditItem.Create_New_Job,
      preview: {
        tittle: "About this Add-In",
        description: `<ul style="padding-left: 15px!important;">
        <li>Create a new draft job.</li>
        <li>Use a template.</li>
        </ul>
        <strong>Important:</strong> <br>
          <p>In order for the Add-In to be accessible by a member in the home page, in the Add-Ins Store. </p>
        <ul>
        <li>A- Must be activated.</li>
        <li>B- The permission settings for that member role is {Can Access}. It is also able to be customized for that member only, also {Can Access}.</li>
        </ul>
         <p>Home Page > Draft Job.</p>
          <p>
          If the draft job Add-In is activated, and the member access is {Can Access}, then the member can view the [Draft Job] button and create a new Draft Job, but if your access is {No Access}, then the button will be invisible for you
          </p>
          <p>If there were jobs pinned as templates, you can clone any pinned job. </p>
          <strong>When a job cloned:</strong>
          <ol>
            <li>The following tools will have the same information: <br>
              - Customer information <br>
              - Assigned customer(s) <br>
              - Job Status <br>
              - Assigned Trucks <br>
              - Location(s)
             </li>
              <li>All other tools will have the default information.
         </li>
          </ol>
              <strong>Notes: </strong> <br>
              <ul>
                <li>As soon as you click on the [Draft Job] button, Accordev will take you directly to the draft job dashboard.</li>
                  <li>In the Draft Job>  assigned members add-in (tool), by default, you will be assigned to the job you create as a draft, and there will be a mark near your member name that refers to you as the job creator. </li>
                  <li>When the admin sets up the Accordev company account for the first time, in the Add-Ins Store, the status of that Add-In will be {inactive}.
        The members and customers default permission settings will be: <br>
                    <span>A- Managers: {Can Access}.</span> <br>
                    <span> B- Team leaders, Crews, 3rd Parties and Customers: {No Access} </span>
            </li>
              </ul>`
      }
    },
    // {
    //   itemId: ToolDashboardEditItem.Create_New_Job,
    //   preview : {
    //     tittle : "About this Add-In",
    //     description :"Coming Soon"
    //   }
    // },
    {
      itemId: ToolDashboardEditItem.Search_Space,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  <li>Search and access the jobs assigned to you.</li>
  </ul>
  <strong>Important:</strong> <br>
    <p>-In order for the Add-In to be accessible by a member in the Home Page, in the Add-Ins Store that add-in: <br>
A- Must be activated. <br>
B- Its permission settings for that member role is {Can Access} or customized only for that member to be {Can Access}.
</p>
   <p>Step 1: Home Page > Click on the [Search] accordion.</p>
   <p>Step 2: Enter the key ward.</p>
   <p>Step 3:  Click on Search.</p>
    <p>
    Members will only be able to view and access the jobs they are assigned to, as well as the jobs they created.
    </p>
        <strong>Notes: </strong> <br>
        <ul style="list-style-type: square;">
          <li>You can search for the jobs by the job number.</li>
            <li>Whenever you click on any job, Accordev will take you directly to the Job Dashboard. </li>
        </ul>`
      }
    },
    {
      itemId: ToolDashboardEditItem.Job_status_Statistics,
      preview: {
        tittle: "About this Add-In",
        description: ``
      }
    },
    {
      itemId: ToolDashboardEditItem.Calendar,
      preview: {
        tittle: "About this Add-In",
        description: `
        <ul >
  <li>Navigation the calendar to Search and access the jobs assigned to you in a specific date.</li>
  </ul>
  <strong>Important:</strong> <br>
    <p>-In order for the Add-In to be accessible by a member in the Home Page, in the Add-Ins Store that Add-In: <br>
A- Must be activated. <br>
B- Its permission settings for that member role is {Can Access} or customized only for that member to be {Can Access}
</p>
   <p>Step 1: Home Page > Click on the [Calendar] accordion.</p>
   <p>Step 2: There are two ways to search the jobs assigned to a specific date. <br>
     A-Clicking on [Choose Date Space], navigate the dates and click on that specific date. <br>
     B-Navigate through the calendar, then click on that specific date.
    </p>
    <p>
    Members will only be able to view and access the jobs they are assigned to, as well as the jobs they created.
    </p>
        <strong>Notes: </strong> <br>
        <ul style="list-style-type: square; margin-bottom:0;">
          <li>Whenever you click on any job, Accordev will take you directly to the Job Dashboard.</li>
        </ul>
    `
      }
    },
    {
      itemId: ToolSettingEditItem.Warehouse_Map,
      preview: {
        tittle: "About this Add-In",
        description: "Coming Soon"
      }
    },

  ]
}
