import { MaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { CompanyUserModule } from './modules/company-user/company-user.module';
import { LayoutModule } from './layout/layout.module';
import { InviteUserComponent } from './modules/company-admin/pages/invite-user/invite-user.component';
import { LoaderComponent } from './modules/company-admin/pages/Loader/Loader.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxPaginationModule } from 'ngx-pagination';
import { PrintModule } from './modules/print-tage/print.module';
import { DisabledCompanyComponent } from './modules/Disabled-Company/Disabled-Company.component';
import { DeactivatedCompanyComponent } from './modules/Deactivated-Company/Deactivated-Company.component';
import { DisableCustomerComponent } from './modules/company-admin/pages/invite-complete/disable-customer/disable-customer.component';
import { SuccessStripComponent } from './modules/strip/success-strip/success-strip.component';
import { ErrorStripeComponent } from './modules/strip/error-stripe/error-stripe.component';
import { NotPayCompanyComponent } from './modules/NotPay-Company/NotPay-Company.component';
import { NotPayPeriodCompanyComponent } from './modules/NotPayPeriod-company/NotPayPeriod-company.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { QrScanCapacitorComponent } from './modules/qr-scan-capacitor/qr-scan-capacitor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InviteCompleteCarWashComponent } from './modules/company-admin/pages/invite-complete-car-wash/invite-complete-car-wash/invite-complete-car-wash.component';
import { MobileBranchModalComponent } from './layout/pages/mobile-branch-modal/mobile-branch-modal.component';
import { QueueOrderComponent } from './modules/company-admin/pages/car-wash-folder/Queue-Order/Queue-Order.component';
import { GlobalWebsiteComponent } from './modules/global-website/global-website.component';
import { CommonModule } from '@angular/common';
import { LightgalleryModule } from 'lightgallery/angular/10';
import { SafePipe } from './modules/global-website/safe.pipe';
const config: SocketIoConfig = { url: environment.baseUrl, options: {} };
@NgModule({
  declarations: [
    AppComponent,
    InviteUserComponent,
    LoaderComponent,
    DisabledCompanyComponent,
    DeactivatedCompanyComponent,
    DisableCustomerComponent,
    NotPayCompanyComponent,
    NotPayPeriodCompanyComponent,
    SuccessStripComponent,
    ErrorStripeComponent,
    QrScanCapacitorComponent,
    InviteCompleteCarWashComponent,
    QueueOrderComponent,
    MobileBranchModalComponent,
    GlobalWebsiteComponent,
    SafePipe
    ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    // GalleryModule,
    MDBBootstrapModule.forRoot(),
    NoopAnimationsModule,
    BrowserAnimationsModule,
    CoreModule,
    ToastrModule.forRoot(),
    MaterialModule,
    HttpClientModule,
    MatSelectCountryModule,
    CompanyUserModule,
    LayoutModule,
    DragDropModule,
    MatTabsModule,
    NgxPaginationModule,
    // NgxPrintModule,
    // NgxPrintElementModule,
    PrintModule,
    NgxSliderModule,
    LightgalleryModule,
    // DndModule.forRoot(),
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    // MatGoogleMapsAutocompleteModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBUMroxNSrTfjm2m4W_FMcKmFOiR3QwlEA',
    //   libraries: ['places']
    // }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBUMroxNSrTfjm2m4W_FMcKmFOiR3QwlEA'
    // })
  ],
  exports:[
    MatSelectCountryModule,
    HttpClientModule,
    PrintModule,
    NgxSliderModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
