import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { resolve } from 'dns';
import { AuthGuard } from './core/_helper/auth.guard.ts';
import { GetAllMangerAndAdminInCompanyResolver } from './core/_resolver/getAllMangerAndAdminInCompany-resolver';
import { GetCompanyByIdResolver } from './core/_resolver/getCompanyById-resolver';
import { GetCustomersByCompanyResolver } from './core/_resolver/getCustomersByCompany-resolver';
import { GetPersonByIdResolver } from './core/_resolver/getPersonById-resolver';
import { GetToolsByCompanyIdResolver } from './core/_resolver/getToolsByCompanyId-resolver';
import { GetTrucksByCompanyResolver } from './core/_resolver/getTrucksByCompany-resolver';
import { GetUserByCompanyIdResolver } from './core/_resolver/getUserByCompanyId-resolver';
import { JobsResolver } from './core/_resolver/jobs-resolver';
import { Role } from './models/role/RoleModel';
import { InviteCompleteComponent } from './modules/company-admin/pages/invite-complete/invite-complete.component';
import { LoginSuperComponent } from './layout/pages/super-admin/pages/login-super/login-super.component';
import { DisabledCompanyComponent } from './modules/Disabled-Company/Disabled-Company.component';
import { DeactivatedCompanyComponent } from './modules/Deactivated-Company/Deactivated-Company.component';
import { DisableCustomerComponent } from './modules/company-admin/pages/invite-complete/disable-customer/disable-customer.component';
import { NewBranchComponent } from './modules/auth/pages/new-branch/new-branch.component';
import { SecondInviteComponent } from './modules/company-admin/pages/invite-complete/second-invite/second-invite.component';
import { Auth2Guard } from './core/_helper/auth2.guard.ts';
import { CancelPersonComponent } from './modules/company-admin/pages/invite-complete/cancel-person/cancel-person.component';
import { CompanyPlansComponent } from './modules/auth/pages/plans/company-plans/company-plans.component';
import { SuccessStripComponent } from './modules/strip/success-strip/success-strip.component';
import { ErrorStripeComponent } from './modules/strip/error-stripe/error-stripe.component';
import { SignupPaymentComponent } from './modules/auth/pages/signup-payment/signup-payment.component';
import { GetCompanyInfoSignupResolver } from './core/_resolver/getCompanyInfoSignup-resolver';
import { NotPayCompanyComponent } from './modules/NotPay-Company/NotPay-Company.component';
import { NotPayPeriodCompanyComponent } from './modules/NotPayPeriod-company/NotPayPeriod-company.component';
import { ViewVehicleGlobalComponent } from './modules/company-admin/pages/car-wash-folder/view-vehicle-global/view-vehicle-global.component';
// import { MedRouteQrComponent } from './modules/company-admin/pages/car-wash-folder/med-route-qr/med-route-qr.component';
import { AddVehicleComponent } from './modules/company-admin/pages/car-wash-folder/add-vehicle/add-vehicle.component';
import { NotAssignedQrComponent } from './modules/company-admin/pages/car-wash-folder/not-assigned-qr/not-assigned-qr.component';
import { QrScannerComponent } from './modules/company-admin/pages/car-wash-folder/qr-scanner/qr-scanner.component';
import { QrMobilePrintComponent } from './modules/print-tage/qr-mobile-print/qr-mobile-print/qr-mobile-print.component';
import { InventoryTagMobileComponent } from './modules/print-tage/inventory-mobile-print/inventory-tag-mobile/inventory-tag-mobile.component';
import { AllInventoriesMobilePrintComponent } from './modules/print-tage/inventory-mobile-print/all-inventories-mobile-print/all-inventories-mobile-print.component';
import { ContainerMobilePrintComponent } from './modules/print-tage/container-mobile-print/container-mobile-print/container-mobile-print.component';
import { ItemsListContainerMobileComponent } from './modules/print-tage/container-mobile-print/items-list-container-mobile/items-list-container-mobile.component';
import { AllInventoriesPrintMapMobileComponent } from './modules/print-tage/inventory-mobile-print/all-inventories-print-map-mobile/all-inventories-print-map-mobile.component';
import { InviteCustomerCarWashComponent } from './modules/company-admin/pages/invite-customer-car-wash/invite-customer-car-wash.component';
import { QrScanCapacitorComponent } from './modules/qr-scan-capacitor/qr-scan-capacitor.component';
import { InviteCompleteCarWashComponent } from './modules/company-admin/pages/invite-complete-car-wash/invite-complete-car-wash/invite-complete-car-wash.component';
import { MedRouteQrComponent } from './modules/company-admin/pages/car-wash-folder/med-route-qr/med-route-qr.component';
import { QueueOrderComponent } from './modules/company-admin/pages/car-wash-folder/Queue-Order/Queue-Order.component';
import { GlobalWebsiteComponent } from './modules/global-website/global-website.component';
const routes: Routes = [
  {
    path: 'payment/:id', component: SignupPaymentComponent,
    resolve: { 'companyInfo': GetCompanyInfoSignupResolver }
  },
  {
    path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    // canActivate: [Auth2Guard],
  },
  {
    path: 'admin', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    resolve: {
      'jobs': JobsResolver,
      'tools': GetToolsByCompanyIdResolver,
      // 'user': GetPersonByIdResolver,
      'company': GetCompanyByIdResolver,
      // 'adminsInCompany':GetAllMangerAndAdminInCompanyResolver
    }
  },
  {
    path: 'admin/:id', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    resolve: {
      'jobs': JobsResolver,
      // 'usersToCompany': GetUserByCompanyIdResolver,
      // 'customersToCompany': GetCustomersByCompanyResolver,
      // 'trucksToCompany': GetTrucksByCompanyResolver,
      // 'user': GetPersonByIdResolver,
      'company': GetCompanyByIdResolver,
      'tools': GetToolsByCompanyIdResolver,
      // 'adminsInCompany':GetAllMangerAndAdminInCompanyResolver
    }
  },
  // user routing
  {
    path: 'user', loadChildren: () => import('./layout/pages/user-layout/user-layout.module').then(u => u.UserLayoutModule),
    resolve: {
      // 'jobs': JobsResolver,
      'tools': GetToolsByCompanyIdResolver,
      // 'user': GetPersonByIdResolver,
      // 'company': GetCompanyByIdResolver,/
    }
  },
  {
    path: 'user/:id', loadChildren: () => import('./layout/pages/user-layout/user-layout.module').then(u => u.UserLayoutModule),
    resolve: {
      // 'jobs': JobsResolver,
      'tools': GetToolsByCompanyIdResolver,
      // 'user': GetPersonByIdResolver,
      // 'company': GetCompanyByIdResolver,
    }
  },
  { path: 'super-company', loadChildren: () => import('./layout/pages/super-admin/super-admin.module').then(m => m.SuperAdminModule) },
  { path: 'super-login', component: LoginSuperComponent },
  { path: 'invite-complete', component: InviteCompleteComponent },
  { path: 'second-invite', component: SecondInviteComponent },
  { path: 'cancel-person', component: CancelPersonComponent },
  { path: 'disabled-company', component: DisabledCompanyComponent },
  { path: 'deactivated-company', component: DeactivatedCompanyComponent },
  { path: 'not-paid', component: NotPayCompanyComponent },
  { path: 'not-period', component: NotPayPeriodCompanyComponent },
  { path: 'disable-customer', component: DisableCustomerComponent },
  { path: 'success', component: SuccessStripComponent },
  { path: 'failure', component: ErrorStripeComponent },
  { path: 'view-vehicle-global/:id', component: ViewVehicleGlobalComponent },
  { path: 'not-assigned-qr', component: NotAssignedQrComponent },
  { path: 'invite-customer/:id', component: InviteCustomerCarWashComponent },
  { path: 'invite-customer-complete', component:InviteCompleteCarWashComponent},
  { path: 'turn-order/:id', component:QueueOrderComponent},
  { path: 'med-route-qr/:id', component: MedRouteQrComponent },
  { path: 'web/:id', component: GlobalWebsiteComponent },
  {
    path: 'new-company-plans', component: CompanyPlansComponent,
  },
  {
    path: 'new-branch', component: NewBranchComponent,
    resolve: { 'person': GetPersonByIdResolver }
  },
  {path:'qr-scanner',component:QrScannerComponent},
  {path:'qr-scanner-mobile',component:QrScanCapacitorComponent},
  {path:'qr-mobile-print',component:QrMobilePrintComponent},
  {path:'inventory-tag-print',component:InventoryTagMobileComponent},
  {path:'all-inventory-tag-print',component:AllInventoriesMobilePrintComponent},
  {path:'container-tag-print',component:ContainerMobilePrintComponent},
  {path:'items-in-container-tag-print',component:ItemsListContainerMobileComponent},
  {path:'items-in-map-tag-print',component:AllInventoriesPrintMapMobileComponent},
  { path: '**', redirectTo: 'admin', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
