<div class="card">
    <div class="tittle-section">
        <p>
            <!-- <i class="uil uil-print uil-medium pr-1"></i>  -->
            QR Code Stickers
        </p>
        <!-- <div class="mx-auto">
          <span>Data to Print</span>
      </div> -->
    </div>
    <div>
    </div>
    <div class="templates">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 1</span>
                    <span class="tittle">Company name, Phone Number</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <!-- <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->
                        <!-- <img src="./assets/car-wash/QR/qr.png" alt="qr-img"> -->
                        <qrcode [qrdata]="baseWordpressUrl" [width]="175" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' "></qrcode>
                        <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p class="mb-0">{{companyInfo.companyPhone}}</p>
                        </div>
                    </div>
                </div>
                <div class="third-side" *ngIf="qrCodeObj.itemStatus == 'Active' && qrCodeObj.roleStatus == 'active'">
                    <!-- <div class="print"> -->
                    <div class="form-group">
                        <!--  -->
                        <!-- <span class="form-control"> 1 Page ( 30 Car Labels ) </span> -->
                        <select class="browser-default custom-select" disabled id="qty" name="quantity" [(ngModel)]="quantity" (change)="changeQuantity($event.target.value)">
                        <option value="10" selected disabled> 10 Stickers, Size: (2"x4") </option>
                        <!-- <option value="30"> 30 Stickers, Size: (1"x2-5/8") </option> -->
                      </select>
                        <!-- <input type="text" id="name" name="name" class="form-control" required minlength="4" appForbiddenName="bob" [(ngModel)]="hero.name" #name="ngModel">

                      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">

                          <div *ngIf="name.errors?.['required']">
                              Name is required.
                          </div>
                          <div *ngIf="name.errors?.['minlength']">
                              Name must be at least 4 characters long.
                          </div>
                          <div *ngIf="name.errors?.['forbiddenName']">
                              Name cannot be Bob.
                          </div>

                      </div> -->
                    </div>
                    <div>
                        <button class="btn btn-sm btn-primary" (click)="addQrCodes(enumTemplates.Template_One)">Print one page</button>
                        <!-- <button class="badge badge-light mx-1" (click)="print.print('test_print', {printMode: 'template', pageTitle: 'Hello World'})">Print Mobile</button> -->
                    </div>
                    <!-- </div> -->
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                        <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.</li>
                    </ul>
                </div>
                <div class="notes">
                    <strong class="text-blue mb-2 pr-1" (click)="openLink(Print_QrCode_stickers)">
                      <a > Recommended Stickers  <i class="uil uil-external-link-alt uil-small "></i></a>
                    </strong>
                    <!-- <ul>
                        <li (click)="openLink(tenLink)"> 10 Stickers per sheet, Size: (2"x4") <br>
                            <a>Avery Glossy Crystal Clear (10 Stickers per sheet) <i class="uil uil-external-link-alt uil-small "></i> </a>
                        </li>
                        <li (click)="openLink(thirtyLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Avery Glossy Crystal Clear (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 2</span>
                    <span class="tittle">Company name, Num, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [width]="175" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' "></qrcode>
                        <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p>{{companyInfo.companyPhone}}</p>
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                    </div>
                </div>
                <div class="third-side" *ngIf="qrCodeObj.itemStatus == 'Active' && qrCodeObj.roleStatus == 'active'">
                    <!-- <div class="print"> -->
                    <div class="form-group">
                        <select class="browser-default custom-select" disabled id="qty" name="quantity" [(ngModel)]="quantity" (change)="changeQuantity($event.target.value)">
                        <option value="10" selected disabled> 10 Stickers, Size: (2"x4") </option>
                        <!-- <option value="30"> 30 Stickers, Size: (1"x2-5/8") </option> -->
                      </select>
                        <!-- <span class="form-control"> 1 Page ( 30 Car Labels ) </span> -->
                        <!-- <select class="browser-default custom-select form-control" id="qty" name="quantity" [(ngModel)]="quantity" class="form-control" (change)="changeQuantity($event.target.value)">
                        <option value="10"> 1 Page ( 10 QR Codes ) </option>
                        <option value="20"> 2 Page ( 20 QR Codes ) </option>
                        <option value="30"> 3 Page ( 30 QR Codes ) </option>
                        <option value="40"> 4 Page ( 40 QR Codes ) </option>
                        <option value="50"> 5 Page ( 50 QR Codes ) </option>
                   </select> -->
                    </div>
                    <div>
                        <button class="btn btn-sm btn-primary" (click)="addQrCodes(enumTemplates.Template_Tow)">Print one page</button>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                        <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.</li>
                    </ul>
                    <!-- <strong class="text-blue mb-2 pr-1" (click)="openLink(Print_QrCode_stickers)">
                    <a > Recommended Stickers  <i class="uil uil-external-link-alt uil-small "></i></a>
                  </strong> -->
                    <!-- <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                        <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.</li>
                    </ul> -->
                </div>
                <div class="notes">
                    <strong class="text-blue mb-2 pr-1" (click)="openLink(Print_QrCode_stickers)">
                    <a > Recommended Stickers  <i class="uil uil-external-link-alt uil-small "></i></a>
                  </strong>
                    <!-- <strong class="required pr-1 mb-2">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(tenLink)"> 10 Stickers per sheet, Size: (2"x4") <br>
                            <a>Avery Glossy Crystal Clear (10 Stickers per sheet) <i class="uil uil-external-link-alt uil-small "></i> </a>
                        </li>
                        <li (click)="openLink(thirtyLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Avery Glossy Crystal Clear (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 3</span>
                    <span class="tittle">Company name, Address, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [width]="175" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' "></qrcode>
                        <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                            <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                    </div>
                </div>
                <div class="third-side" *ngIf="qrCodeObj.itemStatus == 'Active' && qrCodeObj.roleStatus == 'active'">
                    <!-- <div class="print"> -->
                    <div class="form-group">

                        <select class="browser-default custom-select " disabled id="qty" name="quantity" [(ngModel)]="quantity" (change)="changeQuantity($event.target.value)">
                        <option value="10" selected disabled> 10 Stickers, Size: (2"x4") </option>
                        <!-- <option value="30"> 30 Stickers, Size: (1"x2-5/8") </option> -->
                      </select>
                        <!-- <span class="form-control"> 1 Page ( 30 Car Labels ) </span> -->
                        <!-- <select class="browser-default custom-select form-control" id="qty" name="quantity" [(ngModel)]="quantity" class="form-control" (change)="changeQuantity($event.target.value)">
                        <option value="10"> 1 Page ( 10 QR Codes ) </option>
                        <option value="20"> 2 Page ( 20 QR Codes ) </option>
                        <option value="30"> 3 Page ( 30 QR Codes ) </option>
                        <option value="40"> 4 Page ( 40 QR Codes ) </option>
                        <option value="50"> 5 Page ( 50 QR Codes ) </option>
                   </select> -->
                    </div>
                    <div>
                        <button class="btn btn-sm btn-primary" (click)="addQrCodes(enumTemplates.Template_Third)">Print one page</button>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                        <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.</li>
                    </ul>
                </div>
                <div class="notes">
                    <strong class="text-blue  pr-1" (click)="openLink(Print_QrCode_stickers)">
                    <a > Recommended Stickers  <i class="uil uil-external-link-alt uil-small "></i></a>
                  </strong>
                    <!-- <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(tenLink)"> 10 Stickers per sheet, Size: (2"x4") <br>
                            <a>Avery Glossy Crystal Clear (10 Stickers per sheet) <i class="uil uil-external-link-alt uil-small "></i> </a>
                        </li>
                        <li (click)="openLink(thirtyLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Avery Glossy Crystal Clear (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span>Template 4</span>
                    <span class="tittle">C. name, Address, P. Number, Website</span>
                </mat-expansion-panel-header>
                <div class="item">
                    <div class="first-side">
                        <qrcode [qrdata]="baseWordpressUrl" [width]="175" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' "></qrcode>
                        <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                    </div>
                    <div class="second-side">
                        <div class="info">
                            <p>{{companyInfo.companyName}}</p>
                            <p>{{companyInfo.companyAddress.addressOne}} <span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                            <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                            <p>{{companyInfo.companyPhone}} </p>
                            <p class="mb-0">{{companyInfo.companyWebsite}}</p>
                        </div>
                    </div>
                </div>
                <div class="third-side" *ngIf="qrCodeObj.itemStatus == 'Active' && qrCodeObj.roleStatus == 'active'">
                    <!-- <div class="print"> -->
                    <div class="form-group">
                        <select class="browser-default custom-select" disabled id="qty" name="quantity" [(ngModel)]="quantity" (change)="changeQuantity($event)">
                        <option value="10" selected disabled> 10 Stickers, Size: (2"x4") </option>
                        <!-- <option value="30"> 30 Stickers, Size: (1"x2-5/8") </option> -->
                      </select>
                        <!-- <span class="form-control"> 1 Page ( 30 Car Labels ) </span> -->
                    </div>
                    <div>
                        <button class="btn btn-sm btn-primary" (click)="addQrCodes(enumTemplates.Template_Four)">Print one page</button>
                        <!-- <button class="btn btn-sm btn-primary" (click)="qrPrint1Modal.show()">qrPrint1Modal</button> -->
                    </div>
                    <!-- </div> -->
                </div>
                <div class="notes">
                    <strong class="required pr-1">Important:</strong>
                    <ul>
                        <li>In the printing settings, make sure the paper size is [A4].</li>
                        <li>Stickers have reversed text and logo and must be stuck on the inside of the car windshield.</li>
                    </ul>
                </div>
                <div class="notes">
                    <strong class="text-blue  pr-1" (click)="openLink(Print_QrCode_stickers)">
                    <a > Recommended Stickers  <i class="uil uil-external-link-alt uil-small "></i></a>
                  </strong>
                    <!-- <strong class="required pr-1">Recommended Stickers:</strong>
                    <ul>
                        <li (click)="openLink(tenLink)"> 10 Stickers per sheet, Size: (2"x4") <br>
                            <a>Avery Glossy Crystal Clear (10 Stickers per sheet) <i class="uil uil-external-link-alt uil-small "></i> </a>
                        </li>
                        <li (click)="openLink(thirtyLink)">30 Stickers per sheet, Size: (1"x 2-5/8") <br>
                            <a>Avery Glossy Crystal Clear (30 Stickers per sheet) <i class="uil uil-external-link-alt uil-small"></i></a>
                        </li>
                    </ul> -->
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<!--  print QR -->
<div mdbModal #qrPrintModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Template</h5>
                    <button type="button" class="close" (click)="qrPrintModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
                </div>
                <div class="modal-body">
                    <!-- <div class="row" id="qr_codes_Id_30" *ngIf="quantity == 30">
                        <div class="col-4 " *ngFor="let item of qrCodes ">
                            <div class="full-item">
                                <div class="info ">
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                </div>
                                <div class="qr-img">
                                    <img src="{{companyInfo.companyLogo}}" alt="logo-img">
                                    <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row" id="qr_codes_Id_10" *ngIf="quantity == 10">
                        <div class="col-6 " *ngFor="let item of qrCodes ">
                            <div class="full-item">
                                <div class="info ">
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_One">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Tow">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Third">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="bufferTemplate == enumTemplates.Template_Four">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}<span *ngIf="companyInfo.companyAddress.addressTow != ''">, {{companyInfo.companyAddress.addressTow}}</span></p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <!-- <h2>{{countPrintBranches + item}}</h2> -->
                                </div>
                                <div class="qr-img">
                                    <!-- <img src="{{companyInfo.companyLogo}}" alt="logo-img"> -->
                                    <qrcode [qrdata]="getLinkQR(item)" [width]="200" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-sm btn-primary " (click)="qrPrintModal.hide();printFixed() ">Print</button>
              </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div id="test_print">
  test print
</div> -->
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='true'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>