import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FlipModule } from 'ngx-flip';
import { InviteUserComponent } from '../company-admin/pages/invite-user/invite-user.component';
import { CompanyUserRoutingModule } from './company-user-routing.module';
import { JobUserFilterPipe } from './pages/my-account/filters/Job-user-filter';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    MyAccountComponent,
    JobUserFilterPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    CompanyUserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    FlipModule
  ],
  exports: [
    CommonModule,
    SharedModule,
    CompanyUserRoutingModule,
    FormsModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    FlipModule,
    MatTabsModule
  ],
  providers:[
  ]
})
export class CompanyUserModule { }
