import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/_services/global/global.service';

@Component({
  selector: 'app-not-assigned-qr',
  templateUrl: './not-assigned-qr.component.html',
  styleUrls: ['./not-assigned-qr.component.scss']
})
export class NotAssignedQrComponent implements OnInit {

  constructor(
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
  }
  goBack(){
    this.globalService.goBack()
  }
}
