import { MyAccountComponent } from './pages/my-account/my-account.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/_helper/auth.guard.ts';
import { Role } from 'src/app/models/role/RoleModel';
import { UserLayoutComponent } from 'src/app/layout/pages/user-layout/pages/user-layout/user-layout.component';
import { jobByIdResolver } from 'src/app/core/_resolver/getJobById-resolver';
import { GetPersonByIdResolver } from 'src/app/core/_resolver/getPersonById-resolver';
import { InviteUserComponent } from '../company-admin/pages/invite-user/invite-user.component';
import { PermissionsComponent } from '../company-admin/pages/permissions/permissions.component';
import { GetToolsByCompanyIdResolver } from 'src/app/core/_resolver/getToolsByCompanyId-resolver';
import { JobDetailsComponent } from '../company-admin/pages/job-details/job-details.component';
import { SubmitTicketComponent } from '../company-admin/pages/submit-ticket/submit-ticket.component';
import { UsersListComponent } from '../company-admin/pages/users-list/users-list.component';
import { CustomersListComponent } from '../company-admin/pages/customers-list/customers-list.component';
import { UsersJobsResolver } from 'src/app/core/_resolver/super-admin-resolver/usersjobs-resolver';
import { getAllInventoryItemMixedResolver } from 'src/app/core/_resolver/getAllInventoryItemMixed-resolver';
import { GetAllInventoryItemMixedSummaryResolver } from 'src/app/core/_resolver/getAllInventoryItemMixedSummary-resolver';
import { ManageAccountsComponent } from '../company-admin/pages/manage-accounts/manage-accounts.component';
import { CustomerProfileComponent } from '../company-admin/pages/customer-profile/customer-profile.component';
import { GetAllUserCompaniesResolver } from 'src/app/core/_resolver/getAllUserCompanies-resolver';
import { UserProfileComponent } from '../company-admin/pages/user-profile/user-profile.component';
import { AddCustomerManuallyComponent } from '../company-admin/pages/add-customer-manually/add-customer-manually.component';
import { AddVehicleComponent } from '../company-admin/pages/car-wash-folder/add-vehicle/add-vehicle.component';
// import { AssignedVehicleQrComponent } from '../company-admin/pages/car-wash-folder/assigned-vehicle-qr/assigned-vehicle-qr.component';
import { CarWashTemplatesComponent } from '../company-admin/pages/car-wash-folder/car-wash-templates/car-wash-templates.component';
import { ViewVehicleComponent } from '../company-admin/pages/car-wash-folder/view-vehicle/view-vehicle.component';
import { VehiclesListComponent } from '../company-admin/pages/car-wash-folder/vehicles-list/vehicles-list.component';
import { CompleteMembershipComponent } from '../company-admin/pages/car-wash-folder/complete-membership/complete-membership.component';
import { ProductsStripComponent } from '../company-admin/pages/car-wash-folder/products-strip/products-strip.component';
import { AddVehicleCustomerComponent } from '../company-admin/pages/car-wash-folder/add-vehicle-customer/add-vehicle-customer.component';
import { ProductsCustomerComponent } from '../company-admin/pages/car-wash-folder/products-customer/products-customer.component';
import { QrCodeStickersComponent } from '../company-admin/pages/car-wash-folder/qr-code-stickers/qr-code-stickers/qr-code-stickers.component';
import { MyProfileComponent } from '../company-admin/pages/my-profile/my-profile.component';
import { CustomizeWebsiteComponent } from '../company-admin/pages/car-wash-folder/customize-website/customize-website.component';

const routes: Routes = [
  {
    path: '', component: UserLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Customer, Role.Crew, Role.TeamLeader, Role.ThirdParty] },
    children: [
      {
        path: '', component: MyAccountComponent,
        resolve: {
          'userJobs': UsersJobsResolver,
          'user': GetPersonByIdResolver
        },
      },
      { path: 'invite', component: InviteUserComponent },
      {
        path: 'job-details/:id', component: JobDetailsComponent,
        resolve:
        {
          'jobs': jobByIdResolver,
          'tools': GetToolsByCompanyIdResolver,
          // 'mixItemsSummary': GetAllInventoryItemMixedSummaryResolver,
        }
      },
      {
        path: 'customer-profile/:id', component: CustomerProfileComponent,
        resolve: {
          'customer': GetPersonByIdResolver
        }
      },
      {
        path: 'user-profile/:id', component: UserProfileComponent,
        resolve: { 'user': GetPersonByIdResolver }
      },
      {
        path: 'add-customer-manually', component: AddCustomerManuallyComponent
      },
      {
        path: 'permissions', component: PermissionsComponent,
        resolve: { 'tools': GetToolsByCompanyIdResolver }
      },
      {
        path: 'add-ticket', component: SubmitTicketComponent,
        resolve: { 'tools': GetToolsByCompanyIdResolver }
      },
      {
        path: 'users-list', component: UsersListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'customers-list', component: CustomersListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'my-profile', component: MyProfileComponent,
        resolve: {
          'user': GetPersonByIdResolver,
          'tools': GetToolsByCompanyIdResolver
        }
      },
      {
        path: 'manage-accounts', component: ManageAccountsComponent,
        resolve: { 'accounts': GetAllUserCompaniesResolver }
      },
      {
        path: 'vehicles-list', component: VehiclesListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'vehicles-list/:id', component: VehiclesListComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      { path: 'add-vehicle', component: AddVehicleComponent },
      // {path:'qr-scanner',component:QrScannerComponent},
      // {path:'add-assigned-vehicle/:id',component:AssignedVehicleQrComponent},
      {
        path: 'car-wash-template', component: CarWashTemplatesComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      {
        path: 'qr-code-stickers', component: QrCodeStickersComponent,
        resolve: {
          'tools': GetToolsByCompanyIdResolver,
        }
      },
      { path: 'view-vehicle/:id', component: ViewVehicleComponent },
      { path: 'complete-membership/:id', component: CompleteMembershipComponent },
      { path: 'products-strip/:id', component: ProductsStripComponent },
      { path: 'new-vehicle', component: AddVehicleCustomerComponent },
      { path: 'products/:id', component: ProductsCustomerComponent },
      { path: 'customize-website', component: CustomizeWebsiteComponent },
      // {
      //   path: 'qr-codes-template', component: QrCodeMovingComponent,
      //   resolve: {
      //     'tools': GetToolsByCompanyIdResolver,
      //   }
      // }
    ],
  },
  { path: '**', redirectTo: 'user', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyUserRoutingModule { }
