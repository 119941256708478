import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-qr-scan-capacitor',
  templateUrl: './qr-scan-capacitor.component.html',
  styleUrls: ['./qr-scan-capacitor.component.scss']
})
export class QrScanCapacitorComponent implements OnInit {

constructor(private router: Router) { }
user:any = {}
jobModel:any = {}
ngOnInit() {
  this.user = JSON.parse(localStorage.getItem('user'))
  this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
  this.startScan()
  this.setupListener()
}

// scan functions
async checkPermission() {
  try {
    const status = await BarcodeScanner.checkPermission({ force: true });
    console.log('checkPermission', status)
    if (status.granted) {
      // the user granted permission
      console.log('checkPermission granted', true)
      return true;
    }
    // check or request permission
    console.log('checkPermission granted', false)
    return false;
  } catch (e) {
    console.log('checkPermission exception')
    console.log(e)
  }
}
async startScan() {
  try {
    const permission = await this.checkPermission();
    if (!permission) {
      console.log('error before start scan')
      return
    }
    console.log(' before hideBackground')
    await BarcodeScanner.hideBackground();
    console.log(' aftar  hideBackground')
    document.querySelector('body').classList.add('scanner-active');
    document.getElementById("app_Id").style.visibility = 'hidden'
    console.log('scanner-active class addedd')
    const result = await BarcodeScanner.startScan();
    console.log('startScan result', result)
    BarcodeScanner.showBackground();
    document.querySelector('body').classList.remove('scanner-active');
    document.getElementById("app_Id").style.visibility = 'visible'
    if (result?.hasContent) {
      // this.scannedResult = result.content
      this.router.navigate(['/admin/customers-list'])
      console.log(result.content);
    }
  } catch (e) {
    console.log('catch start scan ', e)
    this.stopScan()
  }
}
stopScan() {
  BarcodeScanner.showBackground()
  BarcodeScanner.stopScan()
  document.querySelector('body').classList.remove('scanner-active');
  document.getElementById("app_Id").style.visibility = 'visible'
}
async setupListener() {
  App.addListener('backButton', (data) => {
     (this.user.role == 'admin' || this.user.role == 'manager') ?
       this.router.navigate(['/admin/job-details',this.jobModel.jobId]) :
       this.router.navigate(['/user/job-details',this.jobModel.jobId]);
  });
}
ngOnDestroy(): void {
  this.stopScan()
}
}
