import { Container } from './../../../models/container/Container';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { OnlineOfflineServiceService } from '../onlineoffline/onlineofflineService.service';

@Injectable({
  providedIn: 'root'
})
export class ContainerService {
  baseUrl = environment.baseUrl
  idItem: any
  constructor(private http: HttpClient,
    public onlineOfflineService: OnlineOfflineServiceService

  ) {
    this.idItem = ToolJobsEditItem.containers
  }

  // getContainers() {
  //   return this.http.get(this.baseUrl + 'containers' + '/' + this.idItem);
  // }
  getContainerById(id: any, jobId: string) {
    return this.http.get(this.baseUrl + 'container/' + id + '/' + jobId + '/' + this.idItem);
  }
  getAllContainersByJob(id: any) {
    return this.http.get(this.baseUrl + 'containers/' + id + '/' + this.idItem);
  }
  getAllContainersByCompany() {
    return this.http.get(this.baseUrl + 'containers/' + this.idItem);
  }
  getAllContainersByCompanyId(companyId: string) {
    return this.http.get(this.baseUrl + 'containersCompanyId/' + companyId + '/' + this.idItem);
  }
  getAllContainersNotInJob(jobId: string) {
    return this.http.get(this.baseUrl + 'containersNotInJob/' + jobId + '/' + this.idItem);
  }
  getAllInventoryItemsByContainer(id: string) {
    return this.http.get(this.baseUrl + 'containerItems/' + id + '/' + this.idItem);
  }
  getAllInventoryItemsByContainerInJob(id: string, jobId: string) {
    return this.http.get(this.baseUrl + 'containerItemsInJob/' + id + '/' + jobId + '/' + this.idItem);
  }
  getAllInventoryItemsForPrintByContainer(id: string) {
    return this.http.get(this.baseUrl + 'inventoryContainerPrint/' + id);
  }
  getContainerItemsLengthInJob(containerId: string, jobId: string) {
    return this.http.get(this.baseUrl + 'containerItemsLengthInJob/' + containerId + '/' + jobId + '/' + this.idItem);
  }
  addContainer(container: Container, jobId: string) {
    // return this.http.post(this.baseUrl + 'container' + '/' + this.idItem, container);
    if (!this.onlineOfflineService.isOnline) {
      this.onlineOfflineService.addToIndexedDb(container, 'container');
    }
    else {
      return this.http.post(this.baseUrl + 'container/' + jobId + '/' + this.idItem, container);
    }
  }
  addContainerToMap(container: Container) {
    return this.http.post(this.baseUrl + 'containerInMap/' + this.idItem, container);
  }
  updateContainer(newContainer: Container, id: string, jobId: string) {
    return this.http.put(this.baseUrl + 'container/' + jobId + '/' + id + '/' + this.idItem, newContainer);
  }
  updateContainerJob(containerId: string, jobId: string) {
    return this.http.put(this.baseUrl + 'containerJob/' + jobId + '/' + containerId + '/' + this.idItem, { jobId: jobId });
  }
  updateContainerAnotherJob(containerId: string, jobId: string) {
    return this.http.put(this.baseUrl + 'containerNotAssignJob/' + jobId + '/' + containerId + '/' + this.idItem, { jobId: jobId });
  }
  deleteContainer(containerId: string, jobId: string) {
    return this.http.delete(this.baseUrl + 'container/' + jobId + '/' + containerId + '/' + this.idItem);
  }
}
