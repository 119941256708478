<div class="card">
    <div class="tittle-section">
        <p>Add a Vehicle</p>
    </div>
    <div class="section-stepper">
        <form [formGroup]="addVehicleFormGroup">
            <mat-vertical-stepper [linear]="isLinear" #stepper>
                <mat-step state="vehicle">
                    <ng-template matStepLabel>
                        Vehicle Information
                        <i class="required italic-required">(required)</i>
                    </ng-template>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Vehicle Make</mat-label>
                        <input type="text" required matInput formControlName="vehicleType" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let car of filteredOptions | async" [value]="car.Make" (click)="chooseCar(car)">
                                {{car.Make}} / {{car.Model}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                        <div *ngIf="f.vehicleType.errors.required">Vehicle Make is required</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Plate / #VIN( Last 5 Digits)</mat-label>
                            <input matInput required type="text" class="text-uppercase" formControlName="plateNumber" (keyup)="isPlateNumberExist()">
                        </mat-form-field>
                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                            <div *ngIf="f.plateNumber.errors.pattern">plate number only characters & numbers</div>
                        </div>
                        <div *ngIf="isExists" class="alert alert-danger" role="alert">Plate Number is Exists.</div>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Car Model</mat-label>
                            <input matInput type="text" formControlName="vehicleModel">
                        </mat-form-field>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input matInput type="number" formControlName="phone">
                        </mat-form-field>
                    </div> -->
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <ngx-mat-intl-tel-input formControlName="phone" [preferredCountries]="countrySelectedArr" [enablePlaceholder]="true" [enableSearch]="true" name="phone" (countryChanged)="yourComponentMethodToTreatyCountryChangedEvent($event)">
                            </ngx-mat-intl-tel-input>
                        </mat-form-field>
                        <mat-error *ngIf="f.phone?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </div>
                    <!-- <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Car Condition</mat-label>
                            <textarea rows="1" cols="26" matInput type="text" formControlName="condition"> </textarea>
                        </mat-form-field>
                    </div> -->
                    <button matStepperNext type="button" class="btn btn-sm btn-success" (click)="addVehicle()" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid  || vehicle.id || isExists || f.phone?.errors?.validatePhoneNumber">Create a Car Profile</button>
                </mat-step>
                <mat-step state="membership">
                    <div class="form-group">
                        <!-- <div class="check-section">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Card" (change)="handleChange($event)" id="flexRadioDefault2" checked/>
                                <label class="form-check-label" for="flexRadioDefault2">Card </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="Cash" (change)="handleChange($event)" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Cash </label>
                            </div>
                        </div> -->
                        <span class="no-vehicle" *ngIf="!vehicle.id">First, you MUST add vehicle information.</span>
                        <!-- cash Payment -->
                        <!-- <div class="cash-month" *ngIf="vehicle.id">
                            <span class="pr-2">Expires aftar </span>
                            <select class="browser-default custom-select form-control" *ngIf="paymentType == paymentTypeEnum.card" (change)="chooseCountMonth($event.target.value)">
                              <option *ngFor="let month of monthsCard" value="{{month}}"> {{month}} </option>
                             </select>
                            <span class="pl-2"> Months</span>
                        </div> -->
                    </div>
                    <ng-template matStepLabel>Membership <i class="italic-optional">(Optional)</i></ng-template>
                    <div>
                        <button class="btn btn-sm btn-primary" *ngIf="paymentType == paymentTypeEnum.card" [disabled]="!vehicle.id || f.phone?.errors?.validatePhoneNumber" (click)="createMemberShipCash(vehicle.id)">Create Membership <i class="uil uil-external-link-alt uil-small pl-1"></i></button>
                    </div>
                </mat-step>
                <ng-template matStepperIcon="scan">
                    <mat-icon>camera_alt</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="vehicle">
                    <mat-icon> directions_car</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="person">
                    <mat-icon>person</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="membership">
                    <mat-icon>card_membership</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <!-- <ng-template matStepperIcon="done">
                    <mat-icon>forum</mat-icon>
                </ng-template> -->
            </mat-vertical-stepper>
        </form>
    </div>
</div>
<div mdbModal #warningKeyStripModal="mdbModal" class="modal warning">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Required</h5>
                    <button type="button" class="close" (click)="warningKeyStripModal.hide()" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-left">
                    <p>Ensure that the payment gate is integrated into the company account: <br> Accordev Home Page> Menu > Company account> Stripe integration.</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="warningKeyStripModal.hide() ">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
