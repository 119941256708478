import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { CarWashService } from './../../../../../core/_services/car-wash-service/car-wash.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { PaymentType } from '../Enum/PaymentType';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import { Capacitor } from '@capacitor/core';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { HistoryCarWashNames } from '../Enum/HistoryCarWashNames';
import { ActionsCar } from '../Enum/ActionsCar';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { GlobalService } from 'src/app/core/_services/global/global.service';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BaseConfig } from 'ngx-scanner-qrcode';
import { CompanyPlan } from 'src/app/models/company/company';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SMSNotification } from 'src/app/models/tools-model/SmsNotification';
import { QueueHistory } from '../Enum/QueueHistory';
import { AssignType } from '../Enum/AssignType';

@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss']
})
export class VehiclesListComponent implements OnInit {
  @ViewChild('editVehicleModal') editVehicleModal: ModalDirective;
  @ViewChild('searchVehicleModal') searchVehicleModal: ModalDirective;
  @ViewChild('serviceStatisticModal') serviceStatisticModal: ModalDirective;
  @ViewChild('printScanMobile') printScanMobile: ModalDirective;
  @ViewChild('deleteVehicleModal') deleteVehicleModal: ModalDirective;
  @ViewChild('reNewMembershipModal') reNewMembershipModal: ModalDirective;
  @ViewChild('deniedModal') deniedModal: ModalDirective;
  @ViewChild('inviteModal') inviteModal: ModalDirective;
  @ViewChild('scanWebModal') scanWebModal: ModalDirective;
  @ViewChild('stripKeyModal') stripKeyModal: ModalDirective;
  @ViewChild('cameraScanModal') cameraScanModal: ModalDirective;
  @ViewChild('notSendMessageModal') notSendMessageModal: ModalDirective;
  @ViewChild('sendMessageModal') sendMessageModal: ModalDirective;
  @ViewChild('noPhoneModal') noPhoneModal: ModalDirective;
  @ViewChild('assignModal') assignModal: ModalDirective;
  baseDomain = environment.baseDomain
  // baseUrl = environment.baseUrl
  itemsPerPage = 6
  user: any = {}
  vehicles: any[] = []
  vehiclesQueue: any[] = []
  membersVehicles: any = []
  otherVehicles: any = []
  searchText = ''
  searchText1 = ''
  searchText2 = ''
  searchText3 = ''
  searchText4 = ''
  p: number = 1
  p1: number = 1
  p2: number = 1
  p3: number = 1
  p4: number = 1
  p5: number = 1
  p6: number = 1
  isLinear = false;
  vehicleForm: FormGroup
  step;
  vehicleId: string
  customerId: string
  dateObj: any
  date = moment();
  weekno = moment(this.date).isoWeek();
  days: any = [];
  dateItemPicker: any = ''
  timeItemPicker: any = ''
  bsConfig: Partial<BsDatepickerConfig>;
  yearStatistic: any
  yearStatistic1: any
  tools: any[] = []
  othersTools: any = {}
  carWashObj: any = {}
  customerObj: any = {}
  vehicle: any = {}
  statisticYearVehicles: any = {}
  datesByToday: any[] = []
  vehiclesByDates: any = []
  vehiclesByDate: any = []
  selected: any = {}
  vehiclesStaticsYear: any = {}
  paymentTypeEnum = PaymentType
  countMemberShip = 0
  buffVehicle:any = {}
  isDenied = true
  histories: any = []
  queueHistories: any = []
  historyCarWash = HistoryCarWash
  queueHistory = QueueHistory
  historyCarWashNames = HistoryCarWashNames
  actionsCar = ActionsCar
  carsList: any = []
  inviteLink = ''
  isCopied = false
  emailCustomer = ''
  emailPattern = ValidationModel.emailPattern
  companyPlan = CompanyPlan
  quantity = 10
  qrCodes: any
  companyInfo: any = {}
  isHaveProducts  = false
  // scan qr code variable
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;
  public config: BaseConfig = {
    text: { font: '25px serif' }, // Hiden { font: '0px', bottom: 40 },
    frame: { lineWidth: 4 },
  };
  @ViewChild('action') action: any;
  bufferVehicles :any =[]
  //  Variables to open camera
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: any = null;
  public showWebcam = false;
  public errors: WebcamInitError[] = [];
  token_platerecognizer = environment.token_platerecognizer
  Api_vehicle = environment.Api_vehicle
  isMobile = false
  smsNotification = SMSNotification
  smsType :any
  currentCompany :any = {}
  isPrint = true
  toppings = new FormControl();
  toppingList = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  selectedToppings;
  carWashServices:any=[]
  additionalServices:any=[]
  usersToAssigned:any = []
  selectedPackageIndex
  selectedPackageIndex1
  normalServiceObj:any = {}
  otherServiceObj:any = {}
  servicesSelected :any = []
  servicesOthersSelected :any = []
  totalDuNormal: number = 0
  totalDueOthers: number = 0
  receivedAmount = 0
  isDisabled: boolean = false;
  selectedUsers :any =[]
  servicesObj:any ={}
  bufferVehicle: any = {};
  assignType = AssignType
  constructor(
    private router: Router,
    private _carWashService: CarWashService,
    private _formBuilder: FormBuilder,
    public datePipe: DatePipe,
    private route: ActivatedRoute,
    private _personService: PersonService,
    private _toolsService: ToolsService,
    private socket: Socket,
    private _toaster: ToasterCustomService,
    public http: HttpClient,
    private _globalService:GlobalService,
    private _companiesService:CompaniesService,
    private deviceService: DeviceDetectorService,
  ) { }
  ngOnInit() {
     (Capacitor.isNativePlatform() ||  !this.deviceService.isDesktop())? this.isMobile = true : this.isMobile = false
    this.qrCodes = new Array(this.quantity);
    this.getData()
    this.yearStatistic = this.datePipe.transform(new Date(), 'y')
    this.yearStatistic1 = this.datePipe.transform(new Date(), 'y')
    this.dateObj = new Date();
    this.bsConfig = {
      containerClass: 'theme-blue',
      showClearButton: true,
      clearPosition: 'left',
      showTodayButton: true,
      todayPosition: 'center',
      adaptivePosition: true,
    };
    this.getweeks(this.date);
    this.user = JSON.parse(localStorage.getItem('user'))
    this.currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.createAddVehicleForm()
    // this.getVehicles()
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.tools = response['tools'].data
        this.othersTools = this.tools.find(t => t.toolType == ToolType.Setting_Tools)
        if (this.user.role == 'admin' || this.user.role == 'manager' || this.user.role == 'teamleader' || this.user.role == 'crew') {
          this._personService.getPersonById(this.user.id).subscribe((response: ResponseModel) => {
            if (response.success) {
              let permissionList = (response.data.permissionList == null || response.data.permissionList == undefined) ? [] : response.data.permissionList;
              this.carWashObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.Car_Wash_Vehicle, ToolType.Setting_Tools, permissionList)
              this.customerObj = this._toolsService.getOverridePermission(this.user.role, this.othersTools, ToolSettingEditItem.Customers_list, ToolType.Setting_Tools, permissionList)
              // console.log(this.carWashObj)
              if (this.user.role == 'admin' || this.carWashObj.itemStatus == "Active" && this.carWashObj.roleStatus == "active" || this.carWashObj.itemStatus == "Active" && this.carWashObj.roleStatus == "readOnly") {
                this.getVehicles()
              }
            }
          })
        }
      }
    }, error => {
      console.log(error)
    })
    this.getVehiclesByDate(this.dateObj)
    //  Vehicle_Check_In Check_Out and Subscription Realtime
    console.log('test socket',this.socket)
    this.socket.on(RealTimeKeys.Vehicle_Check_In, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this.getOthersAndMembersVehiclesRealTime()
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index =  this.vehicles.findIndex(c => c.id == data.id)
            // let indexMember =  this.membersVehicles.findIndex(c => c.id == data.id)
            // let indexOthers =  this.otherVehicles.findIndex(c => c.id == data.id)
            // let indexDates =  this.vehiclesByDate.findIndex(c => c.id == data.id)
            if(index != -1){
            this.vehicles[index] = response.data
            this.vehiclesQueue.push(response.data)
            }
            // if(indexMember != -1){
            // this.membersVehicles[indexMember] = response.data
            // }
            // if(indexOthers != -1){
            // this.otherVehicles[indexOthers] = response.data
            // }
            // if( indexDates!= -1){
            //   this.vehiclesByDate[indexDates] = response.data
            //   }
        }
        })
      }
    })
    this.socket.on(RealTimeKeys.Vehicle_Check_Out, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this.getOthersAndMembersVehiclesRealTime()
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index =  this.vehicles.findIndex(c => c.id == data.id)
            let indexMember =  this.vehiclesQueue.findIndex(c => c.id == data.id)
            // let indexOthers =  this.otherVehicles.findIndex(c => c.id == data.id)
            // let indexDates =  this.vehiclesByDate.findIndex(c => c.id == data.id)
            if(index != -1){
            this.vehicles[index] = response.data
            }
            if(indexMember != -1){
              this.vehiclesQueue.splice(indexMember,1)
                        }
            // if(indexOthers != -1){
            // this.otherVehicles[indexOthers] = response.data
            // }
            // if( indexDates!= -1){
            //   this.vehiclesByDate[indexDates] = response.data
            //   }
        }
        })
      }
    })
    this.socket.on(RealTimeKeys.Vehicle_Un_Check_In, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index =  this.vehicles.findIndex(c => c.id == data.id)
            let indexMember =  this.vehiclesQueue.findIndex(c => c.id == data.id)
            // let indexOthers =  this.otherVehicles.findIndex(c => c.id == data.id)
            // let indexDates =  this.vehiclesByDate.findIndex(c => c.id == data.id)
            if(index != -1){
            this.vehicles[index] = response.data
            }
            if(indexMember != -1){
              this.vehiclesQueue.splice(indexMember, 1);
            }
            // if(indexOthers != -1){
            // this.otherVehicles[indexOthers] = response.data
            // }
            // if( indexDates!= -1){
            //   this.vehiclesByDate[indexDates] = response.data
            //   }
        }
        })
      }
    })
    this.socket.on(RealTimeKeys.Vehicle_Change_Subscription, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index = this.vehicles.findIndex(c => c.id == data.id)
            let indexMember = this.membersVehicles.findIndex(c => c.id == data.id)
            let indexOthers = this.otherVehicles.findIndex(c => c.id == data.id)
            let indexDates = this.vehiclesByDate.findIndex(c => c.id == data.id)
            if (index != -1) {
              this.vehicles[index] = response.data
            }
            if (indexMember != -1) {
              this.membersVehicles[indexMember] = response.data
            }
            if (indexOthers != -1) {
              this.otherVehicles[indexOthers] = response.data
            }
            if (indexDates != -1) {
              this.vehiclesByDate[indexDates] = response.data
            }
          }
        })
      }
    })
    this.socket.on(RealTimeKeys.Vehicle_Add, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index = this.vehicles.findIndex(c => c.id == data.id)
            if (index == -1) {
              this.vehicles.unshift(response.data)
            }
          }
        })
      }
    })
    this.socket.on(RealTimeKeys.Vehicle_Delete, (data: any) => {
      if (this.user.companyId == data.companyId) {
        let index = this.vehicles.findIndex(c => c.id == data.id)
        if (index > -1) {
          this.vehicles.splice(index, 1);
        }
      }
    });
    this.socket.on(RealTimeKeys.Vehicle_Update, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index = this.vehicles.findIndex(c => c.id == data.id)
            if (index != -1) {
              this.vehicles[index] = response.data
            }
          }
        })
      }
    });
    this.socket.on(RealTimeKeys.Service_Assigned, (data: any) => {
      if (this.user.companyId == data.companyId) {
        this._carWashService.getVehicleById(data.id).subscribe((response: ResponseModel) => {
          if (response.success) {
            let index =  this.vehicles.findIndex(c => c.id == data.id)
            let indexQueue =  this.vehiclesQueue.findIndex(c => c.id == data.id)
            let indexOthers =  this.otherVehicles.findIndex(c => c.id == data.id)
            let indexMember =  this.membersVehicles.findIndex(c => c.id == data.id)
            let indexDates =  this.vehiclesByDate.findIndex(c => c.id == data.id)
            if(index != -1){
            this.vehicles[index] = response.data
            }
            if(indexQueue != -1){
              this.vehiclesQueue[indexQueue] = response.data
            }
            if(indexOthers != -1){
            this.otherVehicles[indexOthers] = response.data
            }
            if(indexMember != -1){
              this.membersVehicles[indexMember] = response.data
              }
            if( indexDates!= -1){
              this.vehiclesByDate[indexDates] = response.data
           }}
        })
      }
    })
    // this.getVehiclesListLive()
    this.getInviteLink()
    this.getCompanyAddress()
    // this.getServices()
  }
  getServices(vehicle:any){
    this.buffVehicle = vehicle
    this._carWashService.getServices(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.carWashServices = response.data.normalServices
        // this.normalServiceObj  = this.additionalServices[0]
        this.additionalServices = response.data.otherServices
         this._personService.getUsersByCompany(this.user.companyId).subscribe((response:ResponseModel)=>{
          if(response.success){
            response.data = response.data.filter(r =>r.role != 'admin')
            this.usersToAssigned = response.data.map(s => ({name: s.name,id:s.id}))
            console.log(this.usersToAssigned)
            if(this.buffVehicle.CheckInObject.length > 0 && this.buffVehicle.CheckInObject[0].servicesObj){
              let  servicesObj = JSON.parse(JSON.stringify(this.buffVehicle.CheckInObject[0].servicesObj))
              this.servicesSelected = servicesObj.servicesNormalSelected
              console.log(this.servicesSelected)
              this.servicesOthersSelected = servicesObj.servicesOthersSelected
              this.selectedUsers = servicesObj.usersSelected
              this.receivedAmount = servicesObj.receivedAmount
              this.totalDuNormal = servicesObj.totalDu
              this.totalDueOthers = servicesObj.totalDueOthers
            }
            this.assignModal.show()
            console.log(this.selectedUsers)
          }
        })
      }
    })
  }
  getUsersViewByCompany() {

  }
  resetServices(){
    this.servicesSelected = []
    this.carWashServices = []
    this.additionalServices = []
    this.usersToAssigned = []
    this.servicesOthersSelected =[]
    this.selectedUsers =[]
    this.receivedAmount = 0
    this.totalDuNormal = 0
    this.totalDueOthers = 0
    this.isDisabled = true
  }
  equalsUsers(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.id === objTwo.id;
    }
  }
  selectedNormalService(event,item:any,service,i){
    let index = this.servicesSelected.findIndex(s =>s.id == service.id)
    if(index != -1 ){
    this.servicesSelected[index].price = Number(item['price'+i])
    this.servicesSelected[index].size =  item['size'+i]
    // this.totalDuNormal  += this.servicesSelected[index].price
    }
    else{
      // let event ={target:{checked:true}
      // }
      this.servicesSelected.push({
        id:service.id,
        serviceName: service.serviceName,
        time: service.time,
        date: service.date,
        price: Number(item['price'+i]),
        size: item['size'+i] ,
        isActive: service.isActive,
        isShowJob: service.isShowJob,
      })
      this.isChecked(service)
      // this.handleChange(event,service,i)
    }
    console.log(this.servicesSelected)
    this.getSummary()
  }
  selectedOtherService(event,service:any){
    console.log(service)
    if(event.target.checked){
      this.servicesOthersSelected.push({
        id:service.id,
        serviceName: service.serviceName,
        price: service.price,
        time: service.time,
        date: service.date,
        isActive: service.isActive,
        isShowJob: service.isShowJob,
      })
      this.totalDueOthers  += service.price
      this.totalDueOthers > 0 ? this.totalDueOthers  : 0
    }
    else{
      let index = this.servicesOthersSelected.findIndex(s =>s.id == service.id)
      this.servicesOthersSelected.splice(index,1)
      this.totalDueOthers  -= service.price
      this.totalDueOthers > 0 ? this.totalDueOthers  : 0
    }
    console.log(this.servicesOthersSelected)
  }
  handleChange(event,service){
    console.log(service)
    if(event.target.checked){
      this.servicesSelected.push({
        id:service.id,
        serviceName: service.serviceName,
        time: service.time,
        date: service.date,
        price: 0,
        size: '' ,
        isActive: service.isActive,
        isShowJob: service.isShowJob,
      })
      // this.totalDuNormal  += 0
    }
    else{
      let index = this.servicesSelected.findIndex(s =>s.serviceName == service.serviceName)
      // this.totalDuNormal  -= this.servicesSelected[index].price
      this.servicesSelected.splice(index,1)
    }
    console.log(this.servicesSelected)
    this.getSummary()
    this.isCheckedSize(service)
  }
  getSummary(){
    let sum: number = 0;
    if(this.servicesSelected.length > 0) {
      this.servicesSelected.forEach(a => sum += a.price);
      this.totalDuNormal = sum > 0  ? sum : 0
    }
    else{
      this.totalDuNormal = 0
    }
    // let results = this.servicesSelected.concat(this.servicesOthersSelected);
    // console.log(results)
  }
  isChecked(service:any){
    let isChecked = false
    if(this.servicesSelected.length> 0){
      let item = this.servicesSelected.find(s => s.id == service.id )
      item ? isChecked = true : isChecked = false
    }
    return isChecked
  }
  isCheckedOther(service){
    let isChecked = false
    if(this.servicesOthersSelected.length> 0){
      let item = this.servicesOthersSelected.find(s => s.id == service.id)
      item ? isChecked = true : isChecked = false
    }
    return isChecked
  }
  assignedService(){
    let model = {
      servicesNormalSelected: this.servicesSelected,
      servicesOthersSelected: this.servicesOthersSelected,
      usersSelected:this.selectedUsers,
      totalDu: this.totalDuNormal ,
      receivedAmount: this.receivedAmount,
      cashBack:this.getCashBack(),
      totalDueOthers:this.totalDueOthers,
      date: new Date()
     }
    this._carWashService.assignedService(this.buffVehicle.id,model).subscribe((response:ResponseModel)=>{
      if(response.success){
        console.log(response)
        this.resetServices()
      }
    })
  }
  getUniqId(i:number,j:number)
  {
    return `${j}${i}`
  }
  isCheckedSize(service:any){
    let size = ''
    if(this.servicesSelected.length> 0){
      let itemBuff = this.servicesSelected.find(s => s.id == service.id)
      if(itemBuff){
        size =  itemBuff.size
      }
    }
    return size
  }
  isCheckedSizePrice(service:any){
    let itemBuff
    if(this.servicesSelected.length> 0){
       itemBuff = this.servicesSelected.find(s => s.id == service.id)
    }
    return itemBuff
  }
  checkServiceName(service){
    if(this.servicesSelected.length> 0){
    let itemBuff = this.servicesSelected.find(s => s.id == service.id)
      if(itemBuff){
        if(itemBuff.serviceName != service.serviceName){
          return itemBuff.serviceName
        }
      }
    }
  }
  changeReceivedAmount(event){
    this.receivedAmount =  Number(event)
  }
  getCashBack(){
    (this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)) < 0 ? this.isDisabled = true : this.isDisabled = false
    return this.receivedAmount - (this.totalDuNormal + this.totalDueOthers)
  }
  // getCashBackView(others,normal,received) {
  //   return received - (normal + others)
  // }
  selectItemPackage(i) {
    this.selectedPackageIndex = i;
  }
  selectItemPackage1(i) {
    this.selectedPackageIndex1 = i;
  }
  getInviteLink() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.inviteLink = `${this.baseDomain}invite-customer/${this.user.companyNumber}-${this.user.signUpDateTimeStamp}`
  }
  getCompanyAddress() {
    let company = this.user.companyData.find(c => c.companyId == this.user.companyId)
    this.companyInfo = {
      companyName: company.companyName,
      companyPhone: this.user.companyPhone,
      companyWebsite: this.user.companyWebsite,
      companyAddress: company.companyAddress,
      companyLogo: company.companyLogo,
    }
  }
  copyLink() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
  getData() {
    return this.http.get('/assets/cars_list.json').subscribe((response: any) => {
      // console.log(response)
      this.carsList = response
    });
  }
  // getVehiclesListLive()
  // {
  //  is._carWashService.getVehiclesByCompanyForRemoveLive().subscribe((res:ResponseModel)=>{
  //     console.log('from live',res.data)
  //     this.vehicles = res.data
  //   })
  // }
  InviteUser() {
    this.emailCustomer = this.getEmailWithoutSpace(this.emailCustomer)
    let model: any = {}
    model.type = 'customer'
    model.role = 'customer'
    model.date = new Date()
    model.dateFormate = this.datePipe.transform(new Date(), 'yyyy-d-M')
    model.timeFormate = this.datePipe.transform(new Date(), 'h:mm a')
    model.email = this.getEmailWithoutSpace(this.emailCustomer)
    model.companyNumber = Number(this.user.companyNumber)
    model.signUpDateTimeStamp = Number(this.user.signUpDateTimeStamp)
    this._personService.InviteCustomerPendingGlobal(model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toaster.success('Please check your email inbox or the spam box', 'Successfully Invitation')
        this.inviteModal.hide()
      }
      if (!response.success) {
        this._toaster.error(`${model.email} E-mail already exists, please enter another Email.`, '')
      }
    }, error => {
      console.log(error)
    })
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  createAddVehicleForm() {
    this.vehicleForm = this._formBuilder.group({
      vehicleType: ['', Validators.required],
      plateNumber: ['', [Validators.required]],
      vehicleModel: [''],
      // color: [''],
      condition: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }
  get f() {
    return this.vehicleForm.controls;
  }
  getVehicles() {
    this._carWashService.getVehiclesByCompanies(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (response.data == null) {
          this.vehicles = []
        } else {
          this.vehicles = response.data.vehicles
          this.vehiclesQueue = response.data.queueVehicle
          this.bufferVehicles = response.data
          // this.vehiclesQueue = this.vehiclesQueue.sort((a, b) => (a.QueueObj.number > b.QueueObj.number ? -1 : 1));
          this.route.queryParams.subscribe(params => {
            if (params['qrCodeNumber'] != undefined && params['qrCodeNumber'] != null) {
              this.vehicles = this.vehicles.filter(v => v.qrCodeNumber ==  Number(params['qrCodeNumber']))
              this.searchText = params['qrCodeNumber'];
              this.step = 0
              this.p = 1
              this.setStep(this.step)
            }
            else {
              localStorage.setItem('search', 'false')
            }
          });
          this.countMemberShip = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == true && !v.isSubscriptionEnd).length
        }
        // console.log('this.vehicles', this.vehicles)
        // this.membersVehicles = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == true && this.isEqualDay(v.date))
        // this.otherVehicles = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == false || !v.isMemberShip && this.isEqualDay(v.date))
        // console.log('membersVehicles', this.membersVehicles)
        // console.log('otherVehicles', this.otherVehicles)
      }
    })
  }
  getQueueVehicles() {
    this._carWashService.getVehiclesByCompanies(this.user.companyId).subscribe((response: ResponseModel) => {
      if (response.success) {
        if (response.data == null) {
          this.vehicles = []
        } else {
          this.vehicles = response.data.vehicles
          this.vehiclesQueue = response.data.queueVehicle
          console.log('vehiclesQueue', this.vehiclesQueue)
          // this.bufferVehicles = response.data
        }
        // console.log('this.vehicles', this.vehicles)
        // this.membersVehicles = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == true && this.isEqualDay(v.date))
        // this.otherVehicles = this.vehicles.filter(v => v.isMemberShip && v.isMemberShip == false || !v.isMemberShip && this.isEqualDay(v.date))
        // console.log('membersVehicles', this.membersVehicles)
        // console.log('otherVehicles', this.otherVehicles)
      }
    })
  }
  getOthersAndMembersVehicles() {
    this.step = ''
    // if( this.membersVehicles.length <= 0 || this.otherVehicles.length <= 0){/
    let dateJob = this.datePipe.transform(new Date(), 'yyyy-M-dd')
    this._carWashService.getVehiclesByDate(this.user.companyId, dateJob, false).subscribe((response: ResponseModel) => {
      let allVehiclesInToday = response.data
      this.membersVehicles = allVehiclesInToday.filter(v => v.isMemberShip && v.isMemberShip == true)
      this.otherVehicles = allVehiclesInToday.filter(v => v.isMemberShip && v.isMemberShip == false || !v.isMemberShip)
    })
  }
  getOthersAndMembersVehiclesRealTime() {
    let dateJob = this.datePipe.transform(new Date(), 'yyyy-M-dd')
    this._carWashService.getVehiclesByDate(this.user.companyId, dateJob, false).subscribe((response: ResponseModel) => {
      let allVehiclesInToday = response.data
      this.membersVehicles = allVehiclesInToday.filter(v => v.isMemberShip && v.isMemberShip == true)
      this.otherVehicles = allVehiclesInToday.filter(v => v.isMemberShip && v.isMemberShip == false || !v.isMemberShip)
    })
  }
  isEqualDay(date: any) {
    let date1 = new Date(date)
    let date2 = new Date()
    return date1.getDay() == date2.getDay()
  }
  redirection() {
    localStorage.removeItem('renewMembership')
  localStorage.setItem('isDetailing','false')
    if (this.user.role == 'admin' || this.user.role == 'manager') {
      this.router.navigate(['/admin/add-vehicle'])
    }
    else {
      this.router.navigate(['/user/add-vehicle'])
    }
  }
  storeHistories(vehicle: any) {
    this.p4 = 1
    if (!vehicle.histories) {
      this.histories = []
    }
    else {
      this.histories = vehicle.histories.filter(element => element.key != this.historyCarWash.Check_In_Action && element.key != this.historyCarWash.Check_Out_Action && element.key != this.assignType.Assign &&  element.key != this.assignType.UnAssign)
    }
  }
  storeQueueHistories(vehicle: any) {
    this.p6 = 1
    if (!vehicle.queueHistories) {
      this.queueHistories = []
    }
    else {
      this.queueHistories = vehicle.queueHistories
    }
  }
  restPagination(event) {
    this.p = 1
    this.step = ''
    if(this.vehicles.length == 1){
      this.vehicles = this.bufferVehicles
    }
  }
  restPaginationQueue(event) {
    this.p5 = 1
  }
  restPagination1(event) {
    this.p1 = 1
  }
  restPagination2(event) {
    this.p2 = 1
  }
  storeVehicle(vehicle: any, type: any,index:any) {
    let url = ''
    if (type == undefined || type == null) {
      type = this.paymentTypeEnum.card
    }
    localStorage.setItem('type',String(type))
    localStorage.setItem('selectedIndex',String(index))
    if (this.user.role == 'admin' || this.user.role == 'manager') {
      // url  = `${this.baseUrl}admin/complete-membership/${vehicle.id}?type=${type}`
      // window.location.href = url
      this.router.navigate(['/admin/complete-membership/', vehicle.id])
    }
    else {
      // url  = `${this.baseUrl}user/complete-membership/${vehicle.id}?type=${type}`
      // window.location.href = url
      this.router.navigate(['/user/complete-membership/', vehicle.id])
    }
    // this.vehicleId = vehicle.id
    // this.customerId = vehicle.customerId
    // this.vehicle = vehicle
    // if (vehicle.qrCodeId != "") {
    //   if (vehicle.customer != null) {
    //     this.vehicleForm.setValue({
    //       vehicleType: vehicle.vehicleType,
    //       plateNumber: vehicle.plateNumber,
    //       vehicleModel: vehicle.vehicleModel,
    //       color: vehicle.color,
    //       condition: vehicle.condition,
    //       firstName: vehicle.customer.firstName,
    //       lastName: vehicle.customer.lastName
    //     })
    //   }
    //   else {
    //     this.vehicleForm.setValue({
    //       vehicleType: vehicle.vehicleType,
    //       plateNumber: vehicle.plateNumber,
    //       vehicleModel: vehicle.vehicleModel,
    //       color: vehicle.color,
    //       condition: vehicle.condition,
    //       firstName: '',
    //       lastName: ''
    //     })
    //   }
    // }
    // else {

    // }
  }
  renewMembership(vehicle: any) {
    localStorage.setItem('renewMembership', 'true')
    localStorage.setItem('paymentType', vehicle.paymentType)
    if (this.user.role == 'manager' || this.user.role == 'admin') {
      this.router.navigate(['/admin/complete-membership', vehicle.id])
    }
    else {
      this.router.navigate(['/user/complete-membership', vehicle.id])
    }
  }
  createMemberShip(vehicleId) {
    if (this.user.role == 'admin' || this.user.role == 'manager') {
      this.router.navigate(['/admin/complete-membership/', vehicleId])
    }
    else {
      this.router.navigate(['/user/complete-membership/', vehicleId])
    }
  }
  updateVehicleInformation() {
    let item = this.vehicleForm.value
    let model = {
      vehicleType: item.vehicleType,
      plateNumber: item.plateNumber,
      vehicleModel: item.vehicleModel,
      // color: item.color,
      condition: item.condition,
    }
    // console.log(model)
    this._carWashService.updateVehicle(this.vehicleId, model).subscribe((response: ResponseModel) => {
      // console.log(response)
      if (response.success) {
        this.setValueVehicle(response.data)
        this.editVehicleModal.hide()
      }
    })
  }
  setValueVehicle(newVehicle: any) {
    let index = this.vehicles.findIndex(v => v.id == this.vehicleId)
    this.vehicles[index].vehicleType = newVehicle.vehicleType
    this.vehicles[index].plateNumber = newVehicle.plateNumber
    this.vehicles[index].vehicleModel = newVehicle.vehicleModel
    // this.vehicles[index].color = newVehicle.color
    this.vehicles[index].condition = newVehicle.condition
  }
  updateCustomerInformation() {
    let item = this.vehicleForm.value
    let model = {
      firstName: item.firstName,
      lastName: item.lastName,
    }
    this._carWashService.updateCustomerVehicle(this.customerId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let index = this.vehicles.findIndex(v => v.id == this.vehicleId)
        this.vehicles[index].customer.firstName = response.data.firstName
        this.vehicles[index].customer.lastName = response.data.lastName
        this.editVehicleModal.hide()
      }
    })
  }
  setStep(index: number) {
    this.step = index;
  }
  nextweek() {
    // this.date = moment();
    this.date.add(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
    this.getCountVehiclesGlobalByDate()
  }
  previousweek() {
    this.date.subtract(1, 'weeks');
    this.getweeks(this.date);
    this.weekno = moment(this.date).isoWeek();
    this.getCountVehiclesGlobalByDate()
  }
  handlingAllMemberPagination(event) {
    this.step = ''
    this.p = event
  }
  handlingQueuePagination(event){
    this.step = ''
    this.p5 = event
  }
  // getIndex(index){
  //   let res
  //   index == 0 ? res = 'OK' : res = index
  //   return res
  // }
  handlingMembersPagination(event) {
    this.step = ''
    this.p1 = event
  }
  handlingOthersPagination(event) {
    this.step = ''
    this.p2 = event
  }
  handlingStatisticPagination(event) {
    this.step = ''
    this.p3 = event
  }
  getCountVehiclesGlobalByDate() {
    this.datesByToday = []
    for (let index = 0; index < this.days.length; index++) {
      this.datesByToday.push(this.days[index].year + '-' + this.days[index].month + '-' + this.days[index].value);
    }
    let model = {
      dates: this.datesByToday
    }
    this._carWashService.getCountVehicleByDate(this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.vehiclesByDates = response.data
      }
    })
  }
  getweeks(currentDate) {
    this.days = [];
    var weekStart = currentDate.clone().startOf('week');
    var weekEnd = currentDate.clone().endOf('week');
    // console.log('start week' + moment(weekStart).add(i, 'days').format("ddd[\r\n]DD"))
    for (var i = 0; i <= 6; i++) {
      this.days.push(
        {
          name: moment(weekStart).add(i, 'days').format("ddd"),
          value: moment(weekStart).add(i, 'days').format("DD"),
          year: moment(weekStart).add(i, 'days').format("YYYY"),
          month: moment(weekStart).add(i, 'days').format("MM")
        }
      );
    };
  }
  createPortalCustomerStripeCustom(vehicle: any) {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
    }
    this._carWashService.createPortalCustomerStripeCustom(vehicle.id, vehicle.paymentType, history).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._globalService.openLink(response.data)
      }
    })
  }
  previousYear() {
    this.yearStatistic = moment(this.yearStatistic).subtract(1, 'year').format('YYYY').toString();
    this.getVehiclesStaticsYearGlobal()
  }
  nextYear() {
    this.yearStatistic = moment(this.yearStatistic).add(1, 'year').format('YYYY').toString();
    this.getVehiclesStaticsYearGlobal()
  }
  previousYearStatistic() {
    this.yearStatistic1 = moment(this.yearStatistic1).subtract(1, 'year').format('YYYY').toString();
    this.getVehicleStaticsYearGlobal(this.vehicleId)
  }
  nextYearStatistic() {
    this.yearStatistic1 = moment(this.yearStatistic1).add(1, 'year').format('YYYY').toString();
    this.getVehicleStaticsYearGlobal(this.vehicleId)
  }
  searchQR() {
    // if(Capacitor.isNativePlatform()) {
    //   this.printScanMobile.show()
    // } else {
    //   localStorage.setItem('search', 'true')
    //   this.router.navigate(['/qr-scanner'])
    // }
    this.handle(this.action, 'start')
  }
  backRoute() {
    if (this.user.role == 'admin' || this.user.role == 'manager') {
      this.router.navigate(['/admin/vehicles-list'])
    }
    else {
      this.router.navigate(['/user/vehicles-list'])
    }
  }
  // this is statistic
  getVehicleStaticsYearGlobal(vehicleId: string) {
    this._carWashService.getVehicleStaticsYear(vehicleId, this.yearStatistic1).subscribe((response: ResponseModel) => {
      if (response.success) {
        // console.log(response)
        this.statisticYearVehicles = response.data
      }
    })
  }
  getVehicleStaticsYear(vehicleId: string) {
    this.vehicleId = vehicleId
    this._carWashService.getVehicleStaticsYear(vehicleId, this.yearStatistic1).subscribe((response: ResponseModel) => {
      if (response.success) {
        // console.log(response)
        this.statisticYearVehicles = response.data
        this.serviceStatisticModal.show()
      }
    })
  }
  getProductName(item: any, product: any) {
    return item['count' + product]
  }
  getTotalName(i: number) {
    return this.statisticYearVehicles.countColumn['AllCount' + this.statisticYearVehicles.products[i]]
  }
  getCountVehicleByDate() {
    // if (this.vehiclesByDates.length > 0) {
    //   this.vehiclesByDates = this.vehiclesByDates
    // }
    // else {
    this.step = ''
    this.datesByToday = []
    for (let index = 0; index < this.days.length; index++) {
      this.datesByToday.push(this.days[index].year + '-' + this.days[index].month + '-' + this.days[index].value);
    }
    let model = {
      dates: this.datesByToday
    }
    this._carWashService.getCountVehicleByDate(this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.vehiclesByDates = response.data
      }
    })
    // }
  }
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  getVehiclesByDate(date: any) {
    this.step = ''
    this.dateObj = date
    let dateJob = this.datePipe.transform(date, 'yyyy-M-dd')
    this._carWashService.getVehiclesByDate(this.user.companyId, dateJob, false).subscribe((response: ResponseModel) => {
      this.vehiclesByDate = response.data
      this.p3 = 1
    })
  }
  getVehiclesByDateTime(date: any) {
    // console.log(date)
    // this.dateObj = date
    // let dateJob
    // dateJob = this.datePipe.transform(date, 'yyyy-M-dd')
    // this._carWashService.getVehiclesByDate(this.user.companyId, dateJob).subscribe((response: ResponseModel) => {
    //   this.vehiclesByDate = response.data
    //   this.p3 = 1
    // })
  }
  getTodayDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }
  getVehiclesStaticsYearGlobal() {
    this._carWashService.getVehiclesStaticsYear(this.user.companyId, this.yearStatistic).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.vehiclesStaticsYear = response.data
      }
    })
  }
  getVehiclesStaticsYear() {
    // if(Object.keys(this.vehiclesStaticsYear).length <= 0){
    this._carWashService.getVehiclesStaticsYear(this.user.companyId, this.yearStatistic).subscribe((response: ResponseModel) => {
      if (response.success) {
        this.vehiclesStaticsYear = response.data
      }
    })
    // }
    // else{
    //   this.vehiclesStaticsYear = this.vehiclesStaticsYear
    // }
  }
  getProductName1(item: any, product: any) {
    return item['count' + product]
  }
  getTotalName1(i: number) {
    // console.log(this.vehiclesStaticsYear.products[i])
    // console.log(this.vehiclesStaticsYear.countColumn)
    return this.vehiclesStaticsYear.countColumn['AllCount' + this.vehiclesStaticsYear.products[i]]
  }
  timeConverter(UNIX_timestamp) {
    return new Date(UNIX_timestamp * 1000)
  }
  isCheckInVehicle(id: string) {
    let vehicle = this.vehicles.find(v => v.id == id)
    if (vehicle.CheckInObject.length <= 0) {
      return false
    }
    else {
      let isCheckIn = vehicle?.CheckInObject?.[0].isCheckIn
      if (isCheckIn != undefined || isCheckIn != null) {
        return isCheckIn
      }
      else {
        return false
      }
    }
  }
  isUnCheckInVehicle(id: string) {
    let vehicle = this.vehicles.find(v => v.id == id)
    if (vehicle.CheckInObject.length <= 0) {
      return false
    }
    else {
      let isCheckIn = vehicle.CheckInObject?.[0].isUnCheckIn
      // console.log(isCheckIn)
      if (isCheckIn != undefined || isCheckIn != null) {
        return isCheckIn
      }
      else {
        return false
      }
    }
  }
  checkIn(vehicle: any) {
    let todayDate = new Date()
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    // let history = {
    //   id : this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
    //   userId : this.user.id,
    //   branchId : this.user.companyId,
    //   addedBy : this.user.displayName,
    //   branchName:currentCompany.companyName,
    //   dateHistory :todayDate,
    //   timeHistory : this.datePipe.transform(todayDate,'H:mm'),
    //   key: this.historyCarWash.Check_In_Action,
    //   // eventName: 'Check in'
    // }
    let model = {
      date: (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      time: this.datePipe.transform(todayDate, 'H:mm'),
      companyId: this.user.companyId,
      addedBy: this.user.displayName,
      userId: this.user.id,
      companyName: currentCompany.companyName,
      lastCheckDate: new Date(),
      queueHistory : this.getHistoryObj(QueueHistory.CheckIn_Queue)
    }
    this._carWashService.updateCheckInVehicle(vehicle.id,this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let index = this.vehicles.findIndex(v => v.id == vehicle.id)
        this.vehicles[index] = response.data
      }
    })
  }
  checkOut(vehicle: any) {
    let todayDate = new Date()
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    // let history = {
    //   id : this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
    //   userId : this.user.id,
    //   branchId : this.user.companyId,
    //   addedBy : this.user.displayName,
    //   branchName:currentCompany.companyName,
    //   dateHistory :todayDate,
    //   timeHistory : this.datePipe.transform(todayDate,'H:mm'),
    //   // eventName: 'Check out',
    //   key: this.historyCarWash.Check_Out_Action,
    // }
    let model = {
      date: (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      time: this.datePipe.transform(todayDate, 'H:mm'),
      companyId: this.user.companyId,
      addedBy: this.user.displayName,
      // history : history,
      userId: this.user.id,
      lastCheckDate: new Date(),
      companyName: currentCompany.companyName,
      // queueHistory : this.getHistoryObj(QueueHistory.Out_Of_Queue)
    }
    this._carWashService.updateCheckOutVehicle(vehicle.id,this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let index = this.vehicles.findIndex(v => v.id == vehicle.id)
        this.vehicles[index] = response.data
      }
    })
  }
  updateUnCheckInVehicle(vehicle: any){
    let todayDate = new Date()
    let model = {
      queueHistory : this.getHistoryObj(QueueHistory.Out_Of_Queue),
      history : this.getHistoryObj(QueueHistory.Out_Of_Queue),
      lastCheckDate: new Date(),
      date: (todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1)) + '-' + todayDate.getDate()),
      time: this.datePipe.transform(todayDate, 'H:mm'),
    }
    this._carWashService.updateUnCheckInVehicle(vehicle.id,this.user.companyId, model).subscribe((response: ResponseModel) => {
      if (response.success) {
        let index = this.vehicles.findIndex(v => v.id == vehicle.id)
        this.vehicles[index] = response.data
      }
    })
  }
  storeBuffVehicle(vehicle: any) {
    this.buffVehicle = vehicle
  }
  deleteVehicle(vehicle: any) {
    this._carWashService.deleteVehicle(vehicle.id).subscribe((response: ResponseModel) => {
      if (response.success) {
        this._toaster.success('', 'Successfully delete vehicle')
      }
      else {
        this._toaster.error('', 'Error delete vehicle')
      }
    })
  }
  renewForce(vehicle: any) {
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      key: this.historyCarWash.Force_Update_Plan,
      // eventName: 'Force update plan'
    }
    this._carWashService.renewUpdateForce(vehicle.id, history).subscribe((response: ResponseModel) => {
      if (response.success) {
        // this._toaster.success('','Successfully delete vehicle')
        this.storeVehicle(response.data, response.data.paymentType,2)
      }
      else {
        // this._toaster.error('','Error delete vehicle')
      }
    })
  }
  changeDenied(isDenied) {
    this.isDenied = isDenied
  }
  checkStripKey(){
    this._carWashService.isProductsCompanyById(this.user.companyId).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.isHaveProducts = true
        this.inviteModal.show()
      }
      else{
        this.isHaveProducts = false
        this.stripKeyModal.show()
      }
    })
  }
  // getLastCheckIn(vehicle:any,type:number){
  //   let lastCheckIn:any = ''
  //   if(type == this.historyCarWash.Check_In_Action && vehicle.histories) {
  //     lastCheckIn = vehicle.histories.find(v => v.key == this.historyCarWash.Check_In_Action)
  //     lastCheckIn =  lastCheckIn ? lastCheckIn.dateHistory : ''
  //   }
  //   else if(type == this.historyCarWash.Check_Out_Action && vehicle.histories ){
  //     lastCheckIn = vehicle.histories.find(v => v.key == this.historyCarWash.Check_Out_Action)
  //     lastCheckIn =  lastCheckIn ? lastCheckIn.dateHistory : ''
  //   }
  //   return lastCheckIn
  // }
  selectAction(event: any, vehicle: any) {
    localStorage.setItem('isDetailing','false')
    if (event == this.actionsCar.Edit_Vehicle_Profile) {
      this.storeVehicle(vehicle, vehicle.paymentType,0)
    }
    if (event == this.actionsCar.Qr_Code) {
      this.storeVehicle(vehicle, vehicle.paymentType,1)
    }
    else if (event == this.actionsCar.Delete_Vehicle_Profile) {
      this.storeBuffVehicle(vehicle)
      this.deleteVehicleModal.show()
    }
    else if (event == this.actionsCar.Force_Plan_Change) {
      this.storeBuffVehicle(vehicle);
      this.reNewMembershipModal.show()
    }
    else if (event == this.actionsCar.Create_Card_Subscription) {
      this.storeVehicle(vehicle, this.paymentTypeEnum.card,2)
    }
    else if (event == this.actionsCar.View_Card_Subscription) {
       this.createPortalCustomerStripeCustom(vehicle)
    }
    else if (event == this.actionsCar.Create_Cash_Subscription) {
      this.storeVehicle(vehicle, this.paymentTypeEnum.cash,2)
    }
    else if (event == this.actionsCar.View_Cash_Subscription) {
       this.createPortalCustomerStripeCustom(vehicle)
    }
    else if (event == this.actionsCar.Disabled_Payment_Type) {
      this.changeDenied(false)
      this.deniedModal.show()
    }
    else if (event == this.actionsCar.Customer_Route) {
      if (vehicle.customer.uid) {
        (this.user.role == 'admin' || this.user.role == 'manager') ? this.router.navigate(['/admin/customer-profile/', vehicle.customer.id]) : this.router.navigate(['/user/customer-profile/', vehicle.customer.id]);
      }
      if (!vehicle.customer.uid) {
        localStorage.setItem('bufferCustomer', JSON.stringify(vehicle.customer));
        (this.user.role == 'admin' || this.user.role == 'manager') ? this.router.navigate(['/admin/add-customer-manually']) : this.router.navigate(['/user/add-customer-manually']);
      }
    }
  }
  checkAction(event: any, vehicle: any){
    console.log(vehicle)
    if(vehicle.phone == undefined ||  vehicle.phone == '' || vehicle.phone == null){
      this.noPhoneModal.show()
    }
    else{
      this.buffVehicle = vehicle
      this.smsType = event
      if(event == this.smsNotification.Car_Wash_Ready_to_Served){
        let car_Wash_Ready_to_Served = this.othersTools.items.find(t => t.itemId == ToolSettingEditItem.Car_Wash_Vehicle).SmsNotification.find(e => e.type == event)
        car_Wash_Ready_to_Served.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
      }
      if(event == this.smsNotification.Car_Wash_Ready){
        let car_ready = this.othersTools.items.find(t => t.itemId == ToolSettingEditItem.Car_Wash_Vehicle).SmsNotification.find(e => e.type == event)
        car_ready.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
      }
      if(event == this.smsNotification.Car_Wash_Out_Of_Queue){
        let car_Wash_Out_Of_Queue = this.othersTools.items.find(t => t.itemId == ToolSettingEditItem.Car_Wash_Vehicle).SmsNotification.find(e => e.type == event)
        car_Wash_Out_Of_Queue.status == 'Active'  ? this.sendMessageModal.show() :  this.notSendMessageModal.show()
      }
    }
  }
  sendSMSMessage(){
    let model = {
      smsType: this.smsType,
      date: (new Date().getFullYear() + '-' + ((new Date().getMonth() + 1)) + '-' + new Date().getDate()),
    }
    this._carWashService.sendSmsVehicle(this.buffVehicle.id,this.user.companyId,model).subscribe((response:ResponseModel)=>{
      if(response.success){
        this._toaster.success('',response.message)
      }
    })
  }
  shareLink() {
    const navigator = window.navigator as any;
    if (navigator.share) {
      navigator
        .share({
          title: this.companyInfo.CompanyName,
          text: '',
          url: this.inviteLink,
          // img: this.user.companyLogo
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      alert('share not supported');
    }
  }
  storeValuesToPrint(model:any,vehicle:any){
    this.bufferVehicle = vehicle
    this.servicesObj = model
    console.log(model)
    setTimeout(() => {
        this.printInvoice()
    }, 500);
  }
  printFixed() {
    //  if (this.deviceService.isDesktop()) {
    // let printContents = document.getElementById('qr_codes_Id').innerHTML;
    let printContents: any = ''
    // if (Number(this.quantity) == 10) {
      printContents = document.getElementById('qr_code_print').innerHTML;
    // }
    // else {
      // printContents = document.getElementById('qr_codes_Id_30').innerHTML;
    // }
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
       <html>
         <head>
           <title>print</title>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
           <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
           <style>
           body {
            // text-align : center;
            // display: inline-flex;
            // justify-content: space-between !important;
            // flex-wrap: wrap;
            // margin-top:1.2rem !important;
            // align-items: center;
            text-align: center;
            position: absolute;
            top: -35%;
            left: 50%;
            transform: translate(-50%, 50%);
          }
          .item {
            display: flex;
            justify-content: center;
            margin:0 auto 0;
        }
        .qr-code{
          display:block !important
          margin:auto
        }
        .qr-code h5{
          font-size:3rem;
          font-weight:600;
          color:#040497 ;
        }
        .first-side  img {
            width: 350px;
            height: 350px;
          }
          .second-side {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .second-side .info{
          // display: flex;
          // justify-content: flex-start;
          text-align:left !important;
        }
        .second-side .info  p {
          // margin-bottom: 0;
          margin-top: .2rem;
          color: #040497 !important;
          font-size: 2.2rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

     .steps {
          margin: 2rem auto !important;
          padding: 0 1rem;
          // text-align: center;
        display: flex;
        justify-content: center;

      }
       .steps .steps-content {
        text-align: initial !important;
       }
      .steps .num{
        width: 60px;
        height: 60px;
        display: inline-block;
        color: #fff !important;
        background: #040497;
        border-radius: 50%;
        text-align: center;
        line-height: 1.7;
        margin-right: 16px;
      }
      .qr-code img{
        width:400px;
        height:400px;
      }
      .steps .steps-content p{
        font-size: 2.2rem;
        font-weight: 700;
        color: #040497;
        margin-bottom: 2.5rem;
        // text-align:left;
      }
      .address-section p{
        font-size: 2rem;
        font-weight: 600 !important;
        padding-left: 20px;
        margin-bottom: 3rem !important;
        color: #040497 !important;
      }
          // .row{
          //   width:384px !important
          //   height:192px !important
          //   padding: 0
          // }
          // .col-4{
          //   display: flex;
          //   align-items: center;
          // }
          //  .full-item {
          //   display: flex;
          //   justify-content: right !important;
          //   margin: 0.6rem auto  ;
          //   // margin: 0.3rem auto  0.7rem  ;
          //   width:384px ;
          //   // height:192px !important;
          // }
          // .col-4 {
          //   padding:0;
          // }
          // .col-4:nth-child(3n+1) .full-item{
          //   justify-content: space-evenly !important;
          //   padding-right: 15px  !important;
          //   padding-left: 15px  !important;
          // }
          // .col-4:nth-child(n+13) .full-item {
          //   margin: 0.4rem auto !important;
          // }
          // .last-item{
          //   margin-top:1rem !important
          // }
          // .full-item .info{
          //   padding-right:16px;
          //   // direction: rtl !important;
          //   text-align: left!important;
          // }
          // .col-4:nth-child(3n+1) .full-item .info{
          //   padding:0px;
          // }
          // .col-6{
          //   display: flex;
          //   align-items: center;
          //   padding:0;
          // }
          // .col-6 .full-item {
          //   margin: 2.6rem  auto ;
          // }
          // .col-6:nth-child(n+5) .full-item {
          //   margin: 2.3rem  auto ;
          // }
          // .col-6 .full-item  .qr-img  img{
          //   width:165px !important
          //   height:165px !important
          // -webkit-transform: scaleX(-1);
          // }
          // .info-details  p {
          //   font-size: 11px;
          //   margin: 0;
          //   font-weight:700;
          //   transform: scaleX(-1);
          //   // direction: ltr !important;
          //   // unicode-bidi: bidi-override !important;
          //   // direction: ltr !important;
          //   // unicode-bidi: bidi-override !important;
          //   line-height: 1.5;
          //   }
          // .qr-img  {
          //   // height:192px !important
          //   display: block;
          //   // margin: 10px  ;
          //   text-align : left
          // }
          // .col-4  .qr-img  img{
          //   width:120px !important;
          //   height:120px !important;
          //   // -webkit-transform: scaleX(-1);
          // }
          // .col-6 .qr-img img{
          //   width:200px !important;
          //   height:200px !important;
          //   // -webkit-transform: scaleX(-1);
          // }
          // .qr-img  span {
          //   padding-left: 5px;
          //   font-size: 7px;
          //   }
          //   .info {
          //     display: flex;
          //     justify-content: left !important;
          //     align-items: center;
          //     text-align:left !important;
          // }
        // img{
        //   -webkit-transform: scaleX(-1) !important;
        // }
      //   @page {
      //     margin-right: 0;
      // }
          // @media print {
          //   .pagebreak  {
          //       page-break-before: always;
          //   }
            /* page-break-after works, as well */
        // }
           </style>
         </head>
         <body onload="window.print()">${printContents}</body>
       </html>`
    );
    popupWin.document.close();
  }
  printFixedToday(isMember:boolean) {
    if (Capacitor.isNativePlatform()) {
      this.isPrint = true
      this.printScanMobile.show()
    }
    else{
      let printContents: any = ''
      isMember ?
      printContents = document.getElementById('today_members_id').innerHTML:
      printContents = document.getElementById('today_others_id').innerHTML;
      let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
         <html>
           <head>
             <title>print</title>
             <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
             <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
             <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
             <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
             <style>
             body {
              text-align: center;
              margin-top: 2rem;
            }
          .tittle-table{
            display: flex;
            justify-content: space-between;
          }
          .table{
            text-align: center;
          }
          }
             </style>
           </head>
           <body onload="window.print()">${printContents}</body>
         </html>`
      );
      popupWin.document.close();
    }
  }
  printInvoice() {
    if (Capacitor.isNativePlatform()) {
      this.isPrint = true
      this.printScanMobile.show()
    }
    else{
      let printContents: any = ''
      printContents = document.getElementById('receipt_id').innerHTML
      let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
         <html>
           <head>
             <title>print</title>
             <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
             <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
             <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
             <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
             <style>
             body {
              text-align: center;
              margin-top: 4rem;
            }
          .tittle-invoice{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            width: 50%;
            margin: auto;
           }
          .item {
            display: flex;
            justify-content: revert;
            align-items: flex-start;
            margin: 1rem auto;
            width: 50%;
        }
        .first-side  img {
          width: 175px;
          height: 175px;
          }
          .second-side .info {
            text-align: left;
            margin-top: 1rem;

          }
          .second-side .info p{
            margin-bottom: .3rem !important
          }
          .services-block{
            border: 1px solid #ccc;
            padding: 11px;
            border-radius: 3px;
            width: 50%;
            margin: 4rem auto;
          }
          .services-block  .tittle-service{
            display: flex;
            justify-content: space-between;
            align-items: center;
            transform: translateY(-35px);
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px  8px;
            background: #fff;
            width: 67%;
            margin: auto ;
          }
          .services-block .vehicle-info{
            justify-content: center ;
          }
          .services-block  .tittle-service h5 {
            margin: 0 ;
          }
          hr{
            width: 100%;
            margin: 1rem 0;
          }
          .others-normal-services p{
                display: flex;
                justify-content: space-between;
          }
             </style>
           </head>
           <body onload="window.print()">${printContents}</body>
         </html>`
      );
      popupWin.document.close();
    }
  }
  // scan web
   // scan qr code
   public handle(action: any, fn: string): void {
    if (fn == 'start') {
      this.scanWebModal.show()
    }
    else if (fn == 'stop') {
      this.scanWebModal.hide()
      action['stop']().subscribe(res => {
        action.data._value.data = ""
      });
    }
    action[fn]().subscribe(console.log, console.log);
  }
  public onEvent(e: any): void {
    if (e.data != "" && e != null && e != undefined) {
      let data = e.data.split('/')[4].split(',')
      // let data = e.data[3].split('/')
      // console.log(data)
     this.vehicles = this.vehicles.filter(v => v.qrCodeNumber == Number(data[1]))
     this.searchText = data[1]
    this.step = 0
    this.p= 1
    this.setStep(this.step)
      this.handle(this.action, 'stop')
      this.scanWebModal.hide()
    }
  }
  public onError(e: any): void {
    this.scanWebModal.hide()
  }
  // functions for open camera
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public handleImage(webcamImage: any): void {
    this.webcamImage = webcamImage;
    this.trigger = new Subject<void>();
  this._carWashService.getVehicleInformationFromAPI(this.webcamImage._imageAsDataUrl).subscribe((response:ResponseModel)=>{
  //  console.log(response)
    if(response.success){
     if(response.data.results.length > 0){
      this.searchText = response.data.results[0].plate.toString().toLowerCase()
      this.step = 0
      this.p= 1
      this.cameraScanModal.hide()
     }
     else{
      this.cameraScanModal.hide()
      this._toaster.warning('Try again','plate number NOT detect ')
     }
    }
  })
  // let body = new FormData();
  // body.append('upload', this.webcamImage._imageAsDataUrl);
  // fetch("https://api.platerecognizer.com/v1/plate-reader/", {
  //   method: "POST",
  //   headers: {
  //     Authorization: "Token 95b11f0c9ec1a1d6119d742f58c7a8c6f95fd46a",
  //   },
  //   body: body,
  // })
  //   .then((res) => res.json())
  //   .then((json) => {
  //     console.log(json)
  //     this.cameraScanModal.hide()
  //     this.searchText = json.results[0].plate.toString().toLowerCase()
  //     this.step = 0
  //     this.p= 1
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  }
  openCamera() {
    this.showWebcam = true
    this.trigger.next();
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = !this.showWebcam
  }
  closeCamera() {
    this.showWebcam = false
  }
  getHistoryObj(event:any){
    let model = {
      id : this.buffVehicle?.queueHistories ? this.buffVehicle?.histories.length + 1 : 2,
      userId : this.user.id,
      branchId : this.user.companyId,
      addedBy : this.user.displayName,
      branchName:this.currentCompany.companyName,
      dateHistory : new Date(),
      timeHistory : this.datePipe.transform(new Date(),'H:mm'),
      key: event,
    }
    return model
  }
  getCurrency(){
    return this._globalService.getCurrency()
  }

}
