import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Router } from '@angular/router';
import { PersonService } from 'src/app/core/_services/person/person.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { Socket } from 'ngx-socket-io';
import { v4 as uuid } from 'uuid';
declare var $
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit,OnDestroy {
  inviteFormGroup: FormGroup;
  role: any
  isThirdParty: any = ""
  isCustomer: any = ""
  isAdmin: any = ""
  user:any={}
  emailPattern=ValidationModel.emailPattern
  constructor(
    private _formBuilder: FormBuilder,
    private _personService: PersonService,
    private router: Router,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private socket: Socket,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.createInviteForm()
    const user = JSON.parse(localStorage.getItem('user'));
    this.role = user.role
    // console.log(localStorage.getItem('isCustomer'))
    if (localStorage.getItem('isThirdParty')) {
      this.isThirdParty = localStorage.getItem('isThirdParty')
    }
    if (localStorage.getItem('isCustomer')) {
      this.isCustomer = localStorage.getItem('isCustomer')
    }
    if (localStorage.getItem('isAdmin')) {
      this.isAdmin = localStorage.getItem('isAdmin')
    }
  }
  createInviteForm() {
    this.inviteFormGroup = this._formBuilder.group({
      email: ['', [Validators.required,Validators.pattern(this.emailPattern)]],
      role: ['', Validators.required]
    });
  }
  get f() {
    return this.inviteFormGroup.controls;
  }
  InviteUser() {
    let inviteUserModel = this.inviteFormGroup.value
    // let current_date = new Date()
    // let date = this.datePipe.transform(current_date, 'MM/d/yy, h:mm a')
    let type: any
    let role: any
    if (inviteUserModel.role === 'manager') {
      role = 'manager'
      type = 'user'
    }
    if (inviteUserModel.role === 'teamleader') {
      role = 'teamleader'
      type = 'user'
    }
    else if (inviteUserModel.role === 'customer') {
      role = 'customer'
      type = 'customer'
    }
    else if (inviteUserModel.role === 'manager') {
      role = 'manager'
      type = 'user'
    }
    else if (inviteUserModel.role === 'crew') {
      role = 'crew'
      type = 'user'
    }
    else if (inviteUserModel.role === '3rdparty') {
      role = '3rdparty'
      type = 'user'
    }
    inviteUserModel.type = type
    inviteUserModel.role = role
    inviteUserModel.date = new Date()
    inviteUserModel.dateFormate = this.datePipe.transform(new Date(), 'yyyy-d-M')
    inviteUserModel.timeFormate = this.datePipe.transform(new Date(), 'h:mm a')
    inviteUserModel.email = this.getEmailWithoutSpace(inviteUserModel.email)
    inviteUserModel.idIndexedDB = uuid()
    inviteUserModel.companyId = this.user.companyId
    if(type == 'customer'){
      this._personService.InviteCustomerPending(inviteUserModel).subscribe((response: ResponseModel) => {
        if (response.success) {
          $("#myModal").modal('show');
        }
        else {
          this.toastr.error(response.message, 'Invitation Failed',
            {
              progressBar: true,
              progressAnimation: "increasing",
              timeOut: 3000
            });
        }
      }, error => {
        console.log(error)
      })
    }
    else if(type == 'user'){
      this._personService.InvitePerson(inviteUserModel).subscribe((response: ResponseModel) => {
        if (response.success) {
          $("#myModal").modal('show');
        }
        else {
          this.toastr.error(response.message, 'Invitation Failed',
            {
              progressBar: true,
              progressAnimation: "increasing",
              timeOut: 3000
            });
        }
      }, error => {
        console.log(error)
      })
    }

    this.inviteFormGroup.reset
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  changeUserRole() {
    // if (this.isAdmin === 'true') {
    //   this.router.navigate(['/admin/users-list'])
    // }
    // if (this.isCustomer === 'true') {
    //   this.router.navigate(['/admin/customers-list'])
    // }
    // else {
    //   this.router.navigate(['/admin/users-list'])
    // }
    this.inviteFormGroup.reset()
    this.inviteFormGroup.patchValue({ role: ''});
  }
  ngOnDestroy() {
    localStorage.setItem("isAdmin", "false")
    localStorage.setItem("isCustomer", "false")
    localStorage.setItem('isThirdParty', 'false')
  }
}
