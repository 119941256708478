import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { QrService } from 'src/app/core/_services/qr-service/qr.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { environment } from 'src/environments/environment.prod';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';

@Component({
  selector: 'app-med-route-qr',
  templateUrl: './med-route-qr.component.html',
  styleUrls: ['./med-route-qr.component.scss']
})
export class MedRouteQrComponent implements OnInit,OnDestroy {
  @ViewChild('isQrUsingModal') isQrUsingModal: ModalDirective;
  qrCodeId = ''
  user: any = {}
  jobModel: any = {}
  baseUrl = environment.baseUrl
  constructor(
    private _qrService: QrService,
    private route: ActivatedRoute,
    private router: Router,
    private _toaster:ToasterCustomService,
    private _companiesService : CompaniesService
  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.qrCodeId = this.route.snapshot.paramMap.get('id');
    let data = this.route.snapshot.paramMap.get('id').split(',')
    if(this.user){
      if(this.user.role == 'admin' || this.user.role == 'manager'){
        this.router.navigate(['/admin/vehicles-list'],{queryParams: { qrCodeNumber: data[1] }})
      }
      else if(this.user.role == 'teamleader' || this.user.role == 'crew'){
      this.router.navigate(['/user/vehicles-list'],{queryParams: { qrCodeNumber: data[1] }})
      }
      else{
      this.router.navigate(['/user'])
      this._toaster.warning('','do Not have Permission')
      }
    }
    else{
      this.router.navigate(['/view-vehicle-global',`${data[0]},${data[1]}`])
      // this._companiesService.checkVehicleQrNumber(data[0],Number(data[1])).subscribe((response:ResponseModel)=>{
      //   if(response.success && !response.data.isFound){
      //     this.router.navigate(['/not-assigned-qr'])
      //   }
      //   else{
      //   }
      // })
    }

    this._qrService.getQrCodeById(this.qrCodeId).subscribe((response: ResponseModel) => {
      if (response.success && response.data.companyId == this.user.companyId) {
        if (this.user != null && !response.data.isAssigned) {
          let isAssignedQR = localStorage.getItem('isAssignedQR')
          if (isAssignedQR && isAssignedQR == 'true') {
            if (this.user.role == 'admin' || this.user.role == 'manager') {
              this.router.navigate(['/admin/add-assigned-vehicle/' + this.qrCodeId])
              // console.log('this is first')
            } else if (this.user.role == 'teamleader' || this.user.role == 'crew') {
              this.router.navigate(['/user/add-assigned-vehicle/' + this.qrCodeId])
              // console.log('this is first')
            }
          }
          else{
            this.router.navigate(['/not-assigned-qr'])
          }
        }
        else if (this.user != null && response.data.isAssigned) {
          let search = localStorage.getItem('search')
          let isAssignedQR = localStorage.getItem('isAssignedQR')
          if (search && search == 'true') {
            // console.log('enter search', search)
            if (this.user.role == 'admin' || this.user.role == 'manager') {
              this.router.navigate(['/admin/vehicles-list/' + this.qrCodeId], { queryParams: { search: 'true' } })
              // console.log('this is second')
            }
            else if (this.user.role == 'teamleader' || this.user.role == 'crew') {
              this.router.navigate(['/user/vehicles-list/' + this.qrCodeId], { queryParams: { search: 'true' } })
              // console.log('this is second')
            }
          }
          else if (search && search == 'true_job') {
            // console.log('job details search', search)
            if (this.user.role == 'admin' || this.user.role == 'manager') {
               this.jobModel = JSON.parse(localStorage.getItem('jobModel'))
              this.router.navigate(['/admin/job-details/' + this.jobModel.jobId], { queryParams: { search: this.qrCodeId } })
              // console.log('this is second')
            }
            else if (this.user.role == 'teamleader' || this.user.role == 'crew') {
              this.router.navigate(['/user/job-details/' + this.jobModel.jobId], { queryParams: { search: this.qrCodeId } })
              // console.log('this is second')
            }
          }
          else {
            if (isAssignedQR && isAssignedQR == 'true') {
              this.isQrUsingModal.show()
            }
            else {
              if (this.user.role == 'admin' || this.user.role == 'manager') {
                this.router.navigate(['/admin/view-vehicle/' + this.qrCodeId])
                // console.log('this is second')
              }
              else if (this.user.role == 'teamleader' || this.user.role == 'crew') {
                this.router.navigate(['/user/view-vehicle/' + this.qrCodeId])
                // console.log('this is second')
              }
            }
          }
        }
        else if (this.user == null && response.data.isAssigned || this.user == undefined && response.data.isAssigned) {
          this.router.navigate(['/view-vehicle-global/' + this.qrCodeId])
          // console.log('this is third')
        }
        else if (this.user == null && !response.data.isAssigned || this.user == undefined && !response.data.isAssigned) {
          this.router.navigate(['/not-assigned-qr'])
          // console.log('this is four')
        }
      }
      else{
        this.router.navigate(['/login'])
      }
    })
  }
  assignQR() {
    // this.router.navigate(['/qr-scanner'])
    // localStorage.setItem('isAssignedQR','true')
    // this._carWashService.assignQR()
  }
  ngOnDestroy() {
    // localStorage.setItem('isAssignedQR','false')
  }
}
