import { PersonService } from './../../../../../core/_services/person/person.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { PersonPendingService } from 'src/app/core/_services/PendingPersons/personPending.service';
@Component({
  selector: 'app-invite-complete-car-wash',
  templateUrl: './invite-complete-car-wash.component.html',
  styleUrls: ['./invite-complete-car-wash.component.scss']
})
export class InviteCompleteCarWashComponent implements OnInit {
  email :any = ''
  passwordPattern = ValidationModel.passwordPattern
  isMismatch = false
  user:any = {}
  customerModel :any= {}
  customerId = ''
  role  =''
  type = ''
  isShowPassword = false
  companyId = ''
  constructor(
    private route: ActivatedRoute,
    private datePipe : DatePipe,
   private _personService : PersonService,
   private _personPendingService : PersonPendingService,
   private router: Router
  ) { }

  ngOnInit(){
    this.route.queryParams.subscribe(params => {
     this.email = params['email'];
     this.user = JSON.parse(localStorage.getItem('user'))
     this.customerId = params['id'];
     if ( this.customerId  != null) {
      this._personPendingService.getPendingUser(this.customerId).subscribe((response: ResponseModel) => {
      if(response.success){
        this.email = response.data.email
        this.type = response.data.type
        this.companyId = response.data.companyId
        this.role = response.data.role
      }
      else if(!response.success){
        this.router.navigate(['/second-invite'])
      }
      })
  }})
  }
  checkPassword(){
    this.customerModel.psw != this.customerModel.cPsw   ?
    this.isMismatch = true :  this.isMismatch = false
  }
  addCustomer(){
    let CustomerModel: any = {
      companyId: this.companyId,
      firstName:'Customer',
      lastName: 'Name',
      name: 'Customer Name',
      email: this.email.trim().toLocaleLowerCase(),
      password: this.customerModel.psw,
      confirmPassword: this.customerModel.cPsw,
      phone: 0,
      addressOne: '',
      addressTow:'',
      city: '',
      state: '',
      zip: 0,
      country: {
        name: 'United States',
        alpha2Code: 'US',
        alpha3Code: 'US',
        numericCode: '840'
      },
      link: window.location.href,
      type: this.type,
      status: 'Unassigned',
      role: this.role,
      customPersonType: "Customer Type",
      isInvited: true,
      isCanceled: false,
      date: new Date(),
      dateFormate : this.datePipe.transform(new Date(), 'yyyy-d-M'),
      timeFormate : this.datePipe.transform(new Date(), 'h:mm a'),
      id: this.customerId,
      lat :'' ,
      lng :'' ,
      formatted_address :'' ,
      place_id :''
    }
    this._personService.addCustomerFromInvite(CustomerModel).subscribe((response: ResponseModel) => {
      this.router.navigate(['/login'],{ queryParams: { email: this.email.trim().toLocaleLowerCase() }, queryParamsHandling: "preserve" })
    }, error => {
      console.log(error)
    })
  }
  showPassword() {
    this.isShowPassword = !this.isShowPassword
  }
}
