<div class="container">
    <div class="card">
        <div class="tittle-section">
            <p>
                <span>Customer Information</span>
            </p>
        </div>
        <form #form="ngForm" id="form">
            <div class="form-group">
                <label for="email">Email </label>
                <input type="email" id="email" name="email" readonly [(ngModel)]="email" class="form-control">
            </div>
            <div class="form-group">
                <label for="psw">Password <span class="required">*</span></label>
                <input [type]="isShowPassword ? 'text' : 'password'" id="psw" name="psw" required (input)="checkPassword()" [(ngModel)]="customerModel.psw" class="form-control" [pattern]="passwordPattern" #pswM="ngModel">
                <div *ngIf="pswM.errors && pswM.touched" class="alert-danger">
                    <div *ngIf="pswM.errors.pattern"> must be at least 6 characters and should contain at least one number and one special character and capital letter </div>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPsw">Confirm Password <span class="required">*</span></label>
                <input [type]="isShowPassword ? 'text' : 'password'" id="confirmPsw" name="cPsw" required (input)="checkPassword()" [(ngModel)]="customerModel.cPsw" class="form-control" [pattern]="passwordPattern" #cPswM="ngModel">
                <div *ngIf="cPswM.errors && cPswM.touched" class="alert-danger">
                    <div *ngIf="cPswM.errors.pattern"> must be at least 6 characters and should contain at least one number and one special character and capital letter </div>
                </div>
                <div *ngIf="isMismatch" class="alert-danger"> Password is Mismatch</div>
            </div>
            <div class="form-check ">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="showPassword()">
                <label class="form-check-label" for="flexCheckDefault">
                Show Password
            </label>
            </div>
            <div class="text-center">
                <button class="btn btn-sm btn-success" form="form" [disabled]="!form.valid || isMismatch " (click)="addCustomer()">Create</button>
            </div>
        </form>
    </div>
</div>