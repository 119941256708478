import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { JobDetails } from 'src/app/models/job-details/JobDetails';
import { JobDetailsService } from './../../../../../core/_services/job-details/job-details.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { RealTimeKeys } from 'src/app/models/realtime/RealTimeKeys';
import { Socket } from 'ngx-socket-io';
import { StatusActive } from 'src/app/models/tools-model/StatusActive';
import { JobsService } from 'src/app/core/_services/jobs/jobs.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolsService } from 'src/app/core/_services/tools/tools.service';

interface Signature {
  jobId: string;
  name: string,
  image: string,
  status: string,
  role: string,
  date: Date
}
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  model: any = {} as any
  signatures: Signature[] = []
  doneModel: Signature = {} as Signature
  toggleModel = ""
  statusItems: any[] = []
  isLinear = false;
  user: any = {}
  roles: any[] = []
  role: string = ''
  p: number = 1
  signaturePadOptions: Object = {
    'minWidth': 0.5,
    'canvasWidth': 360,
    'canvasHeight': 300
  };
  SignatureForm: FormGroup
  jobDetails: JobDetails = {}
  jobId: any
  signatureId: string = ''
  userPermission: any = {}
  statusActive: any = StatusActive
  items:any[]=[]
  jobsTools: any = {}
  signatureObj: any = {}
  jobDetailsId:string
  isDrawImageSignature = true
  constructor(
    private _jobDetailsService: JobDetailsService,
    private _jobsService: JobsService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private socket: Socket,
    public _toolsService: ToolsService

  ) { }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = this.user.role
    this.model.status = "Draft"
    this.model.role = this.role
    this.model.name = this.user.displayName
    this.jobId = this.route.snapshot.paramMap.get('id');
    this.statusItems = [{
      status: "Draft"
    },
    {
      status: "Deal",
    },
    {
      status: "In Progress",
    },
    {
      status: "Done",
    },
    {
      status: "Canceled",
    }
    ]
    // roles items
    this.roles = [
    //   role: "admin"
    // },
    // {
    //   role: "manger",
    // },
    // {
    //   role: "teamLader",
    // },
    // {
    //   role: "crew",
    // },
    {
      role: "3rdparty",
    },
    {
      role: "customer",
    }
    ]
    if(this.role == 'admin' || this.role == 'manager' || this.role == 'teamleader' || this.role == 'crew'){
      this.roles.push({
        role:this.user.role
      })
    }
    // get signatures
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['tools'].success) {
        this.jobsTools = response['tools'].data.find(d => d.toolType === ToolType.Jobs_Tools)
      if (response['jobs'].success) {
        this.items = response['jobs'].data.showItems
        if (this.role == "admin") {
          this.userPermission = this._toolsService.getPermission(this.user.role,this.jobsTools,ToolJobsEditItem.signatures)
          if(this.userPermission.itemStatus == 'Active') {
            this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Active)
          }
          this.getSignatures()
        }
        if (this.role != "admin" && this.role != "manager") {
          this.userPermission = this._jobsService.getLayerPermission(this.items, ToolJobsEditItem.signatures, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getSignatures()
          }
          }
        if (this.role == "manager") {
          this.userPermission = this._jobsService.getDefaultLayerPermission(this.items, ToolJobsEditItem.signatures, this.user.id)
          if (this.userPermission.value != this.statusActive.InActive) {
            this.getSignatures()
          }
        }
      }
     }
    }, error => {
      console.log(error)
    })
    this.socket.on(RealTimeKeys.Job_Add_Sig, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        let idItem = ToolJobsEditItem.signatures
        this._jobDetailsService.getJobDetailsByIdItem(data.id, idItem).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.signatures = response.data.signature
          }
        }, error => {
          console.log(error)
        })
      }
    });
    this.socket.on(RealTimeKeys.Job_Delete_Sig, (data: any) => {
      if (this.user.companyId == data.companyId && this.jobId == data.jobId) {
        let idItem = ToolJobsEditItem.signatures
        this._jobDetailsService.getJobDetailsByIdItem(data.id, idItem).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.signatures = response.data.signature
          }
        }, error => {
          console.log(error)
        })
      }
    });
  }
  checkItemPlan(){
    if(this._jobsService.getToolPlan(this.items,ToolJobsEditItem.signatures) > this.user.companyPlan){
      this.userPermission = this._jobsService.getStaticPermission(this.statusActive.Read_Only)
    }
  }
  getSignatures(){
    this._jobDetailsService.getJobDetailsByIdItem( this.jobId,ToolJobsEditItem.signatures).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.signatures = response.data.signature
        this.jobDetailsId = response.data.id
        this.checkItemPlan()
      }
    })
  }
  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.clear();
  }
  drawComplete() {
    this.model.image = this.signaturePad.toDataURL()
    this.isDrawImageSignature =false
  }
  clear() {
    this.signaturePad.clear()
    this.isDrawImageSignature = true
  }
  save() {
    let date = new Date()
    this.clear()
    this.model.status = "Draft"
    this.model.name = ''
    this.model.status = ''
    this.model.role = 'Admin'
  }
  undo() {
    let data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }
  addSignature() {
    let item = Object.assign({}, this.model)
    item.date = new Date()
    item.id = (this.signatures.length) + 1
    let idItem = ToolJobsEditItem.signatures
    // this.jobDetails.jobId = this.jobId
    // setTimeout(() => {
      this._jobDetailsService.updateJobDetails(item, this.jobId, idItem,0).subscribe((response: ResponseModel) => {
        if(response.success){
          this.clear()
          this.model.status = "Draft"
          this.model.name = ''
          this.signatures = response.data.signature
          this.isDrawImageSignature = true
        }
      }, error => {
        console.log(error)
      })
    // }, 2000);
  }
  storeItem(item: any) {
    this.signatureId = item.id
  }
  deleteSignature() {
    let idItem = ToolJobsEditItem.signatures
    this._jobDetailsService.updateJobDetailsDeleteSignature(this.jobId, this.signatureId,idItem).subscribe((response: ResponseModel) => {
    })
  }
}
