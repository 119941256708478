<div class="container-fluid">
    <h1>Accordev</h1>
    <div class="tittle">
        <p>Activating Accordev account</p>
    </div>
    <div class="second">
        <strong>
          Payment is required in order to be able to access your Accordev account:
        </strong>
        <div class="first">
            <p>{{companyInfo.companyName}}</p>
            <p>
                {{companyInfo.companyAddress?.addressOne}},<span *ngIf="companyInfo.companyAddress?.addressTow"> {{companyInfo.companyAddress?.addressTow}}, </span> {{companyInfo.companyAddress?.country.name}}, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}}
                {{companyInfo.companyAddress?.zip}}
            </p>
        </div>
        <!-- <strong> waiting Pay .</strong> -->
    </div>
    <!-- <div class="third">
      <p> please Pay a invoice:</p>
      <strong>Kind Regards, Accordev Team</strong>
  </div> -->
    <div class="request-form">
        <a class="btn btn-primary" href="{{companyInfo.url}}" target="_blank">Pay</a>
    </div>
</div>