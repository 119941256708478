import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TrashService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }
  // ********** get all trash in company **********
  getAllTrash(companyId: string) {
    return this.http.get(this.baseUrl + 'trashes/' + companyId)
  }
  getAllTrashByType(companyId: string, type: number) {
    return this.http.get(this.baseUrl + 'trashesByType/' + companyId + '?type=' + type)
  }
  // ********** trash either inventory item or container **********
  trashThing(id: string, type: number) {
    let model = {
      type : type,
      trashDate : new Date()
    }
    return this.http.put(this.baseUrl + 'trashThing/' + id , model)
  }
  // ********** restore either inventory item or container **********
  restoreThing(id: string, type: number) {
    return this.http.put(this.baseUrl + 'restoreThing/' + id + '/' + type, {})
  }
}

