<section id="items mb-2">
    <div class="tittle-section tittle-job-dashboard">
        <p><i class="fas fa-toolbox pr-1"></i> Job Dashboard</p>
        <!-- <div class=" btn-add-new" *ngIf="role == 'admin' " (click)="inviteManager()">
        <span class="badge badge-success"> <i class="fas fa-paper-plane"></i> Invite a Manager</span>
    </div> -->
    </div>
    <div class="section-clone" *ngIf="newJobPermission.itemStatus === 'Active' && newJobPermission.roleStatus === 'active'">
        <div class="tittle-section">
            <div class="btn-add-new" (click)="colneJobModal.show()">
                <span class="badge badge-success"> <i class="far fa-clone pr-1"></i> Clone Job</span>
            </div>
            <div class="clone-pin">
                <span class="pr-2"> Pin to Templates</span>
                <mat-slide-toggle (change)="handleChangeClone($event)" [checked]="jobObj?.isPinToClone"> </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="second-accordion mb-3">
        <mat-accordion class="example-headers-align" *ngIf="checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'active' || checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly'">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <span *ngIf="role == 'admin'"> Toolbox ({{itemsModel?.length || '0'}}) </span>
                    <span *ngIf="role != 'admin'"> Toolbox ({{countItemsShow || '0' }}) </span>
                    <!-- <div class="title-info">
                            <span>Total:9</span>
                        </div> -->
                </mat-expansion-panel-header>
                <div class="all-items">
                    <div class="item">
                        <!-- <a href="/admin/job-details#{{item.idAccess}}"> -->
                        <!-- <a> -->
                        <!-- <div class="item-content" *ngFor="let item of itemsModel;let i=index"> -->
                        <!-- <span> {{item.itemName}} </span>
                        <button class=" badge-light">Override</button>
                        <mat-slide-toggle *ngIf="item.show === true && checkPermission(toolJobs.ToolBox).roleStatus == 'active'" class="mat-checked pl-2" (click)="newMessage(i)"> </mat-slide-toggle>
                        <mat-slide-toggle *ngIf="item.show === false && checkPermission(toolJobs.ToolBox).roleStatus == 'active'" class="pl-2" (click)="newMessage(i)"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly'" class="pl-2"></mat-slide-toggle> -->
                        <div class="row item-content header-show">
                            <div class="col-4">
                                <span>Tool </span>
                            </div>
                            <div class="col-4" *ngIf="checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'active'">
                                <span>Override Default Permission</span>
                            </div>
                            <div class="col-4" *ngIf="checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly' ">
                            </div>
                            <div class="col-4">
                                <span>Status</span>
                                <div class="show-all">
                                    <mat-slide-toggle (change)="handleChange($event)" [checked]="isAllItemsActive"> </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let item of itemsModel;let i=index">
                            <div class="row item-content" *ngIf="role != 'admin' && getLayerPermission(item.itemId) != null && getLayerPermission(item.itemId).value != 0 ">
                                <div class="col-4">
                                    <span> {{item.itemName}} </span>
                                </div>
                                <div class="col-4" *ngIf="checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'active'">
                                    <button class="badge-light" (click)="permissionUsersShow(i)" [disabled]="item.planName > companyPlan" *ngIf="item.isUpdated == false">Override</button>
                                    <button class="badge-danger" (click)="permissionUsersShow(i)" [disabled]="item.planName > companyPlan" *ngIf="item.isUpdated == true">Customized</button>
                                </div>
                                <div class="col-4" *ngIf="checkPermission(toolJobs.ToolBox).itemStatus == 'Active' && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly'">
                                </div>
                                <div class="col-4">
                                    <button class="badge-light" *ngIf="item.planName > companyPlan" (click)="upgradePlan()">Upgrade</button>
                                    <mat-slide-toggle *ngIf="item.isShow === true && checkPermission(toolJobs.ToolBox).roleStatus == 'active' && item.planName <= companyPlan " class="mat-checked pl-2" (click)="changeShow(i)"> </mat-slide-toggle>
                                    <mat-slide-toggle *ngIf="item.isShow === false && checkPermission(toolJobs.ToolBox).roleStatus == 'active' && item.planName <= companyPlan " class="pl-2" (click)="changeShow(i)"></mat-slide-toggle>
                                    <mat-slide-toggle *ngIf="item.isShow == false && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly' || item.isShow == false && item.planName > companyPlan" [disabled]="true" class="pl-2"></mat-slide-toggle>
                                    <mat-slide-toggle *ngIf="item.isShow == true && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly' || item.isShow == true && item.planName > companyPlan" [disabled]="true" class="mat-checked pl-2"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="row item-content" *ngIf="role == 'admin'">
                                <div class="col-4">
                                    <span> {{item.itemName}} </span>
                                </div>
                                <div class="col-4">
                                    <button class="badge-light" (click)="permissionUsersShow(i)" [disabled]="item.planName > companyPlan" *ngIf="item.isUpdated == false">Override</button>
                                    <button class="badge-danger" (click)="permissionUsersShow(i)" [disabled]="item.planName > companyPlan" *ngIf="item.isUpdated == true">Customized</button>
                                </div>
                                <div class="col-4">
                                    <button class="badge-light" *ngIf="item.planName > companyPlan" (click)="upgradePlan()">Upgrade</button>
                                    <mat-slide-toggle *ngIf="item.isShow === true && checkPermission(toolJobs.ToolBox).roleStatus == 'active' && item.planName <= companyPlan " class="mat-checked pl-2" (click)="changeShow(i)"> </mat-slide-toggle>
                                    <mat-slide-toggle *ngIf="item.isShow === false && checkPermission(toolJobs.ToolBox).roleStatus == 'active' && item.planName <= companyPlan  " class="pl-2" (click)="changeShow(i)"></mat-slide-toggle>
                                    <mat-slide-toggle class="pl-2" *ngIf=" item.isShow == false && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly' || item.isShow == false && item.planName > companyPlan" [disabled]="true"></mat-slide-toggle>
                                    <mat-slide-toggle class="mat-checked pl-2" *ngIf=" item.isShow == true && checkPermission(toolJobs.ToolBox).roleStatus == 'readOnly' || item.isShow == true && item.planName > companyPlan" [disabled]="true"></mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="add-more-tools" *ngIf="role == 'admin' || role == 'manager'" (click)="routeToTools()">
                            <span><i class="uil uil-plus-circle "></i> Add more tools</span>
                        </div>
                        <!-- </div> -->
                        <!-- </a> -->
                    </div>
                </div>
                <div *ngIf="checkPermission(toolJobs.ToolBox).roleStatus == 'notActive'"></div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="section-tool-job" *ngIf="(jobInformationTool | json) != '{}' && jobInformationTool.value != statusActive.InActive &&  checkShow(jobInfoShow?.itemName) == true ">
        <div class="tittle-section">
            <p><i class="uil uil-file-info-alt uil-medium pr-1"></i> Job Information </p>
            <div class=" btn-add-new" *ngIf="carWashPermission?.value != statusActive.InActive && getDateAssigned(vehicle) ">
                <span class="badge badge-success" (click)="storeValuesToPrint(vehicle)">Receipt</span>
                <span class="badge badge-success ml-2" *ngIf="jobObj.jobCustomerObj?.email" (click)="sendModal()">Send</span>
            </div>
        </div>
        <div class="first-accordion inner-section">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <!-- <div class="tittle-section px-2"> -->
                        <p>#{{jobNumber}}</p>
                        <div class="mx-auto">
                            <p>{{jobType}}</p>
                        </div>
                        <!-- </div> -->
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-12">
                            <div class="tittle-section section_2  px-2">
                                <div class="primary">
                                    <small>Job Number</small>
                                </div>
                                <div class="badge badge-success">
                                    <label># {{jobNumber}}
                           <button class="edit-job-number" *ngIf="role != 'customer' && (jobInformationTool | json) != '{}'  && jobInformationTool.value == statusActive.Active  " (click)="editNumberModal.show()"><i class="far fa-edit"></i></button>
                        </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-1 mb-1">
                            <div class="tittle-section section_2">
                                <div class="primary">
                                    <small>job Type</small>
                                </div>
                                <div class="job-buttons">
                                    <div class="badge badge-success ">
                                        <label> {{jobType}}
                                  <button class="edit-job-number" *ngIf="role != 'customer' && (jobInformationTool | json) != '{}' && jobInformationTool.value == statusActive.Active  "(click)="editTypeModal.show()" >
                                      <i class="far fa-edit"  ></i>
                                  </button>
                               </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="templates" *ngIf="vehicle">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-4">
                                <span class="tittle plate-make ">
                          <span class="queue-plate-make">{{vehicle?.plateNumber}}</span>
                                </span>
                            </div>
                            <!-- <div class="col-4">
                      <span class="tittle plate-make text-capitalize">
                  <span>{{vehicle.vehicleType}}</span>
                      </span>
                  </div> -->
                            <div class="col-4">
                                <span class="tittle">
                             {{vehicle?.vehicleType}}
                          </span>
                            </div>
                            <div class="col-4">
                                <span class="tittle">
                          <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                                <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd"> <i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                </span>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="body">
                        <div class="header-vehicle mt-2">
                            <div class="added-by-date">
                                <div class="icons-vehicle">
                                    <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                        <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                        <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                        </small>
                                    <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                </div>
                                <div *ngIf="!vehicle.isSubscriptionEnd">
                                    <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt)
                                    | date:'M/d/yyyy'}} </span>
                                    <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                    </span>
                                    <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                          <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                    <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                         <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                          <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, y'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate | date: 'MMM
                                    d, y'}}
                                    </span>
                                    <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                          <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="section-checks">
                            <div class="button-group" *ngIf="getDateAssigned(vehicle)">
                                <div class="label-check">
                                    <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Assigned,</small> {{getDateAssigned(vehicle) | date : 'mediumDate'}} ({{getDateAssigned(vehicle) |  timePast}})</span>
                                </div>
                            </div>
                        </div>
                        <div class="info-vehicle">
                            <div class="item-vehicle" *ngIf="vehicle?.vehicleModel">
                                <p>Model</p>
                                <span>{{vehicle.vehicleModel}}</span>
                            </div>
                            <div class="item-vehicle" *ngIf="vehicle?.phone">
                                <p>Phone</p>
                                <span>{{vehicle.phone}}</span>
                            </div>
                            <div class="item-vehicle" *ngIf="vehicle?.condition">
                                <p>Condition</p>
                                <span>{{vehicle.condition}}</span>
                            </div>
                            <!-- <div class="services-block" *ngIf="(getService(vehicle) | json) != '{}'">
                                <h6 class="tittle-service">Services</h6>
                                <div class="assigned-users" *ngIf="getService(vehicle).usersSelected.length > 0">
                                    <p *ngFor="let userObj of getService(vehicle).usersSelected">
                                        <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                    </p>
                                </div>
                                <div class="others-normal-services">
                                    <div *ngIf="getService(vehicle).servicesNormalSelected.length > 0">
                                        <p *ngFor="let service of getService(vehicle).servicesNormalSelected">
                                            <strong>{{service.serviceName}}</strong>
                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                        </p>
                                    </div>
                                    <div *ngIf="getService(vehicle).servicesOthersSelected.length > 0">
                                        <p *ngFor="let service of getService(vehicle).servicesOthersSelected">
                                            <strong>{{service.serviceName}}</strong>
                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                        </p>
                                    </div>
                                    <hr>
                                    <div class="summary-services">
                                        <div>
                                            <p>
                                                <strong>Total Due</strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).totalDu + getService(vehicle).totalDueOthers}}</span>
                                            </p>
                                            <p>
                                                <strong>Received </strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).receivedAmount}}</span>
                                            </p>
                                            <p class="required">
                                                <strong>Cash Back</strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).cashBack}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="footer-vehicle fo-vehicle">
                            <div class="history-service">
                                <!-- <div class=" badge-light">
                              <button class="btn btn-sm " (click)="storeAssignedHistories(vehicle);historiesDetailingModal.show()"><i class="uil uil-history"></i> Log History</button>
                          </div> -->
                                <!-- <div class=" badge-light">
                              <button class="btn btn-sm " (click)="UnAssignVehicle(vehicle)">Unassign</button>
                          </div> -->
                                <!-- <div class=" badge-light">
                              <button class="btn btn-sm " (click)="getServices(vehicle,true)">Add/Edit Service</button>
                          </div> -->
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="templates" *ngIf="vehicle">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-4 d-flex justify-content-start">
                                <span class="tittle plate-make text-left mx-0">
                                     <span class="queue-plate-make ">Services</span>
                                </span>
                            </div>
                            <!-- <div class="col-4">
                    <span class="tittle plate-make text-capitalize">
                <span>{{vehicle.vehicleType}}</span>
                    </span>
                </div> -->
                            <!-- <div class="col-4">
                              <span class="tittle">
                           {{vehicle?.vehicleType}}
                        </span>
                          </div> -->
                            <!-- <div class="col-4">
                              <span class="tittle">
                        <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                              <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd"> <i class="uil uil-money-bill-slash uil-medium required"></i></span>
                              </span>
                          </div> -->
                        </div>
                    </mat-expansion-panel-header>
                    <div class="body">
                        <div class="header-vehicle mt-2">
                            <div class="added-by-date">
                                <!-- <div class="icons-vehicle">
                                  <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                      <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                      </small>
                                  <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                              </div> -->
                                <!-- <div *ngIf="!vehicle.isSubscriptionEnd">
                                  <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt)
                                  | date:'M/d/yyyy'}} </span>
                                  <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                    </span>
                                  <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                  <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                       <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                        <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, y'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate | date: 'MMM
                                  d, y'}}
                                  </span>
                                  <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                  </span>
                                  </span>
                              </div> -->
                            </div>
                        </div>
                        <!-- <div class="section-checks">
                          <div class="button-group" *ngIf="getDateAssigned(vehicle)">
                              <div class="label-check">
                                  <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Assigned,</small> {{getDateAssigned(vehicle) | date : 'mediumDate'}} ({{getDateAssigned(vehicle) |  timePast}})</span>
                              </div>
                          </div>
                      </div> -->
                        <div class="info-vehicle">
                            <!-- <div class="item-vehicle" *ngIf="vehicle?.vehicleModel">
                              <p>Model</p>
                              <span>{{vehicle.vehicleModel}}</span>
                          </div>
                          <div class="item-vehicle" *ngIf="vehicle?.phone">
                              <p>Phone</p>
                              <span>{{vehicle.phone}}</span>
                          </div>
                          <div class="item-vehicle" *ngIf="vehicle?.condition">
                              <p>Condition</p>
                              <span>{{vehicle.condition}}</span>
                          </div> -->
                            <div class="services-block" *ngIf="(getService(vehicle) | json) != '{}'">
                                <h6 class="tittle-service">Services</h6>
                                <div class="assigned-users" *ngIf="getService(vehicle).usersSelected.length > 0">
                                    <p *ngFor="let userObj of getService(vehicle).usersSelected">
                                        <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                    </p>
                                </div>
                                <div class="others-normal-services">
                                    <div *ngIf="getService(vehicle).servicesNormalSelected.length > 0">
                                        <p *ngFor="let service of getService(vehicle).servicesNormalSelected">
                                            <strong>{{service.serviceName}}</strong>
                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                        </p>
                                    </div>
                                    <div *ngIf="getService(vehicle).servicesOthersSelected.length > 0">
                                        <p *ngFor="let service of getService(vehicle).servicesOthersSelected">
                                            <strong>{{service.serviceName}}</strong>
                                            <span>({{getCurrency()}}) {{service.price}}</span>
                                        </p>
                                    </div>
                                    <hr>
                                    <div class="summary-services">
                                        <div>
                                            <p>
                                                <strong>Total Due</strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).totalDu + getService(vehicle).totalDueOthers}}</span>
                                            </p>
                                            <p>
                                                <strong>Received </strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).receivedAmount}}</span>
                                            </p>
                                            <p class="required">
                                                <strong>Cash Back</strong>
                                                <span>({{getCurrency()}}) {{getService(vehicle).cashBack}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-vehicle fo-vehicle">
                            <div class="history-service">
                                <div>
                                    <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Notify Customer </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready_to_Served,vehicle)"> Ready to serve vehicle</button>
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Job_Started,vehicle)">Job started</button>
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready,vehicle)">Vehicle is ready  </button>
                                        <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Service_Canceled,vehicle)">Service is canceled  </button>
                                    </mat-menu>
                                </div>
                                <div class=" badge-light">
                                    <button class="btn btn-sm " (click)="getServices(vehicle,true)">Add/Edit Service</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="section-tool-job" *ngIf="(customerInformationTool | json) != '{}' && customerInformationTool.value != statusActive.InActive && checkShow1(customerInfoShow?.itemName) == true   ">
        <div class="tittle-section">
            <p><i class="uil uil-user uil-medium pr-1"></i>Customer Information </p>
        </div>
        <div class="first-accordion inner-section">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <p *ngIf="jobObj.jobCustomerObj && jobObj.jobCustomerObj?.name != ''">{{jobObj.jobCustomerObj?.lastName}}, {{jobObj.jobCustomerObj?.firstName}} </p>
                        <p *ngIf="jobObj.jobCustomerObj && jobObj.jobCustomerObj?.name == ''">No Name Yet </p>
                        <div class="mx-auto">
                            <p *ngIf="jobObj.jobCustomerObj">{{jobObj.jobCustomerObj.customPersonType}}</p>
                            <p *ngIf="!jobObj.jobCustomerObj">No primary customer assigned yet </p>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-12" *ngIf="(customerInformationTool | json) != '{}' && customerInformationTool.value == statusActive.Active ">
                            <div class="tittle-section section_2 px-2">
                                <div class="primary">
                                    <small>Primary Customer:</small>
                                    <span>{{jobObj.jobCustomerObj?.lastName}}, {{jobObj.jobCustomerObj?.firstName}}</span>
                                </div>
                                <!-- <div>
                                    <select class="browser-default custom-select" (change)="storeId($event.target.value);changePrimaryModal.show()">
                                    <option disabled selected value="">{{primaryCustomerName}}</option>
                                    <option *ngFor="let customer of customersList" [value]="customer.id">
                                    <span>{{customer.name}}</span>
                                  </option>
                             </select>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-12" *ngIf="jobObj.jobCustomerObj">
                            <div class="tittle-section section_2">
                                <div class="primary">
                                    <small>Customer Type: </small> <span>{{jobObj.jobCustomerObj.customPersonType}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="jobObj.jobCustomerObj">
                            <div class="tittle-section section_2">
                                <div class="primary">
                                    <small>Phone : </small> <span>{{jobObj.jobCustomerObj.phone}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="jobObj.jobCustomerObj">
                            <div class="tittle-section section_2">
                                <div class="primary">
                                    <small>Email :</small> <span>{{jobObj.jobCustomerObj.email}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="no-items" *ngIf="countToolsActive <= 0 && role !='admin'">
        <p>You have no access to any add-in (tool) yet!</p>
    </div>
</section>
<!-- edit job number -->
<div mdbModal #editNumberModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Job Number </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="undoJobNumber();editNumberModal.hide()">
                      <span aria-hidden="true">&times;</span>
                     </button>
                </div>
                <div class="modal-body">
                    <form #jobNumberForm="ngForm" id="jobNumberForm">
                        <div class="form-group">
                            <input type="text" class="form-control" name="jobNumber" placeholder="Enter job number *" required #jobNumberValid="ngModel" [pattern]="numberPattern" (keyup)="isJobNumberExist($event.target.value)" [(ngModel)]="jobNumber">
                            <div *ngIf="jobNumberValid.errors && jobNumberValid.touched" class="alert alert-danger">
                                <div *ngIf="jobNumberValid.errors.required">
                                    Job Number required.
                                </div>
                                <div *ngIf="jobNumberValid.errors.pattern">
                                    Job Number not valid must only number.
                                </div>
                            </div>
                            <div class="alert alert-danger" *ngIf="isJobNumberExists">Number Item is Exists.</div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer text-center">
                    <button type="button " class="btn btn-sm btn-primary " data-dismiss="modal " (click)="undoJobNumber();editNumberModal.hide() ">Undo</button>
                    <button type="button " class="btn btn-sm btn-success " form="jobNumberForm" [disabled]="jobNumberForm.invalid || isJobNumberExists" (click)="updateJobNumber();editNumberModal.hide() ">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- edit job type -->
<div mdbModal #editTypeModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Job Type </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editTypeModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <form #typeForm='ngForm' id="typeForm">
                        <input type="text" name="typeJob" [(ngModel)]="typeJob" required class="form-control" placeholder="Enter job type *" minlength="1" maxlength="12" #uname="ngModel">
                        <div *ngIf="uname.errors">
                            <div *ngIf="uname.errors.minlength" class="alert alert-danger">
                                Limited to 12 charactersز
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer text-center">
                    <!-- <button type="button " class="btn btn-sm btn-primary " data-dismiss="modal " (click)="editTypeModal.hide() ">Undo</button> -->
                    <button type="button " class="btn btn-sm btn-success " form="typeForm" [disabled]="typeForm.invalid " (click)="changeTypeJob(typeForm);editTypeModal.hide() ">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- overide or customized -->
<div mdbModal #permissionsModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Only For This Job </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="permissionsModal.hide()">
                      <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="modal-body" *ngIf="users.length > 0">
                    <p>Override Default Permission Settings</p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Assigned User</th>
                                <th scope="col">Role</th>
                                <th scope="col">Defualt</th>
                                <th scope="col">Custom</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users; let j=index">
                                <th scope="row">{{user.lastName}},{{user.firstName}}{{checkItemInCustomer(itemTool,user.role)}}</th>
                                <td>{{user.role}}</td>
                                <td>
                                    <div *ngIf="user?.defaultPermission">
                                        <span *ngIf="user?.defaultPermission.value == 0">No Access</span>
                                        <span *ngIf="user?.defaultPermission.value == 2">View Only</span>
                                        <span *ngIf="user?.defaultPermission.value == 1">Can Edit</span>
                                    </div>
                                    <span *ngIf="!user?.defaultPermission">No Access</span>
                                </td>
                                <td>
                                    <div *ngIf="user.permission">
                                        <select #mySelect1 value="user?.permission.value" [(ngModel)]="user?.permission.value" class="form-control" (change)="changeUserPermission(mySelect1.value,user.id)">
                                      <option value="0">No Access</option>
                                      <option value="2">View Only</option>
                                      <option value="1" *ngIf="isCanEdit">Can Edit</option>
                                   </select>
                                    </div>
                                    <span *ngIf="!user.permission"> No Access </span>
                                    <!-- <select #mySelect3 value="user.permission.value" *ngIf="" [(ngModel)]="user.permission.value" class="form-control"
                                        (change)="changeUserPermission(mySelect3.value,user.id)">
                                      <option value="0">No Access</option>
                                      <option value="2">View Only</option>
                                   </select> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ant-empty-image" *ngIf="users.length <= 0">
                    <img src="./assets/img/empty.svg" alt="empty-img">
                    <span class="mb-2">No Assigned Users or Customers Yet!</span>
                </div>
                <div class="modal-footer text-center" *ngIf="users.length > 0">
                    <button type="button " class="btn btn-sm btn-success " form="typeForm" (click)="refreshPage();permissionsModal.hide()">Done</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- colne job -->
<div mdbModal #colneJobModal="mdbModal" class="modal fade">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Clone Job </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="colneJobModal.hide()">
                         <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                <div class="modal-body">
                    <div class="text-left">
                        <span>When pinned, the user can clone this job from the [Template] button.</span> <br>
                        <span> - When cloned:</span> <br>
                        <span>1- The following tools will have the same information:</span> <br>
                        <span>- Customer information </span> <br>
                        <span>- Assigned Vehicle.</span> <br>
                        <span>- Location</span> <br>
                        <span>2 - All other tools will have the default information.</span>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button " class="btn btn-sm btn-danger " (click)="colneJobModal.hide()">Cancel</button>
                    <button type="button " class="btn btn-sm btn-success " (click)="coloneJob();colneJobModal.hide()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Conforamation primary customer -->
<div mdbModal id="changePrimaryModal" #changePrimaryModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();changePrimaryModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Assign as primary customer? </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="changePrimaryModal.hide() "> No </button>
                    <button type="button " class="btn btn-sm btn-success " (click)="checkAddSendEmail();changePrimaryModal.hide()"> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- send email ? -->
<div mdbModal id="changePrimarySendModal" #changePrimarySendModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="isNotSendCustomer();changePrimarySendModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Notify Customer Via Email? </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-danger " data-dismiss="modal " (click)="isNotSendCustomer();updateJobName(customerId);changePrimarySendModal.hide() "> No </button>
                    <button type="button " class="btn  btn-sm btn-success " (click)="updateJobName(customerId);changePrimarySendModal.hide()"> Yes </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- upgrad plan -->
<div mdbModal id="upgradePlanModal" #upgradePlanModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">You have no access </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="upgradePlanModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="text-secondary">Only users who have access to the company account can change the company plan</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Pin Clone Modal -->
<!-- upgrad plan -->
<div mdbModal id="clonePinModal" #clonePinModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <span *ngIf="jobObj.isPinToClone">Pin to Quick Clone</span>
                        <span *ngIf="!jobObj.isPinToClone">Unpin</span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="clonePinModal.hide()">
                     <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body ">
                    <div class="text-left" *ngIf="jobObj.isPinToClone">
                        <span>When pinned, the user can clone this job from the [Template] button.</span> <br>
                        <span> - When cloned:</span> <br>
                        <span>1- The following tools will have the same information:</span> <br>
                        <span>- Customer information </span> <br>
                        <span>- Assigned Vehicle.</span> <br>
                        <span>- Location</span> <br>
                        <span>2 - All other tools will have the default information.</span>
                    </div>
                    <div class="text-center" *ngIf="!jobObj.isPinToClone">Unpin from template</div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-success" (click)="pinCloneJob();clonePinModal.hide()"> Confirm </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile [isPrint]='isPrint'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Print Receipt  -->
<div mdbModal #printReceiptModal="mdbModal" class="modal assign-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                <span >Print Receipt </span>
            </strong>
                    <button type="button" class="close" (click)="printReceiptModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body ">
                    <div id="receipt_id">
                        <div class="tittle-invoice">
                            <h1>Invoice</h1>
                            <span><strong>Date of Issue :</strong> {{dateObj | date: 'MMM d, y, h:mm a'}}</span>
                        </div>
                        <div class="item">
                            <div class="first-side">
                                <img src="{{currentCompany.companyLogo}}" alt="logo-img"> <br>
                            </div>
                            <div class="second-side">
                                <div class="info">
                                    <p>{{currentCompany.companyName}}</p>
                                    <p class="address-small">{{currentCompany.companyAddress.addressOne}} <span *ngIf="currentCompany.companyAddress.addressTow != ''">, {{currentCompany.companyAddress.addressTow}}</span>, {{currentCompany.companyAddress.city}}, {{currentCompany.companyAddress.state}}
                                        {{currentCompany.companyAddress.zip}}
                                    </p>
                                    <p *ngIf="user.companyPhone">{{user.companyPhone}} </p>
                                    <p class="mb-0" *ngIf="user.companyWebsite">{{user.companyWebsite}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="services-block">
                            <div class="tittle-service vehicle-info">
                                <h5>Job Information</h5>
                                <!-- <strong>{{servicesObj?.date | date: 'MMM d, y'}}</strong> -->
                            </div>
                            <div class="others-normal-services">
                                <div>
                                    <p>
                                        <strong>Job Number</strong>
                                        <span>#{{jobNumber}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <strong>Job Type </strong>
                                        <span>{{jobType}}</span>
                                    </p>
                                </div>
                                <div *ngIf="vehicle?.vehicleModel">
                                    <p>
                                        <strong>Job Status</strong>
                                        <span>{{jobObj?.jobStatus}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="services-block">
                            <div class="tittle-service vehicle-info">
                                <h5>Vehicle Information</h5>
                                <!-- <strong>{{servicesObj?.date | date: 'MMM d, y'}}</strong> -->
                            </div>
                            <div class="others-normal-services">
                                <div>
                                    <p>
                                        <strong>Plate Number</strong>
                                        <span>{{bufferVehicle.plateNumber}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <strong>Make</strong>
                                        <span>{{bufferVehicle.vehicleType}}</span>
                                    </p>
                                </div>
                                <div *ngIf="bufferVehicle?.vehicleModel">
                                    <p>
                                        <strong>Model</strong>
                                        <span>{{bufferVehicle.vehicleModel}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="services-block">
                            <div class="tittle-service">
                                <h5>Services</h5>
                                <strong>{{servicesObj?.date | date: 'MMM d, y'}}</strong>
                            </div>
                            <div class="others-normal-services">
                                <div *ngIf="servicesObj.servicesNormalSelected?.length > 0">
                                    <p *ngFor="let service of servicesObj.servicesNormalSelected">
                                        <strong>{{service.serviceName}}</strong>
                                        <span>({{getCurrency()}}) {{service.price}}</span>
                                    </p>
                                </div>
                                <div *ngIf="servicesObj.servicesOthersSelected?.length > 0">
                                    <p *ngFor="let service of servicesObj.servicesOthersSelected">
                                        <strong>{{service.serviceName}}</strong>
                                        <span>({{getCurrency()}}) {{service.price}}</span>
                                    </p>
                                </div>
                                <hr>
                                <div class="summary-services">
                                    <div>
                                        <p>
                                            <strong>Total Due</strong>
                                            <span>({{getCurrency()}}) {{servicesObj.totalDu + servicesObj.totalDueOthers}}</span>
                                        </p>
                                        <p>
                                            <strong>Received </strong>
                                            <span>({{getCurrency()}}) {{servicesObj.receivedAmount}}</span>
                                        </p>
                                        <p class="required">
                                            <strong>Cash Back</strong>
                                            <span>({{getCurrency()}}) {{servicesObj.cashBack}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Send Email  -->
<div mdbModal #inventorySendModal="mdbModal" class="modal tag-modal">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Send the invoice attached as a PDF via email</h6>
                    <button type="button" class="close" (click)="inventorySendModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                    <form #sendForm="ngForm" id="sendForm">
                        <!-- <input type="email" id="emailSend" name="email" required [(ngModel)]="modelSendItem.email" class="form-control" placeholder="Email address " [pattern]="emailPattern" #emailSend="ngModel" /> -->
                        <!-- <div *ngIf="emailSend.errors && emailSend.touched" class="alert alert-danger">
                            <div *ngIf="emailSend.errors.required">
                                Email Cusotmer required.
                            </div>
                            <div *ngIf="emailSend.errors.pattern">
                                Email Cusotmer not valid.
                            </div>
                        </div> -->
                        <tag-input [(ngModel)]='tags' [separatorKeyCodes]="[32]" [modelAsStrings]="true" name="tags" [placeholder]="'Enter another email'" [secondaryPlaceholder]="'CC:'"></tag-input>
                        <input type="text" id="subject" name="subject" required [(ngModel)]="modelSendItem.subject" class="form-control" placeholder="Email subject " #subject="ngModel" />
                        <div *ngIf="subject.errors && subject.touched" class="alert alert-danger">
                            <div *ngIf="subject.errors.required">
                                Subject required.
                            </div>
                        </div>
                        <div contenteditable [innerHtml]="bodySend | safeHtml" (input)="onNameChange($event.target.innerHTML)" class="form-control body-send"></div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-primary" form="sendForm" [disabled]='sendForm.invalid' (click)="sendEmailItems(sendForm);inventorySendModal.hide()">Send</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #noPhoneModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                  <span >Can't send message</span>
              </strong>
                    <button type="button" class="close" (click)="noPhoneModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Add the phone number first</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="noPhoneModal.hide() ">close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #sendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                    <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Service_Canceled">Service is canceled</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Job_Started">Job started</span>
                </strong>
                    <button type="button" class="close" (click)="sendMessageModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Send Message</p>
                </div>
                <div class="modal-footer">
                    <button type="button " class="btn btn-sm btn-light" (click)="sendMessageModal.hide() ">cancel</button>
                    <button type="button " class="btn btn-sm btn-success" (click)="sendMessageModal.hide();sendSMSMessage() ">send</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Assign  -->
<div mdbModal #assignModal="mdbModal" class="modal assign-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                  <span >Add/Edit Service </span>
              </strong>
                    <button type="button" class="close" (click)="assignModal.hide();resetServices()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body ">
                    <!-- <h6>Assign Crew</h6> -->
                    <!-- <form>
                        <div class="form-group muli-select">
                            <label>Crew Name:</label>
                            <mat-form-field appearance="outline">
                                <mat-select placeholder="user name" name="userObj" [compareWith]="equalsUsers" [(ngModel)]="selectedUsers" multiple>
                                    <mat-option *ngFor="let userObj of usersToAssigned" [value]="userObj">{{userObj.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="selectedUsers.length > 0">
                            <ul class="list-group" *ngFor="let user of selectedUsers; let i=index">
                                <li class="list-group-item user-item  list-group-item-action">
                                    <span><i class="uil uil-check-circle success"></i>  {{user.name}}</span>
                                </li>
                            </ul>
                        </div>
                    </form> -->
                    <h6>Select Services</h6>
                    <div class="servies-normal" *ngFor="let service of carWashServices;let j=index">
                        <mat-accordion *ngIf="(isChecked(service) && service.isAchieve) || !service.isAchieve">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <div class="header-service">
                                        <span>
                                    <input type="checkbox" id="" name="" (change)="handleChangeVehicle($event,service,null)" [checked]="isChecked(service)"> {{service.serviceName}}
                                    <span *ngIf="checkServiceName(service)">({{checkServiceName(service)}})</span>
                                        </span>
                                        <small *ngIf="isCheckedSizePrice(service)">( {{isCheckedSizePrice(service).size}} | ({{getCurrency()}}) {{isCheckedSizePrice(service).price}} )</small>
                                    </div>
                                </mat-expansion-panel-header>
                                <ul class="list-group">
                                    <li *ngFor="let item of service.sizes; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                        <span>
                                       <input type="radio" id="{{service.serviceName}}" [value]="item['size' + i ]"  [checked]="isCheckedSize(service) == item['size' + i ]"  name="{{service.serviceName}}" (change)="selectedNormalService($event,item,service,i)"  >
                                       {{item['size' + i ]}}
                                     </span>
                                        <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{item['price' + i ]}}</span>
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <h6>Select Other Services</h6>
                    <div class="servies-others">
                        <ul class="list-group" *ngFor="let service of additionalServices; let i=index">
                            <li [ngClass]="{'selected-item': selectedPackageIndex1 == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                <span>
                               <input type="checkbox" id="service.serviceName"  name="service.serviceName" (change)="selectedOtherService($event,service);selectItemPackage1(i)" value="service.serviceName" [checked]="isCheckedOther(service)">
                               {{service.serviceName}}
                             </span>
                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{service.price}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="summary">
                        <p><strong>Total due</strong> ({{getCurrency()}}) {{totalDuNormal + totalDueOthers > 0 ? totalDuNormal + totalDueOthers : 0}}</p>
                        <p> <strong>Received</strong>
                            <input type="number" class="form-control w-25" value="{{receivedAmount}}" min="0" (input)="changeReceivedAmount($event.target.value)">
                        </p>
                        <p> <strong>Cash Back</strong> ({{getCurrency()}}) {{getCashBack()}}</p>
                        <span *ngIf="isDisabled" class="alert alert-danger">The received amount must be equal to or greater than the due amount.</span>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-sm btn-danger" (click)="assignModal.hide();resetServices() ">close</button>
                    <button type="button" class="btn btn-sm btn-success" [disabled]="isDisabled" (click)="assignedService();assignModal.hide() ">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--   denied Send Message -->
<div mdbModal #notSendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                    <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Service_Canceled">Service is canceled</span>
                    <span *ngIf="smsType == smsNotification.Car_Wash_Job_Started">Job started</span>
                </strong>
                    <button type="button" class="close" (click)="notSendMessageModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Sending this notification is inactive in the Add-Ins Store > Assigned Vehicle </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="notSendMessageModal.hide() ">cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>