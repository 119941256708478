import { GlobalService } from 'src/app/core/_services/global/global.service';
import { AuthService } from 'src/app/core/_services/auth/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-invite',
  templateUrl: './second-invite.component.html',
  styleUrls: ['./second-invite.component.scss']
})
export class SecondInviteComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _globalService: GlobalService
    ) { }

  ngOnInit() {
  }
  login() {
    this._globalService.logout()
  }
}
