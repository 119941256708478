<div class="card section-tool-job" *ngIf="userPermission?.value != statusActive.InActive ">
    <div class="tittle-section">
        <p>
            <i class="uil uil-car-wash uil-medium pr-1"></i>
            <span>Assigned Vehicle</span>
        </p>
        <div class=" btn-add-new" (click)="redirection()" *ngIf="userPermission?.value == statusActive.Active || user.role == 'admin' ">
            <span class="badge badge-success">Add New</span>
        </div>
    </div>
    <div class="templates inner-section">
        <mat-tab-group [selectedIndex]="selectedIndex">
            <mat-tab label="Assign">
                <!-- <div class="row search-qr-scan">
                    <div class="col-lg-12">
                        <div class="form-group mb-2 mt-2" *ngIf="vehicles.length > 0">
                            <input type="text" id="search-text" class="form-control border-serach" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Plate Number / #VIN( Last 4 Digits) " />
                        </div>
                    </div>
                </div> -->
                <div class="row search-qr-scan">
                    <div class="col-12">
                        <div class="form-group" *ngIf="vehicles.length > 0">
                            <input type="text" id="search-text" class="form-control" aria-describedby="search-text" (keydown)="restPagination($event)" [(ngModel)]="searchText" placeholder="Search Plate / Make / #VIN (Last 4 digits)" />
                        </div>
                    </div>
                    <div class="col-12 " (click)="cameraScanModal.show();openCamera()" *ngIf="vehicles.length > 0 && isMobile">
                        <div class="btn btn-sm btn-primary">
                            <p> <i class="uil uil-camera"></i> Capture Plate </p>
                        </div>
                    </div>
                    <!-- <div class="col-12 " (click)="searchQR()" *ngIf="vehicles.length > 0 && isMobile">
                      <div class="btn btn-sm btn-primary">
                          <p> <i class="uil uil-qrcode-scan "></i> Scan QR Code</p>
                      </div>
                  </div> -->
                </div>
                <div class="header-member" *ngIf="vehicles.length > 0">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <div class="row ">
                                    <div class="col-4">
                                        <span class="tittle ">
                                          <span>Plate / #VIN <strong >({{vehicles.length}})</strong></span>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                            <span>Make</span>
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                             <span >M. Status <strong> ({{countMemberShip}})</strong></span>
                                        </span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <mat-accordion *ngFor="let vehicle of vehicles | vehicleFilter: searchText | paginate: {id: 'vehicle_id' , itemsPerPage: itemsPerPage, currentPage: p }; let i=index ">
                    <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                        <mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-4">
                                    <span class="tittle plate-make">
                                        <span>{{vehicle.plateNumber}}</span>
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="tittle plate-make">
                                       <span>{{vehicle.vehicleType}}</span>
                                    </span>
                                </div>
                                <div class="col-4">
                                    <span class="tittle">
                                      <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                                    <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd"> <i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                    </span>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="body">
                            <div class="header-vehicle mt-2">
                                <div class="added-by-date">
                                    <!-- <details>
                                    <summary><strong>Branch Name:</strong> {{vehicle?.branchName}}</summary>
                                    <p class="mt-1"> <strong>Added By : </strong> {{vehicle.addedBy}} </p>
                                </details> -->
                                    <!-- <div class="badge badge-light" (click)="storeHistories(vehicle);historiesDetailingModal.show()">
                                    <span>Edit History</span>
                                </div> -->
                                    <!-- <span>{{vehicle.date | date : 'MMM d,  y'}}, {{vehicle.creationTime}}</span> -->
                                    <div class="icons-vehicle">
                                        <!-- <small [ngClass]="[vehicle.id != '' ? 'icon-active' : 'icon-not-active']"><i class="fas fa-car"></i></small> -->
                                        <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                    <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                    <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                    <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                    </small>
                                        <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                    </div>
                                    <div *ngIf="!vehicle.isSubscriptionEnd">
                                        <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span> {{timeConverter(vehicle.cancelAt)
                                        | date:'M/d/yyyy'}} </span>
                                        <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                        </span>
                                        <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                                      <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                        <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                                     <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                                      <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, yy'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate
                                        | date: 'MMM d, yy'}}
                                        </span>
                                        <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                                      <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                        </span>
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="button-group" *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && userPermission?.value != 'notActive'  && user.role != 'admin'">
                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                            </div> -->
                                <!-- <div class="button-group" *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.countPackage != 0 && userPermission?.value != 'notActive' && user.role != 'admin'">
                                <span><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                            </div> -->
                            </div>
                            <div class="section-checks" *ngIf="(assignedVehicles.length <= 0 || isExistsInVehicles(vehicle) ) && userPermission?.value == statusActive.Active">
                                <div class="button-group" *ngIf="isCheckAssign(vehicle) == false ">
                                    <div class="label-check" *ngIf="getDateAssigned(vehicle)">
                                        <span><small class=""><i class="uil uil-clock-three uil-extra-small" ></i> Assigned,</small> {{getDateAssigned(vehicle) | date : 'mediumDate'}} ({{getDateAssigned(vehicle)  |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" (click)="assignVehicle(vehicle) ">Assign   </button>
                                        <button class="btn btn-sm btn-danger-custom" [disabled]="true">Assigned</button>
                                    </div>
                                    <!-- <div> -->
                                    <!-- <div class="item-check"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_In_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- <div class="item-check item-check-out disabled-option"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_Out_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- </div> -->
                                    <!-- <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span>
                                  <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Forever</span> </span>
                                  <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span> -->
                                </div>
                                <div class="button-group" *ngIf="isCheckAssign(vehicle) == true  ">
                                    <div class="label-check" *ngIf="getDateAssigned(vehicle)">
                                        <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Assigned,</small> {{getDateAssigned(vehicle) | date : 'mediumDate'}} ({{getDateAssigned(vehicle)  |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" [disabled]="true">Assign </button>
                                        <button class="btn btn-sm btn-danger-custom" (click)="UnAssignVehicle(vehicle)">Assigned </button>
                                    </div>
                                    <!-- <div> -->
                                    <!-- <div class="item-check disabled-option"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_In_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- <div class="item-check item-check-out"> -->
                                    <!-- <small>{{getLastCheckIn(vehicle,historyCarWash.Check_Out_Action) | date : 'short'}}</small> -->
                                    <!-- </div> -->
                                    <!-- </div> -->
                                    <!-- <span *ngIf="vehicle.countPackageBase && vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                  <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Forever</span> </span>
                                  <span *ngIf="vehicle.cancelAt  && !vehicle.countPackageBase && !vehicle.countPackage"><span class="required"><i class="uil uil-clock-three uil-small"></i> Cancels</span> {{timeConverter(vehicle.cancelAt) | date: 'M/d/yyyy'}}</span> -->
                                </div>
                            </div>
                            <div class="info-vehicle">
                                <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                    <p>Model</p>
                                    <span>{{vehicle.vehicleModel}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.phone">
                                    <p>Phone</p>
                                    <span>{{vehicle.phone}}</span>
                                </div>
                                <div class="item-vehicle" *ngIf="vehicle?.condition">
                                    <p>Condition</p>
                                    <span>{{vehicle.condition}}</span>
                                </div>
                            </div>
                            <div class="footer-vehicle" *ngIf="userPermission?.value == statusActive.Active || user.role == 'admin' ">
                                <div>
                                    <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Actions </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="selectAction(actionsCar.Edit_Vehicle_Profile,vehicle)"> <i class="uil uil-edit"></i>Edit vehicle Profile</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Qr_Code,vehicle)" *ngIf="user.companyPlan == companyPlan.Premium">  <i class="uil uil-qrcode-scan "></i>
                                        <ng-container  *ngIf="!vehicle.qrCodeNumber">Assign</ng-container>
                                        <ng-container  *ngIf="vehicle.qrCodeNumber">Re-Assign</ng-container> a QR Code
                                      </button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Create_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip"><i class="uil  uil-credit-card"></i>Create card subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.View_Card_Subscription,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.card"><i class="uil uil-credit-card"></i> Edit/View card subscription <i class="uil uil-external-link-alt uil-size"></i> </button>
                                        <!-- <button mat-menu-item (click)="selectAction(actionsCar.View_Cash_Subscription,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.cash"><i class="uil uil-dollar-sign-alt"></i> Edit/View cash subscription <i class="uil uil-external-link-alt uil-size"></i> </button> -->
                                        <button mat-menu-item (click)="selectAction(actionsCar.Create_Cash_Subscription,vehicle)" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip"><i class="uil uil-dollar-sign"></i>Create cash subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Force_Plan_Change,vehicle)" *ngIf="vehicle.countPackageBase && vehicle.countPackage"><i class="uil uil-sync-slash uil-small"></i> Force plan change</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Delete_Vehicle_Profile,vehicle)" *ngIf="!vehicle.isMemberShip && (!vehicle.stripeObj || !vehicle.stripeObj.subscriptionId)"> <i class="uil uil-trash-alt"></i>Delete vehicle profile</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Disabled_Payment_Type,vehicle)" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd && vehicle.paymentType == paymentTypeEnum.card"><i class="uil uil-dollar-sign"></i>Create cash subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Disabled_Payment_Type,vehicle)" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd && vehicle.paymentType == paymentTypeEnum.cash"><i class="uil  uil-credit-card"></i>Create card subscription</button>
                                        <button mat-menu-item (click)="selectAction(actionsCar.Customer_Route,vehicle)" *ngIf="vehicle.isMemberShip  && vehicle.paymentType == paymentTypeEnum.card && (customerObj.roleStatus == statusActive.Active || role == 'admin') "><i class="uil uil-user"></i>Vehicle Owner</button>
                                    </mat-menu>
                                </div>
                                <div class="history-service">
                                    <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="storeAssignedHistories(vehicle);historiesDetailingModal.show()"><i class="uil uil-history"></i> Log History</button>
                                    </div>
                                    <!-- <div class=" badge-light">
                                        <button class="btn btn-sm " (click)="getVehicleStaticsYear(vehicle.id)"><i class="uil uil-history"></i>  Service History</button>
                                    </div> -->
                                </div>
                                <!-- <button class="btn btn-sm btn-success-custom" (click)="storeVehicle(vehicle,vehicle.paymentType)">Edit</button> -->
                                <!-- <div class="button-group"> -->
                                <!-- <button class="btn btn-sm btn-custom" *ngIf="(!vehicle.countPackageBase && !vehicle.countPackage ||  vehicle.countPackageBase && vehicle.countPackage > 0) && vehicle.isMemberShip && userPermission?.value == 'active'   || (!vehicle.countPackageBase && !vehicle.countPackage ||  vehicle.countPackageBase && vehicle.countPackage > 0) && vehicle.isMemberShip && user.role == 'admin' "
                                  (click)="createPortalCustomerStripeCustom(vehicle)">
                                  View Subscription
                               </button> -->
                                <!-- <button class="btn btn-sm btn-success" *ngIf="!vehicle.isMemberShip && userPermission?.value == 'active' || !vehicle.isMemberShip && user.role == 'admin'" (click)="createMemberShip(vehicle.id)">
                                Create Subscription
                             </button> -->
                                <!-- <button class="btn btn-sm btn-primary" *ngIf="!vehicle.cancelAt && vehicle.isMemberShip && vehicle.countPackageBase && vehicle.countPackage == 0 && userPermission?.value == 'active' || !vehicle.cancelAt  && vehicle.isMemberShip && vehicle.countPackageBase && vehicle.countPackage == 0 && user.role == 'admin' " (click)="renewMembership(vehicle)"> Renew Subscription</button> -->
                                <!-- <button class="btn btn-sm btn-primary" (click)="getVehicleStaticsYear(vehicle.id)">
                                 Service Statistics
                               </button> -->
                                <!-- <button class="btn btn-sm btn-danger-custom" *ngIf="!vehicle.isMemberShip && (!vehicle.stripeObj || !vehicle.stripeObj.subscriptionId)" (click)="storeBuffVehicle(vehicle);deleteVehicleModal.show()">Delete Vehicle</button> -->
                                <!-- <button class="btn btn-sm btn-danger-custom" *ngIf="vehicle.countPackageBase && vehicle.countPackage" (click)="storeBuffVehicle(vehicle);reNewMembershipModal.show()">Force Plan change</button> -->
                                <!-- </div> -->
                                <!-- <div class="create-membership" *ngIf="vehicle.isMemberShip && vehicle.isSubscriptionEnd || !vehicle.isMemberShip">
                                  <div class="buttons-membership">
                                      <button class="btn btn-sm btn-light-custom" (click)="storeVehicle(vehicle,paymentTypeEnum.card)"><i class="far fa-plus-square "></i> Card Subscription</button>
                                      <button class="btn btn-sm btn-light-custom" (click)="storeVehicle(vehicle,paymentTypeEnum.cash)"><i class="far fa-plus-square "></i> Cash Subscription</button>
                                  </div>
                              </div> -->
                                <!-- <div class="create-membership" *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd">
                                  <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.card">
                                      <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Card Subscription  <i class="uil uil-external-link-alt "></i></button>
                                      <button class="btn btn-sm btn-sm btn-light-custom" (click)="changeDenied(false);deniedModal.show()"><i class="far fa-plus-square "></i> Cash Subscription</button>
                                  </div>
                                  <div class="buttons-membership" *ngIf="vehicle.paymentType == paymentTypeEnum.cash">
                                      <button class="btn btn-sm btn-light-custom" (click)="changeDenied(true);deniedModal.show()"><i class="far fa-plus-square "></i> Card Subscription</button>
                                      <button class="btn btn-sm btn-info-custom" (click)="createPortalCustomerStripeCustom(vehicle)">Cash Subscription  <i class="uil uil-external-link-alt  "></i></button>
                                  </div>
                              </div> -->
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="table-footer text-center mt-3" *ngIf="vehicles.length > 0">
                    <pagination-controls previousLabel="" nextLabel="" id="vehicle_id" (pageChange)="handlingMembersPagination($event) "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="vehicles.length <= 0">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </mat-tab>
            <mat-tab label="Assigned Vehicle">
                <div class="templates">
                    <div class="header-member" *ngIf="assignVehicles.length > 0">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <div class="row ">
                                        <div class="col-4">
                                            <span class="tittle ">
                                                <span>Plate / #VIN</span>
                                            </span>
                                        </div>
                                        <!-- <div class="col-4">
                                        <span class="tittle">
                                    <span>Make</span>
                                        </span>
                                    </div> -->
                                        <div class="col-4">
                                            <span class="tittle">
                                              <span >Make </span>
                                            </span>
                                        </div>
                                        <div class="col-4">
                                            <span class="tittle">
                                          <span>M. Status</span>
                                            </span>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <mat-accordion *ngFor="let vehicle of assignVehicles | vehicleFilter: searchText4 | paginate: {id: 'assign_id' , itemsPerPage: itemsPerPage, currentPage: p5 }; let i=index; let first = first ">
                        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)">
                            <mat-expansion-panel-header>
                                <div class="row">
                                    <div class="col-4">
                                        <span class="tittle plate-make ">
                                        <span class="queue-plate-make">{{vehicle.plateNumber}}</span>
                                        </span>
                                    </div>
                                    <!-- <div class="col-4">
                                    <span class="tittle plate-make text-capitalize">
                                <span>{{vehicle.vehicleType}}</span>
                                    </span>
                                </div> -->
                                    <div class="col-4">
                                        <span class="tittle">
                                           {{vehicle.vehicleType}}
                                        </span>
                                    </div>
                                    <div class="col-4">
                                        <span class="tittle">
                                        <span *ngIf="vehicle.isMemberShip && !vehicle.isSubscriptionEnd"> {{vehicle.stripeObj.planName}}</span>
                                        <span *ngIf="!vehicle.isMemberShip || vehicle.isSubscriptionEnd"> <i class="uil uil-money-bill-slash uil-medium required"></i></span>
                                        </span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div class="body">
                                <div class="header-vehicle mt-2">
                                    <div class="added-by-date">
                                        <div class="icons-vehicle">
                                            <small [ngClass]="[vehicle.isMemberShip && !vehicle.isSubscriptionEnd ? 'icon-active' : 'icon-not-active']">
                                      <i *ngIf="vehicle.paymentType == undefined" class="uil uil-credit-card uil-extra-small"></i>
                                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == paymentTypeEnum.card " class="uil uil-credit-card uil-extra-small"></i>
                                      <i *ngIf="vehicle.paymentType != undefined && vehicle.paymentType == 0 " class="uil uil-dollar-sign"></i>
                                      </small>
                                            <small [ngClass]="[vehicle.qrCodeNumber != 0 ? 'icon-active' : 'icon-not-active']"><i class="fas fa-qrcode"></i></small>
                                        </div>
                                        <div *ngIf="!vehicle.isSubscriptionEnd">
                                            <span *ngIf="vehicle.cancelAt && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card "><span class="required"><i class="far fa-calendar-times "></i> Cancels</span>                                            {{timeConverter(vehicle.cancelAt) | date:'M/d/yyyy'}} </span>
                                            <span *ngIf="vehicle.isMemberShip && vehicle.isForever && !vehicle.countPackageBase && !vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card"><span class="required"> <i class="fas fa-calendar-day"></i> Subscription</span>                                            </span>
                                            <span *ngIf="vehicle.countPackageBase && vehicle.countPackage && vehicle.paymentType == paymentTypeEnum.card">
                                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}</span>
                                            <span *ngIf="vehicle.paymentType == paymentTypeEnum.cash && vehicle.cashMembershipInfo">
                                       <span *ngIf="!vehicle.cashMembershipInfo.isPackage">
                                        <span class="required"> </span> {{vehicle.cashMembershipInfo.startDateFormate | date: 'MMM d, y'}} <strong class="success">to</strong> {{vehicle.cashMembershipInfo.endDateFormate
                                            | date: 'MMM d, y'}}
                                            </span>
                                            <span *ngIf="vehicle.cashMembershipInfo.isPackage">
                                        <span class="required"><i class="fas fa-hourglass-half"></i> Remaining</span> {{vehicle.countPackage}} / {{vehicle.countPackageBase}}
                                            </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-checks">
                                    <div class="button-group" *ngIf="userPermission?.value == statusActive.Active  || isCheckInVehicle(vehicle.id) == false && user.role == 'admin' ">
                                        <div class="label-check" *ngIf="getDateAssigned(vehicle)">
                                            <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Assigned,</small> {{getDateAssigned(vehicle) | date : 'mediumDate'}} ({{getDateAssigned(vehicle)  |  timePast}})</span>
                                        </div>
                                        <!-- <div class="item-check">
                                            <button class="btn btn-sm btn-success-custom" (click)="unCheckinModal.show();storeBuffVehicle(vehicle)">Uncheck in </button>
                                            <button class="btn btn-sm btn-danger-custom" (click)="checkOut(vehicle)">Check out</button>
                                        </div> -->
                                    </div>
                                    <!-- <div class="button-group" *ngIf="isCheckInVehicle(vehicle.id) == true  && userPermission?.value == 'active'  || isCheckInVehicle(vehicle.id) == true && user.role == 'admin' ">
                                    <div class="label-check" *ngIf="vehicle.lastCheckDate">
                                        <span><small class=""><i class="uil uil-clock-three uil-extra-small"></i> Checked in,</small> {{vehicle.lastCheckDate | date : 'mediumDate'}} ({{vehicle.lastCheckDate |  timePast}})</span>
                                    </div>
                                    <div class="item-check">
                                        <button class="btn btn-sm btn-success-custom" [disabled]="true">Checked In </button>
                                        <button class="btn btn-sm btn-danger-custom" (click)="checkOut(vehicle)">Check Out </button>
                                    </div>
                                </div> -->
                                </div>
                                <div class="info-vehicle">
                                    <div class="item-vehicle" *ngIf="vehicle.vehicleModel">
                                        <p>Model</p>
                                        <span>{{vehicle.vehicleModel}}</span>
                                    </div>
                                    <div class="item-vehicle" *ngIf="vehicle?.phone">
                                        <p>Phone</p>
                                        <span>{{vehicle.phone}}</span>
                                    </div>
                                    <div class="item-vehicle" *ngIf="vehicle?.condition">
                                        <p>Condition</p>
                                        <span>{{vehicle.condition}}</span>
                                    </div>
                                    <div class="services-block" *ngIf="(getService(vehicle) | json) != '{}'">
                                        <h6 class="tittle-service ">Services</h6>
                                        <div class="assigned-users " *ngIf="getService(vehicle).usersSelected.length> 0">
                                            <p *ngFor="let userObj of getService(vehicle).usersSelected">
                                                <span><i class="uil uil-user"></i> {{userObj.name}}</span>
                                            </p>
                                        </div>
                                        <div class="others-normal-services">
                                            <div *ngIf="getService(vehicle).servicesNormalSelected.length > 0">
                                                <p *ngFor="let service of getService(vehicle).servicesNormalSelected">
                                                    <strong>{{service.serviceName}}</strong>
                                                    <span>({{getCurrency()}}) {{service.price}}</span>
                                                </p>
                                            </div>
                                            <div *ngIf="getService(vehicle).servicesOthersSelected.length > 0">
                                                <p *ngFor="let service of getService(vehicle).servicesOthersSelected">
                                                    <strong>{{service.serviceName}}</strong>
                                                    <span>({{getCurrency()}}) {{service.price}}</span>
                                                </p>
                                            </div>
                                            <hr>
                                            <div class="summary-services">
                                                <div>
                                                    <p>
                                                        <strong>Total Due</strong>
                                                        <span>({{getCurrency()}}) {{getService(vehicle).totalDu + getService(vehicle).totalDueOthers}}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Received </strong>
                                                        <span>({{getCurrency()}}) {{getService(vehicle).receivedAmount}}</span>
                                                    </p>
                                                    <p class="required">
                                                        <strong>Cash Back</strong>
                                                        <span>({{getCurrency()}}) {{getService(vehicle).cashBack}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer-vehicle fo-vehicle" *ngIf="userPermission?.value ==statusActive.Active || user.role == 'admin' ">
                                    <!-- <div>
                                        <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-chevron-down"></i>Notify Customer </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready_to_Served,vehicle)"> Ready to serve vehicle</button>
                                            <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Job_Started,vehicle)">Job started</button>
                                            <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Ready,vehicle)">Vehicle is ready  </button>
                                            <button mat-menu-item (click)="checkAction(smsNotification.Car_Wash_Service_Canceled,vehicle)">Service is canceled  </button>
                                        </mat-menu>
                                    </div> -->
                                    <div class="history-service">
                                        <div class=" badge-light">
                                            <button class="btn btn-sm " (click)="storeAssignedHistories(vehicle);historiesDetailingModal.show()"><i class="uil uil-history"></i> Log History</button>
                                        </div>
                                        <div class=" badge-light">
                                            <button class="btn btn-sm " (click)="UnAssignVehicle(vehicle)">Unassign</button>
                                        </div>
                                        <!-- <div class=" badge-light">
                                            <button class="btn btn-sm " (click)="getServices(vehicle,true)">Add/Edit Service</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <div class="table-footer mt-3" *ngIf="assignVehicles.length > 0">
                        <pagination-controls previousLabel="" nextLabel="" id="assign_id" (pageChange)="handlingQueuePagination($event) "></pagination-controls>
                    </div>
                    <div class="ant-empty-image" *ngIf="assignVehicles.length <= 0">
                        <img src="./assets/img/icons/empty.svg" alt="empty-img">
                        <span>No Assigned Vehicle</span>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<!-- Edit Vehicle Modal -->
<div mdbModal #editVehicleModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Vehicle</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="editVehicleModal.hide()" aria-label="Close">
                      <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body ">
                    <form [formGroup]="vehicleForm">
                        <mat-horizontal-stepper [linear]="isLinear" #stepper>
                            <mat-step [stepControl]="vehicleForm">
                                <ng-template matStepLabel>Vehicle Details</ng-template>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="vehicleType" placeholder="make" />
                                        <div *ngIf="f.vehicleType.touched && f.vehicleType.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.vehicleType.errors.required">Vehicle Type is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="plateNumber" placeholder="plate number" />
                                        <div *ngIf="f.plateNumber.touched && f.plateNumber.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.plateNumber.errors.required">Plate Number is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="vehicleModel" placeholder="vehicle model" />
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="color" placeholder="color" />
                                    </div> -->
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="condition" placeholder="condition " />
                                    </div>
                                </div>
                                <button type="button" mdbBtn class="btn btn-sm btn-success" [disabled]="f.plateNumber.invalid || f.vehicleType.invalid " (click)="updateVehicleInformation();editVehicleModal.show()">Update</button>
                                <button type="button" mdbBtn class="btn btn-sm btn-primary" matStepperNext *ngIf="vehicle.customer">Next</button>
                            </mat-step>
                            <mat-step [stepControl]="vehicleForm" *ngIf="vehicle.customer">
                                <ng-template matStepLabel>Customer Details</ng-template>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="firstName" placeholder="first name" />
                                        <div *ngIf="f.firstName.touched && f.firstName.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="lastName" placeholder="last name " />
                                        <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger" role="alert">
                                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" mdbBtn class="btn btn-sm btn-primary" matStepperPrevious>Back</button>
                                <button type="submit" mdbBtn class="btn btn-sm btn-success" [disabled]="f.firstName.invalid || f.lastName.invalid " (click)="updateCustomerInformation();editVehicleModal.show()">Update </button>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- service statistic Modal
<div mdbModal #serviceStatisticModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Statistic</h5>
                    <button type="button" class="close" (click)="serviceStatisticModal.hide()" aria-label="Close">
                  <span>&times;</span>
               </button>
                </div>
                <div class="modal-body text-center">
                    <p>this is service statistic modal</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="serviceStatisticModal.hide()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--  print QR -->
<div mdbModal #qrPrintModal="mdbModal" class="modal fade">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Template</h5>
                    <button type="button" class="close" (click)="qrPrintModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
         </button>
                </div>
                <div class="modal-body">
                    <div class="row" id="qr_codes_Id">
                        <div class="col-6 ">
                            <div class="full-item">
                                <div class="info ">
                                    <div class="info-details" *ngIf="templateType == enumTemplates.Template_One">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="templateType == enumTemplates.Template_Tow">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyPhone}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="templateType == enumTemplates.Template_Third">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}, {{companyInfo.companyAddress.addressTow}}</p>
                                        <p>{{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                    <div class="info-details" *ngIf="templateType == enumTemplates.Template_Four">
                                        <p>{{companyInfo.companyName}}</p>
                                        <p>{{companyInfo.companyAddress.addressOne}}, {{companyInfo.companyAddress.addressTow}}</p>
                                        <p> {{companyInfo.companyAddress.city}}, {{companyInfo.companyAddress.state}} {{companyInfo.companyAddress.zip}}</p>
                                        <p>{{companyInfo.companyPhone}} </p>
                                        <p>{{companyInfo.companyWebsite}}</p>
                                    </div>
                                </div>
                                <div class="qr-img">
                                    <!-- <qrcode [qrdata]="linkQrCode" [width]="192" [allowEmptyString]="true" [errorCorrectionLevel]=" 'L' " [elementType]="'img'"></qrcode> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #printScanMobile="mdbModal" class="modal fade">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Print</h5>
                    <button type="button" class="close" (click)="printScanMobile.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body">
                    <app-print-scan-mobile *ngIf="isScan" [isPrint]='false'></app-print-scan-mobile>
                    <app-print-scan-mobile *ngIf="!isScan" [isPrint]='true'></app-print-scan-mobile>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #deleteVehicleModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Vehicle</h5>
                    <button type="button" class="close" (click)="deleteVehicleModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center">
                    <strong> Delete Vehicle?</strong>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-light" (click)="deleteVehicleModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="deleteVehicle(buffVehicleId);deleteVehicleModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #reNewMembershipModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Force update plan</h5>
                    <button type="button" class="close" (click)="reNewMembershipModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body text-center">
                    <span> if you need to make a refund, You must log in to the <strong>business stripe account > Customers > Tap on the customer email> refund the amount.</strong></span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-light" (click)="reNewMembershipModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="renewForce(buffVehicleId);reNewMembershipModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- service statistic Modal -->
<div mdbModal #serviceStatisticModal="mdbModal" class="modal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Vehicle Statistics</h5>
                    <button type="button" class="close" (click)="serviceStatisticModal.hide()" aria-label="Close">
                  <span>&times;</span>
               </button>
                </div>
                <div class="modal-body text-center">
                    <div class="row">
                        <div class="col-lg-12 week-picker">
                            <!-- left arrow read and write -->
                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize" (click)="previousYearStatistic()">
                          <mat-icon>chevron_left</mat-icon>
                      </button>
                            <div class="week-text">
                                <p><b>{{yearStatistic1}} </b></p>
                            </div>
                            <!-- right arrow read and write  -->
                            <button mat-icon-button matTooltip="Previous Week" matTooltipPosition="'above'" class="iconsSize " (click)="nextYearStatistic()">
                          <mat-icon>chevron_right</mat-icon>
                      </button>
                        </div>
                        <div class="col-lg-12">
                            <table class="table table-bordered mt-2">
                                <thead>
                                    <tr>
                                        <th scope="col">Month</th>
                                        <th scope="col" *ngFor="let product of  statisticYearVehicles?.products">{{product}}</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of statisticYearVehicles?.count;let i=index ">
                                        <th scope="row">
                                            <span>{{i+1}}</span>
                                            <!-- <span *ngIf="last">Total</span> -->
                                        </th>
                                        <td scope="col" *ngFor="let product of  statisticYearVehicles?.products">{{getProductName(item,product)}}</td>
                                        <!-- <td scope="col">{{item.countDaily}}</td>
                                      <td scope="col">{{item.countMonthly}}</td> -->
                                        <td scope="col">{{item.countMonth}}</td>
                                        <!-- <td class="text-blue" (click)="getCountJobsByTypeInYear(0);select('draft')" [ngClass]="{activeJobs: isActive('draft')}"><a>{{jobsInYear.countDraft}}</a></td> -->
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td scope="col" *ngFor="let product of  statisticYearVehicles?.products ; let i =index">
                                            <span>{{getTotalName(i)}}</span>
                                        </td>
                                        <td scope="col">{{statisticYearVehicles.countAll}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Assign  -->
<div mdbModal #assignModal="mdbModal" class="modal assign-modal" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                  <span >Add/Edit Service </span>
              </strong>
                    <button type="button" class="close" (click)="assignModal.hide();resetServices()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body ">
                    <!-- <h6>Assign Crew</h6> -->
                    <!-- <form>
                        <div class="form-group muli-select">
                            <label>Crew Name:</label>
                            <mat-form-field appearance="outline">
                                <mat-select placeholder="user name" name="userObj" [compareWith]="equalsUsers" [(ngModel)]="selectedUsers" multiple>
                                    <mat-option *ngFor="let userObj of usersToAssigned" [value]="userObj">{{userObj.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="selectedUsers.length > 0">
                            <ul class="list-group" *ngFor="let user of selectedUsers; let i=index">
                                <li class="list-group-item user-item  list-group-item-action">
                                    <span><i class="uil uil-check-circle success"></i>  {{user.name}}</span>
                                </li>
                            </ul>
                        </div>
                    </form> -->
                    <h6>Select Services</h6>
                    <div class="servies-normal" *ngFor="let service of carWashServices;let j=index">
                        <mat-accordion *ngIf="(isChecked(service) && service.isAchieve) || !service.isAchieve">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <div class="header-service">
                                        <span>
                                    <input type="checkbox" id="" name="" (change)="handleChange($event,service,null)" [checked]="isChecked(service)"> {{service.serviceName}}
                                    <span *ngIf="checkServiceName(service)">({{checkServiceName(service)}})</span>
                                        </span>
                                        <small *ngIf="isCheckedSizePrice(service)">( {{isCheckedSizePrice(service).size}} | ({{getCurrency()}}) {{isCheckedSizePrice(service).price}} )</small>
                                    </div>
                                </mat-expansion-panel-header>
                                <ul class="list-group">
                                    <li *ngFor="let item of service.sizes; let i=index" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                        <span>
                                       <input type="radio" id="{{service.serviceName}}" [value]="item['size' + i ]"  [checked]="isCheckedSize(service) == item['size' + i ]"  name="{{service.serviceName}}" (change)="selectedNormalService($event,item,service,i)"  >
                                       {{item['size' + i ]}}
                                     </span>
                                        <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{item['price' + i ]}}</span>
                                    </li>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <h6>Select Other Services</h6>
                    <div class="servies-others">
                        <ul class="list-group" *ngFor="let service of additionalServices; let i=index">
                            <li [ngClass]="{'selected-item': selectedPackageIndex1 == i}" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action">
                                <span>
                               <input type="checkbox" id="service.serviceName"  name="service.serviceName" (change)="selectedOtherService($event,service);selectItemPackage1(i)" value="service.serviceName" [checked]="isCheckedOther(service)">
                               {{service.serviceName}}
                             </span>
                                <span class="badge bg-primary rounded-pill">({{getCurrency()}}) {{service.price}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="summary">
                        <p><strong>Total due</strong> ({{getCurrency()}}) {{totalDuNormal + totalDueOthers > 0 ? totalDuNormal + totalDueOthers : 0}}</p>
                        <p> <strong>Received</strong>
                            <input type="number" class="form-control w-25" value="{{receivedAmount}}" min="0" (input)="changeReceivedAmount($event.target.value)">
                        </p>
                        <p> <strong>Cash Back</strong> ({{getCurrency()}}) {{getCashBack()}}</p>
                        <span *ngIf="isDisabled" class="alert alert-danger">The received amount must be equal to or greater than the due amount.</span>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-sm btn-danger" (click)="assignModal.hide();resetServices() ">close</button>
                    <button type="button" class="btn btn-sm btn-success" [disabled]="isDisabled" (click)="assignedService();assignModal.hide() ">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ReNewMembershipModal -->
<div mdbModal #reNewMembershipModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Force update plan</h5>
                    <button type="button" class="close" (click)="reNewMembershipModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body text-center">
                    <span> if you need to make a refund, You must log in to the <strong>business stripe account > Customers > Tap on the customer email> refund the amount.</strong></span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-light" (click)="reNewMembershipModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="renewForce(buffVehicle);reNewMembershipModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Denied Modal  -->
<div mdbModal #deniedModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-md" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">This Action Cannot Be Completed</h6>
                    <button type="button" class="close" (click)="deniedModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                 </button>
                </div>
                <div class="modal-body ">
                    <!-- <span *ngIf="isDenied">Card Denied</span> -->
                    <span>  This Action Cannot Be Completed Because there is a current subscription. </span><br>
                    <span>    To proceed, You can cancel the existing subscription.</span>
                    <!-- <span *ngIf="!isDenied">Card Denied</span> -->
                    <div class="text-center">
                        <button class="btn btn-sm btn-success-custom" (click)="deniedModal.hide()">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Vehicle Modal -->
<div mdbModal #deleteVehicleModal="mdbModal" class="modal delete-modal">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Delete Vehicle</h6>
                    <button type="button" class="close" (click)="deleteVehicleModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body text-center px-3">
                    <strong> Delete Vehicle?</strong>
                </div>
                <div class="modal-footer px-1">
                    <button class="btn btn-sm btn-light" (click)="deleteVehicleModal.hide()">Cancel</button>
                    <button class="btn btn-sm btn-success" (click)="deleteVehicle(buffVehicle);deleteVehicleModal.hide()">Ok</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #noPhoneModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                    <span >Can't send message</span>
                </strong>
                    <button type="button" class="close" (click)="noPhoneModal.hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Add the phone number first</p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="noPhoneModal.hide() ">close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #sendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                      <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Service_Canceled">Service is canceled</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Job_Started">Job started</span>
                  </strong>
                    <button type="button" class="close" (click)="sendMessageModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Send Message</p>
                </div>
                <div class="modal-footer">
                    <button type="button " class="btn btn-sm btn-light" (click)="sendMessageModal.hide() ">cancel</button>
                    <button type="button " class="btn btn-sm btn-success" (click)="sendMessageModal.hide();sendSMSMessage() ">send</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--   denied Send Message -->
<div mdbModal #notSendMessageModal="mdbModal" class="modal ">
    <div class="modal-dialog modal-sm" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <strong class="modal-title">
                      <span *ngIf="smsType == smsNotification.Car_Wash_Ready_to_Served">Ready to serve vehicle</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Ready">Vehicle is ready</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Service_Canceled">Service is canceled</span>
                      <span *ngIf="smsType == smsNotification.Car_Wash_Job_Started">Job started</span>
                  </strong>
                    <button type="button" class="close" (click)="notSendMessageModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <p class="mt-3">Sending this notification is inactive in the Add-Ins Store > Assigned Vehicle </p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-sm btn-light" (click)="notSendMessageModal.hide() ">cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal history" mdbModal #historiesDetailingModal="mdbModal">
    <div class="modal-dialog  ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title "> Log History</h5>
                <button type="button " class="close " data-dismiss="modal " (click)="historiesDetailingModal.hide()" aria-label="Close ">
                  <span>&times;</span>
                </button>
            </div>
            <div class="modal-body ">
                <div *ngIf="historiesDetailing.length > 0 ">
                    <div *ngFor="let item of historiesDetailing | paginate: {id: 'history_Id' , itemsPerPage: 3, currentPage: p4 }">
                        <div class="card ">
                            <div>
                                <small>{{item.dateHistory | date: 'MMM d,  y'}}, {{item.timeHistory }}</small>
                                <small *ngIf="item.startDate">( {{item.startDate | date: 'MMM d,  yy'}}</small>
                                <small *ngIf="item.endDate">-{{item.endDate |  date: 'MMM d,  yy'}} )</small>
                                <small *ngIf="item?.planName"> ({{item?.planName}}) </small>
                                <div class="row-history ">
                                    <p><span [ngClass]="[item.key == historyCarWash.Add_Vehicle || item.key == historyCarWash.Update_Vehicle || item.key == historyCarWash.Update_Vehicle_Subscription || item.key == historyCarWash.Check_In_Action  || item.key == historyCarWash.Assign_Qr_Code ||  item.key == assignType.Assign ? 'section-to' : '',  item.key == historyCarWash.Create_Membership  ? 'blue-item' : '', item.key == historyCarWash.Check_Out_Action || item.key == historyCarWash.Cancel_Vehicle_Subscription  ||  item.key == historyCarWash.Force_Update_Plan ||  item.key == assignType.UnAssign ? 'section-from' : '']">
                                    <span *ngIf="item.key == historyCarWash.Add_Vehicle">Added</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle">Updated</span>
                                        <span *ngIf="item.key == historyCarWash.Update_Vehicle_Subscription">Update Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Assign_Qr_Code">Assign Qr Code</span>
                                        <span *ngIf="item.key == assignType.Assign">Assign Vehicle</span>
                                        <span *ngIf="item.key == assignType.UnAssign">UnAssign Vehicle</span>
                                        <span *ngIf="item.key == historyCarWash.Cancel_Vehicle_Subscription">Cancel Subscription</span>
                                        <span *ngIf="item.key == historyCarWash.Create_Membership">Create
                                        <span *ngIf="item.paymentType == paymentTypeEnum.cash">Cash</span>
                                        <span *ngIf="item.paymentType == paymentTypeEnum.card">Card</span> Membership
                                        </span>
                                        <span *ngIf="item.key ==  historyCarWash.Force_Update_Plan">Force Update Plan</span>
                                        </span> in <strong>{{item.branchName}}</strong> branch, by <strong>{{item.addedBy}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-3 " *ngIf="historiesDetailing.length> 0 ">
                    <pagination-controls id="history_Id" (pageChange)="p4 = $event "></pagination-controls>
                </div>
                <div class="ant-empty-image" *ngIf="historiesDetailing.length <= 0 ">
                    <img src="./assets/img/icons/empty.svg" alt="empty-img">
                    <span>No Data</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  open camera -->
<div mdbModal #cameraScanModal="mdbModal" class="modal camera-scan " [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Capture Plate</h5>
                    <button type="button" class="close" (click)="cameraScanModal.hide();closeCamera()" aria-label="Close">
               <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body webcam-modal">
                    <div class="text-center">
                        <webcam [height]="500" [width]="350" [captureImageData]="true" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam" [imageQuality]="1" (initError)="handleInitError($event)"></webcam>
                    </div>
                </div>
                <div class="modal-footer ">
                    <div class="upload-buttons">
                        <div class="btn-group">
                            <button class="btn btn-success" *ngIf="showWebcam " (click)="triggerSnapshot()"> <i class="uil uil-capture pr-2"></i><span>Capture</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>