import { ToastrService } from 'ngx-toastr';
import { PersonService } from './../../../../core/_services/person/person.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpdateInfo } from '../edit-company/Enum/UpdateInfo';
declare var google: any;
interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
}
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('addressText') addressText: any;
  userModal: any ={}
  Currencies: any[] = []
  userFormGroup: FormGroup
  defaultValue: Country ;
  user: any = {}
  place_id = ''
  formatted_address = ''
  lng = 0 
  lat = 0
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _personService:PersonService
  ) { }
  ngOnInit() {
    // this.defaultValue = {
    //   name: 'United States',
    //   alpha2Code: 'US',
    //   alpha3Code: 'US',
    //   numericCode: '840'
    // };
    this.createCustomerForm()
    this.Currencies = [
      "USD",
      "GBP",
      "CAD",
      "AUD",
      "EUR",
      "ZAR",
      "AED"
    ]
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['user'].success) {
        this.user = response['user'].data
        this.patchValues(this.user)
      }
    }, error => {
      console.log(error)
    })
  }
  createCustomerForm() {
    this.userFormGroup = this.fb.group({
      "firstName": ['', Validators.required],
      "lastName": [''],
      "email": ['', Validators.required],
      "phone": ['', Validators.required],
      "customPersonType": ['', Validators.required],
      "addressOne": ['', Validators.required],
      "addressTow": [''],
      "state": ['', Validators.required],
      "zip": ['', Validators.required],
      "city": ['', Validators.required],
      "country": ['', Validators.required]
    });
  }
  get f() {
    return this.userFormGroup.controls;
  }
  patchValues(user:any){
    this.userFormGroup.patchValue({
      firstName:user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      customPersonType: user.customPersonType,
      addressOne: user.addressOne,
      addressTow: user.addressTow,
      state: user.state,
      zip: user.zip,
      city: user.city,
      country: user.country,
    });
    this.defaultValue = user.country
  }
  onCountrySelected(country: any) {
    this.userFormGroup.patchValue({
      country:country
    });
  }
  updateUser() {
    this.userModal = Object.assign({},this.userFormGroup.value)
    let merged = {...this.user, ...this.userModal};
    merged.name = merged.lastName  + ', ' + merged.firstName
    merged.lat = this.lat
    merged.lng = this.lng
    merged.formatted_address = this.formatted_address
    merged.place_id = this.place_id
    this._personService.updatePerson(merged,this.user.id,UpdateInfo.All).subscribe((response:ResponseModel)=>{
      if(response.success){
        this.patchValues(response.data)
        this.toastr.success('', 'Successfully Update Information',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
      else{
        this.toastr.error('Error Update', 'Please try Again ',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
      }
    },error=>{
      this.toastr.error('Error Update', 'Please try Again ',
      {
        progressBar: true,
        progressAnimation: "increasing",
        timeOut: 3000
      });
    })
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       this.lat =  place.geometry.location.lat()
       this.lng = place.geometry.location.lng()
       this.formatted_address = place.formatted_address
       this.place_id =  place.place_id
       this.patchValuesAddress(this.getAddressFromComponents(place.address_components))
    });
  }
  patchValuesAddress(item:any){
    this.userFormGroup.patchValue({
      addressOne: item.addressOne,
      addressTow: item.addressTow,
      state: item.state,
      zip: item.zip,
      city: item.city,
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      // country: countryName,
      zip: zip
    };
  }
}
