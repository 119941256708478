
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuard } from './_helper/auth.guard.ts';
import { Auth2Guard } from './_helper/auth2.guard.ts';
import { ErrorInterceptor } from './_helper/error.interceptor.ts';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { LoaderInterceptor } from './_helper/loader.interceptor.ts';
import { ContainersResolver } from './_resolver/containers-resolver';
import { DateAndTimeResolver } from './_resolver/dateAndTime-resolver';
import { getAllInventoryItemMixedResolver } from './_resolver/getAllInventoryItemMixed-resolver';
import { GetAllInventoryItemMixedSummaryResolver } from './_resolver/getAllInventoryItemMixedSummary-resolver';
import { GetAllMangerAndAdminInCompanyResolver } from './_resolver/getAllMangerAndAdminInCompany-resolver';
import { getAllMangerUsersResolver } from './_resolver/getAllMangerUsers-resolver';
import { GetAllPendingCustomersResolver } from './_resolver/getAllPendingCustomers-resolver';
import { GetAllPendingUsersResolver } from './_resolver/getAllPendingUsers-resolver';
import {  GetAllTrucksFiltersByCompanyResolver } from './_resolver/getAllTrucksFiltersByCompany-resolver';
import { GetAllUserCompaniesResolver } from './_resolver/getAllUserCompanies-resolver';
import { GetCompanyByIdResolver } from './_resolver/getCompanyById-resolver';
import { GetCompanyInfoSignupResolver } from './_resolver/getCompanyInfoSignup-resolver';
import { GetCustomersByCompanyResolver } from './_resolver/getCustomersByCompany-resolver';
import { jobByIdResolver } from './_resolver/getJobById-resolver';
import { GetPersonByIdResolver } from './_resolver/getPersonById-resolver';
import { GetToolsByCompanyIdResolver } from './_resolver/getToolsByCompanyId-resolver';
import { GetToolsByTypeResolver } from './_resolver/getToolsByType-resolver.ts';
import { GetTrucksByCompanyResolver } from './_resolver/getTrucksByCompany-resolver';
import { GetUserByCompanyIdResolver } from './_resolver/getUserByCompanyId-resolver';
import { GetUserFilterByCompanyIdResolver } from './_resolver/getUserFilterByCompanyId-resolver';
import { GetUserViewByCompanyIdResolver } from './_resolver/getUserViewByCompanyId-resolver';
import { InventoryItemResolver } from './_resolver/inventory-item-resolver';
import { JobsResolver } from './_resolver/jobs-resolver';
import { JobSummaryResolver } from './_resolver/jobSummary-resolver';
import { NoteResolver } from './_resolver/note-resolver';
import { SignatureResolver } from './_resolver/signature-resolver';
import { StatusJobsResolver } from './_resolver/statusJobs-resolver';
import { StepsResolver } from './_resolver/steps-resolver';
import { GetCompanyByIdSuperResolver } from './_resolver/super-admin-resolver/getCompanyByIdSuperResolver';
import { GetTicketsByCompanyResolver } from './_resolver/super-admin-resolver/getTicketsByCompanyResolver';
import { UsersJobsResolver } from './_resolver/super-admin-resolver/usersjobs-resolver';
// import { getAllCompaniesResolver } from './_resolver/super-admin-resolver/getAllCompanies-resolver';
import { SuppliesResolver } from './_resolver/supplies-resolver';
import { TruckByIdResolver } from './_resolver/truckById-resolver';
import { TrucksResolver } from './_resolver/trucks-resolver';
import { AddressMapsService } from './_services/address-maps/address-maps.service';
import { AuthService } from './_services/auth/auth.service';
import { CarWashService } from './_services/car-wash-service/car-wash.service';
import { ChatService } from './_services/chat-Service/chat.service';
import { CompaniesService } from './_services/companies/companies.service';
import { ContainerService } from './_services/containers/container.service';
import { FrontSpaceService } from './_services/frontspace/front-space.service';
import { GlobalService } from './_services/global/global.service';
import { ShowItemsService } from './_services/global/showsItemsService/show-items.service';
import { HistoryService } from './_services/history/history.service';
import { HubService } from './_services/hub/hub.service';
import { InventoryItemsService } from './_services/InventoryItems/inventoryItems.service';
import { JobDetailsService } from './_services/job-details/job-details.service';
import { JobsService } from './_services/jobs/jobs.service';
import { LoaderService } from './_services/loader/loader.service';
import { MapService } from './_services/map-service/map.service';
import { OnlineOfflineServiceService } from './_services/onlineoffline/onlineofflineService.service';
import { PersonPendingService } from './_services/PendingPersons/personPending.service';
import { PersonService } from './_services/person/person.service';
import { PricingService } from './_services/pricing/pricing.service';
import { QrCodeMovingService } from './_services/qr-code-moving/qr-code-moving.service';
import { QrService } from './_services/qr-service/qr.service';
import { StepsService } from './_services/steps/steps.service';
import { StripService } from './_services/strip/strip.service';
import { SuperAdminService } from './_services/super-admin/super-admin.service';
import { SuppliesService } from './_services/supplies/supplies.service';
import { TicketService } from './_services/ticket/ticket.service';
import { ToasterCustomService } from './_services/toaster-service/ToasterCustomService.service';
import { ToolsService } from './_services/tools/tools.service';
import { TrashService } from './_services/trash-services/trash.service';
import { TrucksService } from './_services/trucks/trucks.service';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    CompaniesService,
    ContainerService,
    InventoryItemsService,
    JobDetailsService,
    StepsService,
    SuppliesService,
    ToolsService,
    JobsService,
    PersonService,
    AuthService,
    Auth2Guard,
    TrucksService,
    PersonPendingService,
    StripService,
    // resolvers
    JobsResolver,
    StepsResolver,
    InventoryItemResolver,
    ContainersResolver,
    SuppliesResolver,
    TrucksResolver,
    TruckByIdResolver,
    jobByIdResolver,
    GetUserByCompanyIdResolver,
    GetCustomersByCompanyResolver,
    GetTrucksByCompanyResolver,
    GetCompanyByIdResolver,
    GetPersonByIdResolver,
    GetToolsByCompanyIdResolver,
    GetAllTrucksFiltersByCompanyResolver,
    GetAllPendingUsersResolver,
    GetAllPendingCustomersResolver,
    GetToolsByTypeResolver,
    GetAllMangerAndAdminInCompanyResolver,
    GetUserViewByCompanyIdResolver,
    GetCompanyInfoSignupResolver,
    AuthGuard,
    LoaderService,
    ChatService,
    FrontSpaceService,
    MapService,
    HubService,
    OnlineOfflineServiceService,
    TrashService,
    HistoryService,
    SuperAdminService,
    ShowItemsService,
    GetCompanyByIdSuperResolver,
    UsersJobsResolver,
    getAllInventoryItemMixedResolver,
    GetAllInventoryItemMixedSummaryResolver,
    DateAndTimeResolver,
    NoteResolver,
    SignatureResolver,
    StatusJobsResolver,
    JobSummaryResolver,
    TicketService,
    GetTicketsByCompanyResolver,
    getAllMangerUsersResolver,
    GetUserFilterByCompanyIdResolver,
    GetAllUserCompaniesResolver,
    ToasterCustomService,
    PricingService,
    GlobalService,
    QrService,
    CarWashService,
    AddressMapsService,
    QrCodeMovingService,
    // getAllCompaniesResolver,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class CoreModule { }
