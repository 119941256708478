import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/core/_services/global/global.service';
@Component({
  selector: 'app-items-list-container-mobile',
  templateUrl: './items-list-container-mobile.component.html',
  styleUrls: ['./items-list-container-mobile.component.scss']
})
export class ItemsListContainerMobileComponent implements OnInit {
  objFromParent2:any = []
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.objFromParent2 = JSON.parse(params['data'])
     });
    setTimeout(() => {
      let  printContents = document.getElementById('items-list').innerHTML;
      let popupWin = window.open('', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
       popupWin.document.write(`
       <html>
         <head>
           <title>print</title>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
           <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
           <style>
           body {
            margin : 2rem auto;
            text-align : center;
            page-break-after: auto;
          }
          .header {
           display: flex;
           justify-content: space-between;
        }
         .table thead tr {
           background: #efefef;
           text-align: center;
        }
         .table thead th {
           border: 0;
        }
         .table tbody {
           text-align: center;
        }
         .table .badge-light {
           font-size: 16px !important;
           margin: 10px !important;
        }
         @media (max-width: 576px) {
           .table thead, .table tbody {
             font-size: 10px;
          }
        }
        }
           </style>
         </head>
         <body onload="window.print()">${printContents}</body>
       </html>`
       );
       popupWin.document.close();
    }, 1000);
  }
  goBack(){
    this.globalService.goBack()
  }
}
