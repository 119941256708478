import { AuthService } from './../_services/auth/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
        // role not authorized so redirect to home page
        this.router.navigate(['/login']);
        return false;
      }
      // if (this._authService.LoggedIn()) {
      //   return true;
      // }
      // authorized so return true
      // this.router.navigate(['/admin']);
      return true;
    }
    else{
      this.router.navigate(['/login']);
    }
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/']);
    // this.router.navigate(['/login']);
    return false;
  }

}
