import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule } from '@angular/common/http';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MDBBootstrapModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectCountryModule,
    HttpClientModule
  ],
  exports: [
    MaterialModule,
    MDBBootstrapModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectCountryModule,
    HttpClientModule
  ]
})
export class SharedModule { }
