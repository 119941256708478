<div class="container-fluid">
    <h1>Accordev</h1>
    <div class="first">
        <p>Your Account was Disabled.</p>
    </div>
    <div class="second">
        <strong>Your Account With:</strong>
        <p>Company Name</p>
        <p>
            {{companyInfo.companyAddress?.addressOne}},<small *ngIf="companyInfo.companyAddress?.addressTow"> {{companyInfo.companyAddress?.addressTow}}, </small> {{companyInfo.companyAddress?.country.name}}, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}}
            {{companyInfo.companyAddress?.zip}}
        </p>
        <strong> was Disabled.</strong>
    </div>
    <div class="third">
        <p>If you think your account was disabled by mistake, please contact the company.</p>
        <strong>Kind Regards, Accordev Team</strong>
    </div>
    <!-- <div class="request-form">
        <button class="btn btn-primary">Request Form</button>
    </div> -->
</div>