import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/core/_services/global/global.service';

@Component({
  selector: 'app-all-inventories-print-map-mobile',
  templateUrl: './all-inventories-print-map-mobile.component.html',
  styleUrls: ['./all-inventories-print-map-mobile.component.scss']
})
export class AllInventoriesPrintMapMobileComponent implements OnInit {
  objFromParent3:any = {}
  id=''
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.objFromParent3 = JSON.parse(params['data'])
      this.id = params['id']
     });
    let printContents = document.getElementById(this.id).innerHTML;
    let popupWin = window.open('', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>print</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.min.js" integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF" crossorigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
          <link rel="stylesheet"  type="text/css" href="./src/assets/css/documentation.print.css">
          <style>
          .print {
            text-align: center;
        }
        body {
          page-break-after: always;
        }
        .print .btn {
            text-transform: capitalize;
        }

        .job-summary .table {
            text-align: center;
        }

        .job-summary .table thead tr th {
            background: #c1c1cc;
            border: 1px solid #e9e9eb;
        }

        .job-summary .table tbody tr td {
            border: 1px solid #e9e9eb;
        }

        .row .col-12 {
            margin: 7px auto;
        }

        .row .col-12 .first-row-header {
            display: flex;
            justify-content: space-between;
            background: #c1c1cc;
            padding: 5px;
            border: 1px solid #a5a5a5;
        }

        .row .col-12 .tow-row-header,
        .row .col-12 .three-row-header {
            display: flex;
            justify-content: left;
            background: #c1c1cc;
            padding: 5px;
            border: 1px solid #a5a5a5;
        }

        .row .col-12 .tow-row,
        .row .col-12 .three-row {
            display: flex;
            justify-content: center;
            padding: 5px;
            border: 1px solid #a5a5a5;
            background-color: transparent;
        }

        .row .item-person {
            display: flex;
            padding: 10px;
            background: #e9e9eb;
            align-items: baseline;
            margin: 5px auto;
        }

        .row .item-person label,
        .row .item-person h6 {
            margin: 0;
        }

        .row .item-person h6 {
            padding-left: 5px;
        }

        .signature .table tbody td {
            border: 1px solid #c1c1c1 !important;
        }

        .signature .table tbody .img-sign {
            text-align: center;
        }

        .signature .table tbody .img-sign img {
            width: 100px;
        }

        .signature .table tbody .status-date {
            display: flex;
            justify-content: space-between;
        }

        .signature .table tbody .last {
            height: 11px;
        }

        .tittle h6 {
            padding: 0.5rem;
            text-align: center;
            background: #c1c1cc;
            margin: 10px auto;
        }
        .inventories .col-12{
          page-break-after: always;
        }
        @media print {
       .header .btn{
        display:none
       }
        }
          </style>
        </head>
    <body onload="window.print()">${printContents}</body>
      </html>`
      );
      popupWin.document.close();
  }
  goBack(){
    this.globalService.goBack()
  }
}
