import { Company } from 'src/app/models/company/company';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { OnlineOfflineServiceService } from '../onlineoffline/onlineofflineService.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private onlineOfflineService: OnlineOfflineServiceService,
    private toastr: ToastrService,
  ) {
    this.registerToEvents();
  }
  getCompanyIds(){
    return this.http.get(this.baseUrl + "companyIds")
  }
  getCompanies() {
    return this.http.get(this.baseUrl + "companies")
  }
  getCompanyById(id: any) {
    return this.http.get(this.baseUrl + "company/" + id)
  }
  companyInfoFroInvite(companyNumber: any,signUpDateTimeStamp: any) {
    return this.http.get(`${this.baseUrl}companyInfoFroInvite/${companyNumber}/${signUpDateTimeStamp}`)
  }
  companyInfoWithQueue(companyNumber: any,signUpDateTimeStamp: any,plateNumber:any) {
    return this.http.get(`${this.baseUrl}companyInfoWithQueue/${companyNumber}/${signUpDateTimeStamp}/${plateNumber}`)
  }
  getCompanyInfoSignup(id: any) {
    return this.http.get(this.baseUrl + "companyInfoSignup/" + id)
  }
  getCompanyContactEmail(companyId:string){
    return this.http.get(this.baseUrl + "companyContactEmail/" + companyId)
  }
  companyFullSummary(id: any) {
    return this.http.get(this.baseUrl + "companyFullSummary/" + id)
  }
  getCompanyFullSummaryWithRole(companyId: string, userId: string) {
    return this.http.get(this.baseUrl + "companyFullSummaryWithRole/" + companyId + '/' + userId)
  }
  getCompanyTermAndCondition(companyId: string) {
    return this.http.get(this.baseUrl + "companyTermAndCondition/" + companyId )
  }
  getCompanyCountPrint(companyId: string) {
    return this.http.get(this.baseUrl + "companyCountPrint/" + companyId )
  }
  checkVehicleQrNumber(adminId: string,qrCodeNumber:any) {
    return this.http.get(this.baseUrl + "checkVehicleQrNumber/" + adminId +'/'+qrCodeNumber )
  }
  createCustomerPortal(companyId: string) {
    return this.http.post(this.baseUrl + 'createCustomerPortal/' + companyId, {})
  }
  addCompany(newCompany: any) {
    return this.http.post(this.baseUrl + 'company', newCompany)
  }
  addCompanyBranch(newBranchCompany: any, id: string) {
    return this.http.post(this.baseUrl + 'companyBranch/' + id, newBranchCompany)
  }
  updateCompany(newCompany: any, id: any, updateType: any) {
    return this.http.put(this.baseUrl + 'company/' + id + '?updateType=' + updateType, newCompany)
  }
  changeLogo(image: any, companyId: string) {
    return this.http.put(this.baseUrl + 'companyLogo/' + companyId, { companyLogo: image })
  }
  getIpInfo() {
    let countryCode: any
    this.http.get<any>("https://ipinfo.io/json").subscribe(
      (resp: any) => {
        // console.log(resp)
        countryCode = resp && resp.country ? resp.country : "US";
        localStorage.setItem('country', countryCode)
      },
      (error) => {
        // console.error(error);
      })
  }
  getCountry() {
    let country = localStorage.getItem('country')
    return country === 'US' ? true : false
  }
  getCompanyStripeKey(companyId:string){
    return this.http.get(this.baseUrl + 'companyStripeKey/' + companyId)
  }
  registerToEvents() {
    // console.log('this is registerToEvents in company service ')
    this.onlineOfflineService.connectionChanged.subscribe(online => {
      // console.log('this is registerToEvents in company service ')
      // console.log(online)
      if (online) {
        this.onlineOfflineService.sendItemsFromIndexedDb();

      } else {
        this.toastr.info('Some Functionality may be unavailable.', 'You are offline',
          {
            // progressBar: true,
            // progressAnimation: "increasing",
            positionClass: 'toast-bottom-left',
            disableTimeOut: false,
            // tapToDismiss : false,
            closeButton: false
            // timeOut: 3000

          });
      }
    }, error => {
      console.log(error)
    });
  }
  updateCompanySubscription(companyId: string, model: any) {
    return this.http.put(this.baseUrl + 'companySubscription/' + companyId, model)
  }
  restoreCompanySubscription(companyId: string) {
    return this.http.put(this.baseUrl + 'companyRestoreSubscription/' + companyId, {})
  }
  // Apis Cas Wash
  updateCompanyStripeKey(companyId: string, model: any) {
    return this.http.put(this.baseUrl + 'companyStripeKey/' + companyId, model)
  }
  getProductsCompanyById(companyId: string) {
    return this.http.get(this.baseUrl + "productsCompany/" + companyId)
  }
  updateCompanyCategory(companyId:string,categoryCompany:number){
    return this.http.put(this.baseUrl + 'companyCategory/' + companyId, {categoryCompany:categoryCompany})
  }
  updateCompanyTermAndCondition(companyId:string,model:any){
    return this.http.put(this.baseUrl + 'companyTermAndCondition/' + companyId,model)
  }
  updateCompanyContactEmail(companyId:string,email:string){
    return this.http.put(this.baseUrl + 'companyContactEmail/' + companyId,{contactEmail:email})
  }
  updateCompanyAminCountPrintBranches(companyId: string,model:any) {
    return this.http.put(this.baseUrl + "companyCountPrint/" + companyId ,model)
  }
  // addTodo(todo: any) {
  //   if (!this.onlineOfflineService.isOnline) {
  //     this.onlineOfflineService.addToIndexedDb(todo);
  //   }
  //   else {
  //   }
  // }
}
