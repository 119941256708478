export enum InitialRoles {
  All_Roles,
  All_Roles_Without_Customer,
  All_Roles_Without_Customer_And_ThirdParty
}

export enum ToolNames {
  Job_Tool ='Job Dashboard',
  Home_Page_Tool = 'Home Page',
  Others_Tool = 'Others',
}
