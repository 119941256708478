<div class="card" *ngIf="vehicle">
    <div class="tittle-section">
        <p>Vehicle Information</p>
    </div>
    <div class="item">
        <label>Plate Number</label>
        <h6>{{vehicle?.plateNumber}}</h6>
    </div>
    <div class="item">
        <label>Make</label>
        <h6>{{vehicle?.vehicleType}}</h6>
    </div>
</div>
<div class="not-assigned" *ngIf="!vehicle">
    <i class="uil uil-car-slash required"></i>
    <h6>QR Code has not been assigned yet</h6>
</div>