import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Injectable } from '@angular/core';
import { OnlineOfflineServiceService } from '../onlineoffline/onlineofflineService.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient,
    public onlineOfflineService: OnlineOfflineServiceService
  ) { }

  /************** users ***************/
  getUsers() {
    return this.http.get(this.baseUrl + 'users')
  }
  getUsersByCompany(companyId: any) {
    return this.http.get(this.baseUrl + 'users/' + companyId)
  }
  getUsersViewByCompany(companyId: any) {
    return this.http.get(this.baseUrl + 'usersView/' + companyId)
  }
  getUsersFiltered(companyId: any) {
    return this.http.get(this.baseUrl + 'usersFiltered/' + companyId)
  }
  /************** customers ***************/
  getCustomers() {
    return this.http.get(this.baseUrl + 'customers')
  }
  getCustomersWithCountJobsByCompany(companyId: any,type:number) {
    return this.http.get(this.baseUrl + 'customersWithCount/' + companyId + '?type=' + type )
  }
  getCustomersByCompany(companyId: any) {
    return this.http.get(this.baseUrl + 'customers/' + companyId)
  }
  getAllJobsByCustomer(companyId: string, customerId: string) {
    return this.http.get(this.baseUrl + 'customerJobs/' + companyId + '/' + customerId);
  }
  getAllJobsByUser(companyId: string, userId: string) {
    return this.http.get(this.baseUrl + 'userJobs/' + companyId + '/' + userId);
  }
  getAdminsByCompany(companyId: string) {
    return this.http.get(this.baseUrl + '/usersMangers/' + companyId);
  }
  /************** persons ***************/
  getPersonById(id: any) {
    return this.http.get(this.baseUrl + 'person/' + id)
  }
  getAllUserCompanies(uid: any) {
    return this.http.get(this.baseUrl + 'userCompanies/' + uid)
  }
  getAllPersonInCompanies(companyId:string){
    return this.http.get(this.baseUrl + 'personsCompany/' + companyId)
  }
  addPerson(newPerson: any) {
    return this.http.post(this.baseUrl + 'person', newPerson)
  }
  isEmailUserExists(email: string) {
    return this.http.get(this.baseUrl + 'isEmailUserExists/' + email)
  }
  InviteCustomer(customerId: string) {
    return this.http.post(this.baseUrl + 'InviteCustomer', { customerId: customerId })
  }
  addCustomerFromInvite(model: any) {
    return this.http.post(this.baseUrl + 'addCustomerFromInvite', model)
  }
  updatePerson(newPerson: any, id: any, updateType: any) {
    return this.http.put(this.baseUrl + 'person/' + id + '?updateType=' + updateType, newPerson)
  }
  InvitePerson(model: any) {
    if (!this.onlineOfflineService.isOnline) {
      this.onlineOfflineService.addToIndexedDb(model, 'person');
    }
    else {
      return this.http.post(this.baseUrl + 'InviteUser', model)
    }
  }
  InviteCustomerPending(model: any) {
    return this.http.post(this.baseUrl + 'InviteCustomerPending', model)
  }
  InviteCustomerPendingGlobal(model: any) {
    return this.http.post(this.baseUrl + 'InviteCustomerPendingGlobal', model)
  }
  ReInvitePerson(id: string) {
    return this.http.post(this.baseUrl + 'ReInviteUser', { id: id })
  }
  ReInviteCustomer(id: string) {
    return this.http.post(this.baseUrl + 'ReInviteCustomer', { id: id })
  }
  ReInvitePersonPending(id: string) {
    return this.http.post(this.baseUrl + 'ReInviteUserPending', { idPending: id })
  }
  ReInviteCustomerPending(id: string) {
    return this.http.post(this.baseUrl + 'ReInviteCustomerPending', { idPending: id })
  }
  addInvitePerson(model: any) {
    return this.http.post(this.baseUrl + 'addInvitedPerson', model)
  }
  disableUser(id: string) {
    return this.http.put(`${this.baseUrl}disableUser/${id}`, {})
  }
  disableCustomer(id: string) {
    return this.http.put(`${this.baseUrl}disableCustomer/${id}`, {})
  }
  enableUser(id: string) {
    return this.http.put(`${this.baseUrl}enablePerson/${id}`, {})
  }
  updatePersonRole(id: string, role: string) {
    return this.http.put(`${this.baseUrl}updateUserRole/${id}`, { role: role })
  }
  updateCustomerRole(id: string, role: string) {
    return this.http.put(`${this.baseUrl}updateCustomerRole/${id}`, { role: role })
  }
  HideInSwitchCompanyPerson(id: string) {
    return this.http.put(`${this.baseUrl}hideCompany/${id}`, {})
  }
  ShowInSwitchCompanyPerson(id: string) {
    return this.http.put(`${this.baseUrl}showCompany/${id}`, {})
  }
  cancelCustomerPending(id: string) {
    return this.http.delete(`${this.baseUrl}cancelCustomerPending/${id}`)
  }
  cancelUserPending(id: any) {
    return this.http.delete(`${this.baseUrl}cancelUserPending/${id}`)
  }
  cancelPerson(id: string) {
    return this.http.delete(`${this.baseUrl}cancelUser/${id}`)
  }
  cancelCustomerPerson(id: string) {
    return this.http.delete(`${this.baseUrl}cancelCustomer/${id}`)
  }
  getAddressInfo(id: string, type: any) {
    return this.http.get(this.baseUrl + 'AddressInfo/' + id + '?type=' + type)
  }
  isEmailInPersonAndInPendingExists(model: any) {
    return this.http.post(this.baseUrl + 'isEmailInPersonInPendingExists', model)
  }
  updateMangerPermission(id: string, model: any) {
    return this.http.put(this.baseUrl + 'mangerPermission/' + id, model)
  }
  updatePersonPermission(id: string,model:any) {
    return this.http.put(this.baseUrl + 'personPermission/' + id, model)
  }
}
