<div class="container-fluid">
    <h1>Accordev</h1>
    <div class="first">
        <p>Company Account was Deactivated.</p>
    </div>
    <div class="second">
        <strong>Company Account for:</strong>
        <p>Company Name</p>
        <p>
            {{companyInfo.companyAddress?.addressOne}},<small *ngIf="companyInfo.companyAddress?.addressTow"> {{companyInfo.companyAddress?.addressTow}}, </small> {{companyInfo.companyAddress?.country.name}}, {{companyInfo.companyAddress?.city}}, {{companyInfo.companyAddress?.state}}
            {{companyInfo.companyAddress?.zip}}
        </p>
        <strong> was Deactivated.</strong>
    </div>
    <div class="third">
        <p>If you think your account was Deactivated by mistake, please submit a request:</p>
        <strong>Kind Regards, Accordev Team</strong>
    </div>
    <div class="request-form">
        <a class="btn btn-primary" href="https://accordev.com/contact" target="_blank">Request Form</a>
    </div>
</div>