import { ResponseModel } from './../../../../../models/response/ResponseModel';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/_services/global/global.service';

@Component({
  selector: 'app-cancel-person',
  templateUrl: './cancel-person.component.html',
  styleUrls: ['./cancel-person.component.scss']
})
export class CancelPersonComponent implements OnInit {
user:any={}
companyInfo:any = {}
  constructor(private _globalService:GlobalService) { }

  ngOnInit() {
    // this.user = JSON.parse(localStorage.getItem('user'))
    // this._globalService.companyFullSummary(this.user.companyId).subscribe((response:ResponseModel)=>{
    //   console.log(response)
    //   this.companyInfo = response.data
    // })
  }

}
