import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { CarWashService } from 'src/app/core/_services/car-wash-service/car-wash.service';
import { ToasterCustomService } from 'src/app/core/_services/toaster-service/ToasterCustomService.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { ActionsCar } from '../Enum/ActionsCar';
import { HistoryCarWash } from '../Enum/HistoryCarWash';
import { PaymentType } from '../Enum/PaymentType';

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.scss']
})
export class ViewVehicleComponent implements OnInit {
  updateVehicleFormGroup: FormGroup;
  @ViewChild('updateVehicleModal') updateVehicleModal: ModalDirective;
  numCharPattern = ValidationModel.complexPattern
  isLinear = false;
  customerId: string = ''
  vehicle: any = {}
  vehicles: any = {}
  customer: any = {}
  p4 = 1
  paymentTypeEnum = PaymentType
  histories: any[] = [];
  historyCarWash = HistoryCarWash
  isExists = false
  timer: any
  user: any = {}
  actionsCar = ActionsCar
  queueData: any = {}
  countrySelectedArr = []
  countrySelected: any = {}
  constructor(
    private _formBuilder: FormBuilder,
    private _carWashService: CarWashService,
    private route: ActivatedRoute,
    public datePipe: DatePipe,
    private _toaster: ToasterCustomService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.createUpdateVehicleForm()
    this.customerId = this.route.snapshot.paramMap.get('id');
    this.getVehicleById()
    this.countrySelectedArr.unshift(localStorage.getItem('country').toLowerCase())
  }
  createUpdateVehicleForm() {
    this.updateVehicleFormGroup = this._formBuilder.group({
      vehicleType: [''],
      plateNumber: ['', Validators.pattern(this.numCharPattern)],
      vehicleModel: [''],
      phone: ['']
    });
  }
  get f() {
    return this.updateVehicleFormGroup.controls;
  }
  getVehicleById() {
    this._carWashService.getVehiclesByCustomerId(this.customerId).subscribe((response: ResponseModel) => {
      console.log(response)
      if (response.success) {
        // this.vehicle = response.data.vehicles
        this.vehicles = response.data.vehicles.map(item => item.vehicle);
        console.log(this.vehicles)
        this.customer = response.data.vehicles[0].customer
        this.queueData = response.data.vehicles[0].queueData
      }
    })
  }
  isPlateNumberExist() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (this.f.plateNumber.status == "VALID") {
        this._carWashService.isPlateNumberExist(this.user.companyId, this.f.plateNumber.value.toUpperCase()).subscribe((response: ResponseModel) => {
          if (response.success) {
            this.isExists = false
            // this.isAnotherVehicle = false
          }
          else {
            this.isExists = true
            // this.isAnotherVehicle = true
          }
        })
      }
      else {
        this.isExists = false
        return null
      }
    }, 1000)
  }
  storeVehicle(vehicle) {
    this.setValueVehicle(vehicle)
    this.vehicle = vehicle
    this.updateVehicleModal.show()
  }
  setValueVehicle(vehicle: any) {
    this.updateVehicleFormGroup.setValue({
      vehicleType: vehicle.vehicleType,
      plateNumber: vehicle.plateNumber,
      vehicleModel: vehicle.vehicleModel,
      phone: vehicle.phone != undefined ? vehicle.phone : '',
    })
  }
  storeHistories(vehicle: any) {
    this.p4 = 1
    if (!vehicle.histories) {
      this.histories = []
    }
    else {
      this.histories = vehicle.histories.filter(element => element.key != this.historyCarWash.Check_In_Action && element.key != this.historyCarWash.Check_Out_Action)
    }
  }
  // updateVehicle(){

  // }
  updateVehicle() {
    let item = this.updateVehicleFormGroup.value
    let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
    let history = {
      id: this.vehicle?.histories ? this.vehicle?.histories.length + 1 : 2,
      userId: this.user.id,
      branchId: this.user.companyId,
      addedBy: this.user.displayName,
      branchName: currentCompany.companyName,
      dateHistory: new Date(),
      timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      key: this.historyCarWash.Update_Vehicle,
      eventName: 'updated'
    }
    let model = {
      vehicleType: item.vehicleType,
      plateNumber: item.plateNumber.toUpperCase().replace(/\s/g, ''),
      vehicleModel: item.vehicleModel,
      history: history,
      condition: this.vehicle.condition,
      qrCodeNumber: this.vehicle.qrCodeNumber,
      phone: this.vehicle.phone
    }
    // console.log(model)
    this._carWashService.updateVehicle(this.vehicle.id, model).subscribe((response: ResponseModel) => {
      // console.log(response)
      if (response.success) {
        this.vehicle = response.data
        this._toaster.success('', 'Car Profile Successfully Updated')
      }
    })
  }
  selectAction(event: any, vehicle: any) {
    {
      if (event == this.actionsCar.View_Card_Subscription) {
        this.createPortalCustomerStripeCustom(vehicle)
      }
      if (event == this.actionsCar.Create_Card_Subscription) {
        this.router.navigate(['/user/products', vehicle.id])
      }
    }
  }
  createPortalCustomerStripeCustom(vehicle: any) {
    if (!Capacitor.isNativePlatform()) {
      let currentCompany = this.user.companyData.find(c => c.companyId == this.user.companyId)
      let history = {
        userId: this.user.id,
        branchId: this.user.companyId,
        addedBy: this.user.displayName,
        branchName: currentCompany.companyName,
        dateHistory: new Date(),
        timeHistory: this.datePipe.transform(new Date(), 'H:mm'),
      }
      this._carWashService.createPortalCustomerStripeCustom(vehicle.id, vehicle.paymentType, history).subscribe((response: ResponseModel) => {
        if (response.success) {
          window.location.href = response.data
        }
      })
    }
  }
  redirection() {

  }
  timeConverter(UNIX_timestamp) {
    return new Date(UNIX_timestamp * 1000)
  }
  yourComponentMethodToTreatyCountryChangedEvent(event) {
    this.countrySelected = event
  }
}
