import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-NotPayPeriod-company',
  templateUrl: './NotPayPeriod-company.component.html',
  styleUrls: ['./NotPayPeriod-company.component.scss']
})
export class NotPayPeriodCompanyComponent implements OnInit {
  companyInfo:any = {}
  constructor() { }

  ngOnInit() {
    this.companyInfo = JSON.parse(localStorage.getItem('user'))
  }

}
