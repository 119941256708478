export enum ToolJobsEditItem {
  jobStatus = 0,
  jobSummary = 1,
  assignedCustomers = 2,
  assignedUsers = 3,
  assigned_Truck = 4,
  steps = 5,
  Date_and_Time = 6,
  notes = 7,
  estimate = 8,
  inventory = 9,
  containers = 10,
  signatures = 11,
  ToolBox = 16,
  Repeat = 17,
  jobInformation = 18,
  primaryCustomerInformation = 19,
  progressStatus = 20,
  Assigned_Vehicle = 21,
  Military_Bill = 22,
  Qr_Code_Inv = 23,
}
