import { JobDetailsService } from 'src/app/core/_services/job-details/job-details.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { of, Observable, Subscription } from 'rxjs';
import { StepsService } from '../_services/steps/steps.service';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ToolsService } from '../_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { GlobalService } from '../_services/global/global.service';
import { ShowItemsService } from '../_services/global/showsItemsService/show-items.service';

@Injectable()

export class StatusJobsResolver implements Resolve<any[]>
{
  jobsTools: any = {}
  statusJobsObj: any = {}
  user:any={}
  subscription: Subscription;
  constructor(
    private _jobDetailsService: JobDetailsService,
    private _toolsService: ToolsService,
    private _globalService: GlobalService,
    private _showItemsService: ShowItemsService,
    ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    this.user = JSON.parse(localStorage.getItem('user'))
      if(this._showItemsService.currentItems != undefined){
        this.subscription = this._showItemsService.currentItems.subscribe(res=>{
        })
      }
      if (this._globalService.items != undefined && this._globalService.items.success) {
        this.jobsTools = this._globalService.items.data.find(d => d.toolType === ToolType.Jobs_Tools)
        this.statusJobsObj = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolJobsEditItem.jobStatus)
        if( this.user.role =='admin' || this.statusJobsObj.itemStatus == "Active" && this.statusJobsObj.roleStatus == "active" || this.statusJobsObj.itemStatus == "Active" && this.statusJobsObj.roleStatus == "readOnly") {
          return this._jobDetailsService.getJobDetailsByIdItem(route.params['id'],ToolJobsEditItem.jobStatus).pipe(
            catchError(error => {
              // console.log(error)
              return of(null);
            })
          );
        }
      }
      else if(this._globalService.items == undefined){
        if (this._globalService.items != undefined && this._globalService.items.success) {
          this.jobsTools = this._globalService.items.data.find(d => d.toolType === ToolType.Jobs_Tools)
          this.statusJobsObj = this._toolsService.getPermission(this.user.role, this.jobsTools, ToolJobsEditItem.jobStatus)
          if( this.user.role =='admin' || this.statusJobsObj.itemStatus == "Active" && this.statusJobsObj.roleStatus == "active" || this.statusJobsObj.itemStatus == "Active" && this.statusJobsObj.roleStatus == "readOnly") {
            return this._jobDetailsService.getJobDetailsByIdItem(route.params['id'],ToolJobsEditItem.jobStatus).pipe(
              catchError(error => {
                // console.log(error)
                return of(null);
              })
            );
          }
        }
      }
  }
}
