import { TrucksService } from './../_services/trucks/trucks.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()

export class GetTrucksByCompanyResolver implements Resolve<any[]>
{
  constructor(private _trucksService: TrucksService) { }
  resolve(): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    return this._trucksService.getAllTrucksByCompany(user.companyId).pipe(
      catchError(error => {
        // console.log(error)
        return of(null);
      })
    );
  }
}
