import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class ToasterCustomService {

constructor(
  private _toastrService:ToastrService
) { }

success(message:string,tittle:string){
  this._toastrService.success(message, tittle,
  {
    progressBar: true,
    titleClass : 'toast-title',
    progressAnimation: "increasing",
    timeOut: 7000
  });
}
info(message:string,tittle:string){
  this._toastrService.info(message, tittle,
  {
    progressBar: true,
    titleClass : 'toast-title',
    progressAnimation: "increasing",
    timeOut: 2000
  });
}
error(message:string,tittle:string){
  this._toastrService.error(message, tittle,
  {
    progressBar: true,
    titleClass : 'toast-title',
    progressAnimation: "increasing",
    timeOut: 7000
  });
}
warning(message:string,tittle:string){
  this._toastrService.warning(message, tittle,
  {
    progressBar: true,
    titleClass : 'toast-title',
    progressAnimation: "increasing",
    timeOut: 7000
  });
}
}
