<!-- section Navbar dashboard -->
<div class="second-navbar">
    <div class="logo" [routerLink]="['/user']">
        <!-- <img src="./assets/img/logo/logo.svg" class="" alt="logo-img"> -->
        <!-- <img src="https://via.placeholder.com/150" class="pl-3 logo-img" alt="logo-img"> -->
        <img [attr.src]="logoUrl ? logoUrl: 'https://via.placeholder.com/150'" class="pl-3 logo-img" alt="logo-img">
        <!-- <a>Maplly</a> -->
    </div>
    <div class="title">
        <p class="text-center"> {{ownerName}} |
            <small class="role" *ngIf="role == 'teamleader'">
            Team Leader
          </small>
            <small class="role" *ngIf="role != 'teamleader'">
            {{role}}
          </small>
        </p>
        <hr>
        <span>{{companyName}}
        <small class="company-address">{{currentCompany.companyAddress?.addressOne}}, {{currentCompany.companyAddress?.city}}</small>
      </span>
    </div>
    <button class="btn more" mdbBtn color="primary" [matMenuTriggerFor]="menuCompany">
      <img *ngIf="isOnline" src="{{logo_online}}" class="" alt="logo-one-letter-img">
      <img *ngIf="!isOnline" src="{{logo_test}}" class="" alt="logo-one-letter-img">
  <small *ngIf="user.companyPlan == 0">BASIC</small>
  <small  *ngIf="user.companyPlan == 1">PRO</small>
  <small  *ngIf="user.companyPlan == 2">PREMIUM</small>
  <!-- <i class="fas fa-exchange-alt"></i> -->
  <i class="uil uil-exchange-alt uil-large"></i>
</button>
</div>
<!-- Menu Companies -->
<mat-menu #menuCompany="matMenu">
    <div class="header-menu">
        <h6 class="companyName">Accordev Accounts</h6>
    </div>
    <button mat-menu-item *ngFor="let company of companies" [disabled]="company.companyId == user.companyId" (click)="chooseCompany(company)">
  <img  [attr.src]="company.companyLogo ? company.companyLogo: 'https://via.placeholder.com/150'" [ngClass]="[company.companyId == user.companyId ? 'active-company' : 'not-active-company']" alt="{{company.companyName}}Logo">
    <span >{{company.companyName}} | {{company.role}}
      <small>{{company.companyAddress?.addressOne}}<small *ngIf="company.companyAddress?.addressTow">, {{company.companyAddress?.addressTow}}</small>, {{company.companyAddress?.city}} {{company.companyAddress?.state}}  {{company.companyAddress?.zip}}</small>
    </span>
    </button>
    <div class="footer-menu" [routerLink]="['/user/manage-accounts']">
        <a>Manage Accounts List</a>
        <!-- <span> -->
        <small>Search, Hide or Unhide a Company from the List</small>
        <!-- </span> -->
    </div>
    <div class="footer-menu logout" (click)="logout()">
        <a>
            <!-- <i class="uil uil-sign-out-alt"></i> -->
            Log Out</a>
        <!-- <small>Create a New Branch or a New Company</small> -->
    </div>
    <!-- <div class="footer-menu" (click)="logout()">
        <a>Create New</a>
        <small>Create a New Branch or a New Company</small>
    </div> -->
</mat-menu>
<!-- Row Icons -->
<div class="container">
    <div class="search_map_customers">
        <div class="row">
            <div class="col-3" [ngClass]="knowledgeBase.itemStatus == 'InActive' && customersList.itemStatus == 'InActive'  || knowledgeBase.itemStatus == 'Active' && knowledgeBase.roleStatus == 'notActive' &&  customersList.itemStatus == 'Active' && customersList.roleStatus == 'notActive' ? 'col-6' : 'col-3'">
                <button class="btn" mdbBtn color="primary" [routerLink]="['/user',userId]">
                  <i class="fas fa-home"></i>
                </button>
            </div>
            <!-- Knowlwdge Base  -->
            <div class="col-3" [ngClass]="customersList.itemStatus == 'InActive' || customersList.roleStatus == 'notActive' ? 'col-6' : 'col-3'" *ngIf="knowledgeBase.itemStatus == 'Active' && knowledgeBase.roleStatus === 'active' || knowledgeBase.itemStatus == 'Active' && knowledgeBase.roleStatus === 'readOnly'">
                <button class="btn" mdbBtn color="primary" (click)="HelpModal.show()">
                   <i class="fas fa-question"></i>
               </button>
            </div>
            <!-- customers List -->
            <div class="col-3" [ngClass]="knowledgeBase.itemStatus == 'InActive' || knowledgeBase.roleStatus == 'notActive' ? 'col-6' : 'col-3'" *ngIf="customersList.itemStatus === 'Active' && customersList.roleStatus === 'active' || customersList.itemStatus === 'Active' && customersList.roleStatus === 'readOnly'">
                <button class="btn" mdbBtn color="primary" [routerLink]="['/user/customers-list'] ">
                 <i class="fas fa-users "></i>
              </button>
                <!-- <button  [routerLink]="['/user/customers-list'] " class="btn read-only" mdbBtn color="primary">
                 <i class="fas fa-users "></i>
             </button> -->
                <!-- <span *ngIf="customersList.itemStatus === 'Active' && customersList.roleStatus === 'notActive' || customersList.itemStatus === 'InActive'">
               </span> -->
            </div>
            <div class="col-3" [ngClass]="knowledgeBase.itemStatus == 'InActive' && customersList.itemStatus == 'InActive' || knowledgeBase.itemStatus == 'Active' && knowledgeBase.roleStatus == 'notActive' &&  customersList.itemStatus == 'Active' && customersList.roleStatus == 'notActive' ? 'col-6' : 'col-3'">
                <button class="btn more" mdbBtn color="primary" [matMenuTriggerFor]="menu">
                   <!-- <i class="fas fa-ellipsis-v"></i> -->
                   <!-- <i class="uil uil-create-dashboard " ></i> -->
                   <i class="uil uil-apps"></i>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="first-item-list">
                    <!-- <i class="uil uil-plus"></i> -->
                    <!-- <i class="uil uil-plus-square"></i> -->
                    <!-- <i class="uil uil-apps"></i> -->
                    <div class="item">
                      <span>Other Add-Ins</span>
                    </div>
                </button>
                    <!-- users -->
                    <button *ngIf="usersList.itemStatus === 'Active' && usersList.roleStatus === 'active' && user.companyPlan >= planCompanyType.PRO" mat-menu-item [routerLink]="['/user/users-list'] ">
                    <!-- <i class="fas fa-user-cog"></i> -->
                    <!-- <img src="./assets/img/icons/menu_icons/new_Icons/Company-Users.svg" class="pr-1" alt="users"> -->
                    <i class="uil uil-user-circle"></i>
                    <div class="item">
                      <span>Members</span>
                      <small>Add, invite or disable a customer</small>
                    </div>
                </button>
                    <button *ngIf="usersList.itemStatus === 'Active' && usersList.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PRO" mat-menu-item [routerLink]="['/user/users-list'] ">
                  <!-- <i class="fas fa-user-cog"></i> -->
                  <!-- <img src="./assets/img/icons/menu_icons/new_Icons/Company-Users.svg" class="pr-1" alt="users"> -->
                  <i class="uil uil-user-circle"></i>
                  <div class="item">
                    <span>Members</span>
                    <small>Add, invite or disable a customer</small>
                  </div>
                </button>
                    <span *ngIf="usersList.itemStatus === 'Active' && usersList.roleStatus === 'notActive' || usersList.itemStatus === 'InActive'">
                  </span>
                    <!-- Qr  codes moving-->
                    <!-- <button *ngIf="user.categoryCompany == categoryCompany.Inventory && qrCodeMoving.itemStatus === 'Active' && (qrCodeMoving.roleStatus === 'readOnly' || qrCodeMoving.roleStatus === 'active')" mat-menu-item [routerLink]="['/admin/qr-codes-template'] ">
                      <i class="uil uil-qrcode-scan"></i>
                      <div class="item">
                        <span>QR Codes</span>
                        <small>Print qr codes, Multiple templates</small>
                      </div>
                  </button> -->
                    <!-- Qr  -->
                    <button *ngIf="user.categoryCompany == categoryCompany.Car_Wash && qrCode.itemStatus === 'Active' && qrCode.roleStatus === 'active' && user.companyPlan >= planCompanyType.PRO || qrCode.itemStatus === 'Active' && qrCode.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PRO "
                        mat-menu-item [routerLink]="['/user/car-wash-template'] ">
                      <i class="uil uil-label-alt"></i>
                      <div class="item">
                        <span>Membership Stickers</span>
                        <small>Print stickers, Multiple templates</small>
                      </div>
                   </button>
                    <!-- Qr code stickers  car wash-->
                    <button *ngIf="user.categoryCompany == categoryCompany.Car_Wash && qrCodeStickers.itemStatus === 'Active' && qrCodeStickers.roleStatus === 'active' && user.companyPlan >= planCompanyType.PREMIUM || qrCodeStickers.itemStatus === 'Active' && qrCodeStickers.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PREMIUM "
                        mat-menu-item [routerLink]="['/admin/qr-code-stickers'] ">
                        <i class="uil uil-qrcode-scan "></i>
                      <div class="item">
                        <span>QR Code Stickers</span>
                        <small>Print stickers, Multiple templates</small>
                      </div>
                  </button>
                    <!-- car-wash  -->
                    <button *ngIf="user.categoryCompany == categoryCompany.Car_Wash && carWashVehicle.itemStatus === 'Active' && carWashVehicle.roleStatus === 'active' && user.companyPlan >= planCompanyType.PREMIUM || carWashVehicle.itemStatus === 'Active' && carWashVehicle.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PREMIUM "
                        mat-menu-item [routerLink]="['/user/vehicles-list'] ">
                        <i class="uil uil-car-wash"></i>
                      <div class="item">
                        <span>Car Wash Manager</span>
                        <small>Add/Edit a vehicle, Statistics</small>
                      </div>
                  </button>
                    <button *ngIf="user.categoryCompany == categoryCompany.Car_Wash && carWashServices.itemStatus === 'Active' && carWashServices.roleStatus === 'active' && user.companyPlan >= planCompanyType.PRO || carWashVehicle.itemStatus === 'Active' && carWashServices.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PRO "
                        mat-menu-item [routerLink]="['/admin/carwash-services'] ">
                         <i class="uil uil-briefcase-alt"></i>
                        <div class="item">
                          <span>Car Wash Services</span>
                          <small>Add/Edit one-time services</small>
                        </div>
                    </button>
                    <button *ngIf="user.categoryCompany == categoryCompany.Car_Wash && carwashWebsiteObj.itemStatus === 'Active' && carwashWebsiteObj.roleStatus === 'active'  || carwashWebsiteObj.itemStatus === 'Active' && carwashWebsiteObj.roleStatus === 'readOnly' " mat-menu-item
                        [routerLink]="['/user/customize-website'] ">
                        <i class="uil uil-globe"></i>
                      <div class="item">
                        <span>Car Wash Website</span>
                        <small>Customize car wash website</small>
                      </div>
                    </button>
                    <!-- Start Trucks -->
                    <!-- <button *ngIf="trucksList.itemStatus === 'Active' && trucksList.roleStatus === 'active' && user.companyPlan >= planCompanyType.PRO " mat-menu-item [routerLink]="[ '/user/trucks-list'] ">
                     <i class="uil uil-truck"></i>
                     <div class="item">
                        <span>Trucks</span>
                        <small>Add Truck, Assign Driver</small>
                      </div>
                  </button> -->
                    <!-- read only -->
                    <!-- <button *ngIf="trucksList.itemStatus === 'Active' && trucksList.roleStatus === 'readOnly' && user.companyPlan >= planCompanyType.PRO " mat-menu-item [routerLink]="[ '/user/trucks-list'] ">
                      <i class="uil uil-truck"></i>
                      <div class="item">
                        <span>Trucks</span>
                        <small>Add Truck, Assign Driver</small>
                      </div>
                 </button> -->
                    <!-- <span *ngIf="trucksList.itemStatus === 'Active' && trucksList.roleStatus === 'notActive' || trucksList.itemStatus === 'InActive'"> </span> -->
                    <!-- End Trucks -->
                    <!-- <button mat-menu-item>
                        <img src="./assets/img/icons/menu_icons/new_Icons/Knowledge_database.svg" class="pr-1" alt="Knowledge_database">
                        <div class="item">
                          <span>Knowledge Base</span>
                          <small>Demos, FAQ, Ask a Question</small>
                        </div>
                    </button> -->
                    <!-- <button *ngIf="user.categoryCompany == categoryCompany.Inventory && whorehousePermission.itemStatus === 'Active' && (whorehousePermission.roleStatus === 'active' || whorehousePermission.roleStatus === 'readOnly') && user.companyPlan >= planCompanyType.PREMIUM  "
                        mat-menu-item [routerLink]="[ '/map'] ">
                      <i class="uil uil-map"></i>
                      <div class="item">
                        <span>Warehouse Map</span>
                        <small>Add & Move container, Warehouse Statistics</small>
                      </div>
                  </button> -->
                    <!-- ticket permissions -->
                    <button *ngIf="supportTickets.itemStatus === 'Active' && supportTickets.roleStatus === 'active' " mat-menu-item>
                            <!-- <img src="./assets/img/icons/menu_icons/new_Icons/Submit_a_ticket.svg" class="pr-1" alt="Submit_a_ticket"> -->
                            <i class="uil uil-tag-alt"></i>
                            <div class="item" [routerLink]="['/user/add-ticket']" >
                              <span>Support Tickets</span>
                              <small>Troubleshooting, Contact Support</small>
                            </div>
                        </button>
                    <button *ngIf="supportTickets.itemStatus === 'Active' && supportTickets.roleStatus === 'readOnly'" mat-menu-item>
                          <!-- <img src="./assets/img/icons/menu_icons/new_Icons/Submit_a_ticket.svg" class="pr-1" alt="Submit_a_ticket" > -->
                          <i class="uil uil-tag-alt"></i>
                          <div class="item" [routerLink]="['/user/add-ticket']">
                            <span>Support Tickets</span>
                            <small>Troubleshooting, Contact Support</small>
                          </div>
                       </button>
                    <span *ngIf="supportTickets.itemStatus === 'Active' && supportTickets.roleStatus === 'notActive' || supportTickets.itemStatus === 'InActive'"></span>
                    <button mat-menu-item *ngIf="user.role == 'customer' && user.isCustomerCarWash " [routerLink]="[ '/user/view-vehicle/',user.id] ">
                      <i class="uil uil-car"></i>
                    <div class="item">
                        <span>Vehicle Profle </span>
                        <small>information,subscriptions</small>
                    </div>
                 </button>
                    <!-- Start  My Profile -->
                    <button mat-menu-item *ngIf="myProfile.itemStatus === 'Active' && myProfile.roleStatus === 'active' " [routerLink]="[ '/user/my-profile'] ">
                      <i class="uil uil-user-square"></i>
                    <div class="item">
                        <span>My Profle </span>
                        <small>Name, Phone, Email, Address</small>
                    </div>
                 </button>
                    <button mat-menu-item *ngIf="myProfile.itemStatus === 'Active' && myProfile.roleStatus === 'readOnly'  " [routerLink]="[ '/user/my-profile'] ">
                  <!-- <div class="icon-img">
                    <img src="./assets/img/icons/menu_icons/new_Icons/My_profile.svg" class="pr-1" alt="Name, Phone, Email, Address">
                  </div> -->
                  <i class="uil uil-user-square"></i>
                  <div class="item">
                      <span>My Profle </span>
                      <small>Name, Phone, Email, Address</small>
                  </div>
               </button>
                    <!-- <button mat-menu-item (click)="logout()">
                      <i class="uil uil-sign-out-alt"></i>
                      <div class="item">
                        <span>Log Out</span>
                      </div>
                  </button> -->
                </mat-menu>
            </div>
        </div>
    </div>
    <!-- Router about dashboard pages  -->
    <router-outlet></router-outlet>
</div>
<!-- modal Help -->
<div mdbModal #HelpModal="mdbModal" class="modal knowledge-base">
    <div class="modal-dialog modal-lg" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Knowledge Base</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="HelpModal.hide()">
                <span aria-hidden="true">&times;</span>
             </button>
                </div>
                <div class="modal-body">
                    <div class="tabs-apps">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <img src="./assets/book-open.svg" alt="book-open"> Installing the Accordev App
                                </mat-expansion-panel-header>
                                <div class="tabs-content">
                                    <mat-tab-group [disableRipple]="false">
                                        <mat-tab label="Andriod">
                                            <br>
                                            <strong>Installing the Accordev App on Andriod </strong>
                                            <ul>
                                                <li>1. On your Android device,<a href="https://play.google.com/store/apps/details?id=com.android.chrome&referrer=utm_source%3Dhelp-center%26utm_medium%3Dhc-answer%26utm_campaign%3Dhelp-center-mg"> open Chrome <i class="fab fa-chrome"></i></a></li>
                                                <li>2. Go to <a href="https://app.accordev.com/">Accordeb web app.</a></li>
                                                <li>3. At the top right of the address bar, click More. <i class="uil uil-ellipsis-v uil-small"></i></li>
                                                <li>4. Tap <b>[Add to Home screen].</b> </li>
                                                <li>5. Follow the on-screen instructions.</li>
                                            </ul>
                                            <strong>The web app will be installed, and you can find it on the home screen.</strong>
                                            <div class="notes mt-2">
                                                <b>Notes: </b>
                                                <ul class="mt-0">
                                                    <li> It only takes a few seconds to install the app.</li>
                                                    <li>If you have difficulty installing the app, clear the browser cache:
                                                        <br>
                                                        <b> Step 1:</b> At the top right of the address bar, click More. <i class="uil uil-ellipsis-v uil-small"></i><br>
                                                        <b>Step 2: </b> Select Settings > Site Settings > All Sites > Select http://app.accordev.com > Click on [Clear & reset].
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="iPhone & iPad">
                                            <br>
                                            <strong>Installing the Accordev App on iPhone & iPad </strong>
                                            <ul>
                                                <li>1. On your iPhone or iPad, <b>open Safari .</b></li>
                                                <li>2. Go to <a href="https://app.accordev.com/">Accordeb web app <i class="uil uil-external-link-alt uil-small"></i>.</a></li>
                                                <li>3. Tap <i class="uil uil-upload uil-small"></i> <b>[Add to Home screen]</b>. </li>
                                                <li>4. Follow the on-screen instructions.</li>
                                            </ul>
                                            <strong>The web app will be installed, and you can find it on the home screen.</strong>
                                            <div class="notes mt-2">
                                                <b>Notes: </b>
                                                <ul class="mt-0">
                                                    <li> It only takes a few seconds to install the app.</li>
                                                    <li>If you have difficulty installing the app, clear the browser cache:
                                                        <br>
                                                        <span>1.Launch the <b>Settings</b> app on your iOS device.</span> <br>
                                                        <span>2.Scroll down and select <b>Safari.</b></span> <br>
                                                        <span>3.<b>Advanced > Website Data.</b></span> <br>
                                                        <span>4.<b>At the top right, tap Edit > Remove Accordev Website Data.</b> </span> <br>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Windows">
                                            <br>
                                            <strong>Installing the Accordev App on Windows PC  </strong>
                                            <ul>
                                                <li>1. On your computer, <a href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop&oco=1#zippy=%2Cwindows"> open Chrome <i class="fab fa-chrome"></i></a></li>
                                                <li>2. Go to <a href="https://app.accordev.com/">Accordeb web app <i class="uil uil-external-link-alt uil-small"></i>.</a></li>
                                                <li>3. At the top right of the address bar, click Install.<i class="uil uil-download-alt uil-small"></i> </li>
                                                <li>4. Follow the on-screen instructions.</li>
                                            </ul>
                                            <strong>The web app will be installed, and you can find it on the home screen.</strong>
                                            <div class="notes mt-2">
                                                <b>Notes: </b>
                                                <ul class="mt-0">
                                                    <li>It only takes a few seconds to install the app.</li>
                                                    <li>If you have difficulty installing the app, clear the browser cache:
                                                        <br>
                                                        <span>1.On your computer, open Chrome.</span> <br>
                                                        <span>2.At the top right of the address bar, click More  <i class="uil uil-ellipsis-v uil-small"></i></span> <br>
                                                        <span>3.Settings> Privacy and security > Cookies and other site data > Site settings > View permissions and data stored across sites.</span> <br>
                                                        <span>4.Next to [app.accordev.com], click on the delete Icon.</span> <br>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Mac OS">
                                            <br>
                                            <strong>Installing the Accordev App on Mac OS   </strong>
                                            <ul>
                                                <li>1. On your Mac, <a href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop&oco=1#zippy=%2Cwindows"> open Chrome <i class="fab fa-chrome"></i></a></li>
                                                <li>2. Go to <a href="https://app.accordev.com/">Accordeb web app <i class="uil uil-external-link-alt uil-small"></i>.</a></li>
                                                <li>3. At the top right of the address bar, click Install.<i class="uil uil-download-alt uil-small"></i> </li>
                                                <li>4. Follow the on-screen instructions.</li>
                                            </ul>
                                            <strong>The web app will be installed, and you can find it on the home screen.</strong>
                                            <div class="notes mt-2">
                                                <b>Notes: </b>
                                                <ul class="mt-0">
                                                    <li>It only takes a few seconds to install the app.</li>
                                                    <li>If you have difficulty installing the app, clear the browser cache:
                                                        <br>
                                                        <span>1.On your Mac, open Chrome.</span> <br>
                                                        <span>2.At the top right of the address bar, click More   <i class="uil uil-ellipsis-v uil-small"></i></span> <br>
                                                        <span>3.Settings> Privacy and security > Cookies and other site data > Site settings > View permissions and data stored across sites.</span> <br>
                                                        <span>4.Next to [app.accordev.com], click on the delete Icon.</span> <br>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Linux">
                                            <br>
                                            <strong>Installing the Accordev App on Linux PC   </strong>
                                            <ul>
                                                <li>1. On your computer, <a href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop&oco=1#zippy=%2Cwindows"> open Chrome <i class="fab fa-chrome"></i></a></li>
                                                <li>2. Go to <a href="https://app.accordev.com/">Accordeb web app .</a></li>
                                                <li>3. At the top right of the address bar, click Install<i class="uil uil-download-alt uil-small"></i> </li>
                                                <li>4. Follow the on-screen instructions.</li>
                                            </ul>
                                            <strong>The web app will be installed, and you can find it on the home screen.</strong>
                                            <div class="notes mt-2">
                                                <b>Notes: </b>
                                                <ul class="mt-0">
                                                    <li>It only takes a few seconds to install the app.</li>
                                                    <li>If you have difficulty installing the app, clear the browser cache:
                                                        <br>
                                                        <span>1.On your computer, open Chrome.</span> <br>
                                                        <span>2.At the top right of the address bar, click More <i class="uil uil-ellipsis-v uil-small"></i></span> <br>
                                                        <span>3.Settings> Privacy and security > Cookies and other site data > Site settings > View permissions and data stored across sites.</span> <br>
                                                        <span>4.Next to [app.accordev.com], click on the delete Icon.</span> <br>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <!-- <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <img src="./assets/book-open.svg" alt="book-open"> Installing the Accordev App</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*When a user opens Accordev Web App using the Chrome browser for the first time، there will be always a pop up with a clickable text [Add Accordev to Home screen], in order to install the Accordev web app.</i></small>
                                </li>
                                <li>
                                    <small><i>*The pop up shows in the bottom of the home page, in whatever browser the user uses, but for the best user experience, the Accordev team recommends browsing the Accordev web app with Google Chrome browser.</i></small>
                                </li>
                                <li>
                                    <small><i>*The Accordev web app is very light, and it will be installed even if your phone doesn't have any available storage. </i></small>
                                </li>
                                <li>
                                    <small><i>*If you have difficulty in installing the app, Please read the notes below.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Go to the Accordev log in page.</li>
                                <li>Step 2: Click on the pop up [Add Accordev to Home screen].</li>
                                <li>Step 3: Confirm installing the app.</li>
                            </ul>
                            <p class="med-paragraph">If the pop up did not show up, you can install Accordev web app manually:</p>
                            <ul class="pl-0">
                                <li>Step 1: Click on the three dots on the top right corner of the Google chrome browser.</li>
                                <li>Step 2: Click on [Install app].</li>
                                <li>Step 3: Confirm installing the app.</li>
                            </ul>
                            <p class="med-paragraph">The web app will be installed without any further action needed.</p>

                            <div class="notes">
                                <b>Notes: </b>
                                <ul class="mt-0">
                                    <li> It only takes a few seconds to install the app.</li>
                                    <li> You will find the app icon already added to the home screen.</li>
                                    <li> If you have difficulty in installing the app, clear the browser cache:
                                        <ul class="mt-0">
                                            <li>Step 1: Click on the three dots on the top right corner of the Google chrome browser.</li>
                                            <li>Step 2: Click on Settings> Privacy and Security> Clear browsing data.</li>
                                            <li>Step 3: Choose the time range and select deleting preferences.</li>
                                            <li>Step 4: Click on the [Clear data] button.</li>
                                            <li>Step 4: Try to install the Accordev app once again.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion> -->
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty'">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <img src="./assets/book-open.svg" alt="book-open"> Customize software with the Add-Ins Store</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*All Add-Ins are located in Add-Ins Store. This is only accessible by the admin and manager.</i></small>
                                </li>
                                <li>
                                    <small><i>*Any Add-Ins that need to be activated MUST be included in the Company subscription plan.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home Page> Menu> Add-Ins Store.</li>
                                <li>Step 2: Choose Add-In category.</li>
                                <li>Step 3: Download the Add-ins needed.</li>
                                <li>Step 4: Review Add-In permission settings. </li>
                                <li>Step 5: If visible, activate or deactivate email notification(s).</li>
                            </ul>
                            <div class="notes">
                                <b>Notes: </b>
                                <ul class="mt-0">
                                    <li> In the Add-Ins Store, Add-Ins are listed under three categories: Home Page, Job Dashboard, and Others.</li>
                                    <li> When an admin or a manager download an Add-In, it will appear in a specific location.</li>
                                </ul>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.categoryCompany == categoryCompany.Inventory">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Adding or Inviting a Customer</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Customers Add-Ins are included in any Company Subscription Plan.</i></small>
                                </li>
                                <li>
                                    <small><i>*Customers Add-Ins MUST be downloaded in the Add-ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*Customer Add-Ins are always accessible by admin and manager. The permission setting must be changed, in order to be accessible by lower roles.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>
                                    <b>Step 1:</b> Home Page > Menu> Customers <br> <u>You can choose between the two options:</u> <br> Option 1: Invite a New Customer. <br> Option 2: Add a Customer Manually. <br> <br>
                                </li>
                                <li>
                                    <b>For option 1:</b>
                                    <ul class="mt-0">
                                        <li>Step 1 : Click on [Invite a New Customer].</li>
                                        <li>
                                            Step 2 :
                                            <ul class="mt-0">
                                                <li>A- Enter customer email.</li>
                                                <li> B- Make sure the role is customer .</li>
                                                <li>C- Click [Invite]. </li>
                                            </ul>
                                        </li>
                                        <li class="notes">
                                            <b> Notes:  </b>
                                            <ul class="mt-0">
                                                <li><small>You can make sure that the invitation is sent by chicking, invited customers list, in the pending invites tab.</small> </li>
                                                <li><small>You can re-invite customers or cancel the invitation. </small></li>
                                                <li><small>When a customer signs up, the customer invitation record is pending, the invite tab disappears, and a new record will be added in the customers tab.</small></li>
                                                <li><small>In the customers tab, you can change customer status between [Customer] and [Disable], edit customer profile, and view/edit assigned jobs.</small></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <br> <b>For option 2:</b>
                                    <ul class="mt-0">
                                        <li> Step 1: Click on [Add a Customer Manually].</li>
                                        <li>
                                            Step 2:
                                            <ul class="mt-0">
                                                <li> A- Enter customer information.</li>
                                                <li> B- Click [Add Add Customer].</li>
                                            </ul>
                                        </li>
                                        <li class="notes">
                                            <b> Notes:  </b>
                                            <ul class="mt-0">
                                                <li><small>You can make sure that a customer is added by chicking customers list in the customers page.</small></li>
                                                <li><small>You can invite and re-invite customers added manually.</small></li>
                                                <li><small>After inviting a customer, and once the customer signs up, in the same customer record in the customer list, the button [Re-invite] turns to [Signed Up]</small></li>
                                                <li><small>After a customer signs up, you can switch the customer status between [Active] and [Disabled].</small></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty'">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Inviting a Member (Employee & 3rd Party)</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Members Add-Ins MUST be included in the Company Subscription Plan.</i></small>
                                </li>
                                <li>
                                    <small><i>*Members Add-Ins MUST be activated in the Add-Ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*Members Add-Ins are always accessible by the admin and manager. The permission setting must be changed, in order to be accessible by lower role.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home page > Menu> Members.</li>
                                <li>Step 2: Click on [Invite a new user].</li>
                                <li>Step 3:
                                    <ul class="mt-0">
                                        <li>A- Enter Member Email.</li>
                                        <li>B- Choose Member Role</li>
                                        <li>C- Click [Invite].</li>
                                    </ul>
                                </li> <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>You can make sure that the invitation is sent by chicking invited, under Members Page> Pending Invites Tab.</li>
                                        <li>You can re-invite users or cancel the invitation.</li>
                                        <li>When a user signs up, the user invitation record in the pending invites tab disappears, and a new record will be added in users tab.</li>
                                        <li>In the users tab, you can change the user role, disable the user account, or edit the user profile and view/edit assigned jobs.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty' && user.categoryCompany == categoryCompany.Inventory">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Adding a truck</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Trucks Add-Ins MUST be included in the Company Subscription Plan.</i></small>
                                </li>
                                <li>
                                    <small><i>*Trucks Add-Ins MUST be activated in the Add-Ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*Trucks Add-Ins are always accessible by the admin and manager. The permission setting must be changed, in order to be accessible by lower role.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home Page > Menu> Trucks. </li>
                                <li>Step 2: Click on [Add a New Truck].</li>
                                <li class="alphabet">Step 3:
                                    <ul class="mt-0 ">
                                        <li>Enter Truck Information.</li>
                                        <li>Choose Truck Type.</li>
                                        <li>Click [Add Truck].</li>
                                    </ul>
                                </li>
                                <!-- <li>Step 4: Switch truck status to In-Service.</li>  -->
                                <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>You can make sure that a truck is added by clicking the trucks list in the trucks page.</li>
                                        <li>In the trucks page, you can change any truck type, switch its status between [In-Service] and [Out of Service], or edit truck information.</li>
                                        <li>Only when truck status is in service, in any job dashboard you will be able to find the truck listed in the unassigned trucks tab, assign and assign a driver to it.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Creating a New Job</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Draft Job Add-In is included in any Company Subscription Plan.</i></small>
                                </li>
                                <li>
                                    <small><i>*Draft Job Add-In MUST be activated in the Add-Ins Store, by adding it to the Job Dashboard Toolbox.</i></small>
                                </li>
                                <li>
                                    <small><i>*Draft Job Add-In is always accessible by admin and manager. The permission setting must be changed, in order to be accessible by lower role.</i></small>
                                </li>
                                <li>
                                    <small><i>*In the Add-Ins Store, any Add-In in the Job Dashboard Category is called a Tool. </i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home Page > Draft Job.</li>
                                <li class="alphabet">Step 2: Edit Job Details:
                                    <ul class="mt-0 ">
                                        <li>Click on [Toolbox] and activate tools (Add-Ins) needed.</li>
                                        <li>Assign Member(s) (Pro Plan or Up).</li>
                                        <li>Assign Customer(s) (Any Plan).</li>
                                        <li>Assign Truck(s) (Pro Plan or Up). </li>
                                        <li>Add Location(s) (Pro Plan or Up).</li>
                                        <li>In the toolbox, if your role is permitted, edit default permission for each tool used.</li>
                                        <li>Edit the rest of the activated tools.</li>
                                    </ul>
                                </li> <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>If there is more than one customer assigned, you can choose who is the primary customer.</li>
                                        <li>In toolbox, if your role is permitted, you can activate the job status tool and change the job status.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty'">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Notifying a Customer or a Member via Email</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Email Notifications Settings are located in the Add-Ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*The Add-Ins Store is accessible only by admin and manager.</i></small>
                                </li>
                                <li>
                                    <small><i>*Each Add-In (if exists) has its own Email Notifications Settings.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home Page> Menu> Add- Ins Store.</li>
                                <li>Step 2: Choose Add-In Category. </li>
                                <li>Step 3: Choose the Add-In you need to edit its Email Notifications Settings.</li>
                                <li>Step 4: Choose the Add-In action that has the option to send an email notification, then switch the status between [Active] and [Inactive]. </li> <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>If you make an action that requires an email notification to be sent, while the coordinating Add-In Mail Notifications Status is [Active], Accordev will ask you to confirm sending the email notification or it will
                                            give you the option to cancel.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty'">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Changing an Add-Ins Permission Settings</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Permission Settings are located in the Add-Ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*Each Add-In (if needed) has its own Permission Settings.</i></small>
                                </li>
                                <li>
                                    <small><i>*The Add-Ins Store is accessible only by admin and manager.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li>Step 1: Home Page> Menu> Add- Ins Store.</li>
                                <li>Step 2: Click on Permission Settings Accordion. </li>
                                <li>Step 3: Choose the role you need to edit its permission settings.</li>
                                <li>Step 4: Switch the permission settings status between [No Access], [View Only] and [Can Edit].</li>
                                <li>Step 5: If you need to set a specific permission for a specific user or customer, you can click on [override] button.</li> <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>All permission settings are final, but the permission settings of the Job Dashboard Add-Ins in the Add-Ins Store are not. There is a second permission layer that needs to be edited, in order to override the default
                                            settings for the same Add-In in the Add-Ins Store individually</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' && user.role != '3rdparty'">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Overriding job default permissions</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>*Settings for overriding a Default Permission Settings of a Job Dashboard Tool (Add-In) is located in Job Dashboard>  Toolbox.</i></small>
                                </li>
                                <li>
                                    <small><i>*Each tool (Add-In) in the toolbox has its own setting in order to override that default setting in the Add-Ins Store.</i></small>
                                </li>
                                <li>
                                    <small><i>*Toolbox is accessible only by admin and manager. Unless the permission setting was changed.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li> Step 1: Home Page> Search.</li>
                                <li>Step 2: Choose the job you need to edit. </li>
                                <li>Step 3: Choose the Tool in the Toolbox you need to override its Permission Settings.</li>
                                <li class="alphabet">Step 4: Click on [Edit]
                                    <ul class="mt-0 ">
                                        <li>Make sure there are Customers or Members assigned to that Job.</li>
                                        <li>Choose the Customer or the Member you need to override their permission.</li>
                                        <li>Switch the permission settings status between [No Access], [View Only] and [Can Edit].</li>
                                    </ul>
                                </li> <br>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>Overriding the default permission settings in the toolbox is final, and that is what the assigned users, 3rd parties, and customers will experience.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="user.role != 'customer' ">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header> <img src="./assets/book-open.svg" alt="book-open"> Submitting a support ticket</mat-expansion-panel-header>
                            <ul class="pl-0 required">
                                <li>
                                    <small><i>* In order for the user to submit a support ticket, a user must be logged in and able to access the Support Ticket Add-in.</i></small>
                                </li>
                                <li>
                                    <small><i>*Admin always has access to the support ticket Add-ins while other users with different roles will have  access based on their add-in permission sittings.</i></small>
                                </li>
                            </ul>
                            <ul class="pl-0">
                                <li> Step 1: Login to your Accordev account.</li>
                                <li>Step 2: Go to Menu> Support Tickets. </li>
                                <li>Step 3: Click on [Add New].</li>
                                <li>Step 4: Fill in the required information. </li>
                                <li>Step 5: Take a screenshot and upload it (if possible). </li>
                                <li>Step 6: Click on [Add Ticket].</li>
                                <p class="med-paragraph">The Accordev support team will respond as soon as possible.</p>
                                <li class="notes">
                                    <b>Notes:</b>
                                    <ul class="mt-0">
                                        <li>When a ticket is submitted the user will receive an email confirmation.</li>
                                        <li>When a ticket is resolved the user will receive another email confirmation.</li>
                                        <li>There are two tabs in the support tickets page, all tickets that are not resolved yet will be found under [In Progress] tab, and all resolved tickets will be found under [Closed] tab.</li>
                                    </ul>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <!-- <span class="more" *ngIf="user.role != 'customer' && user.role != '3rdparty' && user.categoryCompany==categoryCompany.Inventory">
                  For more, explore  <b><a href="https://accordev.com/knowledge-base" target="_blank">Accordev Knowledge Base</a></b>
                </span> -->
                </div>
                <!-- <div class="modal-footer">
                <div class="btn btn-sm btn-light" (click)="HelpModal.hide()">Cancel</div>
                <div class="btn btn-sm btn-success" (click)="newJob();HelpModal.hide()">Yes</div>
            </div> -->
            </div>
        </div>
    </div>
</div>
<!-- Warning Modal  -->
<div mdbModal #warningModal="mdbModal" class="modal">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warning</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="warningModal.hide()">
              <span aria-hidden="true">&times;</span>
           </button>
                </div>
                <div class="modal-body text-center">
                    <p *ngIf=" this.companyStatus == companyStatusEnum.Canceled"> Company Canceled</p>
                    <p *ngIf=" this.companyStatus == companyStatusEnum.Deactivated"> Company Deactivated</p>
                    <p *ngIf=" this.companyStatus == companyStatusEnum.NotPay"> Company Not Pay</p>
                    <p *ngIf=" this.companyStatus == companyStatusEnum.NotPayPeriod"> Comapny W Payment</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal User Disable Modal -->
<div mdbModal #userDisableModal="mdbModal" class="modal">
    <div class="modal-dialog " role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Warning</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="userDisableModal.hide()">
            <span aria-hidden="true">&times;</span>
         </button>
                </div>
                <div class="modal-body text-center">
                    <p> Member Disabled</p>
                </div>
            </div>
        </div>
    </div>
</div>
