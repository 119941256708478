import { GlobalService } from 'src/app/core/_services/global/global.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/_services/auth/auth.service';
import { CompaniesService } from 'src/app/core/_services/companies/companies.service';
import { SuperAdminService } from 'src/app/core/_services/super-admin/super-admin.service';
import { CompanyPlan } from 'src/app/models/company/company';
import { ResponseModel } from 'src/app/models/response/ResponseModel';
import { ValidationModel } from 'src/app/ValidationModel/ValidationModel';
import { AsyncEmailUserValidator } from '../../async.validator-user';
import { HttpClient } from '@angular/common/http';
import { PlanCompanyType } from 'src/app/models/planType/PlanCompanyType';
declare var google: any;
interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: string;
}
@Component({
  selector: 'app-new-branch',
  templateUrl: './new-branch.component.html',
  styleUrls: ['./new-branch.component.scss']
})
export class NewBranchComponent implements OnInit, AfterViewInit {
  @ViewChild('addressText') addressText: any;
  contianer
  isLinear = false;
  registerFormGroup: FormGroup;
  signInFormGroup: FormGroup;
  registerModel: any = {}
  signInModel: any = {}
  showSignUp: boolean;
  showSignIn: boolean;
  emailUser: any
  emailReset: any
  emailPattern = ValidationModel.emailPattern
  numberPattern = ValidationModel.numberPattern
  passwordPattern = ValidationModel.passwordPattern
  hideModal: boolean = true;
  @ViewChild('resetPasswordModal') resetPasswordModal: ModalDirective;
  @ViewChild('verifyEmailModal') verifyEmailModal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;
  person: any = {}
  // defaultValue: Country = {} as Country;
  defaultValue = {
    name: 'United States',
    alpha2Code: 'US',
    alpha3Code: 'US',
    numericCode: '840'
  };
  error: any = {
    isError: true,
    errorMessage: ''
  };
  user: any = {}
  timer : any
  logoDefault:any=''
  planCompanyType = PlanCompanyType
  planType
  lng = 0 
  lat = 0
  formatted_address = ''
  place_id = ''
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private _companiesService: CompaniesService,
    private _authService: AuthService,
    readonly emailUserValidator: AsyncEmailUserValidator,
    private route: ActivatedRoute,
    private _superAdminService: SuperAdminService,
    private _globalService: GlobalService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    // if (this.user.role != 'admin' || this.user.role == null || this.user.role == undefined ) {
    //   this.router.navigate(['/Login'])
    // }
    this.getDefaultLogo()
    this.createRegisterForm();
    this.createSignInForm();
    this.contianer = document.querySelector(".contianer")
    this.contianer.classList.add('sign-up-mode')
    this.showSignUp = false;
    this.showSignIn = false;
    this.route.queryParams.subscribe(params => {
      this.emailUser = params['email'];
    });
    if (this.emailUser) {
      this.signInFormGroup.patchValue({
        email: this.emailUser
      })
    }
    this.route.data.subscribe((response: ResponseModel) => {
      if (response['person'].success) {
        this.person = response['person'].data
        this.patchValues(response['person'].data)
      }
    })
    this.planType = localStorage.getItem('bufferPlan')
  }
  patchValues(user: any) {
    this.registerFormGroup.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone
      // addressOne: user.addressOne,
      // addressTow: user.addressTow,
      // state: user.state,
      // zip: user.zip,
      // city: user.city,
      // country: 'United States',
    });
  }
  // ******** Initial  Up In Form **********
  createRegisterForm() {
    this.registerFormGroup = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['',
        {
          validators: [Validators.required, Validators.pattern(this.emailPattern)]
        }],
      // passwrod: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      // confirmPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      companyPhone: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      companyName: ['', Validators.required],
      companyEmail: ['',
        {
          validators: [Validators.required, Validators.pattern(this.emailPattern)]
          // ,asyncValidators: [this.emailValidator.validate.bind(this.emailValidator)],
          // updateOn: 'blur'
        }
      ],
      // companyStatus: [0, Validators.required],
      addressOne: ['', Validators.required],
      addressTow: ['',],
      website: ['',],
      // country: ['', Validators.required],
      country: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      phone: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
    });
  }
  // ******** Method to match Password And Confirm Password **********
  // passwordMachValidetor(g: FormGroup) {
  //   var password = g.get('passwrod').value,
  //     confimePassword = g.get('confirmPassword').value;
  //   return password === confimePassword ? null : { 'mismatch': true };
  // }
  get f() {
    return this.registerFormGroup.controls;
  }

  // ******** Initial  Sign In Form **********
  createSignInForm() {
    this.signInFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', Validators.required],
    })
  }

  get ff() {
    return this.signInFormGroup.controls
  }
  // ******** SignUpMode && SignInMode Method for animation in page **********
  SignUpMode() {
    const sign_up_btn = document.querySelector("#sign-up-btn")
    sign_up_btn.addEventListener('click', () => {
      this.contianer.classList.add('sign-up-mode')
    })
  }
  SignInMode() {
    const sign_in_btn = document.querySelector("#sign-in-btn")
    sign_in_btn.addEventListener('click', () => {
      this.contianer.classList.remove('sign-up-mode')
    })
  }
  // ******** Sign Up Method send to Service for Store Data **********
  SignUp() {
    let bufferCompanyPlan: any = 0
    let bufferCompanyPlanType: any = 0
    this.registerModel = this.registerFormGroup.value;
    if (localStorage.getItem('bufferPlan') != null) {
      bufferCompanyPlan = localStorage.getItem('bufferPlan')
    }
    if (localStorage.getItem('companyPlanType') != null) {
      bufferCompanyPlanType = localStorage.getItem('companyPlanType')
    }
    let newCompany = {
      companyAddress: {
        country: this.defaultValue,
        addressOne: this.registerModel.addressOne,
        addressTow: this.registerModel.addressTow,
        city: this.registerModel.city,
        zip: this.registerModel.zip,
        state: this.registerModel.state,
        lat :this.lat ,
        lng :this.lng ,
        formatted_address :this.formatted_address ,
        place_id :this.place_id 
      },
      companyEmail: this.getEmailWithoutSpace(this.registerModel.companyEmail),
      companyName: this.registerModel.companyName,
      companyPhone: this.registerModel.companyPhone,
      companyWebsite: this.registerModel.website,
      companyLogo:this.logoDefault,
      currency : 'USD',
      companyPlan: Number(bufferCompanyPlan),
      categoryCompany:Number(localStorage.getItem('companyCategoryType')),
      companyPlanType: Number(bufferCompanyPlanType),
      ownerName: this.registerModel.lastName + ', ' + this.registerModel.firstName,
      signUpDate: new Date(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userInformation: {
        firstName: this.registerModel.firstName,
        lastName: this.registerModel.lastName,
        email: this.getEmailWithoutSpace(this.registerModel.email),
        phone: this.registerModel.phone,
        // password: this.registerModel.passwrod,
        // confirmPassword: this.registerModel.confirmPassword,
        name: this.registerModel.firstName + " " + this.registerModel.lastName,
        addressOne: this.registerModel.addressOne,
        addressTow: this.registerModel.addressTow,
        country: this.defaultValue,
        city: this.registerModel.city,
        zip: this.registerModel.zip,
        state: this.registerModel.state,
        lat :this.lat ,
        lng :this.lng ,
        formatted_address :this.formatted_address ,
        place_id :this.place_id 
      },
    }
    this._companiesService.addCompanyBranch(newCompany, this.person.id).subscribe((response: ResponseModel) => {
      if (response.success) {
        // this._globalService.logout()
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.toastr.success('Please Login ', 'Successfully Register',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
        this.registerFormGroup.reset()
        localStorage.clear()
        // localStorage.setItem('payment',response.data.id)
        // clearTimeout(this.timer)
          this.router.navigate(['payment/',response.data.id])
        //  this.timer = setTimeout(() => {
        //   window.location.reload()
        // }, 2000);
      }
      else {
        this.toastr.error('Error Registration ', 'Please check your information ',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      }
    }, error => {
      this.toastr.error('Error Registration ', 'Please check your information',
        {
          progressBar: true,
          progressAnimation: "increasing",
          timeOut: 3000
        });
    })
  }
  // ******** Sign In Method send to Service for check credentials **********
  SignIn() {
    this.signInModel = this.signInFormGroup.value;
    let model = {
      email: this.getEmailWithoutSpace(this.signInModel.email),
      password: this.signInModel.password
    }
    if (model.email == 'morhaf@maplly.com' || model.email == '7285820dd0@dragonmail.live' || model.email == 'omar@maplly.com' || model.email == 'tmap44@hotmail.com' || model.email == 'mhd@maplly.com') {
      this._superAdminService.loginSuperAdmin(model).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.router.navigate(['/super-company'])
        }
      })
    }
    else {
      this._authService.login(model).subscribe((response: ResponseModel) => {
        if (response.success && response.data.companyStatus == 1) {
          this.router.navigate(['/disabled-company'])
        }
        else if (response.success && response.data.companyStatus == 0) {
          this.router.navigate(['/deactivated-company'])
        }
        else if (response.success && !response.data.emailVerified) {
          this.verifyEmailModal.show()
        }
        else if (response.success && response.data.emailVerified) {
          let id = response.data.companyId
          localStorage.setItem("companyId", id)
          localStorage.setItem("displayName", response.data.displayName)
          // if (response.data.role === "admin") {
          //   localStorage.setItem('isAdmin','true')
          // }
          if (response.data.role === "admin" || response.data.role === "manager") {
            localStorage.setItem("userId", response.data.id)
            localStorage.setItem("isCustomer", 'false')
            localStorage.setItem("isThirdParty", 'false')
            localStorage.setItem("isAdmin", 'false')
            this.router.navigate(['/admin/', id])
            this.toastr.success('Welcome in Dashboard', 'Successfully Login',
              {
                progressBar: true,
                progressAnimation: "increasing",
                timeOut: 3000
              });
          }
          else if (response.data.role === "user" || response.data.role === "customer" || response.data.role === "crew"
            || response.data.role === "teamleader" || response.data.role === "3rdparty") {
            this.router.navigate(['/user/', response.data.id])
            localStorage.setItem("userId", response.data.id)
            this.toastr.success('Welcome in your profile', 'Successfully Login',
              {
                progressBar: true,
                progressAnimation: "increasing",
                timeOut: 3000
              });
          }
          // else {
          //   this.router.navigate(['/admin/', id])
          //   this.toastr.success('Welcome in Dashboard', 'Successfully Login',
          //     {
          //       progressBar: true,
          //       progressAnimation: "increasing",
          //       timeOut: 3000
          //     });
          // }
        }
        else {
          this.toastr.error('Please try agin', 'email or password wrong',
            {
              progressBar: true,
              progressAnimation: "increasing",
              timeOut: 3000
            });
        }
      }, error => {
        this.toastr.error('Please try agin', 'email or password wrong',
          {
            progressBar: true,
            progressAnimation: "increasing",
            timeOut: 3000
          });
      })
    }
  }
  getDefaultLogo()
  {
    let base64data:any =''
    this.http.get('/assets/img/logo/default_logo.png', { responseType: 'blob' })
    .subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        //  base64data = ;
         this.logoDefault = reader.result
      }
      reader.readAsDataURL(res);
    });
}
  myFunction() {
    var x = <HTMLInputElement>document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  passwordSignUp() {
    this.showSignUp = !this.showSignUp;
  }
  passwordSignIn() {
    this.showSignIn = !this.showSignIn;

  }
  onCountrySelected(country: any) {
    this.defaultValue = country
  }
  // forgotPassword(resetForm: NgForm) {
  //   let email = this.getEmailWithoutSpace(this.emailReset)
  //   this._authService.passwordResetEmail(email).subscribe((response: ResponseModel) => {
  //     if (response.success) {
  //       resetForm.reset()
  //       this.resetPasswordModal.hide();
  //       this.toastr.success('Please Check inbox in email', 'Successfully Submitting',
  //         {
  //           progressBar: true,
  //           progressAnimation: "increasing",
  //           timeOut: 3000
  //         });
  //     }
  //     else {
  //       this.toastr.error('Please try agin', 'Error Reset',
  //         {
  //           progressBar: true,
  //           progressAnimation: "increasing",
  //           timeOut: 3000
  //         });
  //     }
  //   })
  // }
  forgotPassword(resetForm: NgForm) {
    let email = String(this.emailReset).toLowerCase().match(this.emailPattern)
    if (this.emailReset == null || email == null) {
      return null
    }
    else {
      this._authService.isEmailExists(this.emailReset).subscribe((response: ResponseModel) => {
        if (response.success) {
          this.error = {
            isError: true,
            errorMessage: response.message
          };
        }
        else {
          this.error = {
            isError: false,
          }
          let email = this.getEmailWithoutSpace(this.emailReset)
          this._authService.passwordResetEmail(email).subscribe((response: ResponseModel) => {
            if (response.success) {
              resetForm.reset()
              this.resetPasswordModal.hide();
              this.toastr.success('Please Check inbox in email', 'Successfully Submitting',
                {
                  progressBar: true,
                  progressAnimation: "increasing",
                  timeOut: 3000
                });
            }
            else {
              this.toastr.error('Please try agin', 'Error Reset',
                {
                  progressBar: true,
                  progressAnimation: "increasing",
                  timeOut: 3000
                });
            }
          })
        }
      })
    }
  }
  getEmailWithoutSpace(email: string) {
    return email.trim().toLocaleLowerCase()
  }
  ngOnDestroy() {
    if (localStorage.getItem('bufferPlan') != null) {
      localStorage.removeItem('bufferPlan')
    }
  }
  ngAfterViewInit(){
    this.getPlaceAutocomplete()
  }
  getPlaceAutocomplete() {
    let place:any
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      }
      );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
       place = autocomplete.getPlace();
       this.lat = place.geometry.location.lat()
       this.lng = place.geometry.location.lng()
       this.formatted_address = place.formatted_address
       this.place_id = place.place_id
      this.patchValuesAddress(this.getAddressFromComponents(place.address_components))
    });
  }
  patchValuesAddress(item:any){
    this.registerFormGroup.patchValue({
      addressOne: item.addressOne,
      addressTow: item.addressTow,
      state: item.state,
      zip: item.zip,
      city: item.city,
    });
  }
  getAddressFromComponents(geo: any) {
    const streetNumber:any = geo.find( g => g.types.find( t => t === 'street_number') ) != undefined  ? geo.find( g => g.types.find( t => t === 'street_number') ).long_name : '' ;
    const streetName = geo.find( g => g.types.find( t => t === 'route' )) != undefined ?geo.find( g => g.types.find( t => t === 'route' )).long_name : '';
    const cityName = geo.find( g => g.types.find( t => t === 'locality') != undefined && g.types.find( t => t === 'political' )) != undefined ? geo.find( g => g.types.find( t => t === 'locality') && g.types.find( t => t === 'political' )).long_name : '' ;
    const stateName =  geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'administrative_area_level_1') && g.types.find( t => t === 'political' )).long_name : '';
    const countryName = geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )) != undefined ?geo.find( g => g.types.find( t => t === 'country') && g.types.find( t => t === 'political' )).long_name : '';
    const zip = geo.find( g => g.types.find( t => t === 'postal_code' )) != undefined ?  geo.find( g => g.types.find( t => t === 'postal_code' )).long_name : '';
    // this.StopForm.patchValue
    return {
      addressOne: `${streetNumber} ${streetName}`,
      addressTow: '',
      city: cityName,
      state: stateName,
      // country: countryName,
      zip: zip
    };
  }
}
