<div class="estimate-section" id="Estimate">
    <div class="tittle-section">
        <p><i class="fas fa-warehouse pr-1"></i>Estimate</p>
        <div class="button-group">
            <button class="btn btn-primary">
            Email to
           </button>
            <button class="btn btn-primary">
           Print
          </button>
        </div>
        <button class="btn btn-success" (click)="addEstimateModal.show() ">
        <i class="far fa-plus-square"></i> Add New
    </button>
    </div>
    <div class="ant-empty-image" *ngIf="estimates.length <= 0 ">
        <img src="./assets/img/empty.svg" alt="empty-img">
        <span>No Data</span>
    </div>
    <mat-accordion class="example-headers-align " multi *ngFor="let item of estimates; let i= index">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <p>Num: {{item.itemNumber}} </p>
                <div class="title-info">
                    <span>{{item.itemName }} || Quantity: {{item.quantity}}</span>
                </div>
                <!-- <p>{{item.date | date:'short'}}</p>
            <p>Item Num:{{item.itemNumber}}</p> -->
            </mat-expansion-panel-header>
            <div class="card">
                <ngx-flip [flip]="flipDiv1">
                    <div front [style.display]="!flipDiv1 ? 'block' : 'none'">
                        <div class="title-card">
                            <button class="btn btn-success" (click)="storeEstimate(item);onClick1()"><i class="fas fa-edit"></i> Edit</button>
                            <button class="btn btn-danger" (click)="storeEstimate(item);deleteEstimateModal.show()"><i class="fas fa-trash-alt"></i> Delete</button>
                            <button class="btn btn-primary" (click)="print()"><i class="fas fa-save"></i> Print Tags</button>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Item Number</h6>
                                    <label>{{item.itemNumber}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Item Name</h6>
                                    <label>{{item.itemName}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Category</h6>
                                    <label>{{item.category}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>HandledBy</h6>
                                    <label>{{userName}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Weight</h6>
                                    <label>{{item.weight}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Quantity</h6>
                                    <label>{{item.quantity}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="item">
                                    <h6>Volume</h6>
                                    <label>{{item.volume}}</label>
                                </div>
                            </div>
                            <div class="col-lg-4 ">
                                <div class="item">
                                    <h6>Price</h6>
                                    <label>{{item.price}}</label>
                                </div>
                            </div>
                            <div class="col-sm-12 description" *ngIf="item.quality">
                                <div class="title">
                                    <span>{{item.date | date:'short'}}</span>
                                    <span>GPS : Address GPS</span>
                                </div>
                                <div class="item">
                                    <h6>Pick Up Quality</h6>
                                    <label>{{item.quality}}</label>
                                </div>
                            </div>
                            <div class="col-sm-12 description" *ngIf="item.delivery">
                                <div class="title">
                                    <span>{{item.date | date:'short'}}</span>
                                    <span>GPS : Address GPS</span>
                                </div>
                                <div class="item">
                                    <h6>Delivery Quality</h6>
                                    <label>{{item.delivery}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div back [style.display]="flipDiv1 ? 'block' : 'none'">
                        <div class="title-card ">
                            <button class="btn btn-primary" (click)="onClick1()"><i class="fas fa-backward"></i> Back</button>
                            <select class="browser-default custom-select">
                          <option selected>Draft</option>
                          <option>Deal</option>
                          <option>InProcess</option>
                          <option>Done</option>
                       </select>
                            <button class="btn btn-success" (click)="estimateEditModal.show()">
                           <i class="fas fa-save"></i> Save
                        </button>
                        </div>
                        <form>
                            <div class="row back">
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-gruop">
                                        <label for="itemNumber">Item Number:</label>
                                        <input type="text" class="form-control" name="itemNumber" [(ngModel)]="estimateModel.itemNumber" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-gruop">
                                        <label for="itemName">Item Name:</label>
                                        <input type="text" class="form-control" name="itemName" [(ngModel)]="estimateModel.itemName" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="category">Category:</label>
                                        <input type="text" class="form-control" name="category" [(ngModel)]="estimateModel.category" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="from-group">
                                        <label for="handledBy">HandledBy:</label>
                                        <input type="text" class="form-control" name="userName" [(ngModel)]="userName" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="weight">Weight:</label>
                                        <input type="text" class="form-control" name="weight" [(ngModel)]="estimateModel.weight" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="quantity">Quantity:</label>
                                        <input type="text" class="form-control" name="quantity" [(ngModel)]="estimateModel.quantity" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="volume">Volume</label>
                                        <input type="text" class="form-control" name="volume" [(ngModel)]="estimateModel.volume" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="volume">Price</label>
                                        <input type="text" class="form-control" name="price" [(ngModel)]="estimateModel.price" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="quality">Pick Up Quality :</label>
                                        <textarea class="form-control" name="quality" [(ngModel)]="estimateModel.quality" rows="4"></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="delivery">Pick Up Delivery :</label>
                                        <textarea class="form-control" name="delivery" [(ngModel)]="estimateModel.delivery" rows="4"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </ngx-flip>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<!-- Modal Add Estimate -->
<div mdbModal #addEstimateModal="mdbModal" class="modal estimateModal fade">
    <div class="modal-dialog modal-lg">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">New Estimate</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="addEstimateModal.hide()">
                   <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="estimatesForm" (ngSubmit)="addEstimate()">
                        <mat-horizontal-stepper [linear]="isLinear" #stepper>
                            <mat-step [stepControl]="estimatesForm">
                                <ng-template matStepLabel>Add Estimate</ng-template>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="itemNumber" placeholder="Item Number" />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="itemName" placeholder="Item Name" />
                                    </div>
                                    <!-- <div class="col-md-6">
                                      <input type="text" class="form-control" formControlName="handledBy" placeholder="Handled By(Name)" />
                                  </div> -->
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="category" placeholder="Category(Ca.Name)" />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="volume" placeholder="Volume (Val.Conv.)" />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="weight" placeholder="Weight (Wei.Conv.)" />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="quantity" placeholder="Quantity" />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control" formControlName="price" placeholder="Price" />
                                    </div>
                                </div>
                                <button type="button" mdbBtn class="btn" matStepperNext color="success">Next</button>
                            </mat-step>
                            <mat-step [stepControl]="estimatesForm">
                                <ng-template matStepLabel>Add Estimate</ng-template>
                                <form #form="ngForm">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <textarea type="text" class="form-control" name="quality" [(ngModel)]="estimateDrivenForm.quality" placeholder="Pick Up Quality" rows="3"></textarea>
                                            <div class="copy">
                                                <input type="checkbox" [checked]="estimateDrivenForm.checked" (change)="toggleEditable($event)" />
                                                <span>Same Pick Up</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <textarea type="text" class="form-control" name="delivery" [(ngModel)]="estimateDrivenForm.delivery" placeholder="Delivery Delivery" rows="3"></textarea>
                                        </div>
                                    </div>
                                </form>
                                <button type="button" mdbBtn class="btn" matStepperPrevious color="primary">Back</button>
                                <button type="submit" mdbBtn class="btn" color="success" (click)="addEstimateModal.hide()">Add </button>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Edit Estimate -->
<div mdbModal #estimateEditModal="mdbModal" class="modal fade">
    <div class="modal-dialog" role="document">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" (click)="estimateEditModal.hide()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <p>Do you want to save changes to this page before closing?</p>
                    <p class="text-secondary"><small>If you don't save, your changes will be lost.</small></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="estimateEditModal.hide()">Close</button>
                    <button type="button" class="btn btn-success" (click)="updateEstimate();estimateEditModal.hide() ">Save </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Delete Estimate  -->
<div mdbModal #deleteEstimateModal="mdbModal" class="modal fade">
    <div class="modal-dialog">
        <div class="container">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="deleteEstimateModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <p>Do you want to remove a note ?</p>
                    <p class="text-secondary"><small>If you don't remove, your changes will be lost.</small></p>
                </div>
                <div class="modal-footer ">
                    <button type="button " class="btn btn-primary " data-dismiss="modal " (click)="deleteEstimateModal.hide() ">Close</button>
                    <button type="button " class="btn btn-danger " (click)="deleteEstimate();deleteEstimateModal.hide() ">Delete
                  </button>
                </div>
            </div>
        </div>
    </div>
</div>