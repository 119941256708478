import { TrucksService } from '../_services/trucks/trucks.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { PersonPendingService } from '../_services/PendingPersons/personPending.service';
import { GlobalService } from '../_services/global/global.service';
import { ToolsService } from '../_services/tools/tools.service';
import { ToolType } from 'src/app/models/tools-model/ToolType';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';

@Injectable()

export class GetAllPendingCustomersResolver implements Resolve<any[]>
{
  settingTools: any = {}
  customersObj: any = {}
  constructor(
    private _personPendingService: PersonPendingService,
    private _globalService: GlobalService,
    private _toolsService: ToolsService,
    ) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    let user = JSON.parse(localStorage.getItem('user'))
    if (this._globalService.items != undefined && this._globalService.items.success) {
      this.settingTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
      this.customersObj = this._toolsService.getPermission(user.role, this.settingTools, ToolSettingEditItem.Customers_list)
      if (user.role =='admin' || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "active" || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "readOnly") {
        return  this._personPendingService.getAllPendingCustomers(user.companyId).pipe(
          catchError(error => {
            // console.log(error)
            return of(null);
          })
        );
      }
    }
    else if(this._globalService.items == undefined){
      if (this._globalService.items != undefined && this._globalService.items.success) {
        this.settingTools = this._globalService.items.data.find(d => d.toolType === ToolType.Setting_Tools)
        this.customersObj = this._toolsService.getPermission(user.role, this.settingTools, ToolSettingEditItem.Customers_list)
        if (user.role =='admin' || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "active" || this.customersObj.itemStatus == "Active" && this.customersObj.roleStatus == "readOnly") {
          return  this._personPendingService.getAllPendingCustomers(user.companyId).pipe(
            catchError(error => {
              // console.log(error)
              return of(null);
            })
          );
        }
      }
    }
  }
}
