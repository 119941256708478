import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToolSettingEditItem } from 'src/app/models/tools-model/ToolSettingEditItem';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class QrCodeMovingService {
  baseUrl = environment.baseUrl
  constructor(
    private http: HttpClient
  ) { }

  addQrCodes(companyId:any,model:any){
    return this.http.post(this.baseUrl + 'qrCodes/' + companyId,model)
  }
  getQrCode(id:any){
    return this.http.get(this.baseUrl + 'qrCode/' + id)
  }
  unassignQrCode(jobId:string,id:string){ 
    return this.http.put(`${this.baseUrl}UnassignQrCode/${jobId}/${id}/${ToolJobsEditItem.inventory}`,{})
  }
}
