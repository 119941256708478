import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToolJobsEditItem } from 'src/app/models/tools-model/ToolJobsEditItem';
import { environment } from 'src/environments/environment.prod';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class CarWashService {
  baseUrl = environment.baseUrl
  baseDomain = environment.baseDomain
  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) { }

  getVehicleById(id: string) {
    return this.http.get(this.baseUrl + 'vehicle/' + id)
  }
  // for all  companies
  getVehiclesByCompanies(companyId: string) {
    return this.http.get(this.baseUrl + 'vehiclesCompanies/' + companyId)
  }
  // for company
  getVehiclesByCompany(companyId: string) {
    return this.http.get(this.baseUrl + 'vehicles/' + companyId)
  }
  getVehicleByCustomerId(customerId: string) {
    return this.http.get(this.baseUrl + 'customerVehicle/' + customerId)
  }
  getVehiclesByCustomerId(customerId: string) {
    return this.http.get(this.baseUrl + 'customerVehicles/' + customerId)
  }
  // getVehiclesByCompanyForRemoveLive() {
  //   return this.http.get('https://accordev.herokuapp.com/' + 'vehicles/' + '3992OFYAShrK1QtlrDNa')
  // }
  getVehiclePublicByQrCodeId(adminId: any, qrCodeNumber: any) {
    return this.http.get(this.baseUrl + 'vehiclesPublicQrCode/' + adminId + '/' + qrCodeNumber)
  }
  getVehicleByQrCodeId(id: string) {
    return this.http.get(this.baseUrl + 'vehiclesQrCode/' + id)
  }
  getVehicleStaticsYear(vehicleId: string, year: any) {
    return this.http.get(this.baseUrl + 'vehicleStaticsYear/' + vehicleId + '?q=' + year)
  }
  getVehiclesByDate(companyId: string, date: any, isTime: boolean) {
    return this.http.get(this.baseUrl + 'vehiclesByDate/' + companyId + '/' + date + '?isTime=' + isTime)
  }
  getCountVehicleByDate(companyId: string, dates: any) {
    return this.http.post(this.baseUrl + 'vehiclesCountByDate/' + companyId, dates)
  }
  getVehiclesStaticsYear(companyId: string, q: any) {
    return this.http.get(this.baseUrl + 'vehiclesStaticsYear/' + companyId + '?q=' + q)
  }
  // for job
  getVehiclesStaticsYearInJob(companyId: string, jobId: string, q: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.get(this.baseUrl + 'vehiclesStaticsYearInJob/' + companyId + '/' + jobId + '/' + itemId + '?q=' + q)
  }
  getCustomerCash(companyId: string) {
    return this.http.get(this.baseUrl + 'customerCash/' + companyId)
  }
  getCustomerEmailCard(vehicleId: string) {
    return this.http.get(this.baseUrl + 'vehicleCustomer/' + vehicleId)
  }
  getServices(companyId: string) {
    return this.http.get(this.baseUrl + 'carWashServices/' + companyId)
  }
  isPlateNumberExist(companyId: string, plateNumber: any) {
    return this.http.get(this.baseUrl + 'isVehicleExist/' + companyId + '?plateNumber=' + plateNumber)
  }
  isWebsiteNameExist(websiteName: any) {
    return this.http.get(this.baseUrl + 'isWebsiteNameExist' + '?websiteName=' + websiteName)
  }
  companyWebsiteLinkName(companyId: string, websiteName: any) {
    return this.http.put(this.baseUrl + 'companyWebsiteName/' + companyId, { websiteName: websiteName })
  }
  sendEmailContact(id, model) {
    return this.http.post(this.baseUrl + 'websiteContact/' + id, model)
  }
  getServiceById(id: string) {
    return this.http.get(this.baseUrl + 'service/' + id)
  }
  isCarWashServiceExist(companyId: string, serviceName: any) {
    return this.http.get(this.baseUrl + 'isCarWashServiceExist/' + companyId + '?serviceName=' + serviceName)
  }
  carWashServiceStatus(id: string) {
    return this.http.put(this.baseUrl + 'carWashServiceStatus/' + id, {})
  }
  isVehiclesInQueue(companyId: string) {
    return this.http.get(this.baseUrl + 'isVehiclesInQueue/' + companyId, {})
  }
  isProductsCompanyById(companyId: string) {
    return this.http.get(this.baseUrl + 'isProductsCompany/' + companyId)
  }
  addDescritionProduct(companyId: string, model: any) {
    return this.http.put(this.baseUrl + 'companyStripeProductsDescription/' + companyId, model)
  }
  addVehicle(model: any) {
    return this.http.post(this.baseUrl + 'vehicle', model)
  }
  addVehicleGlobal(model: any, customerId: string) {
    return this.http.post(this.baseUrl + 'vehicleGlobal/' + customerId, model)
  }
  assignVehicle(vehicleId: string, qrCodeId: string) {
    return this.http.post(this.baseUrl + 'assignVehicle/' + vehicleId, { qrCodeId: qrCodeId })
  }
  addCustomerVehicle(vehicleId: string, model: any) {
    return this.http.post(this.baseUrl + 'customerVehicle/' + vehicleId, model)
  }
  addSubscriptionVehicle(vehicleId: string, priceId: string, model: any) {
    return this.http.post(this.baseUrl + 'vehicleSubscription/' + vehicleId + '/' + priceId, model)
  }
  assignExistingCustomerVehicle(vehicleId: string, model: string) {
    return this.http.post(this.baseUrl + 'ExistingCustomerVehicle/' + vehicleId, model)
  }
  vehicleSubscriptionGlobal(vehicleId: string, priceId: string, model: any) {
    return this.http.post(this.baseUrl + 'vehicleSubscriptionGlobal/' + vehicleId + '/' + priceId, model)
  }
  addNormalServices(model: any) {
    return this.http.post(this.baseUrl + 'carWashService', model)
  }
  updateNormalServices(id: string, model: any) {
    return this.http.put(this.baseUrl + 'carWashService' + '/' + id, model)
  }
  updateVehicle(vehicleId: string, model: any) {
    return this.http.put(this.baseUrl + 'updateVehicle/' + vehicleId, model)
  }
  assignedService(vehicleId: string, model: any) {
    return this.http.put(this.baseUrl + 'vehicleService/' + vehicleId, model)
  }
  createVehicleCash(vehicleId: string, model: any) {
    return this.http.put(this.baseUrl + 'updateCashInfo/' + vehicleId, model)
  }
  updateCustomerVehicle(customerId: string, model: any) {
    return this.http.put(this.baseUrl + 'updateCustomerVehicle/' + customerId, model)
  }
  createPortalCustomerStripeCustom(vehicleId: string, type: number, history: any) {
    return this.http.post(this.baseUrl + 'customerVehiclePortal/' + vehicleId + '/?type=' + type, history)
  }
  createCustomerCash(model: any) {
    return this.http.post(this.baseUrl + 'customerCash', model)
  }
  updateCustomerCash(id: string, email: string) {
    return this.http.put(this.baseUrl + 'customerCash/' + id, { email: email })
  }
  updateVehicleMemberShip(vehicleId: string) {
    return this.http.put(this.baseUrl + 'vehicleMemberShip/' + vehicleId, {})
  }
  updateCheckInVehicle(vehicleId: string, comapnyId: string, model: any) {
    return this.http.put(this.baseUrl + 'checkInVehicle/' + vehicleId + '/' + comapnyId, model)
  }
  updateCheckOutVehicle(vehicleId: string, comapnyId: string, model: any) {
    return this.http.put(this.baseUrl + 'checkOutVehicle/' + vehicleId + '/' + comapnyId, model)
  }
  updateUnCheckInVehicle(vehicleId: string, comapnyId: string, model: any) {
    return this.http.put(this.baseUrl + 'UnCheckInVehicle/' + vehicleId + '/' + comapnyId, model)
  }
  // for job
  updateCheckInVehicleInJob(vehicleId: string, jobId: string, model: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'checkInVehicleInJob/' + vehicleId + '/' + jobId + '/' + itemId, model)
  }
  updateCheckOutVehicleInJob(vehicleId: string, jobId: string, model: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'checkOutVehicleInJob/' + vehicleId + '/' + jobId + '/' + itemId, model)
  }
  sendSmsVehicle(vehicleId: string, companyId, model: any) {
    return this.http.post(this.baseUrl + 'sendSmsVehicle/' + vehicleId + '/' + companyId, model)
  }
  renewUpdateForce(vehicleId: string, history) {
    return this.http.put(this.baseUrl + 'vehicleForce/' + vehicleId, { history: history })
  }
  // for job
  renewUpdateForceInJob(vehicleId: string, jobId: string) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'vehicleForceJob/' + vehicleId + '/' + jobId + '/' + itemId, {})
  }
  // for vehicle
  deleteVehicle(vehicleId: string) {
    return this.http.delete(this.baseUrl + 'vehicle/' + vehicleId)
  }
  // for car wash in job details
  deleteVehicleInJob(vehicleId: string, jobId: string) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.delete(this.baseUrl + 'vehicleJob/' + vehicleId + '/' + jobId + '/' + itemId)
  }
  //  car wash in job Apis
  getVehicleInJob(jobId: string) {
    let vehicleId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.get(this.baseUrl + 'vehicleInJob/' + jobId + '/' + vehicleId)
  }
  getVehiclesByJob(companyId: string, jobId: string) {
    let vehicleId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.get(this.baseUrl + 'vehiclesInJob/' + companyId + '/' + jobId + '/' + vehicleId)
  }
  updateVehicleInJob(vehicleId: string, jobId: string, model: any) {
    return this.http.put(this.baseUrl + 'updateVehicleInJob/' + vehicleId + '/' + jobId, model)
  }
  assignQR() {
    if (this.deviceService.isDesktop()) {
      // console.log(this.deviceService.getDeviceInfo())
      this.router.navigate(['/qr-scanner'])
    }
    else if (this.deviceService) {
      let url = `${this.baseDomain}qr-scanner?isAccess=true`
      // Browser.open({ url: url });
      window.location.href = url
    }
  }
  getVehicleInformationFromAPI(model: any) {
    // const fromData = new FormData()
    // fromData.append('upload',model)
    //   const formData = new FormData()
    //   formData.append('upload', model)
    return this.http.post(this.baseUrl + 'checkPlateNumber', { img: model })
  }
  getCarWashWebsiteByCompany(companyId: string) {
    return this.http.get(this.baseUrl + 'carWashWebsite/' + companyId)
  }
  getWebsiteByName(websiteName: string) {
    return this.http.get(this.baseUrl + 'websiteByName/' + websiteName)
  }
  updateCarWashWebsite(id: string, sectionId: any, model: any) {
    return this.http.put(this.baseUrl + 'carWashWebsite/' + id + '/' + sectionId, model)
  }
  getWebsiteById(id: string) {
    return this.http.get(this.baseUrl + 'website/' + id)
  }
  getPrintBodyTemplate(companyId: string, jobId: string) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.get(this.baseUrl + 'getReceiptService/' + companyId + '/' + jobId + '/' + itemId)
  }
  jobVehicleAssigned(jobId: string, model: any) {
    return this.http.put(this.baseUrl + 'jobVehicleAssigned/' + jobId + '/' + ToolJobsEditItem.Assigned_Vehicle, model)
  }
  //  Detaining Car Wash APIS
  addVehicleJob(model: any, jobId: string) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.post(this.baseUrl + 'vehicleJob/' + jobId + '/' + itemId, model)
  }
  updateVehicleJob(vehicleId: string, model: any, jobId: string) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'updateVehicleJob/' + vehicleId + '/' + jobId + '/' + itemId, model)
  }
  sendSmsVehicleDetailing(vehicleId: string, companyId, model: any, jobId: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.post(this.baseUrl + 'sendSmsVehicleJob/' + vehicleId + '/' + companyId + '/' + jobId + '/' + itemId, model)
  }
  createVehicleCashInJob(vehicleId: string, model: any, jobId: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'updateCashInfoJob/' + vehicleId + '/' + jobId + '/' + itemId, model)
  }
  assignedJobService(vehicleId: string, jobId: string, model: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.put(this.baseUrl + 'vehicleServiceJob/' + vehicleId + '/' + jobId + '/' + itemId, model)
  }
  sendVoiceByEmail(jobId: string, model: any) {
    let itemId = ToolJobsEditItem.Assigned_Vehicle
    return this.http.post(this.baseUrl + 'printReceiptService/' + jobId + '/' + itemId, model);
  }
}
